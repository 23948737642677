import { CachableHttpRequest, ILog, ILogSession } from '@curbnturf/entities';
import { createAction, props } from '@ngrx/store';

export const startLogSession = createAction('[Log] Start Log Session');
export const logSessionStarted = createAction(
  '[Log] Log Session Started',
  props<{
    payload: { request?: CachableHttpRequest; response: ILogSession };
  }>(),
);

export const logMessage = createAction('[Log] Log Message', props<{ log: ILog }>());
