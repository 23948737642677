import { NGRX_BUCKETS, Reservation, Site } from '@curbnturf/entities';
import * as SiteSelectors from '@curbnturf/site';
import { UserState } from '@curbnturf/user/src/lib/+state/user.reducer';
import * as UserSelectors from '@curbnturf/user/src/lib/+state/user.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReservationState } from './reservation.reducer';

// Lookup the 'Reservation' feature state managed by NgRx
const getReservationState = createFeatureSelector<ReservationState>(NGRX_BUCKETS.reservation);

const getLoaded = createSelector(getReservationState, (state: ReservationState) => state.loaded);
const getError = createSelector(getReservationState, (state: ReservationState) => state.error);

const getAllReservationEntities = createSelector(
  getReservationState,
  getLoaded,
  (state: ReservationState, isLoaded) => {
    if (isLoaded) {
      const instantiatedReservations: { [id: number]: Reservation } = {};
      for (const key in state.reservations) {
        if (state.reservations.hasOwnProperty(key)) {
          instantiatedReservations[key] = new Reservation(state.reservations[key]);
        }
      }

      return instantiatedReservations;
    }

    return {};
  },
);

// Sorting reservation by first date in descendign order
const sortDateDescending = (reservations: Reservation[]) => {
  return reservations.sort((a: Reservation, b: Reservation) => {
    if (!a.date && !b.date) {
      return 0;
    }
    if (!a.date) {
      return 1;
    }
    if (!b.date) {
      return -1;
    }
    if (a.date.length === 0 && b.date.length === 0) {
      return 0;
    }
    if (a.date.length === 0 && b.date.length > 0) {
      return 1;
    }
    if (a.date.length > 0 && b.date.length === 0) {
      return -1;
    }

    const aDate = a.date.from;
    const bDate = b.date.from;

    if (aDate < bDate) {
      return 1;
    }
    if (aDate > bDate) {
      return -1;
    }

    return 0;
  });
};

const getAllReservations = createSelector(getReservationState, getLoaded, (state: ReservationState, isLoaded) => {
  return isLoaded
    ? sortDateDescending(
        Object.keys(state.reservations).map<Reservation>((id) => new Reservation(state.reservations[parseInt(id, 10)])),
      )
    : [];
});

const getAllGuestReservations = createSelector(
  getAllReservations,
  UserSelectors.getUserState,
  (reservations: Reservation[], userState: UserState) => {
    return reservations.filter((reservation) => reservation.isGuest(userState.currentId));
  },
);

const getAllHostReservations = createSelector(
  getAllReservations,
  UserSelectors.getUserState,
  (reservations: Reservation[], userState: UserState) => {
    return reservations.filter((reservation) => reservation.isHost(userState.currentId));
  },
);

const getSelectedId = createSelector(getReservationState, (state: ReservationState) => state.selectedId);

const getSelectedReservation = createSelector(getAllReservationEntities, getSelectedId, (reservations, id) => {
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = parseInt(id, 10);
  }
  return new Reservation(reservations[id]);
});

const getSelectedReservationSite = createSelector(
  getSelectedReservation,
  SiteSelectors.getSiteEntities,
  (reservation, sites) => {
    if (!reservation || !reservation.site || !sites || !reservation.site.id) {
      return undefined;
    }

    return new Site(sites[reservation.site.id]);
  },
);

export const reservationQuery = {
  getLoaded,
  getError,
  getAllReservationEntities,
  getAllReservations,
  getAllGuestReservations,
  getAllHostReservations,
  getSelectedReservation,
  getSelectedReservationSite,
};
