import { HttpErrorResponse } from '@angular/common/http';
import {
  ICreateUser,
  ICurbNTurfCash,
  IPoiFavorite,
  IRV,
  ISite,
  ISiteFavorite,
  ITax,
  IUser,
  IUserSearchRequest,
  Role,
} from '@curbnturf/entities';
import { createAction, props } from '@ngrx/store';

export enum UserActionTypes {
  LoadCurrentUser = '[User] Load Current User',
  ReloadCurrentUser = '[User] Reload Current User',
  CurrentUserLoaded = '[User] Current User Loaded',
  CurrentUserUnloaded = '[User] Current User Unloaded',
  LoadUser = '[User] Load User',
  LoadUsers = '[User] Load Users',
  UserLoaded = '[User] User Loaded',
  UsersLoaded = '[User] Users Loaded',
  UserLoadError = '[User] User Load Error',
  RemoveUser = '[User] Remove User',
  RestoreUser = '[User] Restore User',
  SelectUser = '[User] Select User',
  CreateUser = '[User] Create User',
  UserCreated = '[User] User Created',
  UserCreateError = '[User] User Create Error',
  AddRV = '[User] Create RV Locally',
  RemoveRV = '[User] Remove RV',
  UpdateRV = '[User] Update RV',
  SelectRV = '[User] Select RV',
  AddTax = '[User] Create Tax Locally',
  RemoveTax = '[User] Remove Tax',
  UpdateTax = '[User] Update Tax',
  SelectTax = '[User] Select Tax',
  UpdateUser = '[User] Update User',
  UserUpdated = '[User] User Updated',
  UserUpdateError = '[User] User Update Error',
  UploadUserImage = '[User] Upload User Image',
  UserImageUploaded = '[User] User Image Uploaded',
  UserImageUploadError = '[User] User Image Upload Error',
  RemoveUserImage = '[User] Remove User Image',
  UserImageRemoved = '[User] User Image Removed',
  UserImageRemoveError = '[User] User Image Remove Error',
  UploadRvImage = '[User] Upload RV Image',
  RvImageUploaded = '[User] RV Image Uploaded',
  RvImageUploadError = '[User] RV Image Upload Error',
  RemoveRvImage = '[User] Remove RV Image',
  RvImageRemoved = '[User] RV Image Removed',
  RvImageRemoveError = '[User] RV Image Remove Error',
  LoginUser = '[User] Login User',
  LoadUserFavorites = '[User] Load User Favorites',
  UserFavoritesLoaded = '[User] User Favorites Loaded',
  SetInProgressSite = '[User] Set In Progress Site',
  RegisterPushToken = '[User] Register Push Notification Token',
  RegisterPushTokenSuccess = '[User] Register Push Notification Token Success',
  RegisterPushTokenFailed = '[User] Register Push Notification Token Failed',
  LoadAccountBalanceTransactions = '[User] Load Account Balance Transactions',
  LoadAccountBalanceTransactionsSuccess = '[User] Load Account Balance Transactions Success',
  LoadAccountBalanceTransactionsFailed = '[User] Load Account Balance Transactions Failed',
  LoadCashTransactions = '[User] Load CurbNTurf Cash Transactions',
  LoadCashTransactionsSuccess = '[User] Load CurbNTurf Cash Transactions Success',
  LoadCashTransactionsFailed = '[User] Load CurbNTurf Cash Transactions Failed',
  UpdateCurrentUserLocally = '[User] Update Current User Info Locally',
}

export const loadCurrentUser = createAction(UserActionTypes.LoadCurrentUser);

export const reloadCurrentUser = createAction(UserActionTypes.ReloadCurrentUser);

export const currentUserLoaded = createAction(UserActionTypes.CurrentUserLoaded, props<{ user: IUser }>());

export const currentUserUnloaded = createAction(UserActionTypes.CurrentUserUnloaded);

export const loadUser = createAction(
  UserActionTypes.LoadUser,
  props<{ id?: number; refId?: string; force?: boolean }>(),
);

export const loadUsers = createAction(UserActionTypes.LoadUsers, props<{ request: IUserSearchRequest }>());

export const userLoadError = createAction(
  UserActionTypes.UserLoadError,
  props<{ error: HttpErrorResponse | string }>(),
);

export const userLoaded = createAction(UserActionTypes.UserLoaded, props<{ user: IUser }>());

export const usersLoaded = createAction(UserActionTypes.UsersLoaded, props<{ users: IUser[] }>());

export const selectUser = createAction(UserActionTypes.SelectUser, props<{ userId: number }>());

export const removeUser = createAction(UserActionTypes.RemoveUser, props<{ userId: number }>());

export const restoreUser = createAction(UserActionTypes.RestoreUser, props<{ userId: number }>());

export const createUser = createAction(
  UserActionTypes.CreateUser,
  props<{
    payload: {
      email: string;
      firstName?: string;
      lastName?: string;
      authAccessToken?: string;
      authIdToken?: string;
      authRenewToken?: string;
      authExpires?: number;
      loginAfter?: boolean;
      role?: Role;
      source?: string;
    };
  }>(),
);

export const userCreateError = createAction(
  UserActionTypes.UserCreateError,
  props<{ error: HttpErrorResponse | string }>(),
);

export const userCreated = createAction(UserActionTypes.UserCreated, props<{ user: IUser }>());

export const addRV = createAction(UserActionTypes.AddRV, props<{ rvId?: number }>());

export const removeRV = createAction(UserActionTypes.RemoveRV, props<{ rv: IRV; userId?: number }>());

export const updateRV = createAction(UserActionTypes.UpdateRV, props<{ userId?: number; rv: IRV }>());

export const selectRV = createAction(UserActionTypes.SelectRV, props<{ rv: IRV | undefined }>());

export const addTax = createAction(UserActionTypes.AddTax);

export const removeTax = createAction(UserActionTypes.RemoveTax, props<{ tax: ITax }>());

export const updateTax = createAction(UserActionTypes.UpdateTax, props<{ tax: ITax }>());

export const selectTax = createAction(UserActionTypes.SelectTax, props<{ tax: ITax | null }>());

export const updateUser = createAction(UserActionTypes.UpdateUser, props<{ user: IUser }>());

export const userUpdated = createAction(UserActionTypes.UserUpdated, props<{ user: IUser }>());

export const userUpdateError = createAction(
  UserActionTypes.UserUpdateError,
  props<{ error: HttpErrorResponse | string }>(),
);

export const delayedUploadUserImage = createAction(
  '[User] Upload User Image - delayed',
  props<{ formData: FormData; data?: { id?: number } }>(),
);

export const uploadUserImage = createAction(
  UserActionTypes.UploadUserImage,
  props<{ formData: FormData; data?: { id?: number } }>(),
);

export const userImageUploaded = createAction(UserActionTypes.UserImageUploaded, props<IUser>());

export const userImageUploadError = createAction(
  UserActionTypes.UserImageUploadError,
  props<{ error: HttpErrorResponse | string }>(),
);

export const removeUserImage = createAction(UserActionTypes.RemoveUserImage);

export const userImageRemoved = createAction(UserActionTypes.UserImageRemoved, props<{ user: IUser }>());

export const userImageRemoveError = createAction(
  UserActionTypes.UserImageRemoveError,
  props<{ error: HttpErrorResponse | string }>(),
);

export const uploadRvImage = createAction(
  UserActionTypes.UploadRvImage,
  props<{ formData: FormData; data: { id: number; userId?: number } }>(),
);

export const rvImageUploaded = createAction(UserActionTypes.RvImageUploaded, props<IUser>());

export const rvImageUploadError = createAction(
  UserActionTypes.RvImageUploadError,
  props<{ error: HttpErrorResponse | string }>(),
);

export const removeRvImage = createAction(UserActionTypes.RemoveRvImage, props<{ rvId: number; index: number }>());

export const rvImageRemoved = createAction(UserActionTypes.RvImageRemoved, props<{ user: IUser }>());

export const rvImageRemoveError = createAction(
  UserActionTypes.RvImageRemoveError,
  props<{ error: HttpErrorResponse | string }>(),
);

export const loginUser = createAction(UserActionTypes.LoginUser, props<{ payload: ICreateUser }>());

export const loadUserFavorites = createAction(UserActionTypes.LoadUserFavorites, props<{ user: IUser }>());

export const userFavoritesLoaded = createAction(
  UserActionTypes.UserFavoritesLoaded,
  props<{ id: number; poiFavorites: IPoiFavorite[]; siteFavorites: ISiteFavorite[] }>(),
);

export const setInProgressSite = createAction(UserActionTypes.SetInProgressSite, props<{ site: ISite; user: IUser }>());

export const registerPushToken = createAction(UserActionTypes.RegisterPushToken, props<{ token: string }>());

export const registerPushTokenSuccess = createAction(UserActionTypes.RegisterPushTokenSuccess);

export const registerPushTokenFailed = createAction(
  UserActionTypes.RegisterPushTokenFailed,
  props<{ error: string | HttpErrorResponse }>(),
);

export const loadAccountBalanceTransactions = createAction(
  UserActionTypes.LoadAccountBalanceTransactions,
  props<{ user: IUser }>(),
);

export const loadAccountBalanceTransactionsSuccess = createAction(
  UserActionTypes.LoadAccountBalanceTransactionsSuccess,
  props<{ user: IUser; transactions: ICurbNTurfCash[] }>(),
);

export const loadAccountBalanceTransactionsFailed = createAction(
  UserActionTypes.LoadAccountBalanceTransactionsFailed,
  props<{ error: string | HttpErrorResponse }>(),
);

export const loadCashTransactions = createAction(UserActionTypes.LoadCashTransactions, props<{ user: IUser }>());

export const loadCashTransactionsSuccess = createAction(
  UserActionTypes.LoadCashTransactionsSuccess,
  props<{ user: IUser; transactions: ICurbNTurfCash[] }>(),
);

export const loadCashTransactionsFailed = createAction(
  UserActionTypes.LoadCashTransactionsFailed,
  props<{ error: string | HttpErrorResponse }>(),
);

export const updateCurrentUserLocally = createAction(
  UserActionTypes.UpdateCurrentUserLocally,
  props<{ user: IUser }>(),
);
