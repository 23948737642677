import { IPriceComponent, ISite, ITax, IUser } from '../interfaces';

export class Tax implements ITax {
  id: number;
  user?: IUser;
  name: string;
  rate: number;
  sites?: ISite[];
  priceComponents?: IPriceComponent[];
  deleted?: boolean;
  deletedDate?: number;

  constructor(data?: ITax) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: ITax): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key) && key !== 'rate') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (data.rate) {
      if (typeof data.rate === 'string') {
        this.rate = parseFloat(data.rate);
      } else {
        this.rate = data.rate;
      }
    } else {
      this.rate = 0;
    }
  }

  toString(): string {
    return `${this.name} ${this.rate}%`;
  }
}
