import { ChecklistState } from '@curbnturf/checklist/src/lib/+state/checklist.reducer';
import { IChecklist } from '@curbnturf/entities';
import { IStorageSerializer } from '../storage-serializer.interface';
import { DateTime } from 'luxon';

export class ChecklistSerializer implements IStorageSerializer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serialize(state: ChecklistState): any {
    return {
      ...state,
      checklists: state.checklists.map((list: IChecklist) => {
        return {
          ...list,
          lastCompleted: list.lastCompleted instanceof DateTime ? list.lastCompleted?.toISO() : '',
        };
      }),
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unserialize(state: any): ChecklistState {
    return {
      ...state,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      checklists: state.checklists.map((el: any) => {
        return {
          ...el,
          lastCompleted: el.lastCompleted?.length > 0 ? DateTime.fromISO(el.lastCompleted) : undefined,
        };
      }),
    };
  }
}
