import { ILatLon, IRoutePoint, ITrip, RoutePoint } from '@curbnturf/entities';
import { Action, createReducer, on } from '@ngrx/store';
import * as TripPlannerActions from './trip-planner.actions';

export const TRIP_PLANNER_FEATURE_KEY = 'tripPlanner';

export interface TripPlannerState {
  trip: IRoutePoint[];
  route: ILatLon[];
  buffer: ILatLon[];
  saved?: ITrip[];
  distance?: number;
  corridor: number;
  tripPlannerOpen: boolean;
}

export const initialState: TripPlannerState = {
  // set initial required properties
  trip: [],
  route: [],
  buffer: [],
  saved: [],
  corridor: 50,
  tripPlannerOpen: false,
};

export const tripPlannerReducer = createReducer(
  initialState,
  on(TripPlannerActions.init, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(TripPlannerActions.newTrip, (state, { trip }) => {
    let newTrip: RoutePoint[] = [];
    if (trip) {
      newTrip = trip.map((el) => new RoutePoint({ ...el }));
    }

    // Fix origins and destination flags.
    newTrip.forEach((el, index) => {
      if (index === 0) {
        el.origin = true;
        el.destination = false;
      } else if (index === newTrip.length - 1) {
        el.destination = true;
        el.origin = false;
      } else {
        el.origin = false;
        el.destination = false;
      }
    });

    return {
      ...state,
      trip: newTrip,
    };
  }),
  on(TripPlannerActions.addRoutePoint, (state, { point }) => {
    const routePoint = new RoutePoint({ ...point });
    const trip: IRoutePoint[] = state.trip.map((el) => new RoutePoint(el));
    if (routePoint.origin) {
      const currentOriginIndex = trip.findIndex((el) => el.origin === true);
      if (currentOriginIndex !== -1) {
        trip[currentOriginIndex].origin = false;
      }
    }
    if (routePoint.origin) {
      trip.unshift(routePoint);
    } else {
      // Make the new point the destination point.
      trip.forEach((el) => {
        el.destination = false;
      });
      routePoint.destination = true;

      trip.push(routePoint);
    }

    return {
      ...state,
      trip,
    };
  }),
  on(TripPlannerActions.reorderPoint, (state, { source, destination }) => {
    const trip: IRoutePoint[] = state.trip.map((el) => new RoutePoint(el));
    const insertPoint = trip.splice(source, 1).pop();
    if (insertPoint) {
      trip.splice(destination, 0, insertPoint);
    }

    // Fix origins and destination flags.
    trip.forEach((el, index) => {
      if (index === 0) {
        el.origin = true;
        el.destination = false;
      } else if (index === trip.length - 1) {
        el.destination = true;
        el.origin = false;
      } else {
        el.origin = false;
        el.destination = false;
      }
    });

    return {
      ...state,
      trip,
    };
  }),
  on(TripPlannerActions.removeRoutePoint, (state, { point }) => {
    const foundIndex = state.trip.findIndex((el) => el === point);
    const trip: IRoutePoint[] = state.trip.map((el) => new RoutePoint(el));
    if (foundIndex !== -1) {
      trip.splice(foundIndex, 1);
    }

    // Fix origins and destination flags.
    trip.forEach((el, index) => {
      if (index === 0) {
        el.origin = true;
        el.destination = false;
      } else if (index === trip.length - 1) {
        el.destination = true;
        el.origin = false;
      } else {
        el.origin = false;
        el.destination = false;
      }
    });

    return {
      ...state,
      trip,
    };
  }),
  on(TripPlannerActions.setPointActivity, (state, { point, active }) => {
    const trip: IRoutePoint[] = state.trip.slice();
    const foundIndex = trip.findIndex((el) => el === point);
    if (foundIndex !== -1) {
      const newTrip = new RoutePoint({ ...trip[foundIndex], isActive: active });
      trip.splice(foundIndex, 1, newTrip);
    }

    return {
      ...state,
      trip,
    };
  }),
  on(TripPlannerActions.updateRoutePoint, (state, { point }) => {
    const trip: IRoutePoint[] = state.trip.slice();
    const foundIndex = trip.findIndex((el) => el === point);
    if (foundIndex !== -1) {
      trip.splice(foundIndex, 1, { ...point });
    }

    return {
      ...state,
      trip,
    };
  }),
  on(TripPlannerActions.setCorridor, (state, { range }) => ({
    ...state,
    corridor: range,
  })),
  on(TripPlannerActions.setRoute, (state, { route }) => {
    if (!route) {
      route = [];
    }

    return {
      ...state,
      route,
    };
  }),
  on(TripPlannerActions.setTripPlannerPanelState, (state, { open }) => {
    return {
      ...state,
      tripPlannerOpen: open,
    };
  }),
  on(TripPlannerActions.setBuffer, (state, { buffer }) => {
    if (!buffer) {
      buffer = [];
    }

    return {
      ...state,
      buffer,
    };
  }),
  on(TripPlannerActions.setDistance, (state, { distance }) => {
    return {
      ...state,
      distance,
    };
  }),
  on(TripPlannerActions.setDates, (state, { point, arrival, departure }) => {
    const trip: IRoutePoint[] = state.trip.slice();
    const foundIndex = trip.findIndex((el) => el === point);
    if (foundIndex !== -1) {
      // Innocent until proven guilty.
      let validDates = true;

      // Check for invalid dates
      trip.forEach((routePoint) => {
        if (routePoint.arrival && routePoint.departure && point !== routePoint) {
          if (routePoint.departure.diff(arrival).as('seconds') > 0) {
            if (routePoint.arrival.diff(departure).as('seconds') < 0) {
              validDates = false;
            }
          }
          if (routePoint.arrival.diff(departure).as('seconds') > 0) {
            if (routePoint.departure.diff(arrival).as('seconds') < 0) {
              validDates = false;
            }
          }
        }
      });

      if (validDates) {
        const newPoint = new RoutePoint({ ...trip[foundIndex], arrival, departure });
        trip.splice(foundIndex, 1, newPoint);
      }
    }

    return {
      ...state,
      trip,
    };
  }),
  on(TripPlannerActions.loadTripsSuccess, (state, { trips }) => {
    return {
      ...state,
      saved: [...trips],
    };
  }),
  on(TripPlannerActions.clearPointDates, (state, { point }) => {
    const trip: IRoutePoint[] = state.trip.slice();
    const foundIndex = trip.findIndex((el) => el === point);
    if (foundIndex !== -1) {
      // Innocent until proven guilty.
      // todo: validDates is never modified so it seems it should be removed but not sure why it was added in the first
      // todo: place was there something in progress that needs to be finished or should it be removed?
      let validDates = true;

      if (validDates) {
        const newPoint = new RoutePoint({ ...trip[foundIndex], arrival: undefined, departure: undefined });
        trip.splice(foundIndex, 1, newPoint);
      }
    }

    return {
      ...state,
      trip,
    };
  }),
);

export function reducer(state: TripPlannerState | undefined, action: Action) {
  return tripPlannerReducer(state, action);
}
