declare const Buffer: any;

import { IPhoto } from '@curbnturf/objects';
import { toPrecision } from './math';
import { ISharpOptions } from './sharp-options.interface';

const format = (base: string, photo: IPhoto, edits: ISharpOptions): string => {
  const imageRequest = JSON.stringify({
    key: photo.key,
    edits: {
      toFormat: 'jpeg',
      ...edits,
    },
  });

  if (eval('typeof btoa') !== 'undefined') {
    return `${base}/${btoa(imageRequest)}`;
  }

  if (eval('typeof Buffer') !== 'undefined') {
    return `${base}/${Buffer.from(imageRequest).toString('base64')}`;
  }

  return '';
};

export const getPhotoUrl = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 1270 } });
};

export const getPhotoUrlFullSize = (base: string, photo: IPhoto) => {
  return format(base, photo, {});
};

export const getPhotoUrlOriginal = (base: string, photo: IPhoto) => {
  return format(base, photo, { resize: { width: 1920 } });
};

export const getPhotoUrlMedium = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 600, height: 233 } });
};

export const getPhotoUrl3x4Large = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 1116, height: 837 } });
};

export const getPhotoUrl3x4Medium = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 472, height: 275 } });
};

export const getPhotoUrl3x4Small = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 372, height: 279 } });
};

export const getPhotoUrl3x4Thumbnail = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 172, height: 129 } });
};

export const getPhotoUrlSquareMedium = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 330, height: 330 } });
};

export const getPhotoUrlSquareSmall = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 200, height: 200 } });
};

export const getPhotoUrlSquareBlogThumbnail = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 132, height: 132 } });
};

export const getPhotoUrlSquareThumbnail = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 72, height: 72 } });
};

export const getPhotoUrlSquareTiny = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 48, height: 48 } });
};

export const getPhotoUrlFlatMedium = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 624, height: 250 } });
};

export const getPhotoUrlFlatSmall = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 372, height: 161 } });
};

export const getPhotoUrlFlatThumbnail = (base: string, photo: IPhoto) => {
  let edits = createCropEdit(photo);
  return format(base, photo, { ...edits, resize: { width: 183, height: 79 } });
};

export const getPhotoUrlTitleCropped = (base: string, photo: IPhoto) => {
  return format(base, photo, {
    resize: { width: 2000 },
    extract: { left: 700, top: 100, width: 1270, height: 546 },
  });
};

const createCropEdit = (photo: IPhoto) => {
  let cropTop, cropLeft, cropBottom, cropRight;
  cropTop = toPrecision(photo.cropTop || 0, 0);
  cropLeft = toPrecision(photo.cropLeft || 0, 0);
  cropBottom = toPrecision(photo.cropBottom || 0, 0);
  cropRight = toPrecision(photo.cropRight || 0, 0);

  // rotate: null triggers the rotation to any exif settings
  // todo: these changes don't keep track of any image rotations that are manually done.
  const edits: ISharpOptions = { rotate: null };
  if (
    cropLeft !== undefined &&
    cropTop !== undefined &&
    cropRight !== undefined &&
    cropBottom !== undefined &&
    cropBottom > 0 &&
    cropRight > 0
  ) {
    if (
      cropLeft >= 0 &&
      cropTop >= 0 &&
      cropRight > 0 &&
      cropBottom > 0 &&
      cropRight > cropLeft &&
      cropBottom > cropTop
    ) {
      edits.extract = {
        left: cropLeft,
        top: cropTop,
        width: cropRight - cropLeft,
        height: cropBottom - cropTop,
      };
    }
  }
  return edits;
};
