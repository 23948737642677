import { NGRX_BUCKETS, User } from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AddressBookState } from './address-book.reducer';

// Lookup the 'AddressBook' feature state managed by NgRx
export const getAddressBookState = createFeatureSelector<AddressBookState>(NGRX_BUCKETS.addressBook);

const getError = createSelector(getAddressBookState, (state: AddressBookState) => state.error);

const getAllContacts = createSelector(getAddressBookState, (state: AddressBookState) => {
  return Object.keys(state.contacts).map<User>((syncId) => new User(state.contacts[syncId]));
});

const getAllContactEntities = createSelector(getAddressBookState, (state: AddressBookState) => {
  const instantiatedUsers: { [syncId: string]: User } = {};
  for (const key in state.contacts) {
    if (state.contacts.hasOwnProperty(key)) {
      instantiatedUsers[key] = new User(state.contacts[key]);
    }
  }

  return instantiatedUsers;
});

export const addressBookQuery = {
  getError,
  getAllContacts,
  getAllContactEntities,
};
