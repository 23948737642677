import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { SiteSvgsComponent } from '@curbnturf/custom-svgs/src/lib/site-svgs/site-svgs.component';
import {
  COGNITO_CALLBACK,
  COGNITO_CALLBACK_APP,
  COGNITO_CALLBACK_IOS,
  COGNITO_CLIENT_ID,
  COGNITO_DOMAIN,
} from '@curbnturf/entities';
import { Logger, SafariViewWrapperService } from '@curbnturf/network';
import { PlatformService } from '@curbnturf/network/src/lib/platform/platform.service';
import { UrlControlFacade } from '@curbnturf/url-control/src/lib/+state/url-control.facade';
import { AlertController, IonicModule } from '@ionic/angular';

// Sign In With Apple Modal Dependencies
const ENABLE_SIGN_IN_WITH_APPLE = false;

@Component({
  selector: 'curbnturf-social-auth',
  templateUrl: './social-auth.component.html',
  styleUrls: ['./social-auth.component.scss'],
  standalone: true,
  imports: [IonicModule, NgClass, SiteSvgsComponent, NgIf],
})
export class SocialAuthComponent implements OnInit {
  ios: boolean;

  get facebookUrl() {
    return this.buildSocialLoginLink('Facebook');
  }

  get googleUrl() {
    return this.buildSocialLoginLink('Google');
  }

  get appleUrl() {
    return this.buildSocialLoginLink('SignInWithApple');
  }

  constructor(
    private alertController: AlertController,
    private logger: Logger,
    private platformService: PlatformService,
    private router: Router,
    private safariViewController: SafariViewWrapperService,
    private urlControlFacade: UrlControlFacade,
  ) {}

  ngOnInit() {
    this.ios = this.platformService.isIOS() || this.platformService.isIOSWeb();

    const url = this.router.url;
    if (url !== '' && url !== '/' && !url.startsWith('/auth')) {
      this.urlControlFacade.redirectUrl$.subscribe((savedUrl) => {
        if (!savedUrl) {
          this.urlControlFacade.setRedirectUrl(this.router.url);
        }
      });
    }
  }

  async onSocialLogin(event: Event, loginType: string) {
    event.preventDefault();
    let socialLink: string;

    switch (loginType.toLowerCase()) {
      case 'apple':
        socialLink = this.appleUrl;

        // Quick Apple Sign In Feature Gate
        if (ENABLE_SIGN_IN_WITH_APPLE) {
          if (this.platformService.isIOS()) {
            await this.signInWithApple();
            return;
          }
        }
        break;
      case 'facebook':
        socialLink = this.facebookUrl;
        break;
      case 'google':
        socialLink = this.googleUrl;
        break;
      default:
        socialLink = '';
        break;
    }

    // Check for Physical Device & for now restrict to iOS
    if (this.platformService.isPhysicalDevice() && this.platformService.isIOS()) {
      // Check for the availability of the Safari View Controller
      this.safariViewController.isAvailable().then(
        (available: boolean) => {
          if (available) {
            this.safariViewController.launchBrowserUI(socialLink, () => {});
          } else {
            this.logger.error('Attempted Safari View Controller, but was not available.');
            // Fall back to browser link
            window.location.href = socialLink;
          }
        },
        (e) => {
          this.logger.error('Attempted Safari View Controller, but an error occurred.', e);
        },
      );
    } else {
      // Launch the redirect to social login.
      window.location.href = socialLink;
    }
  }

  private async signInWithApple() {
    SignInWithApple.authorize()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then(async (res: { response: { identityToken: any } }) => {
        if (res.response && res.response.identityToken) {
          // @todo do something else
          // this.user = res.response;
          console.log(res);
        } else {
          await this.loginFailureAlert();
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch(async (err: any) => {
        console.error(err);
        await this.loginFailureAlert();
      });
  }

  private async loginFailureAlert() {
    const alert = await this.alertController.create({
      header: 'Login Failed',
      message: 'Please try again later',
      buttons: ['OK'],
    });
    await alert.present();
  }

  private buildSocialLoginLink(provider: string) {
    let callback = COGNITO_CALLBACK;
    if (this.platformService.isPhysicalDevice()) {
      if (this.platformService.isIOS()) {
        callback = COGNITO_CALLBACK_IOS;
      } else {
        callback = COGNITO_CALLBACK_APP;
      }
    }

    return (
      `https://${COGNITO_DOMAIN}/oauth2/authorize?redirect_uri=${callback}` +
      `&response_type=code&client_id=${COGNITO_CLIENT_ID}&identity_provider=${provider}`
    );
  }
}
