import { Component } from '@angular/core';
import { RegisterComponent } from '@curbnturf/auth-components/src/lib/register/register.component';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'curbnturf-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss'],
  standalone: true,
  imports: [IonicModule, RegisterComponent],
})
export class RegisterModalComponent {}
