import { IOrder, IReservation, IReservationWhere, Status } from '@curbnturf/entities';
import { Action, createAction, props } from '@ngrx/store';

export enum ReservationActionTypes {
  LoadReservation = '[Reservation] Load Reservation',
  UpdateReservationStatus = '[Reservation] Update Reservation Status',
  ReservationLoaded = '[Reservation] Reservation Loaded',
  ReservationLoadError = '[Reservation] Reservation Load Error',
  SelectReservation = '[Reservation] Select Reservation',
  LoadGuestReservations = '[Reservation] Load Guest Reservations',
  LoadHostReservations = '[Reservation] Load Host Reservations',
  LoadAllReservations = '[Reservation] Load All Reservations',
  ReservationsLoaded = '[Reservation] Reservations Loaded',
  CreateReservation = '[Reservation] Create Reservation',
  AcceptReservation = '[Reservation] Accept Reservation',
  RejectReservation = '[Reservation] Reject Reservation',
  CancelReservation = '[Reservation] Cancel Reservation',
  ChangeReservation = '[Reservation] Change Reservation',
  ChangeReservationSuccess = '[Reservation] Change Reservation Success',
  ChangeReservationFailure = '[Reservation] Change Reservation Failure',
  SetReservations = '[Reservation] Set Reservation List',
  EmptyReservations = '[Reservation] Empty Reservations',
  SetReservationRange = '[Reservation] Set Reservation Date Range',
}

export class LoadReservation implements Action {
  readonly type = ReservationActionTypes.LoadReservation;
  constructor(public payload?: number) {} // reservationId
}

export const loadReservation = createAction(ReservationActionTypes.LoadReservation, props<{ payload: number }>());

export class UpdateReservationStatus implements Action {
  readonly type = ReservationActionTypes.UpdateReservationStatus;
  constructor(public payload: { reservationId: number; status: Status }) {}
}

export class ReservationLoadError implements Action {
  readonly type = ReservationActionTypes.ReservationLoadError;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public payload: any) {}
}

export const reservationLoadError = createAction(
  ReservationActionTypes.ReservationLoadError,
  props<{ payload: any }>(),
);

export class ReservationLoaded implements Action {
  readonly type = ReservationActionTypes.ReservationLoaded;
  constructor(public payload: IReservation) {}
}

export const reservationLoaded = createAction(
  ReservationActionTypes.ReservationLoaded,
  props<{ payload: IReservation }>(),
);

export class SelectReservation implements Action {
  readonly type = ReservationActionTypes.SelectReservation;
  constructor(public payload?: IReservation) {}
}

export const selectReservation = createAction(
  ReservationActionTypes.SelectReservation,
  props<{ payload: IReservation }>(),
);

export class LoadGuestReservations implements Action {
  readonly type = ReservationActionTypes.LoadGuestReservations;
}

export class LoadHostReservations implements Action {
  readonly type = ReservationActionTypes.LoadHostReservations;
}

export const loadHostReservations = createAction(ReservationActionTypes.LoadHostReservations);

export class LoadAllReservations implements Action {
  readonly type = ReservationActionTypes.LoadAllReservations;
  constructor(public payload?: IReservationWhere) {}
}

export class ReservationsLoaded implements Action {
  readonly type = ReservationActionTypes.ReservationsLoaded;
  constructor(public payload: IReservation[]) {}
}

export const reservationsLoaded = createAction(
  ReservationActionTypes.ReservationsLoaded,
  props<{ payload: IReservation[] }>(),
);

export class CreateReservation implements Action {
  readonly type = ReservationActionTypes.CreateReservation;
  constructor(public payload: IReservation) {}
}

export class AcceptReservation implements Action {
  readonly type = ReservationActionTypes.AcceptReservation;
  constructor(public payload: { reservation: IReservation; reason: string }) {}
}

export const acceptReservation = createAction(
  ReservationActionTypes.AcceptReservation,
  props<{ payload: { reservation: IReservation; reason: string } }>(),
);

export class RejectReservation implements Action {
  readonly type = ReservationActionTypes.RejectReservation;
  constructor(public payload: { reservation: IReservation; reason: string }) {}
}

export class CancelReservation implements Action {
  readonly type = ReservationActionTypes.CancelReservation;
  constructor(public payload: { reservation: IReservation; reason: string }) {}
}

export const changeReservation = createAction(
  ReservationActionTypes.ChangeReservation,
  props<{ reservation: IReservation; order: IOrder }>(),
);

export class ChangeReservationSuccess implements Action {
  readonly type = ReservationActionTypes.ChangeReservationSuccess;
  constructor(public reservation: IReservation) {}
}

export const changeReservationSuccess = createAction(
  ReservationActionTypes.ChangeReservationSuccess,
  props<{ reservation: IReservation }>(),
);

export const changeReservationFailure = createAction(
  ReservationActionTypes.ChangeReservationFailure,
  props<{ error: any }>(),
);

export class SetReservations implements Action {
  readonly type = ReservationActionTypes.SetReservations;
  constructor(public payload: { reservations: IReservation[] }) {}
}

export class EmptyReservations implements Action {
  readonly type = ReservationActionTypes.EmptyReservations;
}

export class SetReservationRange implements Action {
  readonly type = ReservationActionTypes.SetReservationRange;
  constructor(public payload: string) {}
}
export type ReservationAction =
  | LoadReservation
  | UpdateReservationStatus
  | ReservationLoaded
  | ReservationLoadError
  | SelectReservation
  | LoadGuestReservations
  | LoadHostReservations
  | LoadAllReservations
  | ReservationsLoaded
  | CreateReservation
  | AcceptReservation
  | RejectReservation
  | CancelReservation
  | ChangeReservationSuccess
  | SetReservations
  | EmptyReservations
  | SetReservationRange;

export const fromReservationActions = {
  LoadReservation,
  UpdateReservationStatus,
  ReservationLoaded,
  ReservationLoadError,
  SelectReservation,
  LoadGuestReservations,
  LoadHostReservations,
  LoadAllReservations,
  ReservationsLoaded,
  CreateReservation,
  AcceptReservation,
  RejectReservation,
  CancelReservation,
  ChangeReservationSuccess,
  SetReservations,
  EmptyReservations,
  SetReservationRange,
};
