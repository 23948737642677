import { IActivityLocation, IPoiRating } from '../interfaces';
import { Rating } from './rating';
import { User } from './user';

export class PoiRating extends Rating implements IPoiRating {
  poiRated?: IActivityLocation;
  poiRatedId: number;

  constructor(data?: IPoiRating) {
    super(data);

    if (data) {
      this.setData(data);
    }
  }

  setData(data: IPoiRating): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (this.creator) {
      this.creator = new User(this.creator);
    }
  }
}
