import { NgFor } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { forceInt } from '@curbnturf/helpers';
import { DateTime } from 'luxon';
import { getMonthFullName, getMonthShortName } from './datepicker-tools';

@Component({
  selector: 'curbnturf-datepicker-navigation-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./datepicker-navigation-select.scss'],
  template: `
    <select
      #month
      [disabled]="disabled"
      i18n-aria-label="@@ngb.datepicker.select-month"
      aria-label="Select month"
      i18n-title="@@ngb.datepicker.select-month"
      title="Select month"
      (change)="changeMonth($any($event).target.value)"
      class="custom-select"
    >
      <option *ngFor="let m of months" [attr.aria-label]="getMonthFullName(m, date?.year)" [value]="m">
        {{ getMonthShortName(m, date?.year) }}
      </option>
    </select>
    <select
      #year
      [disabled]="disabled"
      i18n-aria-label="@@ngb.datepicker.select-year"
      aria-label="Select year"
      i18n-title="@@ngb.datepicker.select-year"
      title="Select year"
      (change)="changeYear($any($event).target.value)"
      class="custom-select"
    >
      <option *ngFor="let y of years" [value]="y">
        {{ y }}
      </option>
    </select>
  `,
  standalone: true,
  imports: [NgFor, FormsModule],
})
export class DatepickerNavigationSelect implements AfterViewChecked {
  @Input() date: DateTime;
  @Input() disabled: boolean;
  @Input() months: number[];
  @Input() years: number[];

  @Output() select = new EventEmitter<DateTime>();

  @ViewChild('month', { static: true, read: ElementRef })
  monthSelect: ElementRef;
  @ViewChild('year', { static: true, read: ElementRef }) yearSelect: ElementRef;

  getMonthFullName = getMonthFullName;
  getMonthShortName = getMonthShortName;
  private _month = -1;
  private _year = -1;

  constructor(private _renderer: Renderer2) {}

  changeMonth(month: string) {
    this.select.emit(DateTime.fromObject({ year: this.date.year, month: forceInt(month), day: 1 }));
  }

  changeYear(year: string) {
    this.select.emit(DateTime.fromObject({ year: forceInt(year), month: this.date.month, day: 1 }));
  }

  ngAfterViewChecked() {
    if (this.date) {
      if (this.date.month && this.date.month !== this._month) {
        this._month = this.date.month;
        this._renderer.setProperty(this.monthSelect.nativeElement, 'value', this._month);
      }
      if (this.date.year && this.date.year !== this._year) {
        this._year = this.date.year;
        this._renderer.setProperty(this.yearSelect.nativeElement, 'value', this._year);
      }
    }
  }
}
