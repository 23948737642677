import { Injectable } from '@angular/core';
import { PlatformService } from '@curbnturf/network/src/lib/platform/platform.service';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as AuthModalActions from './auth-modal.actions';
import { AuthModalState } from './auth-modal.reducer';
import { authModalQuery } from './auth-modal.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthModalFacade {
  modal$: Observable<string | undefined>;

  constructor(private store: Store<AuthModalState>, private platformService: PlatformService) {
    this.modal$ = this.store.pipe(select(authModalQuery.getModal));
  }

  openAuthModal(modal?: string) {
    // The app doesn't use modals.
    if (!this.platformService.isAppContext()) {
      this.store.dispatch(AuthModalActions.openAuthModal({ modal }));
    }
  }

  closeAuthModal() {
    this.store.dispatch(AuthModalActions.closeAuthModal());
  }
}
