<div class="new">
  New to CurbNTurf?
  <a (click)="openRegisterModal()" *ngIf="inModal" class="auth-link">
    <strong class="bold-text">Create account</strong>
  </a>
  <a [routerLink]="['/auth/register']" *ngIf="!inModal" class="auth-link">
    <strong class="bold-text">Create account</strong>
  </a>
</div>
<div class="horz-line white"></div>
<div class="_18-px-spacer"></div>
<curbnturf-social-auth></curbnturf-social-auth>
<div class="_18-px-spacer"></div>
<div class="horz-line white"></div>
<div class="_18-px-spacer"></div>
<h2 class="heading-2 white">Log in here.</h2>
<div class="w-form">
  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <label for="login-email" class="sr-only">Email Address</label>
    <input
      id="login-email"
      type="email"
      class="_48px-text-field w-input"
      formControlName="email"
      [email]="true"
      [required]="true"
      placeholder="Email address"
    />
    <label for="login-password" class="sr-only">Password</label>
    <input
      id="login-password"
      type="password"
      class="_48px-text-field w-input"
      formControlName="password"
      [required]="true"
      placeholder="Password"
    />
    <div class="_12-px-spacer"></div>
    <div class="forgot-password white">
      <a (click)="openRecoverModal()" *ngIf="inModal" class="auth-link">
        <strong> Forgot password? </strong>
      </a>
      <a [routerLink]="['/auth/recover']" *ngIf="!inModal" class="auth-link">
        <strong> Forgot password? </strong>
      </a>
    </div>
    <div class="_18-px-spacer"></div>
    <button type="submit" class="cnt-button nandor w-inline-block">
      <div class="cnt-button-copy">Log in</div>
    </button>
  </form>
</div>
<div class="_18-px-spacer"></div>
