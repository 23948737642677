import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { NGRX_NO_ACTION } from '@curbnturf/entities';
import { ModalController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import * as AuthModalActions from './auth-modal.actions';

// The amount of time to debounce network status change events.
const DEBOUNCE_TIMER = 50;

export const closeAuthModal$ = createEffect(
  (actions$ = inject(Actions), modalController = inject(ModalController), platformId = inject(PLATFORM_ID)) =>
    actions$.pipe(
      ofType(AuthModalActions.closeAuthModal),
      debounceTime(isPlatformServer(platformId) ? 0 : DEBOUNCE_TIMER),
      mergeMap(() => {
        const modalClose = async () => {
          const top = await modalController.getTop();

          if (top) {
            return modalController.dismiss();
          }

          return true;
        };

        try {
          return from(modalClose()).pipe(mergeMap(() => of(NGRX_NO_ACTION)));
        } catch (e) {
          return of(NGRX_NO_ACTION);
        }
      }),
    ),
  { functional: true },
);
