import { RatingType } from '../enums';
import { IGuestRating, IReservation, IScore, IUser } from '../interfaces';
import { Rating } from './rating';
import { User } from './user';

export class GuestRating extends Rating implements IGuestRating {
  guestRated?: IUser;
  guestRatedId?: number;
  reservation?: IReservation;
  reservationId?: number;
  hidden?: boolean;

  constructor(data?: IGuestRating) {
    super(data);

    if (data) {
      this.setData(data);
    }
  }

  setData(data: IGuestRating): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
