import { Directive, ElementRef, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { fromEvent, Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Directive({
  selector: 'form',
  standalone: true,
})
export class DisplayValidationErrorsSubmitDirective {
  submit$: Observable<Event>;

  get element() {
    return this.host?.nativeElement;
  }

  // FormGroupDirective allows accessing the form as long as the form has the formGroup added to it.
  constructor(
    private host: ElementRef<HTMLFormElement>,
    @Optional() public formGroup: FormGroupDirective,
  ) {
    this.submit$ = fromEvent(this.element, 'submit').pipe(
      tap(() => {
        if (this.element && this.element?.classList && this.element?.classList?.contains('submitted') === false) {
          this.element?.classList?.add('submitted');
        }
      }),
      shareReplay(1),
    );
  }
}
