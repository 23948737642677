<div class="_18-px-spacer"></div>
<h2 class="heading-2 white">New Password</h2>
<div class="new">Your password has expired please enter a new one.</div>
<div class="w-form">
  <form [formGroup]="newPasswordForm" (ngSubmit)="resetPassword($event)">
    <label for="repeatPassword" class="sr-only">Repeat Password</label>
    <input
      id="password"
      type="password"
      class="_48px-text-field w-input"
      formControlName="password"
      [required]="true"
      placeholder="Password"
    />
    <div class="_12-px-spacer"></div>
    <label for="repeatPassword" class="sr-only">Repeat Password</label>
    <input
      id="repeatPassword"
      type="password"
      class="_48px-text-field w-input"
      formControlName="repeatPassword"
      [required]="true"
      placeholder="Repeat Password"
    />
    <button type="submit" class="cnt-button nandor w-inline-block">
      <div class="cnt-button-copy">Save Password</div>
    </button>
  </form>
</div>
