import { IAffiliateType, Role, TransactionEvent, TransactionStatus, TransactionType } from '../enums';
import {
  IActivityLocation,
  IAddressChangeRequest,
  IAgritourismCourseEntry,
  IBoondockRating,
  IGuestRating,
  IMessage,
  IMessageChannel,
  IPayout,
  IPoiRating,
  IReservation,
  ISite,
  ISiteRating,
  IStatusUpdate,
  ITrip,
  IUser,
} from '../interfaces';
import { Address } from './address';
import { CurbNTurfCash } from './cash';
import { LatLon } from './coordinates';
import { CoordinateGis } from './coordinates-gis';
import { GuestRating } from './guest-rating';
import { NotificationSetting } from './notification-setting';
import { Photo } from './photo';
import { PoiFavorite } from './poi-favorite';
import { RV } from './rv';
import { SiteFavorite } from './site-favorite';
import { SubPhoto } from './sub-photo';
import { Tax } from './tax';

export class User implements IUser {
  id?: number;
  syncId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  address?: Address;
  billing?: Address;
  coordinates?: CoordinateGis;
  phone?: string;
  photo?: SubPhoto;
  role?: Role;
  source?: string;
  promotion?: string;
  affiliateRef?: string;
  affiliateType?: IAffiliateType['type'];
  description?: string;
  languages?: string;
  collectTaxes?: boolean;
  inProgressId?: number;
  deleted?: boolean;
  deletedDate?: number;
  created?: number;
  welcomed?: boolean;

  // links to other data
  sites?: ISite[];
  trips?: ITrip[];
  createdGuestRatings?: IGuestRating[];
  createdBoondockRatings?: IBoondockRating[];
  createdPoiRatings?: IPoiRating[];
  createdSiteRatings?: ISiteRating[];
  guestRatings?: ISiteRating[];
  reservations?: IReservation[];
  messageChannels?: IMessageChannel[];
  sentMessages?: IMessage[];
  receivedMessages?: IMessage[];
  statusUpdates?: IStatusUpdate[];
  rvs?: RV[];
  selectedRv?: RV;
  taxes?: Tax[];
  cashTransactions?: CurbNTurfCash[];
  accountBalanceTransactions?: CurbNTurfCash[];
  events?: Event[];
  locations?: IActivityLocation[];
  poiFavorites?: PoiFavorite[];
  siteFavorites?: SiteFavorite[];

  // Auth
  adminPassword?: boolean;

  // temporary fields that aren't saved to the database
  authAccessToken?: string;
  authIdToken?: string; // This is a token used for authentication
  authRenewToken?: string; // This is a token used for renewing authentication
  authExpires?: number;
  authenticating?: boolean;
  creating?: boolean;

  // messaging data
  sendEmails?: boolean;
  sendSmss?: boolean;
  sendPushNotifications?: boolean;
  subscriptions?: PushSubscriptionJSON[];

  // generated values
  guestRating?: number;
  guestRatingCount?: number;
  hasListings?: boolean;
  cash?: number;
  cashPending?: number;
  cashPaid?: number;
  accountBalance?: number;
  accountBalancePending?: number;
  accountBalancePaid?: number;
  temporaryPassword?: boolean;

  // Push Notifications
  pushToken?: string;

  // Notification Settings
  notificationSettings?: NotificationSetting[];

  // Agritourism Course information
  agritourismCourse?: IAgritourismCourseEntry;

  // Financial
  payouts?: IPayout[];

  addressChangeRequests?: IAddressChangeRequest[];

  get name(): string {
    return this.getName();
  }

  get nameId(): string {
    return `${this.name} (${this.id})`;
  }

  static getName(user?: IUser): string {
    if (!user) {
      return 'No Name provided';
    }
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    if (user.firstName) {
      return `${user.firstName}`;
    }
    if (user.lastName) {
      return `${user.lastName}`;
    }
    return 'No Name provided';
  }

  static getShortName(user: IUser): string {
    if (!user) {
      return 'No Name provided';
    }
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName.charAt(0)}.`;
    }
    if (user.firstName) {
      return `${user.firstName}`;
    }
    if (user.lastName) {
      return `${user.lastName}`;
    }
    return 'No Name provided';
  }

  static getCurbNTurfCash(user: IUser, type: TransactionType = TransactionType.available): number {
    if (!user || !user.cashTransactions || user.cashTransactions.length === 0) {
      return 0;
    }

    if (type === TransactionType.pending) {
      return user.cashTransactions.reduce(
        (total, transaction) => total + ((transaction.status === TransactionStatus.pending && transaction.value) || 0),
        0,
      );
    }

    if (type === TransactionType.paid) {
      return user.cashTransactions.reduce(
        (total, transaction) =>
          total +
          ((transaction.event === TransactionEvent.withdrawal &&
            transaction.status === TransactionStatus.valid &&
            transaction.value) ||
            0),
        0,
      );
    }

    return user.cashTransactions.reduce(
      (total, transaction) => total + ((transaction.status === TransactionStatus.valid && transaction.value) || 0),
      0,
    );
  }

  static getAccountBalance(user: IUser, type: TransactionType = TransactionType.available): number {
    if (!user || !user.accountBalanceTransactions || user.accountBalanceTransactions.length === 0) {
      return 0;
    }

    if (type === TransactionType.pending) {
      return user.accountBalanceTransactions.reduce(
        (total, transaction) => total + ((transaction.status === TransactionStatus.pending && transaction.value) || 0),
        0,
      );
    }

    if (type === TransactionType.paid) {
      return user.accountBalanceTransactions.reduce(
        (total, transaction) =>
          total +
          ((transaction.event === TransactionEvent.withdrawal &&
            transaction.status === TransactionStatus.valid &&
            transaction.value) ||
            0),
        0,
      );
    }

    return user.accountBalanceTransactions.reduce(
      (total, transaction) => total + ((transaction.status === TransactionStatus.valid && transaction.value) || 0),
      0,
    );
  }

  static isEqual(userA?: IUser, userB?: IUser): boolean {
    return JSON.stringify(userA) === JSON.stringify(userB);
  }

  constructor(data?: IUser) {
    if (data) {
      this.setData(data);
    }
  }

  getName(): string {
    return User.getName(this);
  }

  getShortName(): string {
    return User.getShortName(this);
  }

  getCurbNTurfCash(type: TransactionType): number {
    return User.getCurbNTurfCash(this, type);
  }

  getAccountBalance(type: TransactionType): number {
    return User.getAccountBalance(this, type);
  }

  setData(data: IUser): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key) && key !== 'rvs' && key !== 'taxes') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    this.photo = new Photo(this.photo);

    if (data.rvs) {
      this.rvs = data.rvs.map((rv) => new RV(rv));
    } else {
      this.rvs = [];
    }

    if (this.selectedRv) {
      this.selectedRv = new RV(this.selectedRv);
    }

    if (data.taxes) {
      this.taxes = data.taxes.map((tax) => new Tax(tax));
    } else {
      this.taxes = [];
    }

    if (data.cashTransactions) {
      this.cashTransactions = data.cashTransactions.map((transaction) => new CurbNTurfCash(transaction));
    } else {
      this.cashTransactions = [];
    }

    if (!this.createdGuestRatings) {
      this.createdGuestRatings = [];
    }

    if (!this.createdBoondockRatings) {
      this.createdBoondockRatings = [];
    }

    if (!this.createdPoiRatings) {
      this.createdPoiRatings = [];
    }

    if (!this.createdSiteRatings) {
      this.createdSiteRatings = [];
    }

    if (!this.guestRatings) {
      this.guestRatings = [];
    }

    if (!this.subscriptions) {
      this.subscriptions = [];
    }

    if (!data.poiFavorites) {
      this.poiFavorites = [];
    } else {
      this.poiFavorites = data.poiFavorites.map((poiFavorite) => new PoiFavorite(poiFavorite));
    }

    if (!data.siteFavorites) {
      this.siteFavorites = [];
    } else {
      this.siteFavorites = data.siteFavorites.map((siteFavorite) => new SiteFavorite(siteFavorite));
    }

    this.address = new Address(this.address);

    this.coordinates = new LatLon(this.coordinates);
  }

  getGuestRating(): number {
    if (this.guestRating || this.guestRating === 0) {
      return this.guestRating;
    }

    return GuestRating.calculateRating(this.guestRatings);
  }
}
