import { IAmenityType } from '../enums/amenity-types';
import { IReservation, IRoadCondition, IRV, ISubPhoto, IUser } from '../interfaces';

export class RV implements IRV {
  id?: number;
  syncId?: string;
  user?: IUser;
  userId?: number;
  name?: string;
  year?: number;
  make?: string;
  model?: string;
  type?: string;
  width?: number; // ft
  height?: number; // ft
  length?: number; // ft
  underwayHeight?: number; // ft
  underwayWidth?: number; // ft
  underwayLength?: number; // ft
  clearance?: number; // in
  trailerCount?: number;
  weight?: number;
  axleCount?: number;
  roadCondition?: IRoadCondition;
  tent?: boolean;
  tentCount?: number;
  tentHeight?: number;
  tentWidth?: number;
  tentLength?: number;
  photo?: ISubPhoto;
  deleted?: boolean;
  deletedDate?: number;
  licenseState?: string;
  licensePlate?: string;
  rentalUrl?: string;

  amenities?: IAmenityType['type'][];
  reservations?: IReservation[];

  constructor(data?: IRV) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IRV): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (!this.amenities) {
      this.amenities = [];
    }

    if (!this.reservations) {
      this.reservations = [];
    }
  }
}
