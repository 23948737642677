import { Injectable } from '@angular/core';
import { capSQLiteChanges, capSQLiteValues } from '@capacitor-community/sqlite';
import { CachedHttpResponse, ICachedHttpResponse } from '@curbnturf/entities';
import { DatabaseService } from '../database.service';
import { IDbSet } from '../dbset.interface';
import { CachedHttpResponseEntity } from '../entities/cached-http-response';

const TABLE_NAME = 'cached_http_responses';

@Injectable({
  providedIn: 'root',
})
export class CachedHttpResponsesDbSet implements IDbSet {
  constructor(private databaseService: DatabaseService) {}

  public store(response: ICachedHttpResponse): Promise<capSQLiteValues> {
    return this.databaseService.insert(TABLE_NAME, this.mapObjectsToStorableResponses([response]).pop());
  }

  public storeArray(responses: ICachedHttpResponse[]): Promise<capSQLiteValues> {
    return this.databaseService.insertMulti<CachedHttpResponseEntity>(TABLE_NAME, responses);
  }

  public async truncateAndStoreArray(responses: ICachedHttpResponse[]): Promise<capSQLiteValues> {
    await this.databaseService.execute('DELETE FROM cached_http_responses;');
    const { statement, values } = this.databaseService.generateInsertStatement<CachedHttpResponseEntity>(
      TABLE_NAME,
      responses,
    );
    return this.databaseService.query(statement, values);
  }

  public async truncate(): Promise<capSQLiteChanges> {
    return await this.databaseService.execute('DELETE FROM cached_http_responses;');
  }

  public async retrieve(syncId: string): Promise<{ values: CachedHttpResponse[] }> {
    const results = await this.databaseService.select(TABLE_NAME, { sync_id: syncId });
    return {
      values: this.mapResultsToCachedHttpResponse(results),
    };
  }

  public async retrieveAll(): Promise<CachedHttpResponse[]> {
    const results = await this.databaseService.selectAll(TABLE_NAME);
    return this.mapResultsToCachedHttpResponse(results);
  }

  public async remove(id: string): Promise<capSQLiteValues> {
    return await this.databaseService.delete(TABLE_NAME, { id });
  }

  private mapResultsToCachedHttpResponse(results: capSQLiteValues): CachedHttpResponse[] {
    if (results.values) {
      return results.values?.map((response) => {
        return new CachedHttpResponse({
          method: response.method ? response.method : undefined,
          body: response.body ? JSON.parse(response.body) : undefined,
          url: response.url ? response.url : undefined,
          response: response.response ? JSON.parse(response.response) : undefined,
        });
      });
    } else {
      return [];
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private mapObjectsToStorableResponses(items: ICachedHttpResponse[]): any[] {
    if (items) {
      return items?.map((response) => {
        return {
          method: response.method ? response.method : '',
          body: response.body ? JSON.stringify(response.body) : undefined,
          url: response.url ? response.url : '',
          response: response.response ? JSON.stringify(response.response) : '',
        };
      });
    } else {
      return [];
    }
  }
}
