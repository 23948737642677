export enum LocationCategory {
  dump = 'Dump Station',
  restArea = 'Rest Stop',
  wifi = 'WiFi',
  potableWater = 'Fresh Water',
  propane = 'Propane',
}

export interface ILocationCategories {
  categories: 'dump' | 'restArea' | 'wifi' | 'potableWater' | 'propane';
}

export const LocationCategories = ['dump', 'restArea', 'wifi', 'potableWater', 'propane'];
