import { DatePipe, NgClass, NgFor } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IMessage, MessageChannel, Reservation, User } from '@curbnturf/entities';
import { MessageFacade } from '@curbnturf/message/src/lib/+state/message.facade';
import { UserFacade } from '@curbnturf/user/src/lib/+state/user.facade';

@Component({
  selector: 'curbnturf-message-chat-box',
  templateUrl: './message-chat-box.component.html',
  styleUrls: ['./message-chat-box.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass, DatePipe],
})
export class MessageChatBoxComponent implements OnInit {
  @Input() channel!: MessageChannel;

  @Input() reservation!: Reservation;

  currentUser: User;
  waitedForUser = 0;

  constructor(
    private messageFacade: MessageFacade,
    private userFacade: UserFacade,
  ) {}

  ngOnInit() {
    this.userFacade.currentUserOnce$.subscribe((user) => (this.currentUser = user));

    this.checkForUnread();
  }

  getMessageDirection(message: IMessage): string {
    if (this.isSender(message)) {
      return 'sent justify-content-end';
    }

    return 'received';
  }

  isSender(message: IMessage): boolean {
    if (!message || !message.from || !this.currentUser) {
      return false;
    }

    return message.from.id === this.currentUser.id;
  }

  private checkForUnread() {
    if (!this.currentUser && this.waitedForUser < 10) {
      setTimeout(() => this.checkForUnread(), 1000);
      this.waitedForUser += 1;
      return;
    }

    let anyUnread = false;

    if (!this.channel || !this.channel.messages) {
      return;
    }

    this.channel.messages.forEach((message) => {
      if (!this.isSender(message) && !message.read) {
        anyUnread = true;
      }
    });

    if (anyUnread) {
      setTimeout(() => {
        if (this.channel) {
          this.messageFacade.read(this.channel);
        }
      }, 1000);
    }
  }
}
