import { moneyDecimal, toPrecision } from '@curbnturf/helpers';
import { APPLIED_CASH_STRING } from '../constants';
import { PriceType } from '../enums';
import { IPriceComponent } from '../interfaces';
import { PriceComponent } from './price-component';

const FEEDBACK_INCENTIVE = 5;

interface ILineItem {
  key: string;
  value: number;
}

export class PriceDisplay {
  tentsAndVehicles: number;
  aLaCarte: number;
  nightlyAverage: number;
  guestGross: number;
  hostGross: number;
  hostNet: number;
  feedbackIncentive: number;
  fees: number;
  tax: number;
  taxes: ILineItem[];
  discounts: ILineItem[];
  nights: ILineItem[];
  extras: ILineItem[];

  constructor(prices: IPriceComponent[], showOriginalPrices: boolean = false) {
    if (prices) {
      // Check for price changes.
      const origPrices = prices.filter((el) => el.pendingChange === false || el.pendingChange === undefined);
      const changePrices = prices.filter((el) => el.pendingChange === true);

      let priceComponents: IPriceComponent[];
      if (changePrices.length > 0 && !showOriginalPrices) {
        priceComponents = changePrices;
      } else {
        priceComponents = origPrices;
      }
      const { nights, extras } = this.splitTypes(priceComponents);

      // number calculations
      this.tentsAndVehicles = this.calculateTentAndVehicles(extras);
      this.aLaCarte = this.calculateAlaCart(extras);
      this.guestGross = this.calculateGuestGross(priceComponents);
      this.hostGross = this.calculateHostGross(priceComponents);
      this.hostNet = this.calculateHostNet(priceComponents);
      this.feedbackIncentive = this.calculateFeedbackIncentive(nights);
      this.fees = toPrecision(this.calculateFees(priceComponents, this.feedbackIncentive), 2);
      this.nightlyAverage = toPrecision(this.calculateNightlyAverage(nights, this.tentsAndVehicles), 2);

      // list calculations
      this.taxes = this.calculateTaxes(priceComponents);
      this.discounts = this.calculateDiscounts(priceComponents);
      this.nights = this.calculateNights(nights);
      this.extras = this.calculateExtras(extras);

      this.tax = this.calculateTaxTotal(this.taxes);
    }
  }

  addCntCash(amount: number): void {
    if (!this.discounts) {
      this.discounts = [];
    }

    this.discounts.push({ key: APPLIED_CASH_STRING, value: -amount });
    this.guestGross -= amount;
  }

  private calculateAlaCart(extras: IPriceComponent[]): number {
    return toPrecision(
      extras.reduce((total, price) => total + (!this.extraIsTentOrVehicle(price) ? price.value : 0), 0),
      2,
    );
  }

  private calculateDiscounts(prices: IPriceComponent[]): ILineItem[] {
    const discountPricesHashes: { [id: string]: ILineItem } = {};

    prices.forEach((price) => {
      if (price.discounts) {
        price.discounts.forEach((discount) => {
          if (!discountPricesHashes[discount.name]) {
            discountPricesHashes[discount.name] = {
              key: discount.name,
              value: 0,
            };
          }

          discountPricesHashes[discount.name].value = toPrecision(
            discountPricesHashes[discount.name].value - discount.value,
            2,
          );
        });
      }
    });

    return Object.keys(discountPricesHashes).map((discount) => discountPricesHashes[discount] as ILineItem);
  }

  private calculateExtras(prices: IPriceComponent[]): ILineItem[] {
    const extraPricesHashes: { [id: string]: ILineItem } = {};

    prices.forEach((price) => {
      if (price && price.extra && price.extra.extra) {
        const extra = price.extra.extra;

        if (extra.id) {
          const extraId = extra.id + '';
          if (!extraPricesHashes[extraId]) {
            let name = `${price.extra.quantity} ${extra.name}${price.extra.quantity > 1 ? 's' : ''}`;

            if (extra.label === 'extraParking') {
              name = `${price.extra.quantity} vehicle${price.extra.quantity > 1 ? 's' : ''}, 1 night`;
            }

            if (extra.label === 'extraTent') {
              name = `${price.extra.quantity} tent${price.extra.quantity > 1 ? 's' : ''}, 1 night`;
            }

            if (extra.label === 'firewood') {
              name = `${price.extra.quantity} firewood`;
            }

            extraPricesHashes[extraId] = {
              key: name || 'Unknown',
              value: 0,
            };
          }

          if (extraPricesHashes[extraId].value) {
            const oldCount = Math.round(extraPricesHashes[extraId].value / price.value);
            if (oldCount === 1) {
              extraPricesHashes[extraId].key = extraPricesHashes[extraId].key.replace('1 night', '2 nights');
            } else {
              extraPricesHashes[extraId].key = extraPricesHashes[extraId].key.replace(
                `${oldCount} nights`,
                `${oldCount + 1} nights`,
              );
            }
          }
          extraPricesHashes[extraId].value = toPrecision(extraPricesHashes[extraId].value + price.value, 2);
        }
      }
    });

    return Object.keys(extraPricesHashes).map((extra) => extraPricesHashes[extra]);
  }

  private calculateFeedbackIncentive(prices: IPriceComponent[]): number {
    return toPrecision(prices.length * FEEDBACK_INCENTIVE, 2);
  }

  private calculateFees(prices: IPriceComponent[], subtractiveFees: number): number {
    return toPrecision(prices.reduce((total, price) => total + (price.fee || 0), 0) - subtractiveFees, 2);
  }

  // includes taxes
  private calculateGuestGross(prices: IPriceComponent[]): number {
    return toPrecision(
      prices.reduce((total, price) => {
        const priceComponent = new PriceComponent(price);

        return total + priceComponent.value - (priceComponent.discount || 0) + (priceComponent.tax || 0);
      }, 0),
      2,
    );
  }

  // excludes taxes
  private calculateHostGross(prices: IPriceComponent[]): number {
    return toPrecision(
      prices.reduce((total, price) => {
        const priceComponent = new PriceComponent(price);

        return total + priceComponent.value - (priceComponent.discount || 0);
      }, 0),
      2,
    );
  }

  // excludes taxes and fees
  private calculateHostNet(prices: IPriceComponent[]): number {
    return toPrecision(
      prices.reduce((total, price) => {
        const priceComponent = new PriceComponent(price);

        return total + priceComponent.value - (priceComponent.discount || 0) - (priceComponent.fee || 0);
      }, 0),
      2,
    );
  }

  private calculateNightlyAverage(nights: IPriceComponent[], tentsAndVehicles: number): number {
    return toPrecision((nights.reduce((total, night) => total + night.value, 0) + tentsAndVehicles) / nights.length, 2);
  }

  private calculateNights(prices: IPriceComponent[]): ILineItem[] {
    const basePricesHashes: { [id: string]: number[] } = {};

    prices.forEach((price) => {
      const valueString = price.value + '';
      if (!basePricesHashes[valueString]) {
        basePricesHashes[valueString] = [];
      }

      basePricesHashes[valueString].push(price.value);
    });

    return Object.keys(basePricesHashes).map((dayPrice: string) => ({
      key: `$${moneyDecimal(parseFloat(dayPrice))} x ${basePricesHashes[dayPrice].length} nights`,
      value: toPrecision(
        basePricesHashes[dayPrice].reduce((total: number, value: number) => total + value, 0) as number,
        2,
      ),
    }));
  }

  private calculateTaxes(prices: IPriceComponent[]): ILineItem[] {
    const taxesHash = prices
      .map((price) => price.taxes)
      .reduce((reducedTaxes, taxes) => {
        if (taxes) {
          taxes.forEach((tax) => {
            if (tax.tax && tax.tax.id) {
              const id = tax.tax.id + '';
              if (!reducedTaxes[id]) {
                reducedTaxes[id] = {
                  key: tax.tax.name,
                  value: 0,
                };
              }

              reducedTaxes[id].value += toPrecision(tax.value, 2);
            }
          });
        }

        return reducedTaxes;
      }, {} as { [id: string]: ILineItem });

    return Object.keys(taxesHash).map((taxId) => taxesHash[taxId]);
  }

  private calculateTaxTotal(taxes: ILineItem[]): number {
    return toPrecision(
      taxes.reduce((total, tax) => total + tax.value, 0),
      2,
    );
  }

  private calculateTentAndVehicles(extras: IPriceComponent[]): number {
    return toPrecision(
      extras.reduce((total, price) => total + (this.extraIsTentOrVehicle(price) ? price.value : 0), 0),
      2,
    );
  }

  private extraIsTentOrVehicle(price?: IPriceComponent): boolean {
    if (!price || !price.extra || !price.extra.extra) {
      return false;
    }

    const extra = price.extra.extra;
    return extra.label === 'extraParking' || extra.label === 'extraTent';
  }

  private splitTypes(prices: IPriceComponent[]): {
    nights: PriceComponent[];
    extras: PriceComponent[];
  } {
    const nights: PriceComponent[] = [];
    const extras: PriceComponent[] = [];

    prices.forEach((price) => {
      if (price.type === PriceType.extra && price.extra && price.extra.extra) {
        extras.push(new PriceComponent(price));
      } else {
        nights.push(new PriceComponent(price));
      }
    });

    return { nights, extras };
  }
}
