import {
  BoondockRating,
  GuestRating,
  IBoondockRating,
  IGuestRating,
  IPoiRating,
  ISiteRating,
  NGRX_BUCKETS,
  PoiRating,
  SiteRating,
} from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RatingState } from './rating.reducer';

// Lookup the 'Rating' feature state managed by NgRx
const getRatingState = createFeatureSelector<RatingState>(NGRX_BUCKETS.rating);

const getLoaded = createSelector(getRatingState, (state: RatingState) => state.loaded);

const getError = createSelector(getRatingState, (state: RatingState) => state.error);

const getCaretakerSites = createSelector(getRatingState, (state: RatingState) => state.caretakerSites);

const getAllSiteRatingEntities = createSelector(getRatingState, getLoaded, (state: RatingState, isLoaded) => {
  if (isLoaded) {
    const instantiatedRatings: { [syncId: string]: ISiteRating } = {};
    for (const key in state.siteRatings) {
      if (state.siteRatings.hasOwnProperty(key)) {
        instantiatedRatings[key] = new SiteRating(state.siteRatings[key]);
      }
    }

    return instantiatedRatings;
  }

  return {};
});

const getAllSiteRatings = createSelector(getRatingState, getLoaded, (state: RatingState, isLoaded) => {
  return isLoaded
    ? Object.keys(state.siteRatings).map<ISiteRating>((id) => new SiteRating(state.siteRatings[parseInt(id, 10)]))
    : [];
});

const getAllGuestRatingEntities = createSelector(getRatingState, getLoaded, (state: RatingState, isLoaded) => {
  if (isLoaded) {
    const instantiatedRatings: { [syncId: string]: IGuestRating } = {};
    for (const key in state.guestRatings) {
      if (state.guestRatings.hasOwnProperty(key)) {
        instantiatedRatings[key] = new GuestRating(state.guestRatings[key]);
      }
    }

    return instantiatedRatings;
  }

  return {};
});

const getAllGuestRatings = createSelector(getRatingState, getLoaded, (state: RatingState, isLoaded) => {
  return isLoaded
    ? Object.keys(state.guestRatings).map<IGuestRating>((id) => new GuestRating(state.guestRatings[parseInt(id, 10)]))
    : [];
});

const getAllBoondockRatingEntities = createSelector(getRatingState, getLoaded, (state: RatingState, isLoaded) => {
  if (isLoaded) {
    const instantiatedRatings: { [syncId: string]: IBoondockRating } = {};
    for (const key in state.boondockRatings) {
      if (state.boondockRatings.hasOwnProperty(key)) {
        instantiatedRatings[key] = new BoondockRating(state.boondockRatings[key]);
      }
    }

    return instantiatedRatings;
  }

  return {};
});

const getAllBoondockRatings = createSelector(getRatingState, getLoaded, (state: RatingState, isLoaded) => {
  return isLoaded
    ? Object.keys(state.boondockRatings).map<IBoondockRating>(
        (id) => new BoondockRating(state.boondockRatings[parseInt(id, 10)]),
      )
    : [];
});

const getAllPoiRatingEntities = createSelector(getRatingState, getLoaded, (state: RatingState, isLoaded) => {
  if (isLoaded) {
    const instantiatedRatings: { [syncId: string]: IPoiRating } = {};
    for (const key in state.poiRatings) {
      if (state.poiRatings.hasOwnProperty(key)) {
        instantiatedRatings[key] = new PoiRating(state.poiRatings[key]);
      }
    }

    return instantiatedRatings;
  }

  return {};
});

const getAllPoiRatings = createSelector(getRatingState, getLoaded, (state: RatingState, isLoaded) => {
  return isLoaded
    ? Object.keys(state.poiRatings).map<IPoiRating>((id) => new PoiRating(state.poiRatings[parseInt(id, 10)]))
    : [];
});

export const ratingQuery = {
  getLoaded,
  getError,
  getCaretakerSites,
  getAllSiteRatingEntities,
  getAllSiteRatings,
  getAllGuestRatingEntities,
  getAllGuestRatings,
  getAllBoondockRatingEntities,
  getAllBoondockRatings,
  getAllPoiRatingEntities,
  getAllPoiRatings,
};
