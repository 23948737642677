import { toDegrees, toPrecision, toRadians } from '@curbnturf/helpers';
import { ILatLon } from '../interfaces';

const earthRadius = 3959; // radius of earth in miles

export class LatLon implements ILatLon {
  lat: number;
  lon: number;

  static simplifyWaypoints(
    waypoints: LatLon[],
    maxDistance: number = 100,
    allowedBearingVariance: number = 2.5,
  ): LatLon[] {
    const simplifiedWaypoints: LatLon[] = [];
    for (let i = 0; i < waypoints.length; i) {
      const startWaypoint = waypoints[i];
      simplifiedWaypoints.push(startWaypoint);

      // we increment here as we need to control the incrementation;
      i += 1;
      if (waypoints.length > i + 1) {
        const baseBearing = startWaypoint.bearingTo(waypoints[i]);
        for (let j = i + 1; j < waypoints.length; j++) {
          const nextBearing = startWaypoint.bearingTo(waypoints[j]);
          const distance = startWaypoint.distanceTo(waypoints[j]);
          i = j;

          if (Math.abs(baseBearing - nextBearing) > allowedBearingVariance || distance >= maxDistance) {
            break;
          }
        }
      }
    }

    return simplifiedWaypoints;
  }

  constructor(data?: ILatLon) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: ILatLon): void {
    if (!data) {
      return;
    }

    this.lat = typeof data.lat === 'string' ? parseFloat(data.lat) : data.lat;
    this.lon = typeof data.lon === 'string' ? parseFloat(data.lon) : data.lon;
  }

  toString(): string {
    if (!this.lat || !this.lon) {
      return 'Coordinates Missing';
    }

    return `${this.lat}, ${this.lon}`;
  }

  equals(coordinatesB: ILatLon, accuracy: number = 5): boolean {
    const multiplier = Math.pow(10, accuracy);
    return (
      this &&
      coordinatesB &&
      Math.floor(this.lat * multiplier) === Math.floor(coordinatesB.lat * multiplier) &&
      Math.floor(this.lon * multiplier) === Math.floor(coordinatesB.lon * multiplier)
    );
  }

  distanceTo(point: LatLon): number {
    const phi = toRadians(this.lat);
    const lambda = toRadians(this.lon);
    const phi2 = toRadians(point.lat);
    const lambda2 = toRadians(point.lon);
    const deltaPhi = phi2 - phi;
    const deltaLambda = lambda2 - lambda;

    const a =
      Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
      Math.cos(phi) * Math.cos(phi2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = earthRadius * c;

    return d;
  }

  bearingTo(point: LatLon): number {
    const phi = toRadians(this.lat);
    const lambda = toRadians(this.lon);
    const phi2 = toRadians(point.lat);
    const lambda2 = toRadians(point.lon);

    const y = Math.sin(lambda2 - lambda) * Math.cos(phi2);
    const x = Math.cos(phi) * Math.sin(phi2) - Math.sin(phi) * Math.cos(phi2) * Math.cos(lambda2 - lambda);
    const bearing = Math.atan2(y, x);

    return (toDegrees(bearing) + 360) % 360;
  }

  destinationPoint(distance: number, bearing: number): LatLon {
    const delta = distance / earthRadius;
    const theta = toRadians(bearing);

    const phi = toRadians(this.lat);
    const lambda = toRadians(this.lon);

    const sinPhi = Math.sin(phi);
    const cosPhi = Math.cos(phi);
    const sinDelta = Math.sin(delta);
    const cosDelta = Math.cos(delta);
    const sinTheta = Math.sin(theta);
    const cosTheta = Math.cos(theta);

    const sinPhi2 = sinPhi * cosDelta + cosPhi * sinDelta * cosTheta;
    const phi2 = Math.asin(sinPhi2);
    const y = sinTheta * sinDelta * cosPhi;
    const x = cosDelta - sinPhi * sinPhi2;
    const lambda2 = lambda + Math.atan2(y, x);

    return new LatLon({
      lat: toPrecision(toDegrees(phi2), 6),
      lon: toPrecision(((toDegrees(lambda2) + 540) % 360) - 180, 6),
    });
  }

  midpointTo(point: LatLon): LatLon {
    const phi = toRadians(this.lat);
    const lambda = toRadians(this.lon);
    const phi2 = toRadians(point.lat);
    const deltaLambda = toRadians(point.lon - this.lon);
    const Bx = Math.cos(phi2) * Math.cos(deltaLambda);
    const By = Math.cos(phi2) * Math.sin(deltaLambda);
    const x = Math.sqrt((Math.cos(phi) + Bx) * (Math.cos(phi) + Bx) + By * By);
    const y = Math.sin(phi) + Math.sin(phi2);
    const phi3 = Math.atan2(y, x);
    const lambda2 = lambda + Math.atan2(By, Math.cos(phi) + Bx);

    return new LatLon({
      lat: toPrecision(toDegrees(phi3), 6),
      lon: toPrecision(((toDegrees(lambda2) + 540) % 360) - 180, 6),
    });
  }
}
