import { IAffiliateType, Role } from '../enums';
import { IAffiliateUser, ISite, IUser } from '../interfaces';
import { Address } from './address';
import { Photo } from './photo';
import { Site } from './site';

export class AffiliateUser implements IAffiliateUser {
  // From User
  id: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  address?: Address;
  phone?: string;
  photo?: Photo;
  role?: Role;
  affiliateRef?: string;
  created?: number;
  type?: IAffiliateType['type'];

  // calculated for affiliate
  sites?: ISite[];
  earnings?: number;
  assignedEarnings?: number;

  static fromUser(user: IUser): AffiliateUser {
    return new AffiliateUser({
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      address: user.address,
      phone: user.phone,
      photo: user.photo,
      role: user.role,
      affiliateRef: user.affiliateRef,
      created: user.created,
      type: user.affiliateType,
      sites: user.sites,
    });
  }

  constructor(data?: IAffiliateUser) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IAffiliateUser): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    this.photo = new Photo(this.photo);

    this.address = new Address(this.address);

    if (data.sites) {
      this.sites = data.sites.map((site) => new Site(site));
    }
  }
}
