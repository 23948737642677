import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NGRX_BUCKETS, NO_ACTION } from '@curbnturf/entities';
import { Storage } from '@ionic/storage-angular';
import { StorageActionTypes } from './+state/storage.actions';
import { IStorageOptions } from './+state/storage.interface';
import { ChecklistSerializer } from './serializers/checklist-serializer';
import { TripPlannerSerializer } from './serializers/trip-planner-serializer';
import { IStorageSerializer } from './storage-serializer.interface';
import { storageOptions } from './storage.options';

@Injectable()
export class StorageService {
  defaultOptions: IStorageOptions = {
    keys: [],
    ignoreActions: [
      StorageActionTypes.Hydrated,
      NO_ACTION,
      '@ngrx/store/init',
      '@ngrx/effects/init',
      '@ngrx/store/update-reducers',
    ],
    serializers: {
      [NGRX_BUCKETS.tripPlanner]: new TripPlannerSerializer(),
      [NGRX_BUCKETS.checklist]: new ChecklistSerializer(),
    },
  };

  options: IStorageOptions;

  serializers: { [feature: string]: IStorageSerializer } = {};

  private _storage: Storage | null = null;

  constructor(
    private storage: Storage,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.options = {
      keys: [...this.defaultOptions.keys, ...storageOptions.keys],
      ignoreActions: [...this.defaultOptions.ignoreActions, ...storageOptions.ignoreActions],
    };

    // Add Serializers
    for (const featureName in this.defaultOptions.serializers) {
      this.registerSerializer(featureName, this.defaultOptions.serializers[featureName]);
    }

    this.init();
  }

  async init() {
    this._storage = await this.storage.create();
  }

  async fetchState(): Promise<Record<string, unknown>> {
    if (!isPlatformServer(this.platformId)) {
      if (!this._storage) {
        await this.init();
      }
      return (
        this._storage
          ?.get(NGRX_BUCKETS.storage)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((s: any) => {
            if (s) {
              for (const featureName in this.serializers) {
                if (s[featureName]) {
                  s[featureName] = this.serializers[featureName].unserialize(s[featureName]);
                }
              }
            }

            return s || {};
          })
          .catch((err: string) => {
            console.error('fetchState Error', err);
          })
      );
    }

    return {};
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async saveState(state: any, actionType?: string): Promise<void> {
    if (!this._storage) {
      await this.init();
    }
    // Pull out the portion of the state to save.
    state = this.options.keys.reduce((acc, k) => {
      const val = this.getNested(state, k);
      if (val) {
        let storeState;
        if (this.serializers[k]) {
          storeState = this.serializers[k].serialize(val);
        } else {
          storeState = val;
        }

        this.setNested(acc, k, storeState);
      }
      return acc;
    }, {});

    return this._storage?.set(NGRX_BUCKETS.storage, state);
  }

  // get/setNested inspired by
  // https://github.com/mickhansen/dottie.js
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getNested(obj: any, path: string): any {
    if (obj !== null && path) {
      // Recurse into the object.
      const parts = path.split('.').reverse();
      while (obj != null && parts.length) {
        const key = parts.pop();
        if (key) {
          obj = obj[key];
        }
      }
    }
    return obj;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setNested(obj: any, path: string, value: any): any {
    if (obj != null && path) {
      const pieces = path.split('.'),
        length = pieces.length;
      let current = obj,
        piece,
        i;

      for (i = 0; i < length; i++) {
        piece = pieces[i];
        if (i === length - 1) {
          current[piece] = value;
        } else if (!current[piece]) {
          current[piece] = {};
        }
        current = current[piece];
      }
    }

    return obj;
  }

  async clear(): Promise<void> {
    if (!this._storage) {
      await this.init();
    }
    return this._storage?.clear();
  }

  registerSerializer(featureName: string, serializer: IStorageSerializer): void {
    this.serializers[featureName] = serializer;
  }

  clearSerializer(featureName: string): void {
    delete this.serializers[featureName];
  }
}
