import { IBoondockRating, ICaretaker, IGuestRating, IPoiRating, ISite, ISiteRating } from '@curbnturf/entities';
import { Action } from '@ngrx/store';

export enum RatingActionTypes {
  LoadGuestRatings = '[Rating] Load Ratings for a Guest',
  LoadSiteRatings = '[Rating] Load Ratings for a Site',
  LoadBoondockRatings = '[Rating] Load Boondock for a Site',
  LoadPoiRatings = '[Rating] Load Poi for a Site',
  RatingLoadError = '[Rating] Rating Load Error',
  SiteRatingsLoaded = '[Rating] Site Ratings Loaded',
  GuestRatingsLoaded = '[Rating] Guest Ratings Loaded',
  BoondockRatingsLoaded = '[Rating] Boondock Ratings Loaded',
  PoiRatingsLoaded = '[Rating] Poi Ratings Loaded',
  CreateSiteRatings = '[Rating] Create Site Ratings',
  SiteRatingsCreated = '[Rating] Site Ratings Created',
  SyncSiteRatings = '[Rating] Sync Site Ratings',
  CreateGuestRatings = '[Rating] Create Guest Ratings',
  GuestRatingsCreated = '[Rating] Guest Ratings Created',
  SyncGuestRatings = '[Rating] Sync Guest Ratings',
  CreateBoondockRatings = '[Rating] Create Boondock Ratings',
  BoondockRatingsCreated = '[Rating] Boondock Ratings Created',
  CreatePoiRatings = '[Rating] Create POI Ratings',
  PoiRatingsCreated = '[Rating] POI Ratings Created',
  SelectCaretakerSite = '[Rating] Select Caretaker Site',
  SelectCaretakerSiteComplete = '[Rating] Select Caretaker Site Complete',
  SelectCaretakerSiteFailed = '[Rating] Select Caretaker Site Failed',
}

export class LoadGuestRatings implements Action {
  readonly type = RatingActionTypes.LoadGuestRatings;
  constructor(public payload: number) {}
}

export class LoadSiteRatings implements Action {
  readonly type = RatingActionTypes.LoadSiteRatings;
  constructor(public payload: number) {}
}

export class LoadBoondockRatings implements Action {
  readonly type = RatingActionTypes.LoadBoondockRatings;
  constructor(public payload: number) {}
}

export class LoadPoiRatings implements Action {
  readonly type = RatingActionTypes.LoadPoiRatings;
  constructor(public payload: number) {}
}

export class RatingLoadError implements Action {
  readonly type = RatingActionTypes.RatingLoadError;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public payload: any) {}
}

export class SiteRatingsLoaded implements Action {
  readonly type = RatingActionTypes.SiteRatingsLoaded;
  constructor(public payload: ISiteRating[]) {}
}

export class GuestRatingsLoaded implements Action {
  readonly type = RatingActionTypes.GuestRatingsLoaded;
  constructor(public payload: IGuestRating[]) {}
}

export class BoondockRatingsLoaded implements Action {
  readonly type = RatingActionTypes.BoondockRatingsLoaded;
  constructor(public payload: IBoondockRating[]) {}
}

export class PoiRatingsLoaded implements Action {
  readonly type = RatingActionTypes.PoiRatingsLoaded;
  constructor(public payload: IPoiRating[]) {}
}

export class CreateSiteRatings implements Action {
  readonly type = RatingActionTypes.CreateSiteRatings;
  constructor(public payload: ISiteRating[]) {}
}

export class SiteRatingsCreated implements Action {
  readonly type = RatingActionTypes.SiteRatingsCreated;
  constructor(public payload: number) {}
}

export class SyncSiteRatings implements Action {
  readonly type = RatingActionTypes.SyncSiteRatings;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public payload: { response?: ISiteRating[]; request?: ISiteRating[]; error?: any }) {}
}

export class CreateGuestRatings implements Action {
  readonly type = RatingActionTypes.CreateGuestRatings;
  constructor(public payload: IGuestRating[]) {}
}

export class GuestRatingsCreated implements Action {
  readonly type = RatingActionTypes.GuestRatingsCreated;
}

export class SyncGuestRatings implements Action {
  readonly type = RatingActionTypes.SyncGuestRatings;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public payload: { response?: IGuestRating[]; request?: IGuestRating[]; error?: any }) {}
}

export class CreateBoondockRatings implements Action {
  readonly type = RatingActionTypes.CreateBoondockRatings;
  constructor(public payload: IBoondockRating[]) {}
}

export class BoondockRatingsCreated implements Action {
  readonly type = RatingActionTypes.BoondockRatingsCreated;
}

export class CreatePoiRatings implements Action {
  readonly type = RatingActionTypes.CreatePoiRatings;
  constructor(public payload: IPoiRating[]) {}
}

export class PoiRatingsCreated implements Action {
  readonly type = RatingActionTypes.PoiRatingsCreated;
}

export class SelectCaretakerSite implements Action {
  readonly type = RatingActionTypes.SelectCaretakerSite;
  constructor(public payload: ICaretaker) {}
}

export class SelectCaretakerSiteComplete implements Action {
  readonly type = RatingActionTypes.SelectCaretakerSiteComplete;
  constructor(public payload: ISite) {}
}

export class SelectCaretakerSiteFailed implements Action {
  readonly type = RatingActionTypes.SelectCaretakerSiteFailed;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public error: any) {}
}

export type RatingAction =
  | LoadGuestRatings
  | LoadSiteRatings
  | LoadBoondockRatings
  | LoadPoiRatings
  | RatingLoadError
  | SiteRatingsLoaded
  | GuestRatingsLoaded
  | BoondockRatingsLoaded
  | PoiRatingsLoaded
  | CreateSiteRatings
  | SiteRatingsCreated
  | SyncSiteRatings
  | CreateGuestRatings
  | GuestRatingsCreated
  | SyncGuestRatings
  | CreateBoondockRatings
  | BoondockRatingsCreated
  | CreatePoiRatings
  | PoiRatingsCreated
  | SelectCaretakerSite
  | SelectCaretakerSiteComplete
  | SelectCaretakerSiteFailed;

export const fromRatingActions = {
  LoadGuestRatings,
  LoadSiteRatings,
  LoadBoondockRatings,
  LoadPoiRatings,
  RatingLoadError,
  SiteRatingsLoaded,
  GuestRatingsLoaded,
  BoondockRatingsLoaded,
  PoiRatingsLoaded,
  CreateSiteRatings,
  SiteRatingsCreated,
  SyncSiteRatings,
  CreateGuestRatings,
  GuestRatingsCreated,
  SyncGuestRatings,
  CreateBoondockRatings,
  BoondockRatingsCreated,
  CreatePoiRatings,
  PoiRatingsCreated,
  SelectCaretakerSite,
  SelectCaretakerSiteComplete,
  SelectCaretakerSiteFailed,
};
