import { IAddress } from '../interfaces';

export class Address implements IAddress {
  address: string;
  city: string;
  state: string;
  zipcode: string;

  static isEqual(a?: IAddress, b?: IAddress): boolean {
    if (a === b) {
      return true;
    }

    if (!a || !b) {
      return false;
    }

    return a.address === b.address && a.city === b.city && a.state === b.state && a.zipcode === b.zipcode;
  }

  constructor(data?: IAddress) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IAddress): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
