import { Injectable } from '@angular/core';
import { ILatLon, IRoutePoint, ITrip, RoutePoint } from '@curbnturf/entities';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import * as TripPlannerActions from './trip-planner.actions';
import * as TripPlannerSelectors from './trip-planner.selectors';

@Injectable({
  providedIn: 'root',
})
export class TripPlannerFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  trip$: Observable<IRoutePoint[]>;
  route$: Observable<ILatLon[]>;
  buffer$: Observable<ILatLon[]>;
  distance$: Observable<number | undefined>;
  corridor$: Observable<number>;
  tripPlannerPanel$: Observable<boolean>;
  savedTrips$: Observable<ITrip[] | undefined>;

  constructor(private readonly store: Store) {
    this.trip$ = this.store.pipe(select(TripPlannerSelectors.getTrip));
    this.route$ = this.store.pipe(select(TripPlannerSelectors.getRoute));
    this.buffer$ = this.store.pipe(select(TripPlannerSelectors.getBuffer));
    this.distance$ = this.store.pipe(select(TripPlannerSelectors.getDistance));
    this.corridor$ = this.store.pipe(select(TripPlannerSelectors.getCorridor));
    this.tripPlannerPanel$ = this.store.pipe(select(TripPlannerSelectors.getTripPlannerPanel));
    this.savedTrips$ = this.store.pipe(select(TripPlannerSelectors.getSavedTrips));
  }

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init(): void {
    this.store.dispatch(TripPlannerActions.init());
  }

  newTrip(trip?: RoutePoint[]): void {
    this.store.dispatch(TripPlannerActions.newTrip({ trip }));
  }

  addPoint(point: IRoutePoint, updateRoute?: boolean): void {
    this.store.dispatch(TripPlannerActions.addRoutePoint({ point, updateRoute }));
  }

  reorder(from: number, to: number): void {
    this.store.dispatch(TripPlannerActions.reorderPoint({ source: from, destination: to }));
  }

  removePoint(point: IRoutePoint, updateRoute?: boolean): void {
    this.store.dispatch(TripPlannerActions.removeRoutePoint({ point, updateRoute }));
  }

  setPointActivity(point: IRoutePoint, active: boolean): void {
    this.store.dispatch(TripPlannerActions.setPointActivity({ point, active }));
  }

  corridor(range: number): void {
    this.store.dispatch(TripPlannerActions.setCorridor({ range }));
  }

  setRoute(route: ILatLon[]): void {
    this.store.dispatch(TripPlannerActions.setRoute({ route }));
  }

  setTripPlannerPanelState(open: boolean): void {
    this.store.dispatch(TripPlannerActions.setTripPlannerPanelState({ open }));
  }

  setDates(point: IRoutePoint, arrival: DateTime, departure: DateTime) {
    this.store.dispatch(TripPlannerActions.setDates({ point, arrival, departure }));
  }

  updateRoute(showLoader: boolean = true) {
    this.store.dispatch(TripPlannerActions.updateRoute({ showLoader }));
  }

  saveTrip(name: string, rvId: number) {
    this.store.dispatch(TripPlannerActions.saveTrip({ name, rvId }));
  }

  loadForUser(userId?: number) {
    this.store.dispatch(TripPlannerActions.loadTrips({ userId }));
  }

  clearDates(point: IRoutePoint) {
    this.store.dispatch(TripPlannerActions.clearPointDates({ point }));
  }
}
