import { NGRX_BUCKETS } from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UrlControlState } from './url-control.reducer';

// Lookup the 'UrlControl' feature state managed by NgRx
const getUrlControlState = createFeatureSelector<UrlControlState>(NGRX_BUCKETS.urlControl);

const getRedirectUrl = createSelector(getUrlControlState, (state: UrlControlState) => state.redirectUrl);
const getReferenceId = createSelector(getUrlControlState, (state: UrlControlState) => state.referenceId);

export const urlControlQuery = {
  getRedirectUrl,
  getReferenceId,
};
