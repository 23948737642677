import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IAmenityType, IRV } from '@curbnturf/entities';
import { DateTime } from 'luxon';
import {
  addressFormBuilder,
  coordinatesFormBuilder,
  photoFormBuilder,
  rvRoadConditionsFormBuilder,
} from './builder.helpers';
import { IRvForm, IUserForm } from './interfaces';

export class UserForm {
  userForm: FormGroup<IUserForm>;
  rvForm: FormGroup<IRvForm>;

  constructor(formBuilder: FormBuilder) {
    this.userForm = formBuilder.group(
      {
        firstName: formBuilder.control('', [Validators.maxLength(120)]),
        lastName: formBuilder.control('', [Validators.maxLength(120)]),
        phone: formBuilder.control('', [Validators.maxLength(20)]),
        address: addressFormBuilder(formBuilder),
        billing: addressFormBuilder(formBuilder),
        description: formBuilder.control(''),
        languages: formBuilder.control(''),
        sendEmails: formBuilder.control(true, { updateOn: 'change' }),
        sendSmss: formBuilder.control(false, { updateOn: 'change' }),
        sendPushNotifications: formBuilder.control(false, { updateOn: 'change' }),
        source: formBuilder.control('', { updateOn: 'change' }),
        promotion: formBuilder.control(''),
        inProgressId: formBuilder.control<number | null>(null),
        coordinates: coordinatesFormBuilder(formBuilder),
        photo: photoFormBuilder(formBuilder),
        role: formBuilder.control(''),
        affiliateRef: formBuilder.control(''),
      },
      { updateOn: 'blur' },
    );

    this.rvForm = formBuilder.group<IRvForm>(
      {
        id: formBuilder.control<number | null>(null),
        name: formBuilder.control<string | null>('', [Validators.min(2)]),
        type: formBuilder.control<string | null>(null, { updateOn: 'change' }),
        year: formBuilder.control<number | null>(null, [
          Validators.min(1910),
          Validators.max(parseInt(DateTime.now().plus({ year: 1 }).toFormat('yyyy'), 10)),
        ]),
        make: formBuilder.control<string | null>(null),
        model: formBuilder.control<string | null>(null),
        height: formBuilder.control<number | null>(null, [Validators.min(0)]),
        width: formBuilder.control<number | null>(null, [Validators.min(0)]),
        length: formBuilder.control<number | null>(null, [Validators.min(0)]),
        underwayHeight: formBuilder.control<number | null>(null, [Validators.min(0)]),
        underwayWidth: formBuilder.control<number | null>(null, [Validators.min(0)]),
        underwayLength: formBuilder.control<number | null>(null, [Validators.min(0)]),
        clearance: formBuilder.control<number | null>(null, [Validators.min(0)]),
        weight: formBuilder.control<number | null>(null, [Validators.min(0)]),
        axleCount: formBuilder.control<number | null>(null, [Validators.min(0)]),
        trailerCount: formBuilder.control<number | null>(null, [Validators.min(0)]),
        tent: formBuilder.control<boolean | null>(false),
        tentCount: formBuilder.control<number | null>(null, [Validators.min(0)]),
        tentHeight: formBuilder.control<number | null>(null, [Validators.min(0)]),
        tentWidth: formBuilder.control<number | null>(null, [Validators.min(0)]),
        tentLength: formBuilder.control<number | null>(null, [Validators.min(0)]),
        roadCondition: rvRoadConditionsFormBuilder(formBuilder),
        amenities: formBuilder.array<FormControl<IAmenityType['type']>>([], { updateOn: 'change' }),
        licenseState: formBuilder.control<string | null>(''),
        licensePlate: formBuilder.control<string | null>(''),
        rentalUrl: formBuilder.control<string | null>(''),
      },
      { updateOn: 'blur' },
    );
  }

  static setRvValue(form: FormGroup<IRvForm>, value: IRV): FormGroup<IRvForm> {
    const amenitiesFormArray = form.get('amenities') as FormArray;

    // remove all amenities form controls
    while (amenitiesFormArray?.length > 0) {
      amenitiesFormArray.removeAt(0);
    }

    form.setValue({
      id: value.id ? value.id : null,
      name: value.name ? value.name : null,
      type: value.type ? value.type : null,
      year: value.year ? value.year : null,
      make: value.make ? value.make : null,
      model: value.model ? value.model : null,
      height: value.height ? value.height : null,
      width: value.width ? value.width : null,
      length: value.length ? value.length : null,
      underwayHeight: value.underwayHeight ? value.underwayHeight : null,
      underwayWidth: value.underwayWidth ? value.underwayWidth : null,
      underwayLength: value.underwayLength ? value.underwayLength : null,
      clearance: value.clearance ? value.clearance : null,
      weight: value.weight ? value.weight : null,
      axleCount: value.axleCount ? value.axleCount : null,
      trailerCount: value.trailerCount ? value.trailerCount : null,
      tent: value.tent !== null && value.tent !== undefined ? value.tent : null,
      tentCount: value.tentCount ? value.tentCount : null,
      tentHeight: value.tentHeight ? value.tentHeight : null,
      tentWidth: value.tentWidth ? value.tentWidth : null,
      tentLength: value.tentLength ? value.tentLength : null,
      roadCondition: {
        paved:
          value.roadCondition?.paved !== null && value.roadCondition?.paved !== undefined
            ? value.roadCondition.paved
            : null,
        smoothGravel:
          value.roadCondition?.smoothGravel !== null && value.roadCondition?.smoothGravel !== undefined
            ? value.roadCondition.smoothGravel
            : null,
        poorGravel:
          value.roadCondition?.poorGravel !== null && value.roadCondition?.poorGravel !== undefined
            ? value.roadCondition.poorGravel
            : null,
        highClearance:
          value.roadCondition?.highClearance !== null && value.roadCondition?.highClearance !== undefined
            ? value.roadCondition.highClearance
            : null,
        offRoad:
          value.roadCondition?.offRoad !== null && value.roadCondition?.offRoad !== undefined
            ? value.roadCondition.offRoad
            : null,
      },
      amenities: [],
      licenseState: value.licenseState || null,
      licensePlate: value.licensePlate || null,
      rentalUrl: value.rentalUrl || null,
    });

    if (value.amenities?.length) {
      value.amenities.forEach((amenity) => {
        const amenityForm = new FormControl(amenity);
        amenityForm.setValue(amenity);
        amenitiesFormArray?.push(amenityForm);
      });
    }

    return form;
  }

  static patchRvValue(form: FormGroup<IRvForm>, value: IRV): FormGroup<IRvForm> {
    const amenitiesFormArray = form.get('amenities') as FormArray;

    const originalAmenities = amenitiesFormArray.value as string[];

    // remove all amenities form controls
    while (amenitiesFormArray?.length > 0) {
      amenitiesFormArray.removeAt(0);
    }

    form.patchValue({
      id: value.id ? value.id : undefined,
      name: value.name ? value.name : undefined,
      type: value.type ? value.type : undefined,
      year: value.year ? value.year : undefined,
      make: value.make ? value.make : undefined,
      model: value.model ? value.model : undefined,
      height: value.height ? value.height : undefined,
      width: value.width ? value.width : undefined,
      length: value.length ? value.length : undefined,
      underwayHeight: value.underwayHeight ? value.underwayHeight : undefined,
      underwayWidth: value.underwayWidth ? value.underwayWidth : undefined,
      underwayLength: value.underwayLength ? value.underwayLength : undefined,
      clearance: value.clearance ? value.clearance : undefined,
      weight: value.weight ? value.weight : undefined,
      axleCount: value.axleCount ? value.axleCount : undefined,
      trailerCount: value.trailerCount ? value.trailerCount : undefined,
      tent: value.tent !== null && value.tent !== undefined ? value.tent : undefined,
      tentCount: value.tentCount ? value.tentCount : undefined,
      tentHeight: value.tentHeight ? value.tentHeight : undefined,
      tentWidth: value.tentWidth ? value.tentWidth : undefined,
      tentLength: value.tentLength ? value.tentLength : undefined,
      roadCondition: {
        paved:
          value.roadCondition?.paved !== null && value.roadCondition?.paved !== undefined
            ? value.roadCondition.paved
            : undefined,
        smoothGravel:
          value.roadCondition?.smoothGravel !== null && value.roadCondition?.smoothGravel !== undefined
            ? value.roadCondition.smoothGravel
            : undefined,
        poorGravel:
          value.roadCondition?.poorGravel !== null && value.roadCondition?.poorGravel !== undefined
            ? value.roadCondition.poorGravel
            : undefined,
        highClearance:
          value.roadCondition?.highClearance !== null && value.roadCondition?.highClearance !== undefined
            ? value.roadCondition.highClearance
            : undefined,
        offRoad:
          value.roadCondition?.offRoad !== null && value.roadCondition?.offRoad !== undefined
            ? value.roadCondition.offRoad
            : undefined,
      },
      amenities: value.amenities ? value.amenities : [],
      licenseState: value.licenseState ? value.licenseState : undefined,
      licensePlate: value.licensePlate ? value.licensePlate : undefined,
      rentalUrl: value.rentalUrl ? value.rentalUrl : undefined,
    });

    // add amenity form controls
    if (value.amenities?.length) {
      value.amenities.forEach((amenity) => {
        const amenityForm = new FormControl(amenity);
        amenityForm.setValue(amenity);
        amenitiesFormArray?.push(amenityForm);
      });
    } else if (originalAmenities?.length) {
      originalAmenities.forEach((amenity) => {
        const amenityForm = new FormControl(amenity);
        amenityForm.setValue(amenity);
        amenitiesFormArray?.push(amenityForm);
      });
    }

    return form;
  }

  static formatRvFormValue(form: FormGroup<IRvForm>): IRV {
    const value = form.value;

    return {
      id: value.id ? value.id : undefined,
      name: value.name ? value.name : undefined,
      type: value.type ? value.type : undefined,
      year: value.year ? value.year : undefined,
      make: value.make ? value.make : undefined,
      model: value.model ? value.model : undefined,
      height: value.height ? value.height : undefined,
      width: value.width ? value.width : undefined,
      length: value.length ? value.length : undefined,
      underwayHeight: value.underwayHeight ? value.underwayHeight : undefined,
      underwayWidth: value.underwayWidth ? value.underwayWidth : undefined,
      underwayLength: value.underwayLength ? value.underwayLength : undefined,
      clearance: value.clearance ? value.clearance : undefined,
      weight: value.weight ? value.weight : undefined,
      axleCount: value.axleCount ? value.axleCount : undefined,
      trailerCount: value.trailerCount ? value.trailerCount : undefined,
      tent: value.tent !== null && value.tent !== undefined ? value.tent : undefined,
      tentCount: value.tentCount ? value.tentCount : undefined,
      tentHeight: value.tentHeight ? value.tentHeight : undefined,
      tentWidth: value.tentWidth ? value.tentWidth : undefined,
      tentLength: value.tentLength ? value.tentLength : undefined,
      roadCondition: {
        paved:
          value.roadCondition?.paved !== null && value.roadCondition?.paved !== undefined
            ? value.roadCondition.paved
            : undefined,
        smoothGravel:
          value.roadCondition?.smoothGravel !== null && value.roadCondition?.smoothGravel !== undefined
            ? value.roadCondition.smoothGravel
            : undefined,
        poorGravel:
          value.roadCondition?.poorGravel !== null && value.roadCondition?.poorGravel !== undefined
            ? value.roadCondition.poorGravel
            : undefined,
        highClearance:
          value.roadCondition?.highClearance !== null && value.roadCondition?.highClearance !== undefined
            ? value.roadCondition.highClearance
            : undefined,
        offRoad:
          value.roadCondition?.offRoad !== null && value.roadCondition?.offRoad !== undefined
            ? value.roadCondition.offRoad
            : undefined,
      },
      amenities: value.amenities ? value.amenities : [],
      licenseState: value.licenseState ? value.licenseState : undefined,
      licensePlate: value.licensePlate ? value.licensePlate : undefined,
      rentalUrl: value.rentalUrl ? value.rentalUrl : undefined,
    };
  }
}
