import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import * as AlertActions from '@curbnturf/alert/src/lib/+state/alert.actions';
import { AlertFacade } from '@curbnturf/alert/src/lib/+state/alert.facade';
import { CachableHttpRequest, NGRX_NO_ACTION } from '@curbnturf/entities';
import * as PrefetchActions from '@curbnturf/prefetch/src/lib/+state/prefetch.actions';
import * as SiteActions from '@curbnturf/site/src/lib/+state/site.actions';
import * as UserActions from '@curbnturf/user/src/lib/+state/user.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { from, of } from 'rxjs';
import { catchError, concatMap, debounceTime, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Logger } from '../../log/logger';
import { OfflineSiteDbSet } from '../../native/dbset/offline-site.dbset';
import { CachedDataStorageService } from '../cached-data-storage.service';
import { CachedHttpClient } from '../cached-http-client.service';
import * as NetworkActions from './network.actions';
import * as NetworkSelectors from './network.selectors';

// The amount of time to debounce network status change events.
const DEBOUNCE_TIMER = 10000;

export const networkStateChanged$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), platformId = inject(PLATFORM_ID)) =>
    actions$.pipe(
      ofType(NetworkActions.setNetworkStatus),
      debounceTime(isPlatformServer(platformId) ? 0 : DEBOUNCE_TIMER),
      withLatestFrom(store.select(NetworkSelectors.getPendingItems)),
      switchMap(([action, items]) => {
        const actions: Action[] = [];

        let sitePhotoSyncIds: string[] = [];
        if (items.sitePhotos) {
          sitePhotoSyncIds = Object.keys(items.sitePhotos);

          const sitePhotos: { [syncId: string]: FormData[] } = {};
          sitePhotoSyncIds.forEach((syncId) => (sitePhotos[syncId] = [...items.sitePhotos[syncId]]));

          items = {
            ...items,
            sitePhotos,
          };
        }

        if (action.status?.connected) {
          if (items.requests?.length > 0) {
            actions.push(NetworkActions.flushCachedRequests({ status: action.status }));
          }

          if (items.sites?.length > 0) {
            if (Capacitor.isNativePlatform()) {
              actions.push(PrefetchActions.syncOfflineSites());
            } else {
              actions.push(
                ...items.sites.map((site) => {
                  if (site.id) {
                    return SiteActions.updateSite({ site });
                  } else {
                    return SiteActions.createSite({ site });
                  }
                }),
              );
              actions.push(...items.sites.map((site) => NetworkActions.removePendingSite({ site })));
            }
          }

          if (items.sitePhotos && Object.keys(items.sitePhotos)?.length) {
            if (Capacitor.isNativePlatform()) {
              // todo: figure out if we need to do anything here
            } else {
              actions.push(SiteActions.delayedUploadSiteImages({ formData: items.sitePhotos }));
              actions.push(
                ...sitePhotoSyncIds.map((syncId) => NetworkActions.removePendingSiteImageUpload({ syncId })),
              );
            }
          }

          if (items.user) {
            actions.push(UserActions.updateUser({ user: items.user }), NetworkActions.removeDeferUserUpdate());
          }

          if (items.userPhoto) {
            actions.push(
              UserActions.delayedUploadUserImage(items.userPhoto),
              NetworkActions.removeDeferUserImageUpload(),
            );
          }

          if (actions.length === 0) {
            return [NGRX_NO_ACTION];
          }
        } else {
          actions.push(NGRX_NO_ACTION);
        }

        return actions;
      }),
    ),
  { functional: true },
);

export const cacheHttpRequest$ = createEffect(
  (actions$ = inject(Actions), logger = inject(Logger), cachedDataStorageService = inject(CachedDataStorageService)) =>
    actions$.pipe(
      ofType(NetworkActions.cacheHttpRequest),
      switchMap((action) => {
        // persist the store of cached requests
        return from(cachedDataStorageService.insertRequest(action.request)).pipe(
          map(() => {
            return NGRX_NO_ACTION;
          }),
          catchError((error) => {
            logger.error('cacheHttpRequest$ Fatal Error', {
              error,
              action,
            });
            return [
              AlertActions.displayAlert({
                level: 'error',
                body: 'Failed to cache request: ' + (typeof error === 'string') ? error : JSON.stringify(error),
                title: 'Failed to Save Cached Request',
              }),
            ];
          }),
        );
      }),
    ),
  { dispatch: false, functional: true },
);

export const flushCachedRequests$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(NetworkActions.flushCachedRequests),
      withLatestFrom(store.select(NetworkSelectors.getCachedHttpRequests)),
      concatMap(([action, requests]) =>
        from(
          requests.map((request: CachableHttpRequest) => {
            if (action.status.connected) {
              return NetworkActions.processCachedRequest({ request });
            }
            return NGRX_NO_ACTION;
          }),
        ),
      ),
    ),
  { functional: true },
);

export const processCachedRequest$ = createEffect(
  (actions$ = inject(Actions), cachedHttpClient = inject(CachedHttpClient), logger = inject(Logger)) =>
    actions$.pipe(
      ofType(NetworkActions.processCachedRequest),
      concatMap((action: { request: CachableHttpRequest }) => {
        const options = {
          ...action.request.options,
          body: action.request.body,
        };
        return cachedHttpClient.request(action.request.method, action.request.url, options).pipe(
          map((response) =>
            NetworkActions.processCachedRequestSuccess({
              ...action,
              response,
            }),
          ),
          catchError((error) => {
            logger.warning('processCachedRequest$ Failure', {
              error,
              action,
            });
            return of(NetworkActions.processCachedRequestFailed({ ...action, error }));
          }),
        );
      }),
    ),
  { functional: true },
);

export const processCachedRequestSuccess$ = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    logger = inject(Logger),
    cachedDataStorageService = inject(CachedDataStorageService),
  ) =>
    actions$.pipe(
      ofType(NetworkActions.processCachedRequestSuccess),
      withLatestFrom(store.select(NetworkSelectors.getCachedHttpRequests)),
      mergeMap(([action, requests]) => {
        return from(cachedDataStorageService.removeRequest(action.request)).pipe(
          map(() => {
            if (action.request.syncAction) {
              return {
                ...action.request.syncAction,
                payload: { request: action.request, response: action.response },
              };
            }
            if (requests.length === 0) {
              return NetworkActions.retrieveCachedRequests({});
            }
            return NGRX_NO_ACTION;
          }),
          catchError((error) => {
            logger.error('processCachedRequestSuccess$ Fatal Error', {
              error,
              action,
              requests,
            });
            return of(
              AlertActions.displayAlert({
                level: 'error',
                body:
                  'Failed to process cached request: ' + (typeof error === 'string') ? error : JSON.stringify(error),
                title: 'Failure to Process Network Request',
              }),
            );
          }),
        );
      }),
    ),
  { functional: true },
);

export const processCachedRequestFailed$ = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    logger = inject(Logger),
    cachedDataStorageService = inject(CachedDataStorageService),
    alertFacade = inject(AlertFacade),
    platformId = inject(PLATFORM_ID),
  ) =>
    actions$.pipe(
      ofType(NetworkActions.processCachedRequestFailed),
      withLatestFrom(store.select(NetworkSelectors.getCachedHttpRequests)),
      switchMap(([action, requests]) => {
        const found = requests.find((el) => el.syncId === action.request.syncId);
        if (!found) {
          return from(cachedDataStorageService.removeRequest(action.request)).pipe(map(() => ({ action, requests })));
        } else {
          return from(cachedDataStorageService.updateRequest(action.request)).pipe(map(() => ({ action, requests })));
        }
      }),
      map((action) => {
        // Log the error under certain conditions
        if (
          action.action.request.retries >= CachableHttpRequest.MAX_RETRIES ||
          (action.action?.error.status >= 400 && action.action?.error.status < 500) ||
          (action.action?.error.status >= 200 && action.action?.error.status < 300)
        ) {
          // Log the error locally
          logger.debug('Cached Request Processing Error', JSON.stringify(action.action?.error));

          // Attempt to log the error message with Sentry
          if (!isPlatformServer(platformId)) {
            Sentry.getCurrentScope().setTransactionName('Cached Request Processing Error');
            Sentry.setContext('Effect Content', {
              error: {
                ...action.action.error,
                error: JSON.stringify(action.action.error?.error),
                headers: JSON.stringify(action.action.error?.headers),
              },
              request: {
                ...action.action.request,
                body: JSON.stringify(action.action.request?.body),
                options: JSON.stringify(action.action.request?.options),
                syncAction: JSON.stringify(action.action.request?.syncAction),
              },
            });
            Sentry.captureException(action.action.error);
          }

          alertFacade.display({
            level: 'error',
            title: 'Error Syncing Data',
            body: 'An error occurred while syncing offline data: ' + action.action?.error?.error?.message,
          });
        }

        if (action.action.request.syncAction && action.action.request.retries >= CachableHttpRequest.MAX_RETRIES) {
          return {
            ...action.action.request.syncAction,
            payload: {
              error: action.action.error,
              request: action.action.request,
            },
          };
        }
        if (action.requests.length === 0) {
          return NetworkActions.retrieveCachedRequests({});
        }
        return NGRX_NO_ACTION;
      }),
    ),
  { functional: true },
);

export const restoreCachedRequests$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), platformId = inject(PLATFORM_ID)) =>
    actions$.pipe(
      ofType(NetworkActions.restoreCachedRequests),
      debounceTime(isPlatformServer(platformId) ? 0 : DEBOUNCE_TIMER),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      withLatestFrom(store.select(NetworkSelectors.getCachedHttpRequests)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([[action, status], requests]) => {
        if (requests?.length > 0 && status?.connected) {
          return of(NetworkActions.flushCachedRequests({ status }));
        } else {
          return of(NGRX_NO_ACTION);
        }
      }),
    ),
  { functional: true },
);

export const retrieveCachedRequests$ = createEffect(
  (actions$ = inject(Actions), cachedDataStorageService = inject(CachedDataStorageService)) =>
    actions$.pipe(
      ofType(NetworkActions.retrieveCachedRequests),
      switchMap(() => {
        return from(cachedDataStorageService.getRequests()).pipe(
          map((requests) => NetworkActions.restoreCachedRequests({ requests })),
        );
      }),
    ),
  { functional: true },
);

export const pendingSiteUpdate$ = createEffect(
  (actions$ = inject(Actions), offlineSiteDbSet = inject(OfflineSiteDbSet)) =>
    actions$.pipe(
      ofType(NetworkActions.pendingSiteUpdate),
      mergeMap((action) => {
        if (action.site.syncId) {
          if (Capacitor.isNativePlatform()) {
            return from(offlineSiteDbSet.store(action.site)).pipe(map(() => NGRX_NO_ACTION));
          } else {
            // if not a native platform the reducer already stores the site data
          }
        }
        return [NGRX_NO_ACTION];
      }),
    ),
  { functional: true },
);

export const removePendingSiteUpdate$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(PrefetchActions.syncOfflineSitesSuccess),
      mergeMap((action) => from(action.sites.map((site) => NetworkActions.removePendingSite({ site })))),
    ),
  { functional: true },
);
