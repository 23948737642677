import { Injectable } from '@angular/core';
import { capSQLiteChanges, capSQLiteValues } from '@capacitor-community/sqlite';
import { AlertFacade } from '@curbnturf/alert/src/lib/+state/alert.facade';
import { CachableHttpRequest, ICachableHttpRequest, LogLevel } from '@curbnturf/entities';
import { LogFacade } from '../../log/+state/log.facade';
import { DatabaseService } from '../database.service';
import { IDbSet } from '../dbset.interface';
import { CachableHttpRequestEntity } from '../entities/cachable-http-request-entity';

const TABLE_NAME = 'cached_http_requests';

@Injectable({
  providedIn: 'root',
})
export class CachedHttpRequestsDbSet implements IDbSet {
  constructor(
    private databaseService: DatabaseService,
    private logFacade: LogFacade,
    private alertFacade: AlertFacade,
  ) {}

  public async store(request: ICachableHttpRequest): Promise<capSQLiteValues> {
    const entity = this.mapObjectsToStorableRequests([request]).pop();
    if (entity) {
      return this.databaseService.insert(TABLE_NAME, entity);
    } else {
      this.logFacade.log({
        level: LogLevel.CRITICAL,
        message: 'Failed to create storable object for database.',
        details: 'Insert Object: ' + JSON.stringify(request) + ' Result Entity: ' + JSON.stringify(entity),
      });
      this.alertFacade.display({
        title: 'Failed to create storable object for database.',
        body: 'Insert Object: ' + JSON.stringify(request) + ' Result Entity: ' + JSON.stringify(entity),
      });
      return {};
    }
  }

  public storeArray(requests: ICachableHttpRequest[]): Promise<capSQLiteValues> {
    const entities = this.mapObjectsToStorableRequests(requests);
    return this.databaseService.insertMulti<CachableHttpRequestEntity>(TABLE_NAME, entities);
  }

  public async truncateAndStoreArray(requests: ICachableHttpRequest[]): Promise<capSQLiteValues> {
    const entities = this.mapObjectsToStorableRequests(requests);
    await this.databaseService.execute('DELETE FROM cached_http_requests;');
    const { statement, values } = this.databaseService.generateInsertStatement<CachableHttpRequestEntity>(
      TABLE_NAME,
      entities,
    );
    return this.databaseService.query(statement, values);
  }

  public async truncate(): Promise<capSQLiteChanges> {
    return await this.databaseService.execute('DELETE FROM cached_http_requests;');
  }

  public async retrieve(syncId: string): Promise<{ values: CachableHttpRequest[] }> {
    const results = await this.databaseService.select(TABLE_NAME, { sync_id: syncId });
    return {
      values: this.mapResultsToCachableHttpRequest(results),
    };
  }

  public async update(request: CachableHttpRequest): Promise<capSQLiteValues> {
    const storable = this.mapObjectsToStorableRequests([request]).pop();
    if (storable) {
      return await this.databaseService.update<CachableHttpRequestEntity>(TABLE_NAME, storable, {
        sync_id: request.syncId || '',
      });
    } else {
      this.logFacade.log({
        level: LogLevel.CRITICAL,
        message: 'Failed to create storable object for database.',
        details: 'Insert Object: ' + JSON.stringify(request) + ' Result Entity: ' + JSON.stringify(storable),
      });
      this.alertFacade.display({
        title: 'Failed to create storable object for database.',
        body: 'Insert Object: ' + JSON.stringify(request) + ' Result Entity: ' + JSON.stringify(storable),
      });
      return {};
    }
  }

  public async retrieveAll(limit: number = 20, offset: number = 0): Promise<CachableHttpRequest[]> {
    const results = await this.databaseService.selectAll(TABLE_NAME, undefined, limit, offset);
    return this.mapResultsToCachableHttpRequest(results);
  }

  public async remove(syncId: string): Promise<capSQLiteValues> {
    return await this.databaseService.delete(TABLE_NAME, { sync_id: syncId });
  }

  public async count(): Promise<number> {
    const results = await this.databaseService.query(`SELECT COUNT(*) as rowcount FROM ${TABLE_NAME}`, []);
    const record: { rowcount: number } = results.values?.pop() || { rowcount: 0 };
    return record.rowcount;
  }

  private mapResultsToCachableHttpRequest(results: capSQLiteValues): CachableHttpRequest[] {
    if (results.values) {
      return results.values?.map((request) => {
        return new CachableHttpRequest({
          syncId: request.sync_id ? request.sync_id : undefined,
          syncAction: request.sync_action ? JSON.parse(request.sync_action) : undefined,
          options: request.options ? JSON.parse(request.options) : undefined,
          method: request.method ? request.method : undefined,
          body: request.body ? JSON.parse(request.body) : undefined,
          url: request.url ? request.url : undefined,
          retries: request.retries ? parseInt(request.retries, 10) : 0,
        });
      });
    } else {
      return [];
    }
  }

  private mapObjectsToStorableRequests(items: ICachableHttpRequest[]): CachableHttpRequestEntity[] {
    if (items) {
      return items?.map((request) => {
        return {
          sync_id: request.syncId ? request.syncId : undefined,
          sync_action: request.syncAction ? JSON.stringify(request.syncAction) : undefined,
          options: request.options ? JSON.stringify(request.options) : '{}',
          method: request.method ? request.method : '',
          body: request.body ? JSON.stringify(request.body) : undefined,
          url: request.url ? request.url : '',
          retries: request.retries ? request.retries : 0,
        };
      });
    } else {
      return [];
    }
  }
}
