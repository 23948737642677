import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { AuthFacade } from '@curbnturf/auth';
import { AuthModalFacade } from '@curbnturf/auth-modal/src/lib/+state/auth-modal.facade';
import {
  DisplayValidationErrorsDirective,
  DisplayValidationErrorsSubmitButtonDirective,
  DisplayValidationErrorsSubmitDirective,
} from '@curbnturf/form-helpers';
import { IonicModule } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SocialAuthComponent } from '../social-auth/social-auth.component';

@Component({
  selector: 'curbnturf-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    DisplayValidationErrorsSubmitDirective,
    DisplayValidationErrorsDirective,
    DisplayValidationErrorsSubmitButtonDirective,
    FormsModule,
    IonicModule,
    NgIf,
    ReactiveFormsModule,
    RouterLink,
    SocialAuthComponent,
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() inModal: boolean;

  errorSubscription: Subscription;

  loginForm: FormGroup<{ email: FormControl<string | null>; password: FormControl<string | null> }>;

  constructor(
    private fb: FormBuilder,
    private authFacade: AuthFacade,
    private authModalFacade: AuthModalFacade,
  ) {
    this.loginForm = this.fb.group({
      email: this.fb.control<string>('', [Validators.required, Validators.email]),
      password: this.fb.control<string>('', Validators.required),
    });
  }

  ngOnInit() {
    this.errorSubscription = this.authFacade.error$.subscribe((error) => {
      const passwordInput = this.loginForm.get('password');
      if (error && passwordInput) {
        passwordInput.setValue('');
      }
    });
  }

  ngOnDestroy() {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
  }

  login() {
    if (this.loginForm.valid) {
      this.authFacade.login({ email: this.loginForm.value.email || '', password: this.loginForm.value.password || '' });
    }
  }

  openRecoverModal() {
    this.authModalFacade.openAuthModal('recover');
  }

  openRegisterModal() {
    this.authModalFacade.openAuthModal('register');
  }
}
