import { Injectable } from '@angular/core';
import { IOrder, IReservation, IReservationWhere, Reservation } from '@curbnturf/entities';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import * as ReservationActions from './reservation.actions';
import {
  AcceptReservation,
  CancelReservation,
  CreateReservation,
  LoadAllReservations,
  LoadGuestReservations,
  LoadHostReservations,
  LoadReservation,
  RejectReservation,
  SelectReservation,
  SetReservations,
} from './reservation.actions';
import { reservationQuery } from './reservation.selectors';

@Injectable({
  providedIn: 'root',
})
export class ReservationFacade {
  loaded$: Observable<boolean>;
  error$: Observable<string | undefined>;
  allEntities$: Observable<{ [id: number]: Reservation }>;
  all$: Observable<Reservation[]>;
  allForGuest$: Observable<Reservation[]>;
  allForHost$: Observable<Reservation[]>;
  selected$: Observable<Reservation | undefined>;
  selectedOnce$: Observable<Reservation | undefined>;

  constructor(private store: Store) {
    this.loaded$ = this.store.pipe(select(reservationQuery.getLoaded));
    this.error$ = this.store.pipe(select(reservationQuery.getError));
    this.allEntities$ = this.store.pipe(select(reservationQuery.getAllReservationEntities));
    this.all$ = this.store.pipe(select(reservationQuery.getAllReservations));
    this.allForGuest$ = this.store.pipe(select(reservationQuery.getAllGuestReservations));
    this.allForHost$ = this.store.pipe(select(reservationQuery.getAllHostReservations));
    this.selected$ = this.store.pipe(select(reservationQuery.getSelectedReservation));
    this.selectedOnce$ = this.selected$.pipe(
      skipWhile((reservation) => !reservation || !reservation.id),
      take(1),
    );
  }

  load(id: number) {
    this.store.dispatch(new LoadReservation(id));
  }
  loadAll(where?: IReservationWhere) {
    this.store.dispatch(new LoadAllReservations(where));
  }
  loadAllForGuest() {
    this.store.dispatch(new LoadGuestReservations());
  }
  loadAllForHost() {
    this.store.dispatch(new LoadHostReservations());
  }
  create(reservation: IReservation) {
    this.store.dispatch(new CreateReservation(reservation));
  }
  select(reservation?: IReservation) {
    this.store.dispatch(new SelectReservation(reservation));
  }
  accept(reservation: IReservation, reason: string) {
    this.store.dispatch(new AcceptReservation({ reservation, reason }));
  }
  reject(reservation: IReservation, reason: string) {
    this.store.dispatch(new RejectReservation({ reservation, reason }));
  }
  cancel(reservation: IReservation, reason: string) {
    this.store.dispatch(new CancelReservation({ reservation, reason }));
  }
  change(reservation: IReservation, order: IOrder) {
    this.store.dispatch(ReservationActions.changeReservation({ reservation, order }));
  }
  set(reservations: IReservation[]) {
    this.store.dispatch(new SetReservations({ reservations }));
  }
}
