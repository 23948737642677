import { HttpErrorResponse } from '@angular/common/http';
import { ICalendarDay, ISchedule } from '@curbnturf/entities';
import { Action } from '@ngrx/store';

export enum ScheduleActionTypes {
  StoreSchedule = '[Schedule] Store Schedule',
  CreateSchedule = '[Schedule] Create Schedule',
  CreateScheduleFailed = '[Schedule] Create Schedule Failed',
  LoadSchedule = '[Schedule] Load Data',
  LoadSchedules = '[Schedule] Load Schedules',
  LoadScheduleFailed = '[Schedule] Load Data Failed',
  ClearSchedules = '[Schedule] Clear Schedules',
  UpdateSchedule = '[Schedule] Update',
  UpdateScheduleFailed = '[Schedule] Update Failed',
  RemoveSchedule = '[Schedule] Remove Schedule',
  ScheduleCreated = '[Schedule] Created',
  ScheduleLoaded = '[Schedule] Data Loaded',
  SchedulesLoaded = '[Schedule] Data For Schedules Loaded',
  ScheduleRemoved = '[Schedule] Removed',
  ScheduleUpdated = '[Schedule] Updated',
  ScheduleSet = '[Schedule] Set Schedule State',
  SelectSchedule = '[Schedule] Select Schedule',
  ScheduleSelected = '[Schedule] Schedule Selected',
  DonateSchedule = '[Schedule] Donate',
  ScheduleDonated = '[Schedule] Donated',
  ScheduleDonateFailed = '[Schedule] Donate Failed',
  CaretakerRequest = '[Schedule] Caretaker Request',
  RequestDays = '[Schedule] Fill the calendar days',
  DaysFilled = '[Schedule] calendar days Filled',
}

export class StoreSchedule implements Action {
  readonly type = ScheduleActionTypes.StoreSchedule;
  constructor(public payload?: ISchedule) {}
}

export class CreateSchedule implements Action {
  readonly type = ScheduleActionTypes.CreateSchedule;
  constructor(public payload: ISchedule) {}
}

export class CreateScheduleFailed implements Action {
  readonly type = ScheduleActionTypes.CreateScheduleFailed;
  constructor(public payload: string | HttpErrorResponse) {}
}

export class LoadSchedule implements Action {
  readonly type = ScheduleActionTypes.LoadSchedule;
  constructor(public payload: string) {}
}

export class LoadSchedules implements Action {
  readonly type = ScheduleActionTypes.LoadSchedules;
  constructor(public payload?: { siteId?: number; email?: string }) {}
}

export class LoadScheduleFailed implements Action {
  readonly type = ScheduleActionTypes.LoadScheduleFailed;
  constructor(public payload: string | HttpErrorResponse) {}
}

export class ClearSchedules implements Action {
  readonly type = ScheduleActionTypes.ClearSchedules;
}

export class RemoveSchedule implements Action {
  readonly type = ScheduleActionTypes.RemoveSchedule;
  constructor(public payload: string) {}
}

export class ScheduleCreated implements Action {
  readonly type = ScheduleActionTypes.ScheduleCreated;
  constructor(public payload: ISchedule) {}
}

export class ScheduleLoaded implements Action {
  readonly type = ScheduleActionTypes.ScheduleLoaded;
  constructor(public payload: ISchedule) {}
}

export class ScheduleRemoved implements Action {
  readonly type = ScheduleActionTypes.ScheduleRemoved;
  constructor(public payload: ISchedule) {}
}

export class SchedulesLoaded implements Action {
  readonly type = ScheduleActionTypes.SchedulesLoaded;
  constructor(public payload: ISchedule[]) {}
}

export class UpdateSchedule implements Action {
  readonly type = ScheduleActionTypes.UpdateSchedule;
  constructor(public payload: ISchedule) {}
}

export class SetSchedule implements Action {
  readonly type = ScheduleActionTypes.ScheduleSet;
  constructor(public payload: ISchedule[]) {}
}

export class ScheduleUpdated implements Action {
  readonly type = ScheduleActionTypes.ScheduleUpdated;
  constructor(public payload: ISchedule) {}
}

export class UpdateScheduleFailed implements Action {
  readonly type = ScheduleActionTypes.UpdateScheduleFailed;
  constructor(public payload: string | HttpErrorResponse) {}
}

export class SelectSchedule implements Action {
  readonly type = ScheduleActionTypes.SelectSchedule;
  constructor(public payload?: string) {}
}

export class ScheduleSelected implements Action {
  readonly type = ScheduleActionTypes.ScheduleSelected;
  constructor(public payload?: ISchedule) {}
}

export class DonateSchedule implements Action {
  readonly type = ScheduleActionTypes.DonateSchedule;
  constructor(public payload: { confirmationCode: string; price: number }) {}
}

export class ScheduleDonated implements Action {
  readonly type = ScheduleActionTypes.ScheduleDonated;
  constructor(public payload: ISchedule) {}
}

export class ScheduleDonateFailed implements Action {
  readonly type = ScheduleActionTypes.ScheduleDonateFailed;
  constructor(public payload: string | HttpErrorResponse) {}
}

export class CaretakerRequest implements Action {
  readonly type = ScheduleActionTypes.CaretakerRequest;
  constructor(public payload: number) {}
}

export class RequestDays implements Action {
  readonly type = ScheduleActionTypes.RequestDays;
  constructor(public payload: number) {}
}

export class DaysFilled implements Action {
  readonly type = ScheduleActionTypes.DaysFilled;
  constructor(public payload: ICalendarDay[]) {}
}

export type ScheduleActions =
  | StoreSchedule
  | CreateSchedule
  | CreateScheduleFailed
  | LoadSchedule
  | LoadSchedules
  | LoadScheduleFailed
  | ClearSchedules
  | ScheduleCreated
  | ScheduleLoaded
  | SchedulesLoaded
  | UpdateSchedule
  | ScheduleUpdated
  | SetSchedule
  | UpdateScheduleFailed
  | ScheduleRemoved
  | RemoveSchedule
  | SelectSchedule
  | ScheduleSelected
  | DonateSchedule
  | ScheduleDonated
  | ScheduleDonateFailed
  | RequestDays
  | DaysFilled
  | CaretakerRequest;

export const fromScheduleActions = {
  StoreSchedule,
  CreateSchedule,
  CreateScheduleFailed,
  LoadSchedule,
  LoadSchedules,
  LoadScheduleFailed,
  ClearSchedules,
  ScheduleCreated,
  ScheduleLoaded,
  SchedulesLoaded,
  UpdateSchedule,
  ScheduleUpdated,
  SetSchedule,
  UpdateScheduleFailed,
  ScheduleRemoved,
  RemoveSchedule,
  SelectSchedule,
  ScheduleSelected,
  DonateSchedule,
  ScheduleDonated,
  ScheduleDonateFailed,
  RequestDays,
  DaysFilled,
  CaretakerRequest,
};
