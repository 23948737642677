export enum ListingType {
  standard = 'standard',
  boondock = 'boondock',
}

export interface IListingType {
  type: 'standard' | 'boondock';
}

export enum AffiliateType {
  raf = 'Refer a Friend',
  grow = 'Grow Program',
}

export enum HelpRequestType {
  host = 'I am ready to host',
  question = 'I have some questions',
  listing = 'I need help with my listing',
  concerns = 'I have some concerns',
}

export enum HelpRequestLabel {
  host = 'host',
  question = 'question',
  listing = 'listing',
  concerns = 'concerns',
}

export interface IAffiliateType {
  type: 'raf' | 'grow';
}

export enum Role {
  guest = 'guest',
  host = 'host',
  boondocker = 'boondocker',
  admin = 'admin',
  affiliate = 'affiliate',
  sales = 'sales',
  salesManager = 'salesmanager',
  report = 'report',
}

export enum SignalSource {
  gps = 'GPS',
  att = 'AT&T',
  sprint = 'Sprint',
  tMobile = 'T-Mobile',
  verizon = 'Verizon',
}

export enum SignalStrength {
  'There is no coverage',
  'Poor',
  'Fair',
  'Good',
  'Excellent',
}
