import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

const urlCleanRegex = /[\W_]+/g;

@Injectable()
export class ListingForwarderGuard implements CanActivate {
  constructor(private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (route.params['siteName']) {
      const siteName = route.params['siteName'].replace(urlCleanRegex, '-');
      await this.router.navigate([`/rv-camping/${siteName}/${route.params['siteId']}`]);
    } else if (route.params['name'] && /^\d+$/.test(route.params['name'])) {
      // site ID and Name are swapped.
      const siteName = route.params['siteId'].replace(urlCleanRegex, '-');
      await this.router.navigate([`/rv-camping/${siteName}/${route.params['name']}`]);
    } else {
      if (route.data.deprecated) {
        let params = '';
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [key, value] of Object.entries(route.params)) {
          params += '/' + value;
        }
        await this.router.navigate([`/rv-camping${params}`]);
      }
      return true;
    }
    return false;
  }
}
