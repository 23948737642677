import { HttpErrorResponse } from '@angular/common/http';
import { ConnectionStatus } from '@capacitor/network';
import { CachableHttpRequest, CachedHttpResponse, ISite, IUser } from '@curbnturf/entities';
import { Action, createReducer, on } from '@ngrx/store';
import * as NetworkActions from './network.actions';

export const CACHE_MEMORY_LIMIT = 20;

export interface NetworkState {
  status: ConnectionStatus; // Network Status
  cachedHttpRequests: CachableHttpRequest[]; // Cached requests
  cachedHttpResponses: CachedHttpResponse[];
  siteUpdates: ISite[];
  sitePhotoUpdates: { [syncId: string]: FormData[] };
  userUpdate?: IUser;
  userPhotoUpdate?: {
    formData: FormData;
    data: {
      id: number;
    };
  };
  override: boolean;
}

export const initialState: NetworkState = {
  // set initial required properties
  status: { connected: true, connectionType: 'wifi' },
  cachedHttpRequests: [],
  cachedHttpResponses: [],
  siteUpdates: [],
  sitePhotoUpdates: {},
  override: false,
};

export const networkReducer = createReducer(
  initialState,
  on(NetworkActions.setNetworkStatus, (state, action) => {
    if (state.override !== true) {
      return {
        ...state,
        status: { ...action.status },
      };
    }
    return state;
  }),
  on(NetworkActions.cacheHttpRequest, (state, action) => {
    if (state.cachedHttpRequests.length < CACHE_MEMORY_LIMIT) {
      const cachedHttpRequests = state.cachedHttpRequests.slice();
      cachedHttpRequests.push(action.request);
      return {
        ...state,
        cachedHttpRequests,
      };
    } else {
      return state;
    }
  }),
  on(NetworkActions.cacheHttpResponse, (state, action) => {
    const newCachedResponse = action.response;
    const cachedHttpResponses = state.cachedHttpResponses.slice();
    const currentResponse = cachedHttpResponses.findIndex(
      (el) => el.url === newCachedResponse.url && el.method === newCachedResponse.method,
    );
    if (currentResponse === -1) {
      cachedHttpResponses.push(newCachedResponse);
    } else {
      cachedHttpResponses.splice(currentResponse, 1, newCachedResponse);
    }
    return {
      ...state,
      cachedHttpResponses,
    };
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on(NetworkActions.processCachedRequestSuccess, (state, action: { request: CachableHttpRequest; response: any }) => {
    const cachedHttpRequests = [...state.cachedHttpRequests];
    cachedHttpRequests.splice(cachedHttpRequests.findIndex((el) => el === action.request));
    return {
      ...state,
      cachedHttpRequests,
    };
  }),
  on(
    NetworkActions.processCachedRequestFailed,
    (state, action: { error: HttpErrorResponse; request: CachableHttpRequest }) => {
      const cachedHttpRequests = state.cachedHttpRequests.slice();
      const foundIndex = cachedHttpRequests.findIndex((el) => {
        if (el.body?.syncId && action.request.body?.syncId && el.body.syncId === action.request.body.syncId) {
          return true;
        } else if (el.syncId && action.request.syncId && el.syncId === action.request.syncId) {
          return true;
        }
        return false;
      });
      if (foundIndex !== -1) {
        cachedHttpRequests.splice(foundIndex, 1);

        const error = action.error;
        if (error.status < 200 || (error.status > 300 && error.status < 400) || error.status >= 500) {
          // increment the error count and add back to the list.
          if (action.request.retries < CachableHttpRequest.MAX_RETRIES) {
            cachedHttpRequests.push(
              new CachableHttpRequest({
                ...action.request,
                retries: action.request.retries + 1,
              }),
            );
          }
        }
      }

      return {
        ...state,
        cachedHttpRequests,
      };
    },
  ),
  on(NetworkActions.restoreCachedRequests, (state, action: { requests: CachableHttpRequest[] }) => {
    return {
      ...state,
      cachedHttpRequests: [...action.requests],
    };
  }),
  on(NetworkActions.restoreCachedResponses, (state, action: { responses: CachedHttpResponse[] }) => {
    return {
      ...state,
      cachedHttpResponses: [...action.responses],
    };
  }),
  on(NetworkActions.setNetworkOverride, (state, action: { override: boolean; status: ConnectionStatus }) => {
    return {
      ...state,
      override: action.override,
      status: action.status,
    };
  }),
  on(NetworkActions.pendingSiteUpdate, (state, action) => {
    const foundSite = state.siteUpdates.find((el) => el.syncId === action.site.syncId);

    const site = {
      ...foundSite,
      ...action.site,
    };

    const siteUpdates = [...state.siteUpdates.slice().filter((el) => el.syncId !== action.site.syncId), site];

    return {
      ...state,
      siteUpdates,
    };
  }),
  on(NetworkActions.removePendingSite, (state, action) => {
    const siteUpdates = [...state.siteUpdates.slice().filter((el) => el.syncId !== action.site.syncId)];

    return {
      ...state,
      siteUpdates,
    };
  }),
  on(NetworkActions.deferUserUpdate, (state, action) => {
    const userUpdate = {
      ...state.userUpdate,
      ...action.user,
    };

    return {
      ...state,
      userUpdate,
    };
  }),
  on(NetworkActions.removeDeferUserUpdate, (state) => {
    const newState = { ...state };

    delete newState.userUpdate;

    return newState;
  }),
  on(NetworkActions.deferUserImageUpload, (state, action) => {
    return {
      ...state,
      userPhotoUpdate: action,
    };
  }),
  on(NetworkActions.removeDeferUserImageUpload, (state) => {
    const newState = { ...state };

    delete newState.userPhotoUpdate;

    return newState;
  }),
  on(NetworkActions.pendingSiteImageUpload, (state, action) => {
    const sitePhotoUpdates = {
      ...state.sitePhotoUpdates,
      [action.syncId]: [...(state.sitePhotoUpdates[action.syncId] || []), action.photo],
    };

    return {
      ...state,
      sitePhotoUpdates,
    };
  }),
  on(NetworkActions.removePendingSiteImageUpload, (state, action) => {
    const newState = { ...state, sitePhotoUpdates: { ...state.sitePhotoUpdates } };

    if (newState.sitePhotoUpdates?.[action.syncId]) {
      delete newState.sitePhotoUpdates[action.syncId];
    }

    return newState;
  }),
);

export function reducer(state: NetworkState | undefined, action: Action) {
  return networkReducer(state, action);
}
