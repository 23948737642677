export enum AmenityType {
  pullThrough = 'Pull Through',
  backIn = 'Back In',
  electricity = 'Electricity',
  water = 'Potable Water',
  dump = 'Dump Station',
  sewage = 'Sewage',
  cell = 'Cell Phone Coverage',
  wifi = 'WiFi',
  firesAllowed = 'Campfires Allowed',
  petsAllowed = 'Pets Allowed',
  trash = 'Trash Provided',
  recycling = 'Recycle Provided',
  table = 'Picnic Table',
  restroom = 'Toilet Available',
  shower = 'Shower',
  laundry = 'Laundry',
  tents = 'Tents Allowed',
  vehicles = 'Vehicles Allowed',
  pool = 'Hot Tub/Pool',
  wheelchair = 'Wheelchair Accessible',
  custom = 'Custom',
}

export interface IAmenityType {
  type:
    | 'pullThrough'
    | 'backIn'
    | 'electricity'
    | 'water'
    | 'dump'
    | 'sewage'
    | 'cell'
    | 'wifi'
    | 'firesAllowed'
    | 'petsAllowed'
    | 'trash'
    | 'recycling'
    | 'table'
    | 'restroom'
    | 'shower'
    | 'laundry'
    | 'tents'
    | 'vehicles'
    | 'pool'
    | 'wheelchair'
    | 'custom';
}
