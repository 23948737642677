import { Injectable, TemplateRef } from '@angular/core';
import { DayTemplateContext } from './datepicker-day-template-context';
import { IDatepickerStruct } from './datepicker-struct';

/**
 * A configuration service for the [`NgbDatepicker`](#/here-map/datepicker/api#NgbDatepicker) component.
 *
 * You can inject this service, typically in your root component, and customize the values of its properties in
 * order to provide default values for all the datepickers used in the application.
 */
@Injectable({ providedIn: 'root' })
export class DatepickerConfig {
  dayTemplate: TemplateRef<DayTemplateContext>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dayTemplateData: (date: IDatepickerStruct, current: { year: number; month: number }) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  footerTemplate: TemplateRef<any>;
  displayMonths = 1;
  isDisabled: (date: IDatepickerStruct, current: { year: number; month: number }) => boolean;
  minDate: IDatepickerStruct;
  maxDate: IDatepickerStruct;
  navigation: 'select' | 'arrows' | 'none' = 'select';
  outsideDays: 'visible' | 'collapsed' | 'hidden' = 'visible';
  showWeekdays = true;
  showWeekNumbers = false;
  startDate: { year: number; month: number };
}
