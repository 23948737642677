import { animate, style, transition, trigger } from '@angular/animations';
import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs';

@Component({
  selector: 'curbnturf-update',
  animations: [
    trigger('updateVisibilityAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('250ms', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)' }),
        animate('250ms', style({ transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class UpdateComponent {
  updateReady = false;

  constructor(private swUpdate: SwUpdate) {
    this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map((evt) => (this.updateReady = true)),
    );
  }
}
