import { toPrecision } from '@curbnturf/helpers';
import { PriceType } from '../enums';
import { IPriceComponent, IReservation, ISiteExtra, ITax } from '../interfaces';

export class PriceComponent implements IPriceComponent {
  id?: number;
  reservation?: IReservation;
  date?: string;
  type: PriceType;
  value: number; // USD
  fee?: number; // USD
  taxes?: {
    tax: ITax;
    value: number;
  }[];
  extra?: {
    extra: ISiteExtra;
    quantity: number; // USD
  };
  discounts?: {
    name: string;
    value: number; // USD
  }[];
  pendingChange?: boolean;

  get discount(): number {
    return this.discounts ? this.discounts.reduce((total, discount) => total + discount.value, 0) : 0;
  }

  get tax(): number {
    return this.taxes ? this.taxes.reduce((total, tax) => total + tax.value, 0) : 0;
  }

  constructor(data?: IPriceComponent) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IPriceComponent): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    // set value to two decimal places
    if (this.value) {
      this.value = toPrecision(this.value, 2);
    }

    if (this.fee) {
      this.fee = toPrecision(this.fee, 2);
    }
  }
}
