import { NgModule } from '@angular/core';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { RatingModule } from '@curbnturf/rating';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as activityEffects from './+state/activity.effects';
import { activityReducer, initialState as activityInitialState } from './+state/activity.reducer';

@NgModule({
  imports: [RatingModule],
  providers: [
    provideState(NGRX_BUCKETS.activity, activityReducer, {
      initialState: activityInitialState,
    }),
    provideEffects(activityEffects),
  ],
})
export class ActivityModule {}
