import { formatDate } from '@angular/common';
import { DateTime, Info } from 'luxon';
import { isDefined } from '../util/util';
import { IDatepickerStruct } from './datepicker-struct';
import { DatepickerViewModel, DayViewModel, MonthViewModel } from './datepicker-view-model';

export function isChangedDate(prev?: DateTime, next?: DateTime) {
  return !dateComparator(prev, next);
}

export function isChangedMonth(prev?: DateTime, next?: DateTime) {
  return !prev && !next ? false : !prev || !next ? true : prev.year !== next.year || prev.month !== next.month;
}

export function dateComparator(prev?: DateTime, next?: DateTime) {
  return (!prev && !next) || (!!prev && !!next && prev.equals(next));
}

export function checkMinBeforeMax(minDate?: DateTime, maxDate?: DateTime) {
  if (maxDate && minDate && maxDate < minDate) {
    throw new Error(`'maxDate' ${maxDate} should be greater than 'minDate' ${minDate}.`);
  }
}

export function checkDateInRange(date?: DateTime, minDate?: DateTime, maxDate?: DateTime): DateTime | undefined {
  if (date && minDate && date < minDate) {
    return minDate;
  }
  if (date && maxDate && date > maxDate) {
    return maxDate;
  }

  return date;
}

export function isDateSelectable(date?: DateTime, state?: DatepickerViewModel) {
  if (!date || !state) {
    return false;
  }

  const { minDate, maxDate, disabled, isDisabled } = state;
  // clang-format off
  return !(
    !isDefined(date) ||
    disabled ||
    (isDisabled && isDisabled(date)) ||
    (minDate && date < minDate) ||
    (maxDate && date > maxDate)
  );
  // clang-format on
}

export function generateSelectBoxMonths(date?: DateTime, minDate?: DateTime, maxDate?: DateTime) {
  if (!date) {
    return [];
  }

  let months = Info.months('numeric').map((month) => parseInt(month, 10));

  if (minDate && date.year === minDate.year) {
    const index = months.findIndex((month) => month === minDate.month);
    months = months.slice(index);
  }

  if (maxDate && date.year === maxDate.year) {
    const index = months.findIndex((month) => month === maxDate.month);
    months = months.slice(0, index + 1);
  }

  return months;
}

export function generateSelectBoxYears(date?: DateTime, minDate?: DateTime, maxDate?: DateTime) {
  if (!date || !date.year) {
    return [];
  }

  const start = minDate && minDate.year ? Math.max(minDate.year, date.year - 500) : date.year - 10;
  const end = maxDate && maxDate.year ? Math.min(maxDate.year, date.year + 500) : date.year + 10;

  const length = end - start + 1;
  const numbers = Array(length);
  for (let i = 0; i < length; i++) {
    numbers[i] = start + i;
  }

  return numbers;
}

export function nextMonthDisabled(date?: DateTime, maxDate?: DateTime): boolean {
  if (!date) {
    return true;
  }
  const nextDate = date.plus({ month: 1 }).startOf('day');
  return Boolean(maxDate && nextDate > maxDate);
}

export function prevMonthDisabled(date?: DateTime, minDate?: DateTime): boolean {
  if (!date) {
    return true;
  }
  const prevDate = date.minus({ month: 1 }).startOf('day');
  return Boolean(
    minDate &&
      minDate.year &&
      prevDate.year &&
      minDate.month &&
      prevDate.month &&
      ((prevDate.year === minDate.year && prevDate.month < minDate.month) ||
        (prevDate.year < minDate.year && minDate.month === 1)),
  );
}

export function buildMonths(
  date: DateTime,
  state: DatepickerViewModel,
  force: boolean,
): (MonthViewModel | undefined)[] {
  const { displayMonths, months } = state;
  // move old months to a temporary array
  const monthsToReuse = months.splice(0, months.length);

  // generate new first dates, nullify or reuse months
  const firstDates = Array.from({ length: displayMonths ? displayMonths : 1 }, (_, i) => {
    const firstDate = date.plus({ month: i }).startOf('day');
    (months as (MonthViewModel | undefined)[])[i] = undefined;

    if (!force) {
      const reusedIndex = monthsToReuse.findIndex((month) => {
        if (month && month.firstDate) {
          return month.firstDate.equals(firstDate);
        }

        return false;
      });
      // move reused month back to months
      if (reusedIndex !== -1) {
        months[i] = monthsToReuse.splice(reusedIndex, 1)[0];
      }
    }

    return firstDate;
  });

  // rebuild nullified months
  firstDates.forEach((firstDate: DateTime, i: number) => {
    if (!months[i]) {
      months[i] = buildMonth(state, firstDate, monthsToReuse.shift() || ({} as MonthViewModel));
    }
  });

  return months;
}

export function getDayAriaLabel(date: IDatepickerStruct): string {
  if (date && date.year && date.month && date.day) {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return formatDate(jsDate, 'fullDate', 'en-US');
  }

  return '';
}

const monthsShort = Info.months('short');
const monthsFull = Info.months('long');
const weekdaysShort = Info.weekdays('short').map((weekday) => weekday.slice(0, 2));

export function getMonthShortName(month: number): string {
  return monthsShort[month - 1];
}
export function getMonthFullName(month: number): string {
  return monthsFull[month - 1];
}

export function getWeekdayShortName(weekday: number): string {
  return weekdaysShort[weekday - 1];
}

export function buildMonth(
  state: DatepickerViewModel,
  date?: DateTime,
  month: MonthViewModel = {} as MonthViewModel,
): MonthViewModel {
  const { dayTemplateData, minDate, maxDate, isDisabled, outsideDays } = state;
  const calendarToday = DateTime.now().startOf('day');

  delete month.firstDate;
  delete month.lastDate;
  month.number = date ? date.month : calendarToday.month;
  month.year = date ? date.year : calendarToday.year;
  month.weeks = month.weeks || [];
  month.weekdays = month.weekdays || [];

  date = getFirstViewDate(date ? date : calendarToday);

  // month has weeks
  for (let week = 0; week < 6; week++) {
    let weekObject = month.weeks[week];
    if (!weekObject) {
      weekObject = month.weeks[week] = { number: 0, days: [], collapsed: true };
    }
    const days = weekObject.days;

    // week has days
    for (let day = 0; day < 7; day++) {
      if (week === 0) {
        month.weekdays[day] = date ? date.weekday : calendarToday.weekday;
      }
      if (!date) {
        date = calendarToday;
      }

      const newDate = date.plus({ days: 0 }).startOf('day');
      const nextDate = newDate.plus({ day: 1 });

      const ariaLabel = getDayAriaLabel(newDate);

      // marking date as disabled
      let disabled = !!((minDate && newDate < minDate) || (maxDate && newDate > maxDate));
      if (!disabled && isDisabled) {
        disabled = isDisabled(newDate);
      }

      // today
      const today = newDate.equals(calendarToday);

      // adding user-provided data to the context
      const contextUserData = dayTemplateData
        ? dayTemplateData(newDate, { month: month.number, year: month.year })
        : undefined;

      // saving first date of the month
      if (!month.firstDate && newDate.month === month.number) {
        month.firstDate = newDate;
      }

      // saving last date of the month
      if (newDate.month === month.number && nextDate && nextDate.month !== month.number) {
        month.lastDate = newDate;
      }

      let dayObject = days[day];
      if (!dayObject) {
        dayObject = days[day] = {} as DayViewModel;
      }
      dayObject.date = newDate;
      dayObject.context = Object.assign(dayObject.context || {}, {
        $implicit: newDate,
        date: newDate,
        data: contextUserData,
        currentMonth: month.number,
        currentYear: month.year,
        disabled,
        focused: false,
        selected: false,
        today,
      });
      dayObject.tabindex = -1;
      dayObject.ariaLabel = ariaLabel;
      dayObject.hidden = false;

      date = nextDate;
    }

    // marking week as collapsed
    weekObject.collapsed =
      outsideDays === 'collapsed' &&
      days[0].date.month !== month.number &&
      days[days.length - 1].date.month !== month.number;
  }

  return month;
}

export function getFirstViewDate(date: DateTime): DateTime | undefined {
  const firstMonthDate = DateTime.fromObject({ year: date.year, month: date.month, day: 1 });
  const dayOfWeek = firstMonthDate.weekday % 7;
  return firstMonthDate.minus({ day: dayOfWeek });
}
