import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DateTime } from 'luxon';
import { DatepickerNavigationSelect } from './datepicker-navigation-select';
import { getMonthFullName } from './datepicker-tools';
import { MonthViewModel, NavigationEvent } from './datepicker-view-model';

@Component({
  selector: 'curbnturf-datepicker-navigation',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./datepicker-navigation.scss'],
  template: `
    <div class="ngb-dp-arrow">
      <button
        type="button"
        class="ngb-dp-arrow-btn"
        (click)="onClickPrev($event)"
        [disabled]="prevDisabled"
        aria-label="Previous month"
        title="Previous month"
        data-test="datepicker-nav-previous-month"
      >
        <span class="ngb-dp-navigation-chevron"></span>
      </button>
    </div>
    <curbnturf-datepicker-navigation-select
      *ngIf="showSelect"
      class="ngb-dp-navigation-select"
      [date]="date"
      [disabled]="disabled"
      [months]="selectBoxes.months"
      [years]="selectBoxes.years"
      (select)="select.emit($event)"
    >
    </curbnturf-datepicker-navigation-select>

    <ng-template *ngIf="!showSelect" ngFor let-month [ngForOf]="months" let-i="index">
      <div class="ngb-dp-arrow" *ngIf="i > 0"></div>
      <div class="ngb-dp-month-name">
        {{ getMonthFullName(month.number) }}
        {{ month.year }}
      </div>
      <div class="ngb-dp-arrow" *ngIf="i !== months.length - 1"></div>
    </ng-template>
    <div class="ngb-dp-arrow arrow-right">
      <button
        type="button"
        class="ngb-dp-arrow-btn"
        (click)="onClickNext($event)"
        [disabled]="nextDisabled"
        aria-label="Next month"
        title="Next month"
        data-test="datepicker-nav-next-month"
      >
        <span class="ngb-dp-navigation-chevron"></span>
      </button>
    </div>
  `,
  standalone: true,
  imports: [NgIf, DatepickerNavigationSelect, NgFor],
})
export class DatepickerNavigation {
  navigation = NavigationEvent;
  getMonthFullName = getMonthFullName;
  @Input() date: DateTime;
  @Input() disabled: boolean;
  @Input() months: MonthViewModel[] = [];
  @Input() showSelect: boolean;
  @Input() prevDisabled: boolean;
  @Input() nextDisabled: boolean;
  @Input() selectBoxes: { years: number[]; months: number[] };

  @Output() navigate = new EventEmitter<NavigationEvent>();
  @Output() select = new EventEmitter<DateTime>();

  onClickPrev(event: MouseEvent) {
    (event.currentTarget as HTMLElement).focus();
    this.navigate.emit(this.navigation.PREV);
  }

  onClickNext(event: MouseEvent) {
    (event.currentTarget as HTMLElement).focus();
    this.navigate.emit(this.navigation.NEXT);
  }
}
