import { IUser, NGRX_BUCKETS, Role } from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.reducer';

// Lookup the 'Auth' feature state managed by NgRx
export const getAuthState = createFeatureSelector<AuthState>(NGRX_BUCKETS.auth);

export const getLoaded = createSelector(getAuthState, (state: AuthState) => state.loaded);
export const getError = createSelector(getAuthState, (state: AuthState) => state.error);

export const getUser = createSelector(getAuthState, getLoaded, (state: AuthState, isLoaded) =>
  isLoaded ? state.user : undefined,
);

export const getUserId = createSelector(getUser, (user: IUser) => (user ? user.id : undefined));

export const getAccessToken = createSelector(getUser, (user: IUser) => (user ? user.authAccessToken : undefined));
export const getAuthSession = createSelector(getUser, (user: IUser) => {
  if (user) {
    return {
      authIdToken: user.authIdToken,
      authAccessToken: user.authAccessToken,
      authRenewToken: user.authRenewToken,
    };
  }
  return {
    IdToken: '',
    AccessToken: '',
    RefreshToken: '',
  };
});

export const getTempRole = createSelector(getAuthState, (state: AuthState): Role | undefined =>
  state ? state.tempRole : undefined,
);

export const getNoRedirect = createSelector(getAuthState, (state: AuthState) => state.noRedirect);

export const getLoggedIn = createSelector(getAuthState, getLoaded, (state: AuthState, isLoaded) =>
  isLoaded ? state.loggedIn : false,
);

export const getAttemptEmail = createSelector(getAuthState, (state: AuthState) => state.attemptEmail);

export const getNewPasswordSet = createSelector(
  getAuthState,
  (state: AuthState): boolean | undefined => state.newPasswordSet,
);
