import { IPoiFavorite, IUser } from '../interfaces';
import { ActivityLocation } from './activity-location';

export class PoiFavorite implements IPoiFavorite {
  id?: number;
  userId: number;
  user?: IUser;
  poiId: number;
  poi?: ActivityLocation;

  constructor(data?: IPoiFavorite) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IPoiFavorite): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (data.poi) {
      this.poi = new ActivityLocation(data.poi);
    }
  }
}
