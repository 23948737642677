import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ConnectionStatus } from '@capacitor/network';
import { Preferences } from '@capacitor/preferences';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { AlertFacade } from '@curbnturf/alert/src/lib/+state/alert.facade';
import { HelperFunctions, IPushMessage } from '@curbnturf/entities';
import { MessageFacade } from '@curbnturf/message/src/lib/+state/message.facade';
import { Logger } from '@curbnturf/network';
import { ReservationFacade } from '@curbnturf/reservation/src/lib/+state/reservation.facade';
import { StatusFacade } from '@curbnturf/status';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import debounce = HelperFunctions.debounce;

/**
 * A Small wrapper for the Capacitor Push Notifications API
 */
@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  available = true;
  registered = false;

  notificationReceived: Subject<PushNotificationSchema> = new Subject<PushNotificationSchema>();
  actionPerformed: Subject<ActionPerformed> = new Subject<ActionPerformed>();

  connectionStatus: ConnectionStatus;
  connectionSub: Subscription;

  register: () => void;

  constructor(
    private alertFacade: AlertFacade,
    private logger: Logger,
    private messageFacade: MessageFacade,
    private modalController: ModalController,
    private navController: NavController,
    private router: Router,
    private reservationFacade: ReservationFacade,
    private statusFacade: StatusFacade,
    private toastController: ToastController,
  ) {
    if (!Capacitor.isNativePlatform()) {
      this.available = false;
    }

    this.register = debounce(() => {
      this.connectionSub = this.statusFacade.networkStatus$.subscribe(async (status) => {
        this.connectionStatus = status;

        if (this.available && !this.registered) {
          // If connected start the registration. Else, wait for a network change to become online before registering.
          if (status.connected) {
            this.logger.debug('Registering for Push Notifications');
            this.registered = true;
            await PushNotifications.register();

            // kill the subscription now that we are registered.
            if (this.connectionSub && !this.connectionSub.closed) {
              this.connectionSub.unsubscribe();
            }
          }
        } else {
          // If we're not available or already registered than clear the subscription.
          if (this.connectionSub && !this.connectionSub.closed) {
            this.connectionSub.unsubscribe();
          }
        }
      });
    }, 1000);
  }

  forceAvailability() {
    this.available = true;
  }

  registerListeners() {
    if (this.available) {
      // Setup Push Notifications
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: Token) => {
        this.messageFacade.savePushToken(token.value);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: any) => {
        this.logger.error('Error on registration', { error });
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
        this.notificationReceived.next(notification);
        this.logger.debug('Push Notifications Received', { notification });
        if (notification.data.message) {
          const data: IPushMessage = JSON.parse(notification.data.message);

          // If there is a message channel, reload the messages for that channel when the notification is received.
          if (data.channelId) {
            this.messageFacade.load(data.channelId);

            this.messageFacade.chatModalActiveOnce$
              .pipe(withLatestFrom(this.messageFacade.selectedMessageChannel$))
              .subscribe(async ([active, channel]) => {
                // Don't notify if already chatting on that channel
                if (!active || (active && channel?.id !== data.channelId)) {
                  const self = this;

                  // Show the notification in a toast message.
                  const toast = await this.toastController.create({
                    header: notification.title,
                    message: notification.body,
                    duration: 5000,
                    icon: 'notifications',
                    color: 'orange',
                    buttons: [
                      {
                        text: 'Reply',
                        side: 'end',
                        async handler() {
                          self.modalController.dismiss(undefined, undefined, 'chatModal').finally(() => {
                            self.messageFacade.showChatModal(true, data.channelId);
                          });
                          await toast.dismiss();
                        },
                      },
                    ],
                  });

                  await toast.present();
                } else if (data.channelId) {
                  this.logger.debug('Received Push - Reloading Channel:' + data.channelId);
                  this.messageFacade.load(data.channelId);
                }
              });
          } else if (data.reservationId) {
            const self = this;
            const id = data.reservationId;
            const url = `/reservation-processing/${id}`;

            // We aren't currently on that page
            if (self.router.url !== url) {
              // Show the notification in a toast message, allow the user to go to the reservation details.
              const toast = await this.toastController.create({
                header: notification.title,
                message: notification.body,
                duration: 5000,
                icon: 'notifications',
                color: 'orange',
                buttons: [
                  {
                    text: 'View',
                    side: 'end',
                    async handler() {
                      if (id) {
                        self.reservationFacade.load(id);
                        await self.navController.navigateRoot(url);
                      } else {
                        self.alertFacade.display({
                          title: 'Could Not Find Reservation',
                          body: 'Reservation information was missing in the notification.',
                          level: 'error',
                        });
                      }
                    },
                  },
                ],
              });

              await toast.present();
            } else {
              // reload the reservation in case something has updated.
              this.reservationFacade.load(id);
            }
          } else {
            // Show the notification in a toast message.
            const toast = await this.toastController.create({
              header: notification.title,
              message: notification.body,
              duration: 5000,
              icon: 'notifications',
              color: 'orange',
            });

            await toast.present();
          }
        }
      });

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
        this.logger.debug('Push action performed', { notification });
        this.actionPerformed.next(notification);

        const fcmMessage: IPushMessage = notification.notification.data.message;
        if (fcmMessage.channelId && !fcmMessage.reservation) {
          this.messageFacade.showChatModal(true, fcmMessage.channelId);
        } else if (fcmMessage.reservationId) {
          const id = fcmMessage.reservationId;
          await this.navController.navigateRoot('/reservation-processing/' + id);
        }
      });
    }
  }

  async init() {
    if (this.available) {
      const result = await PushNotifications.checkPermissions();
      if (result.receive === 'prompt' || result.receive === 'prompt-with-rationale') {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        this.logger.debug('Requesting Permissions for Push Notifications');
        const requestResult = await PushNotifications.requestPermissions();
        if (requestResult.receive === 'granted') {
          this.registerListeners();
          // Register with Apple / Google to receive push via APNS/FCM
          this.register();
        }
      } else if (result.receive === 'granted') {
        this.registerListeners();
        // Register with Apple / Google to receive push via APNS/FCM
        this.register();
      } else {
        const { value } = await Preferences.get({
          key: 'userOnboarded',
        });
        // only show the notification if the user has made it through the onboarding process.
        if (JSON.parse(value || 'false') === true) {
          this.alertFacade.display({
            title: 'Notifications Not Allowed',
            body: "You won't be notified of new messages from hosts.",
            level: 'warning',
          });
        }
      }
    }
  }
}
