import { Injectable } from '@angular/core';
import { ICalendarDay, ICDate, ISchedule, ISite, Schedule } from '@curbnturf/entities';
import { dateStringMinusDays } from '@curbnturf/helpers';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  CaretakerRequest,
  ClearSchedules,
  CreateSchedule,
  DonateSchedule,
  LoadSchedule,
  LoadSchedules,
  RemoveSchedule,
  RequestDays,
  ScheduleSelected,
  SetSchedule,
  StoreSchedule,
  UpdateSchedule
} from './schedule.actions';
import { ScheduleState } from './schedule.reducer';
import { scheduleQuery } from './schedule.selectors';

@Injectable({
  providedIn: 'root',
})
export class ScheduleFacade {
  scheduleEntities$: Observable<{[id: string]: Schedule}>;
  schedules$: Observable<Schedule[]>;
  currentUserSchedules$: Observable<Schedule[]>;
  selected$: Observable<Schedule | null>;
  error$: Observable<string | undefined>;
  storedSchedule$: Observable<Schedule | null>;
  days$: Observable<ICalendarDay[]>;

  constructor(private store: Store<ScheduleState>) {
    this.scheduleEntities$ = this.store.pipe(select(scheduleQuery.getScheduleEntities));
    this.schedules$ = this.store.pipe(select(scheduleQuery.getSchedules));
    this.currentUserSchedules$ = this.store.pipe(select(scheduleQuery.getCurrentUserSchedules));
    this.selected$ = this.store.pipe(select(scheduleQuery.getSelectedSchedule));
    this.error$ = this.store.pipe(select(scheduleQuery.getError));
    this.storedSchedule$ = this.store.pipe(select(scheduleQuery.getStoredSchedule));
    this.days$ = this.store.pipe(select(scheduleQuery.getDays));
  }

  getDays(siteId?: number) {
    if (!siteId) {
      return;
    }

    this.store.dispatch(new RequestDays(siteId));
  }

  storeSchedule(schedule?: ISchedule) {
    if (schedule) {
      const formattedSchedule: ISchedule = {
        ...schedule,
        date: {
          ...schedule.date,
          to: schedule.date && schedule.date.to ? dateStringMinusDays(schedule.date.to, 1) : undefined,
        } as ICDate,
      };
      return this.store.dispatch(new StoreSchedule(formattedSchedule));
    }

    return this.store.dispatch(new StoreSchedule());
  }

  create(schedule: ISchedule) {
    const formattedSchedule: ISchedule = {
      ...schedule,
      date: {
        ...schedule.date,
        to: schedule.date && schedule.date.to ? dateStringMinusDays(schedule.date.to, 1) : undefined,
      } as ICDate,
    };
    this.store.dispatch(new CreateSchedule(formattedSchedule));
  }

  remove(confirmationCode: string) {
    this.store.dispatch(new RemoveSchedule(confirmationCode));
  }

  update(schedule: ISchedule) {
    this.store.dispatch(new UpdateSchedule(schedule));
  }

  select(schedule?: ISchedule) {
    this.store.dispatch(new ScheduleSelected(schedule));
  }

  set(schedules: ISchedule[]) {
    this.store.dispatch(new SetSchedule(schedules));
  }

  load(confirmationCode: string) {
    this.store.dispatch(new LoadSchedule(confirmationCode));
  }

  loadForUser() {
    this.store.dispatch(new LoadSchedules());
  }

  loadForSite(site: ISite) {
    this.store.dispatch(new LoadSchedules({ siteId: site.id }));
  }

  clear() {
    this.store.dispatch(new ClearSchedules());
  }

  donate(confirmationCode?: string, price?: number) {
    if (!confirmationCode || !price) {
      return;
    }

    this.store.dispatch(new DonateSchedule({ confirmationCode, price }));
  }

  sendCaretakerRequest(siteId?: number) {
    if (!siteId) {
      return;
    }

    this.store.dispatch(new CaretakerRequest(siteId));
  }
}
