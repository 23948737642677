<label class="w-checkbox toggle-wrap rv">
  <input type="checkbox" class="w-checkbox-input toggle-ticker" />
  <span (click)="toggled()" for="Toggle-Switch" class="toggle-label w-form-label"> </span>
  <div (click)="toggled()" [ngClass]="{ on: selected }" class="toggle">
    <div class="toggle-active">
      <div class="active-overlay"></div>
      <div class="top-line"></div>
      <div class="bottom-line"></div>
    </div>
  </div>
</label>
