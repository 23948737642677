import { DateTime } from 'luxon';
import { IActivityLocation, IAddress, ICoordinateGis, ISite, ITrip, ITripRoutePoint, IUser } from '../interfaces';
import { LatLon } from './coordinates';

export class TripRoutePoint implements ITripRoutePoint {
  id?: number;
  user?: IUser;
  userId?: number;
  site?: ISite;
  siteId?: number;
  poi?: IActivityLocation;
  poiId?: number;
  userPoint?: IActivityLocation;
  userPointId?: number;
  isActive?: boolean;
  arrival?: DateTime;
  departure?: DateTime;
  name?: string;
  address?: IAddress;
  coordinates?: ICoordinateGis;
  ordinality?: number;
  trip?: ITrip;
  tripId?: number;

  constructor(data?: ITripRoutePoint) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: ITripRoutePoint): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    this.coordinates = new LatLon(this.coordinates);
  }
}
