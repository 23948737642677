import { NGRX_BUCKETS } from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoggerState } from './log.reducer';

// The log state is also the log session
export const getLogState = createFeatureSelector<LoggerState>(NGRX_BUCKETS.log);
export const getLogSession = createSelector(getLogState, (state) => ({
  sessionId: state.sessionId,
  created: state.created,
}));
export const getLogMessages = createSelector(getLogState, (state) => state.logs);
