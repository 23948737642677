import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AlertFacade } from '@curbnturf/alert/src/lib/+state/alert.facade';
import {
  IAccessRoute,
  IActivity,
  IActivityType,
  ICancellationPolicyOption,
  ICheckInProcessType,
  IKvPair,
  ILandOwner,
  ILandType,
  INoise,
  IReservationProcessType,
  ISite,
  IWaypoint,
  ListingType,
  Site,
} from '@curbnturf/entities';
import { siteComplete } from '@curbnturf/helpers';
import { SiteFacade } from '@curbnturf/site/src/lib/+state/site.facade';
import { DateTime } from 'luxon';
import { getFormValidationErrors } from '../helpers/errors';
import {
  addressFormBuilder,
  amenityFormBuilder,
  coordinatesFormBuilder,
  extraFormBuilder,
  formatAddressFormValue,
  formatAmenityFormValue,
  formatCoordinatesFormValue,
  formatPhotosFormValue,
  formatPolicyFormValue,
  formatPrivacyFormValue,
  formatRoadConditionsFormValue,
  formatSignalReceptionFormValue,
  formatSiteExtraFormValue,
  formatVehiclesFormValue,
  formatWaypointFormValue,
  photoFormBuilder,
  policyFormBuilder,
  privacyFormBuilder,
  roadConditionsFormBuilder,
  signalReceptionFormBuilder,
  vehiclesFormBuilder,
  waypointFormBuilder,
} from './builder.helpers';
import {
  IAccessRouteForm,
  IActivityForm,
  IAddressForm,
  IAmenityForm,
  ICoordinatesForm,
  ILandOwnerForm,
  INoiseForm,
  IPhotoForm,
  IPolicyForm,
  IPrivacyForm,
  IRoadConditionsForm,
  ISignalReceptionForm,
  ISiteExtraForm,
  ISiteForm,
  IVehiclesForm,
  IWaypointForm,
} from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class ListingForm {
  accessRouteGroup: () => IAccessRouteForm;
  activityGroup: (activityType?: IKvPair) => IActivityForm;
  landOwnerGroup: () => ILandOwnerForm;
  noiseGroup: () => INoiseForm;
  siteForm: FormGroup<ISiteForm>;

  errors: string[] = [];
  lastSaved: DateTime;

  get accessRoutes() {
    if (this.siteForm) {
      return this.siteForm.get('accessRoutes') as FormArray<FormGroup<IAccessRouteForm>>;
    }

    return;
  }

  get activities() {
    if (this.siteForm) {
      return this.siteForm.get('activities') as FormArray<FormGroup<IActivityForm>>;
    }

    return;
  }

  get amenities() {
    if (this.siteForm) {
      return this.siteForm.get('amenities') as FormArray<FormGroup<IAmenityForm>>;
    }

    return;
  }

  get blackoutDays() {
    if (this.siteForm) {
      return this.siteForm.get('blackoutDays') as FormArray<FormControl<string>>;
    }

    return;
  }

  get blackoutMonths() {
    if (this.siteForm) {
      return this.siteForm.get('blackoutMonths') as FormArray<FormControl<number>>;
    }

    return;
  }

  get extras() {
    if (this.siteForm) {
      return this.siteForm.get('extras') as FormArray<FormGroup<ISiteExtraForm>>;
    }

    return;
  }

  get landTypes() {
    if (this.siteForm) {
      return this.siteForm.get('landTypes') as FormArray<FormControl<string>>;
    }

    return;
  }

  get photos() {
    if (this.siteForm) {
      return this.siteForm.get('photos') as FormArray<FormGroup<IPhotoForm>>;
    }

    return;
  }

  get policies() {
    if (this.siteForm) {
      return this.siteForm.get('policies') as FormArray<FormGroup<IPolicyForm>>;
    }

    return;
  }

  get signalReception() {
    if (this.siteForm) {
      return this.siteForm.get('signalReception') as FormArray<FormGroup<ISignalReceptionForm>>;
    }

    return;
  }

  static formatSiteFormValue(form: FormGroup<ISiteForm>): ISite {
    const value = form.getRawValue();

    return {
      id: value.id || undefined,
      available: value.available === null || value.available === undefined ? undefined : value.available,
      name: value.name || undefined,
      type: (value.type as ListingType) || ListingType.standard,
      landTypes: (value.landTypes?.filter((type) => type) as ILandType['type'][]) || [],
      coordinates: formatCoordinatesFormValue(form.get('coordinates') as FormGroup<ICoordinatesForm>),
      reservationProcess: value.reservationProcess || 'standard',
      checkInProcess: value.checkInProcess || undefined,
      checkIn: value.checkIn || undefined,
      checkOut: value.checkOut || undefined,
      description: value.description || undefined,
      price: value.price === null ? undefined : value.price,
      weekdayDiscount: value.weekdayDiscount || undefined,
      nextReservationDiscount: value.nextReservationDiscount === null ? undefined : value.nextReservationDiscount,
      blackoutMonths: (value.blackoutMonths?.filter((month) => typeof month === 'number') as number[]) || [],
      blackoutDays: (value.blackoutDays?.filter((day) => day) as string[]) || [],
      photos: formatPhotosFormValue(form.get('photos') as FormArray<FormGroup<IPhotoForm>>),
      maxHeight: value.maxHeight || undefined,
      maxWidth: value.maxWidth || undefined,
      maxLength: value.maxLength || undefined,
      maxGuests: value.maxGuests || undefined,
      size: value.size || undefined,
      sizeBuses: value.sizeBuses || undefined,
      sizeCars: value.sizeCars || undefined,
      amenities: (form.get('amenities') as FormArray<FormGroup<IAmenityForm>>)?.controls?.map((amenityForm) =>
        formatAmenityFormValue(amenityForm),
      ),
      extras: (form.get('extras') as FormArray<FormGroup<ISiteExtraForm>>)?.controls?.map((extraForm) =>
        formatSiteExtraFormValue(extraForm),
      ),
      vehicles: formatVehiclesFormValue(form.get('vehicles') as FormGroup<IVehiclesForm>),
      signalReception: (form.get('signalReception') as FormArray<FormGroup<ISignalReceptionForm>>)?.controls?.map(
        (signalReceptionForm) => formatSignalReceptionFormValue(signalReceptionForm),
      ),
      maxRVAge: value.maxRVAge || undefined,
      minLengthOfStayInDays: value.minLengthOfStayInDays || undefined,
      maxLengthOfStayInDays: value.maxLengthOfStayInDays || undefined,
      bookingWindow: value.bookingWindow || undefined,
      reserveDaysInAdvance: value.reserveDaysInAdvance === null ? undefined : value.reserveDaysInAdvance,
      cancellationPolicy: value.cancellationPolicy || undefined,
      noise: ListingForm.formatNoiseFormValue(form.get('noise') as FormGroup<INoiseForm>),
      policies: (form.get('policies') as FormArray<FormGroup<IPolicyForm>>)?.controls?.map((policyForm) =>
        formatPolicyFormValue(policyForm),
      ),
      privacy: formatPrivacyFormValue(form.get('privacy') as FormGroup<IPrivacyForm>),
      activities: (form.get('activities') as FormArray<FormGroup<IActivityForm>>)?.controls?.map((activityForm) =>
        ListingForm.formatActivityFormValue(activityForm),
      ),
      landOwner: ListingForm.formatLandOwnerFormValue(form.get('landOwner') as FormGroup<ILandOwnerForm>),
      address: formatAddressFormValue(form.get('address') as FormGroup<IAddressForm>),
      accessRoutes: (form.get('accessRoutes') as FormArray<FormGroup<IAccessRouteForm>>)?.controls?.map(
        (accessRouteForm) => ListingForm.formatAccessRouteFormValue(accessRouteForm),
      ),
      tentOnly: value.tentOnly === null || value.tentOnly === undefined ? undefined : value.tentOnly,
      phone: value.phone || undefined,
      email: value.email || undefined,
      url: value.url || undefined,
      potentialHazards:
        value.potentialHazards === null || value.potentialHazards === undefined ? undefined : value.potentialHazards,
      potentialHazardDescription: value.potentialHazardDescription || undefined,
    };
  }

  static formatNoiseFormValue(form?: FormGroup<INoiseForm>): INoise {
    if (!form) {
      return {};
    }

    const value = form.value;

    return {
      level: value.level === null ? undefined : value.level,
      description: value.description || undefined,
    };
  }

  static formatAccessRouteFormValue(form?: FormGroup<IAccessRouteForm>): IAccessRoute {
    if (!form) {
      return {
        roadCondition: {
          paved: false,
          pavedDescription: '',
          smoothGravel: false,
          smoothGravelDescription: '',
          poorGravel: false,
          poorGravelDescription: '',
          highClearance: false,
          highClearanceDescription: '',
          offRoad: false,
          offRoadDescription: '',
        },
      };
    }

    const value = form.value;

    return {
      id: value.id || undefined,
      name: value.name || undefined,
      description: value.description || undefined,
      directions: value.directions || undefined,
      startingPoint: formatCoordinatesFormValue(form.get('startingPoint') as FormGroup<ICoordinatesForm>),
      maxHeight: value.maxHeight || undefined,
      maxLength: value.maxLength || undefined,
      maxWidth: value.maxWidth || undefined,
      minClearance: value.minClearance || undefined,
      intermediateWaypoints: (form.get('intermediateWaypoints') as FormArray<FormGroup<IWaypointForm>>)?.controls
        ?.map((waypointForm) => formatWaypointFormValue(waypointForm))
        .filter((waypoint) => waypoint) as IWaypoint[],
      roadCondition: formatRoadConditionsFormValue(form.get('roadCondition') as FormGroup<IRoadConditionsForm>),
    };
  }

  static formatActivityFormValue(form?: FormGroup<IActivityForm>): IActivity {
    if (!form) {
      return {};
    }

    const value = form.value;

    return {
      id: value.id || undefined,
      type: value.type || undefined,
      typeCustom: value.typeCustom || undefined,
      name: value.name || undefined,
      description: value.description || undefined,
      photos: formatPhotosFormValue(form.get('photos') as FormArray<FormGroup<IPhotoForm>>),
      url: value.url || undefined,
    };
  }

  static formatLandOwnerFormValue(form?: FormGroup<ILandOwnerForm>): ILandOwner {
    if (!form) {
      return {};
    }

    const value = form.value;

    return {
      id: value.id || undefined,
      self: value.self === null ? undefined : value.self,
      private: value.private === null ? undefined : value.private,
      firstName: value.firstName || undefined,
      lastName: value.lastName || undefined,
      phone: value.phone || undefined,
      address: formatAddressFormValue(form.get('address') as FormGroup<IAddressForm>),
      represent: value.represent === null ? undefined : value.represent,
    };
  }

  constructor(private formBuilder: FormBuilder, private siteFacade?: SiteFacade, private alertFacade?: AlertFacade) {
    this.accessRouteGroup = (): IAccessRouteForm => {
      return {
        id: formBuilder.control<number | null>(null),
        name: formBuilder.control<string | null>(''),
        description: formBuilder.control<string | null>('', [Validators.minLength(8)]),
        directions: formBuilder.control<string | null>(''),
        startingPoint: coordinatesFormBuilder(formBuilder),
        maxHeight: formBuilder.control<number | null>(null, [Validators.min(0)]),
        maxLength: formBuilder.control<number | null>(null, [Validators.min(0)]),
        maxWidth: formBuilder.control<number | null>(null, [Validators.min(0)]),
        minClearance: formBuilder.control<number | null>(null, [Validators.min(0)]),
        intermediateWaypoints: formBuilder.array<FormGroup<IWaypointForm>>([], { updateOn: 'blur' }),
        roadCondition: roadConditionsFormBuilder(formBuilder),
      };
    };

    this.activityGroup = (activityType?: IKvPair): IActivityForm => {
      return {
        id: formBuilder.control<number | null>(null),
        type: formBuilder.control<IActivityType['type'] | null>((activityType?.key as IActivityType['type']) || null),
        typeCustom: formBuilder.control(''),
        name: formBuilder.control(activityType ? activityType.value : '', [Validators.maxLength(120)]),
        description: formBuilder.control(''),
        // todo: why is address and coordinates here
        address: addressFormBuilder(formBuilder),
        coordinates: coordinatesFormBuilder(formBuilder),
        photos: formBuilder.array<FormGroup<IPhotoForm>>([], { updateOn: 'blur' }),
        url: formBuilder.control(''),
      };
    };

    this.landOwnerGroup = (): ILandOwnerForm => {
      return {
        id: formBuilder.control<number | null>(null),
        self: formBuilder.control<boolean | null>(null),
        private: formBuilder.control<boolean | null>(null),
        firstName: formBuilder.control('', [Validators.maxLength(120)]),
        lastName: formBuilder.control('', [Validators.maxLength(120)]),
        phone: formBuilder.control('', [Validators.maxLength(20)]),
        address: addressFormBuilder(formBuilder),
        represent: formBuilder.control<boolean | null>(null),
      };
    };

    this.noiseGroup = (): INoiseForm => {
      return {
        level: formBuilder.control<'silence' | 'nature' | 'periodic' | 'constant' | null>(null),
        description: formBuilder.control<string | null>(''),
      };
    };

    this.siteForm = formBuilder.group<ISiteForm>(
      {
        id: formBuilder.control<number | null>(null),
        syncId: formBuilder.control<string | null>(null),
        available: formBuilder.control<boolean | null>(null),
        name: formBuilder.control<string | null>('', [Validators.minLength(8), Validators.maxLength(64)]),
        type: formBuilder.control(ListingType.standard),
        landTypes: formBuilder.array<ILandType['type']>([], { updateOn: 'change' }),
        coordinates: coordinatesFormBuilder(formBuilder),
        reservationProcess: formBuilder.control<IReservationProcessType['type'] | null>('standard', {
          updateOn: 'change',
        }),
        checkInProcess: formBuilder.control<ICheckInProcessType['type'] | null>('meetAndGreet', { updateOn: 'change' }),
        checkIn: formBuilder.control<string | null>(null, {
          validators: [Validators.minLength(8), Validators.maxLength(8)],
          updateOn: 'change',
        }), // time '' '04:00PM'
        checkOut: formBuilder.control<string | null>(null, {
          validators: [Validators.minLength(8), Validators.maxLength(8)],
          updateOn: 'change',
        }), // time '' '11:00AM'
        description: formBuilder.control<string | null>('', [Validators.minLength(150), Validators.maxLength(3000)]),
        price: formBuilder.control<number | null>(null, { updateOn: 'change', validators: [Validators.min(0)] }),
        weekdayDiscount: formBuilder.control<number | null>(0, [Validators.min(0)]),
        nextReservationDiscount: formBuilder.control<number | null>(null, {
          validators: [Validators.min(0)],
          updateOn: 'change',
        }),
        // array of months for which the site is unavailable (1 = January, 12 = December)
        blackoutMonths: formBuilder.array<number>([], { updateOn: 'change' }),
        blackoutDays: formBuilder.array<string>([], { updateOn: 'blur' }),
        photos: formBuilder.array<FormGroup<IPhotoForm>>([], { updateOn: 'blur' }), // array of URL ''s
        maxHeight: formBuilder.control<number | null>(null, [Validators.min(0)]),
        maxWidth: formBuilder.control<number | null>(null, [Validators.min(0)]),
        maxLength: formBuilder.control<number | null>(null, [Validators.min(0)]),
        maxGuests: formBuilder.control<number | null>(null, [Validators.min(0)]),
        size: formBuilder.control<number | null>(null, [Validators.min(0)]),
        sizeBuses: formBuilder.control<number | null>(0, [Validators.min(0)]),
        sizeCars: formBuilder.control<number | null>(0, [Validators.min(0)]),
        amenities: formBuilder.array<FormGroup<IAmenityForm>>([], { updateOn: 'change' }),
        extras: formBuilder.array<FormGroup<ISiteExtraForm>>([], { updateOn: 'blur' }),
        vehicles: vehiclesFormBuilder(formBuilder),
        signalReception: formBuilder.array<FormGroup<ISignalReceptionForm>>([], { updateOn: 'change' }),
        maxRVAge: formBuilder.control<number | null>(null, [Validators.min(0), Validators.max(150)]),
        minLengthOfStayInDays: formBuilder.control<number | null>(null, {
          validators: [Validators.min(1), Validators.max(365)],
          updateOn: 'change',
        }),
        maxLengthOfStayInDays: formBuilder.control<number | null>(null, {
          validators: [Validators.min(1)],
          updateOn: 'change',
        }),
        bookingWindow: formBuilder.control<number | null>(365, { updateOn: 'change' }),
        reserveDaysInAdvance: formBuilder.control<number | null>(null, {
          validators: [Validators.min(0), Validators.max(365)],
          updateOn: 'change',
        }),
        cancellationPolicy: formBuilder.control<ICancellationPolicyOption['option'] | null>(null, {
          updateOn: 'change',
        }),
        noise: formBuilder.group(this.noiseGroup(), { updateOn: 'change' }),
        policies: formBuilder.array<FormGroup<IPolicyForm>>([], { updateOn: 'change' }),
        privacy: privacyFormBuilder(formBuilder),
        activities: formBuilder.array<FormGroup<IActivityForm>>([], { updateOn: 'blur' }),
        landOwner: formBuilder.group(this.landOwnerGroup(), {
          updateOn: 'blur',
        }),
        address: addressFormBuilder(formBuilder),
        accessRoutes: formBuilder.array([formBuilder.group(this.accessRouteGroup(), { updateOn: 'blur' })], {
          updateOn: 'blur',
        }),
        tentOnly: formBuilder.control<boolean | null>(null, { updateOn: 'change' }),
        phone: formBuilder.control<string | null>('', [Validators.maxLength(20)]),
        email: formBuilder.control<string | null>(''),
        url: formBuilder.control<string | null>(''),
        potentialHazards: formBuilder.control<boolean | null>(false, { updateOn: 'change' }),
        potentialHazardDescription: formBuilder.control<string | null>(''),
      },
      { updateOn: 'blur' },
    );
  }

  patchSiteValue(form: FormGroup<ISiteForm>, site: ISite): FormGroup<ISiteForm> {
    form.patchValue({
      ...site,
      id: site.id ? site.id : undefined,
      available: site.available === null ? undefined : site.available,
      name: site.name ? site.name : undefined,
      type: site.type ? site.type : undefined,
      landTypes: site.landTypes ? site.landTypes : undefined,
      coordinates: site.coordinates ? site.coordinates : undefined,
      reservationProcess: site.reservationProcess ? site.reservationProcess : undefined,
      checkInProcess: site.checkInProcess ? site.checkInProcess : undefined,
      checkIn: site.checkIn ? site.checkIn : undefined,
      checkOut: site.checkOut ? site.checkOut : undefined,
      description: site.description ? site.description : undefined,
      price: site.price === null ? undefined : site.price,
      weekdayDiscount: site.weekdayDiscount ? site.weekdayDiscount : undefined,
      nextReservationDiscount: site.nextReservationDiscount === null ? undefined : site.nextReservationDiscount,
      blackoutMonths: site.blackoutMonths ? site.blackoutMonths : undefined,
      blackoutDays: site.blackoutDays ? site.blackoutDays : undefined,
      photos: site.photos ? site.photos : undefined,
      maxHeight: site.maxHeight ? site.maxHeight : undefined,
      maxWidth: site.maxWidth ? site.maxWidth : undefined,
      maxLength: site.maxLength ? site.maxLength : undefined,
      maxGuests: site.maxGuests ? site.maxGuests : undefined,
      size: site.size ? site.size : undefined,
      sizeBuses: site.sizeBuses ? site.sizeBuses : undefined,
      sizeCars: site.sizeCars ? site.sizeCars : undefined,
      amenities: site.amenities ? site.amenities : undefined,
      extras: site.extras ? site.extras : undefined,
      vehicles: site.vehicles ? site.vehicles : undefined,
      signalReception: site.signalReception ? site.signalReception : undefined,
      maxRVAge: site.maxRVAge ? site.maxRVAge : undefined,
      minLengthOfStayInDays: site.minLengthOfStayInDays ? site.minLengthOfStayInDays : undefined,
      maxLengthOfStayInDays: site.maxLengthOfStayInDays ? site.maxLengthOfStayInDays : undefined,
      bookingWindow: site.bookingWindow ? site.bookingWindow : undefined,
      reserveDaysInAdvance: site.reserveDaysInAdvance ? site.reserveDaysInAdvance : undefined,
      cancellationPolicy: site.cancellationPolicy ? site.cancellationPolicy : undefined,
      noise: {
        level: site.noise?.level ? site.noise.level : undefined,
        description: site.noise?.level ? site.noise.description : undefined,
      },
      policies: site.policies ? site.policies : undefined,
      privacy: site.privacy ? site.privacy : undefined,
      activities: site.activities ? site.activities : undefined,
      landOwner: site.landOwner ? site.landOwner : undefined,
      address: site.address ? site.address : undefined,
      accessRoutes: site.accessRoutes ? site.accessRoutes : undefined,
      tentOnly: site.tentOnly === null || site.tentOnly === undefined ? undefined : site.tentOnly,
      phone: site.phone ? site.phone : undefined,
      email: site.email ? site.email : undefined,
      url: site.url ? site.url : undefined,
      potentialHazards:
        site.potentialHazards === null || site.potentialHazards === undefined ? undefined : site.potentialHazards,
      potentialHazardDescription: site.potentialHazardDescription ? site.potentialHazardDescription : undefined,
    });

    return form;
  }

  formatSiteFormValue(form: FormGroup<ISiteForm>): ISite {
    return ListingForm.formatSiteFormValue(form);
  }

  formatNoiseFormValue(form?: FormGroup<INoiseForm>): INoise {
    return ListingForm.formatNoiseFormValue(form);
  }

  formatAccessRouteFormValue(form?: FormGroup<IAccessRouteForm>): IAccessRoute {
    return ListingForm.formatAccessRouteFormValue(form);
  }

  formatActivityFormValue(form?: FormGroup<IActivityForm>): IActivity {
    return ListingForm.formatActivityFormValue(form);
  }

  formatLandOwnerFormValue(form?: FormGroup<ILandOwnerForm>): ILandOwner {
    return ListingForm.formatLandOwnerFormValue(form);
  }

  update(site: Site) {
    if (!site) {
      return;
    }

    this.patchSiteValue(this.siteForm, site);

    this.syncArrayField(site.photos, this.photos, photoFormBuilder);
    this.syncArrayField(site.blackoutMonths, this.blackoutMonths);
    this.syncArrayField(site.blackoutDays, this.blackoutDays);
    this.syncArrayField(site.amenities, this.amenities, amenityFormBuilder);
    this.syncArrayField(site.extras, this.extras, extraFormBuilder);

    if (site.policies) {
      // Make sure the form policies array is at least as short as the incoming array with one extra space
      // while (this.site.policies.length < this.policies.length) {
      //   this.policies.removeAt(this.policies.length - 1);
      // }

      // Add or replace each element in the from array with the incoming element
      site.policies.forEach((policy, index) => {
        if (this.policies) {
          if (this.policies.length > index) {
            this.policies.controls[index].patchValue(policy);
          } else {
            this.policies.push(policyFormBuilder(this.formBuilder, policy));
          }
        }
      });
    }

    this.syncArrayField(site.signalReception, this.signalReception, signalReceptionFormBuilder);
    this.syncArrayField(site.landTypes, this.landTypes);

    if (site.activities && this.activities) {
      // Make sure the form activities array is at least as short as the incoming array
      while (site.activities.length < this.activities.length) {
        this.activities.removeAt(this.activities.length - 1);
      }

      // Add or replace each element in the from array with the incoming element
      site.activities.forEach((activity, index) => {
        if (this.activities) {
          if (this.activities.length > index) {
            this.activities.controls[index].patchValue(activity);
          } else {
            const activityGroup = this.formBuilder.group(this.activityGroup());
            activityGroup.patchValue(activity);
            this.activities.push(activityGroup);
          }

          const photosControl = this.activities.controls[index].get('photos') as FormArray<FormGroup<IPhotoForm>>;

          if (activity.photos && photosControl) {
            this.syncArrayField(activity.photos, photosControl, photoFormBuilder);
          }
        }
      });
    }

    if (site.accessRoutes && this.accessRoutes) {
      // Make sure the form activities array is at least as short as the incoming array
      while (site.accessRoutes.length < this.accessRoutes.length) {
        this.accessRoutes.removeAt(this.accessRoutes.length - 1);
      }

      // Add or replace each element in the from array with the incoming element
      site.accessRoutes.forEach((accessRoute, index) => {
        if (this.accessRoutes) {
          if (this.accessRoutes.length > index) {
            this.accessRoutes.controls[index].patchValue(accessRoute);
          } else {
            const accessRouteGroup = this.formBuilder.group(this.accessRouteGroup());
            accessRouteGroup.patchValue(accessRoute);
            this.accessRoutes.push(accessRouteGroup);
          }

          const intermediateWaypointsControl = this.accessRoutes.controls[index].get(
            'intermediateWaypoints',
          ) as FormArray<FormGroup<IWaypointForm>>;

          if (accessRoute.intermediateWaypoints && intermediateWaypointsControl) {
            this.syncArrayField(accessRoute.intermediateWaypoints, intermediateWaypointsControl, waypointFormBuilder);
          }
        }
      });
    }

    this.siteForm.markAsPristine();
  }

  /**
   * Returns boolean to indicate whether or not saving was necessary.
   * @param {ISite} siteToSave
   * @param {() => void} beforeSaveCallback
   * @return boolean
   */
  save(siteToSave: ISite, beforeSaveCallback?: () => void): boolean {
    this.errors.length = 0;
    const errorHash: { [id: string]: string } = {};

    getFormValidationErrors(this.siteForm).forEach((error) => (errorHash[error] = ''));

    this.errors = Object.keys(errorHash);

    if (this.errors.length === 0 && this.siteForm.valid && this.siteForm.dirty) {
      if (!siteToSave.amenities) {
        siteToSave.amenities = [];
      }

      if (siteComplete.tentExtraIsSet(siteToSave) && !siteComplete.tentAmenity(siteToSave)) {
        siteToSave.amenities.push({
          type: 'tents',
          custom: '',
          description: '',
          options: '',
        });
      }

      if (beforeSaveCallback) {
        beforeSaveCallback();
      }
      this.siteFacade?.update(siteToSave);
      const checkInInput = this.siteForm.get('checkIn');
      const checkOutInput = this.siteForm.get('checkOut');

      if (checkInInput) {
        checkInInput.markAsPristine();
        checkInInput.markAsUntouched();
      }

      if (checkOutInput) {
        checkOutInput.markAsPristine();
        checkOutInput.markAsUntouched();
      }

      this.siteForm.markAsPristine();
      this.lastSaved = DateTime.local();
      return true;
    } else if (this.errors.length > 0 && !this.siteForm.valid && this.siteForm.dirty) {
      const errorList = this.errors.join('\n');
      this.alertFacade?.display({
        level: 'error',
        body: `The form has unresolved errors:\n${errorList}`,
        title: 'Changes Have Not Been Saved',
      });
      return false;
    } else {
      this.lastSaved = DateTime.local();
      return false;
    }
  }

  private syncArrayField(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    source?: any[],
    destination?: UntypedFormArray,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formBuilder?: (formBuilder: FormBuilder, argv?: any) => UntypedFormGroup,
  ) {
    if (source && destination) {
      // Make sure the form array is at least as short as the incoming array
      while (source.length < destination.length) {
        destination.removeAt(destination.length - 1);
      }
      // Add or replace each element in the from array with the incoming element
      source.forEach((sourceEl, index) => {
        if (destination) {
          if (destination.length > index) {
            destination.controls[index].setValue(sourceEl);
          } else {
            if (formBuilder) {
              destination.push(formBuilder(this.formBuilder, sourceEl));
            } else {
              destination.push(this.formBuilder.control(sourceEl));
            }
          }
        }
      });
    }
  }
}
