import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_API_URL } from '@curbnturf/entities';
import { StatusFacade } from '@curbnturf/status';
import { Observable, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { AuthFacade } from './+state/auth.facade';

@Injectable()
export class UnauthenticatedErrorInterceptor implements HttpInterceptor {
  constructor(private authFacade: AuthFacade, private statusFacade: StatusFacade) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (
          err instanceof HttpErrorResponse &&
          err.status === 401 &&
          // we only log them out if the 401 is from our server
          err.url?.includes(BASE_API_URL)
        ) {
          this.statusFacade.loggedIn$.pipe(first()).subscribe((loggedIn) => {
            if (loggedIn) {
              this.authFacade.logout('You have been logged out by the server');
            }
          });

          return throwError('You have been logged out by the server');
        }

        /*const error =
          (err.error && err.error.message) ||
          err.statusText ||
          err.message ||
          'Error making connecting to server';*/
        return throwError(err);
      }),
    );
  }
}
