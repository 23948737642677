import { DateTime } from 'luxon';
import { NgbDateStruct } from './ngb-date-struct';

export const dateStringMinusDays = (dateString: string, value: number): string => {
  return DateTime.fromISO(dateString).minus({ day: value }).toISODate() || '';
};

export const dateStringAddDays = (dateString: string, value: number): string => {
  return DateTime.fromISO(dateString).plus({ day: value }).toISODate() || '';
};

export const dateRangeToDateStringArray = (start?: DateTime, end?: DateTime): string[] => {
  if (!start) {
    return [];
  }

  if (!end) {
    return [start.toISODate() || ''];
  }

  const daysArray: string[] = [];

  let testDate = start.startOf('day');
  const endDate = end.startOf('day');

  if (testDate > endDate) {
    throw new Error('Start must be before end.');
  }

  do {
    daysArray.push(testDate.toISODate() || '');
    testDate = testDate.plus({ days: 1 });
  } while (testDate <= endDate);

  return daysArray;
};

export const dateRangeToDateArray = (start?: DateTime, end?: DateTime): DateTime[] => {
  if (!start) {
    return [];
  }

  if (!end) {
    return [start];
  }

  const daysArray: DateTime[] = [];

  let testDate = start;
  while (testDate <= end) {
    daysArray.push(testDate);
    testDate = testDate.plus({ day: 1 });
  }

  return daysArray;
};

/**
 * Returns an array of date strings with the blackout days for this year, next year and the year after.
 * The returned strings will be formatted MM/DD/YYYY.
 *
 * @param month
 */
export function getMonthDaysForNext3Years(month: number): string[] {
  const monthDate = DateTime.now().set({ month });
  const currentDate = DateTime.now().startOf('day');
  const monthDates: string[] = [
    ...dateRangeToDateStringArray(monthDate.startOf('month'), monthDate.endOf('month')).filter(
      (date) => DateTime.fromISO(date) >= currentDate,
    ),
    ...dateRangeToDateStringArray(
      monthDate.plus({ years: 1 }).startOf('month'),
      monthDate.plus({ years: 1 }).endOf('month'),
    ),
    ...dateRangeToDateStringArray(
      monthDate.plus({ years: 2 }).startOf('month'),
      monthDate.plus({ years: 2 }).endOf('month'),
    ),
  ];

  return monthDates;
}

export const datepickerRangeToDateArray = (pickerRange: string): DateTime[] => {
  const pickerRangeArray = pickerRange.split(':');

  const days: DateTime[] = pickerRangeArray.map((day) => DateTime.fromISO(day));

  return days;
};

export const ngbDateToDateString = (ngbDate?: NgbDateStruct | DateTime): string => {
  if (!ngbDate) {
    return '';
  }

  if ((ngbDate as DateTime).isValid) {
    return (ngbDate as DateTime).toISODate() || '';
  }

  return DateTime.fromObject(ngbDate).toISODate() || '';
};

export const dateStringRangeToDateStringArray = (dateRange: string): string[] => {
  if (!dateRange) {
    return [];
  }

  const dateRangeObj = dateStringRangeToDateRange(dateRange);
  return dateRangeToDateStringArray(dateRangeObj.start, dateRangeObj.end);
};

export const dateStringRangeToDateRange = (dateRange: string): { start?: DateTime; end?: DateTime } => {
  if (!dateRange) {
    return {};
  }

  const dateRangeArray = dateRange.split(':');

  if (dateRangeArray.length === 1) {
    return { start: DateTime.fromISO(dateRangeArray[0]) };
  } else if (dateRangeArray.length === 2) {
    return {
      start: DateTime.fromISO(dateRangeArray[0]),
      end: DateTime.fromISO(dateRangeArray[1]),
    };
  }

  return {};
};

export const beforeToday = (date: string | DateTime): boolean => {
  if (typeof date === 'string') {
    const dateRangeArray = date.split(':');

    if (dateRangeArray.length >= 1) {
      date = DateTime.fromISO(dateRangeArray[0]);
    } else {
      // invalid date so it is before today
      return true;
    }
  }

  const now = DateTime.now().startOf('day');

  return date < now;
};

export const dateCompare = (a: DateTime | string, b: DateTime | string): number => {
  let aDate = a;
  if (typeof a === 'string') {
    aDate = DateTime.fromISO(a);
  }

  let bDate = b;
  if (typeof b === 'string') {
    bDate = DateTime.fromISO(b);
  }

  return (aDate as DateTime).toSeconds() - (bDate as DateTime).toSeconds();
};

export const timeSimplify = (time: string): string => {
  if (!time) {
    return '';
  }

  const hour = parseInt(time.split(':')[0], 10);

  if (!hour) {
    return '';
  }

  if (hour === 12) {
    return '12 pm (Noon)';
  }

  if (hour > 12) {
    return `${hour - 12} pm`;
  }

  return `${hour} am`;
};
