import { generateReservationConfirmationCode } from '@curbnturf/helpers';
import { IMessageChannel, IUser } from '../interfaces';
import { Message } from './message';
import { Reservation } from './reservation';
import { Site } from './site';
import { User } from './user';

export class MessageChannel implements IMessageChannel {
  id?: number;
  user?: IUser;
  userId?: number;
  reservation?: Reservation;
  reservationId?: number;
  site?: Site;
  siteId?: number;
  timestamp?: number;
  messages?: Message[];
  deleted?: boolean;
  deletedDate?: number;

  constructor(data?: IMessageChannel) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IMessageChannel): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key) && key !== 'messages') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    this.messages = data.messages ? data.messages.map((message) => new Message(message)) : [];
  }

  getOtherName(currentUser: User): string {
    if (this.user && this.site && currentUser.id === this.user.id) {
      return User.getName(this.site.user);
    }
    return User.getName(this.user);
  }

  getAboutName(): string {
    if (this.reservation) {
      return this.site?.name || generateReservationConfirmationCode(this.reservation);
    }

    if (this.site) {
      return `${this.site.name}`;
    }

    return '';
  }
}
