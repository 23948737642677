import { ILatLon, MapLayerTypes, MapPoint, Site } from '@curbnturf/entities';
import { createReducer, on } from '@ngrx/store';
import { IFiltersSerialized } from '../filters.interface';
import * as ListingSearchActions from './listing-search.actions';

/**
 * Interface for the 'ListingSearch' data used in
 *  - ListingSearchState, and
 *  - propertySearchReducer
 *
 *  Note: replace if already defined in another module
 */
export interface ListingSearchState {
  searchResults: MapPoint[];
  typeaheadResults: MapPoint[];
  userPoints: MapPoint[];
  selectedId?: number; // which ListingSearch record has been selected
  selectedResult?: MapPoint;
  selectedSite?: Site;
  loaded: boolean; // has the ListingSearch list been loaded
  error?: string; // last error (if any)
  filters?: IFiltersSerialized;
  searchMapCenter?: ILatLon;
  searchMapZoom?: number;
  searchMapHeading?: number;
  searchListOpen?: boolean;
  layerType: MapLayerTypes;
}

export const initialState: ListingSearchState = {
  searchResults: [],
  typeaheadResults: [],
  userPoints: [],
  loaded: false,
  layerType: MapLayerTypes.normal,
};

export const listingSearchReducer = createReducer(
  initialState,
  on(ListingSearchActions.listingSearchCompleted, (state, action) => {
    if (!action.results) {
      return state;
    }

    const listingSearches: MapPoint[] = [...action.results];

    return {
      ...state,
      searchResults: listingSearches,
      loaded: true,
    };
  }),
  on(ListingSearchActions.listingTypeaheadCompleted, (state, action) => {
    if (!action.results) {
      return state;
    }

    const listingSearches: MapPoint[] = [...action.results];

    return {
      ...state,
      typeaheadResults: listingSearches,
      loaded: true,
    };
  }),
  on(ListingSearchActions.listingTypeaheadCleared, (state) => {
    return {
      ...state,
      typeaheadResults: [],
      loaded: true,
    };
  }),
  on(ListingSearchActions.siteDisplaySelected, (state, action) => {
    if (!action.site) {
      return {
        ...state,
      };
    }

    return {
      ...state,
      selectedSite: action.site,
    };
  }),
  on(ListingSearchActions.selectPoint, (state, action) => {
    if (action?.point) {
      return {
        ...state,
        selectedResultId: undefined,
        selectedResult: undefined,
      };
    } else {
      return {
        ...state,
        selectedResultId: action.point?.id,
        selectedResult: new MapPoint(action.point),
      };
    }
  }),
  on(ListingSearchActions.selectPointById, (state, action) => {
    const foundItem = state.searchResults.find((el) => el.id === action.pointId);
    if (foundItem) {
      return {
        ...state,
        selectedResultId: foundItem.id,
        selectedResult: foundItem,
      };
    }

    return {
      ...state,
    };
  }),
  on(ListingSearchActions.siteDisplaySelected, (state, action) => {
    const site = new Site({
      ...action.site,
    });

    return {
      selectedSite: site,
      ...state,
    };
  }),
  on(ListingSearchActions.setFilters, (state, action) => {
    return {
      ...state,
      filters: {
        ...action.filters,
        startDate: action.filters.startDate ? action.filters.startDate.toISO() || '' : undefined,
        endDate: action.filters.endDate ? action.filters.endDate.toISO() || '' : undefined,
      },
    };
  }),
  on(ListingSearchActions.setSearchMapCenter, (state, action) => {
    return {
      ...state,
      searchMapCenter: action.center,
    };
  }),
  on(ListingSearchActions.setSearchMapZoom, (state, action) => {
    return {
      ...state,
      searchMapZoom: action.zoom,
    };
  }),
  on(ListingSearchActions.setSearchMapHeading, (state, action) => {
    return {
      ...state,
      searchMapHeading: action.heading,
    };
  }),
  on(ListingSearchActions.setSearchListOpen, (state, action) => {
    return {
      ...state,
      searchListOpen: action.isOpen,
    };
  }),
  on(ListingSearchActions.addUserPoint, (state, action) => {
    const userPoints: MapPoint[] = [...state.userPoints, action.point];

    return {
      ...state,
      userPoints,
    };
  }),
  on(ListingSearchActions.updateUserPointSuccess, (state, action) => {
    const userPoints = [...state.userPoints];

    const foundPointIndex = state.userPoints.findIndex((el) => el.id === action.point.id);
    if (foundPointIndex !== -1) {
      userPoints.splice(foundPointIndex, 1, action.point);
    } else {
      userPoints.push(action.point);
    }

    return {
      ...state,
      userPoints,
    };
  }),
  on(ListingSearchActions.retrieveUserPointListSuccess, (state, action) => {
    const userPoints: MapPoint[] = [...action.points];

    return {
      ...state,
      userPoints,
    };
  }),
  on(ListingSearchActions.removeUserPointSuccess, (state, action) => {
    const userPoints: MapPoint[] = [...state.userPoints];
    const foundIndex = state.userPoints.findIndex((el) => el === action.point);
    if (foundIndex !== -1) {
      userPoints.splice(foundIndex, 1);
    }

    return {
      ...state,
      userPoints,
    };
  }),
);
