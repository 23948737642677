import { MapPoint, NGRX_BUCKETS } from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DateTime } from 'luxon';
import { ListingSearchState } from './listing-search.reducer';

// Lookup the 'ListingSearch' feature state managed by NgRx
export const getListingSearchState = createFeatureSelector<ListingSearchState>(NGRX_BUCKETS.listingSearch);

export const getLoaded = createSelector(getListingSearchState, (state: ListingSearchState) =>
  state ? state.loaded : false,
);

export const getError = createSelector(getListingSearchState, (state: ListingSearchState) =>
  state ? state.error : null,
);

export const getAllListingSearchEntities = createSelector(getListingSearchState, (state: ListingSearchState) => {
  if (state && state.searchResults) {
    const instantiatedSearchResults: MapPoint[] = [];
    for (const key in state.searchResults) {
      if (Object.hasOwnProperty.call(state.searchResults, key)) {
        instantiatedSearchResults[key] = new MapPoint(state.searchResults[key]);
      }
    }

    return instantiatedSearchResults;
  }

  return [];
});

export const getAllListingSearch = createSelector(
  getListingSearchState,
  getLoaded,
  (state: ListingSearchState, isLoaded): MapPoint[] => {
    return isLoaded
      ? Object.keys(state.searchResults).map<MapPoint>((id) => new MapPoint(state.searchResults[parseInt(id, 10)]))
      : [];
  },
);

export const getUserPoints = createSelector(getListingSearchState, (state) => state.userPoints);

export const getAllListingTypeaheadEntities = createSelector(getListingSearchState, (state: ListingSearchState) => {
  if (state && state.typeaheadResults) {
    const instantiatedSearchResults: MapPoint[] = [];
    for (const key in state.typeaheadResults) {
      if (Object.hasOwnProperty.call(state.typeaheadResults, key)) {
        instantiatedSearchResults[key] = new MapPoint(state.typeaheadResults[key]);
      }
    }

    return instantiatedSearchResults;
  }

  return {};
});

export const getAllListingTypeahead = createSelector(
  getListingSearchState,
  getLoaded,
  (state: ListingSearchState, isLoaded): MapPoint[] => {
    return isLoaded
      ? Object.keys(state.typeaheadResults).map<MapPoint>(
          (id) => new MapPoint(state.typeaheadResults[parseInt(id, 10)]),
        )
      : [];
  },
);

export const getSelectedId = createSelector(getListingSearchState, (state: ListingSearchState) => state.selectedId);

export const getSelectedListingSearch = createSelector(
  getAllListingSearchEntities,
  getSelectedId,
  (listingSearch, id) => {
    if (!id) {
      return;
    }

    const result = listingSearch[id];
    return result ? Object.assign({}, result) : undefined;
  },
);

export const getAllPoints = createSelector(getListingSearchState, getLoaded, (state: ListingSearchState, isLoaded) => {
  return isLoaded ? state.searchResults : [];
});

export const getSelectedResultId = createSelector(
  getListingSearchState,
  (state: ListingSearchState) => state.selectedResult?.id,
);

export const getSelectedPoint = createSelector(
  getListingSearchState,
  (state: ListingSearchState) => state.selectedResult,
);

export const getSelectedSite = createSelector(getListingSearchState, (state: ListingSearchState) => state.selectedSite);

export const getFilters = createSelector(getListingSearchState, (state: ListingSearchState) => {
  return {
    ...state.filters,
    startDate: state.filters?.startDate ? DateTime.fromISO(state.filters?.startDate) : undefined,
    endDate: state.filters?.endDate ? DateTime.fromISO(state.filters?.endDate) : undefined,
  };
});

export const getSearchMapCenter = createSelector(
  getListingSearchState,
  (state: ListingSearchState) => state.searchMapCenter,
);

export const getSearchMapZoom = createSelector(
  getListingSearchState,
  (state: ListingSearchState) => state.searchMapZoom,
);

export const getSearchMapHeading = createSelector(
  getListingSearchState,
  (state: ListingSearchState) => state.searchMapHeading,
);

export const getSearchListOpen = createSelector(
  getListingSearchState,
  (state: ListingSearchState) => state.searchListOpen,
);

export const getMapType = createSelector(getListingSearchState, (state: ListingSearchState) => state.layerType);
