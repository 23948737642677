import { CDate, ICalendarDay, IOrder, IPriceComponent, PriceComponent } from '@curbnturf/entities';
import { OrderAction, OrderActionTypes } from './order.actions';

export interface OrderState {
  order: IOrder; // the current order the user is working on
  priceComponents: IPriceComponent[];
  days: ICalendarDay[];
  error?: string; // last error (if any)
  priceError?: string; // last price error (if any)
}

// required to be an exported function
export function buildOrder(order?: IOrder): IOrder {
  if (!order) {
    return {};
  }

  const newOrder: IOrder = { ...order };

  if (newOrder.date) {
    newOrder.date = new CDate(newOrder.date);
  }

  if (newOrder.priceComponents) {
    newOrder.priceComponents = newOrder.priceComponents.map((price) => new PriceComponent(price));
  }

  newOrder.built = true;

  return newOrder;
}

export const initialState: OrderState = {
  order: {},
  priceComponents: [],
  days: [],
};

export function orderReducer(state: OrderState = initialState, action: OrderAction): OrderState {
  switch (action.type) {
    case OrderActionTypes.SetOrder: {
      state = {
        ...state,
        order: buildOrder(action.payload),
      };

      break;
    }
    case OrderActionTypes.PriceFilled: {
      if (action.payload && action.payload.priceComponents) {
        const priceComponents = action.payload.priceComponents;

        state = {
          ...state,
          order: buildOrder({ ...state.order, priceComponents }),
          priceComponents,
          priceError: undefined,
        };
      }
      break;
    }
    case OrderActionTypes.DaysFilled: {
      if (action.payload) {
        state = {
          ...state,
          days: action.payload,
        };
      }
      break;
    }
    case OrderActionTypes.OrderError: {
      const error = action.payload?.error?.message || action.payload?.message || action.payload;

      state = {
        ...state,
        error,
      };
      break;
    }
    case OrderActionTypes.PriceError: {
      const error = action.payload?.error?.message || action.payload?.message || action.payload;

      state = {
        ...state,
        priceError: error,
      };
      break;
    }
    default: {
      break;
    }
  }
  return state;
}
