import { ActivityType } from '@curbnturf/objects';
import { LocationCategory } from '../enums/location-categories';
import { IActivityLocation, IAddress, ICoordinateGis, IPhoto, IPoiRating, IUser } from '../interfaces';
import { PoiFavorite } from './poi-favorite';

export class ActivityLocation implements IActivityLocation {
  id: number;
  categoryDump?: boolean;
  categoryRestArea?: boolean;
  categoryWifi?: boolean;
  categoryPotableWater?: boolean;
  categoryPropane?: boolean;
  activityBiking?: boolean;
  activityBoating?: boolean;
  activityFishing?: boolean;
  activityGolf?: boolean;
  activityHiking?: boolean;
  activityHorseback?: boolean;
  activityHunting?: boolean;
  activityKayaking?: boolean;
  activityMotorSports?: boolean;
  activityOhv?: boolean;
  activityRafting?: boolean;
  activityRockClimbing?: boolean;
  activitySnowSports?: boolean;
  activitySurfing?: boolean;
  activitySwimming?: boolean;
  activityWaterSports?: boolean;
  activityWildlifeWatching?: boolean;
  activityWindSports?: boolean;
  customActivity?: string;
  name?: string;
  description?: string;
  address?: IAddress;
  coordinates: ICoordinateGis;
  photos?: IPhoto[]; // array of URL strings
  url?: string;
  phone?: string;
  user?: IUser;
  user_id?: number;

  // used for location related searches
  distance?: number;
  favoritedUsers?: PoiFavorite[];
  ratings?: IPoiRating[];

  // just used to match listings
  type?: string;

  created?: number;
  available?: boolean;
  hidden?: boolean;
  deleted?: boolean;
  deletedDate?: number;

  [id: string]: boolean | any;

  static hasActivity(activity: IActivityLocation): boolean {
    return activity.activityBiking ||
      activity.activityBoating ||
      activity.activityFishing ||
      activity.activityGolf ||
      activity.activityHiking ||
      activity.activityHorseback ||
      activity.activityHunting ||
      activity.activityKayaking ||
      activity.activityMotorSports ||
      activity.activityOhv ||
      activity.activityRafting ||
      activity.activityRockClimbing ||
      activity.activitySnowSports ||
      activity.activitySurfing ||
      activity.activitySwimming ||
      activity.activityWaterSports ||
      activity.activityWildlifeWatching ||
      activity.activityWindSports ||
      activity.customActivity
      ? true
      : false;
  }

  constructor(data?: IActivityLocation) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IActivityLocation): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (!this.photos) {
      this.photos = [];
    }
  }

  hasActivity(): boolean {
    return ActivityLocation.hasActivity(this);
  }

  getThingsToDo(): string {
    const locationActivities: string[] = [];

    if (this.categoryDump) {
      locationActivities.push(LocationCategory.dump);
    }

    if (this.categoryRestArea) {
      locationActivities.push(LocationCategory.restArea);
    }

    if (this.categoryWifi) {
      locationActivities.push(LocationCategory.wifi);
    }

    if (this.categoryPotableWater) {
      locationActivities.push(LocationCategory.potableWater);
    }

    if (this.categoryPropane) {
      locationActivities.push(LocationCategory.propane);
    }

    if (this.activityBiking) {
      locationActivities.push(ActivityType.biking);
    }

    if (this.activityBoating) {
      locationActivities.push(ActivityType.boating);
    }

    if (this.activityFishing) {
      locationActivities.push(ActivityType.fishing);
    }

    if (this.activityGolf) {
      locationActivities.push(ActivityType.golf);
    }

    if (this.activityHiking) {
      locationActivities.push(ActivityType.hiking);
    }

    if (this.activityHorseback) {
      locationActivities.push(ActivityType.horseback);
    }

    if (this.activityHunting) {
      locationActivities.push(ActivityType.hunting);
    }

    if (this.activityKayaking) {
      locationActivities.push(ActivityType.kayaking);
    }

    if (this.activityMotorSports) {
      locationActivities.push(ActivityType.motorSports);
    }

    if (this.activityOhv) {
      locationActivities.push(ActivityType.ohv);
    }

    if (this.activityRafting) {
      locationActivities.push(ActivityType.rafting);
    }

    if (this.activityRockClimbing) {
      locationActivities.push(ActivityType.rockClimbing);
    }

    if (this.activitySnowSports) {
      locationActivities.push(ActivityType.snowSports);
    }

    if (this.activitySurfing) {
      locationActivities.push(ActivityType.surfing);
    }

    if (this.activitySwimming) {
      locationActivities.push(ActivityType.swimming);
    }

    if (this.activityWaterSports) {
      locationActivities.push(ActivityType.waterSports);
    }

    if (this.activityWildlifeWatching) {
      locationActivities.push(ActivityType.wildlifeWatching);
    }

    if (this.activityWindSports) {
      locationActivities.push(ActivityType.windSports);
    }

    if (this.customActivity) {
      locationActivities.push(this.customActivity);
    }

    return locationActivities.join(', ');
  }

  getName(): string {
    return this.name || '';
  }

  getLocation(): string {
    if (this.address?.city) {
      return `${this.address.city}, ${this.address.state}`;
    }

    return '';
  }
}
