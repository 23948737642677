import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as AuthActions from '@curbnturf/auth/src/lib/+state/auth.actions';
import { BASE_API_URL, IAddress, ICurbNTurfCash, IRV, IUser, IUserSearchRequest } from '@curbnturf/entities';
import { objectToUrlParams } from '@curbnturf/helpers';
import { StatusFacade } from '@curbnturf/status';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

const API_URL = BASE_API_URL + 'user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
   constructor(private http: HttpClient, private statusFacade: StatusFacade, private store: Store) {}

  fetch(query: { id?: number; refId?: string }): Observable<IUser | undefined> {
    if (!query || (!query.id && !query.refId)) {
      throw new Error('No userId provided');
    }

    if (query.refId) {
      return this.http
        .get(`${API_URL}?affiliateRef=${query.refId}`)
        .pipe(map((users) => users && (users as IUser[]).find((user) => user.affiliateRef === query.refId)));
    }

    return this.http.get<IUser>(`${API_URL}/${query.id}`);
  }

  fetchAll(query: IUserSearchRequest): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${API_URL}?${objectToUrlParams(query)}`);
  }

  fetchFavorites(id: number): Observable<IUser | undefined> {
    if (!id) {
      throw new Error('No userId provided');
    }

    return this.http.get<IUser>(`${API_URL}/${id}/favorites`);
  }

  fetchAccountBalanceTransactions(userId: number): Observable<ICurbNTurfCash[]> {
    return this.http.get<ICurbNTurfCash[]>(`${API_URL}/${userId}/account-transactions`);
  }

  fetchCashTransactions(userId: number): Observable<ICurbNTurfCash[]> {
    return this.http.get<ICurbNTurfCash[]>(`${API_URL}/${userId}/cash-transactions`);
  }

  create(user: IUser): Observable<IUser> {
    return this.http.post<IUser>(API_URL, this.cleanForSaving(user));
  }

  update(user: IUser): Observable<IUser> {
    return this.http.put<IUser>(`${API_URL}/${user.id}`, this.cleanForSaving(user));
  }

  remove(id: number): Observable<IUser> {
    return this.http.delete<IUser>(`${API_URL}/${id}`);
  }

  restore(id: number): Observable<IUser> {
    return this.http.post<IUser>(`${API_URL}/${id}/restore`, null);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadImage(userId: number, files: any): Observable<IUser> {
    return this.http.post<IUser>(`${API_URL}/${userId}/image`, files);
  }

  removeImage(userId: number): Observable<IUser> {
    return this.http.delete<IUser>(`${API_URL}/${userId}/image`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadRvImage(userId: number, rvId: number, files: any): Observable<IUser> {
    return this.http.post<IUser>(`${API_URL}/${userId}/rv/${rvId}/image`, files);
  }

  removeRvImage(userId: number, rvId: number): Observable<IRV> {
    return this.http.delete<IUser>(`${API_URL}/${userId}/rv/${rvId}/image`);
  }

  postLead(user: IUser, assistance: boolean = true): Observable<boolean> {
    return this.http.post<boolean>(`${BASE_API_URL}contact/lead`, { user, assistance });
  }

  savePushToken(token: string): Observable<{ success: boolean; message: string }> {
    return this.statusFacade.userId$.pipe(
      mergeMap((userId) => {
        if (userId) {
          return this.http.put<{ success: boolean; message: string }>(`${API_URL}/${userId}/push-token`, {
            userId,
            token,
          });
        } else {
          throw new Error('Cannot save Push Token unless logged in.');
        }
      }),
    );
  }

  removeAccount(): Observable<{ success: boolean; message: string }> {
    return this.statusFacade.userId$.pipe(
      take(1),
      mergeMap((userId) => {
        if (userId) {
          return this.http
            .delete<{ success: boolean; message: string }>(`${API_URL}/${userId}/remove-account`, {
              body: {
                userId,
              },
            })
            .pipe(
              map((result) => {
                this.store.dispatch(
                  AuthActions.logout({
                    message: 'Your account and information have been removed. You have been logged out.',
                  }),
                );
                return result;
              }),
            );
        } else {
          throw new Error('Cannot remove user account. User not found.');
        }
      }),
    );
  }

  private cleanForSaving(originalUser: IUser): IUser {
    const user = { ...originalUser };
    if (!user.id && !user.email) {
      throw new Error('Unable to save: Invalid User.');
    }

    // If these aren't set then don't send them
    if (!user.id) {
      delete user.id;
    }
    if (!user.collectTaxes && user.collectTaxes !== false) {
      delete user.collectTaxes;
    }
    if (!user.inProgressId) {
      delete user.inProgressId;
    }
    if (!user.role) {
      delete user.role;
    }
    if (!user.source) {
      delete user.source;
    }

    /**
     * These are modified directly on the server through there own interfaces
     * so do not need to be sent.
     */
    delete user.locations;
    delete user.cash;
    delete user.cashTransactions;
    delete user.createdSiteRatings;
    delete user.createdGuestRatings;
    delete user.createdBoondockRatings;
    delete user.createdPoiRatings;
    delete user.guestRating;
    delete user.guestRatingCount;
    delete user.guestRatings;
    delete user.sites;

    return user;
  }

  requestAddressChange(updatedAddress: IAddress): Observable<{status: string}> {
    return this.http.post<{status: string}>(`${API_URL}/address-change`, { updatedAddress });
  }

  verifyAddressChange(addressChangeId: string, verifyString: string, status: string) {
    return this.http.put<{status: string}>(`${API_URL}/address-change-verify/${addressChangeId}`, { id: addressChangeId, verifyString, status });
  }
}
