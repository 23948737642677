import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';
import { BASE_API_URL, ISite } from '@curbnturf/entities';
import { CachedHttpClient } from '@curbnturf/network';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

const API_URL = BASE_API_URL + 'site/display';

@Injectable({
  providedIn: 'root',
})
export class SiteDisplayService {
  constructor(
    private http: CachedHttpClient,
    @Inject(PLATFORM_ID) private platformId: string,
    private transferState: TransferState,
  ) {}

  fetch(siteId: number | string): Observable<ISite> {
    const siteStateKey = makeStateKey<ISite>('site-' + siteId);

    if (this.transferState.hasKey(siteStateKey)) {
      const site = this.transferState.get<ISite>(siteStateKey, {});
      this.transferState.remove(siteStateKey);
      return of(site);
    } else {
      return this.http
        .get<ISite>(`${API_URL}/${siteId}`, undefined, {
          offlineCallback: () => ({}), // @todo cache the site and load from the cache
        })
        .pipe(
          tap((site) => {
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(siteStateKey, site);
            }
          }),
        );
    }
  }
}
