import { NgModule } from '@angular/core';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { StatusFacade } from '@curbnturf/status';
import { UserModule } from '@curbnturf/user';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as reservationEffects from './+state/reservation.effects';
import { initialState as reservationInitialState, reservationReducer } from './+state/reservation.reducer';

@NgModule({
  imports: [UserModule],
  providers: [
    StatusFacade,
    provideState(NGRX_BUCKETS.reservation, reservationReducer, {
      initialState: reservationInitialState,
    }),
    provideEffects(reservationEffects),
  ],
})
export class ReservationModule {}
