import {
  CancellationPolicyOption,
  DEFAULT_CHECK_IN_TIME,
  DEFAULT_CHECK_OUT_TIME,
  ICalendarDay,
  IRating,
  IReservation,
  ISite,
  RatingType,
  Reservation,
  User,
} from '@curbnturf/objects';
import { DateTime } from 'luxon';
import { forceInt } from './math';

export const generateReservationConfirmationCode = (reservation: IReservation): string => {
  return `r-${reservation.id}`;
};

export const getCancellationPolicyString = (cancellationPolicy: string): string => {
  switch (cancellationPolicy) {
    case 'easy': {
      return `Cancel up to one day prior to check-in to get a full refund. Any early check-outs will receive a full refund for all nights falling 24 hours after cancellation.`;
    }
    case 'moderate': {
      return `Cancel up to three days prior to check-in to get a full refund. Any early check-outs will receive a half refund for all nights falling 24 hours after cancellation.`;
    }
    case 'strict': {
      return `Cancel up to seven days prior to check-in to get a half refund. There are no refunds for early check-outs.`;
    }
    case 'exclusive': {
      return 'Any cancellation is non-refundable. There are no refunds for early check-outs.';
    }
    default:
      return '';
  }
};

export function containsCompleteRating(ratings?: IRating[]): boolean {
  return !!ratings?.find((rating) => rating.topic === RatingType.overall && rating.score.description);
}

export function lastDayToCancel(reservation: Reservation): DateTime | false {
  if (reservation.site) {
    let cancelDaysInAdvance;
    switch (reservation.site.cancellationPolicy as string) {
      case 'exclusive': {
        return false;
      }
      case 'strict': {
        cancelDaysInAdvance = 7;
        break;
      }
      case 'moderate': {
        cancelDaysInAdvance = 3;
        break;
      }
      case 'easy':
      default: {
        cancelDaysInAdvance = 1;
      }
    }

    if (reservation.date && reservation.date.from) {
      let latestCancellableDate = DateTime.fromISO(reservation.date.from);
      latestCancellableDate = latestCancellableDate.minus({ day: cancelDaysInAdvance });
      return latestCancellableDate;
    }
  }
  return false;
}

export function isReservationCancellable(reservation: Reservation, currentUser: User): boolean {
  return Boolean(
    currentUser?.id &&
      reservation.cancellable(currentUser?.id) &&
      (function () {
        if (reservation && reservation.site) {
          let cancelDaysInAdvance;
          switch (reservation.site.cancellationPolicy as string) {
            case CancellationPolicyOption.exclusive:
            case 'exclusive': {
              return false;
            }
            case CancellationPolicyOption.strict:
            case 'strict': {
              cancelDaysInAdvance = 7;
              break;
            }
            case CancellationPolicyOption.moderate:
            case 'moderate': {
              cancelDaysInAdvance = 3;
              break;
            }
            case CancellationPolicyOption.easy:
            case 'easy':
            default: {
              cancelDaysInAdvance = 1;
              break;
            }
          }

          if (reservation && reservation.date && reservation.date.from) {
            let latestCancellableDate = DateTime.fromISO(reservation.date.from);
            latestCancellableDate = latestCancellableDate.minus({ day: cancelDaysInAdvance });

            const currentDate = DateTime.now();
            return currentDate < latestCancellableDate;
          }
        }

        return false;
      })(),
  );
}

export function filterAvailableDays(availableDays: ICalendarDay[], site: ISite): ICalendarDay[] {
  return availableDays.filter((day) => isDateValidForSite(day.date, site));
}

export function isDateValidForSite(date: string, site: ISite): boolean {
  if (!site) {
    return false;
  }

  const reserveDaysInAdvance = forceInt(site.reserveDaysInAdvance || 0);

  switch (reserveDaysInAdvance) {
    // if reserveDaysInAdvance = 0 then the can check in up until the check out time
    case 0: {
      return (
        DateTime.fromISO(`${date}T${site.checkOut || DEFAULT_CHECK_OUT_TIME}`, {
          zone: site.timezone || undefined,
        }).plus({
          days: 1,
        }) > DateTime.now()
      );
    }

    // by default if there is a reserveDaysInAdvance it is based of the checkIn
    default: {
      return (
        DateTime.fromISO(`${date}T${site.checkIn || DEFAULT_CHECK_IN_TIME}`, { zone: site.timezone || undefined }) >
        DateTime.now().plus({ days: reserveDaysInAdvance })
      );
    }
  }
}
