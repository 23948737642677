import { ComponentRef, Directive, Host, Optional, ViewContainerRef } from '@angular/core';
import { getFormValidationErrors } from '@curbnturf/form-helpers';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { DisplayValidationErrorsSubmitDirective } from '../display-validation-errors-submit.directive';
import { DisplayValidationErrorsSubmitButtonContainerDirective } from './display-validation-errors-submit-button-container.directive';
import { DisplayValidationErrorsSubmitButtonComponent } from './display-validation-errors-submit-button.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[type="submit"]',
  standalone: true,
})
export class DisplayValidationErrorsSubmitButtonDirective {
  ref?: ComponentRef<DisplayValidationErrorsSubmitButtonComponent>;
  submit$: Observable<Event>;
  container: ViewContainerRef;

  private subscriptions: Subscription[] = [];

  constructor(
    private vcr: ViewContainerRef,
    @Optional()
    displayValidationErrorsContainer: DisplayValidationErrorsSubmitButtonContainerDirective,
    @Optional() @Host() private form: DisplayValidationErrorsSubmitDirective,
  ) {
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
    this.container = displayValidationErrorsContainer ? displayValidationErrorsContainer.vcr : vcr;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.submit$.subscribe(() => {
        if (this.validForm()) {
          this.toggleError(false);
        } else {
          this.toggleError(true);
        }
      }),
    );
  }

  toggleError(show: boolean) {
    if (show) {
      this.ref = this.container.createComponent(DisplayValidationErrorsSubmitButtonComponent);
    } else {
      delete this.ref;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }

  private validForm(): boolean {
    if (!this.form?.formGroup?.form) {
      // no form group set so we don't know how to check so return true
      return true;
    }

    const errors = getFormValidationErrors(this.form.formGroup.form);
    // we have the form from the form group
    return !errors || errors.length === 0;
  }
}
