import { Injectable } from '@angular/core';
import { IUser, Role, User } from '@curbnturf/entities';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import * as authSelectors from './auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  loaded$: Observable<boolean>;

  // only use this in the auth module elswhere use from StatusFacade
  loggedIn$: Observable<boolean>;
  // only use this in the auth module elswhere use from StatusFacade
  userId$: Observable<number | undefined>;

  noRedirect$: Observable<boolean | undefined>;
  error$: Observable<string | undefined>;
  attemptEmail$: Observable<string | undefined>;
  tempRole$: Observable<Role | undefined>;
  user$: Observable<IUser | undefined>;
  accessToken$: Observable<string | undefined>;

  constructor(private store: Store) {
    this.loaded$ = this.store.pipe(select(authSelectors.getLoaded));
    this.loggedIn$ = this.store.pipe(select(authSelectors.getLoggedIn));
    this.userId$ = this.store.pipe(select(authSelectors.getUser), map((user) => user?.id));
    this.noRedirect$ = this.store.pipe(select(authSelectors.getNoRedirect));
    this.error$ = this.store.pipe(select(authSelectors.getError));
    this.attemptEmail$ = this.store.pipe(select(authSelectors.getAttemptEmail));
    this.tempRole$ = this.store.pipe(select(authSelectors.getTempRole));
    this.user$ = this.store.pipe(select(authSelectors.getUser));
    this.accessToken$ = this.store.pipe(select(authSelectors.getUser), map((user) => user?.authAccessToken));

  }

  login(loginObject: { email: string; password: string }) {
    this.store.dispatch(AuthActions.login({ ...loginObject }));
  }

  loginSuccess(user: User) {
    this.store.dispatch(AuthActions.loginSuccess({ user }));
  }

  loginError(errMsg: string) {
    this.store.dispatch(AuthActions.loginError({ error: errMsg }));
  }

  logout(message: string) {
    this.store.dispatch(AuthActions.logout({ message }));
  }

  recover(email: string) {
    this.store.dispatch(AuthActions.recoverPassword({ email }));
  }

  resendVerification(email: string) {
    this.store.dispatch(AuthActions.resendVerification({ email }));
  }

  reset(resetObject: { email: string; password: string; verificationCode: string }) {
    this.store.dispatch(AuthActions.resetPassword({ ...resetObject }));
  }

  newPassword(password: string, autoLogin: boolean = false) {
    this.store.dispatch(AuthActions.newPassword({ password, autoLogin }));
  }

  update(updateObject: { email: string; oldPassword: string; newPassword: string }) {
    this.store.dispatch(AuthActions.updatePassword({ ...updateObject }));
  }

  setTempRole(role: Role) {
    this.store.dispatch(AuthActions.setTempRole({ role }));
  }

  clearTempRole() {
    this.store.dispatch(AuthActions.clearTempRole());
  }
}
