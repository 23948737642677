<div *ngIf="!emailSignUp">
  <h2 class="heading-2 white">Create an Account</h2>
  <h3 class="heading-3 white">Allows Listing and Booking Sites</h3>
  <curbnturf-social-auth></curbnturf-social-auth>

  <div class="_18-px-spacer"></div>
  <div class="horz-line white"></div>

  <button
    (click)="emailSignUp = true"
    [ngClass]="{ nandor: !ios, apple: ios }"
    type="button"
    class="cnt-button w-inline-block"
  >
    <div class="cnt-button-icon" [ngClass]="{ auth: !ios, 'apple-auth': ios }">
      <i class="icon-cnt-envelope"></i>
    </div>
    <div class="cnt-button-copy">Continue With Email</div>
  </button>
</div>
<div *ngIf="emailSignUp">
  <h2 class="heading-2 white">
    Sign Up With <a [href]="facebookUrl" class="auth-link">Facebook</a> or
    <a [href]="googleUrl" class="auth-link">Google</a>
  </h2>

  <form [formGroup]="registerForm" (ngSubmit)="register()">
    <label for="emailAddress" class="sr-only">Email Address</label>
    <input
      id="emailAddress"
      formControlName="email"
      type="email"
      [email]="true"
      [required]="true"
      class="_48px-text-field w-input"
      placeholder="Email address"
      name="email"
    />
    <label for="firstName" class="sr-only">First Name</label>
    <input
      id="firstName"
      formControlName="firstName"
      type="text"
      [required]="true"
      class="_48px-text-field w-input"
      placeholder="First name"
      name="given-name"
    />
    <label for="lastName" class="sr-only">Last Name</label>
    <input
      id="lastName"
      formControlName="lastName"
      type="text"
      [required]="true"
      class="_48px-text-field w-input"
      placeholder="Last name"
      name="family-name"
    />
    <label for="password" class="sr-only">Password</label>
    <input
      id="password"
      formControlName="password"
      type="password"
      [required]="true"
      class="_48px-text-field w-input"
      placeholder="Create a Password"
      name="new-password"
    />

    <div class="_12-px-spacer"></div>

    <label class="w-checkbox checkbox-field white">
      <div
        class="w-checkbox-input w-checkbox-input--inputType-custom checkbox"
        [ngClass]="{
          'w--redirected-checked': registerForm?.get('over18')?.value
        }"
      ></div>
      <input
        type="checkbox"
        formControlName="over18"
        [containerElement]="over18Error"
        style="opacity: 0; position: absolute; z-index: -1"
      />
      <span class="checkbox-label w-form-label"> I am over 18 </span>
    </label>
    <div #over18Error="curbnturfDisplayValidationErrorsContainerRef" curbnturfDisplayValidationErrorsContainer></div>

    <label class="w-checkbox checkbox-field white">
      <div
        class="w-checkbox-input w-checkbox-input--inputType-custom checkbox"
        [ngClass]="{
          'w--redirected-checked': registerForm?.get('newsletter')?.value
        }"
      ></div>
      <input
        type="checkbox"
        checked="checked"
        formControlName="newsletter"
        style="opacity: 0; position: absolute; z-index: -1"
      />
      <span class="checkbox-label w-form-label"> Keep me up to date on CurbNTurf products and service by subscribing to the newsletter. </span>
    </label>

    <div class="_18-px-spacer"></div>
    <div class="horz-line white"></div>
    <div class="_18-px-spacer"></div>

    <p class="white">
      We&rsquo;ll send you marketing promotions, special offers, inspiration, and policy updates via email.
    </p>

    <div *ngIf="error" class="alert-danger">{{ error }}</div>
    <button type="submit" class="cnt-button nandor w-inline-block">
      <div class="cnt-button-copy">Sign Up</div>
    </button>
    <ng-container *ngIf="formError">
      <div class="_12-px-spacer"></div>
      <p class="alert-danger">Something is invalid, check the error messages above.</p>
    </ng-container>
  </form>
</div>
<hr />
<p class="white">
  Already have a CurbNTurf account?<br />
  <strong>
    <a class="auth-link" (click)="openLoginModal()" *ngIf="inModal"> Log in </a>

    <a [routerLink]="['', 'auth', 'login']" *ngIf="!inModal" class="auth-link"> Log in </a>
  </strong>
</p>
