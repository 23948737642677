import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { PROGRAM } from '@curbnturf/entities';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  public resize: Subject<void>;

  constructor(private platform: Platform, @Inject(PLATFORM_ID) private platformId: string) {
    this.resize = this.platform?.resize;
  }

  isPhysicalDevice() {
    if (isPlatformServer(this.platformId)) {
      return false;
    }

    return (this.platform.is('ios') || this.platform.is('android')) && !this.platform.is('mobileweb');
  }

  isIOS() {
    if (isPlatformServer(this.platformId)) {
      return false;
    }

    return this.platform.is('ios') && !this.platform.is('mobileweb');
  }

  isAndroid() {
    if (isPlatformServer(this.platformId)) {
      return false;
    }

    return this.platform.is('android') && !this.platform.is('mobileweb');
  }

  isIOSWeb() {
    if (isPlatformServer(this.platformId)) {
      return false;
    }

    return this.platform.is('ios') && this.platform.is('mobileweb');
  }

  isAndroidWeb() {
    if (isPlatformServer(this.platformId)) {
      return false;
    }

    return this.platform.is('android') && this.platform.is('mobileweb');
  }

  isMobileWeb() {
    return this.isIOSWeb() || this.isAndroidWeb();
  }

  isAppContext() {
    return PROGRAM === 'app';
  }

  isPhoneSize() {
    return (this.platform.isPortrait() && this.width() < 550) || (this.platform.isLandscape() && this.width() < 820);
  }

  isTabletSize() {
    return (this.platform.isPortrait() && this.width() >= 550) || (this.platform.isLandscape() && this.width() >= 820);
  }

  height() {
    return this.platform.height();
  }

  width() {
    return this.platform.width();
  }
}
