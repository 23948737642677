import { Action, createReducer, on } from '@ngrx/store';
import * as AuthModalActions from './auth-modal.actions';

export interface AuthModalState {
  modal?: string;
}

export const initialState: AuthModalState = {};

const authModalReducer = createReducer(
  initialState,
  on(AuthModalActions.openAuthModal, (state, action) => {
    return { ...state, modal: action.modal };
  }),
  on(AuthModalActions.closeAuthModal, (state) => {
    return { ...state, modal: undefined };
  }),
);

export function reducer(state: AuthModalState | undefined, action: Action) {
  return authModalReducer(state, action);
}
