import { inject } from '@angular/core';
import { IUser } from '@curbnturf/entities';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AddressBookService } from '../address-book.service';
import { AddressBookActionTypes } from './address-book.actions';

export const loadContacts$ = createEffect(
  (actions$ = inject(Actions), addressBookService = inject(AddressBookService)) =>
    actions$.pipe(
      ofType(AddressBookActionTypes.LoadContacts),
      switchMap(() =>
        addressBookService.fetch().pipe(
          map((users: IUser[]) => ({
            type: AddressBookActionTypes.ContactsLoaded,
            payload: users,
          })),
          catchError((err) => of({ type: AddressBookActionTypes.LoadContactsError, payload: err })),
        ),
      ),
      catchError((err) => of({ type: AddressBookActionTypes.LoadContactsError, payload: err })),
    ),
    { functional: true },
);
