<div class="icon-check">
  <div class="w-embed">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >
      <style type="text/css">
        .icon-check {
          fill: #008000;
        }
      </style>
      <path
        class="icon-check"
        d="M389.9,159.9c9.2,9.2,9.2,24.2,0,33.5L231.2,352.1c-9.2,9.2-24.2,9.2-33.5,0l-75.6-75.6
c-9.2-9.2-9.2-24.2,0-33.5c9.2-9.2,24.2-9.2,33.5,0l58.8,58.8l142-142C365.6,150.7,380.6,150.7,389.9,159.9z M511.5,256
C511.5,114.8,397.2,0.5,256,0.5C114.8,0.5,0.5,114.8,0.5,256c0,141.2,114.3,255.5,255.5,255.5C397.2,511.5,511.5,397.2,511.5,256z"
      ></path>
    </svg>
  </div>
</div>
