import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IActivityLocation } from '@curbnturf/entities';
import { requiredPoiCategoryValidator } from '../helpers/validators';
import {
  addressFormBuilder,
  coordinatesFormBuilder,
  formatAddressFormValue,
  formatCoordinatesFormValue,
  formatPhotosFormValue,
  photosFormBuilder,
} from './builder.helpers';
import { IActivityLocationForm, IAddressForm, ICoordinatesForm, IPhotoForm } from './interfaces';

export function activityLocationBuilder(
  formBuilder: FormBuilder,
  activity?: IActivityLocation,
): FormGroup<IActivityLocationForm> {
  return formBuilder.group(
    {
      id: [activity?.id ? activity.id : null],
      categoryDump: [activity?.categoryDump ? activity.categoryDump : false],
      categoryRestArea: [activity?.categoryRestArea ? activity.categoryRestArea : false],
      categoryWifi: [activity?.categoryWifi ? activity.categoryWifi : false],
      categoryPotableWater: [activity?.categoryPotableWater ? activity.categoryPotableWater : false],
      categoryPropane: [activity?.categoryPropane ? activity.categoryPropane : false],
      activityBiking: [activity?.activityBiking ? activity.activityBiking : false],
      activityBoating: [activity?.activityBoating ? activity.activityBoating : false],
      activityFishing: [activity?.activityFishing ? activity.activityFishing : false],
      activityGolf: [activity?.activityGolf ? activity.activityGolf : false],
      activityHiking: [activity?.activityHiking ? activity.activityHiking : false],
      activityHorseback: [activity?.activityHorseback ? activity.activityHorseback : false],
      activityHunting: [activity?.activityHunting ? activity.activityHunting : false],
      activityKayaking: [activity?.activityKayaking ? activity.activityKayaking : false],
      activityMotorSports: [activity?.activityMotorSports ? activity.activityMotorSports : false],
      activityOhv: [activity?.activityOhv ? activity.activityOhv : false],
      activityRafting: [activity?.activityRafting ? activity.activityRafting : false],
      activityRockClimbing: [activity?.activityRockClimbing ? activity.activityRockClimbing : false],
      activitySnowSports: [activity?.activitySnowSports ? activity.activitySnowSports : false],
      activitySurfing: [activity?.activitySurfing ? activity.activitySurfing : false],
      activitySwimming: [activity?.activitySwimming ? activity.activitySwimming : false],
      activityWaterSports: [activity?.activityWaterSports ? activity.activityWaterSports : false],
      activityWildlifeWatching: [activity?.activityWildlifeWatching ? activity.activityWildlifeWatching : false],
      activityWindSports: [activity?.activityWindSports ? activity.activityWindSports : false],
      customActivity: [activity?.customActivity ? activity.customActivity : ''],
      name: [activity?.name ? activity.name : '', [Validators.maxLength(120)]],
      description: [activity?.description ? activity.description : ''],
      address: addressFormBuilder(formBuilder, activity?.address),
      coordinates: coordinatesFormBuilder(formBuilder, activity?.coordinates),
      url: [activity?.url ? activity.url : ''],
      phone: [activity?.phone ? activity.phone : ''],
      photos: photosFormBuilder(formBuilder, activity?.photos),
      userId: [activity?.userId ? activity.userId : null],
      hidden: [activity?.hidden || activity?.hidden === false ? activity.hidden : true],
      available: [activity?.available || activity?.available === false ? activity.available : false],
    },
    {
      validators: [requiredPoiCategoryValidator()],
    },
  );
}

export function formatActivityLocationFormValue(form: FormGroup<IActivityLocationForm>): IActivityLocation {
  const value = form.value;

  return {
    id: value.id || undefined,
    categoryDump: value.categoryDump === null ? undefined : value.categoryDump,
    categoryRestArea: value.categoryRestArea === null ? undefined : value.categoryRestArea,
    categoryWifi: value.categoryWifi === null ? undefined : value.categoryWifi,
    categoryPotableWater: value.categoryPotableWater === null ? undefined : value.categoryPotableWater,
    categoryPropane: value.categoryPropane === null ? undefined : value.categoryPropane,
    activityBiking: value.activityBiking === null ? undefined : value.activityBiking,
    activityBoating: value.activityBoating === null ? undefined : value.activityBoating,
    activityFishing: value.activityFishing === null ? undefined : value.activityFishing,
    activityGolf: value.activityGolf === null ? undefined : value.activityGolf,
    activityHiking: value.activityHiking === null ? undefined : value.activityHiking,
    activityHorseback: value.activityHorseback === null ? undefined : value.activityHorseback,
    activityHunting: value.activityHunting === null ? undefined : value.activityHunting,
    activityKayaking: value.activityKayaking === null ? undefined : value.activityKayaking,
    activityMotorSports: value.activityMotorSports === null ? undefined : value.activityMotorSports,
    activityOhv: value.activityOhv === null ? undefined : value.activityOhv,
    activityRafting: value.activityRafting === null ? undefined : value.activityRafting,
    activityRockClimbing: value.activityRockClimbing === null ? undefined : value.activityRockClimbing,
    activitySnowSports: value.activitySnowSports === null ? undefined : value.activitySnowSports,
    activitySurfing: value.activitySurfing === null ? undefined : value.activitySurfing,
    activitySwimming: value.activitySwimming === null ? undefined : value.activitySwimming,
    activityWaterSports: value.activityWaterSports === null ? undefined : value.activityWaterSports,
    activityWildlifeWatching: value.activityWildlifeWatching === null ? undefined : value.activityWildlifeWatching,
    activityWindSports: value.activityWindSports === null ? undefined : value.activityWindSports,
    customActivity: value.customActivity || undefined,
    name: value.name || undefined,
    description: value.description || undefined,
    address: formatAddressFormValue(form.get('address') as FormGroup<IAddressForm>),
    coordinates: formatCoordinatesFormValue(form.get('coordinates') as FormGroup<ICoordinatesForm>),
    url: value.url || undefined,
    phone: value.phone || undefined,
    photos: formatPhotosFormValue(form.get('photos') as FormArray<FormGroup<IPhotoForm>>),
    userId: value.userId || undefined,
    hidden: value.hidden === null ? undefined : value.hidden,
    available: value.available === null ? undefined : value.available,
  };
}
