import { inject } from '@angular/core';
import { ILog, LogLevel } from '@curbnturf/entities';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LogHttpService } from '../log-http.service';
import * as LogActions from './log.actions';

export const start$ = createEffect((actions$ = inject(Actions), logService = inject(LogHttpService)) =>
  actions$.pipe(
    ofType(LogActions.startLogSession),
    switchMap(() =>
      logService.startSession().pipe(
        map((session) =>
          LogActions.logSessionStarted({
            payload: { response: { ...session } },
          }),
        ),
        catchError(() => {
          const log: ILog = {
            level: LogLevel.WARNING,
            message: 'Failed To Start Logging Session. Proceeding Without...',
          };
          return from([LogActions.logMessage({ log })]);
        }),
      ),
    ),
  ),
  {functional: true},
);
