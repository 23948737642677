import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionStatus } from '@capacitor/network';
import { AddressBookActionTypes } from '@curbnturf/address-book';
import { AlertFacade } from '@curbnturf/alert/src/lib/+state/alert.facade';
import * as AuthModalActions from '@curbnturf/auth-modal/src/lib/+state/auth-modal.actions';
import { BASE_API_URL, IContact, IContactRequestingAssistance, IUser, PRODUCTION, User } from '@curbnturf/entities';
import { CachedHttpClient, NetworkService } from '@curbnturf/network';
import { Logger } from '@curbnturf/network/src/lib/log/logger';
import * as NetworkSelectors from '@curbnturf/network/src/lib/network/+state/network.selectors';
import { StorageFacade } from '@curbnturf/storage/src/lib/+state/storage.facade';
import { UrlControlFacade } from '@curbnturf/url-control/src/lib/+state/url-control.facade';
import { UserMarketingService } from '@curbnturf/user';
import { UserFacade } from '@curbnturf/user/src/lib/+state/user.facade';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, first, map, mergeMap, skipWhile, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AuthFacade } from './+state/auth.facade';
import { AuthState } from './+state/auth.reducer';
import * as authSelectors from './+state/auth.selectors';
import { CognitoService } from './cognito.service';

const AUTH_API_URL = BASE_API_URL + 'auth';
const CONTACT_API_URL = BASE_API_URL + 'contact';
const USER_API_URL = BASE_API_URL + 'user';

@Injectable()
export class AuthService {
  logger: Logger;

  constructor(
    private alertFacade: AlertFacade,
    private authFacade: AuthFacade,
    private cachedHttp: CachedHttpClient,
    private cognitoService: CognitoService,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private injector: Injector,
    private networkService: NetworkService,
    private router: Router,
    private storageFacade: StorageFacade,
    private store: Store<AuthState>,
    private urlControlFacade: UrlControlFacade,
    private userFacade: UserFacade,
    private userMarketingService: UserMarketingService,
  ) {
    setTimeout(() => {
      this.logger = this.injector.get(Logger);
      if (!PRODUCTION) {
        this.logger.logToConsole = true;
      }
    });
  }

  /**
   * Login and load the user
   */
  public login(email: string, password: string): Observable<IUser | undefined> {
    return from(this.networkService.getCurrentNetworkStatus()).pipe(
      mergeMap((status: ConnectionStatus) => {
        if (status?.connected === false) {
          this.alertFacade.display({
            level: 'warning',
            title: 'Authentication Required',
            body: 'This area requires you to login. You must be online to login. Please try again when your connection has been reestablished.',
          });
          return of(undefined);
        } else {
          return from(this.cognitoService.authenticateUser(email?.trim(), password?.trim())).pipe(
            // migration code
            catchError(async (err) => {
              this.logger.info('User Authentication Error', err);
              if (err.code === 'UserNotConfirmedException') {
                await this.router.navigate(['', 'auth', 'verifying']);
                throw new Error('User must verify their email before logging in.');
              }

              if (err.code === 'NotAuthorizedException' || err.code === 'UserNotFoundException') {
                throw new Error('Please re-enter your email and password and try again');
              }

              if (err.code === 'PasswordResetRequiredException') {
                throw new Error(
                  'Your password needs to be reset. Click the "Forgot password" link and follow the included steps to proceed.',
                );
              }

              throw new Error(err.code);
            }),
            switchMap((user: IUser) => {
              if (user.temporaryPassword) {
                this.store.dispatch(AuthModalActions.openAuthModal({ modal: 'newPassword' }));
                return this.store.pipe(select(authSelectors.getNewPasswordSet)).pipe(
                  skipWhile((passwordUser) => !passwordUser),
                  take(1),
                  map(() => undefined),
                );
              }

              return this.storeLogin(user);
            }),
          );
        }
      }),
    );
  }

  /**
   * Stores the login tokens and gets the user from the server which is then returned
   *
   * @param user - This user should have the authAccessToken, authIdToken, authRenewToken, and authExpires set.
   * @returns an observable with a user loaded from the server without the tokens.
   */
  public storeLogin(user: IUser): Observable<IUser> {
    // login successful if there's a jwt token in the response
    if (user && user.authIdToken && user.authRenewToken) {
      // save the auth tokens to the auth store
      this.authFacade.loginSuccess(new User(user));

      // get the current user using the auth keys so they are fully populated
      return this.authFacade.user$.pipe(
        skipWhile((user) => !user?.authExpires || user.authExpires < DateTime.now().toMillis()),
        first(),
        switchMap(() =>
          this.getCurrentUser().pipe(
            switchMap((existingUser) => {
              if (existingUser && existingUser.id) {
                // update the auth store to include update user information specifically id and role
                this.authFacade.loginSuccess(
                  new User({
                    ...user,
                    firstName: existingUser.firstName,
                    lastName: existingUser.lastName,
                    role: existingUser.role,
                    id: existingUser.id,
                    // the email should already be in the user but we are replacing it to be sure
                    email: existingUser.email,
                  }),
                );

                if (!existingUser.role) {
                  return this.authFacade.tempRole$.pipe(
                    first(),
                    map((tempRole) => {
                      // if there is a temporary role we should apply it to the user
                      if (tempRole) {
                        this.userFacade.update({ ...existingUser, role: tempRole });
                        this.userFacade.currentUserLoaded({ ...existingUser, role: tempRole });
                        this.authFacade.clearTempRole();

                        return { ...existingUser, role: tempRole };
                      }

                      this.userFacade.currentUserLoaded(existingUser);

                      return existingUser;
                    }),
                  );
                } else {
                  // we don't need to reload the user but we do want to store any possible updates in the user store
                  this.userFacade.currentUserLoaded(existingUser);
                }
                return of(existingUser);
              }

              this.logger.error('Unable to find user on server', JSON.stringify(user));
              throw new Error('Unable to find user on server');
            }),
          ),
        ),
      );
    }

    this.logger.error('Invalid login user', JSON.stringify(user));
    throw new Error('Invalid login user');
  }

  public async logout(user: any): Promise<boolean> {
    if (user && user.authIdToken && user.authAccessToken && user.authRenewToken) {
      await this.cognitoService.signOut({
        IdToken: user.authIdToken,
        AccessToken: user.authAccessToken,
        RefreshToken: user.authRenewToken,
      });
    } else {
      throw new Error('No logged in user found.' + !!user + ' ' + !!user.authIdToken + ' ' + !!user.authAccessToken + ' ' + !!user.authRenewToken);
    }

    // remove user and anything else from local storage to log user out
    this.storageFacade.clear();

    this.logger.debug('User Logged Out');

    return await this.router.navigateByUrl('/');
  }

  public resetPassword(email: string, verificationCode: string, password: string): Observable<IUser> {
    this.logger.info('Password Reset', email);
    return this.cognitoService.confirmResetPassword(email?.trim(), verificationCode?.trim(), password?.trim());
  }

  public updatePassword(oldPassword: string, newPassword: string): Observable<boolean> {
    return this.authFacade.accessToken$.pipe(
      switchMap((accessToken) => {
        if (accessToken) {
          return this.cognitoService.changePassword(oldPassword?.trim(), newPassword?.trim(), accessToken).pipe(
            tap(() =>
              this.userFacade.currentUserOnce$.subscribe((user) =>
                this.userFacade.update({ ...user, adminPassword: false }),
              ),
            ),
            tap(() => this.logger.info('User Password Updated')),
          );
        }

        return of(false);
      }),
    );
  }

  public newPassword(password: string): Observable<string> {
    return from(this.cognitoService.newPassword(password?.trim()));
  }

  public recover(email: string): Observable<IUser> {
    return this.cognitoService.resetPassword(email?.trim());
  }

  public register(email: string, password: string): Promise<string> {
    return this.cognitoService.signUp(email?.trim(), password?.trim()).then(
      () => 'success',
      (err) => err.message,
    );
  }

  public registerHost(contact: IContact, password: string): Observable<IUser> {
    return from(this.cognitoService.signUp(contact.email?.trim(), password?.trim())).pipe(
      mergeMap(() => {
        contact.email = contact.email.toLocaleLowerCase();

        // Create a Sync ID so we can sync this object later.
        contact.syncId = uuidv4();

        return this.cachedHttp.post<IUser>(`${CONTACT_API_URL}/first-five`, contact, undefined, {
          offlineCallback: () => ({ ...contact, id: -1 }),
          syncAction: { type: AddressBookActionTypes.SyncContact },
        });
      }),
    );
  }

  public registerWithoutPassword(data: IContact, fileData?: FormData): Observable<IUser> {
    data.email = data.email.toLocaleLowerCase();

    // Create a Sync ID so we can sync this object later.
    data.syncId = uuidv4();

    if (fileData) {
      return this.cachedHttp.post(`${CONTACT_API_URL}/with-image`, fileData, undefined, {
        offlineCallback: () => ({ ...fileData, id: -1 }),
        syncAction: { type: AddressBookActionTypes.SyncContact },
      });
    }

    return this.cachedHttp.post(CONTACT_API_URL, data, undefined, {
      offlineCallback: () => ({ ...data, id: -1 }),
      syncAction: { type: AddressBookActionTypes.SyncContact },
    });
  }

  public registerRequestingHelp(data: IContactRequestingAssistance): Observable<IUser> {
    data.email = data.email.toLocaleLowerCase();

    // Create a Sync ID so we can sync this object later.
    data.syncId = uuidv4();

    if(data.newsletter === true) {
      this.userMarketingService.registerForNewsletter(new User({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
      })).subscribe({
        error: () => {
          /*do nothing*/
        },
      });
    }

    return this.cachedHttp.post(CONTACT_API_URL, data, undefined, {
      offlineCallback: () => ({ ...data, id: -1 }),
      syncAction: { type: AddressBookActionTypes.SyncContact },
    });
  }

  // This handles logins and sign ups through Facebook and Google
  public codeLogin(code: string) {
    this.cognitoService.codeToUser(code?.trim()).subscribe(async (user) => {
      if (user.email) {
        const email = user.email;

        this.urlControlFacade.referenceIdOnce$.subscribe((source?: string) => {
          this.userFacade.create({
            email,
            firstName: user.firstName,
            lastName: user.lastName,
            authAccessToken: user.authAccessToken,
            authIdToken: user.authIdToken,
            authRenewToken: user.authRenewToken,
            authExpires: user.authExpires,
            loginAfter: true,
            source,
          });
        });
      }
    });
  }

  // we can get the current user using /me as long as we have the tokens stored
  public getCurrentUser(): Observable<IUser> {
    return this.http.get<IUser>(`${USER_API_URL}/me`);
  }

  public resendVerification(email: string) {
    return this.cognitoService.resendVerification(email?.trim());
  }

  public renew(): Observable<string> {
    return this.authFacade.user$.pipe(
      first(),
      withLatestFrom(this.store.select(NetworkSelectors.getStatus)),
      switchMap(([user, status]) => {
        if (status?.connected === true) {
          if (user?.authRenewToken && user?.authIdToken) {
            return this.cognitoService.renew(user.authRenewToken, user.authIdToken).pipe(
              switchMap((userWithTokens) =>
                // once we renew the tokens we will replace the existing tokens and re-pull the user
                this.storeLogin({
                  id: user.id,
                  role: user.role,
                  ...userWithTokens,
                }).pipe(map(() => 'success')),
              ),
            );
          }
        } else {
          // when offline we will wait to renew the tokens
          return of('deferred');
        }

        return throwError('Unable to renew token');
      }),
    );
  }

  public confirmEmail(confirmationCode: string, username: string): Observable<boolean> {
    return this.http.get<boolean>(`${AUTH_API_URL}?confirmationCode=${confirmationCode}&username=${username}`);
  }
}
