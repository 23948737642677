import { isPlatformBrowser, NgClass, NgIf } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AuthFacade, AuthService } from '@curbnturf/auth';
import { AuthModalFacade } from '@curbnturf/auth-modal/src/lib/+state/auth-modal.facade';
import { COGNITO_CALLBACK, COGNITO_CLIENT_ID, COGNITO_DOMAIN, Role, User } from '@curbnturf/entities';
import {
  DisplayValidationErrorsContainerDirective,
  DisplayValidationErrorsDirective,
  DisplayValidationErrorsSubmitButtonDirective,
  DisplayValidationErrorsSubmitDirective,
} from '@curbnturf/form-helpers';
import { PlatformService } from '@curbnturf/network/src/lib/platform/platform.service';
import { UrlControlFacade } from '@curbnturf/url-control/src/lib/+state/url-control.facade';
import { UserMarketingService } from '@curbnturf/user';
import { UserFacade } from '@curbnturf/user/src/lib/+state/user.facade';
import { IonicModule } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SocialAuthComponent } from '../social-auth/social-auth.component';

@Component({
  selector: 'curbnturf-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  standalone: true,
  imports: [
    DisplayValidationErrorsDirective,
    DisplayValidationErrorsContainerDirective,
    DisplayValidationErrorsSubmitButtonDirective,
    DisplayValidationErrorsSubmitDirective,
    FormsModule,
    IonicModule,
    NgClass,
    NgIf,
    ReactiveFormsModule,
    RouterLink,
    SocialAuthComponent,
  ],
})
export class RegisterComponent implements OnInit, OnDestroy {
  @Input() inModal: boolean;

  emailSignUp = false;
  formError = false;
  ios?: boolean;

  role?: Role;
  source?: string;

  registerForm: FormGroup<{
    email: FormControl<string | null>;
    firstName: FormControl<string | null>;
    lastName: FormControl<string | null>;
    over18: FormControl<boolean | null>;
    password?: FormControl<string | null>;
    newsletter?: FormControl<boolean | null>;
  }>;

  facebookUrl =
    `https://${COGNITO_DOMAIN}/oauth2/authorize?redirect_uri=${COGNITO_CALLBACK}` +
    `&response_type=code&client_id=${COGNITO_CLIENT_ID}&identity_provider=Facebook`;

  googleUrl =
    `https://${COGNITO_DOMAIN}/oauth2/authorize?redirect_uri=${COGNITO_CALLBACK}` +
    `&response_type=code&client_id=${COGNITO_CLIENT_ID}&identity_provider=Google`;

  error?: string;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private authFacade: AuthFacade,
    private authModalFacade: AuthModalFacade,
    private authService: AuthService,
    private fb: FormBuilder,
    private platformService: PlatformService,
    @Inject(PLATFORM_ID) private platformId: string,
    private urlControlFacade: UrlControlFacade,
    private userFacade: UserFacade,
    private userMarketingService: UserMarketingService,
  ) {
    this.registerForm = this.fb.group<{
      email: FormControl<string | null>;
      firstName: FormControl<string | null>;
      lastName: FormControl<string | null>;
      over18: FormControl<boolean | null>;
      password?: FormControl<string | null>;
      newsletter?: FormControl<boolean | null>;
    }>({
      email: this.fb.control<string | null>('', [Validators.required, Validators.email]),
      firstName: this.fb.control<string | null>('', [Validators.required]),
      lastName: this.fb.control<string | null>('', [Validators.required]),
      over18: this.fb.control<boolean | null>(false, [Validators.requiredTrue]),
      password: this.fb.control<string | null>('', [
        Validators.required,
        Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})'),
      ]),
      newsletter: this.fb.control<boolean | null>(true),
    });
  }

  ngOnInit() {
    this.ios = this.platformService.isIOS() || this.platformService.isIOSWeb();

    this.subscriptions.push(this.authFacade.tempRole$.subscribe((role?: Role) => (this.role = role)));

    if (isPlatformBrowser(this.platformId)) {
      if ((this.route.snapshot?.data as any)?.role) {
        this.role = (this.route.snapshot.data as any).role;
      }
    }

    this.urlControlFacade.referenceIdOnce$.subscribe((referenceId) => {
      if (referenceId) {
        this.source = referenceId;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public async register() {
    const emailInput = this.registerForm.get('email');
    const passwordInput = this.registerForm.get('password');
    const firstNameInput = this.registerForm.get('firstName');
    const lastNameInput = this.registerForm.get('lastName');

    if (emailInput && passwordInput && firstNameInput && lastNameInput) {
      const email = emailInput.value;
      const password = passwordInput.value;
      const firstName = firstNameInput.value;
      const lastName = lastNameInput.value;

      if (this.registerForm.valid && email && password && firstName && lastName) {
        const formValues = this.registerForm.value;
        this.formError = false;
        const message = await this.authService.register(email, password);

        if (message === 'success') {
          this.userFacade.create({
            email,
            firstName,
            lastName,
            role: this.role,
            source: this.source,
          });

          if (formValues.newsletter === true) {
            try {
              this.userMarketingService
                .registerForNewsletter(
                  new User({
                    firstName,
                    lastName,
                    email,
                  }),
                )
                .subscribe();
            } catch (error) {}
          }
          delete this.error;
        } else {
          this.error = `Unable to register user. Please try again. ${message}`;
        }
      } else {
        this.formError = true;
      }
    } else {
      this.formError = true;
    }
  }

  openLoginModal() {
    this.authModalFacade.openAuthModal('login');
  }
}
