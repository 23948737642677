import { NGRX_BUCKETS } from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NetworkState } from './network.reducer';

// Lookup the 'Network' feature state managed by NgRx
export const getNetworkState = createFeatureSelector<NetworkState>(NGRX_BUCKETS.network);

export const getStatus = createSelector(getNetworkState, (state: NetworkState) => state.status);
export const getCachedHttpRequests = createSelector(getNetworkState, (state: NetworkState) => state.cachedHttpRequests);
export const getCachedHttpResponses = createSelector(
  getNetworkState,
  (state: NetworkState) => state.cachedHttpResponses,
);
export const getNetworkOverride = createSelector(getNetworkState, (state: NetworkState) => state.override);

export const getPendingItems = createSelector(getNetworkState, (state: NetworkState) => ({
  requests: state.cachedHttpRequests,
  sites: state.siteUpdates,
  sitePhotos: state.sitePhotoUpdates,
  user: state.userUpdate,
  userPhoto: state.userPhotoUpdate,
}));
