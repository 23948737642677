export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const camelToSnake = (str: string): string => {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.split(' ').join('_').toLowerCase();
};

export const textToBoolean = (str: string): boolean => {
  return str === 'true' ? true : str === 'false' ? false : (undefined as any);
};

export function titlecaseToKebabcase(title: string): string {
  return (title?.replace(/(\s)/g, '-').trim() || '').toLocaleLowerCase();
}

export function kebabcaseToTitlecase(kebab: string): string {
  return kebab
    ?.replace(/(^\w)/g, (g) => g[0].toUpperCase())
    .replace(/([-]\w)/g, (g) => ' ' + g[1].toUpperCase())
    .replace(/And/g, 'and')
    .trim();
}
