import { createAction, props } from '@ngrx/store';

export enum AuthModalActionTypes {
  OpenAuthModal = '[AuthModal] Open Auth Modal',
  CloseAuthModal = '[AuthModal] Close Auth Modal',
}

export const openAuthModal = createAction(AuthModalActionTypes.OpenAuthModal, props<{ modal?: string }>());

export const closeAuthModal = createAction(AuthModalActionTypes.CloseAuthModal);
