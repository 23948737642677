import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringList',
  standalone: true,
})
export class StringListPipe implements PipeTransform {
  transform(value: string[]): string {
    if (!value || !value.length) {
      return '';
    }

    return value.join(', ');
  }
}
