import { NGRX_BUCKETS } from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IStorageState } from './storage.interface';

// Lookup the 'Rating' feature state managed by NgRx
const getStorageState = createFeatureSelector<IStorageState>(NGRX_BUCKETS.storage);

const getHydrated = createSelector(getStorageState, (state: IStorageState) => state.hydrated);

export const storageQuery = {
  getStorageState,
  getHydrated,
};
