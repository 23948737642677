import { Component, makeStateKey } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertModule } from '@curbnturf/alert';
import { NetworkModule } from '@curbnturf/network';
import { IonicModule } from '@ionic/angular';
import { UpdateComponent } from './components/update/update.component';

export const NGRX_STATE = makeStateKey('NGRX_STATE');

@Component({
  selector: 'curbnturf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [AlertModule, IonicModule, NetworkModule, RouterModule, UpdateComponent],
})
export class AppComponent {
  title = 'curbnturf-admin';
}
