import { ISite } from '@curbnturf/entities';
import { createReducer, on } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import * as SiteActions from './site.actions';

/**
 * Interface for the 'Site' data used in
 *  - SiteState, and
 *  - siteReducer
 */
export interface SiteState {
  sites: ISite[];
  selected?: string | number;
  error?: string;
  loaded?: boolean;
}

export const initialState: SiteState = {
  sites: [],
  loaded: false,
};

export const siteReducer = createReducer(
  initialState,
  on(SiteActions.siteUpdated, (state, action) => {
    if (!action.site) {
      return state;
    }

    const site = action.site;

    const siteState = {
      ...state,
      sites: [...state.sites],
      error: undefined,
    };

    // Find site by ID first then by SyncId, Replace with the updated
    const foundIndex = siteState.sites.findIndex(
      (el) => (el.id && site.id && el.id === site.id) || (el.syncId && site.syncId && el.syncId === site.syncId),
    );
    if (foundIndex !== -1) {
      siteState.sites.splice(foundIndex, 1, site);
    } else {
      siteState.sites.push(site);
    }

    return siteState;
  }),
  on(SiteActions.siteRestored, (state, action) => {
    if (!action.site) {
      return state;
    }

    const site = action.site;

    const siteState = {
      ...state,
      sites: [...state.sites],
      error: undefined,
    };

    // Find site by ID first then by SyncId, Replace with the updated
    const foundIndex = siteState.sites.findIndex(
      (el) => (el.id && site.id && el.id === site.id) || (el.syncId && site.syncId && el.syncId === site.syncId),
    );
    if (foundIndex !== -1) {
      siteState.sites.splice(foundIndex, 1, site);
    } else {
      siteState.sites.push(site);
    }

    return siteState;
  }),
  on(SiteActions.siteLoaded, (state, action) => {
    if (!action.site) {
      return state;
    }

    const site = action.site;

    const siteState = {
      ...state,
      sites: [...state.sites],
      error: undefined,
    };

    // Find site by ID first then by SyncId, Replace with the updated
    const foundIndex = siteState.sites.findIndex(
      (el) => (el.id && site.id && el.id === site.id) || (el.syncId && site.syncId && el.syncId === site.syncId),
    );
    if (foundIndex !== -1) {
      siteState.sites.splice(foundIndex, 1, site);
    } else {
      siteState.sites.push(site);
    }

    return siteState;
  }),

  on(SiteActions.siteImagesUpdated, (state, action) => {
    if (!action.photos || (!action.id && !action.syncId)) {
      return state;
    }

    const id = action.id;
    const syncId = action.syncId;
    const photos = action.photos;

    const siteState = {
      ...state,
      sites: [...state.sites],
      error: undefined,
    };

    // Find site by ID first then by SyncId, Replace with the updated
    const foundIndex = siteState.sites.findIndex(
      (el) => (el.id && id && el.id === id) || (el.syncId && syncId && el.syncId === syncId),
    );
    if (foundIndex !== -1) {
      const site: ISite = {
        ...siteState.sites[foundIndex],
      };

      if (photos) {
        site.photos = photos;
      }

      siteState.sites.splice(foundIndex, 1, site);
    }

    return siteState;
  }),

  on(SiteActions.sitesLoaded, (state, action) => {
    const sites: ISite[] = [...state.sites];
    action.sites.forEach((site) => {
      if (site && (site.id || site.syncId)) {
        const foundIndex = sites.findIndex(
          (el) => (el.id && site.id && el.id === site.id) || (el.syncId && site.syncId && el.syncId === site.syncId),
        );
        if (foundIndex === -1) {
          sites.push(site);
        } else {
          sites.splice(foundIndex, 1, site);
        }
      }
    });

    return {
      ...state,
      sites,
      error: undefined,
      loaded: true,
    };
  }),

  on(SiteActions.clearSites, (state) => {
    return { ...state, sites: [], error: undefined };
  }),
  on(SiteActions.siteRemoved, (state, action) => {
    const site = action.site;

    const siteState = {
      ...state,
      sites: [...state.sites],
      error: undefined,
    };

    // Find site by ID first then by SyncId, Replace with the updated
    const foundIndex = siteState.sites.findIndex(
      (el) => (el.id && site.id && el.id === site.id) || (el.syncId && site.syncId && el.syncId === site.syncId),
    );
    if (foundIndex !== -1) {
      siteState.sites.splice(foundIndex, 1);
    }

    return siteState;
  }),

  on(SiteActions.siteEnabled, (state, action) => {
    const id = action.siteId;

    const siteState = {
      ...state,
      sites: [...state.sites],
      error: undefined,
    };

    // Find site by ID first then by SyncId
    const foundIndex = siteState.sites.findIndex(
      (el) => (el.id && id && el.id === id) || (el.syncId && id && el.syncId === id),
    );
    if (foundIndex !== -1) {
      const site: ISite = { ...siteState.sites[foundIndex], available: true };
      siteState.sites.splice(foundIndex, 1, site);
    }

    return siteState;
  }),
  on(SiteActions.siteDisabled, (state, action) => {
    const id = action.siteId;

    const siteState = {
      ...state,
      sites: [...state.sites],
      error: undefined,
    };

    // Find site by ID first then by SyncId
    const foundIndex = siteState.sites.findIndex(
      (el) => (el.id && id && el.id === id) || (el.syncId && id && el.syncId === id),
    );
    if (foundIndex !== -1) {
      const site: ISite = { ...siteState.sites[foundIndex], available: false };
      siteState.sites.splice(foundIndex, 1, site);
    }

    return siteState;
  }),
  on(SiteActions.selectSiteBySite, (state, action) => {
    if (!action.site || (!action.site.id && !action.site.syncId)) {
      return {
        ...state,
        selected: undefined,
        error: undefined,
      };
    }

    const id: number | string = action.site.id ?? action.site.syncId ?? uuidv4();
    return {
      ...state,
      selected: id,
      error: undefined,
    };
  }),
  on(SiteActions.siteSelected, (state, action) => {
    if (!action.site || (!action.site.id && !action.site.syncId)) {
      return {
        ...state,
        selected: undefined,
        error: undefined,
      };
    }

    const id: number | string = action.site.id ?? action.site.syncId ?? uuidv4();
    return {
      ...state,
      selected: id,
      error: undefined,
    };
  }),
  on(SiteActions.updateSiteFailed, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(SiteActions.siteFavorFailed, (state, action) => {
    return {
      ...state,
      error: action.error,
    };
  }),
  on(SiteActions.siteFavorCompleted, (state) => {
    return {
      ...state,
      error: undefined,
    };
  }),
);
