import { containsCompleteRating } from '@curbnturf/helpers';
import { DateTime } from 'luxon';
import { CancellationPolicyOption, Status } from '../enums';
import {
  IGuestRating,
  IMessageChannel,
  IReservation,
  IReservationExtra,
  IRV,
  ISite,
  ISiteRating,
  IStatusUpdate,
  IUser,
} from '../interfaces';
import { CDate } from './c-date';
import { PriceComponent } from './price-component';
import { User } from './user';

export class Reservation implements IReservation {
  id?: number;
  date?: CDate;
  extras?: IReservationExtra[];
  user?: IUser;
  userId?: number;
  site?: ISite;
  siteId?: number;
  activity?: IStatusUpdate[];
  priceComponents?: PriceComponent[];
  guestRatings?: IGuestRating[];
  guestRated?: boolean;
  siteRatings?: ISiteRating[];
  siteRated?: boolean;
  messageChannel?: IMessageChannel;
  status?: string; // this should match last status in activity
  rv?: IRV;
  rvId?: number;

  get total(): number {
    if (this.priceComponents) {
      return this.priceComponents.reduce(
        (total, price) => total + price.value - (price.discount || 0) + (price.tax || 0),
        0,
      );
    }

    return 0;
  }

  get hostName(): string {
    if (this.site && this.site.user && this.site.user.firstName) {
      return this.site.user.firstName;
    }

    return 'Unavailable';
  }

  get hasHostRated(): boolean {
    return this.guestRated || containsCompleteRating(this.guestRatings) || false;
  }

  get hasGuestRated(): boolean {
    return this.siteRated || containsCompleteRating(this.guestRatings) || false;
  }

  public static reservationCompleted(reservation: IReservation): boolean {
    return (
      reservation.status === Status.completed ||
      !reservation.date?.toDateArray().find((date) => DateTime.now().startOf('day') < date.startOf('day'))
    );
  }

  constructor(data?: IReservation) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IReservation): void {
    for (const key in data) {
      if (
        Object.prototype.hasOwnProperty.call(data, key) &&
        key !== 'dates' &&
        key !== 'priceComponents' &&
        key !== 'user'
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (data.date) {
      this.date = new CDate(data.date);
    }

    if (data.priceComponents) {
      this.priceComponents = data.priceComponents.map(
        (priceComponent) =>
          new PriceComponent({
            ...priceComponent,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value: parseFloat(priceComponent.value as any),
          }),
      );
    } else if (!this.priceComponents) {
      this.priceComponents = [];
    }

    this.user = new User(data.user);
  }

  reservationCompleted(): boolean {
    return Reservation.reservationCompleted(this);
  }

  changeLastNightToEndDate(): Reservation {
    this.date = new CDate(this.date);

    if (this.date) {
      let toDate = this.date.to;
      if (!toDate) {
        toDate = this.date.from;
      }

      if (toDate) {
        this.date.to = DateTime.fromISO(toDate).plus({ days: 1 }).toISODate() || '';
      }
    }

    return this;
  }

  isGuest(userId?: number): boolean {
    return (this.user && this.user.id === userId) || false;
  }

  isHost(userId?: number): boolean {
    return (this.site && this.site.user && this.site.user.id === userId) || false;
  }

  isClosed(): boolean {
    return (
      this.getStatus() === Status.rejected ||
      this.getStatus() === Status.cancelled ||
      this.getStatus() === Status.completed
    );
  }

  cancellable(userId?: number): boolean {
    // Check that only hosts can cancel a "requested" reservation.
    if (userId) {
      if (!this.isHost(userId) && this.getStatus() === Status.requested) {
        return false;
      }
    }

    const self = this;

    return Boolean(
      !this.isClosed() &&
        this.getStatus() !== Status.requested &&
        (function () {
          if (self.site) {
            let cancelDaysInAdvance;
            switch (self.site?.cancellationPolicy as string) {
              case CancellationPolicyOption.exclusive:
              case 'exclusive': {
                return false;
              }
              case CancellationPolicyOption.strict:
              case 'strict': {
                cancelDaysInAdvance = 7;
                break;
              }
              case CancellationPolicyOption.moderate:
              case 'moderate': {
                cancelDaysInAdvance = 3;
                break;
              }
              case CancellationPolicyOption.easy:
              case 'easy':
              default: {
                cancelDaysInAdvance = 1;
                break;
              }
            }

            if (self.date && self.date?.from) {
              let latestCancellableDate = DateTime.fromISO(self.date?.from || '');
              latestCancellableDate = latestCancellableDate.minus({ day: cancelDaysInAdvance });

              const currentDate = DateTime.now();
              return currentDate < latestCancellableDate;
            }
          }

          return false;
        })(),
    );
  }

  getStatus(role: 'guest' | 'host' = 'guest'): string {
    if (role === 'guest' && this.hasGuestRated) {
      return 'rated';
    }

    if (role === 'host' && this.hasHostRated) {
      return 'rated';
    }

    if (this.status) {
      return this.status;
    }

    // if status isn't set then fallback to checking the activity log
    if (this.activity && this.activity.length > 0) {
      return this.activity[this.activity.length - 1].status;
    }
    return '';
  }

  getStatusReason(): string {
    if (this.activity && this.activity.length > 0) {
      return this.activity[this.activity.length - 1].reason || '';
    }

    return '';
  }

  getDatesString(): string {
    if (!this.date) {
      return 'No dates set';
    }

    return this.date.toDisplayString();
  }
}
