import { Injectable } from '@angular/core';
import { ILog } from '@curbnturf/entities';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as LogActions from './log.actions';
import * as LogSelectors from './log.selectors';

@Injectable({
  providedIn: 'root',
})
export class LogFacade {
  session$: Observable<{
    sessionId: string;
    created: number | undefined;
  }>;
  logs$: Observable<ILog[]>;

  constructor(private store: Store) {
    this.session$ = this.store.pipe(select(LogSelectors.getLogSession));
    this.logs$ = this.store.pipe(select(LogSelectors.getLogMessages));
  }
  start() {
    this.store.dispatch(LogActions.startLogSession());
  }

  log(log: ILog) {
    this.store.dispatch(LogActions.logMessage({ log }));
  }
}
