import { Action } from '@ngrx/store';

export enum StorageActionTypes {
  Hydrated = '[Storage] Hydrated',
  Clear = '[Storage] Clear',
  Cleared = '[Storage] Cleared',
}

export class Hydrate implements Action {
  readonly type = StorageActionTypes.Hydrated;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(public payload: any) {}
}

export class Clear implements Action {
  readonly type = StorageActionTypes.Clear;
}

export class Cleared implements Action {
  readonly type = StorageActionTypes.Cleared;
}

export type StorageAction = Hydrate | Clear | Cleared;

export const fromReservationActions = {
  Hydrate,
  Clear,
  Cleared,
};
