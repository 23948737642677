import { inject } from '@angular/core';
import * as AlertActions from '@curbnturf/alert/src/lib/+state/alert.actions';
import { ContactService } from '@curbnturf/contact/src/lib/contact.service';
import { ICalendarDay, ISchedule } from '@curbnturf/entities';
import { stringFromError } from '@curbnturf/form-helpers';
import * as UserSelectors from '@curbnturf/user/src/lib/+state/user.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { ScheduleService } from '../schedule.service';
import {
  CaretakerRequest,
  CreateSchedule,
  CreateScheduleFailed,
  DonateSchedule,
  LoadSchedule,
  LoadScheduleFailed,
  LoadSchedules,
  RemoveSchedule,
  RequestDays,
  ScheduleActionTypes,
  ScheduleDonateFailed,
  UpdateSchedule,
  UpdateScheduleFailed,
} from './schedule.actions';

export const createSchedule$ = createEffect(
  (actions$ = inject(Actions), scheduleService = inject(ScheduleService)) =>
    actions$.pipe(
      ofType(ScheduleActionTypes.CreateSchedule),
      switchMap((action: CreateSchedule) =>
        scheduleService.create(action.payload).pipe(
          mergeMap((createdSchedule: ISchedule) => [
            {
              type: ScheduleActionTypes.ScheduleCreated,
              payload: createdSchedule,
            },
          ]),
          catchError((err) => of({ type: ScheduleActionTypes.CreateScheduleFailed, payload: err })),
        ),
      ),
    ),
  { functional: true },
);

export const removeSchedule$ = createEffect(
  (actions$ = inject(Actions), scheduleService = inject(ScheduleService)) =>
    actions$.pipe(
      ofType(ScheduleActionTypes.RemoveSchedule),
      switchMap((action: RemoveSchedule) =>
        scheduleService.remove(action.payload).pipe(
          mergeMap((removedSchedule: ISchedule) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const actionArray: any[] = [
              {
                type: ScheduleActionTypes.ScheduleRemoved,
                payload: removedSchedule,
              },
            ];

            if (removedSchedule) {
              actionArray.push(
                AlertActions.displayAlert({
                  title: 'Schedule removed',
                  body: `Removed Schedule`,
                  level: 'error',
                }),
              );
            }

            return actionArray;
          }),
        ),
      ),
      catchError((err) => of({ type: ScheduleActionTypes.CreateScheduleFailed, payload: err })),
    ),
  { functional: true },
);

export const loadSchedule$ = createEffect(
  (actions$ = inject(Actions), scheduleService = inject(ScheduleService)) =>
    actions$.pipe(
      ofType(ScheduleActionTypes.LoadSchedule),
      switchMap((action: LoadSchedule) =>
        scheduleService.fetch(action.payload).pipe(
          switchMap((schedule: ISchedule) => [
            {
              type: ScheduleActionTypes.ScheduleLoaded,
              payload: schedule,
            },
            {
              type: ScheduleActionTypes.ScheduleSelected,
              payload: schedule,
            },
          ]),
          catchError((err) => of({ type: ScheduleActionTypes.LoadScheduleFailed, payload: err })),
        ),
      ),
    ),
  { functional: true },
);

export const loadSchedules$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), scheduleService = inject(ScheduleService)) =>
    actions$.pipe(
      ofType(ScheduleActionTypes.LoadSchedules),
      switchMap((action: LoadSchedules) => {
        if (!action.payload) {
          return store.pipe(select(UserSelectors.getCurrentUser)).pipe(
            filter((user) => !!(user && user.id)),
            take(1),
            switchMap((user) => {
              if (!user || !user.email) {
                return of({
                  type: ScheduleActionTypes.LoadScheduleFailed,
                  payload: 'Missing schedule information',
                });
              }
              return scheduleService.fetchForUser(user.email).pipe(
                switchMap((schedules: ISchedule[]) => [
                  {
                    type: ScheduleActionTypes.SchedulesLoaded,
                    payload: schedules,
                  },
                ]),
                catchError((err) =>
                  of({
                    type: ScheduleActionTypes.LoadScheduleFailed,
                    payload: err,
                  }),
                ),
              );
            }),
          );
        } else if (action.payload.siteId) {
          return scheduleService.fetchForSite(action.payload.siteId).pipe(
            switchMap((schedules: ISchedule[]) => [
              {
                type: ScheduleActionTypes.SchedulesLoaded,
                payload: schedules,
              },
            ]),
            catchError((err) =>
              of({
                type: ScheduleActionTypes.LoadScheduleFailed,
                payload: err,
              }),
            ),
          );
        } else if (action.payload.email) {
          return scheduleService.fetchForUser(action.payload.email).pipe(
            switchMap((schedules: ISchedule[]) => [
              {
                type: ScheduleActionTypes.SchedulesLoaded,
                payload: schedules,
              },
            ]),
            catchError((err) =>
              of({
                type: ScheduleActionTypes.LoadScheduleFailed,
                payload: err,
              }),
            ),
          );
        } else {
          return of({
            type: ScheduleActionTypes.LoadScheduleFailed,
            payload: 'Missing schedule information',
          });
        }
      }),
    ),
  { functional: true },
);

export const updateSchedule$ = createEffect(
  (actions$ = inject(Actions), scheduleService = inject(ScheduleService)) =>
    actions$.pipe(
      ofType(ScheduleActionTypes.UpdateSchedule),
      mergeMap((action: UpdateSchedule) =>
        scheduleService.update(action.payload).pipe(
          mergeMap((updatedSchedule: ISchedule) => [
            AlertActions.displayAlert({
              title: 'Schedule Updated',
              body: `Updated ${action.payload.confirmationCode}`,
            }),
            {
              type: ScheduleActionTypes.ScheduleLoaded,
              payload: updatedSchedule,
            },
          ]),
          catchError((err) => of({ type: ScheduleActionTypes.UpdateScheduleFailed, payload: err })),
        ),
      ),
    ),
  { functional: true },
);

export const donateSchedule$ = createEffect(
  (actions$ = inject(Actions), scheduleService = inject(ScheduleService)) =>
    actions$.pipe(
      ofType(ScheduleActionTypes.DonateSchedule),
      mergeMap((action: DonateSchedule) =>
        scheduleService.donate(action.payload.confirmationCode, action.payload.price).pipe(
          mergeMap((updatedSchedule: ISchedule) => [
            AlertActions.displayAlert({
              title: 'Donation Made',
              body: `Made a donation for ${action.payload.confirmationCode}`,
            }),
            {
              type: ScheduleActionTypes.ScheduleDonated,
              payload: updatedSchedule,
            },
          ]),
          catchError((err) =>
            of({
              type: ScheduleActionTypes.ScheduleDonateFailed,
              payload: err,
            }),
          ),
        ),
      ),
    ),
  { functional: true },
);

export const caretakerRequest$ = createEffect(
  (actions$ = inject(Actions), contactService = inject(ContactService)) =>
    actions$.pipe(
      ofType(ScheduleActionTypes.CaretakerRequest),
      mergeMap((action: CaretakerRequest) =>
        contactService.sendCaretakerRequest(action.payload).pipe(
          mergeMap(() => [
            AlertActions.displayAlert({
              title: 'Caretaker Request Made',
              body: `We have received your request to become a caretaker.`,
            }),
          ]),
          catchError(() =>
            of(
              AlertActions.displayAlert({
                title: 'Caretaker Request Failed',
                body: `An error occurred while trying to request making you a caretaker. Please try again.`,
              }),
            ),
          ),
        ),
      ),
    ),
  { functional: true },
);

export const requestDays$ = createEffect(
  (actions$ = inject(Actions), scheduleService = inject(ScheduleService)) =>
    actions$.pipe(
      ofType(ScheduleActionTypes.RequestDays),
      switchMap((action: RequestDays) =>
        scheduleService.requestDays(action.payload).pipe(
          map((days: ICalendarDay[]) => ({
            type: ScheduleActionTypes.DaysFilled,
            payload: days,
          })),
          catchError((err) =>
            of({
              type: ScheduleActionTypes.LoadScheduleFailed,
              payload: err,
            }),
          ),
        ),
      ),
    ),
  { functional: true },
);

export const createScheduleFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ScheduleActionTypes.CreateScheduleFailed),
      map((action: CreateScheduleFailed) =>
        AlertActions.displayAlert({
          title: 'Error Creating Schedule',
          body: stringFromError(action.payload),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const loadScheduleFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ScheduleActionTypes.LoadScheduleFailed),
      map((action: LoadScheduleFailed) =>
        AlertActions.displayAlert({
          title: 'Error Loading Schedules',
          body: stringFromError(action.payload),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const updateScheduleFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ScheduleActionTypes.UpdateScheduleFailed),
      map((action: UpdateScheduleFailed) =>
        AlertActions.displayAlert({
          title: 'Error Updating Schedule',
          body: stringFromError(action.payload),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const scheduleDonateFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ScheduleActionTypes.ScheduleDonateFailed),
      map((action: ScheduleDonateFailed) =>
        AlertActions.displayAlert({
          title: 'Error Making a Donation',
          body: stringFromError(action.payload),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);
