import { MessageChannel } from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MessageState } from './message.reducer';

// Lookup the 'Message' feature state managed by NgRx
const getMessageState = createFeatureSelector<MessageState>('message');

const getLoaded = createSelector(getMessageState, (state: MessageState) => state.loaded);
const getError = createSelector(getMessageState, (state: MessageState) => state.error);

const getAllMessageChannelEntities = createSelector(getMessageState, getLoaded, (state: MessageState, isLoaded) => {
  if (isLoaded) {
    const instantiatedMessages: { [id: number]: MessageChannel } = {};
    for (const key in state.list) {
      if (state.list.hasOwnProperty(key)) {
        instantiatedMessages[key] = new MessageChannel(state.list[key]);
      }
    }

    return instantiatedMessages;
  }

  return {};
});

const getAllMessageChannels = createSelector(getMessageState, getLoaded, (state: MessageState, isLoaded) => {
  return isLoaded
    ? Object.keys(state.list).map<MessageChannel>((id) => new MessageChannel(state.list[parseInt(id, 10)]))
    : [];
});

const getSelectedId = createSelector(getMessageState, (state: MessageState) => state.selectedId);
const getSelectedMessageChannel = createSelector(getAllMessageChannelEntities, getSelectedId, (messages, id) => {
  return id ? new MessageChannel(messages[id]) : undefined;
});

const getPushToken = createSelector(getMessageState, (state) => state.pushToken);

const getChatModalActive = createSelector(getMessageState, (state) => state.chatModalActive);

export const messageQuery = {
  getLoaded,
  getError,
  getAllMessageChannelEntities,
  getAllMessageChannels,
  getSelectedMessageChannel,
  getPushToken,
  getChatModalActive,
};
