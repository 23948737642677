import { Component } from '@angular/core';
import { NewPasswordComponent } from '@curbnturf/auth-components/src/lib/new-password/new-password.component';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'curbnturf-new-password-modal',
  templateUrl: './new-password-modal.component.html',
  styleUrls: ['./new-password-modal.component.scss'],
  standalone: true,
  imports: [IonicModule, NewPasswordComponent],
})
export class NewPasswordModalComponent {}
