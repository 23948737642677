import { FormGroup } from '@angular/forms';
import { IActivityLocationForm } from '../forms/interfaces';

export const requiredPoiCategoryValidator = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (group: FormGroup<IActivityLocationForm>): { [key: string]: any } | null => {
    const categoryDumpControl = group.controls.categoryDump;
    const categoryRestAreaControl = group.controls.categoryRestArea;
    const categoryWifiControl = group.controls.categoryWifi;
    const categoryPotableWaterControl = group.controls.categoryPotableWater;
    const categoryPropaneControl = group.controls.categoryPropane;
    const activityBikingControl = group.controls.activityBiking;
    const activityBoatingControl = group.controls.activityBoating;
    const activityFishingControl = group.controls.activityFishing;
    const activityGolfControl = group.controls.activityGolf;
    const activityHikingControl = group.controls.activityHiking;
    const activityHorsebackControl = group.controls.activityHorseback;
    const activityHuntingControl = group.controls.activityHunting;
    const activityKayakingControl = group.controls.activityKayaking;
    const activityMotorSportsControl = group.controls.activityMotorSports;
    const activityOhvControl = group.controls.activityOhv;
    const activityRaftingControl = group.controls.activityRafting;
    const activityRockClimbingControl = group.controls.activityRockClimbing;
    const activitySnowSportsControl = group.controls.activitySnowSports;
    const activitySurfingControl = group.controls.activitySurfing;
    const activitySwimmingControl = group.controls.activitySwimming;
    const activityWaterSportsControl = group.controls.activityWaterSports;
    const activityWildlifeWatchingControl = group.controls.activityWildlifeWatching;
    const activityWindSportsControl = group.controls.activityWindSports;
    const customActivityControl = group.controls.customActivity;

    if (
      !categoryDumpControl?.value &&
      !categoryRestAreaControl?.value &&
      !categoryWifiControl?.value &&
      !categoryPotableWaterControl?.value &&
      !categoryPropaneControl?.value &&
      !activityBikingControl?.value &&
      !activityBoatingControl?.value &&
      !activityFishingControl?.value &&
      !activityGolfControl?.value &&
      !activityHikingControl?.value &&
      !activityHorsebackControl?.value &&
      !activityHuntingControl?.value &&
      !activityKayakingControl?.value &&
      !activityMotorSportsControl?.value &&
      !activityOhvControl?.value &&
      !activityRaftingControl?.value &&
      !activityRockClimbingControl?.value &&
      !activitySnowSportsControl?.value &&
      !activitySurfingControl?.value &&
      !activitySwimmingControl?.value &&
      !activityWaterSportsControl?.value &&
      !activityWildlifeWatchingControl?.value &&
      !activityWindSportsControl?.value &&
      !customActivityControl?.value
    ) {
      return {
        requiredPoiCategory: true,
      };
    }

    return null;
  };
};
