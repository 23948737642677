import { Role, User } from '@curbnturf/entities';
import { createAction, props } from '@ngrx/store';

export enum AuthActionTypes {
  RecoverPassword = '[Auth] Recover Password',
  RecoverPasswordSuccess = '[Auth] Recover Password Success',
  RecoverPasswordError = '[Auth] Recover Password Error',
  ResetPassword = '[Auth] Reset Password',
  ResetPasswordSuccess = '[Auth] Reset Password Success',
  ResetPasswordError = '[Auth] Reset Password Error',
  NewPassword = '[Auth] New Password',
  NewPasswordSuccess = '[Auth] New Password Success',
  NewPasswordError = '[Auth] New Password Error',
  Login = '[Auth] Login',
  LoginSuccess = '[Auth] Login Success',
  NoRedirect = '[Auth] No Redirect',
  LoginError = '[Auth] Login Error',
  Logout = '[Auth] Logout',
  LogoutSuccess = '[Auth] Logout Success',
  LogoutError = '[Auth] Logout Error',
  UpdatePassword = '[Auth] Update Password',
  ResendVerification = '[Auth] Resend Verification',
  ResendSuccess = '[Auth] Resend Verification Success',
  ResendError = '[Auth] Resend Verification Error',
  SetTempRole = '[Auth] Set Temp Role',
  ClearTempRole = '[Auth] Clear Temp Role',
  NotLoggedIn = '[Auth] Not Logged In',
}

export const recoverPassword = createAction(AuthActionTypes.RecoverPassword, props<{ email: string }>());

export const recoverPasswordSuccess = createAction(AuthActionTypes.RecoverPasswordSuccess, props<{ email: string }>());

export const recoverPasswordError = createAction(AuthActionTypes.RecoverPasswordError, props<{ error: any }>());

export const resetPassword = createAction(
  AuthActionTypes.ResetPassword,
  props<{ password: string; verificationCode: string; email: string }>(),
);

export const resetPasswordSuccess = createAction(AuthActionTypes.ResetPasswordSuccess, props<{ payload: string }>());

export const resetPasswordError = createAction(AuthActionTypes.ResetPasswordError, props<{ error: string }>());

export const newPassword = createAction(AuthActionTypes.NewPassword, props<{ password: string; autoLogin?: boolean }>());

export const newPasswordSuccess = createAction(AuthActionTypes.NewPasswordSuccess, props<{ autoLogin?: boolean; newPassword?: string; }>());

export const newPasswordError = createAction(AuthActionTypes.NewPasswordError, props<{ error: string }>());

export const login = createAction(AuthActionTypes.Login, props<{ email: string; password: string }>());

export const loginSuccess = createAction(AuthActionTypes.LoginSuccess, props<{ user: User }>());

export const noRedirect = createAction(AuthActionTypes.NoRedirect);

export const loginError = createAction(AuthActionTypes.LoginError, props<{ error: any }>());

export const resendVerification = createAction(AuthActionTypes.ResendVerification, props<{ email: string }>());

export const resendSuccess = createAction(AuthActionTypes.ResendSuccess, props<{ email: string }>());

export const resendError = createAction(AuthActionTypes.ResendError, props<{ error: string }>());

export const logout = createAction(AuthActionTypes.Logout, props<{ message?: string }>());

export const logoutSuccess = createAction(AuthActionTypes.LogoutSuccess, props<{ message?: string }>());

export const logoutError = createAction(AuthActionTypes.LogoutError, props<{ error: any }>());

export const updatePassword = createAction(
  AuthActionTypes.UpdatePassword,
  props<{ email: string; oldPassword: string; newPassword: string }>(),
);

export const setTempRole = createAction(AuthActionTypes.SetTempRole, props<{ role: Role }>());

export const clearTempRole = createAction(AuthActionTypes.ClearTempRole);

export const notLoggedIn = createAction(AuthActionTypes.NotLoggedIn);
