import { IAmenity, ISite } from '../interfaces';

export class Amenity implements IAmenity {
  id?: number;
  type?: IAmenity['type'];
  custom?: string;
  description?: string;
  options?: string;
  site?: ISite;

  constructor(data?: IAmenity) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IAmenity): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
