import { ErrorHandler, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthState } from '@curbnturf/auth/src/lib/+state/auth.reducer';
import * as AlertActions from './+state/alert.actions';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private store: Store<AuthState>) {}
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      this.store.dispatch(
        AlertActions.displayAlert({
          title: 'Error loading website',
          body: '<p>There appears to have been an error loading the page. Some of the functionality my be missing.</p><p>Please refresh the page</p>',
          level: 'warning',
          noTimeout: true,
        }),
      );
    }
  }
}
