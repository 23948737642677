import { Component } from '@angular/core';
import { RecoverComponent } from '@curbnturf/auth-components/src/lib/recover/recover.component';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'curbnturf-recover-modal',
  templateUrl: './recover-modal.component.html',
  styleUrls: ['./recover-modal.component.scss'],
  standalone: true,
  imports: [IonicModule, RecoverComponent],
})
export class RecoverModalComponent {}
