import { toPrecision } from '@curbnturf/helpers';
import { ListingType, MapPointTypes } from '../enums';
import { IAddress, IAmenity, ICoordinateGis, IMapPoint, IPhoto, ISite } from '../interfaces';
import { Site } from './site';

export class MapPoint implements IMapPoint {
  id: number;
  type: MapPointTypes;
  name: string;
  description?: string;
  location?: string;
  address?: IAddress;
  photos: IPhoto[] = []; // array of URL key strings and captions
  amenities?: IAmenity[];
  price?: number;
  coordinates: ICoordinateGis;
  rating?: number;
  ratingCount?: number;
  distance?: number; // distance is miles from center of search area

  public static fromSite(site: ISite | Site): MapPoint {
    const mapPoint = new this();

    if (site.type !== ListingType.boondock && site.coordinates) {
      mapPoint.coordinates = {
        lat: toPrecision(site.coordinates.lat, 2),
        lon: toPrecision(site.coordinates.lon, 2),
      };
    }

    if (site.id && site.type && site.name) {
      mapPoint.id = site.id;
      mapPoint.type = site.type as MapPointTypes;
      mapPoint.name = site.name;
    }

    if (site.description) {
      mapPoint.description = site.description;
    }

    if (site.photos) {
      mapPoint.photos = site.photos;
    } else {
      mapPoint.photos = [];
    }

    if (site.coordinates) {
      mapPoint.coordinates = site.coordinates;
    }

    if (site.address && site.address.city && site.address.state) {
      mapPoint.location = `${site.address.city}, ${site.address.state}`;
    } else if (site.address && site.address.city) {
      mapPoint.location = `${site.address.city}`;
    } else if (site.address && site.address.state) {
      mapPoint.location = `${site.address.state}`;
    } else {
      mapPoint.location = '';
    }

    if (site instanceof Site) {
      mapPoint.price = site.getPriceWithTax();
      mapPoint.rating = site.getRating();
      mapPoint.ratingCount = site.getRatingCount();
    }

    return mapPoint;
  }

  constructor(data?: IMapPoint) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IMapPoint): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (!this.photos) {
      this.photos = [];
    }
  }

  getName(): string {
    if (this.id === 1) {
      return 'Curb<span class="trinidad">N</span>Turf HQ';
    }

    return this.name || 'Unidentified Site';
  }
}
