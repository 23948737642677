import { Injectable } from '@angular/core';
import { BASE_API_URL, ICaretaker, IPhoto, ISite } from '@curbnturf/entities';
import { CachedHttpClient } from '@curbnturf/network';
import { Observable } from 'rxjs';

const API_URL = BASE_API_URL + 'caretaker';

@Injectable({
  providedIn: 'root',
})
export class CaretakerService {
  constructor(private cachedHttp: CachedHttpClient) {}

  saveSiteUpdates(caretakerRequest: ICaretaker): Observable<ISite> {
    return this.cachedHttp.post<ISite>(`${API_URL}/site/${caretakerRequest.siteId}`, caretakerRequest, undefined, {
      offlineCallback: () => caretakerRequest,
    });
  }

  uploadImage(siteId: number, formData: FormData): Observable<{ id: number; photos: IPhoto[] }> {
    return this.cachedHttp.post<{ id: number; photos: IPhoto[] }>(`${API_URL}/site/${siteId}/upload-image`, formData);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removePhoto(siteId: number, key: string) {
    // @todo implement this on the server possibly.
  }

  uploadPoiImage(poiId: number, formData: FormData): Observable<{ id: number; photos: IPhoto[] }> {
    return this.cachedHttp.post<{ id: number; photos: IPhoto[] }>(`${API_URL}/poi/${poiId}/upload-image`, formData);
  }
}
