import { NGRX_BUCKETS } from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TripPlannerState } from './trip-planner.reducer';

// Lookup the 'TripPlanner' feature state managed by NgRx
export const getTripPlannerState = createFeatureSelector<TripPlannerState>(NGRX_BUCKETS.tripPlanner);

export const getTrip = createSelector(getTripPlannerState, (state: TripPlannerState) => state.trip);

export const getRoute = createSelector(getTripPlannerState, (state: TripPlannerState) => state.route);

export const getBuffer = createSelector(getTripPlannerState, (state: TripPlannerState) => state.buffer);

export const getDistance = createSelector(getTripPlannerState, (state: TripPlannerState) => state.distance);

export const getCorridor = createSelector(getTripPlannerState, (state: TripPlannerState) => state.corridor);

export const getTripPlannerPanel = createSelector(
  getTripPlannerState,
  (state: TripPlannerState) => state.tripPlannerOpen,
);

export const getSavedTrips = createSelector(getTripPlannerState, (state: TripPlannerState) => state.saved);
