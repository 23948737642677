import { DateTime } from 'luxon';

export const removeEmptyProperties = (baseObject: any): any => {
  if (baseObject == null || baseObject === '' || (typeof baseObject === 'number' && isNaN(baseObject))) {
    return;
  }

  if (Array.isArray(baseObject)) {
    return baseObject.map((item) => removeEmptyProperties(item)).filter((processedItem) => processedItem);
  }

  if (typeof baseObject === 'object') {
    return Object.keys(baseObject)
      .map((key) => ({ key, value: removeEmptyProperties(baseObject[key]) }))
      .filter((processedItem) => processedItem.value)
      .reduce(
        (cleanObject, kvPair) => ({
          ...cleanObject,
          [kvPair.key]: kvPair.value,
        }),
        {},
      );
  }

  return baseObject;
};

export const objectToUrlParams = (obj: any, includeTime: boolean = false): string => {
  if (!obj) {
    return '';
  }

  return Object.keys(obj)
    .filter((key) => obj[key] || obj[key] === false)
    .map(function (key) {
      let value = obj[key];
      if (typeof value === 'object' && value instanceof DateTime) {
        if (includeTime) {
          value = value.toISO();
        } else {
          value = value.toISODate();
        }
      }
      return `${key}=${value}`;
    })
    .join('&');
};

export function mergeArray(arrayA: unknown[], arrayB: unknown[]): unknown[] {
  const mergedArray = [...arrayA];
  arrayB.forEach((row) => {
    if (!mergedArray.includes(row)) {
      mergedArray.push(row);
    }
  });

  return mergedArray;
}

export function mergeDeep(objectA: any, objectB: any): any {
  if (Array.isArray(objectA) && Array.isArray(objectB)) {
    return mergeArray(objectA, objectB);
  } else if (objectA instanceof Object && objectB instanceof Object) {
    const mergedObject = { ...objectA };
    for (const key in objectB) {
      if (objectB[key]) {
        if (Array.isArray(objectB[key])) {
          if (!mergedObject[key]) {
            mergedObject[key] = [];
          }
          mergedObject[key] = mergeDeep(mergedObject[key], objectB[key]);
        } else if (objectB[key] instanceof Object) {
          if (!mergedObject[key]) {
            mergedObject[key] = {};
          }
          mergedObject[key] = mergeDeep(mergedObject[key], objectB[key]);
        } else {
          Object.assign(mergedObject, { [key]: objectB[key] });
        }
      }
    }

    return mergedObject;
  }

  // if not an array or object we currently don't merge and just leave objectA
  return objectA;
}
