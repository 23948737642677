import { Injectable } from '@angular/core';
import { IRV, ITax, IUser, IUserSearchRequest, Role, RV, Tax, User } from '@curbnturf/entities';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import * as UserActions from './user.actions';
import { UserState } from './user.reducer';
import * as UserSelectors from './user.selectors';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {
  allUserEntities$: Observable<{[id: number]: User}>;
  allUsers$: Observable<User[]>;

  currentUser$: Observable<User>;
  currentUserOnce$: Observable<User>;

  selectedRv$: Observable<RV | undefined>;
  selectedRvOnce$: Observable<RV | undefined>;

  currentUserRvs$: Observable<RV[]>;
  currentUserTaxes$: Observable<Tax[]>;
  selectedUser$: Observable<User | null>;

  constructor(private store: Store<UserState>) {
    this.allUserEntities$ = this.store.pipe(select(UserSelectors.getAllUserEntities));
    this.allUsers$ = this.store.pipe(select(UserSelectors.getAllUsers));

    this.currentUser$ = this.store.pipe(select(UserSelectors.getCurrentUser));
    this.currentUserOnce$ = this.currentUser$.pipe(
      skipWhile((user) => !user || !user.id),
      take(1),
    );

    this.selectedRv$ = this.store.pipe(select(UserSelectors.getCurrentUserSelectedRV));
    this.selectedRvOnce$ = this.selectedRv$.pipe(
      skipWhile((rv: RV) => !rv || !rv.id),
      take(1),
    );

    this.currentUserRvs$ = this.store.pipe(select(UserSelectors.getAllCurrentUserRVs));
    this.currentUserTaxes$ = this.store.pipe(select(UserSelectors.getAllCurrentUserTaxes));
    this.selectedUser$ = this.store.pipe(select(UserSelectors.getSelectedUser));

  }

  create(user: {
    email: string;
    firstName?: string;
    lastName?: string;
    authAccessToken?: string;
    authIdToken?: string;
    authRenewToken?: string;
    authExpires?: number;
    loginAfter?: boolean;
    role?: Role;
    source?: string;
  }) {
    this.store.dispatch(UserActions.createUser({ payload: user }));
  }

  load(query: { id?: number; refId?: string; force?: boolean }) {
    this.store.dispatch(UserActions.loadUser({ ...query }));
  }

  loadAll(query: IUserSearchRequest) {
    this.store.dispatch(UserActions.loadUsers({ request: query }));
  }

  select(userId: number) {
    this.store.dispatch(UserActions.selectUser({ userId }));
  }

  remove(userId: number) {
    this.store.dispatch(UserActions.removeUser({ userId }));
  }

  restore(userId: number) {
    this.store.dispatch(UserActions.restoreUser({ userId }));
  }

  loadCurrentUser() {
    this.store.dispatch(UserActions.loadCurrentUser());
  }

  currentUserLoaded(user: IUser) {
    this.store.dispatch(UserActions.currentUserLoaded({ user }));
  }

  loadFavorites(user: IUser) {
    this.store.dispatch(UserActions.loadUserFavorites({ user }));
  }

  addRV(rvId?: number) {
    this.store.dispatch(UserActions.addRV({ rvId }));
  }

  removeRV(rv: IRV, userId?: number) {
    this.store.dispatch(UserActions.removeRV({ userId, rv }));
  }

  selectRV(rv: IRV | undefined) {
    this.store.dispatch(UserActions.selectRV({ rv }));
  }

  updateRV(rv: IRV, userId?: number) {
    this.store.dispatch(UserActions.updateRV({ userId, rv }));
  }

  update(user: IUser) {
    this.store.dispatch(UserActions.updateUser({ user }));
  }

  updateTax(tax: ITax) {
    this.store.dispatch(UserActions.updateTax({ tax }));
  }

  registerPushToken(token: string) {
    this.store.dispatch(UserActions.registerPushToken({ token }));
  }

  loadAccountBalanceTransactions(user: IUser) {
    this.store.dispatch(UserActions.loadAccountBalanceTransactions({ user }));
  }

  loadCashTransactions(user: IUser) {
    this.store.dispatch(UserActions.loadCashTransactions({ user }));
  }
}
