export enum TransactionType {
  available = 'available',
  pending = 'pending',
  paid = 'paid',
}

export enum TransactionEvent {
  rating = 'rating',
  reservation = 'reservation',
  promotion = 'promotion',
  withdrawal = 'withdrawal',
  purchase = 'purchase',
  donation = 'donation',
  grow = 'grow',
  refund = 'refund',
}

export enum TransactionStatus {
  pending = 'pending',
  void = 'void',
  valid = 'valid',
}
