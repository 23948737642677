import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BASE_API_URL, ICalendarDay, IReservation } from '@curbnturf/entities';
import { Observable, of } from 'rxjs';

const API_URL = BASE_API_URL + 'reservation';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: string) {}

  requestPrice(reservation: IReservation): Observable<IReservation> {
    return this.http.post<IReservation>(`${API_URL}/price`, reservation);
  }

  requestDays(siteId: number, reservationId?: number): Observable<ICalendarDay[]> {
    if (isPlatformServer(this.platformId)) {
      return of([]);
    } else {
      if (reservationId) {
        return this.http.get<ICalendarDay[]>(`${API_URL}/calendar/${siteId}/${reservationId}`);
      } else {
        return this.http.get<ICalendarDay[]>(`${API_URL}/calendar/${siteId}`);
      }
    }
  }
}
