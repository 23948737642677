import { AuthActionTypes } from '@curbnturf/auth/src/lib/+state/auth.actions';
import { NGRX_BUCKETS, NO_ACTION } from '@curbnturf/entities';
import { AuthModalActionTypes } from '@curbnturf/auth-modal/src/lib/+state/auth-modal.actions';
import { AlertActionTypes } from '@curbnturf/alert/src/lib/+state/alert.actions';
import { UserActionTypes } from '@curbnturf/user/src/lib/+state/user.actions';

export const storageOptions = {
  keys: [
    NGRX_BUCKETS.auth,
    NGRX_BUCKETS.checklist,
    NGRX_BUCKETS.listingSearch,
    NGRX_BUCKETS.location,
    NGRX_BUCKETS.order,
    NGRX_BUCKETS.reservation,
    NGRX_BUCKETS.schedule,
    NGRX_BUCKETS.tripPlanner,
    NGRX_BUCKETS.urlControl,
    NGRX_BUCKETS.user,
  ],
  ignoreActions: [
    // Don't sync when these actions occur
    AuthActionTypes.NotLoggedIn,
    AuthActionTypes.Login,
    AuthActionTypes.Logout,
    AuthModalActionTypes.OpenAuthModal,
    AlertActionTypes.CloseAllAlerts,
    AlertActionTypes.DisplayAlert,
    UserActionTypes.LoadCurrentUser,
    UserActionTypes.LoginUser,
    NO_ACTION,
  ],
};