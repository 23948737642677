import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MiscSvgComponent } from '@curbnturf/custom-svgs/src/lib/misc-svg/misc-svg.component';

@Component({
  selector: 'curbnturf-input-plus-minus',
  templateUrl: './input-plus-minus.component.html',
  styleUrls: ['./input-plus-minus.component.scss'],
  standalone: true,
  imports: [MiscSvgComponent],
})
export class InputPlusMinusComponent implements OnInit {
  @Input() value: number = 0;

  @Input() increment: number = 1;
  @Input() max: number;
  @Input() min: number = 0;
  @Output() valueChange = new EventEmitter();

  ngOnInit() {
    this.value = this.value ? this.value : 0;
    this.increment = this.increment ? this.increment : 1;
  }

  increase() {
    if (this.value + this.increment > this.max) return;

    this.valueChange.emit(this.value + this.increment);
  }
  decrease() {
    if (this.value - this.increment < this.min) return;

    this.valueChange.emit(this.value - this.increment);
  }
}
