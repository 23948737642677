import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { PATHS } from '@curbnturf/entities';
import { StorageFacade } from '@curbnturf/storage/src/lib/+state/storage.facade';
import { UrlControlFacade } from '@curbnturf/url-control/src/lib/+state/url-control.facade';
import { Observable } from 'rxjs';
import { first, skipWhile } from 'rxjs/operators';
import { AuthFacade } from './+state/auth.facade';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private storageFacade: StorageFacade,
    private authFacade: AuthFacade,
    private urlControlFacade: UrlControlFacade,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      if (isPlatformServer(this.platformId)) {
        return resolve(true);
      }

      this.storageFacade.hydrated$
        .pipe(
          skipWhile((hydrated: boolean) => !hydrated),
          first(),
        )
        .subscribe(() => {
          this.authFacade.loggedIn$.pipe(first()).subscribe((loggedIn) => {
            if (!loggedIn && this.document.location) {
              this.urlControlFacade.setRedirectUrl(state.url || this.document.location.pathname);
              this.router.navigate([PATHS.login]);
            } else {
              resolve(loggedIn);
            }
          });
        });
    });
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }
}
