import { IAccessRoute, ICoordinateGis, ISite, IWaypoint } from '../interfaces';
import { LatLon } from './coordinates';
import { RoadConditionDescribed } from './road-condition-described';
import { Waypoint } from './waypoint';

export class AccessRoute implements IAccessRoute {
  id?: number;
  site: ISite;
  name?: string;
  description?: string;
  directions?: string;
  startingPoint?: ICoordinateGis;
  maxHeight?: number; // ft
  maxWidth?: number; // ft
  maxLength?: number; // ft
  minClearance?: number; // in
  intermediateWaypoints?: IWaypoint[];
  roadCondition: RoadConditionDescribed;

  constructor(data?: IAccessRoute) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IAccessRoute): void {
    for (const key in data) {
      if (
        Object.prototype.hasOwnProperty.call(data, key) &&
        key !== 'startingPoint' &&
        key !== 'intermediateWaypoints'
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    this.startingPoint = new LatLon(data.startingPoint);

    this.intermediateWaypoints = data.intermediateWaypoints
      ? data.intermediateWaypoints.map((point) => new Waypoint(point))
      : [];
  }
}
