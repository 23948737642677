import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthFacade } from '@curbnturf/auth';
import { BASE_API_URL } from '@curbnturf/entities';
import { Observable } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';

const MESSAGE_API_URL = BASE_API_URL + 'message';

interface ISuccess {
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private http: HttpClient, private authFacade: AuthFacade) {}

  public send(message: { email: string; user?: string; body: string }): Observable<ISuccess> {
    if (!message.user) {
      return this.authFacade.user$.pipe(
        first(),
        switchMap((user) => {
          if (user?.id) {
            message.user = `${user.firstName || 'unknown'} ${user.lastName || 'unknown'} (${user.id})`;
          }

          return this.http.post<ISuccess>(`${MESSAGE_API_URL}/contact-curbnturf`, message);
        }),
      );
    }

    // We always give the same reply regardless of the results from the server.
    return this.http.post<ISuccess>(`${MESSAGE_API_URL}/contact-curbnturf`, message);
  }

  public sendPayoutRequest(): Observable<ISuccess> {
    // We always give the same reply regardless of the results from the server.
    return this.http.post<ISuccess>(`${MESSAGE_API_URL}/request-payout`, {});
  }

  public sendCashPayoutRequest(): Observable<ISuccess> {
    // We always give the same reply regardless of the results from the server.
    return this.http.post<ISuccess>(`${MESSAGE_API_URL}/request-cash-payout`, {});
  }

  public sendCaretakerRequest(siteId: number): Observable<ISuccess> {
    return this.authFacade.user$.pipe(
      switchMap((currentUser) => {
        if (!currentUser || !currentUser.email) {
          throw new Error('Must be logged in to become a site caretaker');
        }

        const message = {
          email: currentUser.email,
          userId: currentUser.id,
          body: `Hello I would like to be made the caretaker for site: ${siteId}`,
        };

        return this.send(message);
      }),
    );
  }
}
