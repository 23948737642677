import { toPrecision } from '@curbnturf/helpers';
import { RatingType } from '../enums';
import { IRating, IScore, IUser } from '../interfaces';

export class Rating implements IRating {
  id?: number;
  syncId?: string;
  score: IScore;
  topic: RatingType;
  creator: IUser;
  creatorId?: number;
  issues?: string[];
  additionalComments?: string;
  created?: number;

  static calculateRating(ratings?: IRating[]): number {
    const filteredRatings = this.overallRatings(ratings);

    if (filteredRatings.length === 0) {
      return 0;
    }

    return toPrecision(
      filteredRatings.reduce((total, rating) => (total += rating.score.score), 0) / filteredRatings.length,
      1,
    );
  }

  static calculateRatingCount(ratings?: IRating[]): number {
    return this.overallRatings(ratings).length;
  }

  static overallRatings(ratings?: IRating[]): IRating[] {
    if (!ratings) {
      return [];
    }

    return ratings.filter((rating) => rating.topic === RatingType.overall);
  }

  constructor(data?: IRating) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IRating): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
