import { DEFAULT_MAP_LATITUDE, DEFAULT_MAP_LONGITUDE, ILatLon, LatLon } from '@curbnturf/entities';
import { Action, createReducer, on } from '@ngrx/store';
import * as LocationActions from './location.actions';

export interface LocationState {
  currentLocation?: ILatLon;
  center?: ILatLon; // selected Location
  distance?: number;
  loaded: boolean; // has the Location list been loaded
}

export const initialState: LocationState = {
  currentLocation: new LatLon({ lat: DEFAULT_MAP_LATITUDE, lon: DEFAULT_MAP_LONGITUDE }),
  loaded: false,
};

export const locationReducer = createReducer(
  initialState,
  on(LocationActions.selectLocation, (state, action) => {
    const center = new LatLon(action.payload.center);
    const distance = action.payload.distance;

    if (!state.center || !center.equals(state.center) || distance !== state.distance) {
      state = {
        ...state,
        center,
        distance,
        loaded: true,
      };
    }
    return state;
  }),
  on(LocationActions.currentLocationSuccess, (state, action) => {
    if (action.location) {
      state = {
        ...state,
        currentLocation: new LatLon({ lat: action.location.lat, lon: action.location.lon }),
      };
    }

    return state;
  }),
  on(LocationActions.reverseGeocodeSuccess, (state, action) => {
    if (action.location) {
      state = {
        ...state,
        currentLocation: new LatLon({ lat: action.location.lat, lon: action.location.lon }),
      };
    }

    return state;
  }),
);

export function reducer(state: LocationState | undefined, action: Action) {
  return locationReducer(state, action);
}
