import { CDate, NGRX_BUCKETS, PriceComponent } from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { buildOrder, OrderState } from './order.reducer';

// Lookup the 'Order' feature state managed by NgRx
const getOrderState = createFeatureSelector<OrderState>(NGRX_BUCKETS.order);

const getError = createSelector(getOrderState, (state: OrderState) => state.error);

const getPriceError = createSelector(getOrderState, (state: OrderState) => state.priceError);

const getOrder = createSelector(getOrderState, (state: OrderState) => {
  if (state.order && !state.order.built) {
    return buildOrder(state.order);
  }
  return {
    ...state.order,
    priceComponents: state.order.priceComponents?.map((price) => new PriceComponent(price)),
    date: new CDate(state.order.date),
  };
});

const getPrices = createSelector(getOrderState, (state: OrderState) => {
  if (state.priceComponents) {
    return state.priceComponents.map((price) => new PriceComponent(price));
  }

  return [];
});

const getDays = createSelector(getOrderState, (state: OrderState) => {
  return state.days;
});

export const orderQuery = {
  getError,
  getPriceError,
  getOrder,
  getPrices,
  getDays,
};
