import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'curbnturf-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class ToggleComponent {
  @Input() selected: boolean;
  @Output() clicked: EventEmitter<void> = new EventEmitter();

  toggled() {
    this.clicked.emit();
  }
}
