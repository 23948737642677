import { Injectable } from '@angular/core';
import { capSQLiteChanges, capSQLiteValues } from '@capacitor-community/sqlite';
import { ISite } from '@curbnturf/entities';
import { firstValueFrom, of } from 'rxjs';
import { Logger } from '../../log/logger';
import { DatabaseService } from '../database.service';
import { IDbSet } from '../dbset.interface';
import { ISitePhotoEntity, SitePhotoEntity } from '../entities/site-photos';

const TABLE_NAME = 'site_photos';

@Injectable({
  providedIn: 'root',
})
export class SitePhotosDbSet implements IDbSet {
  public static readonly siteFields = ['id', 'site_id', 'caption', 'key'];

  constructor(private databaseService: DatabaseService, private logger: Logger) {}

  public store(request: ISite): Promise<capSQLiteValues> {
    const entity = this.mapObjectsToStorable([request]).pop();
    if (entity) {
      return this.databaseService.insert<SitePhotoEntity>(TABLE_NAME, entity);
    } else {
      throw new Error('Unable to create storable object.');
    }
  }

  public storeArray(objects: ISite[], ignoreDuplicates: boolean = false): Promise<capSQLiteValues> {
    const entities = this.mapObjectsToStorable(objects);
    if (entities.length > 0) {
      return this.databaseService.insertMulti<SitePhotoEntity>(
        TABLE_NAME,
        entities,
        SitePhotosDbSet.siteFields,
        ignoreDuplicates,
      );
    } else {
      this.logger.warning('Attempted to insert an empty set.', { objects, entities });
      return Promise.resolve<capSQLiteValues>({ values: [] });
    }
  }

  public async truncateAndStoreArray(records: ISite[]): Promise<capSQLiteValues> {
    const entities = this.mapObjectsToStorable(records);
    await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
    const { statement, values } = this.databaseService.generateInsertStatement<SitePhotoEntity>(
      TABLE_NAME,
      entities,
      SitePhotosDbSet.siteFields,
    );
    this.logger.debug('Executing INSERT STATEMENT', statement);
    return this.databaseService.query(statement, values);
  }

  public async truncate(): Promise<capSQLiteChanges> {
    return await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
  }

  public async retrieve(id: number): Promise<SitePhotoEntity> {
    const results = await this.databaseService.select(TABLE_NAME, { id });
    const objResults = this.mapResultsObject(results);
    if (objResults && objResults?.length > 0) {
      return objResults[0];
    } else {
      return new SitePhotoEntity();
    }
  }

  public async retrieveOwnSites(userId?: number): Promise<SitePhotoEntity[]> {
    if (userId) {
      const results = await this.databaseService.select(TABLE_NAME, { user_id: userId });
      return this.mapResultsObject(results);
    } else {
      return firstValueFrom(of([]));
    }
  }

  public async retrieveBatch(siteIds: number[]): Promise<SitePhotoEntity[]> {
    if (siteIds.length > 0) {
      let statement = `SELECT * FROM ${TABLE_NAME} WHERE site_id IN(`;
      const values: string[] = [];
      siteIds.forEach((el) => {
        statement += '?, ';
        values.push(el.toString());
      });
      statement = statement.substr(0, statement.length - 2) + `);`;
      const results = await this.databaseService.query(statement, values);
      return this.mapResultsObject(results);
    } else {
      return [];
    }
  }

  public async retrieveAll(limit?: number): Promise<SitePhotoEntity[]> {
    const results = await this.databaseService.selectAll(TABLE_NAME, undefined, limit);
    return this.mapResultsObject(results);
  }

  public async remove(id: string): Promise<capSQLiteValues> {
    return await this.databaseService.delete(TABLE_NAME, { id });
  }

  private mapResultsObject(results: capSQLiteValues): SitePhotoEntity[] {
    if (results.values) {
      return results.values?.map(
        (record: ISitePhotoEntity) =>
          new SitePhotoEntity({
            id: record.id,
            site_id: record.site_id,
            caption: record.caption,
            key: record.key,
          }),
      );
    } else {
      return [];
    }
  }

  private mapObjectsToStorable(items: ISite[]): SitePhotoEntity[] {
    if (items && Array.isArray(items) && items.length > 0) {
      return (
        items
          .filter((item) => item.id !== undefined && item.photos && item.photos?.length > 0)
          .map((site: ISite) => {
            return site.photos?.map(
              (photo) =>
                new SitePhotoEntity({
                  site_id: site.id as number,
                  caption: photo.caption,
                  key: photo.key,
                }),
            );
          })
          .reduce((acc, value) => acc?.concat(value || [])) || []
      );
    } else {
      return [];
    }
  }
}
