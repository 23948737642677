import { Injectable } from '@angular/core';
import { IBoondockRating, ICaretaker, IGuestRating, IPoiRating, ISiteRating } from '@curbnturf/entities';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  CreateBoondockRatings,
  CreateGuestRatings,
  CreatePoiRatings,
  CreateSiteRatings,
  LoadBoondockRatings,
  LoadGuestRatings,
  LoadPoiRatings,
  LoadSiteRatings,
  SelectCaretakerSite
} from './rating.actions';
import { RatingState } from './rating.reducer';
import { ratingQuery } from './rating.selectors';

@Injectable({
  providedIn: 'root',
})
export class RatingFacade {
  siteRatings$: Observable<ISiteRating[]>;
  guestRatings$: Observable<IGuestRating[]>;
  boondockRatings$: Observable<IBoondockRating[]>;
  poiRatings$: Observable<IPoiRating[]>;
  caretakerSites$: Observable<number[]>;

  constructor(private store: Store<RatingState>) {
    this.siteRatings$ = this.store.pipe(select(ratingQuery.getAllSiteRatings));
    this.guestRatings$ = this.store.pipe(select(ratingQuery.getAllGuestRatings));
    this.boondockRatings$ = this.store.pipe(select(ratingQuery.getAllBoondockRatings));
    this.poiRatings$ = this.store.pipe(select(ratingQuery.getAllPoiRatings));
    this.caretakerSites$ = this.store.pipe(select(ratingQuery.getCaretakerSites));
  }

  loadGuest(id: number) {
    this.store.dispatch(new LoadGuestRatings(id));
  }

  loadSite(id: number) {
    this.store.dispatch(new LoadSiteRatings(id));
  }

  loadBoondock(id: number) {
    this.store.dispatch(new LoadBoondockRatings(id));
  }

  loadPoi(id: number) {
    this.store.dispatch(new LoadPoiRatings(id));
  }

  createSite(ratings: ISiteRating[]) {
    this.store.dispatch(new CreateSiteRatings(ratings));
  }

  createGuest(ratings: IGuestRating[]) {
    this.store.dispatch(new CreateGuestRatings(ratings));
  }

  createBoondock(ratings: IBoondockRating[]) {
    this.store.dispatch(new CreateBoondockRatings(ratings));
  }

  createPoi(ratings: IPoiRating[]) {
    this.store.dispatch(new CreatePoiRatings(ratings));
  }

  selectCaretakerSite(caretakerRequest: ICaretaker) {
    this.store.dispatch(new SelectCaretakerSite(caretakerRequest));
  }
}
