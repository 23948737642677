import { Injectable } from '@angular/core';
import { capSQLiteChanges, capSQLiteValues } from '@capacitor-community/sqlite';
import {
  CDate,
  IReservation,
  PriceComponent,
  PriceType,
  Reservation,
  ReservationExtra,
  Site,
  User,
} from '@curbnturf/entities';
import { DatabaseService } from '../database.service';
import { IDbSet } from '../dbset.interface';
import { PriceComponentEntity, ReservationEntity, ReservationExtraEntity } from '../entities/reservation';

const TABLE_NAME = 'reservations';
const TABLE_NAME_PRICE = 'reservation_price_components';
const TABLE_NAME_EXTRAS = 'reservation_extras';

@Injectable({
  providedIn: 'root',
})
export class ReservationDbSet implements IDbSet {
  public static readonly reservationFields = [
    'id',
    'date_type',
    'date_from',
    'date_to',
    'user_first_name',
    'user_last_name',
    'user_id',
    'site_name',
    'site_id',
    'guest_rated',
    'site_rated',
    'status',
    'rv_id',
  ];

  public static readonly reservationExtraFields = [
    'id',
    'reservation_id',
    'count',
    'label',
    'name',
    'description',
    'price',
  ];

  public static readonly reservationPriceFields = [
    'id',
    'reservation_id',
    'date',
    'type',
    'value',
    'fee',
    'taxes',
    'discounts',
  ];

  constructor(private databaseService: DatabaseService) {}

  public async store(request: IReservation): Promise<capSQLiteValues> {
    const storeObjects = this.mapObjectsToStorable([request]).pop();
    const reservation = storeObjects.reservation;
    const reservationExtras = storeObjects.reservation_extras;
    const priceComponents = storeObjects.price_components;

    // Store the reservation record first.
    const reservationResult = await this.databaseService.insert(TABLE_NAME, reservation);

    // Next store the supporting tables.
    await this.databaseService.insert(TABLE_NAME_EXTRAS, reservationExtras);
    await this.databaseService.insert(TABLE_NAME_PRICE, priceComponents);

    return reservationResult;
  }

  /**
   * Store an Array of reservation records.
   * @param {IReservation[]} records
   * @param {boolean} ignore
   */
  public async storeArray(records: IReservation[], ignore: boolean = false): Promise<capSQLiteValues> {
    const storeObjects = this.mapObjectsToStorable(records);
    const reservations = storeObjects.map((el) => el.reservation);
    const reservationExtras = storeObjects.map((el) => el.reservation_extras).reduce((acc, val) => acc.concat(val), []);
    const priceComponents = storeObjects.map((el) => el.price_components).reduce((acc, val) => acc.concat(val), []);

    const reservationResults = await this.databaseService.insertMulti(
      TABLE_NAME,
      reservations,
      ReservationDbSet.reservationFields,
      ignore,
    );
    await this.databaseService.insertMulti(
      TABLE_NAME_EXTRAS,
      reservationExtras,
      ReservationDbSet.reservationExtraFields,
      true,
    );
    await this.databaseService.insertMulti(
      TABLE_NAME_PRICE,
      priceComponents,
      ReservationDbSet.reservationPriceFields,
      true,
    );

    return reservationResults;
  }

  public async truncateAndStoreArray(records: IReservation[], ignore: boolean = false): Promise<capSQLiteValues> {
    await this.truncate();
    return this.storeArray(records, ignore);
  }

  public async truncate(): Promise<capSQLiteChanges> {
    await this.databaseService.execute(`DELETE FROM ${TABLE_NAME_PRICE};`);
    await this.databaseService.execute(`DELETE FROM ${TABLE_NAME_EXTRAS};`);
    return await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
  }

  public async retrieve(id: number): Promise<IReservation> {
    const reservationResults = await this.databaseService.select(TABLE_NAME, { id });
    const extraResults = await this.databaseService.select(TABLE_NAME_EXTRAS, { reservation_id: id });
    const priceResults = await this.databaseService.select(TABLE_NAME_PRICE, { reservation_id: id });

    const reservation: ReservationEntity | undefined = Array.isArray(reservationResults.values)
      ? reservationResults.values.pop()
      : undefined;
    const extras: ReservationExtraEntity[] | undefined = extraResults.values;
    const prices: PriceComponentEntity[] | undefined = priceResults.values;

    const objResults = this.mapResultsObject([{ reservation, reservation_extras: extras, price_components: prices }]);
    if (objResults && objResults?.length > 0) {
      return objResults[0];
    } else {
      return new Reservation({});
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async retrieveAll(limit?: number): Promise<IReservation[]> {
    const query = `
    SELECT
        ${TABLE_NAME}.*,
        ${TABLE_NAME_EXTRAS}.id as extra_id,
        ${TABLE_NAME_EXTRAS}.reservation_id as extra_reservation_id,
        ${TABLE_NAME_EXTRAS}.count as extra_count,
        ${TABLE_NAME_EXTRAS}.label as extra_label,
        ${TABLE_NAME_EXTRAS}.name as extra_name,
        ${TABLE_NAME_EXTRAS}.description as extra_description,
        ${TABLE_NAME_EXTRAS}.price as extra_price,
        ${TABLE_NAME_PRICE}.id as price_id,
        ${TABLE_NAME_PRICE}.date as price_date,
        ${TABLE_NAME_PRICE}.type as price_type,
        ${TABLE_NAME_PRICE}.value as price_value,
        ${TABLE_NAME_PRICE}.fee as price_fee,
        ${TABLE_NAME_PRICE}.taxes as price_taxes,
        ${TABLE_NAME_PRICE}.discounts as price_discounts
    FROM ${TABLE_NAME}
        LEFT JOIN ${TABLE_NAME_EXTRAS} ON ${TABLE_NAME}.id = ${TABLE_NAME_EXTRAS}.reservation_id
        LEFT JOIN ${TABLE_NAME_PRICE} ON ${TABLE_NAME}.id = ${TABLE_NAME_PRICE}.reservation_id`;

    const results = await this.databaseService.query(query, []);
    return this.mapDatabaseResultsObject(results.values);
  }

  public async remove(id: string): Promise<capSQLiteValues> {
    await this.databaseService.delete(TABLE_NAME_PRICE, { reservation_id: id });
    await this.databaseService.delete(TABLE_NAME_EXTRAS, { reservation_id: id });
    return await this.databaseService.delete(TABLE_NAME, { id });
  }

  private mapResultsObject(
    results?: {
      reservation?: ReservationEntity;
      reservation_extras?: ReservationExtraEntity[];
      price_components?: PriceComponentEntity[];
    }[],
  ): IReservation[] {
    if (Array.isArray(results) && results.length > 0) {
      return results.map((record) => {
        return new Reservation({
          id: record.reservation?.id,
          date: new CDate({
            type: record.reservation?.date_type as 'range' | 'single' | undefined,
            from: record.reservation?.date_from || '',
            to: record.reservation?.date_to,
          }),
          extras: record.reservation_extras?.map((extra) => ({
            id: extra.id,
            reservationId: extra.reservation_id,
            count: extra.count,
            extra: {
              label: extra.label,
              name: extra.name,
              description: extra.description,
              price: extra.price,
            },
          })),
          user: new User({
            id: record.reservation?.user_id,
            firstName: record.reservation?.user_first_name,
            lastName: record.reservation?.user_last_name,
          }),
          userId: record.reservation?.user_id,
          site: new Site({
            id: record.reservation?.site_id,
            name: record.reservation?.site_name,
          }),
          siteId: record.reservation?.site_id,
          priceComponents: record.price_components?.map(
            (price) =>
              new PriceComponent({
                id: price.id,
                date: price.date,
                type: price.type as PriceType,
                value: price.value,
                fee: price.fee,
                taxes: price.taxes ? JSON.parse(price.taxes) : undefined,
                discounts: price.discounts ? JSON.parse(price.discounts) : undefined,
              }),
          ),
          guestRated: !!record.reservation?.guest_rated,
          siteRated: !!record.reservation?.site_rated,
          status: record.reservation?.status,
          rvId: record.reservation?.rv_id,
        });
      });
    } else {
      return [];
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private mapDatabaseResultsObject(results?: any[]): Reservation[] {
    if (Array.isArray(results) && results.length > 0) {
      const reservations: Reservation[] = [];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      results.forEach((record: any) => {
        let reservation: Reservation;
        const foundRecord = reservations.find((el) => el.id === record.id);
        if (foundRecord) {
          if (record.extra_id) {
            if (!Array.isArray(foundRecord.extras)) {
              foundRecord.extras = [];
            }

            const foundExtra = foundRecord.extras.find((el) => el.id === record.extra_id);
            if (!foundExtra) {
              foundRecord.extras.push(
                new ReservationExtra({
                  id: record.extra_id,
                  reservationId: record.extra_reservation_id,
                  count: record.extra_count,
                  extra: {
                    label: record.extra_label,
                    name: record.extra_name,
                    description: record.extra_description,
                    price: record.extra_price,
                  },
                }),
              );
            }
          }
          if (record.price_id) {
            if (!Array.isArray(foundRecord.priceComponents)) {
              foundRecord.priceComponents = [];
            }

            const foundPrice = foundRecord.priceComponents.find((el) => el.id === record.price_id);
            if (!foundPrice) {
              foundRecord.priceComponents.push(
                new PriceComponent({
                  id: record.price_id,
                  date: record.price_date,
                  type: record.price_type as PriceType,
                  value: record.price_value,
                  fee: record.price_fee,
                  taxes: record.price_taxes ? JSON.parse(record.price_taxes) : undefined,
                  discounts: record.price_discounts ? JSON.parse(record.price_discounts) : undefined,
                }),
              );
            }
          }
        } else {
          reservation = new Reservation({
            id: record.id,
            date: new CDate({
              type: record.date_type as 'range' | 'single' | undefined,
              from: record.date_from || '',
              to: record.date_to,
            }),
            extras: [
              new ReservationExtra({
                id: record.extra_id,
                reservationId: record.extra_reservation_id,
                count: record.extra_count,
                extra: {
                  label: record.extra_label,
                  name: record.extra_name,
                  description: record.extra_description,
                  price: record.extra_price,
                },
              }),
            ],
            user: new User({
              id: record.user_id,
              firstName: record.user_first_name,
              lastName: record.user_last_name,
            }),
            userId: record.user_id,
            site: new Site({
              id: record.site_id,
              name: record.site_name,
            }),
            siteId: record.site_id,
            priceComponents: [
              new PriceComponent({
                id: record.price_id,
                date: record.price_date,
                type: record.price_type as PriceType,
                value: record.price_value,
                fee: record.price_fee,
                taxes: record.price_taxes ? JSON.parse(record.price_taxes) : undefined,
                discounts: record.price_discounts ? JSON.parse(record.price_discounts) : undefined,
              }),
            ],
            guestRated: !!record.guest_rated,
            siteRated: !!record.site_rated,
            status: record.status,
            rvId: record.rv_id,
          });

          reservations.push(reservation);
        }
      });

      return reservations;
    } else {
      return [];
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private mapObjectsToStorable(items: IReservation[]): any[] {
    if (items) {
      return items?.map((record: IReservation) => {
        return {
          reservation: {
            // Can't use spread operator here as it tries to enter null in undefined properties as columns
            id: record.id,
            date_type: record.date?.type,
            date_from: record.date?.from,
            date_to: record.date?.to,
            user_first_name: record.user?.firstName,
            user_last_name: record.user?.lastName,
            user_id: record.userId,
            site_name: record.site?.name,
            site_id: record.siteId,
            guest_rated: record.guestRated ? 1 : 0,
            site_rated: record.siteRated ? 1 : 0,
            status: record.status,
            rv_id: record.rvId,
          },
          reservation_extras: record.extras?.map((extra) => ({
            id: extra.id,
            reservation_id: extra.reservationId,
            count: extra.count,
            label: extra.extra?.label,
            name: extra.extra?.name,
            description: extra.extra?.description,
            price: extra.extra?.price,
          })),
          price_components: record.priceComponents?.map((price) => ({
            id: price.id,
            reservation_id: record.id,
            date: price.date,
            type: price.type as string,
            value: price.value,
            fee: price.fee,
            taxes: JSON.stringify(price.taxes),
            discounts: JSON.stringify(price.discounts),
          })),
        };
      });
    } else {
      return [];
    }
  }
}
