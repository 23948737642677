<a
  [href]="facebookUrl"
  class="cnt-button w-inline-block"
  [ngClass]="{ nandor: !ios, apple: ios }"
  (click)="onSocialLogin($event, 'facebook')"
>
  <div class="cnt-button-icon" [ngClass]="{ auth: !ios, 'apple-auth': ios }">
    <div class="html-embed w-embed">
      <curbnturf-site-svgs type="facebook" [white]="!ios" [black]="ios"> </curbnturf-site-svgs>
    </div>
  </div>
  <div class="cnt-button-copy">Continue with Facebook</div>
</a>
<a
  [href]="googleUrl"
  class="cnt-button w-inline-block"
  [ngClass]="{ nandor: !ios, apple: ios }"
  (click)="onSocialLogin($event, 'google')"
>
  <div class="cnt-button-icon" [ngClass]="{ auth: !ios, 'apple-auth': ios }">
    <div class="html-embed w-embed">
      <curbnturf-site-svgs type="google" [white]="!ios" [black]="ios"> </curbnturf-site-svgs>
    </div>
  </div>
  <div class="cnt-button-copy">Continue with Google</div>
</a>
<a
  [href]="appleUrl"
  class="cnt-button w-inline-block"
  [ngClass]="{ nandor: !ios, apple: ios }"
  (click)="onSocialLogin($event, 'apple')"
>
  <div class="cnt-button-icon" [ngClass]="{ auth: !ios, apple: ios }">
    <div class="html-embed w-embed">
      <curbnturf-site-svgs *ngIf="!ios" type="apple" white="true"> </curbnturf-site-svgs>
      <curbnturf-site-svgs *ngIf="ios" type="apple-large-black" white="true"> </curbnturf-site-svgs>
    </div>
  </div>
  <div class="cnt-button-copy">Continue with Apple</div>
</a>
