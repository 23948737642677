import { IStorageSerializer } from '../storage-serializer.interface';
import { TripPlannerState } from '@curbnturf/trip-planner/src/lib/+state/trip-planner.reducer';
import { DateTime } from 'luxon';

export class TripPlannerSerializer implements IStorageSerializer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serialize(state: TripPlannerState): any {
    return {
      ...state,
      trip: state.trip.map((el) => {
        return {
          ...el,
          arrival: el.arrival instanceof DateTime ? el.arrival?.toISO() : '',
          departure: el.departure instanceof DateTime ? el.departure?.toISO() : '',
        };
      }),
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unserialize(state: any): TripPlannerState {
    return {
      ...state,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      trip: state.trip.map((el: any) => {
        return {
          ...el,
          arrival: el.arrival?.length > 0 ? DateTime.fromISO(el.arrival) : undefined,
          departure: el.departure?.length > 0 ? DateTime.fromISO(el.departure) : undefined,
        };
      }),
    };
  }
}
