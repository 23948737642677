/* eslint-disable @typescript-eslint/no-explicit-any */
export function toString(value: any): string {
  return value !== undefined && value !== null ? `${value}` : '';
}

export function getValueInRange(value: number, max: number, min = 0): number {
  return Math.max(Math.min(value, max), min);
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function isDefined(value: any): boolean {
  return value !== undefined && value !== null;
}

export function regExpEscape(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function hasClassName(element: any, className: string): boolean {
  return (
    element && element.className && element.className.split && element.className.split(/\s+/).indexOf(className) >= 0
  );
}

if (typeof Element !== 'undefined' && !Element.prototype.closest) {
  // Polyfill for ie10+

  if (!Element.prototype.matches) {
    // IE uses the non-standard name: msMatchesSelector
    Element.prototype.matches = (Element.prototype as any).msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }

  Element.prototype.closest = function (s: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let el: Element | null = this;
    if (!document || !document.documentElement || !document.documentElement.contains(el)) {
      return null;
    }
    do {
      if (el.matches(s)) {
        return el;
      }
      el = (el.parentElement || el.parentNode) as Element;
    } while (el !== null && el?.nodeType === 1);
    return null;
  };
}

export function closest(element: HTMLElement, selector: any): HTMLElement | null {
  if (!selector) {
    return null;
  }

  return element.closest(selector);
}
