import { NgIf } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormArray } from '@angular/forms';
import { camelToTitle } from '@curbnturf/entities';
import { Subscription } from 'rxjs';
import { FORM_ARRAY_ERRORS, FORM_ERRORS } from '../errors';

@Component({
  selector: 'curbnturf-display-custom-errors',
  templateUrl: '../display-validation-errors/display-validation-errors.component.html',
  styleUrls: ['../display-validation-errors/display-validation-errors.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class DisplayCustomErrorsComponent implements OnChanges, OnDestroy {
  @Input() control: FormControl | FormArray | UntypedFormArray | FormGroup;

  @Input() show: boolean = false;

  hide: boolean = true;
  field: string;
  _text: string;

  private subscriptions: Subscription[] = [];

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(FORM_ERRORS) private errors: { [id: string]: (numberA?: number, numberB?: number) => string },
    @Inject(FORM_ARRAY_ERRORS) private arrayErrors: { [id: string]: (numberA?: number, numberB?: number) => string },
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.control) {
      this.clearSubscriptions();
      this.checkControl();

      this.subscriptions.push(this.control.statusChanges.subscribe(() => this.checkControl()));
    }
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  checkControl() {
    const controlErrors = this.control.errors;
    if (controlErrors) {
      const firstKey = Object.keys(controlErrors)[0];
      let getError = this.errors[firstKey];

      if (this.control instanceof FormArray || this.control instanceof UntypedFormArray) {
        getError = this.arrayErrors[firstKey];
      }

      if (getError) {
        this.hide = false;
        const text = getError(controlErrors[firstKey]);
        this.setError(text, this.getControlName(this.control) || '');
      }
    } else {
      this.hide = true;
    }
  }

  setError(text: string, field: string) {
    if (field) {
      // Custom field names
      // todo: think of a more generalized way to store this
      if (field === 'affiliateRef') {
        this.field = 'Custom CurbNTurf Code';
      } else {
        this.field = camelToTitle(field);
      }
    }

    this._text = text;
  }

  private clearSubscriptions() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions.length = 0;
  }

  private getControlName(formControl: AbstractControl): string | null {
    const formGroup = formControl?.parent?.controls as AbstractControl[];
    return (
      (formGroup &&
        Object.keys(formGroup).find((name) => {
          if (formGroup) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return formControl === formGroup[name as any];
          }

          return false;
        })) ||
      null
    );
  }
}
