import { Injectable } from '@angular/core';
import { capSQLiteChanges, capSQLiteValues } from '@capacitor-community/sqlite';
import { Address, IAddress, IListingType, ISite, Site } from '@curbnturf/entities';
import { DateTime } from 'luxon';
import { firstValueFrom, of } from 'rxjs';
import { Logger } from '../../log/logger';
import { DatabaseService } from '../database.service';
import { IDbSet } from '../dbset.interface';
import { OfflineSiteEntity } from '../entities/offline-site';

const TABLE_NAME = 'offline_sites';

@Injectable({
  providedIn: 'root',
})
export class OfflineSiteDbSet implements IDbSet {
  public static readonly siteFields = ['sync_id', 'id', 'type', 'body_content', 'user_id'];

  constructor(private databaseService: DatabaseService, private logger: Logger) {}

  public store(request: ISite): Promise<capSQLiteValues> {
    const entity = this.mapObjectsToStorable([request]).pop();
    if (entity) {
      return this.databaseService.upsert<OfflineSiteEntity>(
        TABLE_NAME,
        entity,
        ['type', 'body_content', 'user_id'],
        'sync_id',
      );
    } else {
      throw new Error('Unable to create storable object.');
    }
  }

  public storeArray(requests: ISite[], ignoreDuplicates: boolean = false): Promise<capSQLiteValues> {
    const mappedObjects = this.mapObjectsToStorable(requests);
    return this.databaseService.insertMulti<OfflineSiteEntity>(
      TABLE_NAME,
      mappedObjects,
      OfflineSiteDbSet.siteFields,
      ignoreDuplicates,
    );
  }

  public async truncateAndStoreArray(records: ISite[]): Promise<capSQLiteValues> {
    const entities = this.mapObjectsToStorable(records);
    await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
    const { statement, values } = this.databaseService.generateInsertStatement<OfflineSiteEntity>(
      TABLE_NAME,
      entities,
      OfflineSiteDbSet.siteFields,
    );
    this.logger.debug('Executing INSERT STATEMENT', statement);
    return this.databaseService.query(statement, values);
  }

  public async truncate(): Promise<capSQLiteChanges> {
    return await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
  }

  public async retrieve(sync_id: string): Promise<Site> {
    const results = await this.databaseService.select(TABLE_NAME, { sync_id });
    const objResults = this.mapResultsObject(results);
    if (objResults && objResults?.length > 0) {
      return objResults[0];
    } else {
      return new Site();
    }
  }

  public async retrieveOwnSites(userId?: number, type?: string): Promise<Site[]> {
    if (userId) {
      let filters: { user_id: number; type?: string } = { user_id: userId };
      if (type) {
        filters = {
          ...filters,
          type: type,
        };
      }
      const results = await this.databaseService.select(TABLE_NAME, filters);
      return this.mapResultsObject(results);
    } else {
      return firstValueFrom(of([]));
    }
  }

  public async retrieveAll(limit?: number, raw: boolean = false): Promise<Site[] | OfflineSiteEntity[]> {
    const results = await this.databaseService.selectAll(TABLE_NAME, undefined, limit);
    if (raw) {
      return results.values as OfflineSiteEntity[];
    } else {
      return this.mapResultsObject(results);
    }
  }

  public async remove(sync_id: string): Promise<capSQLiteValues> {
    return await this.databaseService.delete(TABLE_NAME, { sync_id });
  }

  private mapResultsObject(results: capSQLiteValues): Site[] {
    if (results.values) {
      return results.values?.map((record) => {
        const site: ISite = JSON.parse(record.body_content);
        return new Site({ ...site, address: new Address({ ...(site.address as IAddress) }) });
      });
    } else {
      return [];
    }
  }

  private mapObjectsToStorable(items: ISite[]): OfflineSiteEntity[] {
    if (items) {
      return items?.map((record: ISite) => ({
        // Can't use spread operator here as it tries to enter null in undefined properties as columns
        sync_id: record.syncId || '',
        id: record.id,
        user_id: record.user?.id,
        type: record.type as IListingType['type'],
        body_content: JSON.stringify(record),
        last_modified: record.modified || DateTime.now().toMillis(),
      }));
    } else {
      return [];
    }
  }
}
