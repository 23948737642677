import { Injectable } from '@angular/core';
import {
  ClearWatchOptions,
  Geolocation,
  GeolocationPluginPermissions,
  WatchPositionCallback,
} from '@capacitor/geolocation';

@Injectable()
export class GeolocationCapacitorService {
  getCurrentPosition(options: PositionOptions) {
    return Geolocation.getCurrentPosition(options);
  }

  watchPosition(options: PositionOptions, callback: WatchPositionCallback) {
    return Geolocation.watchPosition(options, callback);
  }

  clearWatch(options: ClearWatchOptions) {
    return Geolocation.clearWatch(options);
  }

  checkPermissions() {
    return Geolocation.checkPermissions();
  }

  requestPermissions(permissions?: GeolocationPluginPermissions) {
    return Geolocation.requestPermissions(permissions);
  }
}
