import { HttpErrorResponse } from '@angular/common/http';
import {
  Activity,
  Amenity,
  IMapPoint,
  IPhoto,
  ISite,
  ISiteWhere,
  ISubPhoto,
  Policy,
  SignalReception
} from '@curbnturf/entities';
import { createAction, props } from '@ngrx/store';

export enum SiteActionTypes {
  CreateSite = '[Site] Create Site',
  CreateActivity = '[Site] Create Activity',
  CreateAmenity = '[Site] Create Amenity',
  CreatePolicy = '[Site] Create Policy',
  CreateSignalReception = '[Site] Create Signal Reception',
  CreateSiteFailed = '[Site] Create Site Failed',
  LoadSite = '[Site] Load Data',
  LoadSites = '[Site] Load Sites',
  LoadSiteFailed = '[Site] Load Data Failed',
  ClearSites = '[Site] Clear Sites',
  UpdateSite = '[Site] Update',
  UpdateSiteFailed = '[Site] Update Failed',
  UploadSiteImage = '[Site] Upload Site Image',
  UploadBoondockReviewImage = '[Site] Upload Boondock Review Image',
  BoondockReviewImageUploaded = '[Site] Uploaded Boondock Review Image',
  BoondockReviewImageUploadFailed = '[Site] Upload Failed Boondock Review Image',
  RemoveImageFromSite = '[Site] Remove Image',
  RemoveSite = '[Site] Remove Site',
  RemoveSiteFailed = '[Site] Remove Site Failed',
  RestoreSite = '[Site] Restore Site',
  RemoveActivity = '[Site] Remove Activity',
  RemoveAmenity = '[Site] Remove Amenity',
  RemovePolicy = '[Site] Remove Policy',
  RemoveSignalReception = '[Site] Remove Signal Reception',
  SiteCreated = '[Site] Created',
  SiteImageRemoved = '[Site] Image Removed',
  SiteLoaded = '[Site] Data Loaded',
  SitesLoaded = '[Site] Data For Sites Loaded',
  SiteRemoved = '[Site] Removed',
  SiteRestored = '[Site] Restored',
  SiteUpdated = '[Site] Updated',
  SiteImagesUpdated = '[Site] Images Updated',
  EnableSite = '[Site] Enable Site',
  EnableAndHideSite = '[Site] Enable and Hide Site',
  DisableSite = '[Site] Disable Site',
  SiteDisabled = '[Site] Site Disabled',
  SiteDisabledFailed = '[Site] Site Disable Failed',
  SiteEnabled = '[Site] Site Enabled',
  SiteEnabledFailed = '[Site] Site Enable Failed',
  SelectSite = '[Site] Select Site',
  SelectSiteBySite = '[Site] Select By Site',
  SiteSelected = '[Site] Site Selected',
  HideSite = '[Site] Hide Site',
  ShowSite = '[Site] Show Site',
  FavoriteSite = '[Site] Favorite Site',
  UnfavoriteSite = '[Site] Unfavorite Site',
  SiteFavorCompleted = '[Site] Site Favorite/Unfavorite Completed',
  SiteFavorFailed = '[Site] Site Favorite/Unfavorite Failed',
  SiteRecropImage = '[Site] Site Recrop Image',
  SiteRecropImageFailure = '[Site] Site Recrop Image Failure',
}

export const init = createAction('[Site] Site initialize');

export const createSite = createAction(
  SiteActionTypes.CreateSite,
  props<{ site: ISite }>(),
);

export const createActivity = createAction(
  SiteActionTypes.CreateActivity,
  props<{ site: ISite }>(),
);

export const createAmenity = createAction(
  SiteActionTypes.CreateAmenity,
  props<{ site: ISite }>(),
);

export const createPolicy = createAction(
  SiteActionTypes.CreatePolicy,
  props<{ site: ISite }>(),
);

export const createSignalReception = createAction(
  SiteActionTypes.CreateSignalReception,
  props<{ site: ISite }>(),
);

export const createSiteFailed = createAction(
  SiteActionTypes.CreateSiteFailed,
  props<{ error: string }>(),
);

export const loadSite = createAction(
  SiteActionTypes.LoadSite,
  props<{ siteId: number | string }>(),
);

export const loadSites = createAction(
  SiteActionTypes.LoadSites,
  props<{ payload?: ISiteWhere }>(),
);

export const clearSites = createAction(
  SiteActionTypes.ClearSites,
);

export const loadSiteFailed = createAction(
  SiteActionTypes.LoadSiteFailed,
  props<{ error: string }>(),
);

export const uploadSiteImage = createAction(
  SiteActionTypes.UploadSiteImage,
  props<{ formData: FormData; data: { siteId?: number; syncId?: string } }>(),
);

export const delayedUploadSiteImages = createAction(
  '[Site] Upload Site Images - delayed',
  props<{ formData: {[syncId: string]: FormData[]} }>(),
);

export const uploadSiteImages = createAction(
  '[Site] Upload Site Images',
  props<{ formData: {[syncId: string]: FormData[]} }>(),
);

export const uploadBoondockReviewImage = createAction(
  SiteActionTypes.UploadBoondockReviewImage,
  props<{ formData: FormData; data: { siteId: number } }>(),
);

export const boondockReviewImageUploaded = createAction(
  SiteActionTypes.BoondockReviewImageUploaded,
  props<{ id: number; photos: IPhoto[] }>(),
);

export const boondockReviewImageUploadFailed = createAction(
  SiteActionTypes.BoondockReviewImageUploadFailed,
  props<{ error: string }>(),
);

export const removeImageFromSite = createAction(
  SiteActionTypes.RemoveImageFromSite,
  props<{ siteId: number; index: number }>(),
);

export const removeSite = createAction(
  SiteActionTypes.RemoveSite,
  props<{ siteId: number | string }>(),
);

export const removeSiteFailed = createAction(
  SiteActionTypes.RemoveSiteFailed,
  props<{ error: string }>(),
);

export const restoreSite = createAction(
  SiteActionTypes.RestoreSite,
  props<{ siteId: number }>(),
);

export const removeActivity = createAction(
  SiteActionTypes.RemoveActivity,
  props<{ site: ISite; activity: Activity }>(),
);

export const removeAmenity = createAction(
  SiteActionTypes.RemoveAmenity,
  props<{ site: ISite; amenity: Amenity }>(),
);

export const removePolicy = createAction(
  SiteActionTypes.RemovePolicy,
  props<{ site: ISite; policy: Policy }>(),
);

export const removeSignalReception = createAction(
  SiteActionTypes.RemoveSignalReception,
  props<{ site: ISite; signalReception: SignalReception }>(),
);

export const siteCreated = createAction(SiteActionTypes.SiteCreated, props<{ site: ISite }>());

export const siteImageRemoved = createAction(
  SiteActionTypes.SiteImageRemoved,
  props<{ site: ISite }>(),
);

export const siteRemoved = createAction(
  SiteActionTypes.SiteRemoved,
  props<{ site: ISite }>(),
);

export const siteRestored = createAction(SiteActionTypes.SiteRestored, props<{ site: ISite }>());

export const siteLoaded = createAction(SiteActionTypes.SiteLoaded, props<{ site: ISite }>());

export const sitesLoaded = createAction(SiteActionTypes.SitesLoaded, props<{ sites: ISite[] }>());

export const siteUpdated = createAction(SiteActionTypes.SiteUpdated, props<{ site: ISite }>());

export const updateSite = createAction(
  SiteActionTypes.UpdateSite,
  props<{ site: ISite }>(),
);

export const updateSiteOffline = createAction('[Site Offline] Update', props<{ payload: ISite }>());

export const processPendingSite = createAction('[Site Offline] Save Offline Site To Server', props<{ site: ISite }>());

export const processPendingSiteSuccess = createAction(
  '[Site Offline] Process Pending Site Upload Success',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ site: ISite }>(),
);

export const processPendingSiteFailed = createAction(
  '[Site Offline] Process Pending Site Upload Failure',
  props<{ error: HttpErrorResponse; site?: ISite }>(),
);

export const processPendingSitePhoto = createAction(
  '[Site Offline] Process Pending Site Image Upload',
  props<{ siteId: number; photo: IPhoto }>(),
);

export const processPendingSitePhotoSuccess = createAction(
  '[Site Offline] Process Pending Site Image Upload Success',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ siteId: number; photo: IPhoto }>(),
);

export const processPendingSitePhotoFailed = createAction(
  '[Site Offline] Process Pending Site Image Upload Failure',
  props<{ error: any; siteId?: number; photo?: IPhoto }>(),
);

export const updateSiteFailed = createAction(
  SiteActionTypes.UpdateSiteFailed,
  props<{ error: string }>(),
);

export const siteImagesUpdated = createAction(
  SiteActionTypes.SiteImagesUpdated,
  props<{ id?: number; syncId?: string; photos?: IPhoto[] }>(),
);

export const enableSite = createAction(
  SiteActionTypes.EnableSite,
  props<{ site: ISite }>(),
);

export const enableAndHideSite = createAction(
  SiteActionTypes.EnableAndHideSite,
  props<{ site: ISite }>(),
);

export const siteEnabled = createAction(
  SiteActionTypes.SiteEnabled,
  props<{ siteId: number | string }>(),
);

export const siteEnabledFailed = createAction(
  SiteActionTypes.SiteEnabledFailed,
  props<{ body: string; siteId?: number }>(),
);

export const disableSite = createAction(
  SiteActionTypes.DisableSite,
  props<{ site: ISite }>(),
);

export const siteDisabled = createAction(
  SiteActionTypes.SiteDisabled,
  props<{ siteId: number | string }>(),
);

export const siteDisabledFailed = createAction(
  SiteActionTypes.SiteDisabledFailed,
  props<{ error: string }>(),
);

export const selectSite = createAction(
  SiteActionTypes.SelectSite,
  props<{ siteId?: number | string }>(),
);

export const selectSiteBySite = createAction(
  SiteActionTypes.SelectSiteBySite,
  props<{ site?: ISite }>(),
);

export const siteSelected = createAction(
  SiteActionTypes.SiteSelected,
  props<{ site?: ISite }>(),
);

export const hideSite = createAction(
  SiteActionTypes.HideSite,
  props<{ site: ISite }>(),
);

export const showSite = createAction(
  SiteActionTypes.ShowSite,
  props<{ site: ISite }>(),
);

export const favoriteSite = createAction(
  SiteActionTypes.FavoriteSite,
  props<{ point: IMapPoint }>(),
);

export const unfavoriteSite = createAction(
  SiteActionTypes.UnfavoriteSite,
  props<{ point: IMapPoint }>(),
);

export const siteFavorCompleted = createAction(
  SiteActionTypes.SiteFavorCompleted,
  props<{ id: number; favored: boolean }>(),
);

export const siteFavorFailed = createAction(
  SiteActionTypes.SiteFavorFailed,
  props<{ error: string }>(),
);

export const recropImage = createAction(
  SiteActionTypes.SiteRecropImage,
  props<{ siteToSave: ISite; photo: ISubPhoto }>(),
);


export const recropImageFailure = createAction(
  SiteActionTypes.SiteRecropImageFailure,
  props<{ error: string }>(),
);
