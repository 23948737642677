import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ConnectionStatus } from '@capacitor/network';
import { BASE_API_URL, IUser } from '@curbnturf/entities';
import { NetworkState } from '@curbnturf/network';
import * as NetworkSelectors from '@curbnturf/network/src/lib/network/+state/network.selectors';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { asyncScheduler, Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, first, skipWhile, switchMap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { AuthFacade } from './+state/auth.facade';
import { AuthService } from './auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  renewSubject: Subject<string>;
  renew$: Observable<string>;

  constructor(
    private authFacade: AuthFacade,
    private authService: AuthService,
    private store: Store<NetworkState>,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.renewSubject = new Subject();
    this.renew$ = this.renewSubject.asObservable();

    this.renew$
      .pipe(
        filter((command) => command === 'renew'),
        throttleTime(3000, asyncScheduler, { leading: true, trailing: false }),
      )
      .subscribe(() =>
        this.authService
          .renew()
          .pipe(
            catchError((err) => {
              /*
               * err of HttpErrorResponse means that we just aren't connected to internet
               * so we don't want to logout until they have a chance to retry connecting
               */
              if (
                err.name !== 'HttpErrorResponse' ||
                err.error?.message === 'Invalid Refresh Token' ||
                err.error?.message === 'Refresh Token has expired'
              ) {
                this.authFacade.logout('Your session has timed out.');
              }
              return throwError(err);
            }),
          )
          .subscribe((result) => this.renewSubject.next(result)),
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isPlatformBrowser(this.platformId) && request.url.includes(BASE_API_URL)) {
      // add authorization header with jwt token if available
      return this.authFacade.user$.pipe(
        first(),
        withLatestFrom(this.store.select(NetworkSelectors.getStatus)),
        switchMap(([currentUser, status]: [IUser, ConnectionStatus]) => {
          if (
            currentUser &&
            (!currentUser.authExpires ||
              // if the auth is expired or will expire in the next 30 seconds to allow for slow internet
              currentUser.authExpires < DateTime.now().toMillis() + 30000) &&
            status?.connected === true // If not connected we don't want to try to renew the token.
          ) {
            setTimeout(() => this.renewSubject.next('renew'));
            return this.renew$.pipe(
              skipWhile((command) => command !== 'success' && command !== 'deferred'),
              first(),
              switchMap(() => this.intercept(request, next)),
            );
          } else if (currentUser?.authIdToken) {
            return next.handle(
              request.clone({
                setHeaders: {
                  Authorization: currentUser.authIdToken,
                },
              }),
            );
          } else {
            return next.handle(request);
          }
        }),
      );
    }

    return next.handle(request);
  }
}
