import { IUser, IUserCustomLocation } from '../interfaces';
import { ILatLon } from '../interfaces';
import { IMapPoint } from '../interfaces/map-point';

export class UserCustomLocation implements IMapPoint {
  id?: number;
  name: string;
  coordinates: ILatLon;
  user?: IUser;

  constructor(data?: IUserCustomLocation) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IUserCustomLocation): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
