import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { SetRedirectUrl, SetReferenceId } from './url-control.actions';
import { UrlControlState } from './url-control.reducer';
import { urlControlQuery } from './url-control.selectors';

@Injectable({
  providedIn: 'root',
})
export class UrlControlFacade {
  redirectUrl$: Observable<string | undefined>;
  redirectUrlOnce$: Observable<string | undefined>;
  referenceId$: Observable<string | undefined>;
  referenceIdOnce$: Observable<string | undefined>;

  constructor(private store: Store<UrlControlState>) {
    this.redirectUrl$ = this.store.pipe(select(urlControlQuery.getRedirectUrl));
    this.redirectUrlOnce$ = this.redirectUrl$.pipe(first());
    this.referenceId$ = this.store.pipe(select(urlControlQuery.getReferenceId));
    this.referenceIdOnce$ = this.referenceId$.pipe(first());
  }

  setRedirectUrl(redirectUrl?: string) {
    this.store.dispatch(new SetRedirectUrl(redirectUrl));
  }

  setReferenceId(referenceId?: string) {
    this.store.dispatch(new SetReferenceId(referenceId));
  }
}
