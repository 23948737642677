import { Injectable } from '@angular/core';
import { ConnectionStatus } from '@capacitor/network';
import {
  BASE_API_URL,
  IAmenity,
  IPhoto,
  IPolicy,
  ISignalReception,
  ISite,
  ISiteWhere,
  IUser
} from '@curbnturf/entities';
import { objectToUrlParams } from '@curbnturf/helpers';
import {
  CachedHttpClient,
  Logger,
  OfflineSiteEntity,
  OfflineSitePhotoEntity,
  OfflineSitePhotosDbSet
} from '@curbnturf/network';
import { OfflineSiteDbSet } from '@curbnturf/network/src/lib/native/dbset/offline-site.dbset';
import { PhotoService } from '@curbnturf/photo';
import { StatusFacade } from '@curbnturf/status';
import * as UserSelectors from '@curbnturf/user';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, takeWhile, withLatestFrom } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

const API_URL = BASE_API_URL + 'site';

@Injectable({
  providedIn: 'root',
})
export class OfflineSiteService {
  // For this service, start with the assumption that we are online.
  connectionStatus: ConnectionStatus = { connected: true, connectionType: 'cellular' };

  get isOnline() {
    return this.connectionStatus.connected;
  }

  constructor(
    private http: CachedHttpClient,
    private logger: Logger,
    private offlineSiteDbSet: OfflineSiteDbSet,
    private offlineSitePhotosDbSet: OfflineSitePhotosDbSet,
    private photoService: PhotoService,
    private statusFacade: StatusFacade,
    private store: Store,
    private toastController: ToastController,
  ) {
    this.statusFacade.networkStatus$.subscribe((status) => {
      this.connectionStatus = status;
    });
  }

  create(site: ISite): Observable<ISite> {
    const storableSite = { ...site };
    if (!site.syncId) {
      storableSite.syncId = uuidv4();
    }
    // @todo check for photos to update and store those for later upload
    return from(this.offlineSiteDbSet.store(storableSite)).pipe(map(() => storableSite));
  }

  createAmenity(site: ISite): Observable<ISite> {
    const amenities: IAmenity[] = [];
    if (site.amenities) {
      amenities.push(...site.amenities);
    }

    amenities.push({ site });

    return this.update({ ...site, amenities });
  }

  createPolicy(site: ISite): Observable<ISite> {
    const policies: IPolicy[] = [];
    if (site.policies) {
      policies.push(...site.policies);
    }

    policies.push({ site });

    return this.update({ ...site, policies });
  }

  createSignalReception(site: ISite): Observable<ISite> {
    const signalReception: ISignalReception[] = [];
    if (site.signalReception) {
      signalReception.push(...site.signalReception);
    }

    signalReception.push({ site });

    return this.update({
      ...site,
      signalReception,
    });
  }

  fetch(syncId: string): Observable<ISite> {
    return from(this.offlineSiteDbSet.retrieve(syncId));
  }

  fetchAll(query: ISiteWhere): Observable<ISite[]> {
    const queryString = objectToUrlParams(query);
    return this.http.get<ISite[]>(`${API_URL}?${queryString}`);
  }

  remove(syncId: string): Observable<ISite> {
    return from(this.offlineSiteDbSet.retrieve(syncId)).pipe(
      mergeMap((siteToRemove: ISite) => {
        // remove photos
        if (siteToRemove.photos && siteToRemove.photos.length > 0) {
          return forkJoin(
            siteToRemove.photos?.map((photo) => {
              return from(this.offlineSitePhotosDbSet.removeByKey(photo.key)).pipe(
                map(() => {
                  return from(this.photoService.removeOfflinePhoto(photo.key)).pipe(
                    map(() => {
                      return siteToRemove;
                    }),
                  );
                }),
              );
            }),
          ).pipe(mergeMap(() => from(this.offlineSiteDbSet.remove(syncId)).pipe(map(() => siteToRemove))));
        } else {
          return from(this.offlineSiteDbSet.remove(syncId)).pipe(map(() => siteToRemove));
        }
      }),
    );
  }

  restore(siteId: number): Observable<ISite> {
    return this.http.post<ISite>(`${API_URL}/${siteId}/restore`, null);
  }

  removeAmenity(site: ISite, amenity: IAmenity): Observable<ISite> {
    if (!site.amenities) {
      return of(site);
    }

    const amenities = [...site.amenities];

    const index = amenities.indexOf(amenity);

    if (index || index === 0) {
      amenities.splice(index, 1);
    }

    return this.update({ ...site, amenities });
  }

  removePolicy(site: ISite, policy: IPolicy): Observable<ISite> {
    if (!site.policies) {
      return of(site);
    }
    const policies = [...site.policies];

    const index = policies.findIndex((indexedPolicy) => indexedPolicy.id === policy.id);

    if (index >= 0) {
      policies.splice(index, 1);
    }

    return this.update({ ...site, policies });
  }

  removeSignalReception(site: ISite, signalReception: ISignalReception): Observable<ISite> {
    if (!site.signalReception) {
      return of(site);
    }

    const signalReceptions = [...site.signalReception];

    const index = signalReceptions.indexOf(signalReception);

    if (index || index === 0) {
      signalReceptions.splice(index, 1);
    }

    return this.update({
      ...site,
      signalReception: signalReceptions,
    });
  }

  update(site: ISite): Observable<ISite> {
    // @todo check for photos to update and store those for later upload
    return from(this.offlineSiteDbSet.store(site)).pipe(map(() => site));
  }

  enable(site: ISite): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${site.id}/enable`, null);
  }

  enableAndHide(site: ISite): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${site.id}/enableAndHide`, null);
  }

  disable(site: ISite): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${site.id}/disable`, null);
  }

  // @todo rework for offline functionality
  hide(site: ISite): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${site.id}/hide`, null);
  }

  // @todo rework for offline functionality
  show(site: ISite): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${site.id}/show`, null);
  }

  // @todo rework for offline functionality
  removeImage(siteId: number, photoIndex: number): Observable<IPhoto[]> {
    return this.http.delete<IPhoto[]>(`${API_URL}/${siteId}/remove-image/${photoIndex}`);
  }

  uploadImage(syncId: string, photoFormData: FormData): Observable<{ syncId: string; photos: IPhoto[] }> {
    const file = photoFormData.get('files') as File;
    const caption = photoFormData.get('captions') as string;
    const entity: OfflineSitePhotoEntity = {
      syncId: syncId,
      key: 'unknown',
      caption,
    };

    return from(this.photoService.saveOfflinePhoto(file)).pipe(
      takeWhile((filename) => filename !== false),
      mergeMap((filename) => {
        if (filename) {
          entity.key = filename;
          const photo: IPhoto = {
            key: filename,
            caption: entity.caption,
          };
          // Check that we haven't already saved this photo to the database.
          return from(this.offlineSitePhotosDbSet.retrieveByKey(entity.key)).pipe(
            mergeMap((result) => {
              if (result.length === 0) {
                return from(this.offlineSitePhotosDbSet.store(entity)).pipe(map(() => ({ photos: [photo], syncId })));
              }
              return of({ photos: [photo], syncId });
            }),
          );
        } else {
          throw new Error('Failed to save uploaded image.');
        }
      }),
    );
  }

  // @todo rework for offline functionality
  favorite(site: ISite): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${site.id}/favorite`, null);
  }

  // @todo rework for offline functionality
  unfavorite(site: ISite): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${site.id}/unfavorite`, null);
  }

  uploadOfflineSites(): Observable<ISite[]> {
    return from(this.offlineSiteDbSet.retrieveAll()).pipe(
      withLatestFrom(this.store.select(UserSelectors.getCurrentUser)),
      map(([sites, currentUser]: [ISite[] | OfflineSiteEntity[], IUser]) => {
        return sites.map((site) => {
          const photos: IPhoto[] = (site as ISite)?.photos?.slice() || [];
          const siteToUpload: ISite = {
            ...site,
            id: site.id || undefined,
            photos: undefined,
            user: { id: currentUser.id },
          };
          for (const x in siteToUpload) {
            if (
              // @ts-ignore I really don't care in this context that it's "any"
              siteToUpload[x] === null ||
              // @ts-ignore I really don't care in this context that it's "any"
              siteToUpload[x] === '' ||
              // @ts-ignore I really don't care in this context that it's "any"
              (Array.isArray(siteToUpload[x]) && siteToUpload[x].length === 0)
            ) {
              // @ts-ignore I really don't care in this context that it's "any"
              siteToUpload[x] = undefined;
            }
          }

          return this.http.post<ISite>(API_URL, siteToUpload).pipe(
            exhaustMap((result: ISite) => {
              if (result.syncId && result.id) {
                const siteId: number = result.id;
                const syncId: string = result.syncId;
                if (Array.isArray(photos) && photos.length > 0) {
                  // Upload attached photos
                  return forkJoin(
                    photos.map((photo) => {
                      return this.uploadOfflinePhoto(photo, siteId, syncId).pipe(
                        map(() => {
                          return site;
                        }),
                      );
                    }),
                  ).pipe(
                    mergeMap(() => {
                      return this.remove(syncId).pipe(
                        map((site) => {
                          this.logger.debug('Offline site removed.', { siteId, syncId: site.syncId });
                          return site;
                        }),
                      );
                    }),
                  );
                } else {
                  // No Photos exist on this record, remove the record.
                  return this.remove(syncId).pipe(
                    map((site) => {
                      this.logger.debug('Offline site removed.', { siteId, syncId: site.syncId });
                      return site;
                    }),
                  );
                }
              }

              return of(result);
            }),
            catchError(async (e) => {
              this.logger.error('Failed to Upload cached site.' + (e.message || JSON.stringify(e)));
              const toast = await this.toastController.create({
                message: 'Failed to Upload Site. Error:' + (e.message || JSON.stringify(e)),
                duration: 3000,
              });
              await toast.present();
            }),
          );
        });
      }),
      mergeMap((obsResult: Observable<ISite>[]) => forkJoin(obsResult)),
    );
  }

  uploadOfflinePhoto(photo: IPhoto, siteId: number, syncId: string): Observable<IPhoto | void> {
    this.logger.debug('Uploading Photo', { siteId, syncId, photo });
    return from(this.photoService.retrieveOfflinePhoto(photo.key)).pipe(
      mergeMap((file) => {
        if (file) {
          this.logger.debug('Photo found, proceeding to upload.', { siteId, syncId, photo });
          // Setup the file data to send to the server
          const formData = new FormData();
          formData.append('files', file);
          formData.append('captions', photo.caption || '');
          formData.append('siteId', siteId.toString());
          formData.append('syncId', syncId);

          return this.http.post<{ id: number; photos: IPhoto[] }>(`${API_URL}/${siteId}/upload-image`, formData).pipe(
            map((photoResult) => {
              this.logger.debug('Successfully uploaded image: ' + photo.key, {
                siteId,
                result: JSON.stringify(photoResult),
              });
              return photoResult.photos.pop();
            }),
            catchError((error) => {
              this.logger.error('Failed to upload site image.', { siteId, photo, error });
              return of(error);
            }),
          );
        } else {
          this.logger.warning('Local photo not found.', { siteId, syncId, photo });
        }
        return of();
      }),
    );
  }
}
