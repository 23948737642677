import { RatingType } from '../enums';
import { IReservation, IScore, ISite, ISiteRating } from '../interfaces';
import { Rating } from './rating';
import { User } from './user';

export class SiteRating extends Rating implements ISiteRating {
  siteRated?: ISite;
  siteRatedId?: number;
  reservation?: IReservation;
  reservationId?: number;
  hidden?: boolean;

  constructor(data?: ISiteRating) {
    super(data);

    if (data) {
      this.setData(data);
    }
  }

  setData(data: ISiteRating): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (this.creator) {
      this.creator = new User(this.creator);
    }
  }
}
