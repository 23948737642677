import { inject } from '@angular/core';
import * as AlertActions from '@curbnturf/alert/src/lib/+state/alert.actions';
import { ICoordinateGis, ITrip, ITripRoutePoint, NGRX_NO_ACTION } from '@curbnturf/entities';
import * as NetworkSelectors from '@curbnturf/network/src/lib/network/+state/network.selectors';
import * as UserSelectors from '@curbnturf/user/src/lib/+state/user.selectors';
import { RoutingService } from '@curbnturf/map/src/lib/geocoding/routing.service';
import { LoadingController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { TripPlannerService } from '../trip-planner.service';
import * as TripPlannerActions from './trip-planner.actions';
import * as TripPlannerSelectors from './trip-planner.selectors';

export const newTrip$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(TripPlannerActions.newTrip),
      mergeMap(() => from([TripPlannerActions.updateRoute({ showLoader: true })])),
    ),
  { functional: true },
);

export const addRoutePoint$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(TripPlannerActions.addRoutePoint),
      mergeMap((action) => {
        if (action.updateRoute !== true) {
          return from([NGRX_NO_ACTION]);
        } else {
          return from([TripPlannerActions.updateRoute({ showLoader: true })]);
        }
      }),
    ),
  { functional: true },
);

export const removeRoutePoint$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(TripPlannerActions.removeRoutePoint),
      mergeMap((action) => {
        if (action.updateRoute !== true) {
          return from([NGRX_NO_ACTION]);
        } else {
          return from([TripPlannerActions.updateRoute({ showLoader: true })]);
        }
      }),
    ),
  { functional: true },
);

export const reorderPoint$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(TripPlannerActions.reorderPoint),
      mergeMap(() => from([TripPlannerActions.updateRoute({ showLoader: true })])),
    ),
  { functional: true },
);

export const setPointActivity$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(TripPlannerActions.setPointActivity),
      mergeMap(() => from([TripPlannerActions.updateRoute({ showLoader: true })])),
    ),
  { functional: true },
);

export const setCorridor$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(TripPlannerActions.setCorridor),
      withLatestFrom(store.select(TripPlannerSelectors.getTrip)),
      withLatestFrom(store.select(TripPlannerSelectors.getCorridor)),
      mergeMap(() => from([TripPlannerActions.updateRoute({ showLoader: true })])),
    ),
  { functional: true },
);

export const updateRoute$ = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    routingService = inject(RoutingService),
    loadingCtrl = inject(LoadingController),
  ) =>
    actions$.pipe(
      ofType(TripPlannerActions.updateRoute),
      withLatestFrom(store.select(TripPlannerSelectors.getTrip)),
      withLatestFrom(store.select(TripPlannerSelectors.getCorridor)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      mergeMap(([[[action, points], corridor], status]) => {
        // If offline, we can't plot the route
        if (status.connected !== true) {
          return from([NGRX_NO_ACTION]);
        }

        const activePoints = points.filter((el) => el.isActive);

        if (activePoints.length < 2) {
          // No Action
          return from([
            TripPlannerActions.setRoute({ route: [] }),
            TripPlannerActions.setBuffer({ buffer: [] }),
            TripPlannerActions.setDistance({ distance: 0 }),
          ]);
        }

        if (action.showLoader === true) {
          loadingCtrl
            .create({
              message: 'Plotting Route',
              duration: 10000,
            })
            .then((loading) => loading.present());
        }

        return routingService.fetchRoute(activePoints, corridor).pipe(
          mergeMap((resp) => {
            return [
              TripPlannerActions.setRoute({ route: resp.route }),
              TripPlannerActions.setBuffer({ buffer: resp.buffer }),
              TripPlannerActions.setDistance({ distance: resp.distance }),
            ];
          }),
          catchError((error) => {
            loadingCtrl.dismiss();
            return of(
              AlertActions.displayAlert({
                level: 'error',
                body: 'Failed to update your route: ' + (typeof error === 'string') ? error : JSON.stringify(error),
                title: 'Failed to Plot Route',
              }),
            );
          }),
        );
      }),
    ),
  { functional: true },
);

export const setDates$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(TripPlannerActions.setDates),
      withLatestFrom(store.select(TripPlannerSelectors.getTrip)),
      mergeMap(([action, trip]) => {
        let validDates = true;

        // Check for invalid dates
        trip.forEach((routePoint) => {
          if (
            routePoint.arrival &&
            routePoint.departure &&
            JSON.stringify({ listing: action.point.listing, poi: action.point.poi }) !==
              JSON.stringify({ listing: routePoint.listing, poi: routePoint.poi })
          ) {
            if (routePoint.departure.diff(action.arrival).as('seconds') > 0) {
              if (routePoint.arrival.diff(action.departure).as('seconds') < 0) {
                validDates = false;
              }
            }
            if (routePoint.arrival.diff(action.departure).as('seconds') > 0) {
              if (routePoint.departure.diff(action.arrival).as('seconds') < 0) {
                validDates = false;
              }
            }
          }
        });
        if (validDates) {
          let validDateOrder: boolean = true;
          let toPosition: number = 1;

          const currentPosition = trip.findIndex(
            (el) =>
              JSON.stringify({ ...el, arrival: undefined, departure: undefined }) ===
              JSON.stringify({ ...action.point, arrival: undefined, departure: undefined }),
          );
          for (let i = 0; i < trip.length; i++) {
            // Don't compare to itself, that's just asking for trouble!
            if (i === currentPosition) {
              continue;
            }

            // Compare the point's departure date to the new arrival date.
            let closestDiff = -1000000000;
            const departure = trip[i].departure;
            if (departure instanceof DateTime && action.arrival instanceof DateTime && i > currentPosition) {
              const diff = departure.diff(action.arrival).as('seconds');

              // If the date of arrival is greater than the departure date of the comparison point it should be moved down.
              if (diff <= 0 && diff >= closestDiff) {
                validDateOrder = false;
                toPosition = i;
                closestDiff = diff;
                //break;
              }
            }

            // Compare the point's arrival date to the new departure date.
            const arrival = trip[i].arrival;
            if (arrival instanceof DateTime && action.arrival instanceof DateTime && i < currentPosition) {
              const diff = arrival.diff(action.departure).as('seconds');

              // If the date of departure is greater than the arrival date of the comparison point it should be moved up.
              if (diff >= 0) {
                validDateOrder = false;
                toPosition = i;
                break;
              }
            }
          }

          if (validDateOrder) {
            return from([NGRX_NO_ACTION]);
          } else {
            return from([
              TripPlannerActions.reorderPoint({ source: currentPosition, destination: toPosition }),
              AlertActions.displayAlert({
                level: 'warning',
                title: 'Trip Reordered - Date Conflict',
                body: 'You entered trip dates that were out of order with your trip route. Your trip has been reordered to match the dates that you entered.',
              }),
            ]);
          }
        } else {
          return of(
            AlertActions.displayAlert({
              level: 'error',
              title: 'Overlapping Date Detected',
              body: 'Your dates overlap and cannot be saved as entered. Please select different dates.',
            }),
          );
        }
      }),
    ),
  { functional: true },
);

export const saveTrip$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), tripPlannerService = inject(TripPlannerService)) =>
    actions$.pipe(
      ofType(TripPlannerActions.saveTrip),
      withLatestFrom(store.select(TripPlannerSelectors.getTrip)),
      withLatestFrom(store.select(TripPlannerSelectors.getCorridor)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([[[action, points], corridor], user]) => {
        const tripRoutePoints: ITripRoutePoint[] = points.map((el, index) => {
          let coordinates: ICoordinateGis;

          coordinates = el.coordinates;

          const routePoint: ITripRoutePoint = {
            userId: user.id,
            siteId: el.listing?.id,
            poiId: el.poi?.id,
            userPointId: el.user?.id,
            isActive: el.isActive,
            arrival: el.arrival,
            departure: el.departure,
            address: el.poi?.address,
            name: el.listing?.name || el.poi?.name || el.user?.name,
            coordinates,
            ordinality: index,
          };

          return routePoint;
        });

        const trip: ITrip = {
          name: action.name,
          route: tripRoutePoints,
          width: corridor,
          rvId: action.rvId,
        };

        return tripPlannerService.saveTrip(trip).pipe(
          mergeMap((tripResult) =>
            of(
              TripPlannerActions.saveTripSuccess({ trip: tripResult }),
              AlertActions.displayAlert({
                body:
                  'Your trip has been saved as: ' +
                  trip.name +
                  '<br>You can view, edit or book your trip from the Trips screen.',
                title: 'Trip Saved',
              }),
            ),
          ),
          catchError((error) => {
            return of(
              TripPlannerActions.saveTripFailed({ error }),
              AlertActions.displayAlert({
                level: 'error',
                body: 'Failed to save your trip: ' + (typeof error === 'string') ? error : JSON.stringify(error),
                title: 'Failed to Save Trip',
              }),
            );
          }),
        );
      }),
    ),
  { functional: true },
);

export const loadTrips$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), tripPlannerService = inject(TripPlannerService)) =>
    actions$.pipe(
      ofType(TripPlannerActions.loadTrips),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([action, user]) => {
        let userId: number;
        if (action.userId) {
          userId = action.userId;
        } else {
          userId = user.id || 0;
        }

        return tripPlannerService.getTrips(userId).pipe(
          mergeMap((trips) => of(TripPlannerActions.loadTripsSuccess({ trips }))),
          catchError((error) => {
            return of(
              TripPlannerActions.loadTripsFailed({ error }),
              AlertActions.displayAlert({
                level: 'error',
                body: 'Failed to load your trip list: ' + (typeof error === 'string') ? error : JSON.stringify(error),
                title: 'Failed to Load Trip List',
              }),
            );
          }),
        );
      }),
    ),
  { functional: true },
);
