import { ISite } from '@curbnturf/entities';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[Prefetch] Init');

export const syncOfflineSites = createAction('[Prefetch] Sync Offline Sites');
export const syncOfflineSitesSuccess = createAction(
  '[Prefetch] Sync Offline Sites Success',
  props<{ sites: ISite[] }>(),
);

export const syncOfflineSitesFailed = createAction('[Prefetch] Sync Offline Sites Failed', props<{ error: any }>());
