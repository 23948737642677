import { IActivity, IActivityLocation, IPhoto, IUser } from '../interfaces';

export class Photo implements IPhoto {
  id?: number;
  key: string;
  caption?: string;
  activity?: IActivity;
  activityId?: number;
  location?: IActivityLocation;
  locationId?: number;
  user?: IUser;
  userId?: number;

  constructor(data?: IPhoto) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IPhoto): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
