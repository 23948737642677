import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import {
  CachableHttpRequest,
  CachedHttpResponse,
  ICachableHttpRequest,
  ICachedHttpResponse,
  LogLevel,
} from '@curbnturf/entities';
import { PlatformService } from '@curbnturf/network/src/lib/platform/platform.service';
import { DateTime } from 'luxon';
import { firstValueFrom, of } from 'rxjs';
import { LogFacade } from '../log/+state/log.facade';
import { CachedHttpRequestsDbSet } from '../native/dbset/cached-http-requests.dbset';
import { CachedHttpResponsesDbSet } from '../native/dbset/cached-http-responses.dbset';

export const CACHED_HTTP_REQUEST_STORAGE_KEY = 'cached-http-requests';
export const CACHED_HTTP_RESPONSE_STORAGE_KEY = 'cached-http-responses';

@Injectable({
  providedIn: 'root',
})
export class CachedDataStorageService {
  constructor(
    private cachedHttpRequestsDbSet: CachedHttpRequestsDbSet,
    private cachedHttpResponsesDbSet: CachedHttpResponsesDbSet,
    private platformService: PlatformService,
    private logFacade: LogFacade,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async set(requests: ICachableHttpRequest[], responses: ICachedHttpResponse[]): Promise<any> {
    if (this.platformService.isPhysicalDevice()) {
      if (requests.length > 0) {
        await this.cachedHttpRequestsDbSet.truncateAndStoreArray(requests);
      } else {
        await this.cachedHttpRequestsDbSet.truncate();
      }
      if (responses.length > 0) {
        await this.cachedHttpResponsesDbSet.truncateAndStoreArray(responses);
      } else {
        await this.cachedHttpResponsesDbSet.truncate();
      }
    } else {
      await Preferences.set({ key: CACHED_HTTP_REQUEST_STORAGE_KEY, value: JSON.stringify(requests) });
      await Preferences.set({ key: CACHED_HTTP_RESPONSE_STORAGE_KEY, value: JSON.stringify(responses) });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async setRequests(requests: ICachableHttpRequest[]): Promise<any> {
    if (this.platformService.isPhysicalDevice()) {
      if (requests.length > 0) {
        await this.cachedHttpRequestsDbSet.truncateAndStoreArray(requests);
      } else {
        await this.cachedHttpRequestsDbSet.truncate();
      }
    } else {
      await Preferences.set({ key: CACHED_HTTP_REQUEST_STORAGE_KEY, value: JSON.stringify(requests) });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async setResponses(responses: ICachedHttpResponse[]): Promise<any> {
    if (this.platformService.isPhysicalDevice()) {
      if (responses.length > 0) {
        await this.cachedHttpResponsesDbSet.truncateAndStoreArray(responses);
      } else {
        await this.cachedHttpResponsesDbSet.truncate();
      }
    } else {
      await Preferences.set({ key: CACHED_HTTP_RESPONSE_STORAGE_KEY, value: JSON.stringify(responses) });
    }
  }

  public async get(): Promise<{ requests: CachableHttpRequest[]; responses: CachedHttpResponse[] }> {
    let requests = [];
    let responses = [];
    if (this.platformService.isPhysicalDevice()) {
      try {
        requests = await this.cachedHttpRequestsDbSet.retrieveAll();
        responses = await this.cachedHttpResponsesDbSet.retrieveAll();
      } catch (e) {
        this.logFacade.log({
          level: LogLevel.ERROR,
          message: 'Failed to retrieve cached requests or responses.',
          details: { error: e },
          created: DateTime.now().toMillis(),
        });
        requests = [];
        responses = [];
      }
    } else {
      const requestResults = (await Preferences.get({ key: CACHED_HTTP_REQUEST_STORAGE_KEY })).value;
      const responseResults = (await Preferences.get({ key: CACHED_HTTP_REQUEST_STORAGE_KEY })).value;
      if (requestResults !== null) {
        requests = JSON.parse(requestResults || '');
      }
      if (responseResults !== null) {
        responses = JSON.parse(responseResults || '');
      }
    }

    return firstValueFrom(of({ requests: requests || [], responses: responses || [] }));
  }

  public async getRequests(limit: number = 20, skip: number = 0): Promise<CachableHttpRequest[]> {
    let requests = [];
    if (this.platformService.isPhysicalDevice()) {
      requests = await this.cachedHttpRequestsDbSet.retrieveAll(limit, skip);
    } else {
      const requestResults = (await Preferences.get({ key: CACHED_HTTP_REQUEST_STORAGE_KEY })).value;
      if (requestResults !== null) {
        requests = JSON.parse(requestResults || '');
      }
    }

    return of(requests || []).toPromise();
  }

  public async insertRequest(request: CachableHttpRequest): Promise<void> {
    if (request.id) {
      if (this.platformService.isPhysicalDevice()) {
        await this.cachedHttpRequestsDbSet.store(request);
      } else {
        const results = (await Preferences.get({ key: CACHED_HTTP_REQUEST_STORAGE_KEY })).value;
        if (results !== null) {
          const requestResults = JSON.parse(results || '') || [];
          const foundRequest = requestResults.findIndex((el: CachableHttpRequest) => el.id === request.id);
          const storeRequests = requestResults;
          if (foundRequest) {
            storeRequests.splice(foundRequest, 1, request);
          } else {
            storeRequests.push(request);
          }
          await Preferences.set({ key: CACHED_HTTP_REQUEST_STORAGE_KEY, value: JSON.stringify(storeRequests) });
        }
      }
    }
  }

  public async updateRequest(request: CachableHttpRequest): Promise<void> {
    if (request.syncId) {
      if (this.platformService.isPhysicalDevice()) {
        await this.cachedHttpRequestsDbSet.update(request);
      } else {
        const results = (await Preferences.get({ key: CACHED_HTTP_REQUEST_STORAGE_KEY })).value;
        if (results !== null) {
          const requestResults = JSON.parse(results || '') || [];
          const foundRequest = requestResults.findIndex((el: CachableHttpRequest) => el.id === request.id);
          const storeRequests = requestResults;
          if (foundRequest) {
            storeRequests.splice(foundRequest, 1, request);
          } else {
            storeRequests.push(request);
          }
          await Preferences.set({ key: CACHED_HTTP_REQUEST_STORAGE_KEY, value: JSON.stringify(storeRequests) });
        }
      }
    }
  }

  public async removeRequest(request: CachableHttpRequest): Promise<void> {
    if (request.syncId) {
      if (this.platformService.isPhysicalDevice()) {
        try {
          await this.cachedHttpRequestsDbSet.remove(request.syncId);
        } catch (e) {
          this.logFacade.log({
            level: LogLevel.ERROR,
            message: 'Failed at DBSet to remove cached request.',
            details: {
              error: e,
              request: request,
              dbset: this.cachedHttpRequestsDbSet,
            },
          });
        }
      } else {
        const results = (await Preferences.get({ key: CACHED_HTTP_REQUEST_STORAGE_KEY })).value;
        if (results !== null) {
          const requestResults = JSON.parse(results || '') || [];
          const storeRequests = requestResults.filter((el: CachableHttpRequest) => el.id !== request.id);
          await Preferences.set({ key: CACHED_HTTP_REQUEST_STORAGE_KEY, value: JSON.stringify(storeRequests) });
        }
      }
    }
  }
}
