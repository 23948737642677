import { NgModule } from '@angular/core';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as listingSearchEffects from './+state/listing-search.effects';
import { initialState as listingSearchInitialState, listingSearchReducer } from './+state/listing-search.reducer';

@NgModule({
  providers: [
    provideState(NGRX_BUCKETS.listingSearch, listingSearchReducer, {
      initialState: listingSearchInitialState,
    }),
    provideEffects(listingSearchEffects),
  ],
})
export class ListingSearchModule {}
