import { ICachedHttpResponse } from '../interfaces';

export class CachedHttpResponse implements ICachedHttpResponse {
  static readonly METHOD_GET: string = 'GET';
  static readonly METHOD_POST: string = 'POST';
  static readonly METHOD_PUT: string = 'PUT';
  static readonly METHOD_PATCH: string = 'PATCH';
  static readonly METHOD_DELETE: string = 'DELETE';

  public method?: string;
  public url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public body?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public response?: any;

  constructor(data?: ICachedHttpResponse) {
    if (data) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this as any)[key] = (data as any)[key];
        }
      }
    }
  }
}
