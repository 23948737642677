export const emailTemplate = `<!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html
  style="width:100%;font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;Margin:0;">

<head>
  <meta charset="UTF-8" />
  <meta content="width=device-width, initial-scale=1" name="viewport" />
  <meta name="x-apple-disable-message-reformatting" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta content="telephone=no" name="format-detection" />
  <title>CNT Listing Published</title>
  <!--[if (mso 16)]>
<style type="text/css">
a {
text-decoration: none;
}
</style>
<![endif]-->
  <!--[if gte mso 9
]><style>
sup {
font-size: 100% !important;
}
</style><!
[endif]-->
  <!--[if !mso]><!-- -->
  <link href="https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i" rel="stylesheet" />
  <!--<![endif]-->
  <style type="text/css">
    @media only screen and (max-width: 600px) {

      p,
      ul li,
      ol li,
      a {
        font-size: 16px !important;
        line-height: 150% !important;
      }

      h2 {
        font-size: 26px !important;
        text-align: center;
        line-height: 120% !important;
      }

      *[class='gmail-fix'] {
        display: none !important;
      }

      .es-m-txt-c,
      .es-m-txt-c h2 {
        text-align: center !important;
      }

      .es-m-txt-c img {
        display: inline !important;
      }

      .es-button-border {
        display: block !important;
      }

      a.es-button {
        font-size: 20px !important;
        display: block !important;
        border-width: 10px 0px 10px 0px !important;
      }

      .es-btn-fw {
        border-width: 10px 0px !important;
        text-align: center !important;
      }

      .es-adaptive table,
      .es-btn-fw,
      .es-btn-fw-brdr,
      .es-left,
      .es-right {
        width: 100% !important;
      }

      .es-content table,
      .es-content {
        width: 100% !important;
        max-width: 600px !important;
      }

      .es-m-p0r {
        padding-right: 0px !important;
      }

      .es-m-p20b {
        padding-bottom: 20px !important;
      }

      .es-desk-hidden {
        display: table-row !important;
        width: auto !important;
        overflow: visible !important;
        float: none !important;
        max-height: inherit !important;
        line-height: inherit !important;
      }

      table.es-table-not-adapt {
        width: auto !important;
      }

      table.es-social {
        display: inline-block !important;
      }

      table.es-social td {
        display: inline-block !important;
      }
    }

    #outlook a {
      padding: 0;
    }

    .ExternalClass {
      width: 100%;
    }

    .ExternalClass,
    .ExternalClass p,
    .ExternalClass span,
    .ExternalClass font,
    .ExternalClass td,
    .ExternalClass div {
      line-height: 100%;
    }

    .es-button {
      mso-style-priority: 100 !important;
      text-decoration: none !important;
    }

    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: none !important;
      font-size: inherit !important;
      font-family: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
    }

    .es-desk-hidden {
      display: none;
      float: left;
      overflow: hidden;
      width: 0;
      max-height: 0;
      line-height: 0;
      mso-hide: all;
    }
  </style>
</head>

<body
  style="width:100%;font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;Margin:0;">
  <div class="es-wrapper-color" style="background-color:#FFFFFF;">
    <!--[if gte mso 9]>
<v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
<v:fill type="tile" color="#ffffff"></v:fill>
</v:background>
<![endif]-->
    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
      style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:100%;background-repeat:repeat;background-position:center top;">
      <tr style="border-collapse:collapse;">
        <td valign="top" style="padding:0;Margin:0;">
          <table cellpadding="0" cellspacing="0" class="es-content" align="center"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;">
            <tr style="border-collapse:collapse;">
              <td align="center" style="padding:0;Margin:0;">
                <table bgcolor="#ffffff" class="es-content-body" align="center" cellpadding="0" cellspacing="0"
                  width="600"
                  style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;">
                  <tr style="border-collapse:collapse;">
                    <td align="left" style="padding:0;Margin:0;padding-top:20px;padding-left:20px;padding-right:20px;">
                      <table cellpadding="0" cellspacing="0" width="100%"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;">
                        <tr style="border-collapse:collapse;">
                          <td width="560" align="center" valign="top" style="padding:0;Margin:0;">
                          <table cellpadding="0" cellspacing="0" width="100%" role="presentation"
                              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                              <tr style="border-collapse:collapse;">
                                <td align="left" style="padding:0;Margin:0;">
                                  <img src="https://cnt-email.s3-us-west-2.amazonaws.com/welcome/12471578524161226.png"
                                    alt="CurbNTurf"
                                    style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;"
                                    width="72" />
                                </td>
                              <tr style="border-collapse:collapse">
                                <td align="center" height="16" style="padding:0;Margin:0"></td>
                              </tr>
                              <tr style="border-collapse:collapse">
                                <td align="left" bgcolor="transparent" style="padding:0;Margin:0">
                                  {{content goes here}}
                                </td>
                              </tr>
                              <tr style="border-collapse:collapse">
                                <td align="left" bgcolor="transparent" style="padding:0;Margin:0">
                                  <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;line-height:21px;color:#333333;font-size:14px">
                                  </p>
                                </td>
                              </tr>
                              <tr style="border-collapse:collapse">
                                <td align="center" height="16" style="padding:0;Margin:0"></td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr style="border-collapse:collapse;">
                    <td
                      style="Margin:0;padding-top:15px;padding-bottom:15px;padding-left:20px;padding-right:20px;background-color:#E3530D;background-position:left bottom;"
                      bgcolor="#e3530d" align="left">
                      <!--[if mso]><table width="560" cellpadding="0" cellspacing="0"><tr><td width="180" valign="top"><![endif]-->
                      <table class="es-left" cellspacing="0" cellpadding="0" align="left"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left;">
                        <tr style="border-collapse:collapse;">
                          <td class="es-m-p0r es-m-p20b" width="180" valign="top" align="center"
                            style="padding:0;Margin:0;">
                            <table width="100%" cellspacing="0" cellpadding="0"
                              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;">
                              <tr style="border-collapse:collapse;">
                                <td class="es-m-txt-c" align="left" style="padding:0;Margin:0;">
                                  <img src="https://cnt-email.s3-us-west-2.amazonaws.com/welcome/30651578515548862.png"
                                    alt="CurbNTurf Logo"
                                    style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;"
                                    width="32" />
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>

                      <!--[if mso]></td><td width="20"></td><td width="360" valign="top"><![endif]-->
                      <table cellspacing="0" cellpadding="0" align="right"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;">
                        <tr style="border-collapse:collapse;">
                          <td width="360" align="left" style="padding:0;Margin:0;">
                            <table width="100%" cellspacing="0" cellpadding="0"
                              style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;">
                              <tr style="border-collapse:collapse;">
                                <td class="es-m-txt-c" align="right" style="padding:0;Margin:0;padding-top:10px;">
                                  <table class="es-table-not-adapt es-social" cellspacing="0" cellpadding="0"
                                    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;">
                                    <tr style="border-collapse:collapse;">
                                      <td valign="top" align="center" style="padding:0;Margin:0;padding-right:20px;">
                                        <a target="_blank" href="https://www.facebook.com/CurbNTurf"
                                          style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;font-size:14px;text-decoration:underline;color:#2CB543;">
                                          <img title="Facebook"
                                            src="https://cnt-email.s3-us-west-2.amazonaws.com/welcome/facebook-circle-white-bordered.png"
                                            alt="Fb" width="32"
                                            style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;" />
                                        </a>
                                      </td>
                                      <td valign="top" align="center" style="padding:0;Margin:0;padding-right:20px;">
                                        <a target="_blank" href="https://www.instagram.com/curbnturf/"
                                          style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;font-size:14px;text-decoration:underline;color:#2CB543;">
                                          <img title="Instagram"
                                            src="https://cnt-email.s3-us-west-2.amazonaws.com/welcome/instagram-circle-white-bordered.png"
                                            alt="Inst" width="32"
                                            style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;" />
                                        </a>
                                      </td>
                                      <td valign="top" align="center" style="padding:0;Margin:0;">
                                        <a target="_blank"
                                          href="https://www.youtube.com/channel/UCJ6Gk1jp3nVq1hFY6MwtZ3g"
                                          style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;font-size:14px;text-decoration:underline;color:#2CB543;">
                                          <img title="Youtube"
                                            src="https://cnt-email.s3-us-west-2.amazonaws.com/welcome/youtube-circle-white-bordered.png"
                                            alt="Yt" width="32"
                                            style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;" />
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>

                      <!--[if mso]></td></tr></table><![endif]-->
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</body>

</html>`;

export const emailTemplateContentSection = '{{content goes here}}';
export const emailTemplateContentStart = '<!--start of content-->';
export const emailTemplateContentEnd = '<!--end of content-->';
