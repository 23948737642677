import { NgModule } from '@angular/core';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { provideState } from '@ngrx/store';
import { UrlControlFacade } from './+state/url-control.facade';
import { urlControlReducer } from './+state/url-control.reducer';

@NgModule({
  providers: [provideState(NGRX_BUCKETS.urlControl, urlControlReducer)],
})
export class UrlControlModule {}
