import { NgIf, NgStyle } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'curbnturf-misc-svg',
  templateUrl: './misc-svg.component.html',
  styleUrls: ['./misc-svg.component.scss'],
  standalone: true,
  imports: [NgIf, NgStyle],
})
export class MiscSvgComponent {
  @ViewChild('iconElement', { static: true }) iconEl: ElementRef;

  @Input() icon: string;
  @Input() color: string = '#454545';
  @Input() secondColor: string = '#fff';
}
