import { ISite } from '@curbnturf/entities';
import { v4 as uuidv4 } from 'uuid';

export function newSite(site: ISite = {}): ISite {
  return {
    ...site,
    id: undefined,
    syncId: uuidv4(),
    vehicles: {
      classA: site?.vehicles ? site.vehicles.classA : true,
      classB: site?.vehicles ? site.vehicles.classB : true,
      classC: site?.vehicles ? site.vehicles.classC : true,
      fifthWheel: site?.vehicles ? site.vehicles.fifthWheel : true,
      travelTrailer: site?.vehicles ? site.vehicles.travelTrailer : true,
      truckCamper: site?.vehicles ? site.vehicles.truckCamper : true,
      teardrop: site?.vehicles ? site.vehicles.teardrop : true,
      popup: site?.vehicles ? site.vehicles.popup : true,
    },
    available: false,
    hidden: false,
    blackoutDays: [],
    blackoutMonths: [],
    reservations: [],
    ratings: [],
    photos: [],
    extras:
      site?.extras?.map((extra) => {
        return { ...extra, id: undefined };
      }) || [],
    landOwner: site && site.landOwner ? { ...site.landOwner, id: undefined } : undefined,
    amenities:
      site?.amenities?.map((amenity) => ({
        ...amenity,
        id: undefined,
        site: undefined,
      })) || [],
    activities:
      site?.activities?.map((activity) => ({
        ...activity,
        id: undefined,
        site: undefined,
      })) || [],
    signalReception:
      site?.signalReception?.map((signalReception) => ({
        ...signalReception,
        id: undefined,
        site: undefined,
      })) || [],
    siteTaxes:
      site?.siteTaxes?.map((siteTaxes) => ({
        ...siteTaxes,
        id: undefined,
        site: undefined,
      })) || [],
    policies:
      site?.policies?.map((policies) => ({
        ...policies,
        id: undefined,
        site: undefined,
      })) || [],
    accessRoutes:
      site?.accessRoutes?.map((accessRoutes) => ({
        ...accessRoutes,
        id: undefined,
        site: undefined,
      })) || [],
  };
}
