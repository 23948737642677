import { Injectable } from '@angular/core';
import { BASE_API_URL, ILatLon, IRoutePoint, IRouteResponse } from '@curbnturf/entities';
import { CachedHttpClient } from '@curbnturf/network';
import { Observable } from 'rxjs';

const API_URL = BASE_API_URL + 'route';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  constructor(private http: CachedHttpClient) {}

  fetchRoute(points: IRoutePoint[], corridorWidth: number): Observable<IRouteResponse> {
    const waypoints: ILatLon[] = points.map((el) => el.coordinates);

    return this.http.get<IRouteResponse>(`${API_URL}?waypoints=${JSON.stringify(waypoints)}&width=${corridorWidth}`);
  }
}
