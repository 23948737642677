<ion-header>
  <ion-toolbar>
    <ion-title>Conversation with {{ channel?.getOtherName(currentUser) }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-no-padding">
  <ng-container *ngIf="channel">
    <curbnturf-message-chat-box [channel]="channel" [reservation]="reservation"></curbnturf-message-chat-box>
  </ng-container>
  <ng-container *ngIf="!channel && !reservation">
    <div class="loading-message">
      <h3>Loading Conversation...</h3>
    </div>
  </ng-container>
</ion-content>

<ion-footer>
  <form [formGroup]="messageForm" name="sendForm" (ngSubmit)="send(messageBody)">
    <div class="sending">
      <input
        formControlName="messageBody"
        id="message-body"
        name="message-body"
        type="text"
        class="text-input w-input"
        required
        placeholder="Type a message"
        controller
      />
      <button
        class="cnt-button w-inline-block"
        type="submit"
        [disabled]="messageBody.invalid"
        [class.disabled]="messageBody.invalid"
      >
        <div class="cnt-button-copy">
          <i class="icon-cnt-paper-plane"></i>
        </div>
      </button>
    </div>
  </form>
</ion-footer>
