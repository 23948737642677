import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertFacade } from '@curbnturf/alert/src/lib/+state/alert.facade';
import { AuthFacade } from '@curbnturf/auth/src/lib/+state/auth.facade';
import {
  DisplayValidationErrorsDirective,
  DisplayValidationErrorsSubmitButtonDirective,
  DisplayValidationErrorsSubmitDirective,
  equalTo,
} from '@curbnturf/form-helpers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'curbnturf-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
  standalone: true,
  imports: [
    DisplayValidationErrorsSubmitDirective,
    DisplayValidationErrorsDirective,
    DisplayValidationErrorsSubmitButtonDirective,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class NewPasswordComponent {
  newPasswordForm: FormGroup<{ password?: FormControl<string | null>; repeatPassword?: FormControl<string | null> }>;

  subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private alertFacade: AlertFacade,
    private authFacade: AuthFacade,
  ) {
    const password = fb.control('', [
      Validators.required,
      Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})'),
    ]);
    const repeatPassword = fb.control('', [Validators.required, equalTo(password)]);

    this.newPasswordForm = this.fb.group({});
    this.newPasswordForm.addControl('password', password);
    this.newPasswordForm.addControl('repeatPassword', repeatPassword);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public resetPassword(event: Event) {
    event.preventDefault();
    if (this.newPasswordForm.valid) {
      const password = this.newPasswordForm.get('password')?.value || '';
      const passwordConfirm = this.newPasswordForm.get('repeatPassword')?.value || '';

      if (password !== passwordConfirm) {
        return this.alertFacade.display({
          title: 'Error Setting Password',
          body: 'Password and Password Confirmation must match.',
          level: 'error',
        });
      }

      this.authFacade.newPassword(password, true);
    }
  }
}
