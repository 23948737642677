import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'trim',
    standalone: true,
})
export class TrimPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): string {
    try {
      value.toString();
      return value.trim();
    } catch (e) {
      return '';
    }
  }
}
