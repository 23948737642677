import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalSpinnerService {
  spinnerSubject = new BehaviorSubject(false);
  spinner$: Observable<boolean>;

  constructor() {
    this.spinner$ = this.spinnerSubject.asObservable();
  }

  spinnerOn() {
    this.spinnerSubject.next(true);
  }

  spinnerOff() {
    this.spinnerSubject.next(false);
  }
}
