import { ILandOwner } from '../interfaces';
import { Address } from './address';
import { Site } from './site';

export class LandOwner implements ILandOwner {
  id: number;
  firstName?: string;
  lastName?: string;
  address?: Address;
  phone?: string;
  site?: Site;
  self?: boolean;
  private?: boolean;
  represent?: boolean;
  verified?: boolean; // Only admin should modify.

  static isEqual(a: ILandOwner, b: ILandOwner): boolean {
    return (
      a &&
      b &&
      a.id === b.id &&
      a.self === b.self &&
      a.private === b.private &&
      a.firstName === b.firstName &&
      a.lastName === b.lastName &&
      a.represent === b.represent &&
      Address.isEqual(a.address, b.address)
    );
  }

  static getName(landOwner: ILandOwner): string {
    if (!landOwner) {
      return 'No nme provided';
    }
    if (landOwner.firstName && landOwner.lastName) {
      return `${landOwner.firstName} ${landOwner.lastName}`;
    }
    if (landOwner.firstName) {
      return `${landOwner.firstName}`;
    }
    if (landOwner.lastName) {
      return `${landOwner.lastName}`;
    }
    return 'No name provided';
  }

  static getShortName(landOwner: ILandOwner): string {
    if (!landOwner) {
      return 'No name provided';
    }
    if (landOwner.firstName && landOwner.lastName) {
      return `${landOwner.firstName} ${landOwner.lastName.charAt(0)}.`;
    }
    if (landOwner.firstName) {
      return `${landOwner.firstName}`;
    }
    if (landOwner.lastName) {
      return `${landOwner.lastName}`;
    }
    return 'No name provided';
  }

  constructor(data?: ILandOwner) {
    if (data) {
      this.setData(data);
    }
  }

  getName(): string {
    return LandOwner.getName(this);
  }

  getShortName(): string {
    return LandOwner.getShortName(this);
  }

  setData(data: ILandOwner): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key) && key !== 'landOwner') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    this.address = new Address(this.address);
  }
}
