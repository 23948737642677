import { Injectable } from '@angular/core';
import {
  BASE_API_URL,
  IAmenity,
  IMapPoint,
  IPhoto,
  IPolicy,
  ISignalReception,
  ISite,
  ISiteWhere,
  IUser,
} from '@curbnturf/entities';
import { objectToUrlParams } from '@curbnturf/helpers';
import { CachedHttpClient } from '@curbnturf/network';
import { SitePhotosDbSet } from '@curbnturf/network/src/lib/native/dbset/site-photos.dbset';
import { SiteDbSet } from '@curbnturf/network/src/lib/native/dbset/site.dbset';
import { Observable, of, switchMap } from 'rxjs';
import { newSite } from './site-helpers';

const API_URL = BASE_API_URL + 'site';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  constructor(
    private http: CachedHttpClient,
    private siteDbSet: SiteDbSet,
    private sitePhotosDbSet: SitePhotosDbSet,
  ) {}

  create(site: ISite): Observable<ISite> {
    return this.http.post<ISite>(API_URL, this.formatForServer(site));
  }

  createAmenity(site: ISite): Observable<ISite> {
    const amenities: IAmenity[] = [];
    if (site.amenities) {
      amenities.push(...site.amenities);
    }

    amenities.push({ site });

    return this.update({ ...site, amenities });
  }

  createPolicy(site: ISite): Observable<ISite> {
    const policies: IPolicy[] = [];
    if (site.policies) {
      policies.push(...site.policies);
    }

    policies.push({ site });

    return this.update({ ...site, policies });
  }

  createSignalReception(site: ISite): Observable<ISite> {
    const signalReception: ISignalReception[] = [];
    if (site.signalReception) {
      signalReception.push(...site.signalReception);
    }

    signalReception.push({ site });

    return this.update({
      ...site,
      signalReception,
    });
  }

  fetch(siteId: number): Observable<ISite> {
    return this.http.get<ISite>(`${API_URL}/${siteId}`);
  }

  fetchAll(
    query: ISiteWhere,
    sort: string = 'id',
    page: number = 1,
    pageSize: number = 100,
  ): Observable<{ total: number; page: number; sites: ISite[] }> {
    const queryString = objectToUrlParams({ ...query, sort, page, pageSize });
    return this.http.get<{ total: number; page: number; sites: ISite[] }>(`${API_URL}?${queryString}`);
  }

  remove(siteId: number): Observable<ISite> {
    return this.http.delete<ISite>(`${API_URL}/${siteId}`);
  }

  restore(siteId: number): Observable<ISite> {
    return this.http.post<ISite>(`${API_URL}/${siteId}/restore`, null);
  }

  removeAmenity(site: ISite, amenity: IAmenity): Observable<ISite> {
    if (!site.amenities) {
      return of(site);
    }

    const amenities = [...site.amenities];

    const index = amenities.indexOf(amenity);

    if (index || index === 0) {
      amenities.splice(index, 1);
    }

    return this.update({ ...site, amenities });
  }

  removePolicy(site: ISite, policy: IPolicy): Observable<ISite> {
    if (!site.policies) {
      return of(site);
    }
    const policies = [...site.policies];

    const index = policies.findIndex((indexedPolicy) => indexedPolicy.id === policy.id);

    if (index >= 0) {
      policies.splice(index, 1);
    }

    return this.update({ ...site, policies });
  }

  removeSignalReception(site: ISite, signalReception: ISignalReception): Observable<ISite> {
    if (!site.signalReception) {
      return of(site);
    }

    const signalReceptions = [...site.signalReception];

    const index = signalReceptions.indexOf(signalReception);

    if (index || index === 0) {
      signalReceptions.splice(index, 1);
    }

    return this.update({
      ...site,
      signalReception: signalReceptions,
    });
  }

  upsert(site: ISite): Observable<ISite> {
    if (site.id) {
      return this.update(site);
    } else {
      return this.create(newSite(site));
    }
  }

  update(site: ISite): Observable<ISite> {
    if (!site.id) {
      throw new Error('Missing Site Identifier');
    }
    return this.http.put<ISite>(`${API_URL}/${site.id}`, this.formatForServer(site));
  }

  enable(site: ISite): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${site.id}/enable`, null);
  }

  enableAndHide(site: ISite): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${site.id}/enableAndHide`, null);
  }

  disable(site: ISite): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${site.id}/disable`, null);
  }

  hide(site: ISite): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${site.id}/hide`, null);
  }

  show(site: ISite): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${site.id}/show`, null);
  }

  removeImage(siteId: number, photoIndex: number): Observable<IPhoto[]> {
    return this.http.delete<IPhoto[]>(`${API_URL}/${siteId}/remove-image/${photoIndex}`);
  }

  uploadImage(siteId: number, formData: FormData): Observable<{ id: number; photos: IPhoto[] }> {
    return this.http.post<{ id: number; photos: IPhoto[] }>(`${API_URL}/${siteId}/upload-image`, formData);
  }

  uploadImages(siteIdData: { [siteId: string]: FormData[] }): Observable<{ [siteId: string]: IPhoto[] }> {
    const siteIds = Object.keys(siteIdData).map((key) => parseInt(key, 10));
    const results: { [siteId: string]: IPhoto[] } = {};

    // make a non-readonly version
    const data = siteIds.reduce(
      (hash, siteId) => {
        hash[siteId] = [...siteIdData[siteId]];

        return hash;
      },
      {} as { [siteId: string]: FormData[] },
    );

    const pushNext$ = (): Observable<{ [siteId: string]: IPhoto[] }> => {
      if (siteIds[0] && data[siteIds[0]]?.length === 0) {
        siteIds.shift();
      }

      if (siteIds.length > 0) {
        const siteId = siteIds[0];

        if (siteId && data[siteId]?.length > 0) {
          const sitePhoto = data[siteId].shift();

          if (sitePhoto) {
            return this.uploadImage(siteId, sitePhoto).pipe(
              switchMap((result) => {
                results[result.id] = result.photos;
                console.log('SiteService.pushNext.uploadImage', result);
                return pushNext$();
              }),
            );
          }
        } else if (siteId && data[siteId]?.length === 0) {
          return pushNext$();
        }
      }

      return of(results);
    };

    return pushNext$();
  }

  favorite(point: IMapPoint): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${point.id}/favorite`, null);
  }

  unfavorite(point: IMapPoint): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${point.id}/unfavorite`, null);
  }

  /**
   * Retrieves owned sites from the local SQL database
   * @param {IUser} user
   */
  async retrieveLocalOwnedSites(user: IUser): Promise<ISite[]> {
    const sites = await this.siteDbSet.retrieveOwnSites(user?.id);
    const siteIds: number[] = sites.filter((el) => el !== undefined).map((el) => el.id as number);

    // Get the photo records
    const photoResults = await this.sitePhotosDbSet.retrieveBatch(siteIds);
    sites.forEach((site) => {
      site.photos = [...photoResults.filter((photo) => photo.site_id === site.id)];
    });

    return sites;
  }

  private formatForServer(site: ISite): ISite {
    return { ...site };
  }
}
