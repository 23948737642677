import { Injectable } from '@angular/core';
import { SafariViewController, SafariViewControllerOptions } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { Subscription } from 'rxjs';


/**
 * This ends up being a pass-through service for working with the Safari View Controller Cordova plugin.
 */
@Injectable({
  providedIn: 'root',
})
export class SafariViewWrapperService {
  private safariViewController: SafariViewController;
  constructor() {
    this.create();
  }

  async create(): Promise<SafariViewController> {
    this.safariViewController = new SafariViewController();
    await this.safariViewController.connectToService();
    return this.safariViewController;
  }

  async get(): Promise<SafariViewController> {
    if (!this.safariViewController) {
      return this.create();
    }
    return this.safariViewController;
  }

  async show(options?: SafariViewControllerOptions): Promise<any> {
    return (await this.get()).show(options);
  }

  async closeAll(): Promise<any> {
    return (await this.get()).hide();
  }

  async isAvailable(): Promise<boolean> {
    return (await this.get()).isAvailable();
  }

  async launchBrowserUI(url: string, next?: (data:any) => any): Promise<Subscription> {
    return (await this.get())
      .show({
        url,
        hidden: false,
        animated: false,
        transition: 'curl',
        enterReaderModeIfAvailable: true,
        tintColor: '#ff0000',
      })
      .subscribe({
        next,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: (error: any) => {
          console.error('SafariViewController Error', error);
        },
      });
  }
}
