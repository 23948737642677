import { IUser } from '@curbnturf/entities';
import { Action } from '@ngrx/store';

export enum AddressBookActionTypes {
  LoadContacts = '[AddressBook] Load Contacts',
  ContactsLoaded = '[AddressBook] Contacts Loaded',
  LoadContactsError = '[AddressBook] Load Contacts Error',
  ContactCreated = '[AddressBook] Contact Created',
  SyncContact = '[AddressBook] Sync Contact',
}

export class LoadContacts implements Action {
  readonly type = AddressBookActionTypes.LoadContacts;
}

export class ContactsLoaded implements Action {
  readonly type = AddressBookActionTypes.ContactsLoaded;
  constructor(public payload: IUser[]) {}
}

export class LoadContactsError implements Action {
  readonly type = AddressBookActionTypes.LoadContactsError;
  constructor(public payload: any) {}
}

export class ContactCreated implements Action {
  readonly type = AddressBookActionTypes.ContactCreated;
  constructor(public payload: IUser) {}
}

export class SyncContact implements Action {
  readonly type = AddressBookActionTypes.SyncContact;
  constructor(public payload: { response?: IUser; request?: IUser; error?: any }) {}
}

export type AddressBookAction = LoadContacts | ContactsLoaded | LoadContactsError | ContactCreated | SyncContact;

export const fromAddressBookActions = {
  LoadContacts,
  ContactsLoaded,
  LoadContactsError,
  ContactCreated,
  SyncContact,
};
