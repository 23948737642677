import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { MessageChatBoxComponent } from '@curbnturf/message-display/src/lib/message-chat-box/message-chat-box.component';
import { MessageModalComponent } from '@curbnturf/message-display/src/lib/message-modal/message-modal.component';
import { NetworkModule } from '@curbnturf/network';
import { StatusFacade } from '@curbnturf/status';
import { IonicModule } from '@ionic/angular';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as messageEffects from './+state/message.effects';
import { initialState as messageInitialState, reducer as messageReducer } from './+state/message.reducer';

@NgModule({
  imports: [
    CommonModule,
    MessageModalComponent,
    NetworkModule,
    IonicModule,
    MessageChatBoxComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    StatusFacade,
    provideState(NGRX_BUCKETS.message, messageReducer, {
      initialState: messageInitialState,
    }),
    provideEffects(messageEffects),
  ],
})
export class MessageModule {}
