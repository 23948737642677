import { NgModule } from '@angular/core';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { StatusFacade } from '@curbnturf/status';
import { IonicModule } from '@ionic/angular';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as authModalEffects from './+state/auth-modal.effects';
import { reducer as authModalReducer } from './+state/auth-modal.reducer';

@NgModule({
  imports: [IonicModule],
  providers: [StatusFacade, provideState(NGRX_BUCKETS.authModal, authModalReducer), provideEffects(authModalEffects)],
})
export class AuthModalModule {}
