export enum ActivityType {
  biking = 'Biking',
  boating = 'Boating',
  fishing = 'Fishing',
  golf = 'Golf',
  hiking = 'Hiking',
  horseback = 'Horseback Riding',
  hunting = 'Hunting',
  kayaking = 'Kayaking',
  motorSports = 'Motor Sports',
  ohv = 'OHV Trails',
  rafting = 'Rafting',
  rockClimbing = 'Rock Climbing',
  snowSports = 'Snow Sports',
  surfing = 'Surfing',
  swimming = 'Swimming',
  waterSports = 'Water Sports',
  wildlifeWatching = 'Wildlife Watching',
  windSports = 'Wind Sports',
  custom = 'Custom',
}

export interface IActivityType {
  type:
    | 'biking'
    | 'boating'
    | 'fishing'
    | 'golf'
    | 'hiking'
    | 'horseback'
    | 'hunting'
    | 'kayaking'
    | 'motorSports'
    | 'ohv'
    | 'rafting'
    | 'rockClimbing'
    | 'snowSports'
    | 'surfing'
    | 'swimming'
    | 'waterSports'
    | 'wildlifeWatching'
    | 'windSports'
    | 'custom';
}

export const ActivityTypes = [
  'biking',
  'boating',
  'fishing',
  'golf',
  'hiking',
  'horseback',
  'hunting',
  'kayaking',
  'motorSports',
  'ohv',
  'rafting',
  'rockClimbing',
  'snowSports',
  'surfing',
  'swimming',
  'waterSports',
  'wildlifeWatching',
  'windSports',
  'custom',
];
