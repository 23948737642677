import { Injectable } from '@angular/core';
import { NetworkService } from '@curbnturf/network';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StorageActionTypes } from './storage.actions';
import { IStorageState } from './storage.interface';
import { storageQuery } from './storage.selectors';

@Injectable({
  providedIn: 'root',
})
export class StorageFacade {
  hydrated$: Observable<boolean>;

  constructor(private store: Store<IStorageState>, private networkService: NetworkService) {
    this.hydrated$ = this.store.pipe(select(storageQuery.getHydrated));
  }

  clear() {
    this.store.dispatch({ type: StorageActionTypes.Clear });
    this.networkService.getCurrentNetworkStatus().then(/**/);
  }
}
