import { ISiteFavorite } from '../interfaces/site-favorite';
import { MapPoint } from './map-point';
import { Site } from './site';
import { User } from './user';

export class SiteFavorite implements ISiteFavorite {
  id?: number;
  userId: number;
  user?: User;
  siteId: number;
  site?: Site;
  mapPoint?: MapPoint;

  constructor(data?: ISiteFavorite) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: ISiteFavorite): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (data.mapPoint) {
      this.mapPoint = new MapPoint(data.mapPoint);
    }
  }
}
