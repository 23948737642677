export const ActivityLocationSearchActivities = [
  'biking',
  'boating',
  'fishing',
  'golf',
  'hiking',
  'horseback',
  'hunting',
  'kayaking',
  'motorSports',
  'ohv',
  'rafting',
  'rockClimbing',
  'snowSports',
  'surfing',
  'swimming',
  'waterSports',
  'wildlifeWatching',
  'windSports',
];

export interface IActivityLocationSelected {
  activity?: boolean;
  dump?: boolean;
  restArea?: boolean;
  wifi?: boolean;
  potableWater?: boolean;
  propane?: boolean;
  hide?: boolean;
}

export interface IActivityLocationSearchRequest {
  available?: boolean;
  hidden?: boolean;
  deleted?: boolean;
  selected?: IActivityLocationSelected;
  activities?: string[];
  seLat?: number;
  seLon?: number;
  nwLat?: number;
  nwLon?: number;
  userId?: number;
  lat?: number;
  lon?: number;
  distance?: number;
  searchAsAnd?: boolean;
}
