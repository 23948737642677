import { NgModule } from '@angular/core';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as siteEffects from './+state/site.effects';
import { initialState as siteInitialState, siteReducer } from './+state/site.reducer';

@NgModule({
  providers: [
    provideState(NGRX_BUCKETS.site, siteReducer, {
      initialState: siteInitialState,
    }),
    provideEffects(siteEffects),
  ],
})
export class SiteModule {}
