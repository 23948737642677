import { IPrivacy } from '../interfaces';

export class Privacy implements IPrivacy {
  secluded: boolean;
  home: boolean;
  neighbor: boolean;
  others: boolean;

  constructor(data?: IPrivacy) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IPrivacy): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
