import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as userEffects from './+state/user.effects';
import { initialState, userReducer } from './+state/user.reducer';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    provideState(NGRX_BUCKETS.user, userReducer, {
      initialState,
    }),
    provideEffects(userEffects),
  ],
})
export class UserModule {}
