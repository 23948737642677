import { Action } from '@ngrx/store';

export enum UrlControlActionTypes {
  SetRedirectUrl = '[UrlControl] Set Redirect URL',
  SetReferenceId = '[UrlControl] Set Reference ID',
}

export class SetRedirectUrl implements Action {
  readonly type = UrlControlActionTypes.SetRedirectUrl;
  constructor(public payload?: string) {}
}

export class SetReferenceId implements Action {
  readonly type = UrlControlActionTypes.SetReferenceId;
  constructor(public payload?: string) {}
}

export type UrlControlAction = SetRedirectUrl | SetReferenceId;

export const fromUrlControlActions = {
  SetRedirectUrl,
  SetReferenceId,
};
