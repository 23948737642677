<div class="messages">
  <div class="message" *ngFor="let message of channel?.messages" [ngClass]="getMessageDirection(message)">
    <p>
      {{ message.from.firstName }}: {{ message.body }}<br />
      <span class="time_date">
        {{ message.timestamp * 1000 | date : 'short' }}
      </span>
    </p>
  </div>
</div>
