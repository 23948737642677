import { NgModule } from '@angular/core';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as ratingEffects from './+state/rating.effects';
import { initialState as ratingInitialState, ratingReducer } from './+state/rating.reducer';

@NgModule({
  providers: [
    provideState(NGRX_BUCKETS.rating, ratingReducer, {
      initialState: ratingInitialState,
    }),
    provideEffects(ratingEffects),
  ],
})
export class RatingModule {}
