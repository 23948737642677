import { Injectable } from '@angular/core';
import { ConnectionStatus } from '@capacitor/network';
import { CachableHttpRequest, CachedHttpResponse } from '@curbnturf/entities';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as NetworkActions from './network.actions';
import * as NetworkSelectors from './network.selectors';

@Injectable({
  providedIn: 'root',
})
export class NetworkFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  // if not in the network library should use StatusFacade.networkStatus$ instead
  status$: Observable<ConnectionStatus>;
  cachedHttpRequests$: Observable<CachableHttpRequest[]>;
  cachedHttpResponses$: Observable<CachedHttpResponse[]>;
  networkOverride$: Observable<boolean>;

  constructor(private store: Store) {
    this.status$ = this.store.pipe(select(NetworkSelectors.getStatus));
    this.cachedHttpRequests$ = this.store.pipe(select(NetworkSelectors.getCachedHttpRequests));
    this.cachedHttpResponses$ = this.store.pipe(select(NetworkSelectors.getCachedHttpResponses));
    this.networkOverride$ = this.store.pipe(select(NetworkSelectors.getNetworkOverride));
  }

  setNetworkStatus(status: ConnectionStatus): void {
    this.store.dispatch(NetworkActions.setNetworkStatus({ status }));
  }

  public cacheHttpRequest(request: CachableHttpRequest) {
    this.store.dispatch(NetworkActions.cacheHttpRequest({ request }));
  }

  public cacheHttpResponse(response: CachedHttpResponse) {
    this.store.dispatch(NetworkActions.cacheHttpResponse({ response }));
  }

  public restoreCachedHttpResponses(responses: CachedHttpResponse[]) {
    this.store.dispatch(NetworkActions.restoreCachedResponses({ responses }));
  }

  public setOverrideStatus(override: boolean, status: ConnectionStatus) {
    this.store.dispatch(NetworkActions.setNetworkOverride({ override, status }));
  }

  public flushCachedRequests(status: ConnectionStatus) {
    this.store.dispatch(NetworkActions.flushCachedRequests({ status }));
  }

  public retrieveCachedRequests(limit: number = 20, take = 0) {
    this.store.dispatch(NetworkActions.retrieveCachedRequests({ limit, take }));
  }
}
