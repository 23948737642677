import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthFacade } from '@curbnturf/auth';
import { LoginComponent } from '@curbnturf/auth-components/src/lib/login/login.component';
import { NewPasswordComponent } from '@curbnturf/auth-components/src/lib/new-password/new-password.component';
import { RecoverComponent } from '@curbnturf/auth-components/src/lib/recover/recover.component';
import { RegisterComponent } from '@curbnturf/auth-components/src/lib/register/register.component';
import { Role } from '@curbnturf/entities';
import { StatusFacade } from '@curbnturf/status';
import { IonicModule, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthModalFacade } from '../+state/auth-modal.facade';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { NewPasswordModalComponent } from '../new-password-modal/new-password-modal.component';
import { RecoverModalComponent } from '../recover-modal/recover-modal.component';
import { RegisterModalComponent } from '../register-modal/register-modal.component';

@Component({
  selector: 'curbnturf-auth-modals',
  templateUrl: './auth-modals.component.html',
  styleUrls: ['./auth-modals.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    LoginComponent,
    LoginModalComponent,
    NewPasswordComponent,
    NewPasswordModalComponent,
    RecoverComponent,
    RecoverModalComponent,
    RegisterComponent,
    RegisterModalComponent,
  ],
})
export class AuthModalsComponent implements OnDestroy, OnInit {
  authModalSubscription: Subscription;
  onHideSubscription: Subscription;

  modal?: HTMLIonModalElement;

  loggedIn: boolean;
  role?: Role;

  private subscriptions: Subscription[] = [];

  constructor(
    private authFacade: AuthFacade,
    private modalController: ModalController,
    private modalFacade: AuthModalFacade,
    private statusFacade: StatusFacade,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.statusFacade.loggedIn$.subscribe((loggedIn) => {
        this.loggedIn = loggedIn;
      }),
      this.authFacade.tempRole$.subscribe((role) => (this.role = role)),
      this.modalFacade.modal$.subscribe(async (modalToOpen) => {
        switch (modalToOpen) {
          case 'login': {
            await this.modal?.dismiss('reopen');
            this.modal = await this.modalController.create({
              component: LoginModalComponent,
              componentProps: {
                inModal: true,
              },
              cssClass: 'auth-modal login',
            });
            await this.modal.present();
            break;
          }
          case 'newPassword': {
            await this.modal?.dismiss('reopen');
            this.modal = await this.modalController.create({
              component: NewPasswordModalComponent,
              cssClass: 'auth-modal new-password',
            });
            await this.modal.present();
            break;
          }
          case 'recover': {
            await this.modal?.dismiss('reopen');
            this.modal = await this.modalController.create({
              component: RecoverModalComponent,
              componentProps: {
                inModal: true,
              },
              cssClass: 'auth-modal recover',
            });
            await this.modal.present();
            break;
          }
          case 'register': {
            await this.modal?.dismiss('reopen');
            this.modal = await this.modalController.create({
              component: RegisterModalComponent,
              componentProps: {
                inModal: true,
              },
              cssClass: 'auth-modal register',
            });
            await this.modal.present();
            break;
          }
          case null:
          default: {
            if (this.modal) {
              await this.modal.dismiss('reopen');
              delete this.modal;
            }
          }
        }

        this.modal?.onDidDismiss().then((event) => {
          if (event?.data !== 'reopen') {
            this.modalFacade.openAuthModal();
          }
        });
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
