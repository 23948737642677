import { ILatLon, LatLon } from '@curbnturf/entities';
import { Action, createAction, props } from '@ngrx/store';

export enum LocationActionTypes {
  SelectLocation = '[Location] Select Location',
  FindCurrentLocation = '[Location] Find Current Location',
  CurrentLocationSuccess = '[Location] Find Current Location Success',
  CurrentLocationFailed = '[Location] Find Current Location Failed',
  ReverseGeocodeUserAddress = '[Location] Reverse Geocode User Address',
  ReverseGeocodeSuccess = '[Location] Reverse Geocode Success',
  ReverseGeocodeFailed = '[Location] Reverse Geocode Failed',
}

export const selectLocation = createAction(
  LocationActionTypes.SelectLocation,
  props<{ payload: { center: ILatLon; distance: number } }>(),
);

export class SelectLocation implements Action {
  readonly type = LocationActionTypes.SelectLocation;
  constructor(public payload: { center: ILatLon; distance: number }) {}
}

export const findCurrentLocation = createAction(
  LocationActionTypes.FindCurrentLocation,
  props<{ timeout?: number; highAccuracy?: boolean }>(),
);

export const currentLocationSuccess = createAction(
  LocationActionTypes.CurrentLocationSuccess,
  props<{ location: LatLon }>(),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const currentLocationFailed = createAction(LocationActionTypes.CurrentLocationFailed, props<{ error: any }>());

export const reverseGeocodeUserAddress = createAction(LocationActionTypes.ReverseGeocodeUserAddress);

export const reverseGeocodeSuccess = createAction(
  LocationActionTypes.ReverseGeocodeSuccess,
  props<{ location: LatLon }>(),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reverseGeocodeFailed = createAction(LocationActionTypes.ReverseGeocodeFailed, props<{ error: any }>());
