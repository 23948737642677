import { NgModule } from '@angular/core';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as signUpStateEffects from './+state/sign-up-state.effects';
import { initialState as signUpStateInitialState, reducer as signUpStateReducer } from './+state/sign-up-state.reducer';

@NgModule({
  providers: [
    provideState(NGRX_BUCKETS.signUpState, signUpStateReducer, {
      initialState: signUpStateInitialState,
    }),
    provideEffects(signUpStateEffects),
  ],
})
export class SignUpStateModule {}
