import { UrlControlAction, UrlControlActionTypes } from './url-control.actions';

export interface UrlControlState {
  redirectUrl?: string;
  referenceId?: string;
}

export const initialState: UrlControlState = {};

export function urlControlReducer(state: UrlControlState = initialState, action: UrlControlAction): UrlControlState {
  switch (action.type) {
    case UrlControlActionTypes.SetRedirectUrl: {
      state = {
        ...state,
        redirectUrl: action.payload,
      };
      break;
    }
    case UrlControlActionTypes.SetReferenceId: {
      state = {
        ...state,
        referenceId: action.payload,
      };
      break;
    }
    default:
  }
  return state;
}
