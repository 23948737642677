export enum RvTypes {
  classA = 'Class A',
  classB = 'Class B',
  classC = 'Class C',
  fifthWheel = 'Fifth-Wheel',
  travelTrailer = 'Travel Trailer',
  truckCamper = 'Truck Camper',
  teardrop = 'Teardrop',
  popup = 'Popup Camper',
}
