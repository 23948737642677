import { Component } from '@angular/core';
import { LoginComponent } from '@curbnturf/auth-components/src/lib/login/login.component';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'curbnturf-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  standalone: true,
  imports: [IonicModule, LoginComponent],
})
export class LoginModalComponent {}
