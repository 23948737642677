<div class="selection-block">
  <div class="icon-subtraction" (click)="decrease()" [class.disabled]="min !== undefined && value < min + increment">
    <div class="html-embed w-embed">
      <curbnturf-misc-svg icon="minusCircle"></curbnturf-misc-svg>
    </div>
  </div>
  <div class="value-wrapper">
    <strong>
      {{ value ? value : 0 }}
    </strong>
  </div>
  <div class="icon-addition" (click)="increase()" [class.disabled]="max !== undefined && value > max - increment">
    <div class="html-embed w-embed">
      <curbnturf-misc-svg icon="plusCircle"></curbnturf-misc-svg>
    </div>
  </div>
</div>
