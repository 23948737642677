import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: '[ngbDatepickerDayView]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./datepicker-day-view.scss'],
  host: {
    class: 'btn-light',
    '[class.bg-primary]': 'selected',
    '[class.text-white]': 'selected',
    '[class.text-muted]': 'isMuted()',
    '[class.outside]': 'isMuted()',
    '[class.active]': 'focused',
  },
  template: `{{ date.day }}`,
  standalone: true,
})
export class DatepickerDayView {
  @Input() currentMonth: number;
  @Input() date: DateTime;
  @Input() disabled: boolean;
  @Input() focused: boolean;
  @Input() selected: boolean;

  isMuted() {
    return !this.selected && (this.date.month !== this.currentMonth || this.disabled);
  }
}
