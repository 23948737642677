import { Injectable } from '@angular/core';
import { capSQLiteChanges, capSQLiteValues } from '@capacitor-community/sqlite';
import {
  ActivityLocation,
  DEFAULT_MAP_LATITUDE,
  DEFAULT_MAP_LONGITUDE,
  DEFAULT_MAP_VIEWPORT_DISTANCE,
  IActivityLocation,
  IListingSearchQuery,
  LatLon,
  PoiFavorite,
} from '@curbnturf/entities';
import { StatusFacade } from '@curbnturf/status';
import { firstValueFrom, of } from 'rxjs';
import { Logger } from '../../log/logger';
import { DatabaseService } from '../database.service';
import { IDbSet } from '../dbset.interface';

const TABLE_NAME = 'points_of_interest';

@Injectable({
  providedIn: 'root',
})
export class PoiListingDbSet implements IDbSet {
  public static readonly poiFields = [
    'id',
    'category_dump',
    'category_rest_area',
    'category_wifi',
    'category_potable_water',
    'category_propane',
    'activity_biking',
    'activity_boating',
    'activity_fishing',
    'activity_golf',
    'activity_hiking',
    'activity_horseback',
    'activity_hunting',
    'activity_kayaking',
    'activity_motor_sports',
    'activity_ohv',
    'activity_rafting',
    'activity_rock_climbing',
    'activity_snow_sports',
    'activity_surfing',
    'activity_swimming',
    'activity_water_sports',
    'activity_wildlife_watching',
    'activity_wind_sports',
    'custom_activity',
    'poi_name',
    'description',
    'address',
    'city',
    'state',
    'zipcode',
    'lat',
    'lng',
    'user_id',
    'user_first_name',
    'user_last_name',
    'url',
    'phone',
    'favorite',
    'ratings',
  ];

  userId?: number;

  constructor(
    private databaseService: DatabaseService,
    private logger: Logger,
    private statusFacade: StatusFacade,
  ) {
    this.statusFacade.userId$.subscribe((userId) => {
      this.userId = userId;
    });
  }

  public async store(request: IActivityLocation): Promise<capSQLiteValues> {
    return this.databaseService.insert(TABLE_NAME, this.mapObjectsToStorable([request]).pop());
  }

  public async storeArray(requests: IActivityLocation[], ignoreDuplicates: boolean = false): Promise<capSQLiteValues> {
    return this.databaseService.insertMulti(
      TABLE_NAME,
      this.mapObjectsToStorable(requests),
      PoiListingDbSet.poiFields,
      ignoreDuplicates,
    );
    return {};
  }

  public async truncateAndStoreArray(
    records: IActivityLocation[],
    ignoreDuplicates: boolean = false,
  ): Promise<capSQLiteValues> {
    await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
    return this.storeArray(records, ignoreDuplicates);
  }

  public async truncate(): Promise<capSQLiteChanges> {
    return await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
  }

  public async retrieve(id: number): Promise<{ values: IActivityLocation[] }> {
    const results = await this.databaseService.select(TABLE_NAME, { id });
    return {
      values: this.mapResultsObject(results),
    };
  }

  public async retrieveAll(limit?: number): Promise<IActivityLocation[]> {
    const results = await this.databaseService.selectAll(TABLE_NAME, undefined, limit);
    return this.mapResultsObject(results);
  }

  public async retrieveOwnSites(userId?: number): Promise<IActivityLocation[]> {
    if (userId) {
      const results = await this.databaseService.select(TABLE_NAME, { user_id: userId });
      return this.mapResultsObject(results);
    } else {
      return firstValueFrom(of([]));
    }
  }

  public async retrieveFiltered(
    query: IListingSearchQuery,
    take: number = 1000,
    skip: number = 0,
  ): Promise<IActivityLocation[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const values: any[] = [];
    let statement = `SELECT * FROM ${TABLE_NAME} WHERE `;

    const wheres: string[] = [];

    // String Filter
    if (query.string) {
      wheres.push('(name LIKE ? OR location LIKE ?)');
      values.push('%' + query.string + '%');
      values.push('%' + query.string + '%');
    }

    // Activity Filter
    if (query.activities && query.activities.length > 0) {
      let clause = '(';
      query.activities.forEach((activity) => {
        const subClause = `activity_${this.camelToSnakeCase(activity as string)} = ? OR `;
        clause += subClause;
        values.push(1);
      });

      if (clause.length > 2) {
        clause = clause.substr(0, clause.length - 4) + ')';
        wheres.push(clause);
      }
    }

    // Category Filter
    if (query.poiTypes && query.poiTypes.length > 0) {
      const index = query.poiTypes.findIndex((el) => el === 'activity');
      if (index !== -1) {
        query.poiTypes.splice(index, 1);
      }

      let clause = '(';
      query.poiTypes.forEach((category) => {
        const subClause = `category_${this.camelToSnakeCase(category)} = ? OR `;
        clause += subClause;
        values.push(1);
      });

      if (clause.length > 2) {
        clause = clause.substr(0, clause.length - 4) + ')';
        wheres.push(clause);
      }
    }

    // Viewport Filter
    const { nwPoint, sePoint } = this.getViewportPoints(query);

    if (nwPoint && sePoint) {
      let clause = 'lat < ? AND lng > ?';
      values.push(nwPoint.lat, nwPoint.lon);
      clause += 'AND lat > ? AND lng < ?';
      values.push(sePoint.lat, sePoint.lon);
      wheres.push(clause);
    }

    statement += wheres.join(' AND ');

    statement += ` LIMIT ? OFFSET ?;`;
    values.push(take, skip);

    this.logger.debug('Filtered SELECT query executing', { statement, values });

    return this.mapResultsObject(await this.databaseService.query(statement, values));
  }

  public async remove(id: string): Promise<capSQLiteValues> {
    return await this.databaseService.delete(TABLE_NAME, { id });
  }

  private mapResultsObject(results: capSQLiteValues): IActivityLocation[] {
    if (results.values) {
      return results.values?.map((record) => {
        const favoritedUsers = [];
        if (!!parseInt(record.favorite, 10) && this.userId) {
          favoritedUsers.push(new PoiFavorite({ userId: this.userId, poiId: 1 }));
        }

        return new ActivityLocation({
          coordinates: { lat: record.lat, lon: record.lng },
          categoryDump: !!parseInt(record.category_dump, 10),
          categoryRestArea: !!parseInt(record.category_rest_area, 10),
          categoryWifi: !!parseInt(record.category_wifi, 10),
          categoryPotableWater: !!parseInt(record.category_potable_water, 10),
          categoryPropane: !!parseInt(record.category_propane, 10),
          activityBiking: !!parseInt(record.activity_biking, 10),
          activityBoating: !!parseInt(record.activity_boating, 10),
          activityFishing: !!parseInt(record.activity_fishing, 10),
          activityGolf: !!parseInt(record.activity_golf, 10),
          activityHiking: !!parseInt(record.activity_hiking, 10),
          activityHorseback: !!parseInt(record.activity_horseback, 10),
          activityHunting: !!parseInt(record.activity_hunting, 10),
          activityKayaking: !!parseInt(record.activity_kayaking, 10),
          activityMotorSports: !!parseInt(record.activity_motor_sports, 10),
          activityOhv: !!parseInt(record.activity_ohv, 10),
          activityRafting: !!parseInt(record.activity_rafting, 10),
          activityRockClimbing: !!parseInt(record.activity_rock_climbing, 10),
          activitySnowSports: !!parseInt(record.activity_snow_sports, 10),
          activitySurfing: !!parseInt(record.activity_surfing, 10),
          activitySwimming: !!parseInt(record.activity_swimming, 10),
          activityWaterSports: !!parseInt(record.activity_water_sports, 10),
          activityWildlifeWatching: !!parseInt(record.activity_wildlife_watching, 10),
          activityWindSports: !!parseInt(record.activity_wind_sports, 10),
          customActivity: record.custom_activity,
          photos: [], // @todo load this from the point_of_interest_photos table
          name: record.poi_name,
          description: record.description,
          address: {
            address: record?.address,
            city: record?.city,
            state: record?.state,
            zipcode: record?.zipcode,
          },
          user: {
            firstName: record.user_first_name,
            lastName: record.user_last_name,
          }, // @todo at some point we could load up the user
          userId: record.user_id,
          url: record.url,
          phone: record.phone,
          favoritedUsers,
          ratings: [], // @todo find a good way to store ratings.
        });
      });
    } else {
      return [];
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private mapObjectsToStorable(items: IActivityLocation[]): any[] {
    if (items) {
      return items?.map((record: IActivityLocation) => {
        return {
          // Can't use spread operator here as it tries to enter null in undefined properties as columns
          id: record.id,
          category_dump: record.categoryDump ? 1 : 0,
          category_rest_area: record.categoryRestArea ? 1 : 0,
          category_wifi: record.categoryWifi ? 1 : 0,
          category_potable_water: record.categoryPotableWater ? 1 : 0,
          category_propane: record.categoryPropane ? 1 : 0,
          activity_biking: record.activityBiking ? 1 : 0,
          activity_boating: record.activityBoating ? 1 : 0,
          activity_fishing: record.activityFishing ? 1 : 0,
          activity_golf: record.activityGolf ? 1 : 0,
          activity_hiking: record.activityHiking ? 1 : 0,
          activity_horseback: record.activityHorseback ? 1 : 0,
          activity_hunting: record.activityHunting ? 1 : 0,
          activity_kayaking: record.activityKayaking ? 1 : 0,
          activity_motor_sports: record.activityMotorSports ? 1 : 0,
          activity_ohv: record.activityOhv ? 1 : 0,
          activity_rafting: record.activityRafting ? 1 : 0,
          activity_rock_climbing: record.activityRockClimbing ? 1 : 0,
          activity_snow_sports: record.activitySnowSports ? 1 : 0,
          activity_surfing: record.activitySurfing ? 1 : 0,
          activity_swimming: record.activitySwimming ? 1 : 0,
          activity_water_sports: record.activityWaterSports ? 1 : 0,
          activity_wildlife_watching: record.activityWildlifeWatching ? 1 : 0,
          activity_wind_sports: record.activityWindSports ? 1 : 0,
          custom_activity: record.customActivity,
          poi_name: record.name,
          description: record.description,
          address: record.address?.address,
          city: record.address?.city,
          state: record.address?.state,
          zipcode: record.address?.zipcode,
          lat: record.coordinates?.lat,
          lng: record.coordinates?.lon,
          user_id: record.userId,
          user_first_name: record.user?.firstName,
          user_last_name: record.user?.lastName,
          url: record.url,
          phone: record.phone,
          favorite: record.favoritedUsers?.find((el) => el.id === this.userId) ? 1 : 0,
          // ratings: null, @todo figure out a way to condense ratings.
        };
      });
    } else {
      return [];
    }
  }

  private getViewportPoints(query: IListingSearchQuery) {
    let nwPoint;
    let sePoint;
    if (query.lat && query.lon) {
      const point = new LatLon({ lat: query.lat, lon: query.lon });
      const distance = query.distance ? query.distance : DEFAULT_MAP_VIEWPORT_DISTANCE;
      const bearingDistance = Math.sqrt(2 * Math.pow(distance, 2));
      nwPoint = point.destinationPoint(bearingDistance, 315);
      sePoint = point.destinationPoint(bearingDistance, 135);
    } else {
      const point = new LatLon({ lat: DEFAULT_MAP_LATITUDE, lon: DEFAULT_MAP_LONGITUDE });
      const distance = query.distance ? query.distance : DEFAULT_MAP_VIEWPORT_DISTANCE;
      const bearingDistance = Math.sqrt(2 * Math.pow(distance, 2));
      nwPoint = point.destinationPoint(bearingDistance, 315);
      sePoint = point.destinationPoint(bearingDistance, 135);
    }

    return { nwPoint, sePoint };
  }

  private camelToSnakeCase(str: string) {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  }
}
