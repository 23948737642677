import { ComponentRef, Directive, Host, Inject, Input, OnDestroy, Optional, ViewContainerRef } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { camelToTitle } from '@curbnturf/entities';
import { EMPTY, merge, Observable, Subscription } from 'rxjs';
import { DisplayValidationErrorsSubmitDirective } from '../display-validation-errors-submit.directive';
import { FORM_ERRORS } from '../errors';
import { DisplayValidationErrorsContainerDirective } from './display-validation-errors-container.directive';
import { DisplayValidationErrorsComponent } from './display-validation-errors.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControl], [formControlName]',
  standalone: true,
})
export class DisplayValidationErrorsDirective implements OnDestroy {
  ref: ComponentRef<DisplayValidationErrorsComponent>;
  submit$: Observable<Event>;
  container: ViewContainerRef;
  @Input() customErrors = {};
  @Input() containerElement: DisplayValidationErrorsContainerDirective;

  get control() {
    return this.controlDir?.control;
  }

  private subscriptions: Subscription[] = [];

  constructor(
    private vcr: ViewContainerRef,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(FORM_ERRORS) private errors: any,
    @Optional() @Host() private form: DisplayValidationErrorsSubmitDirective,
    private controlDir: NgControl,
  ) {
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  ngOnInit() {
    this.container = this.containerElement ? this.containerElement.vcr : this.vcr;

    if (this.control) {
      this.subscriptions.push(
        merge(this.submit$, this.control.valueChanges).subscribe(() => {
          if (this.control && this.control.touched && (this.control.dirty || this.control.invalid)) {
            const controlErrors = this.control.errors;
            if (controlErrors) {
              const firstKey = Object.keys(controlErrors)[0];
              const getError = this.errors[firstKey];
              if (getError) {
                const text = getError(controlErrors[firstKey]);
                this.setError(text, this.getControlName(this.control) || '');
              }
            } else if (this.ref) {
              this.setError('', '');
            }
          }
        }),
      );
    }
  }

  setError(text: string, field: string) {
    if (!this.ref) {
      this.ref = this.container.createComponent(DisplayValidationErrorsComponent);
    }

    if (field) {
      // Custom field names
      // todo: think of a more generalized way to store this
      if (field === 'affiliateRef') {
        this.ref.instance.field = 'Custom CurbNTurf Code';
      } else {
        this.ref.instance.field = camelToTitle(field);
      }
    }

    this.ref.instance.text = text;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }

  private getControlName(formControl: AbstractControl): string | null {
    const formGroup = formControl?.parent?.controls as AbstractControl[];
    return (
      (formGroup &&
        Object.keys(formGroup).find((name) => {
          if (formGroup) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return formControl === formGroup[name as any];
          }

          return false;
        })) ||
      null
    );
  }
}
