import { DateTime } from 'luxon';
import { IListingType, RvTypes } from '../enums';
import { IActivity, IAmenity, IListingSearchQuery, IRV } from '../interfaces';

export class ListingSearchQuery implements IListingSearchQuery {
  string?: string;
  types?: IListingType['type'][];
  poiTypes?: string[];
  minPrice?: number;
  maxPrice?: number;
  amenities?: IAmenity['type'][];
  activities?: IActivity['type'][];
  activityDistance?: number;
  seLat?: number; // southeast latitude of corner of box to limit results to.
  seLon?: number; // southeast longitude of corner of box to limit results to.
  nwLat?: number; // northwest latitude of corner of box to limit results to.
  nwLon?: number; // northwest longitude of corner of box to limit results to.

  // these next three (lat, lon, distance) are ignored if seLat, seLon, nwLat, and nwLon are set
  lat?: number;
  lon?: number;
  distance?: number;

  startDate?: DateTime;
  endDate?: DateTime;
  myRv?: IRV;

  // flattened RV
  rvAge?: number;
  width?: number; // ft
  height?: number; // ft
  length?: number; // ft
  underwayWidth?: number; // ft
  underwayHeight?: number; // ft
  underwayLength?: number; // ft
  clearance?: number; // in
  rvType?: RvTypes;
  tents?: number;
  additionalVehicles?: number;

  static toQueryString(query: IListingSearchQuery): string {
    const queryArray: string[] = [];

    if (query.string) {
      queryArray.push(`string="${query.string}"`);
    }

    if (query.types && query.types.length > 0) {
      queryArray.push(`types=["${query.types.join('","')}"]`);
    }

    if (query.minPrice || query.minPrice === 0) {
      queryArray.push(`minPrice=${query.minPrice}`);
    }

    if (query.maxPrice || query.maxPrice === 0) {
      queryArray.push(`maxPrice=${query.maxPrice}`);
    }

    if (query.amenities && query.amenities.length > 0) {
      queryArray.push(`amenities=["${query.amenities.join('","')}"]`);
    }

    if (query.activities && query.activities.length > 0) {
      queryArray.push(`activities=["${query.activities.join('","')}"]`);

      if (query.activityDistance || query.activityDistance === 0) {
        queryArray.push(`activityDistance=${query.activityDistance}`);
      }
    }

    if (query.startDate) {
      const startDate = query.startDate.toISODate();
      queryArray.push(`startDate=${startDate}`);
    }

    if (query.endDate) {
      const endDate = query.endDate.toISODate();
      queryArray.push(`endDate=${endDate}`);
    }

    queryArray.push(...ListingSearchQuery.coordinatesToQueryString(query));

    const rvQuery = ListingSearchQuery.buildRVQuery(query.myRv);
    if (rvQuery) {
      queryArray.push(rvQuery);
    }

    if (query.tents) {
      queryArray.push(`tents=${query.tents}`);
    }

    if (query.additionalVehicles) {
      queryArray.push(`additionalVehicles=${query.additionalVehicles}`);
    }

    return queryArray.join('&');
  }

  private static coordinatesToQueryString(query: IListingSearchQuery): string[] {
    const queryArray: string[] = [];

    if (query.seLat) {
      queryArray.push(`seLat=${query.seLat}`);
    }

    if (query.seLon) {
      queryArray.push(`seLon=${query.seLon}`);
    }

    if (query.nwLat) {
      queryArray.push(`nwLat=${query.nwLat}`);
    }

    if (query.nwLon) {
      queryArray.push(`nwLon=${query.nwLon}`);
    }

    if (query.lat) {
      queryArray.push(`lat=${query.lat}`);
    }

    if (query.lon) {
      queryArray.push(`lon=${query.lon}`);
    }

    if (query.distance) {
      queryArray.push(`distance=${query.distance}`);
    }

    return queryArray;
  }

  private static buildRVQuery(rv?: IRV): string {
    if (!rv) {
      return '';
    }

    const rvArray: string[] = [];

    if (rv.id) {
      rvArray.push(`rvid=${rv.id}`);
    }

    if (rv.year) {
      rvArray.push(`rvAge=${DateTime.now().year - rv.year}`);
    }

    if (rv.width) {
      rvArray.push(`width=${rv.width}`);
    }

    if (rv.height) {
      rvArray.push(`height=${rv.height}`);
    }

    if (rv.length) {
      rvArray.push(`length=${rv.length}`);
    }

    if (rv.underwayWidth) {
      rvArray.push(`underwayWidth=${rv.underwayWidth}`);
    }

    if (rv.underwayHeight) {
      rvArray.push(`underwayHeight=${rv.underwayHeight}`);
    }

    if (rv.clearance) {
      rvArray.push(`clearance=${rv.clearance}`);
    }

    if (rv.type) {
      rvArray.push(`rvType=${rv.type}`);
    }

    return rvArray.join('&');
  }

  constructor(data?: IListingSearchQuery) {
    if (data) {
      this.setData(data);
    }
  }

  toString(): string {
    return ListingSearchQuery.toQueryString(this);
  }

  setData(data: IListingSearchQuery): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
