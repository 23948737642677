import { NGRX_BUCKETS, Schedule, User } from '@curbnturf/entities';
import * as UserSelectors from '@curbnturf/user/src/lib/+state/user.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ScheduleState } from './schedule.reducer';

// Lookup the 'Schedule' feature state managed by NgRx
const getScheduleState = createFeatureSelector<ScheduleState>(NGRX_BUCKETS.schedule);

const getLoaded = createSelector(getScheduleState, (state: ScheduleState) => state.loaded);
const getError = createSelector(getScheduleState, (state: ScheduleState) => state.error);

const getStateSchedules = (state: ScheduleState) => {
  if (state && state.schedules) {
    const instantiatedSchedules: { [id: string]: Schedule } = {};
    for (const key in state.schedules) {
      if (state.schedules.hasOwnProperty(key)) {
        instantiatedSchedules[key] = new Schedule(state.schedules[key]);
      }
    }

    return instantiatedSchedules;
  }

  return {};
};

const getScheduleEntities = createSelector(getScheduleState, getStateSchedules);

const getSchedules = createSelector(getScheduleEntities, (schedules): Schedule[] =>
  Object.keys(schedules).map<Schedule>((confirmationCode) => new Schedule(schedules[confirmationCode])),
);

const getCurrentUserSchedules = createSelector(
  getSchedules,
  UserSelectors.getCurrentUser,
  (schedules: Schedule[], user: User): Schedule[] =>
    schedules.filter((schedule) => schedule.email && schedule.email === user.email),
);

const getSelectedScheduleId = createSelector(getScheduleState, (state) => state && state.selected);

const getSelectedSchedule = createSelector(getScheduleEntities, getSelectedScheduleId, (schedules, selected) =>
  !selected ? null : new Schedule(schedules[selected]),
);

const getStoredSchedule = createSelector(getScheduleState, (state) =>
  !state || !state.storedSchedule ? null : new Schedule(state.storedSchedule),
);

const getDays = createSelector(getScheduleState, (state: ScheduleState) => {
  return state.days;
});

export const scheduleQuery = {
  getScheduleEntities,
  getSchedules,
  getCurrentUserSchedules,
  getError,
  getLoaded,
  getSelectedSchedule,
  getStoredSchedule,
  getDays,
};
