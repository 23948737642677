import * as ActivityActions from '@curbnturf/activity/src/lib/+state/activity.actions';
import { IPoiFavorite, ISiteFavorite, IUser, RV, User } from '@curbnturf/entities';
import * as SiteActions from '@curbnturf/site/src/lib/+state/site.actions';
import { createReducer, on } from "@ngrx/store";
import * as UserActions from './user.actions';

export interface UserState {
  users: { [id: number]: IUser }; // list of User; analogous to a sql normalized table
  selectedId?: number; // which User record has been selected
  currentId?: number; // the User which is logged in
  selectedRVId?: number; // the currently selected user RV
  selectedTaxId?: number; // the currently selected user Tax
  loaded: boolean; // has the User list been loaded
  error?: string; // last error (if any)
}

export const initialState: UserState = {
  users: {},
  loaded: false,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.userLoaded, (state, action) => {
    const user = action.user;

    if (!user || !user.id) {
      return state;
    }

    return {
      ...state,
      users: { ...state.users, [user.id]: user },
      selectedId: user.id,
      loaded: true,
    };
  }),
  on(UserActions.usersLoaded, (state, action) => {
    const users: { [id: number]: IUser } = {};
    if (!action.users) {
      return state;
    }

    action.users.forEach((user) => {
      if (user?.id) {
        users[user.id] = user;
      }
    });

    Object.keys(state.users).forEach((key: string | number) => {
      if (typeof key === 'string') {
        key = parseInt(key, 10);
      }

      const user = state.users[key];

      if (!user?.id) {
        return;
      }

      if (users[user.id]) {
        users[user.id] = { ...users[user.id], ...user };
      } else {
        users[user.id] = user;
      }
    });

    return {
      ...state,
      users,
      loaded: true,
    };
  }),
  on(UserActions.currentUserLoaded, (state, action) => {
    const user = action.user;

    if (!user || !user.id) {
      return state;
    }

    return {
      ...state,
      users: { ...state.users, [user.id]: user },
      loaded: true,
      selectedRVId: user.selectedRv ? user.selectedRv.id : undefined,
      currentId: user.id,
    };
  }),
  on(UserActions.currentUserUnloaded, (state) => {
    return {
      ...state,
      users: {}, // We are going to remove all users if there is no current user.
      currentId: undefined,
      selectedId: undefined,
      selectedRVId: undefined,
      selectedTaxId: undefined,
      loaded: false,
      error: undefined,
    };
  }),
  on(UserActions.selectUser, (state, action) => {
      return {
        ...state,
        selectedId: action.userId,
      };
  }),
  on(UserActions.addRV, (state, action) => {
    if (action.rvId) {
      const id = action.rvId;
      const user = state.users[id];

      return {
        ...state,
        users: {
          ...state.users,
          [id]: {
            ...user,
            rvs: [...(user.rvs || []), new RV()],
          },
        },
        selectedRVId: undefined,
      };
    } else if (state.currentId) {
      // add a new RV to the current user.
      const currentUser = state.users[state.currentId];

      if (!currentUser || !currentUser.id) {
        return state;
      }

      return {
        ...state,
        users: {
          ...state.users,
          [currentUser.id]: {
            ...currentUser,
            rvs: [...(currentUser.rvs || []), new RV()],
          },
        },
        selectedRVId: undefined,
      };
    }

    return state;
  }),
  on(UserActions.removeRV, (state, action) => {
    const rvId = action?.rv?.id;

    if (action.userId) {
      const user = state.users[action.userId];

      if (!user || !user.id || !user.rvs) {
        return state;
      }

      const rvs = user.rvs?.filter((rv) => rv.id !== rvId) || [];

      return {
        ...state,
        users: { ...state.users, [user.id]: { ...user, rvs } },
      };
    } else if (state.currentId) {
      // add an new RV to the current user.
      const currentUser = { ...state.users[state.currentId] };

      if (!currentUser || !currentUser.id || !currentUser.rvs) {
        return state;
      }

      const rvs = [];
      rvs.push(...currentUser.rvs);
      currentUser.rvs = rvs.filter((rv) => rv.id !== rvId);

      return {
        ...state,
        users: { ...state.users, [currentUser.id]: currentUser },
      };
    }
    return state;
  }),
  on(UserActions.selectRV, (state, action) => {
    return {
      ...state,
      selectedRVId: action.rv ? action.rv.id : undefined,
    };
  }),
  on(UserActions.userUpdated, (state, action) => {
    const user = action.user;

    if (!user || !user.id) {
      return state;
    }

    let selectedRVId: number | undefined;
    if (state.currentId === user.id && user.selectedRv) {
      selectedRVId = user.selectedRv.id;
    } else {
      selectedRVId = state.selectedRVId;
    }

    return {
      ...state,
      users: { ...state.users, [user.id]: user },
      selectedRVId,
    };
  }),
  on(UserActions.userImageUploaded, (state, action) => {
    const user = action;
    if (!user.id) {
      return state;
    }

    return {
      ...state,
      users: { ...state.users, [user.id]: user },
    };
  }),
  on(UserActions.rvImageUploaded, (state, action) => {
    const user = action;

    const id = user.id;
    if (user && id) {
      return {
        ...state,
        users: { ...state.users, [id]: user },
      };
    }
    return state;
  }),
  on(UserActions.userFavoritesLoaded, (state, action) => {
    if (
      !state.currentId ||
      state.currentId !== action.id ||
      !action?.poiFavorites ||
      !action?.siteFavorites
    ) {
      return state;
    }

    const currentUser = state.users[state.currentId];

    const user: IUser = {
      ...currentUser,
      poiFavorites: action.poiFavorites,
      siteFavorites: action.siteFavorites,
    };

    return {
      ...state,
      users: { ...state.users, [state.currentId]: user },
    };
  }),
  on(SiteActions.siteFavorCompleted, (state, action) => {
      if (!state.currentId || !action.id) {
        return state;
      }

      const siteId = action.id;
      const currentUser = state.users[state.currentId];

      const existingSiteFavorites = currentUser.siteFavorites || [];

      let siteFavorites: ISiteFavorite[];
      if (action.favored) {
        siteFavorites = [...existingSiteFavorites, { userId: state.currentId, siteId }];
      } else {
        siteFavorites = existingSiteFavorites.reduce((favoritesArray, favorite) => {
          if (favorite.siteId !== siteId) {
            return [...favoritesArray, favorite];
          }

          return favoritesArray;
        }, [] as ISiteFavorite[]);
      }

      const user: IUser = { ...currentUser, siteFavorites };

      return {
        ...state,
        users: { ...state.users, [state.currentId]: user },
      };
  }),
  on(ActivityActions.activityFavorCompleted, (state, action) => {
    if (!state.currentId || !action.id) {
      return state;
    }

    const poiId = action.id;
    const currentUser = state.users[state.currentId];

    const existingPoiFavorites = currentUser.poiFavorites || [];

    let poiFavorites: IPoiFavorite[];
    if (action.favored) {
      poiFavorites = [...existingPoiFavorites, { userId: state.currentId, poiId }];
    } else {
      poiFavorites = existingPoiFavorites.reduce((favoritesArray, favorite) => {
        if (favorite.poiId !== poiId) {
          return [...favoritesArray, favorite];
        }

        return favoritesArray;
      }, [] as IPoiFavorite[]);
    }

    const user: IUser = { ...currentUser, poiFavorites };

    return {
      ...state,
      users: { ...state.users, [state.currentId]: user },
    };
  }),
    // updates the favorited poi if the current user is also the owner and updates it
  on(ActivityActions.activityLoaded, (state, action) => {
    if (!state || !state.currentId || !action.activity || !action.activity.id) {
      return state;
    }

    if (!action.activity.id) {
      return state;
    }

    const currentUser = state.users[state.currentId];
    const poiFavorites = currentUser.poiFavorites || [];

    const user: IUser = {
      ...currentUser,
      poiFavorites: poiFavorites.map((poiFavorite) => {
        const favorite: IPoiFavorite = { ...poiFavorite };
        if (favorite.poiId === action.activity.id) {
          favorite.poi = action.activity;
        }

        return favorite;
      }),
    };

    return {
      ...state,
      users: { ...state.users, [state.currentId]: user },
    };
  }),
  on(UserActions.setInProgressSite, (state, action) => {
    const user = action.user;
    const site = action.site;

    if (!user || !user.id) {
      return state;
    }

    if (!site || !site.id) {
      return state;
    }

    return {
      ...state,
      users: {
        ...state.users,
        [user.id]: new User({ ...user, inProgressId: site.id }),
      },
      selectedId: user.id,
      loaded: true,
    };
  }),
  on(UserActions.loadAccountBalanceTransactionsSuccess, (state, action) => {
    let user: IUser;
    if (action.user.id && state.users[action.user.id]) {
      user = { ...state.users[action.user.id] };
    } else {
      user = { ...action.user };
    }

    if (!user || !user.id) {
      return state;
    }

    user.accountBalanceTransactions = action.transactions;

    return {
      ...state,
      users: { ...state.users, [user.id]: user },
    };
  }),
  on(UserActions.loadCashTransactionsSuccess, (state, action) => {
      let user;
      if (action.user.id && state.users[action.user.id]) {
        user = { ...state.users[action.user.id] };
      } else {
        user = { ...action.user };
      }

      if (!user || !user.id) {
        return state;
      }

      user.cashTransactions = action.transactions;

      return {
        ...state,
        users: { ...state.users, [user.id]: user },
      };
  }),
  on(UserActions.updateCurrentUserLocally, (state, action) => {
      if (!state || !state.currentId || !action.user || !action.user.id || action.user.id !== state.currentId) {
        return state;
      }

      const currentUser = state.users[state.currentId];

      return {
        ...state,
        users: { ...state.users, [state.currentId]: { ...currentUser, ...action.user } },
      };
  }),
);
