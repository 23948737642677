import { IOrder, IReservation, Reservation } from '@curbnturf/entities';
import { ReservationAction, ReservationActionTypes } from './reservation.actions';

export interface ReservationState {
  reservations: { [id: number]: IReservation }; // list of Reservation; analogous to a sql normalized table
  order?: IOrder;
  selectedId?: string | number; // which Reservation record has been selected
  loaded: boolean; // has the Reservation list been loaded
  error?: string; // last error (if any)
}

export const initialState: ReservationState = {
  reservations: {},
  loaded: false,
};

export function reservationReducer(
  state: ReservationState = initialState,
  action: ReservationAction,
): ReservationState {
  switch (action.type) {
    case ReservationActionTypes.ReservationLoaded: {
      const reservation = action.payload;
      if (!reservation?.id) {
        break;
      }

      state = {
        ...state,
        reservations: { ...state.reservations, [reservation.id]: reservation },
        loaded: true,
      };
      break;
    }
    case ReservationActionTypes.SetReservations: {
      const reservationList = action.payload.reservations;
      if (!reservationList?.length) {
        break;
      }

      const reservations: IReservation[] = [];
      reservationList.forEach((res) => {
        // should work as long as we supply IDs to this action.
        reservations[res.id || 0] = res;
      });

      state = {
        ...state,
        reservations,
        loaded: true,
      };
      break;
    }
    case ReservationActionTypes.UpdateReservationStatus: {
      const reservationId: number = action.payload.reservationId;
      const reservation = new Reservation({
        ...state.reservations[reservationId],
        status: action.payload.status,
      });
      state = {
        ...state,
        reservations: { ...state.reservations, [reservationId]: reservation },
      };
      break;
    }
    case ReservationActionTypes.SelectReservation: {
      let selectedId;
      if (action.payload) {
        selectedId = action.payload.id;
      }

      state = {
        ...state,
        selectedId,
      };
      break;
    }
    case ReservationActionTypes.ReservationsLoaded: {
      const updatedReservationIds = action.payload.map((updatedReservation) => updatedReservation.id);
      const reservations: { [id: number]: IReservation } = {};
      updatedReservationIds.forEach((reservationId, index) => {
        if (reservationId) {
          reservations[reservationId] = action.payload[index];
        }
      });

      state = {
        ...state,
        reservations: { ...state.reservations, ...reservations },
        loaded: true,
      };
      break;
    }
    case ReservationActionTypes.EmptyReservations: {
      state = {
        ...state,
        reservations: {},
      };
      break;
    }
    case ReservationActionTypes.ReservationLoadError: {
      const error = action.payload?.error?.message || action.payload?.message || action.payload;

      state = {
        ...state,
        error,
      };
      break;
    }
    case ReservationActionTypes.ChangeReservationSuccess: {
      if (action.reservation.id) {
        const reservationId = action.reservation.id;

        state = {
          ...state,
          reservations: { ...state.reservations, [reservationId]: action.reservation },
        };
      }
      break;
    }

    default:
  }
  return state;
}
