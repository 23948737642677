import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ConnectionStatus } from '@capacitor/network';
import { AlertFacade } from '@curbnturf/alert/src/lib/+state/alert.facade';
import { CachableHttpRequest, CachedHttpResponse } from '@curbnturf/entities';
import { EMPTY, isObservable, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { NetworkFacade } from './+state/network.facade';
import IOfflineSyncOperation from './offline-sync-operation';

@Injectable({
  providedIn: 'root',
})
export class CachedHttpClient {
  networkStatus: ConnectionStatus | undefined;
  subscriptions: Subscription[] = [];

  constructor(
    private alertFacade: AlertFacade,
    private http: HttpClient,
    private networkFacade: NetworkFacade,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
    this.subscriptions.push(
      this.networkFacade.status$.subscribe((status) => {
        this.networkStatus = status;
      }),
    );
  }

  /**
   * Get the status of the network. If the status is unknown or undefined, then
   * act as if everything is normal.
   */
  get isOnline(): boolean {
    if (this.networkStatus) {
      return this.networkStatus?.connected;
    } else {
      return false;
    }
  }

  request<T>(
    method: string,
    url: string,
    options?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      body?: any;
      headers?: {
        [header: string]: string | string[];
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      observe?: any;
      params?: {
        [param: string]: string | string[];
      };
      responseType?: 'json';
      reportProgress?: boolean;
      withCredentials?: boolean;
      cachableResponse?: boolean;
    },
    offlineSyncOperation?: IOfflineSyncOperation<T>,
  ): Observable<T> {
    if (!this.isOnline) {
      this.networkFacade.cacheHttpRequest(
        new CachableHttpRequest({
          method,
          url,
          body: options?.body,
          options,
          syncId: options?.body?.syncId || uuidv4(), // Use the included syncId or make a new one.
          syncAction: offlineSyncOperation?.syncAction,
        }),
      );
      if (offlineSyncOperation?.offlineCallback) {
        const result = offlineSyncOperation?.offlineCallback();
        return isObservable(result) ? result : of(result);
      }
      return EMPTY;
    } else {
      return this.http.request<T>(method, url, options).pipe(
        catchError((err) => {
          if (options?.cachableResponse === true) {
            this.cachedResultsAlert();
            return this.networkFacade.cachedHttpResponses$.pipe(
              map((resp: CachedHttpResponse[]) => resp.filter((response) => response.url === url).pop()?.response),
            );
          }
          return throwError(err);
        }),
      );
    }
  }

  post<T>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any | null,
    options?: {
      headers?: {
        [header: string]: string | string[];
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      observe?: any;
      params?: {
        [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      cachableResponse?: boolean;
    },
    offlineSyncOperation?: IOfflineSyncOperation<T>,
  ): Observable<T> {
    if (!this.isOnline) {
      this.networkFacade.cacheHttpRequest(
        new CachableHttpRequest({
          method: CachableHttpRequest.METHOD_POST,
          url,
          body,
          options,
          syncId: body?.syncId || uuidv4(), // Use the included syncId or make a new one.
          syncAction: offlineSyncOperation?.syncAction,
        }),
      );
      if (offlineSyncOperation?.offlineCallback) {
        const result = offlineSyncOperation?.offlineCallback();
        return isObservable(result) ? result : of(result);
      }
      return EMPTY;
    } else {
      return this.http.post<T>(url, body, options).pipe(
        catchError((err) => {
          if (options?.cachableResponse === true) {
            this.cachedResultsAlert();
            return this.networkFacade.cachedHttpResponses$.pipe(
              map((resp: CachedHttpResponse[]) => resp.filter((response) => response.url === url).pop()?.response),
            );
          }
          return throwError(err);
        }),
      );
    }
  }

  get<T>(
    url: string,
    options?: {
      headers?: {
        [header: string]: string | string[];
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      observe?: any;
      params?: {
        [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      cachableResponse?: boolean;
    },
    offlineSyncOperation?: IOfflineSyncOperation<T>,
  ): Observable<T> {
    // if on server always assume online
    if (!this.isOnline && !isPlatformServer(this.platformId)) {
      this.networkFacade.cacheHttpRequest(
        new CachableHttpRequest({
          method: CachableHttpRequest.METHOD_GET,
          url,
          options,
          syncAction: offlineSyncOperation?.syncAction,
        }),
      );
      if (options?.cachableResponse === true) {
        this.offlineResultsAlert();
        return this.networkFacade.cachedHttpResponses$.pipe(
          map((resp: CachedHttpResponse[]) => resp.filter((response) => response.url === url).pop()?.response),
        );
      } else {
        if (offlineSyncOperation?.offlineCallback) {
          const result = offlineSyncOperation?.offlineCallback();
          return isObservable(result) ? result : of(result);
        }
        return EMPTY;
      }
    } else {
      return this.http.get<T>(url, options).pipe(
        tap((data) => {
          if (options?.cachableResponse === true) {
            this.networkFacade.cacheHttpResponse(
              new CachedHttpResponse({
                method: CachedHttpResponse.METHOD_GET,
                url,
                response: data,
              }),
            );
          }
        }),
        catchError((err) => {
          if (options?.cachableResponse === true && err.status !== 403) {
            this.cachedResultsAlert();
            return this.networkFacade.cachedHttpResponses$.pipe(
              map((resp: CachedHttpResponse[]) => resp.filter((response) => response.url === url).pop()?.response),
            );
          }
          return throwError(err);
        }),
      );
    }
  }

  patch<T>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any | null,
    options: {
      headers?: {
        [header: string]: string | string[];
      };
      observe: 'events';
      params?: {
        [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      cachableResponse?: boolean;
    },
    offlineSyncOperation?: IOfflineSyncOperation<T>,
  ): Observable<T> {
    if (!this.isOnline) {
      this.networkFacade.cacheHttpRequest(
        new CachableHttpRequest({
          method: CachableHttpRequest.METHOD_PATCH,
          url,
          options,
          syncAction: offlineSyncOperation?.syncAction,
        }),
      );
      if (offlineSyncOperation?.offlineCallback) {
        const result = offlineSyncOperation?.offlineCallback();
        return isObservable(result) ? result : of(result);
      }
      return EMPTY;
    } else {
      return this.http.patch<T>(url, body, options).pipe(
        catchError((err) => {
          if (options?.cachableResponse === true) {
            this.cachedResultsAlert();
            return this.networkFacade.cachedHttpResponses$.pipe(
              map((resp: CachedHttpResponse[]) => resp.filter((response) => response.url === url).pop()?.response),
            );
          }
          return throwError(err);
        }),
      );
    }
  }

  put<T>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any | null,
    options?: {
      headers?: {
        [header: string]: string | string[];
      };
      observe?: 'body';
      params?: {
        [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      cachableResponse?: boolean;
    },
    offlineSyncOperation?: IOfflineSyncOperation<T>,
  ): Observable<T> {
    if (!this.isOnline) {
      this.networkFacade.cacheHttpRequest(
        new CachableHttpRequest({
          method: CachableHttpRequest.METHOD_PATCH,
          url,
          options,
          syncAction: offlineSyncOperation?.syncAction,
        }),
      );
      if (offlineSyncOperation?.offlineCallback) {
        const result = offlineSyncOperation?.offlineCallback();
        return isObservable(result) ? result : of(result);
      }
      return EMPTY;
    } else {
      return this.http.put<T>(url, body, options).pipe(
        catchError((err) => {
          if (options?.cachableResponse === true) {
            this.cachedResultsAlert();
            return this.networkFacade.cachedHttpResponses$.pipe(
              map((resp: CachedHttpResponse[]) => resp.filter((response) => response.url === url).pop()?.response),
            );
          }
          return throwError(err);
        }),
      );
    }
  }

  delete<T>(
    url: string,
    options?: {
      headers?: {
        [header: string]: string | string[];
      };
      observe?: 'body';
      params?: {
        [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      body?: any | null;
      cachableResponse?: boolean;
    },
    offlineSyncOperation?: IOfflineSyncOperation<T>,
  ): Observable<T> {
    if (!this.isOnline) {
      this.networkFacade.cacheHttpRequest(
        new CachableHttpRequest({
          method: CachableHttpRequest.METHOD_PATCH,
          url,
          options,
          syncAction: offlineSyncOperation?.syncAction,
        }),
      );
      if (offlineSyncOperation?.offlineCallback) {
        const result = offlineSyncOperation?.offlineCallback();
        return isObservable(result) ? result : of(result);
      }
      return EMPTY;
    } else {
      return this.http.delete<T>(url, options).pipe(
        catchError((err) => {
          if (options?.cachableResponse === true) {
            this.cachedResultsAlert();
            return this.networkFacade.cachedHttpResponses$.pipe(
              map((resp: CachedHttpResponse[]) => resp.filter((response) => response.url === url).pop()?.response),
            );
          }
          return throwError(err);
        }),
      );
    }
  }

  private offlineResultsAlert() {
    this.alertFacade.display({
      title: 'Cached Results Returned',
      body: 'You are operating offline. You are viewing cached data.',
    });
  }

  private cachedResultsAlert() {
    this.alertFacade.display({
      title: 'Cached Results Returned',
      body: 'There was a problem with your request and we have returned a cached version of the results.',
    });
  }
}
