import { Injectable } from '@angular/core';
import { isInteger } from '@curbnturf/helpers';
import { DateTime } from 'luxon';
import { IDatepickerStruct } from '../datepicker-struct';

export function DATEPICKER_DATE_ADAPTER_FACTORY() {
  return new NgbDateStructAdapter();
}

/**
 * An abstract service that does the conversion between the internal datepicker `NgbDateStruct` model and
 * any provided user date model `D`, ex. a string, a native date, etc.
 *
 * The adapter is used **only** for conversion when binding datepicker to a form control,
 * ex. `[(ngModel)]="userDateModel"`. Here `userDateModel` can be of any type.
 *
 * The default datepicker implementation assumes we use `NgbDateStruct` as a user model.
 *
 * See the [date format overview](#/components/datepicker/overview#date-model) for more details
 * and the [custom adapter demo](#/components/datepicker/examples#adapter) for an example.
 */
@Injectable({
  providedIn: 'root',
  useFactory: DATEPICKER_DATE_ADAPTER_FACTORY,
})
export abstract class DatepickerAdapter<D> {
  /**
   * Converts a user-model date of type `D` to an `NgbDateStruct` for internal use.
   */
  abstract fromModel(value: D): IDatepickerStruct | undefined;

  /**
   * Converts an internal `NgbDateStruct` date to a user-model date of type `D`.
   */
  abstract toModel(date?: IDatepickerStruct): D | undefined;
}

@Injectable()
export class NgbDateStructAdapter extends DatepickerAdapter<IDatepickerStruct> {
  /**
   * Converts a Luxon DateTime value into DatePickerStruct value
   */
  fromModel(date: DateTime): IDatepickerStruct | undefined {
    return date && isInteger(date.year) && isInteger(date.month) && isInteger(date.day)
      ? { year: date.year, month: date.month, day: date.day }
      : undefined;
  }

  /**
   * Converts a DatePickerStruct value into Luxon DateTime value
   */
  toModel(date: IDatepickerStruct): DateTime | undefined {
    return date && isInteger(date.year) && isInteger(date.month) && isInteger(date.day)
      ? DateTime.fromObject({ year: date.year, month: date.month, day: date.day })
      : undefined;
  }
}
