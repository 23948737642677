import { Injectable } from '@angular/core';
import { IMessage, IMessageChannel, IMessageQuery, MessageChannel } from '@curbnturf/entities';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as MessageActions from './message.actions';
import { MessageState } from './message.reducer';
import { messageQuery } from './message.selectors';

@Injectable({
  providedIn: 'root',
})
export class MessageFacade {
  loaded$: Observable<boolean>;
  error$: Observable<string | undefined>;
  allMessageChannelEntities$: Observable<{[id: number]: MessageChannel}>;
  allMessageChannels$: Observable<MessageChannel[]>;
  selectedMessageChannel$: Observable<MessageChannel | undefined>;
  pushToken$: Observable<string | undefined>;
  chatModalActive$: Observable<boolean>;
  chatModalActiveOnce$: Observable<boolean>;

  constructor(private store: Store<MessageState>) {
    this.loaded$ = this.store.pipe(select(messageQuery.getLoaded));
    this.error$ = this.store.pipe(select(messageQuery.getError));
    this.allMessageChannelEntities$ = this.store.pipe(select(messageQuery.getAllMessageChannelEntities));
    this.allMessageChannels$ = this.store.pipe(select(messageQuery.getAllMessageChannels));
    this.selectedMessageChannel$ = this.store.pipe(select(messageQuery.getSelectedMessageChannel));
    this.pushToken$ = this.store.pipe(select(messageQuery.getPushToken));
    this.chatModalActive$ = this.store.pipe(select(messageQuery.getChatModalActive));
    this.chatModalActiveOnce$ = this.chatModalActive$.pipe(take(1));
  }

  load(id: number) {
    this.store.dispatch(MessageActions.loadMessageChannel({ channel: id }));
  }

  loadChannels(query: IMessageQuery) {
    this.store.dispatch(MessageActions.loadMessageChannels({ query }));
  }

  loadReservationChannel(reservationId: number) {
    this.store.dispatch(MessageActions.loadReservationMessageChannel({ channel: reservationId }));
  }

  create(message: IMessage) {
    this.store.dispatch(MessageActions.createMessage({ message }));
  }

  select(messageChannel?: IMessageChannel) {
    this.store.dispatch(MessageActions.selectMessageChannel({ channel: messageChannel }));
  }

  selectById(id: number) {
    this.store.dispatch(MessageActions.selectMessageChannel({ channel: { id } }));
  }

  read(messageChannel: IMessageChannel) {
    this.store.dispatch(MessageActions.readMessages({ channel: messageChannel }));
  }

  registerPush() {
    this.store.dispatch(MessageActions.registerPushNotifications());
  }

  savePushToken(token: string) {
    this.store.dispatch(MessageActions.registerPushNotificationsSuccess({ token }));
  }

  showChatModal(showModal: boolean, channelId?: number) {
    this.store.dispatch(MessageActions.showChatModal({ showModal, channelId }));
  }
}
