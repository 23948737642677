import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertModule } from '@curbnturf/alert';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { NetworkModule } from '@curbnturf/network';
import { StatusFacade } from '@curbnturf/status';
import { StorageModule } from '@curbnturf/storage';
import { UrlControlModule } from '@curbnturf/url-control';
import { UserModule } from '@curbnturf/user';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as authEffects from './+state/auth.effects';
import { authReducer, initialState as authInitialState } from './+state/auth.reducer';
import { AuthGuard } from './auth.guard';
import { CognitoService } from './cognito.service';
import { JwtInterceptor } from './jwt.interceptor';
import { UnauthenticatedErrorInterceptor } from './unauthenticated-error.interceptor';
import { AuthService } from './auth.service';
import { ListingForwarderGuard } from './listing-forwarder.guard';

@NgModule({
  imports: [
    AlertModule,
    RouterModule,
    NetworkModule,
    StorageModule,
    UrlControlModule,
    UserModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthenticatedErrorInterceptor,
      multi: true,
    },
    AuthService,
    AuthGuard,
    CognitoService,
    ListingForwarderGuard,
    StatusFacade,
    provideState(NGRX_BUCKETS.auth, authReducer, {
      initialState: authInitialState,
    }),
    provideEffects(authEffects),
  ],
})
export class AuthModule {}
