import { Injectable } from '@angular/core';
import { capSQLiteChanges, capSQLiteValues } from '@capacitor-community/sqlite';
import {
  DEFAULT_MAP_LATITUDE,
  DEFAULT_MAP_LONGITUDE,
  DEFAULT_MAP_VIEWPORT_DISTANCE,
  IListingSearchQuery,
  IMapPoint,
  LatLon,
  MapPointTypes
} from '@curbnturf/entities';
import { Logger } from '../../log/logger';
import { DatabaseService } from '../database.service';
import { IDbSet } from '../dbset.interface';

const TABLE_NAME = 'listings';

@Injectable({
  providedIn: 'root',
})
export class ListingDbSet implements IDbSet {
  constructor(
    private databaseService: DatabaseService,
    private logger: Logger,
  ) {}

  public store(request: IMapPoint): Promise<capSQLiteValues> {
    return this.databaseService.insert(TABLE_NAME, this.mapObjectsToStorable([request]).pop());
  }

  public storeArray(requests: IMapPoint[]): Promise<capSQLiteValues> {
    return this.databaseService.insertMulti(TABLE_NAME, this.mapObjectsToStorable(requests));
  }

  public async truncateAndStoreArray(records: IMapPoint[]): Promise<capSQLiteValues> {
    records = this.mapObjectsToStorable(records);
    await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
    const { statement, values } = this.databaseService.generateInsertStatement(TABLE_NAME, records);
    this.logger.debug('Executing INSERT STATEMENT', statement);
    return this.databaseService.query(statement, values);
  }

  public async truncate(): Promise<capSQLiteChanges> {
    return await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
  }

  public async retrieve(id: number): Promise<IMapPoint> {
    const results = await this.databaseService.select(TABLE_NAME, { id });
    const objResults = this.mapResultsObject(results);
    if (objResults && objResults?.length > 0) {
      return objResults[0];
    } else {
      return {
        coordinates: { lat: 0, lon: 0 },
        distance: 0,
        location: '',
        name: '',
        photos: [],
        price: 0,
        rating: 0,
        ratingCount: 0,
        type: MapPointTypes.standard,
        id,
      };
    }
  }

  public async retrieveAll(limit?: number): Promise<IMapPoint[]> {
    const results = await this.databaseService.selectAll(TABLE_NAME, undefined, limit);
    return this.mapResultsObject(results);
  }

  public async retrieveFiltered(
    query: IListingSearchQuery,
    take: number = 1000,
    skip: number = 0,
  ): Promise<IMapPoint[]> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const values: any[] = [];
    let statement = `SELECT * FROM ${TABLE_NAME} WHERE `;

    const wheres: string[] = [];

    // String Filter
    if (query.string) {
      wheres.push('(name LIKE ? OR location LIKE ?)');
      values.push('%' + query.string + '%', '%' + query.string + '%');
    }

    // Types Filter
    if (query.types && query.types.length > 0) {
      let clause = 'type IN(';
      query.types?.forEach((el) => {
        clause += '?, ';
        values.push(el as string);
      });
      clause = clause.substr(0, clause.length - 2) + ')';
      wheres.push(clause);
    }

    // Price Filter
    if (query.maxPrice && query.maxPrice < 999) {
      // 999 is the default value for this filter.
      const clause = 'price < ?';
      values.push(query.maxPrice);
      wheres.push(clause);
    }
    if (query.minPrice) {
      const clause = 'price > ?';
      values.push(query.minPrice);
      wheres.push(clause);
    }

    // Viewport Filter
    const { nwPoint, sePoint } = this.getViewportPoints(query);

    if (nwPoint && sePoint) {
      let clause = 'lat < ? AND lng > ?';
      values.push(nwPoint.lat, nwPoint.lon);
      clause += 'AND lat > ? AND lng < ?';
      values.push(sePoint.lat, sePoint.lon);
      wheres.push(clause);
    }

    statement += wheres.join(' AND ');

    statement += ` LIMIT ? OFFSET ?;`;
    values.push(take, skip);

    this.logger.debug('Filtered SELECT query executing', { statement, values });

    return this.mapResultsObject(await this.databaseService.query(statement, values));
  }

  public async remove(id: string): Promise<capSQLiteValues> {
    return await this.databaseService.delete(TABLE_NAME, { id });
  }

  public async removeBySyncId(syncId: string): Promise<capSQLiteValues> {
    return await this.databaseService.delete(TABLE_NAME, { sync_id: syncId });
  }

  private mapResultsObject(results: capSQLiteValues): IMapPoint[] {
    if (results.values) {
      return results.values?.map((record) => {
        return {
          ...record,
          lat: undefined,
          lng: undefined,
          coordinates: { lat: record.lat, lon: record.lng },
          photos: [],
        };
      });
    } else {
      return [];
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private mapObjectsToStorable(items: IMapPoint[]): any[] {
    if (items) {
      return items?.map((record: IMapPoint) => {
        return {
          // Can't use spread operator here as it tries to enter null in undefined properties as columns
          id: record.id,
          name: record.name,
          type: record.type,
          lat: record.coordinates.lat,
          lng: record.coordinates.lon,
          price: record.price,
          location: record.location,
          rating: record.rating,
          ratingCount: record.ratingCount,
        };
      });
    } else {
      return [];
    }
  }

  private getViewportPoints(query: IListingSearchQuery) {
    let nwPoint;
    let sePoint;
    if (query.lat && query.lon) {
      const point = new LatLon({ lat: query.lat, lon: query.lon });
      const distance = query.distance ? query.distance : DEFAULT_MAP_VIEWPORT_DISTANCE;
      const bearingDistance = Math.sqrt(2 * Math.pow(distance, 2));
      nwPoint = point.destinationPoint(bearingDistance, 315);
      sePoint = point.destinationPoint(bearingDistance, 135);
    } else {
      const point = new LatLon({ lat: DEFAULT_MAP_LATITUDE, lon: DEFAULT_MAP_LONGITUDE });
      const distance = query.distance ? query.distance : DEFAULT_MAP_VIEWPORT_DISTANCE;
      const bearingDistance = Math.sqrt(2 * Math.pow(distance, 2));
      nwPoint = point.destinationPoint(bearingDistance, 315);
      sePoint = point.destinationPoint(bearingDistance, 135);
    }

    return { nwPoint, sePoint };
  }
}
