import { inject } from '@angular/core';
import * as AlertActions from '@curbnturf/alert/src/lib/+state/alert.actions';
import {
  IUserCustomLocation,
  LatLon,
  ListingSearchQuery,
  LogLevel,
  MapPoint,
  MapPointTypes,
  Site,
  UserCustomLocation,
} from '@curbnturf/entities';
import { stringFromError } from '@curbnturf/form-helpers';
import * as LogActions from '@curbnturf/network/src/lib/log/+state/log.actions';
import { Logger } from '@curbnturf/network/src/lib/log/logger';
import * as NetworkSelectors from '@curbnturf/network/src/lib/network/+state/network.selectors';
import { UserCustomLocationService } from '@curbnturf/user';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { ListingSearchService } from '../listing-search.service';
import { SiteDisplayService } from '../site-display.service';
import * as ListingSearchActions from './listing-search.actions';
import { ListingSearchActionTypes } from './listing-search.actions';
import * as ListingSearchSelectors from './listing-search.selectors';

export const runListingSearch$ = createEffect(
  (actions$ = inject(Actions), listingSearchService = inject(ListingSearchService), logger = inject(Logger)) =>
    actions$.pipe(
      ofType(ListingSearchActions.runListingSearch),
      throttleTime(1000),
      tap((payload) => logger.debug('Running Listing Search', JSON.stringify(payload))),
      switchMap((action) => {
        return listingSearchService.find(new ListingSearchQuery(action.query)).pipe(
          map((listingSearchResults: MapPoint[]) => {
            const results = listingSearchResults.map(
              (el) =>
                new MapPoint({
                  ...el,
                  type: el.type === 'standard' ? MapPointTypes.standard : MapPointTypes.boondock,
                }),
            );
            return ListingSearchActions.listingSearchCompleted({ results });
          }),
          catchError((err) => {
            logger.error(ListingSearchActionTypes.ListingSearchError, JSON.stringify(err));
            return of(ListingSearchActions.listingSearchError({ error: err }));
          }),
        );
      }),
    ),
  { functional: true },
);

export const runListingTypeahead$ = createEffect(
  (actions$ = inject(Actions), listingSearchService = inject(ListingSearchService)) =>
    actions$.pipe(
      ofType(ListingSearchActions.runListingTypeahead),
      throttleTime(1000),
      switchMap((action) =>
        listingSearchService.find(new ListingSearchQuery(action.query)).pipe(
          map((listingSearchResults: MapPoint[]) => {
            const results = listingSearchResults.map(
              (el) =>
                new MapPoint({
                  ...el,
                  type: el.type === 'standard' ? MapPointTypes.standard : MapPointTypes.boondock,
                }),
            );
            return ListingSearchActions.listingTypeaheadCompleted({ results });
          }),
          catchError(() => of(ListingSearchActions.listingTypeaheadCleared())),
        ),
      ),
    ),
  { functional: true },
);

export const listingSearchError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ListingSearchActions.listingSearchError),
      map((action) =>
        AlertActions.displayAlert({
          title: 'Error Searching for Properties',
          body: stringFromError(action.error),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const selectPoint$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), siteService = inject(SiteDisplayService)) =>
    actions$.pipe(
      ofType(ListingSearchActions.selectPoint),
      withLatestFrom(store.select(ListingSearchSelectors.getSelectedSite)),
      withLatestFrom(store.select(ListingSearchSelectors.getSelectedPoint)),
      switchMap(([[action, selectedSite], selectedPoint]) => {
        if (!action.point) {
          return [
            ListingSearchActions.pointDisplaySelected({ point: undefined }),
            ListingSearchActions.siteDisplaySelected({ site: undefined }),
          ];
        }

        if (selectedPoint && action.point.id === selectedPoint?.id) {
          const actions = [];
          if (selectedSite && selectedSite.id === selectedPoint.id) {
            actions.push(ListingSearchActions.siteDisplaySelected({ site: selectedSite }));
          } else {
            return siteService.fetch(action.point.id).pipe(
              mergeMap((result) => {
                const site: Site = new Site({ ...result });
                return [
                  ListingSearchActions.siteDisplaySelected({ site }),
                  ListingSearchActions.pointDisplaySelected({ point: selectedPoint }),
                ];
              }),
              catchError((err) => of(ListingSearchActions.listingSearchError({ error: err }))),
            );
          }

          return [...actions, ListingSearchActions.pointDisplaySelected({ point: selectedPoint })];
        }

        if (action.point.id) {
          return siteService.fetch(action.point.id).pipe(
            mergeMap((result) => {
              const site: Site = new Site({ ...result });
              return [
                ListingSearchActions.siteDisplaySelected({ site }),
                ListingSearchActions.pointDisplaySelected({ point: action.point }),
              ];
            }),
            catchError((err) => of(ListingSearchActions.listingSearchError({ error: err }))),
          );
        }

        return [];
      }),
    ),
  { functional: true },
);

export const selectPointById$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), siteService = inject(SiteDisplayService)) =>
    actions$.pipe(
      ofType(ListingSearchActions.selectPointById),
      withLatestFrom(store.select(ListingSearchSelectors.getSelectedSite)),
      withLatestFrom(store.select(ListingSearchSelectors.getSelectedPoint)),
      withLatestFrom(store.select(ListingSearchSelectors.getAllPoints)),
      switchMap(([[[action, selectedSite], selectedPoint], allPoints]) => {
        if (!action.pointId) {
          return [
            ListingSearchActions.pointDisplaySelected({ point: undefined }),
            ListingSearchActions.siteDisplaySelected({ site: undefined }),
          ];
        }

        const point = allPoints.find((el) => el?.id === action.pointId);
        if (point) {
          if (selectedPoint && action.pointId === selectedPoint?.id) {
            const actions = [];
            if (selectedSite && selectedSite.id === selectedPoint.id) {
              actions.push(ListingSearchActions.siteDisplaySelected({ site: selectedSite }));
            } else {
              return siteService.fetch(action.pointId).pipe(
                mergeMap((result) => {
                  const site: Site = new Site({ ...result });
                  return [
                    ListingSearchActions.siteDisplaySelected({ site }),
                    ListingSearchActions.pointDisplaySelected({ point: selectedPoint }),
                  ];
                }),
                catchError((err) => of(ListingSearchActions.listingSearchError({ error: err }))),
              );
            }

            return [...actions, ListingSearchActions.pointDisplaySelected({ point: selectedPoint })];
          }

          return siteService.fetch(action.pointId).pipe(
            mergeMap((result) => {
              const site: Site = new Site({ ...result });
              return [
                ListingSearchActions.siteDisplaySelected({ site }),
                ListingSearchActions.pointDisplaySelected({ point }),
              ];
            }),
            catchError((err) => of(ListingSearchActions.listingSearchError({ error: err }))),
          );
        } else {
          // Couldn't find the map point, so resetting everything to undefined.
          return [
            ListingSearchActions.siteDisplaySelected({ site: undefined }),
            ListingSearchActions.pointDisplaySelected({ point: undefined }),
          ];
        }
      }),
    ),
  { functional: true },
);

export const selectSiteById$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), siteService = inject(SiteDisplayService)) =>
    actions$.pipe(
      ofType(ListingSearchActions.selectSiteById),
      withLatestFrom(store.select(ListingSearchSelectors.getSelectedSite)),
      withLatestFrom(store.select(ListingSearchSelectors.getSelectedPoint)),
      withLatestFrom(store.select(ListingSearchSelectors.getAllPoints)),
      switchMap(([[[action, selectedSite], selectedPoint], allPoints]) => {
        if (!action.siteId) {
          return [
            ListingSearchActions.pointDisplaySelected({ point: undefined }),
            ListingSearchActions.siteDisplaySelected({ site: undefined }),
          ];
        }

        const point = allPoints.find((el) => el?.id === action.siteId);
        if (point) {
          if (selectedPoint && action.siteId === selectedPoint?.id) {
            const actions = [];
            if (selectedSite && selectedSite.id === selectedPoint.id) {
              actions.push(ListingSearchActions.siteDisplaySelected({ site: selectedSite }));
            } else {
              return siteService.fetch(action.siteId).pipe(
                mergeMap((result) => {
                  const site: Site = new Site({ ...result });
                  return [
                    ListingSearchActions.siteDisplaySelected({ site }),
                    ListingSearchActions.pointDisplaySelected({ point: selectedPoint }),
                  ];
                }),
                catchError((err) => of(ListingSearchActions.listingSearchError({ error: err }))),
              );
            }

            return [...actions, ListingSearchActions.pointDisplaySelected({ point: selectedPoint })];
          }

          return siteService.fetch(action.siteId).pipe(
            mergeMap((result) => {
              const site: Site = new Site({ ...result });
              return [
                ListingSearchActions.siteDisplaySelected({ site }),
                ListingSearchActions.pointDisplaySelected({ point }),
              ];
            }),
            catchError((err) => of(ListingSearchActions.listingSearchError({ error: err }))),
          );
        } else {
          // Couldn't find the map point, so resetting everything to undefined.
          return [
            ListingSearchActions.siteDisplaySelected({ site: undefined }),
            ListingSearchActions.pointDisplaySelected({ point: undefined }),
          ];
        }
      }),
    ),
  { functional: true },
);

export const retrieveUserPointList$ = createEffect(
  (actions$ = inject(Actions), userCustomLocationService = inject(UserCustomLocationService)) =>
    actions$.pipe(
      ofType(ListingSearchActions.retrieveUserPointList),
      switchMap((action) =>
        userCustomLocationService.fetchAll().pipe(
          map((locations: IUserCustomLocation[]) =>
            ListingSearchActions.retrieveUserPointListSuccess({
              points: locations.map(
                (location) =>
                  new MapPoint({
                    id: location.id || 0,
                    name: location.name,
                    coordinates: new LatLon({
                      lat: location.coordinates.lat,
                      lon: location.coordinates.lon,
                    }),
                    photos: [],
                    type: MapPointTypes.user,
                  }),
              ),
            }),
          ),
        ),
      ),
    ),
  { functional: true },
);

export const updateUserPoint$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), userCustomLocationService = inject(UserCustomLocationService)) =>
    actions$.pipe(
      ofType(ListingSearchActions.updateUserPoint),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      mergeMap(([action, status]) => {
        if (status.connected === true) {
          if (action.point.id) {
            const point: IUserCustomLocation = new UserCustomLocation({
              id: action.point.id,
              name: action.point.name,
              coordinates: new LatLon({
                lat: action.point.coordinates.lat,
                lon: action.point.coordinates.lon,
              }),
            });

            return userCustomLocationService.update(point).pipe(
              map((location) => {
                const point: MapPoint = new MapPoint({
                  id: location.id || 0,
                  name: location.name,
                  photos: [],
                  type: MapPointTypes.user,
                  coordinates: new LatLon({
                    lat: location.coordinates.lat,
                    lon: location.coordinates.lon,
                  }),
                });

                return ListingSearchActions.updateUserPointSuccess({ point });
              }),
              catchError((error) => [ListingSearchActions.updateUserPointFailed({ error })]),
            );
          } else {
            return [ListingSearchActions.updateUserPointFailed({ error: 'Point identifier not supplied.' })];
          }
        } else {
          // @todo refactor this function for offline functionality.
          //  Not a big deal yet, since this can only be accessed from the map view, which isn't available offline currently.
          return [
            AlertActions.displayAlert({
              title: 'Function Not Available',
              body: 'This function is not currently supported in offline mode.',
              level: 'warning',
            }),
          ];
        }
      }),
    ),
  { functional: true },
);

export const updateUserPointSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ListingSearchActions.updateUserPointSuccess),
      mergeMap((action) => {
        return [
          LogActions.logMessage({
            log: {
              level: LogLevel.DEBUG,
              message: 'Updated User Point',
              details: action,
              subjectId: action.point.id,
              subjectType: 'user-custom-location',
            },
          }),
        ];
      }),
    ),
  { functional: true },
);

export const updateUserPointFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ListingSearchActions.updateUserPointFailed),
      mergeMap((action) => {
        return [
          AlertActions.displayAlert({
            title: 'Failed to Update User Point',
            body:
              'An error occurred while trying to update the user point: ' + (typeof action.error === 'object')
                ? JSON.stringify(action.error)
                : action.error,
            level: 'error',
          }),
          LogActions.logMessage({
            log: {
              level: LogLevel.ERROR,
              message: 'Failed to Update User Point',
              details: action,
              subjectType: 'user-custom-location',
            },
          }),
        ];
      }),
    ),
  { functional: true },
);

export const removeUserPoint$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), userCustomLocationService = inject(UserCustomLocationService)) =>
    actions$.pipe(
      ofType(ListingSearchActions.removeUserPoint),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      mergeMap(([action, status]) => {
        if (status.connected === true) {
          if (action.point.id) {
            const point: IUserCustomLocation = new UserCustomLocation({
              id: action.point.id,
              name: action.point.name,
              coordinates: new LatLon({
                lat: action.point.coordinates.lat,
                lon: action.point.coordinates.lon,
              }),
            });
            return userCustomLocationService.remove(point).pipe(
              map((location) => {
                const point: MapPoint = new MapPoint({
                  id: location.id || 0,
                  name: location.name,
                  photos: [],
                  type: MapPointTypes.user,
                  coordinates: new LatLon({
                    lat: location.coordinates.lat,
                    lon: location.coordinates.lon,
                  }),
                });
                return ListingSearchActions.removeUserPointSuccess({ point: action.point });
              }),
              catchError((error) => [ListingSearchActions.removeUserPointFailed({ error })]),
            );
          } else {
            return [ListingSearchActions.removeUserPointFailed({ error: 'Point identifier not supplied.' })];
          }
        } else {
          // @todo refactor this function for offline functionality.
          //  Not a big deal yet, since this can only be accessed from the map view, which isn't available offline currently.
          return [
            AlertActions.displayAlert({
              title: 'Function Not Available',
              body: 'This function is not currently supported in offline mode.',
              level: 'warning',
            }),
          ];
        }
      }),
    ),
  { functional: true },
);

export const removeUserPointSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ListingSearchActions.removeUserPointSuccess),
      mergeMap((action) => {
        return [
          LogActions.logMessage({
            log: {
              level: LogLevel.DEBUG,
              message: 'Removed User Point',
              details: action,
              subjectId: action.point.id,
              subjectType: 'user-custom-location',
            },
          }),
        ];
      }),
    ),
  { functional: true },
);

export const removeUserPointFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ListingSearchActions.removeUserPointFailed),
      mergeMap((action) => [
        AlertActions.displayAlert({
          title: 'Failed to Remove User Point',
          body:
            'An error occurred while trying to remove the user point: ' + (typeof action.error === 'object')
              ? JSON.stringify(action.error)
              : action.error,
          level: 'error',
        }),
        LogActions.logMessage({
          log: {
            level: LogLevel.ERROR,
            message: 'Failed to Remove User Point',
            details: action,
            subjectType: 'user-custom-location',
          },
        }),
      ]),
    ),
  { functional: true },
);
