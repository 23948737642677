import { IRV, ITrip, ITripRoutePoint, IUser } from '../interfaces';

export class Trip implements ITrip {
  id?: number;
  user?: IUser;
  userId?: number;
  rv?: IRV;
  rvId?: number;
  name: string;
  width: number;
  created?: number;
  route: ITripRoutePoint[];
  deleted?: boolean;
  deletedDate?: number;

  constructor(data?: ITrip) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: ITrip): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (!this.route) {
      this.route = [];
    }
  }
}
