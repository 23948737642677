import { HttpErrorResponse } from '@angular/common/http';
import { ConnectionStatus } from '@capacitor/network';
import { CachableHttpRequest, CachedHttpResponse, ISite, IUser } from '@curbnturf/entities';
import { createAction, props } from '@ngrx/store';

export const setNetworkStatus = createAction('[Network] Set NetworkStatus', props<{ status: ConnectionStatus }>());

export const cacheHttpRequest = createAction(
  '[Network: Request] Cache HTTP Request',
  props<{ request: CachableHttpRequest }>(),
);

export const cacheHttpResponse = createAction(
  '[Network: Response] Cache HTTP Response',
  props<{ response: CachedHttpResponse }>(),
);

export const flushCachedRequests = createAction(
  '[Network: Request] Flush Cached HTTP Requests',
  props<{ status: ConnectionStatus }>(),
);

export const flushPendingSites = createAction(
  '[Network: Request] Flush Pending Sites',
  props<{ status: ConnectionStatus; sites: ISite[] }>(),
);

export const processCachedRequest = createAction(
  '[Network: Request] Process Cached HTTP Request',
  props<{ request: CachableHttpRequest }>(),
);

export const processCachedRequestSuccess = createAction(
  '[Network: Request] Process Cached HTTP Request Success',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ request: CachableHttpRequest; response: any }>(),
);

export const processCachedRequestFailed = createAction(
  '[Network: Request] Process Cached HTTP Request Failure',
  props<{ error: HttpErrorResponse; request: CachableHttpRequest }>(),
);

export const restoreCachedRequests = createAction(
  '[Network] Restore Cached Requests To State',
  props<{ requests: CachableHttpRequest[] }>(),
);

export const restoreCachedResponses = createAction(
  '[Network] Restore Cached Responses To State',
  props<{ responses: CachedHttpResponse[] }>(),
);

export const retrieveCachedRequests = createAction(
  '[Network] Retrieve Cached Requests From Storage',
  props<{ limit?: number; take?: number }>(),
);

export const setNetworkOverride = createAction(
  '[Network] Override Network State',
  props<{ override: boolean; status: ConnectionStatus }>(),
);

export const pendingSiteUpdate = createAction('[Network] Set Pending Site Update', props<{ site: ISite }>());

export const removePendingSite = createAction('[Network] Remove Pending Site Update', props<{ site: ISite }>());

export const pendingSiteImageUpload = createAction(
  '[Network] Add Pending Site Image Upload',
  props<{ syncId: string; photo: any }>(),
);

export const removePendingSiteImageUpload = createAction(
  '[Network] Remove Pending Site Image Upload',
  props<{ syncId: string }>(),
);

export const deferUserUpdate = createAction('[Network] Defer User Update', props<{ user: IUser }>());
export const removeDeferUserUpdate = createAction('[Network] Remove Deferred User Update');
export const deferUserImageUpload = createAction(
  '[Network] Defer User Image Upload',
  props<{ formData: FormData; data: { id: number } }>(),
);
export const removeDeferUserImageUpload = createAction('[Network] Remove Deferred User Image Upload');
