import { Injectable } from '@angular/core';
import { BASE_API_URL, IBoondockRating, IGuestRating, IPoiRating, ISiteRating } from '@curbnturf/entities';
import { CachedHttpClient } from '@curbnturf/network';
import { Observable } from 'rxjs';
import { RatingActionTypes } from './+state/rating.actions';

const API_URL = BASE_API_URL + 'rating';

@Injectable({
  providedIn: 'root',
})
export class RatingService {
  constructor(private http: CachedHttpClient) {}

  fetchSiteRatings(siteId: number): Observable<ISiteRating[]> {
    if (!siteId) {
      throw new Error('Unable to fetch ratings. No site ID provided');
    }

    return this.http.get<ISiteRating[]>(`${API_URL}/site/${siteId}`);
  }

  fetchGuestRatings(userId: number): Observable<IGuestRating[]> {
    if (!userId) {
      throw new Error('Unable to fetch guest ratings. No userId provided');
    }

    return this.http.get<IGuestRating[]>(`${API_URL}/guest/${userId}`);
  }

  fetchBoondockRatings(siteId: number): Observable<IBoondockRating[]> {
    if (!siteId) {
      throw new Error('Unable to fetch boondock ratings. No siteId provided');
    }

    return this.http.get<IBoondockRating[]>(`${API_URL}/boondock/${siteId}`);
  }

  fetchPoiRatings(poiId: number): Observable<IPoiRating[]> {
    if (!poiId) {
      throw new Error('Unable to fetch GOI ratings. No poiId provided');
    }

    return this.http.get<IPoiRating[]>(`${API_URL}/poi/${poiId}`);
  }

  createSiteRatings(ratings: ISiteRating[]): Observable<ISiteRating[]> {
    const saveableRatings = ratings.map((rating) => ({
      ...rating,
      creatorId: rating.creator?.id || rating.creatorId,
      creator: {
        id: rating.creator?.id || rating.creatorId,
      },
    }));
    return this.http.post<ISiteRating[]>(`${API_URL}/site`, saveableRatings, undefined, {
      offlineCallback: () => saveableRatings,
      syncAction: { type: RatingActionTypes.SyncSiteRatings },
    });
  }

  createGuestRatings(ratings: IGuestRating[]): Observable<IGuestRating[]> {
    const saveableRatings = ratings.map((rating) => ({
      ...rating,
      creatorId: rating.creator?.id || rating.creatorId,
      creator: {
        id: rating.creator?.id || rating.creatorId,
      },
    }));
    return this.http.post<IGuestRating[]>(`${API_URL}/guest`, saveableRatings, undefined, {
      offlineCallback: () => saveableRatings,
      syncAction: { type: RatingActionTypes.SyncGuestRatings },
    });
  }

  createBoondockRatings(ratings: IBoondockRating[]): Observable<IBoondockRating[]> {
    return this.http.post<IBoondockRating[]>(
      `${API_URL}/boondock`,
      ratings.map((rating) => ({
        ...rating,
        creatorId: rating.creator?.id || rating.creatorId,
        siteRatedId: rating.siteRated?.id || rating?.siteRatedId,
        creator: {
          id: rating.creator?.id || rating.creatorId,
        },
      })),
    );
  }

  createPoiRatings(ratings: IPoiRating[]): Observable<IPoiRating[]> {
    return this.http.post<IPoiRating[]>(
      `${API_URL}/poi`,
      ratings.map((rating) => ({
        ...rating,
        creatorId: rating.creator?.id,
        poiId: rating.poiRated?.id,
      })),
    );
  }
}
