import { dateRangeToDateArray, dateRangeToDateStringArray } from '@curbnturf/helpers';
import { DateTime } from 'luxon';
import { ICDate, ICDateData } from '../interfaces/c-date';

export class CDate implements ICDate {
  type?: 'range' | 'single';
  from: string;
  to?: string;

  get length(): number {
    return this.toDateStringArray().length;
  }

  /**
   * Returns a CDate using the array of dates.
   *
   * @param dates an array of dates sorted in assending order
   */
  public static fromDateArray(dates: DateTime[]): CDate {
    if (!dates || dates.length === 0) {
      return new CDate();
    }

    if (dates.length === 1) {
      return new CDate({ type: 'single', from: dates[0]?.toISODate() || '' });
    }

    return new CDate({
      type: 'range',
      from: dates[0]?.toISODate() || '',
      to: dates[dates.length - 1]?.toISODate() || '',
    });
  }

  public static fromPgDateRange(pgDate: string): CDate {
    if (!pgDate || pgDate.length === 0 || typeof pgDate !== 'string') {
      return new CDate();
    }

    let startInclusive = true;
    if (pgDate.startsWith('(')) {
      startInclusive = false;
    }

    let endInclusive = true;
    if (pgDate.endsWith(')')) {
      endInclusive = false;
    }

    let [startDate, endDate] = pgDate
      .substring(1, pgDate.length - 1)
      .split(',')
      .map((date: string): DateTime => DateTime.fromISO(date));

    // if not inclusive then we go to the next date
    if (!startInclusive) {
      startDate = startDate.plus({ days: 1 });
    }

    // if not inclusive then we go to the date before
    if (!endInclusive) {
      endDate = endDate.minus({ days: 1 });
    }

    return new CDate({
      type: 'range',
      from: startDate.toISODate() || '',
      to: endDate.toISODate() || '',
    });
  }

  constructor(data?: ICDateData | string) {
    if (data) {
      if (typeof data === 'string') {
        const cDate = CDate.fromPgDateRange(data);
        this.type = cDate.type;
        this.from = cDate.from;
        this.to = cDate.to;
      } else {
        this.type = data.type || data.to ? 'range' : 'single';
        this.from = data.from;
        this.to = data.to;
      }
    }
  }

  public fromDateArray(dates: DateTime[]): CDate {
    return CDate.fromDateArray(dates);
  }

  public fromPgDateRange(pgDate: string): CDate {
    return CDate.fromPgDateRange(pgDate);
  }

  public toDateArray(): DateTime[] {
    return dateRangeToDateArray(
      this.from ? DateTime.fromISO(this.from) : undefined,
      this.to ? DateTime.fromISO(this.to) : undefined,
    );
  }

  public toDateStringArray(): string[] {
    return dateRangeToDateStringArray(
      this.from ? DateTime.fromISO(this.from) : undefined,
      this.to ? DateTime.fromISO(this.to) : undefined,
    );
  }

  public available(dates: ICDate): boolean {
    const busyDays = this.toDateStringArray();
    const neededDays = dates.toDateStringArray();

    const busyDaysHash = busyDays.reduce((hash, dateString) => {
      hash[dateString] = dateString;
      return hash;
    }, {} as { [id: string]: string });

    for (const day of neededDays) {
      if (busyDaysHash[day]) {
        return false;
      }
    }

    return true;
  }

  public toString(): string {
    if (!this.to) {
      return this.from;
    }

    return `${this.from} - ${this.to}`;
  }

  public toDisplayString(): string {
    const from = DateTime.fromISO(this.from).toFormat('MM/dd/yyyy');

    if (!this.to) {
      return from;
    }

    // we always display the date day to day but store the nights of the stay
    const to = DateTime.fromISO(this.to).plus({ days: 1 }).toFormat('MM/dd/yyyy');

    return `${from} - ${to}`;
  }

  public toPgString(): string {
    return `[${this.from},${this.to ? this.to : this.from}]`;
  }
}
