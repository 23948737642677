import { NGRX_BUCKETS } from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthModalState } from './auth-modal.reducer';

// Lookup the 'AuthModal' feature state managed by NgRx
const getAuthModalState = createFeatureSelector<AuthModalState>(NGRX_BUCKETS.authModal);

export const getModal = createSelector(getAuthModalState, (state: AuthModalState) => state.modal);

export const authModalQuery = {
  getModal,
};
