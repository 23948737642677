import { IRV, ITax, NGRX_BUCKETS, RV, Tax, User } from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducer';

// Lookup the 'User' feature state managed by NgRx
export const getUserState = createFeatureSelector<UserState>(NGRX_BUCKETS.user);

export const getLoaded = createSelector(getUserState, (state: UserState) => state.loaded);

export const getError = createSelector(getUserState, (state: UserState) => state.error);

export const getAllUsers = createSelector(getUserState, getLoaded, (state: UserState, isLoaded) => {
  return isLoaded ? Object.keys(state.users).map<User>((id) => new User(state.users[parseInt(id, 10)])) : [];
});

export const getAllUserEntities = createSelector(getUserState, getLoaded, (state: UserState, isLoaded) => {
  if (isLoaded) {
    const instantiatedUsers: { [id: number]: User } = {};
    for (const key in state.users) {
      if (state.users.hasOwnProperty(key)) {
        instantiatedUsers[key] = new User(state.users[key]);
      }
    }

    return instantiatedUsers;
  }

  return {};
});

export const getSelectedId = createSelector(getUserState, (state: UserState) => state.selectedId);

export const getSelectedUser = createSelector(getAllUserEntities, getSelectedId, getLoaded, (users, id, isLoaded) => {
  return isLoaded && id && users[id] ? new User(users[id]) : null;
});

export const getCurrentId = createSelector(getUserState, (state: UserState): number | undefined => state.currentId);

export const getCurrentUser = createSelector(getAllUserEntities, getCurrentId, getLoaded, (users, id, isLoaded) => {
  return isLoaded && id && users[id] ? new User(users[id]) : new User();
});

export const getAllCurrentUserRVs = createSelector(getCurrentUser, (user: User): RV[] => {
  return user.rvs ? user.rvs.map((rv) => new RV(rv)) : [];
});

export const getSelectedRVId = createSelector(getUserState, (state: UserState) => state.selectedRVId);

export const getCurrentUserSelectedRV = createSelector(
  getAllCurrentUserRVs,
  getSelectedRVId,
  (rvs: IRV[], id): RV | undefined => {
    const result = rvs.find((it) => it.id === id);
    return result ? new RV(result) : undefined;
  },
);

export const getAllCurrentUserTaxes = createSelector(getCurrentUser, (user: User): Tax[] => {
  return user.taxes ? user.taxes.map((tax) => new Tax(tax)) : [];
});

export const getSelectedTaxId = createSelector(getUserState, (state: UserState) => state.selectedTaxId);

export const getCurrentUserSelectedTax = createSelector(
  getAllCurrentUserTaxes,
  getSelectedTaxId,
  (taxes: ITax[], id): Tax | undefined => {
    const result = taxes.find((it) => it.id === id);
    return result ? new Tax(result) : undefined;
  },
);
