import { Injectable } from '@angular/core';
import { forceInt, isInteger } from '@curbnturf/helpers';
import { DateTime } from 'luxon';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  buildMonths,
  checkDateInRange,
  checkMinBeforeMax,
  generateSelectBoxMonths,
  generateSelectBoxYears,
  isChangedDate,
  isChangedMonth,
  isDateSelectable,
  nextMonthDisabled,
  prevMonthDisabled,
} from './datepicker-tools';
import { DatePickerDayTemplateData, DatepickerViewModel, IsDisabled } from './datepicker-view-model';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DatepickerServiceInputs
  extends Partial<
    Pick<
      DatepickerViewModel,
      | 'dayTemplateData'
      | 'displayMonths'
      | 'disabled'
      | 'focusVisible'
      | 'isDisabled'
      | 'maxDate'
      | 'minDate'
      | 'navigation'
      | 'outsideDays'
    >
  > {}

@Injectable()
export class DatepickerService {
  private _VALIDATORS: {
    [K in keyof DatepickerServiceInputs]: (v: DatepickerServiceInputs[K]) => Partial<DatepickerViewModel> | undefined;
  } = {
    dayTemplateData: (dayTemplateData: DatePickerDayTemplateData) => {
      if (this._state.dayTemplateData !== dayTemplateData) {
        return { dayTemplateData };
      }

      return;
    },
    displayMonths: (displayMonths: number) => {
      displayMonths = forceInt(displayMonths);
      if (isInteger(displayMonths) && displayMonths > 0 && this._state.displayMonths !== displayMonths) {
        return { displayMonths };
      }

      return;
    },
    disabled: (disabled: boolean) => {
      if (this._state.disabled !== disabled) {
        return { disabled };
      }

      return;
    },
    focusVisible: (focusVisible: boolean) => {
      if (this._state.focusVisible !== focusVisible && !this._state.disabled) {
        return { focusVisible };
      }

      return;
    },
    isDisabled: (isDisabled: IsDisabled) => {
      if (this._state.isDisabled !== isDisabled) {
        return { isDisabled };
      }

      return;
    },
    maxDate: (date?: DateTime) => {
      if (!date) {
        return;
      }

      const maxDate = this.toValidDate(date);
      if (isChangedDate(this._state.maxDate, maxDate)) {
        return { maxDate };
      }

      return;
    },
    minDate: (date?: DateTime) => {
      if (!date) {
        return;
      }

      const minDate = this.toValidDate(date);
      if (isChangedDate(this._state.minDate, minDate)) {
        return { minDate };
      }

      return;
    },
    navigation: (navigation: 'select' | 'arrows' | 'none') => {
      if (this._state.navigation !== navigation) {
        return { navigation };
      }

      return;
    },
    outsideDays: (outsideDays: 'visible' | 'collapsed' | 'hidden') => {
      if (this._state.outsideDays !== outsideDays) {
        return { outsideDays };
      }

      return;
    },
  };

  private _model$ = new Subject<DatepickerViewModel>();

  private _dateSelect$ = new Subject<DateTime | undefined>();

  private _state: DatepickerViewModel = {
    disabled: false,
    displayMonths: 1,
    focusVisible: false,
    months: [],
    navigation: 'select',
    outsideDays: 'visible',
    prevDisabled: false,
    nextDisabled: false,
    selectBoxes: { years: [], months: [] },
  };

  get model$(): Observable<DatepickerViewModel> {
    return this._model$.pipe(filter((model) => model.months.length > 0));
  }

  get dateSelect$(): Observable<DateTime | undefined> {
    return this._dateSelect$.pipe(filter((date) => Boolean(date)));
  }

  set(options: DatepickerServiceInputs) {
    const patch = Object.keys(options)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((key) => (this._VALIDATORS as any)[key]((options as any)[key]))
      .reduce((obj, part) => ({ ...obj, ...part }), {});

    if (Object.keys(patch).length > 0) {
      this._nextState(patch);
    }
  }

  focus(date?: DateTime) {
    if (date && !this._state.disabled && date.isValid && isChangedDate(this._state.focusDate, date)) {
      this._nextState({ focusDate: date });
    }
  }

  focusSelect() {
    if (isDateSelectable(this._state.focusDate, this._state)) {
      this.select(this._state.focusDate, { emitEvent: true });
    }
  }

  open(date?: DateTime) {
    if (!date) {
      return;
    }

    const firstDate = this.toValidDate(date);
    if (!this._state.disabled && (!this._state.firstDate || isChangedMonth(this._state.firstDate, date))) {
      this._nextState({ firstDate });
    }
  }

  select(date?: DateTime, options: { emitEvent?: boolean } = {}) {
    if (date) {
      const selectedDate = this.toValidDate(date);
      if (!this._state.disabled) {
        if (isChangedDate(this._state.selectedDate, selectedDate)) {
          this._nextState({ selectedDate });
        }

        if (options.emitEvent && isDateSelectable(selectedDate, this._state)) {
          this._dateSelect$.next(selectedDate);
        }
      }
    }
  }

  toValidDate(date?: DateTime, defaultValue?: DateTime): DateTime {
    if (!defaultValue) {
      defaultValue = DateTime.now();
    }

    if (date) {
      return date && date.isValid ? date : defaultValue;
    }

    return defaultValue;
  }

  getMonth(struct: DateTime) {
    for (const month of this._state.months) {
      if (month && struct.month === month.number && struct.year === month.year) {
        return month;
      }
    }

    throw new Error(`Month ${struct.month} of year ${struct.year} not found.`);
  }

  private _nextState(patch: Partial<DatepickerViewModel>) {
    const newState = this._updateState(patch);
    this._patchContexts(newState);
    this._state = newState;
    this._model$.next(this._state);
  }

  private _patchContexts(state: DatepickerViewModel) {
    const { months, displayMonths, selectedDate, focusDate, focusVisible, disabled, outsideDays } = state;
    if (months) {
      months.forEach((month) => {
        if (month) {
          month.weeks.forEach((week) => {
            week.days.forEach((day) => {
              // patch focus flag
              if (focusDate) {
                day.context.focused = focusDate.equals(day.date) && focusVisible;
              }

              // calculating tabindex
              day.tabindex =
                !disabled && focusDate && day.date.equals(focusDate) && focusDate.month === (month && month.number)
                  ? 0
                  : -1;

              // override context disabled
              if (disabled === true) {
                day.context.disabled = true;
              }

              // patch selection flag
              if (selectedDate !== undefined) {
                day.context.selected = selectedDate && selectedDate.equals(day.date);
              }

              // visibility
              if (month.number !== day.date.month) {
                const initialMonth = months[0];
                const finalMonth = months[(displayMonths || 1) - 1];
                day.hidden =
                  outsideDays === 'hidden' ||
                  outsideDays === 'collapsed' ||
                  Boolean(
                    displayMonths &&
                      displayMonths > 1 &&
                      initialMonth?.firstDate &&
                      finalMonth?.lastDate &&
                      day.date > initialMonth.firstDate &&
                      day.date < finalMonth.lastDate,
                  );
              }
            });
          });
        }
      });
    }
  }

  private _updateState(patch: Partial<DatepickerViewModel>): DatepickerViewModel {
    // patching fields
    const state = Object.assign({}, this._state, patch);

    let startDate: DateTime | undefined = state.firstDate;

    // min/max dates changed
    if ('minDate' in patch || 'maxDate' in patch) {
      checkMinBeforeMax(state.minDate, state.maxDate);
      state.focusDate = checkDateInRange(state.focusDate, state.minDate, state.maxDate);
      state.firstDate = checkDateInRange(state.firstDate, state.minDate, state.maxDate);
      startDate = state.focusDate;
    }

    // disabled
    if ('disabled' in patch) {
      state.focusVisible = false;
    }

    // initial rebuild via 'select()'
    if ('selectedDate' in patch && this._state.months.length === 0) {
      startDate = state.selectedDate;
    }

    // terminate early if only focus visibility was changed
    if ('focusVisible' in patch) {
      return state;
    }

    // focus date changed
    if ('focusDate' in patch) {
      state.focusDate = checkDateInRange(state.focusDate, state.minDate, state.maxDate);
      startDate = state.focusDate;

      // nothing to rebuild if only focus changed and it is still visible
      if (
        state.months.length !== 0 &&
        state.focusDate &&
        state.firstDate &&
        state.lastDate &&
        state.focusDate >= state.firstDate &&
        state.focusDate <= state.lastDate
      ) {
        return state;
      }
    }

    // first date changed
    if ('firstDate' in patch) {
      state.firstDate = checkDateInRange(state.firstDate, state.minDate, state.maxDate);
      startDate = state.firstDate;
    }

    // rebuilding months
    if (startDate) {
      const forceRebuild =
        'dayTemplateData' in patch ||
        'isDisabled' in patch ||
        'minDate' in patch ||
        'maxDate' in patch ||
        'disabled' in patch ||
        'outsideDays' in patch;

      const months = buildMonths(startDate, state, forceRebuild);

      // updating months and boundary dates
      state.months = months;
      if (months && months.length > 0) {
        const initialMonth = months[0];
        state.firstDate = initialMonth ? initialMonth.firstDate : undefined;
        const lastMonth = months[months.length - 1];
        state.lastDate = lastMonth ? lastMonth.lastDate : undefined;
      } else {
        state.firstDate = undefined;
        state.lastDate = undefined;
      }

      // reset selected date if 'isDisabled' returns true
      if ('selectedDate' in patch && !isDateSelectable(state.selectedDate, state)) {
        delete state.selectedDate;
      }

      // adjusting focus after months were built
      if ('firstDate' in patch) {
        if (
          !state.focusDate ||
          (state.firstDate && state.focusDate < state.firstDate) ||
          (state.lastDate && state.focusDate > state.lastDate)
        ) {
          state.focusDate = startDate;
        }
      }

      // adjusting months/years for the select box navigation
      const yearChanged =
        !this._state.firstDate || !state.firstDate || this._state.firstDate.year !== state.firstDate.year;
      const monthChanged =
        !this._state.firstDate || !state.firstDate || this._state.firstDate.month !== state.firstDate.month;
      if (state.navigation === 'select') {
        // years ->  boundaries (min/max were changed)
        if (
          state.selectBoxes &&
          ('minDate' in patch || 'maxDate' in patch || state.selectBoxes.years.length === 0 || yearChanged)
        ) {
          state.selectBoxes.years = generateSelectBoxYears(state.firstDate, state.minDate, state.maxDate);
        }

        // months -> when current year or boundaries change
        if (
          state.selectBoxes &&
          ('minDate' in patch || 'maxDate' in patch || state.selectBoxes.months.length === 0 || yearChanged)
        ) {
          state.selectBoxes.months = generateSelectBoxMonths(state.firstDate, state.minDate, state.maxDate);
        }
      } else {
        state.selectBoxes = { years: [], months: [] };
      }

      // updating navigation arrows -> boundaries change (min/max) or month/year changes
      if (
        (state.navigation === 'arrows' || state.navigation === 'select') &&
        (monthChanged || yearChanged || 'minDate' in patch || 'maxDate' in patch || 'disabled' in patch)
      ) {
        state.prevDisabled = state.disabled || prevMonthDisabled(state.firstDate, state.minDate);
        state.nextDisabled = state.disabled || nextMonthDisabled(state.lastDate, state.maxDate);
      }
    }

    return state;
  }
}
