import { ActivityLocation, NGRX_BUCKETS, User } from '@curbnturf/entities';
import * as UserSelectors from '@curbnturf/user/src/lib/+state/user.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActivityState } from './activity.reducer';

// Lookup the 'Activity' feature state managed by NgRx
const getActivityFeatureState = createFeatureSelector<ActivityState>(NGRX_BUCKETS.activity);

const getActivityEntities = createSelector(
  getActivityFeatureState,
  (state: ActivityState) => state && state.activities,
);

const getSearchActivityEntities = createSelector(
  getActivityFeatureState,
  (state: ActivityState) => state && state.searchActivities,
);

const getAwaitedActivity = createSelector(getActivityFeatureState, (state: ActivityState) => state.awaitActivity);

const getError = createSelector(getActivityFeatureState, (state: ActivityState) => state.error);

const getActivities = createSelector(getActivityEntities, (activities): ActivityLocation[] =>
  Object.keys(activities)
    .map<ActivityLocation>((id) => new ActivityLocation(activities[parseInt(id, 10)]))
    .sort((aActivity, bActivity) => {
      if (!aActivity.distance && !bActivity.distance) {
        return 0;
      }

      if (!aActivity.distance) {
        return -1;
      }

      if (!bActivity.distance) {
        return 1;
      }

      return aActivity.distance - bActivity.distance;
    }),
);

const getCurrentUserActivities = createSelector(
  getActivities,
  UserSelectors.getCurrentUser,
  (activities: ActivityLocation[], user: User): ActivityLocation[] =>
    activities.filter((activity) => activity.user && activity.user.id === user.id),
);

export const getPendingImage = createSelector(getActivityFeatureState, (state: ActivityState) => state.pendingImage);

const getSelectedActivityId = createSelector(
  getActivityFeatureState,
  (state: ActivityState) => state && state.selected,
);

const getSelectedActivity = createSelector(getActivityEntities, getSelectedActivityId, (activities, selected) =>
  !selected && selected !== 0 ? undefined : new ActivityLocation(activities[selected]),
);

const getSearchActivities = createSelector(getSearchActivityEntities, (activities): ActivityLocation[] =>
  Object.keys(activities).map<ActivityLocation>((id) => new ActivityLocation(activities[parseInt(id, 10)])),
);

const getLastSearchParams = createSelector(getActivityFeatureState, (state: ActivityState) => state?.lastSearchParams);

export const activityQuery = {
  getActivityEntities,
  getActivities,
  getAwaitedActivity,
  getCurrentUserActivities,
  getError,
  getPendingImage,
  getSelectedActivity,
  getSearchActivities,
  getLastSearchParams,
};
