import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BASE_API_URL, ICalendarDay, ISchedule, ISite } from '@curbnturf/entities';
import { ScheduleDbSet } from '@curbnturf/network/src/lib/native/dbset/schedule.dbset';
import { SiteDbSet } from '@curbnturf/network/src/lib/native/dbset/site.dbset';
import { Observable, of } from 'rxjs';

const API_URL = BASE_API_URL + 'schedule';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  constructor(
    private http: HttpClient,
    private scheduleDbSet: ScheduleDbSet,
    private siteDbSet: SiteDbSet,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  create(schedule: ISchedule): Observable<ISchedule> {
    return this.http.post<ISchedule>(API_URL, { ...schedule });
  }

  fetch(confirmationCode: string): Observable<ISchedule> {
    return this.http.get<ISchedule>(`${API_URL}/${encodeURI(confirmationCode)}`);
  }

  fetchForSite(siteId: number): Observable<ISchedule[]> {
    return this.http.get<ISchedule[]>(`${API_URL}?siteId=${siteId}`);
  }

  fetchForUser(email: string): Observable<ISchedule[]> {
    return this.http.get<ISchedule[]>(`${API_URL}?email=${email}`);
  }

  remove(confirmationCode: string): Observable<ISchedule> {
    return this.http.delete<ISchedule>(`${API_URL}/${confirmationCode}`);
  }

  update(schedule: ISchedule): Observable<ISchedule> {
    return this.http.put<ISchedule>(`${API_URL}/${schedule.confirmationCode}`, {
      ...schedule,
    });
  }

  donate(confirmationCode: string, price: number): Observable<ISchedule> {
    return this.http.post<ISchedule>(`${API_URL}/${confirmationCode}/donate`, {
      confirmationCode,
      price,
    });
  }

  requestDays(siteId: number): Observable<ICalendarDay[]> {
    if (isPlatformServer(this.platformId)) {
      return of([]);
    } else {
      return this.http.get<ICalendarDay[]>(`${API_URL}/calendar/${siteId}`);
    }
  }

  preload(email: string) {
    this.fetchForUser(email).subscribe(async (schedules: ISchedule[]) => {
      const sites: ISite[] = [];
      schedules.forEach((schedule) => {
        if (schedule.site) {
          const siteFound = sites.find((el) => el.id === schedule.site?.id);
          if (!siteFound) {
            sites.push(schedule.site);
          }
        }
      });

      await this.scheduleDbSet.truncateAndStoreArray(schedules);
      await this.siteDbSet.storeArray(sites, true);
    });
  }
}
