import { Injectable } from '@angular/core';
import {
  CapacitorSQLite,
  CapacitorSQLitePlugin,
  capSQLiteChanges,
  capSQLiteValues,
  SQLiteConnection,
  SQLiteDBConnection,
} from '@capacitor-community/sqlite';
import { Preferences } from '@capacitor/preferences';
import { OFFLINE_DATABASE_NAME } from '@curbnturf/entities';
import { PlatformService } from '@curbnturf/network/src/lib/platform/platform.service';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject, firstValueFrom, of } from 'rxjs';
import { Logger } from '../log/logger';
import { migration as dbMigrationVersion, statements as dbCreateSql } from './db-versions/offline-data';
import { DbEntity } from './entities/db-entity';

export const DB_SETUP_VERSION_KEY = 'curbnturf-offline-data-version';
export const DEFAULT_DB_NAME = 'curbnturf-offline-data';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  dbReady = new BehaviorSubject(false);
  static db: SQLiteDBConnection;
  static sqlitePlugin: CapacitorSQLitePlugin = CapacitorSQLite;
  static sqlite: SQLiteConnection;
  private readonly dbName: string;

  constructor(private alertCtrl: AlertController, private platformService: PlatformService, private logger: Logger) {
    this.dbName = OFFLINE_DATABASE_NAME || DEFAULT_DB_NAME;
    DatabaseService.sqlite = new SQLiteConnection(DatabaseService.sqlitePlugin);
  }

  public async execute(statements: string): Promise<capSQLiteChanges> {
    this.logger.debug('Executing Statement', statements);
    return await DatabaseService.db.execute(statements, true);
  }

  public async query(statement: string, values: string[]): Promise<capSQLiteValues> {
    this.logger.debug('Executing Query Statement', statement);
    return await DatabaseService.db.query(statement, values);
  }

  public insert<T>(table: string, insertObject: T): Promise<capSQLiteValues> {
    let statement = `INSERT INTO ${table} (`;
    let valueString = '(';
    const values: string[] = [];

    for (const [key, value] of Object.entries(insertObject as any)) {
      // Allow boolean false and NULL through this defined gate
      if (value !== undefined) {
        statement += `${key}, `;
        valueString += '?, ';
        values.push(value as string);
      }
    }
    valueString = valueString.substring(0, valueString.length - 2);
    statement = statement.substring(0, statement.length - 2) + `) VALUES ${valueString});`;

    this.logger.debug('Executing INSERT Statement', statement);

    return DatabaseService.db.query(statement, values);
  }

  /**
   * Create a multiple INSERT statement
   * @param {string} table - the name of the table to insert into
   * @param {Array<T>>} insertArray - the array of values to insert into the table
   * @param {string[]} fields - the array of values to insert into the table
   * @param ignore - whether or not to ignore duplicate primary keys
   */
  public insertMulti<T extends DbEntity>(
    table: string,
    insertArray: Array<T>,
    fields?: string[],
    ignore: boolean = false,
  ): Promise<capSQLiteValues> {
    // Catch 0 length array.
    if (insertArray.length > 0) {
      const { statement, values } = this.generateInsertStatement<T>(table, insertArray, fields, ignore);
      this.logger.debug('Executing INSERT MULTI', { statement, values });
      return DatabaseService.db.query(statement, values);
    } else {
      return firstValueFrom(of({ values: [], error: 'No values submitted to insert.' }));
    }
  }

  /**
   * Create an UPDATE to the database.
   * @param {string} table
   * @param {object} updateObject - the object that is being updated into the database.
   * @param {object} where - an object that specifies where clauses by keys and values.
   */
  public update<T extends DbEntity>(
    table: string,
    updateObject: T,
    where?: Record<string, string | number>,
  ): Promise<capSQLiteValues> {
    let statement = `UPDATE ${table} SET `;
    const values: (string | number)[] = [];

    for (const [key, value] of Object.entries(updateObject)) {
      // Allow boolean false and NULL through this defined gate
      if (value !== undefined) {
        statement += `${key} = ?, `;
        values.push(value);
      }
    }
    statement = statement.substring(0, statement.length - 2);

    // Apply WHERE clause if any
    if (where) {
      statement += ' WHERE ';
      for (const [key, value] of Object.entries(where)) {
        // Allow boolean false and NULL through this defined gate
        if (value !== undefined) {
          statement += `${key} = ? AND `;
          values.push(value);
        }
      }
    }

    statement = statement.substring(0, statement.length - 5) + ';';

    this.logger.debug('Executing UPDATE Statement', statement);

    return DatabaseService.db.query(statement, values);
  }

  /**
   * Create an UPSERT to the database.
   * @param {string} table
   * @param {object} insertObject - the object that is being upserted into the database.
   * @param {object} updateFields - array that specifies fields to be updated in the database upon conflict.
   * @param {object} conflictTarget - the field that has unique constraint to trigger the update.
   */
  public upsert<T extends DbEntity>(
    table: string,
    insertObject: T,
    updateFields?: string[],
    conflictTarget?: string,
  ): Promise<capSQLiteValues> {
    let statement = `INSERT INTO ${table} (`;
    let valueString = '(';
    const values: string[] = [];

    for (const [key, value] of Object.entries(insertObject)) {
      // Allow boolean false and NULL through this defined gate
      if (value !== undefined) {
        statement += `${key}, `;
        valueString += '?, ';
        values.push(value);
      }
    }
    valueString = valueString.substring(0, valueString.length - 2);
    statement = statement.substring(0, statement.length - 2) + `) VALUES ${valueString}) ON CONFLICT`;

    if (conflictTarget) {
      statement += `(${conflictTarget})`;
    }

    if (updateFields) {
      statement += ' DO UPDATE SET ';
      updateFields?.forEach((field) => {
        statement += `${field}=excluded.${field}, `;
      });
      statement = statement.substring(0, statement.length - 2) + ';';
    } else {
      statement += ' DO NOTHING;';
    }

    this.logger.debug('Executing UPSERT Statement', statement);

    return DatabaseService.db.query(statement, values);
  }

  public async selectAll(
    tableName: string,
    selectColumns?: string[],
    limit?: number,
    offset?: number,
  ): Promise<capSQLiteValues> {
    let columns = '*';
    if (selectColumns && selectColumns.length > 0) {
      columns = selectColumns.join(', ');
    }

    let statement = `SELECT ${columns} FROM ${tableName}`;

    if (limit) {
      statement += ` LIMIT ${limit}`;
      if (offset) {
        statement += ` OFFSET ${offset}`;
      }
    }

    statement += ';';

    this.logger.debug('Executing SELECT All Statement', statement);

    try {
      if (DatabaseService.db && (await DatabaseService.db.isDBOpen())) {
        return await DatabaseService.db.query(statement, []);
      } else {
        this.logger.warning('Tried to query database when connection is not established');
      }
    } catch (e) {
      this.logger.error('SELECT ALL Query Failed', { error: e });
    }
    return { values: [] };
  }

  /**
   * Create a SELECT Statement with optional column filters and WHERE clauses.
   * @param {string} tableName - name of the table to query
   * @param {object} filters - an object where the property names anv values become the left and right side of a WHERE clause respectively.
   * @param {string[]} selectColumns - the list of columns to select from the database.
   */
  public async select(
    tableName: string,
    filters?: Record<string, string | number>,
    selectColumns?: string[],
  ): Promise<capSQLiteValues> {
    let columns = '*';
    if (selectColumns && selectColumns.length > 0) {
      columns = selectColumns.join(', ');
    }

    let statement = `SELECT ${columns} FROM ${tableName}`;

    const values = [];
    if (filters) {
      statement += ' WHERE';
      const filterArray: string[] = [];
      for (const [key, value] of Object.entries(filters)) {
        // Allow boolean false and NULL through this defined gate
        if (value !== undefined) {
          filterArray.push(`${key} = ?`);
          values.push(value);
        }
      }
      statement += ' ' + filterArray.join(' AND ');
    }

    statement += ';';

    this.logger.debug('Executing SELECT Statement', statement);

    return await DatabaseService.db.query(statement, values);
  }

  public async delete(table: string, where?: Record<string, string | number>): Promise<capSQLiteValues> {
    let statement = `DELETE FROM ${table} `;
    const values: (string | number)[] = [];

    // Apply WHERE clause if any
    if (where) {
      statement += ' WHERE ';
      for (const [key, value] of Object.entries(where)) {
        // Allow boolean false and NULL through this defined gate
        if (value !== undefined) {
          statement += `${key} = ? AND `;
          values.push(value);
        }
      }
    }

    statement = statement.substring(0, statement.length - 5) + ';';

    this.logger.debug('Executing DELETE Statement', statement);

    return DatabaseService.db.query(statement, values);
  }

  public async truncateTable(table: string): Promise<capSQLiteChanges> {
    const statements = `TRUNCATE TABLE ${table};`;
    return DatabaseService.db.execute(statements, true);
  }

  public generateInsertStatement<T extends DbEntity>(
    table: string,
    items: Array<T>,
    fields?: string[],
    ignore: boolean = false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): { statement: string; values: any[] } {
    // INSERT or INSERT OR IGNORE
    let statement;
    if (ignore) {
      statement = `INSERT OR IGNORE INTO ${table} (`;
    } else {
      statement = `INSERT INTO ${table} (`;
    }

    // INSERT Fields
    let fieldString;
    if (fields && fields.length > 0) {
      fieldString = fields.join(', ');
    } else {
      fieldString = '';
      for (const [key, value] of Object.entries(items[0])) {
        // Allow boolean false and NULL through this defined gate
        if (value !== undefined) {
          fieldString += `${key}, `;
        }
      }
      fieldString = fieldString.substring(0, fieldString.length - 2);
    }

    // Setup Value String
    let valueString = '';
    const values: (number | string | null)[] = [];
    items.forEach((itemObject) => {
      const entries = Object.entries(itemObject);
      valueString += '(';
      if (fields && fields.length > 0) {
        fields.forEach((field) => {
          valueString += '?, ';
          const value = entries
            .filter((el) => el[0] === field)
            .pop()
            ?.pop();
          if (value === undefined) {
            values.push(null);
          } else {
            values.push(value);
          }
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [key, value] of entries) {
          // Allow boolean false and NULL through this defined gate
          if (value !== undefined) {
            valueString += '?, ';
            values.push(value);
          }
        }
      }
      valueString = valueString.substring(0, valueString.length - 2) + '), ';
    });
    valueString = valueString.substring(0, valueString.length - 2);

    // Construct Final Statement
    statement += fieldString + `) VALUES ${valueString};`;
    return { statement, values };
  }

  async init(): Promise<void> {
    this.logger.debug('Initializing Database');
    await this.setupDatabase();
  }

  public async rebuildDatabase() {
    await this.setupDatabase();
  }

  public async removeAndRebuildDatabase() {
    try {
      await DatabaseService.sqlitePlugin.deleteDatabase({ database: this.dbName });
      await DatabaseService.db.close();
      await DatabaseService.sqlite.closeConnection(this.dbName, false);
    } catch (e) {
      this.logger.error('Error While Deleting Database', e);
    }

    try {
      DatabaseService.db = await DatabaseService.sqlite.createConnection(this.dbName, false, 'no-encryption', 1, false);
      await DatabaseService.db.open();
      await this.createDatabaseStructure();
    } catch (e) {
      this.logger.error('Error While Recreating Database', e);
    }
  }

  public async deleteDatabase() {
    this.logger.info('Deleting Database', this.dbName);
    if (!DatabaseService.db || (DatabaseService.db && !(await DatabaseService.db.isExists()))) {
      // Create new SQLite Connection
      await this.createConnection();
    }
    if (DatabaseService.db && !(await DatabaseService.db.isDBOpen())) {
      this.logger.debug('Opening Database');
      await DatabaseService.db.open();
    }
    await DatabaseService.sqlitePlugin.deleteDatabase({ database: this.dbName });
    await DatabaseService.sqlite.closeConnection(this.dbName, false);
  }

  async setupDatabase() {
    try {
      this.logger.debug('Beginning Database Setup', this.dbName);
      const dbVersion = await Preferences.get({ key: DB_SETUP_VERSION_KEY });
      this.logger.debug('Database Version From Storage ' + JSON.stringify(dbVersion));

      // Clean up any existing connections
      if (DatabaseService.db && (await DatabaseService.db.isExists())) {
        if (await DatabaseService.db.isDBOpen()) {
          this.logger.debug('Closing Existing Database');
          await DatabaseService.db.close();
        }
        this.logger.debug('Closing Existing DB Connection.');
        await DatabaseService.sqlite.closeConnection(this.dbName, false);
      }

      // Create new SQLite Connection
      await this.createConnection();

      // Open the database
      await DatabaseService.db.open();
      this.logger.debug('Database Connection Opened', {
        database: this.dbName,
        sqlite: DatabaseService.sqlite,
        connection: DatabaseService.db,
      });

      // Check if a migration is needed
      if (!dbVersion.value || dbVersion.value < dbMigrationVersion) {
        this.logger.debug(`Migrating Database from ${dbVersion.value} to ${dbMigrationVersion}`);
        await this.createDatabaseStructure();
      } else {
        this.logger.debug(
          'SQLite Database Version',
          (await this.query('SELECT sqlite_version() as version', [])).values?.pop()?.version,
        );
        this.dbReady.next(true);
      }
    } catch (e) {
      this.logger.error('Error completing local database setup.', { error: e });
    }
  }

  /**
   * Creates the connection to SQLite Database
   */
  async createConnection() {
    try {
      DatabaseService.db = await DatabaseService.sqlite.createConnection(this.dbName, false, 'no-encryption', 1, false);
      this.logger.debug('Created Database Connection', {
        database: this.dbName,
        sqlite: DatabaseService.sqlite,
        db: DatabaseService.db,
      });
    } catch (e) {
      this.logger.error('Failed to create local database connection', { error: e });
    }
  }

  async createDatabaseStructure() {
    try {
      this.logger.debug('Executing Migration Script', JSON.stringify(dbCreateSql));
      await DatabaseService.db.execute(dbCreateSql, true);
    } catch (e) {
      this.logger.error('Failed to create database.', JSON.stringify(e));
    }
    await Preferences.set({ key: DB_SETUP_VERSION_KEY, value: dbMigrationVersion });
    this.dbReady.next(true);
  }
}
