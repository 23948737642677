import { NgModule } from '@angular/core';
import { NetworkModule } from '@curbnturf/network';
import { StatusFacade } from '@curbnturf/status';
import { IonicModule } from '@ionic/angular';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as tripPlannerEffects from './+state/trip-planner.effects';
import * as fromTripPlanner from './+state/trip-planner.reducer';

@NgModule({
  imports: [IonicModule, NetworkModule],
  providers: [
    StatusFacade,
    provideState(fromTripPlanner.TRIP_PLANNER_FEATURE_KEY, fromTripPlanner.reducer),
    provideEffects(tripPlannerEffects),
  ],
})
export class TripPlannerModule {}
