import { AbstractControl, ValidatorFn } from '@angular/forms';

export function equalTo(equalControl: AbstractControl): ValidatorFn {
  let subscribe = false;

  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!subscribe) {
      subscribe = true;
      equalControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }

    return equalControl.value === control.value ? null : { equalTo: true };
  };
}
