import { ICachableHttpRequest } from '../interfaces';

export class CachableHttpRequest implements ICachableHttpRequest {
  static readonly METHOD_GET: string = 'GET';
  static readonly METHOD_POST: string = 'POST';
  static readonly METHOD_PUT: string = 'PUT';
  static readonly METHOD_PATCH: string = 'PATCH';
  static readonly METHOD_DELETE: string = 'DELETE';
  static readonly MAX_RETRIES: number = 5;

  public id?: number;
  public method: string;
  public url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public body?: any | null;

  public options?: {
    headers?: {
      [header: string]: string | string[];
    };
    observe?: string;
    params?: {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    cachableResponse?: boolean;
  };

  public syncId?: string;
  public syncAction?: { type: string };

  public retries: number = 0;

  constructor(data?: ICachableHttpRequest) {
    if (data) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this as any)[key] = (data as any)[key];
        }
      }
    }
  }
}
