export enum LandType {
  backyard = 'Backyard',
  beach = 'Beach',
  brewery = 'Brewery',
  canyon = 'Canyon',
  coastal = 'Coastal',
  curbside = 'Curbside',
  desert = 'Desert',
  distillery = 'Distillery',
  driveway = 'Driveway',
  farm = 'Farmland',
  forest = 'Forest',
  hotSpring = 'Hot Spring',
  lake = 'Lake',
  mountains = 'Mountains',
  orchard = 'Orchard',
  plain = 'Plain / Prairie',
  stream = 'River / Stream',
  suburban = 'Suburban',
  urban = 'Urban',
  waterfall = 'Waterfall',
  wetlands = 'Wetlands',
  winery = 'Winery',
}

export interface ILandType {
  type:
    | 'backyard'
    | 'beach'
    | 'brewery'
    | 'canyon'
    | 'coastal'
    | 'curbside'
    | 'desert'
    | 'distillery'
    | 'driveway'
    | 'farm'
    | 'forest'
    | 'hotSpring'
    | 'lake'
    | 'mountains'
    | 'orchard'
    | 'plain'
    | 'stream'
    | 'suburban'
    | 'urban'
    | 'waterfall'
    | 'wetlands'
    | 'winery';
}
