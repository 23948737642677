import { IUser, Role, User } from '@curbnturf/entities';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export interface AuthState {
  user?: IUser; // A user ID
  tempRole?: Role;
  loggedIn: boolean; // If they are logged in
  noRedirect?: boolean;
  loaded: boolean; // has the Auth list been loaded
  error?: string; // last none error (if any)
  attemptEmail?: string;
  newPasswordSet?: boolean;
}

export const initialState: AuthState = {
  loggedIn: false,
  loaded: false,
  newPasswordSet: false,
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess, (state, action) => {
    return {
      ...state,
      user: new User({ ...action.user }),
      loggedIn: true,
      loaded: true,
      newPasswordSet: false,
    };
  }),
  on(AuthActions.noRedirect, (state, action) => {
    return {
      ...state,
      noRedirect: true,
    };
  }),
  on(AuthActions.logoutError, () => {
    return {
      user: undefined,
      tempRole: undefined,
      loggedIn: false,
      noRedirect: undefined,
      loaded: true,
      error: undefined,
      attemptEmail: undefined,
      newPasswordSet: false,
    };
  }),
  on(AuthActions.logoutSuccess, () => {
    return {
      user: undefined,
      tempRole: undefined,
      loggedIn: false,
      noRedirect: undefined,
      loaded: true,
      error: undefined,
      attemptEmail: undefined,
      newPasswordSet: false,
    };
  }),
  on(AuthActions.login, (state, action) => {
    return {
      ...state,
      error: undefined,
      attemptEmail: action.email,
    };
  }),
  on(AuthActions.loginError, (state, action) => {
    const error = action.error?.error?.message || action.error?.message || action.error;

    return {
      ...state,
      error,
    };
  }),
  on(AuthActions.recoverPasswordSuccess, (state, action) => {
    return {
      ...state,
      attemptEmail: action.email,
    };
  }),
  on(AuthActions.resendSuccess, (state, action) => {
    return {
      ...state,
      attemptEmail: undefined,
    };
  }),
  on(AuthActions.resetPasswordSuccess, (state, action) => {
    return {
      ...state,
      attemptEmail: undefined,
    };
  }),
  on(AuthActions.newPasswordSuccess, (state, action) => {
    return {
      ...state,
      newPasswordSet: true,
    };
  }),
  on(AuthActions.setTempRole, (state, action) => {
    return {
      ...state,
      tempRole: action.role,
    };
  }),
  on(AuthActions.clearTempRole, (state) => {
    return {
      ...state,
      tempRole: undefined,
    };
  }),
  on(AuthActions.notLoggedIn, (state) => {
    return {
      ...state,
      loaded: true,
      loggedIn: false,
      user: undefined,
    };
  }),
);

export function logoutMetaReducer(reducer: ActionReducer<any>) {
  return (state: any, action: any) => {
    if (action.type === AuthActions.logoutSuccess.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
