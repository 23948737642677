import { Pipe, PipeTransform } from '@angular/core';
import { timeSimplify } from '@curbnturf/helpers';

@Pipe({
    name: 'timeSimplify',
    standalone: true,
})
export class TimeSimplifyPipe implements PipeTransform {
  transform(value: string): string {
    return timeSimplify(value);
  }
}
