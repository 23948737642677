import { Injectable } from '@angular/core';
import { BASE_API_URL, INewsletterSubscriptionStatus, INotificationSetting, IUser } from '@curbnturf/entities';
import { CachedHttpClient } from '@curbnturf/network';
import { Observable } from 'rxjs';

const API_URL = `${BASE_API_URL}notification-settings`;

@Injectable({
  providedIn: 'root',
})
export class UserMarketingService {
  constructor(private http: CachedHttpClient) {}

  getStatus(): Observable<INewsletterSubscriptionStatus> {
    return this.http.get<INewsletterSubscriptionStatus>(`${API_URL}/newsletter`);
  }

  public update(notificationItem: INotificationSetting): Observable<INotificationSetting> {
    return this.http.post(`${API_URL}`, notificationItem);
  }

  registerForNewsletter(user: IUser): Observable<INewsletterSubscriptionStatus> {
    if (user.email) {
      return this.http.post<INewsletterSubscriptionStatus>(`${API_URL}/newsletter`, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      });
    } else {
      throw new Error('User Not Provided.');
    }
  }

  updateSubscription(user: IUser, subscribe: boolean): Observable<INewsletterSubscriptionStatus> {
    if (user.id) {
      if (subscribe) {
        return this.http.put(`${API_URL}/newsletter`, { status: 'subscribed', user: { id: user.id } });
      } else {
        return this.http.put(`${API_URL}/newsletter`, { status: 'unsubscribed', user: { id: user.id } });
      }
    } else {
      throw new Error('User Not Provided.');
    }
  }

  removeNewsletterSubscription(user: IUser): Observable<any> {
    if (user.id) {
      return this.http.delete(`${API_URL}/newsletter/${user.id}`);
    } else {
      throw new Error('User Not Provided.');
    }
  }
}
