import { InjectionToken } from '@angular/core';

export const defaultErrors = {
  required: () => `Required`,
  requiredTrue: () => `Must accept`,
  minLength: ({ requiredLength, actualLength }: { requiredLength: number; actualLength: number }) =>
    `Must be at least ${requiredLength} characters long but got ${actualLength}`,
  minlength: ({ requiredLength, actualLength }: { requiredLength: number; actualLength: number }) =>
    `Must be at least ${requiredLength} characters long but got ${actualLength}`,
  maxLength: ({ requiredLength, actualLength }: { requiredLength: number; actualLength: number }) =>
    `Cannot be more then ${requiredLength} characters long but got ${actualLength}`,
  maxlength: ({ requiredLength, actualLength }: { requiredLength: number; actualLength: number }) =>
    `Cannot be more then ${requiredLength} characters long but got ${actualLength}`,
  pattern: () => `Must have at least 1 lowercase letter, 1 uppercase letter, 1 number, and be over 8 characters long.`,
  email: () => `Not a valid email address`,
  min: ({ min, actual }: { min: number; actual: number }) => `Expect greater than ${min} but got ${actual}`,
  max: ({ max, actual }: { max: number; actual: number }) => `Expect less than ${max} but got ${actual}`,
  cardNumber: () => `Invalid`,
  cardExpiry: () => `Invalid`,
  cardCvc: () => `Invalid`,
  equalTo: () => `Must match password`,
  rvLicense: () =>
    `Reservations require either the RV license plate data or if a rental without access to the license plate information you can provide a URL to the RV from a website`,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors,
});

export const defaultArrayErrors = {
  required: () => `Required`,
  minLength: ({ requiredLength, actualLength }: { requiredLength: number; actualLength: number }) =>
    `Must have at least ${requiredLength} items selected but there are only ${actualLength}`,
  minlength: ({ requiredLength, actualLength }: { requiredLength: number; actualLength: number }) =>
    `Must have at least ${requiredLength} items selected but there are only ${actualLength}`,
  maxLength: ({ requiredLength, actualLength }: { requiredLength: number; actualLength: number }) =>
    `Cannot have more then ${requiredLength} items selected but there are ${actualLength}`,
  maxlength: ({ requiredLength, actualLength }: { requiredLength: number; actualLength: number }) =>
    `Cannot have more then ${requiredLength} items selected but there are ${actualLength}`,
};

export const FORM_ARRAY_ERRORS = new InjectionToken('FORM_ARRAY_ERRORS', {
  providedIn: 'root',
  factory: () => defaultArrayErrors,
});
