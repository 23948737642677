import { ISiteRating, ListingType, NGRX_BUCKETS, Role, Site, SiteRating, User } from '@curbnturf/entities';
import { siteComplete } from '@curbnturf/helpers';
import { ratingQuery } from '@curbnturf/rating';
import * as UserSelectors from '@curbnturf/user/src/lib/+state/user.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SiteState } from './site.reducer';

export const getSiteFeatureState = createFeatureSelector<SiteState>(NGRX_BUCKETS.site);

export const getSiteEntities = createSelector(getSiteFeatureState, (state: SiteState) => {
  if (state && state.sites) {
    const instantiatedSites: { [id: number]: Site } = {};
    state.sites.forEach((site) => {
      if (site.id) {
        instantiatedSites[site.id] = new Site(site);
      }
    });

    return instantiatedSites;
  }

  return {};
});

export const getSites = createSelector(getSiteFeatureState, (state: SiteState): Site[] =>
  state.sites.map((el) => new Site({ ...el })),
);

export const getError = createSelector(getSiteFeatureState, (state: SiteState): string | undefined => state && state.error);

export const getLoaded = createSelector(getSiteFeatureState, (state: SiteState): boolean => (state && state.loaded) || false);

export const getStandardSites = createSelector(getSites, (sites): Site[] =>
  sites.filter((site) => site.type === ListingType.standard),
);

export const getBoondockSites = createSelector(getSites, (sites): Site[] =>
  sites.filter((site) => site.type === ListingType.boondock),
);

export const getCurrentUserSites = createSelector(
  getSites,
  UserSelectors.getCurrentUser,
  (sites: Site[], user: User): Site[] => sites.filter((site) => site.user && site.user.id === user.id),
);

export const getSitesReadyToEnable = createSelector(
  getSites,
  UserSelectors.getCurrentUser,
  (sites: Site[], user: User): Site[] =>
    sites.filter(
      (site) =>
        site.user &&
        (site.user.id === user.id || user.role === Role.admin) &&
        !site.available &&
        siteComplete.completed({ ...site, user }),
    ),
);

export const getSelectedSiteId = createSelector(getSiteFeatureState, (state) => state && state.selected);

export const getSelectedSite = createSelector(getSites, getSelectedSiteId, (sites, selected) =>
  !selected ? null : new Site(sites.find((el) => el.id === selected || el.syncId === selected)),
);

export const getSelectedRatings = createSelector(
  ratingQuery.getAllSiteRatings,
  getSelectedSiteId,
  (ratings: ISiteRating[], siteId: number) => {
    return ratings
      .filter((rating) => rating.siteRated && rating.siteRated.id === siteId)
      .map((rating) => new SiteRating(rating));
  },
);
