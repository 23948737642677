<form *ngIf="rvForm" [formGroup]="rvForm">
  <div class="rv-grid garage">
    <label [style.opacity]="selectedType('classA') ? 1 : 0.5" class="rv-button">
      <input type="radio" name="type" [formControl]="type" value="classA" (click)="onSelect($event)" />
      <div class="rv-button-icon">
        <img src="/img/wf/Class-A_CNT.svg" alt="Class A" />
      </div>
      <div class="rv-button-copy">Class A</div>
    </label>
    <label [style.opacity]="selectedType('classC') ? 1 : 0.5" class="rv-button">
      <input type="radio" name="type" [formControl]="type" value="classC" (click)="onSelect($event)" />
      <div class="rv-button-icon">
        <img src="/img/wf/Class-C_CNT.svg" alt="Class C" />
      </div>
      <div class="rv-button-copy">Class C</div>
    </label>
    <label [style.opacity]="selectedType('classB') ? 1 : 0.5" class="rv-button">
      <input type="radio" name="type" [formControl]="type" value="classB" (click)="onSelect($event)" />
      <div class="rv-button-icon">
        <img src="/img/wf/Class-B_CNT.svg" alt="Class B" />
      </div>
      <div class="rv-button-copy">Class B</div>
    </label>
    <label [style.opacity]="selectedType('fifthWheel') ? 1 : 0.5" class="rv-button">
      <input type="radio" name="type" [formControl]="type" value="fifthWheel" (click)="onSelect($event)" />
      <div class="rv-button-icon">
        <img src="/img/wf/FifthWheel_CNT.svg" alt="Fifth Wheel" />
      </div>
      <div class="rv-button-copy">Fifth Wheel</div>
    </label>
    <label [style.opacity]="selectedType('travelTrailer') ? 1 : 0.5" class="rv-button">
      <input type="radio" name="type" [formControl]="type" value="travelTrailer" (click)="onSelect($event)" />
      <div class="rv-button-icon">
        <img src="/img/wf/Travel_CNT.svg" alt="Travel Trailer" />
      </div>
      <div class="rv-button-copy">Travel Trailer</div>
    </label>
    <label [style.opacity]="selectedType('truckCamper') ? 1 : 0.5" class="rv-button">
      <input type="radio" name="type" [formControl]="type" value="truckCamper" (click)="onSelect($event)" />
      <div class="rv-button-icon">
        <img src="/img/wf/TruckCamper_CNT.svg" alt="Truck Camper" />
      </div>
      <div class="rv-button-copy">Truck Camper</div>
    </label>
    <label [style.opacity]="selectedType('teardrop') ? 1 : 0.5" class="rv-button">
      <input type="radio" name="type" [formControl]="type" value="teardrop" (click)="onSelect($event)" />
      <div class="rv-button-icon">
        <img src="/img/wf/TearDrop_CNT.svg" alt="Teardrop Trailer" />
      </div>
      <div class="rv-button-copy">TearDrop</div>
    </label>
    <label [style.opacity]="selectedType('popup') ? 1 : 0.5" class="rv-button">
      <input type="radio" name="type" [formControl]="type" value="popup" (click)="onSelect($event)" />
      <div *ngIf="!selectedType('popup')" class="rv-button-icon">
        <img src="/img/wf/Popup_CNT.svg" alt="Popup Trailer Unselected" />
      </div>
      <div *ngIf="selectedType('popup')" class="rv-button-icon">
        <img src="/img/wf/Popup_Expanded_CNT_1.svg" alt="Popup Trailer Selected" />
      </div>
      <div class="rv-button-copy">Popup Trailer</div>
    </label>
  </div>
</form>
