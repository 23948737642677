<h2 class="heading-2 white">Lost access to your account?</h2>
<p class="white">Not a problem. Enter your email address to regain access.</p>
<div class="w-form white">
  <form [formGroup]="emailForm" (ngSubmit)="recover()">
    <input
      formControlName="email"
      required="true"
      type="email"
      [email]="true"
      class="_48px-text-field w-input"
      placeholder="Email"
    />

    <button type="submit" class="cnt-button nandor w-inline-block">
      <div class="cnt-button-copy">Recover</div>
    </button>
  </form>
  <div class="_18-px-spacer"></div>
  <a (click)="openLoginModal()" *ngIf="inModal" class="auth-link"> Return to log in </a>
  <a [routerLink]="[loginPath]" *ngIf="!inModal" class="auth-link"> Return to log in </a>
</div>
