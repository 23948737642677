import { NgModule } from '@angular/core';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { NetworkModule } from '@curbnturf/network';
import { UserModule } from '@curbnturf/user';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as scheduleEffects from './+state/schedule.effects';
import { scheduleReducer } from './+state/schedule.reducer';

@NgModule({
  imports: [UserModule, NetworkModule],
  providers: [provideState(NGRX_BUCKETS.schedule, scheduleReducer), provideEffects(scheduleEffects)],
})
export class ScheduleModule {}
