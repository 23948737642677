import { Injectable } from '@angular/core';
import {
  ActivityLocationSearchActivities,
  BASE_API_URL,
  IActivityLocation,
  IActivityLocationSearchRequest,
  IListingSearchQuery,
  IPhoto,
  IUser,
  LatLon,
} from '@curbnturf/entities';
import { CachedHttpClient, DataCacheDbSet, Logger, PoiListingDbSet } from '@curbnturf/network';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

const API_URL = BASE_API_URL + 'activity-location';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  constructor(
    private http: CachedHttpClient,
    private logger: Logger,
    private dataCache: DataCacheDbSet,
    private poiListingDbSet: PoiListingDbSet,
  ) {}

  create(activity: IActivityLocation): Observable<IActivityLocation> {
    return this.http.post<IActivityLocation>(API_URL, this.formatForServer(activity));
  }

  fetch(activityId: number): Observable<IActivityLocation> {
    return this.http.get<IActivityLocation>(`${API_URL}/${activityId}`);
  }

  fetchByCoordinates(lat: number, lon: number): Observable<IActivityLocation[]> {
    return this.http.get<IActivityLocation[]>(`${API_URL}?lat=${lat}&lon=${lon}`);
  }

  fetchForSite(siteId: number): Observable<IActivityLocation[]> {
    return this.http.get<IActivityLocation[]>(`${API_URL}?siteId=${siteId}`);
  }

  fetchForUser(userId: number): Observable<IActivityLocation[]> {
    return this.http.get<IActivityLocation[]>(`${API_URL}?userId=${userId}`);
  }

  fetchForSearch(query: IActivityLocationSearchRequest): Observable<IActivityLocation[]> {
    return this.http.get<IActivityLocation[]>(`${API_URL}?${this.toQuery(query)}`);
  }

  async preload(query?: IActivityLocationSearchRequest) {
    if (!query) {
      query = {
        selected: {
          activity: true,
          dump: true,
          restArea: true,
          wifi: true,
          potableWater: true,
          propane: true,
        },
        activities: [...ActivityLocationSearchActivities],
      };
    }

    this.logger.debug('Preloading POI Data');

    this.http
      .get<IActivityLocation[]>(`${API_URL}?${this.toQuery(query)}`, undefined, {
        offlineCallback: () => [],
      })
      .subscribe(async (results: IActivityLocation[]) => {
        // Save the set
        await this.poiListingDbSet.truncate();

        // Split the results into chunks to make them easier to insert.
        let arrayOffset = 0;
        const chunkAmount = 500;
        do {
          let arrayOffsetEnd = arrayOffset + chunkAmount;
          if (arrayOffsetEnd > results.length) {
            arrayOffsetEnd = results.length;
          }
          const subResults = results.slice(arrayOffset, arrayOffsetEnd);
          await this.poiListingDbSet.storeArray(subResults);
          arrayOffset = arrayOffsetEnd + 1;
        } while (arrayOffset < results.length);

        await this.dataCache.store({
          cacheName: 'points_of_interest',
          lastModified: DateTime.now().toMillis(),
        });
      });
  }

  async retrieveCachedListings(
    query: IListingSearchQuery,
    currentLocation: LatLon,
    take?: number,
    skip?: number,
  ): Promise<IActivityLocation[]> {
    const results = await this.poiListingDbSet.retrieveFiltered(query, take, skip);

    console.log('Activity Results', results.length, JSON.stringify(results));

    // calculate and sort by distance from current location.
    results.sort((a, b) => {
      const aDistance = a.distance
        ? a.distance
        : currentLocation.distanceTo(
            new LatLon({
              lat: a.coordinates?.lat || 0,
              lon: a.coordinates?.lon || 0,
            }),
          );
      a.distance = aDistance;
      const bDistance = b.distance
        ? b.distance
        : currentLocation.distanceTo(
            new LatLon({
              lat: b.coordinates?.lat || 0,
              lon: b.coordinates?.lon || 0,
            }),
          );
      b.distance = bDistance;
      return aDistance - bDistance;
    });

    return results;
  }

  remove(activityId: number): Observable<IActivityLocation> {
    return this.http.delete<IActivityLocation>(`${API_URL}/${activityId}`);
  }

  restore(activityId: number): Observable<IActivityLocation> {
    return this.http.post<IActivityLocation>(`${API_URL}/${activityId}/restore`, null);
  }

  update(activity: IActivityLocation): Observable<IActivityLocation> {
    return this.http.put<IActivityLocation>(`${API_URL}/${activity.id}`, this.formatForServer(activity));
  }

  removeImage(activityId: number, photoIndex: number): Observable<IPhoto[]> {
    return this.http.delete<IPhoto[]>(`${API_URL}/${activityId}/remove-image/${photoIndex}`);
  }

  uploadImage(activityId: number, formData: FormData): Observable<{ activityId: number; photos: IPhoto[] }> {
    return this.http.post<{ activityId: number; photos: IPhoto[] }>(`${API_URL}/${activityId}/upload-image`, formData);
  }

  favorite(activity: IActivityLocation): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${activity.id}/favorite`, null);
  }

  unfavorite(activity: IActivityLocation): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${activity.id}/unfavorite`, null);
  }

  hide(activity: IActivityLocation): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${activity.id}/hide`, null);
  }

  show(activity: IActivityLocation): Observable<{ success: boolean; message: string }> {
    return this.http.post<{ success: boolean; message: string }>(`${API_URL}/${activity.id}/show`, null);
  }

  retrieveLocalOwnedSites(user: IUser): Promise<IActivityLocation[]> {
    return this.poiListingDbSet.retrieveOwnSites(user?.id);
  }

  private formatForServer(activity: IActivityLocation): IActivityLocation {
    return { ...activity };
  }

  private toQuery(query: IActivityLocationSearchRequest): string {
    const queryArray: string[] = [];

    if (query.selected) {
      const selected = query.selected;

      if (selected.dump) {
        queryArray.push(`selectedDump=${selected.dump}`);
      }

      if (selected.restArea) {
        queryArray.push(`selectedRestArea=${selected.restArea}`);
      }

      if (selected.wifi) {
        queryArray.push(`selectedWifi=${selected.wifi}`);
      }

      if (selected.potableWater) {
        queryArray.push(`selectedPotableWater=${selected.potableWater}`);
      }

      if (selected.propane) {
        queryArray.push(`selectedPropane=${selected.propane}`);
      }
    }

    if (query.activities) {
      queryArray.push(`activities=${query.activities.join(',')}`);
    }

    if (query.lat) {
      queryArray.push(`lat=${query.lat}`);
    }

    if (query.lon) {
      queryArray.push(`lon=${query.lon}`);
    }

    if (query.distance) {
      queryArray.push(`distance=${query.distance}`);
    }

    if (query.userId) {
      queryArray.push(`userId=${query.userId}`);
    }

    if (query.available || query.available === false) {
      queryArray.push(`available=${query.available}`);
    }

    if (query.hidden || query.hidden === false) {
      queryArray.push(`hidden=${query.hidden}`);
    }

    if (query.deleted || query.deleted === false) {
      queryArray.push(`deleted=${query.deleted}`);
    }

    return queryArray.join('&');
  }
}
