import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Camera, CameraDirection, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { ConnectionStatus } from '@capacitor/network';
import { DEFAULT_IMAGE_URL, HelperFunctions, IPhoto, PhotoSizes } from '@curbnturf/entities';
import { Logger } from '@curbnturf/network/src/lib/log/logger';
import { StatusFacade } from '@curbnturf/status';
import { Platform } from '@ionic/angular';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  connectionStatus: ConnectionStatus = {
    connected: false,
    connectionType: 'none',
  };

  constructor(
    private platform: Platform,
    private statusFacade: StatusFacade,
    private http: HttpClient,
    private logger: Logger,
  ) {
    this.statusFacade.networkStatus$.subscribe((status) => {
      if (status) {
        this.connectionStatus = status;
      }
    });
  }

  get isOnline(): boolean {
    if (this.connectionStatus) {
      return !!this.connectionStatus?.connected;
    } else {
      return true;
    }
  }

  public async getSavedPhotoUrl(photo: IPhoto, size: string): Promise<string> {
    if (photo) {
      if (this.isOnline) {
        return this.getPhotoOnline(photo, size);
      } else {
        return this.getPhotoOffline(photo);
      }
    }

    return DEFAULT_IMAGE_URL;
  }

  public async getPhotoOffline(photo: IPhoto) {
    if ((await this.cachedPhotoExists(photo)).exists) {
      try {
        const photoObject = await this.loadPhotoForWeb(
          {
            saved: false,
            path: 'cached-' + btoa(photo.key),
            format: 'jpeg',
          },
          Directory.Cache,
        );
        return photoObject.webPath || '';
      } catch (e) {
        this.logger.error('Error Loading Cached Photo', e);
      }
    } else if ((await this.dataPhotoExists(photo)).exists) {
      try {
        const photoObject = await this.loadPhotoForWeb(
          {
            saved: false,
            path: photo.key,
            format: 'jpeg',
          },
          Directory.Data,
        );
        return photoObject.webPath || '';
      } catch (e) {
        this.logger.error('Error Loading Stored Photo', e);
      }
    }

    return DEFAULT_IMAGE_URL;
  }

  public getPhotoOnline(photo: IPhoto, size?: string) {
    let url;
    switch (size) {
      case PhotoSizes.ThreeXFourLarge:
        url = HelperFunctions.getPhotoUrl3x4Large(photo);
        break;
      case PhotoSizes.ThreeXFourMedium:
        url = HelperFunctions.getPhotoUrl3x4Medium(photo);
        break;
      case PhotoSizes.ThreeXFourSmall:
        url = HelperFunctions.getPhotoUrl3x4Small(photo);
        break;
      case PhotoSizes.ThreeXFourThumbnail:
        url = HelperFunctions.getPhotoUrl3x4Thumbnail(photo);
        break;
      case PhotoSizes.Medium:
        url = HelperFunctions.getPhotoUrlMedium(photo);
        break;
      case PhotoSizes.FlatMedium:
        url = HelperFunctions.getPhotoUrlFlatMedium(photo);
        break;
      case PhotoSizes.FlatSmall:
        url = HelperFunctions.getPhotoUrlFlatSmall(photo);
        break;
      case PhotoSizes.FlatThumbnail:
        url = HelperFunctions.getPhotoUrlFlatThumbnail(photo);
        break;
      case PhotoSizes.SquareMedium:
        url = HelperFunctions.getPhotoUrlSquareMedium(photo);
        break;
      case PhotoSizes.SquareSmall:
        url = HelperFunctions.getPhotoUrlSquareSmall(photo);
        break;
      case PhotoSizes.SquareThumbnail:
        url = HelperFunctions.getPhotoUrlSquareThumbnail(photo);
        break;
      case PhotoSizes.SquareTiny:
        url = HelperFunctions.getPhotoUrlSquareTiny(photo);
        break;
      case PhotoSizes.Original:
        url = HelperFunctions.getPhotoUrlOriginal(photo);
        break;
      case PhotoSizes.FullSize:
        url = HelperFunctions.getPhotoUrlFullSize(photo);
        break;
      default:
        url = HelperFunctions.getPhotoUrl(photo);
        break;
    }

    if (Capacitor.isNativePlatform()) {
      this.cachePhoto(url, photo.key).then();
    }

    return url;
  }

  public async getCameraPhoto(
    source: CameraSource = CameraSource.Prompt,
    resultType: CameraResultType = CameraResultType.Uri,
    quality: number = 100,
    direction = CameraDirection.Rear,
  ): Promise<Photo> {
    return await Camera.getPhoto({
      resultType,
      source,
      quality,
      direction,
      allowEditing: false,
      correctOrientation: true,
    });
  }

  public static convertBlobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  public async savePhoto(cameraPhoto: Photo, fileName: string): Promise<Photo> {
    // Convert photo to base64 format, required by Filesystem API to save
    const base64Data = await this.readAsBase64(cameraPhoto);

    // Write the file to the data directory
    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data,
    });

    if (this.platform.is('hybrid')) {
      // Display the new image by rewriting the 'file://' path to HTTP
      // Details: https://ionicframework.com/docs/building/webview#file-protocol
      return {
        saved: false,
        path: savedFile.uri,
        webPath: Capacitor.convertFileSrc(savedFile.uri),
        format: 'jpeg',
      };
    } else {
      // Use webPath to display the new image instead of base64 since it's
      // already loaded into memory
      return {
        saved: false,
        path: fileName,
        webPath: cameraPhoto.webPath,
        format: 'jpeg',
      };
    }
  }

  public async cachedPhotoExists(photo: IPhoto): Promise<{ exists: boolean; modified?: number }> {
    try {
      const stat = await Filesystem.stat({
        path: 'cached-' + btoa(photo.key),
        directory: Directory.Cache,
      });
      if (stat) {
        return { exists: true, modified: stat.mtime };
      }
    } catch (e) {
      return { exists: false };
    }
    return { exists: false };
  }

  public async dataPhotoExists(photo: IPhoto): Promise<{ exists: boolean; modified?: number }> {
    try {
      const stat = await Filesystem.stat({
        path: photo.key,
        directory: Directory.Data,
      });
      if (stat) {
        return { exists: true, modified: stat.mtime };
      }
    } catch (e) {
      return { exists: false };
    }
    return { exists: false };
  }

  public async cachePhoto(url: string, key: string) {
    const fileStat = await this.cachedPhotoExists({ key });
    if (!fileStat.exists) {
      const modifiedTime = DateTime.fromMillis(fileStat.modified || 0);

      // only cache when the photo is over 3 days old.
      if (modifiedTime.plus({ days: 3 }) < DateTime.now()) {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain');
        this.http.get(url, { headers, responseType: 'blob' }).subscribe(
          async (data) => {
            const base64Data = (await PhotoService.convertBlobToBase64(data as Blob)) as string;

            // Write the file to the cache directory
            await Filesystem.writeFile({
              path: 'cached-' + btoa(key),
              data: base64Data,
              directory: Directory.Cache,
            });
          },
          (e) => {
            this.logger.error('Failed to Write File to the filesystem', e);
          },
        );
      }
    }
  }

  public async saveOfflinePhoto(photo: Blob): Promise<string | false> {
    const guid = uuidv4();
    const base64Data = (await PhotoService.convertBlobToBase64(photo as Blob)) as string;
    const filename = 'uploaded-' + btoa(guid);

    try {
      // Write the file to the cache directory
      await Filesystem.writeFile({
        path: filename,
        data: base64Data,
        directory: Directory.Data,
      });
      return filename;
    } catch (e) {
      this.logger.error('Failed to Write File to the filesystem', e);
      return false;
    }
  }

  public async removeOfflinePhoto(photoKey: string): Promise<boolean> {
    try {
      // Write the file to the cache directory
      await Filesystem.deleteFile({
        path: photoKey,
        directory: Directory.Data,
      });
      return true;
    } catch (e) {
      this.logger.error('Failed to delete offline photo.', e);
      return false;
    }
  }

  public async retrieveOfflinePhoto(filename: string, contentType: string = 'image/jpeg'): Promise<Blob | false> {
    this.logger.debug('Retrieving Offline Photo.');
    try {
      // Read each saved photo's data from the Filesystem
      const readFile = await Filesystem.readFile({
        path: filename,
        directory: Directory.Data,
      });

      const byteCharacters = atob(readFile.data as string);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    } catch (e) {
      this.logger.warning('Failed to Load Photo from Filesystem', e);
      return false;
    }
  }

  public async loadPhotoForWeb(photo: Photo, directory = Directory.Data): Promise<Photo> {
    try {
      // Read each saved photo's data from the Filesystem
      const readFile = await Filesystem.readFile({
        path: photo.path || '',
        directory,
      });

      photo.webPath = `data:image/jpeg;base64,${readFile.data}`;
    } catch (e) {
      this.logger.warning('Failed to Load Photo from Filesystem', e);
    }

    return photo;
  }

  private async readAsBase64(cameraPhoto: Photo) {
    // "hybrid" will detect Cordova or Capacitor
    if (this.platform.is('hybrid')) {
      // Read the file into base64 format
      const file = await Filesystem.readFile({
        path: cameraPhoto.path || '',
      });

      return file.data;
    } else {
      // Fetch the photo, read as a blob, then convert to base64 format
      const response = await fetch(cameraPhoto.webPath || '');
      const blob = await response.blob();

      return (await PhotoService.convertBlobToBase64(blob)) as string;
    }
  }
}
