import { ILogSession } from '../interfaces';

export class LogSession implements ILogSession {
  id?: number;
  sessionId: string;
  created?: number;

  constructor(data?: ILogSession) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: ILogSession): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
