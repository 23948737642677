import { ActionReducer } from '@ngrx/store';
import { StorageAction, StorageActionTypes } from './storage.actions';
import { IStorageState } from './storage.interface';

export const initialState: IStorageState = {
  hydrated: false,
};

export function storageReducer(state: IStorageState = initialState, action: StorageAction) {
  switch (action.type) {
    case StorageActionTypes.Cleared:
    case StorageActionTypes.Hydrated: {
      state = { ...state, hydrated: true };

      break;
    }
    default:
  }

  return state;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function metaStorageReducer(reducer: ActionReducer<any>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (state: any, action: any) => {
    if (action.type === StorageActionTypes.Hydrated) {
      state = { ...state, ...action.payload };
    }

    return reducer(state, action);
  };
}
