import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_API_URL, IGrowFilterRequest, ILeadContact, ISite, IUser } from '@curbnturf/entities';
import { objectToUrlParams } from '@curbnturf/helpers';
import { Observable } from 'rxjs';

const API_URL = BASE_API_URL + 'grow';

@Injectable({
  providedIn: 'root',
})
export class GrowService {
  constructor(private http: HttpClient) {}

  getLeads(query: IGrowFilterRequest): Observable<{ totalLeads: number; totalUsers: number; leads: ILeadContact[]; users: IUser[] }> {
    return this.http.get<{
      totalLeads: number;
      totalUsers: number;
      leads: ILeadContact[];
      users: IUser[]
    }>(`${API_URL}/leads?${objectToUrlParams(query)}`);
  }

  getProspects(query: IGrowFilterRequest): Observable<{ total: number; results: ISite[] }> {
    return this.http.get<{ total: number; results: ISite[] }>(`${API_URL}/prospects?${objectToUrlParams(query)}`);
  }

  getClients(query: IGrowFilterRequest): Observable<{ total: number; results: ISite[] }> {
    return this.http.get<{ total: number; results: IUser[] }>(`${API_URL}/clients?${objectToUrlParams(query)}`);
  }

  register(user: IUser) {
    return this.http.post<IUser>(`${API_URL}/register-client`, user);
  }

  checkEmailAvailability(email: string) {

    return this.http.get<string>(`${API_URL}/client/email-check?email=${encodeURIComponent(email)}`);
  }
  createLead(lead: ILeadContact): Observable<ILeadContact> {
    return this.http.post<ILeadContact>(`${API_URL}/lead`, lead);
  }

  updateLead(lead: ILeadContact): Observable<ILeadContact> {
    return this.http.put<ILeadContact>(`${API_URL}/lead/${lead.id}`, lead);
  }

  removeLead(id: number): Observable<ILeadContact> {
    return this.http.delete<ILeadContact>(`${API_URL}/lead/${id}`);
  }

  updateUser(user: IUser): Observable<IUser> {
    return this.http.put<IUser>(`${BASE_API_URL}user/${user.id}`, user);
  }

  notifyClientListingReady(id: number) {
    return this.http.get<string>(`${API_URL}/client/email-listing-notify/${id}`);
  }
}
