import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AuthFacade } from '@curbnturf/auth';
import { IonicModule } from '@ionic/angular';
import { AdminHeaderComponent } from '../admin-header/admin-header.component';

@Component({
  selector: 'curbnturf-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  standalone: true,
  imports: [AdminHeaderComponent, IonicModule, RouterModule],
})
export class AdminLayoutComponent implements OnInit {
  collapsibleFooter = false;
  isCollapsed = true;
  dark = false;

  constructor(
    private route: ActivatedRoute,
    private authFacade: AuthFacade,
  ) {}

  ngOnInit(): void {
    if (this.route?.snapshot?.data?.dark) {
      this.dark = true;
    }

    if (this.route?.snapshot?.data?.layoutOptions?.collapsibleFooter) {
      this.collapsibleFooter = true;
    }
  }

  logout() {
    this.authFacade.logout('You have logged out');
  }
}
