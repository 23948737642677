import { createAction, props } from '@ngrx/store';

export enum AlertActionTypes {
  DisplayAlert = '[Alert] Display Alert',
  CloseAllAlerts = '[Alert] Close All Alerts',
}

export interface IAlertActionDisplayAlertProperties {
  level?: string;
  body: string;
  title: string;
  html?: boolean;
  noTimeout?: boolean;
}

export const displayAlert = createAction(
  AlertActionTypes.DisplayAlert,
  props<IAlertActionDisplayAlertProperties>(),
);

export const closeAllAlerts = createAction(AlertActionTypes.CloseAllAlerts);
