import { DateTime } from 'luxon';
import { ISchedule, ISite } from '../interfaces';
import { CDate } from './c-date';

export class Schedule implements ISchedule {
  confirmationCode?: string;
  date?: CDate;
  email?: string;
  siteId?: number;
  deleted?: boolean;
  completed?: boolean;
  rated?: boolean;
  donated?: boolean;

  // Only saved in DynamoDB
  endTimestamp?: number;

  site?: ISite;

  constructor(data?: ISchedule) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: ISchedule): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key) && key !== 'dates') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (data.date) {
      this.date = new CDate(data.date);
    }
  }

  getDatesString(): string {
    if (!this.date) {
      return 'No dates set';
    }

    return this.date.toDisplayString();
  }

  changeLastNightToEndDate(): Schedule {
    this.date = new CDate(this.date);

    if (this.date) {
      let toDate = this.date.to;
      if (!toDate) {
        toDate = this.date.from;
      }

      if (toDate) {
        this.date.to = DateTime.fromISO(toDate).plus({ days: 1 }).toISODate() || '';
      }
    }

    return this;
  }
}
