import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'splitCamelcase',
    standalone: true,
})
export class SplitCamelcasePipe implements PipeTransform {
  transform(value: string): string {
    return value?.replace(/([A-Z])/g, ' $1').trim() || '';
  }
}
