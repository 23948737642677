import { IBoondockRating, IGuestRating, IPoiRating, ISiteRating } from '@curbnturf/entities';
import { RatingAction, RatingActionTypes } from './rating.actions';

export interface RatingState {
  siteRatings: { [syncId: string]: ISiteRating };
  guestRatings: { [syncId: string]: IGuestRating };
  boondockRatings: { [syncId: string]: IBoondockRating };
  poiRatings: { [syncId: string]: IPoiRating };
  caretakerSites: number[]; // List of sites that have been selected for caretaking.
  loaded: boolean; // has the Rating list been loaded
  error?: string; // last error (if any)
}

export const initialState: RatingState = {
  siteRatings: {},
  guestRatings: {},
  boondockRatings: {},
  poiRatings: {},
  caretakerSites: [],
  loaded: false,
};

export function ratingReducer(state: RatingState = initialState, action: RatingAction): RatingState {
  switch (action.type) {
    case RatingActionTypes.SiteRatingsLoaded: {
      const updatedRatingIds = action.payload.map((updatedRating) => updatedRating.id);
      const ratings: { [syncId: string]: ISiteRating } = {};
      updatedRatingIds.forEach((ratingId, index) => {
        if (ratingId) {
          ratings[ratingId] = action.payload[index];
        }
      });

      state = {
        ...state,
        siteRatings: { ...state.siteRatings, ...ratings },
        loaded: true,
      };
      break;
    }

    case RatingActionTypes.SyncSiteRatings: {
      const siteRatings = { ...state.siteRatings };
      action.payload.response?.forEach((el) => {
        if (el.syncId) {
          siteRatings[el.syncId] = el;
        }
      });
      state = {
        ...state,
        siteRatings,
      };
      break;
    }

    case RatingActionTypes.GuestRatingsLoaded: {
      const updatedRatingIds = action.payload.map((updatedRating) => updatedRating.id);
      const ratings: { [syncId: string]: IGuestRating } = {};
      updatedRatingIds.forEach((ratingId, index) => {
        if (ratingId) {
          ratings[ratingId] = action.payload[index];
        }
      });

      state = {
        ...state,
        guestRatings: { ...state.guestRatings, ...ratings },
        loaded: true,
      };
      break;
    }

    case RatingActionTypes.SyncGuestRatings: {
      const guestRatings = { ...state.guestRatings };
      action.payload.response?.forEach((el) => {
        if (el.syncId) {
          guestRatings[el.syncId] = el;
        }
      });
      state = {
        ...state,
        guestRatings,
      };
      break;
    }

    case RatingActionTypes.BoondockRatingsLoaded: {
      const updatedRatingIds = action.payload.map((updatedRating) => updatedRating.id);
      const ratings: { [syncId: string]: IBoondockRating } = {};
      updatedRatingIds.forEach((ratingId, index) => {
        if (ratingId) {
          ratings[ratingId] = action.payload[index];
        }
      });

      state = {
        ...state,
        boondockRatings: { ...state.boondockRatings, ...ratings },
        loaded: true,
      };
      break;
    }

    case RatingActionTypes.PoiRatingsLoaded: {
      const updatedRatingIds = action.payload.map((updatedRating) => updatedRating.id);
      const ratings: { [syncId: string]: IPoiRating } = {};
      updatedRatingIds.forEach((ratingId, index) => {
        if (ratingId) {
          ratings[ratingId] = action.payload[index];
        }
      });

      state = {
        ...state,
        poiRatings: { ...state.poiRatings, ...ratings },
        loaded: true,
      };
      break;
    }
    case RatingActionTypes.SelectCaretakerSite: {
      const siteId = action.payload.siteId;
      if (siteId && !state.caretakerSites.includes(siteId)) {
        const caretakerSites = [...state.caretakerSites];
        caretakerSites.push(siteId);
        state = {
          ...state,
          caretakerSites,
        };
      }
      break;
    }
    default:
  }
  return state;
}
