import { Routes } from '@angular/router';
import { AdminGuard } from '@curbnturf/auth';
import { AdminLayoutComponent } from '@curbnturf/ui-admin';

export const appRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('@curbnturf/site-admin/src/lib/site-admin.route').then((m) => m.SITE_ADMIN_ROUTES),
  },
  {
    path: 'sites',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('@curbnturf/site-admin/src/lib/site-admin.route').then((m) => m.SITE_ADMIN_ROUTES),
  },
  {
    path: 'pois',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@curbnturf/poi-admin/src/lib/pois/pois.component').then((mod) => mod.PoisComponent),
      },
    ],
  },
  {
    path: 'reservations',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('@curbnturf/reservation-admin/src/lib/reservation-admin.route').then((m) => m.RESERVATION_ADMIN_ROUTES),
  },
  {
    path: 'users',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('@curbnturf/user-admin/src/lib/user-admin.route').then((m) => m.USER_ADMIN_ROUTES),
  },
  {
    path: 'auth',
    loadChildren: () => import('@curbnturf/auth-components/src/lib/auth.route').then((mod) => mod.AUTH_ROUTES),
  },
  {
    path: 'listings',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('@curbnturf/listing-edit/src/lib/listing.route').then((mod) => mod.LISTINGS_ROUTES),
  },
  {
    path: 'email-template',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('@curbnturf/email-admin/src/lib/email-admin.routes').then((m) => m.EMAIL_ADMIN_ROUTE),
  },
  {
    path: 'affiliates',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('@curbnturf/affiliate-admin/src/lib/affiliate-admin-routing').then((m) => m.AFFILIATE_ADMIN_ROUTES),
  },
  {
    path: 'rating',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('@curbnturf/rating-admin/src/lib/rating-admin.route').then((mod) => mod.RATING_ADMIN_ROUTES),
  },
  {
    path: 'log',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    loadChildren: () => import('@curbnturf/log-admin/src/lib/log-admin.route').then((m) => m.LOG_ADMIN_ROUTES),
  },
  {
    path: 'search',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@curbnturf/search-admin/src/lib/search/search.component').then((m) => m.SearchComponent),
      },
    ],
  },
  {
    path: 'reports',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@curbnturf/reports-admin/src/lib/weekly/weekly.component').then((m) => m.WeeklyComponent),
      },
    ],
  },
  {
    path: 'articles',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () => import('@curbnturf/article-admin/src/lib/article-admin.route').then((m) => m.ARTICLE_ROUTES),
  },
  {
    path: 'payouts',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@curbnturf/user-admin/src/lib/payouts/payouts.component').then((m) => m.PayoutsComponent),
      },
    ],
  },
  { path: '**', redirectTo: '/' },
];
