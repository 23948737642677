import { inject } from '@angular/core';
import * as AlertActions from '@curbnturf/alert/src/lib/+state/alert.actions';
import { IBoondockRating, IGuestRating, IPoiRating, ISiteRating, NGRX_NO_ACTION, Status } from '@curbnturf/entities';
import * as NetworkSelectors from '@curbnturf/network/src/lib/network/+state/network.selectors';
import { ReservationActionTypes } from '@curbnturf/reservation/src/lib/+state/reservation.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { CaretakerService } from '../caretaker.service';
import { RatingService } from '../rating.service';
import {
  CreateBoondockRatings,
  CreateGuestRatings,
  CreatePoiRatings,
  CreateSiteRatings,
  LoadBoondockRatings,
  LoadGuestRatings,
  LoadPoiRatings,
  LoadSiteRatings,
  RatingActionTypes,
  RatingLoadError,
  SelectCaretakerSite
} from './rating.actions';

export const ratingLoadError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(RatingActionTypes.RatingLoadError),
      switchMap((action: RatingLoadError) => {
        const message = action?.payload?.error?.message || JSON.stringify(action?.payload?.error);
        return of(
          AlertActions.displayAlert({
            level: 'error',
            title: 'Error Occurred',
            body: `And error occurred while submitting your ratings: ${message}`,
          }),
        );
      }),
    ),
  { functional: true },
);

export const loadGuestRatings$ = createEffect(
  (actions$ = inject(Actions), ratingService = inject(RatingService)) =>
    actions$.pipe(
      ofType(RatingActionTypes.LoadGuestRatings),
      switchMap((action: LoadGuestRatings) =>
        ratingService.fetchGuestRatings(action.payload).pipe(
          map((ratings: IGuestRating[]) => ({
            type: RatingActionTypes.GuestRatingsLoaded,
            payload: ratings,
          })),
          catchError((err) => of({ type: RatingActionTypes.RatingLoadError, payload: err })),
        ),
      ),
    ),
  { functional: true },
);

export const loadSiteRatings$ = createEffect(
  (actions$ = inject(Actions), ratingService = inject(RatingService)) =>
    actions$.pipe(
      ofType(RatingActionTypes.LoadSiteRatings),
      switchMap((action: LoadSiteRatings) =>
        ratingService.fetchSiteRatings(action.payload).pipe(
          map((ratings: ISiteRating[]) => ({
            type: RatingActionTypes.SiteRatingsLoaded,
            payload: ratings,
          })),
          catchError((err) => of({ type: RatingActionTypes.RatingLoadError, payload: err })),
        ),
      ),
    ),
  { functional: true },
);

export const loadBoondockRatings$ = createEffect(
  (actions$ = inject(Actions), ratingService = inject(RatingService)) =>
    actions$.pipe(
      ofType(RatingActionTypes.LoadBoondockRatings),
      switchMap((action: LoadBoondockRatings) =>
        ratingService.fetchBoondockRatings(action.payload).pipe(
          map((ratings: IBoondockRating[]) => ({
            type: RatingActionTypes.BoondockRatingsLoaded,
            payload: ratings,
          })),
          catchError((err) => of({ type: RatingActionTypes.RatingLoadError, payload: err })),
        ),
      ),
    ),
  { functional: true },
);

export const loadPoiRatings$ = createEffect(
  (actions$ = inject(Actions), ratingService = inject(RatingService)) =>
    actions$.pipe(
      ofType(RatingActionTypes.LoadPoiRatings),
      switchMap((action: LoadPoiRatings) =>
        ratingService.fetchPoiRatings(action.payload).pipe(
          map((ratings: IPoiRating[]) => ({
            type: RatingActionTypes.PoiRatingsLoaded,
            payload: ratings,
          })),
          catchError((err) => of({ type: RatingActionTypes.RatingLoadError, payload: err })),
        ),
      ),
    ),
  { functional: true },
);

export const createSiteRating$ = createEffect(
  (actions$ = inject(Actions), ratingService = inject(RatingService)) =>
    actions$.pipe(
      ofType(RatingActionTypes.CreateSiteRatings),
      switchMap((action: CreateSiteRatings) =>
        ratingService.createSiteRatings(action.payload).pipe(
          mergeMap((ratings: ISiteRating[]) => [
            {
              type: RatingActionTypes.SiteRatingsLoaded,
              payload: ratings,
            },
            {
              type: RatingActionTypes.SiteRatingsCreated,
              payload: ratings[0].reservationId,
            },
            AlertActions.displayAlert({
              title: 'Rating Submitted',
              body: `Thank you for submitting your rating. Your feedback helps others make informed decisions.`,
            }),
          ]),
          catchError((err) =>
            of({
              type: RatingActionTypes.RatingLoadError,
              payload: err,
            }),
          ),
        ),
      ),
      catchError((err) =>
        of({
          type: RatingActionTypes.RatingLoadError,
          payload: err,
        }),
      ),
    ),
  { functional: true },
);

export const siteRatingsCreated$ = createEffect(
  (actions$ = inject(Actions), store$ = inject(Store)) =>
    actions$.pipe(
      ofType(RatingActionTypes.SiteRatingsCreated),
      withLatestFrom(store$.select(NetworkSelectors.getStatus)),
      switchMap(([action, status]) => {
        if (!status?.connected) {
          return of({
            type: ReservationActionTypes.UpdateReservationStatus,
            payload: { reservationId: action, status: Status.rated },
          });
        } else {
          return of(NGRX_NO_ACTION);
        }
      }),
    ),
  { functional: true },
);

export const createGuestRating$ = createEffect(
  (actions$ = inject(Actions), ratingService = inject(RatingService)) =>
    actions$.pipe(
      ofType(RatingActionTypes.CreateGuestRatings),
      switchMap((action: CreateGuestRatings) =>
        ratingService.createGuestRatings(action.payload).pipe(
          mergeMap((ratings: IGuestRating[]) => [
            {
              type: RatingActionTypes.GuestRatingsLoaded,
              payload: ratings,
            },
            {
              type: RatingActionTypes.GuestRatingsCreated,
            },
            AlertActions.displayAlert({
              title: 'Rating Submitted',
              body: `Thank you for submitting your rating. Your feedback helps others make informed decisions.`,
            }),
          ]),
          catchError((err) =>
            of({
              type: RatingActionTypes.RatingLoadError,
              payload: err,
            }),
          ),
        ),
      ),
      catchError((err) =>
        of({
          type: RatingActionTypes.RatingLoadError,
          payload: err,
        }),
      ),
    ),
  { functional: true },
);

export const createBoondockRating$ = createEffect(
  (actions$ = inject(Actions), ratingService = inject(RatingService)) =>
    actions$.pipe(
      ofType(RatingActionTypes.CreateBoondockRatings),
      switchMap((action: CreateBoondockRatings) =>
        ratingService.createBoondockRatings(action.payload).pipe(
          mergeMap((ratings: IBoondockRating[]) => [
            {
              type: RatingActionTypes.BoondockRatingsLoaded,
              payload: ratings,
            },
            {
              type: RatingActionTypes.BoondockRatingsCreated,
            },
            AlertActions.displayAlert({
              title: 'Rating Submitted',
              body: `Thank you for submitting your rating. Your feedback helps others make informed decisions.`,
            }),
          ]),
          catchError((err) =>
            of({
              type: RatingActionTypes.RatingLoadError,
              payload: err,
            }),
          ),
        ),
      ),
      catchError((err) =>
        of({
          type: RatingActionTypes.RatingLoadError,
          payload: err,
        }),
      ),
    ),
  { functional: true },
);

export const createPoiRating$ = createEffect(
  (actions$ = inject(Actions), ratingService = inject(RatingService)) =>
    actions$.pipe(
      ofType(RatingActionTypes.CreatePoiRatings),
      switchMap((action: CreatePoiRatings) =>
        ratingService.createPoiRatings(action.payload).pipe(
          mergeMap((ratings: IPoiRating[]) => [
            {
              type: RatingActionTypes.PoiRatingsLoaded,
              payload: ratings,
            },
            {
              type: RatingActionTypes.PoiRatingsCreated,
            },
            AlertActions.displayAlert({
              title: 'Rating Submitted',
              body: `Thank you for submitting your rating. Your feedback helps others make informed decisions.`,
            }),
          ]),
          catchError((err) =>
            of({
              type: RatingActionTypes.RatingLoadError,
              payload: err,
            }),
          ),
        ),
      ),
      catchError((err) =>
        of({
          type: RatingActionTypes.RatingLoadError,
          payload: err,
        }),
      ),
    ),
  { functional: true },
);

export const selectCaretakerSite$ = createEffect(
  (actions$ = inject(Actions), caretakerService = inject(CaretakerService)) =>
    actions$.pipe(
      ofType<SelectCaretakerSite>(RatingActionTypes.SelectCaretakerSite),
      switchMap((action) => {
        return caretakerService.saveSiteUpdates(action.payload).pipe(
          mergeMap((site) => [
            {
              type: RatingActionTypes.SelectCaretakerSiteComplete,
              payload: site,
            },
            AlertActions.displayAlert({
              title: 'Caretaker Application',
              body: 'You have applied to become the caretaker. Your request has been saved and will be reviewed by our staff.',
            }),
          ]),
          catchError((err) =>
            of({
              type: RatingActionTypes.SelectCaretakerSiteFailed,
              payload: err,
            }),
          ),
        );
      }),
    ),
  { functional: true },
);
