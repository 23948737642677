import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[curbnturfDisplayValidationErrorsContainer]',
  exportAs: 'curbnturfDisplayValidationErrorsContainerRef',
  standalone: true,
})
export class DisplayValidationErrorsContainerDirective {
  constructor(public vcr: ViewContainerRef) {}
}
