import { INoise } from '../interfaces';

export class Noise implements INoise {
  level: 'silence' | 'nature' | 'periodic' | 'constant';
  description: string;

  constructor(data?: INoise) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: INoise): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
