import { Injectable } from '@angular/core';
import { capSQLiteChanges, capSQLiteValues } from '@capacitor-community/sqlite';
import { IDataCache } from '@curbnturf/entities';
import { DatabaseService } from '../database.service';
import { IDbSet } from '../dbset.interface';
import { DataCacheEntity } from '../entities/data-cache';

const TABLE_NAME = 'data_cache';

@Injectable({
  providedIn: 'root',
})
export class DataCacheDbSet implements IDbSet {
  constructor(private databaseService: DatabaseService) {}

  public store(request: IDataCache): Promise<capSQLiteValues> {
    return this.databaseService.upsert(
      TABLE_NAME,
      this.mapObjectsToStorable([request])[0],
      ['last_modified'],
      'cache_name',
    );
  }

  public storeArray(requests: IDataCache[]): Promise<capSQLiteValues> {
    return this.databaseService.insertMulti<DataCacheEntity>(TABLE_NAME, this.mapObjectsToStorable(requests));
  }

  public async truncateAndStoreArray(requests: IDataCache[]): Promise<capSQLiteValues> {
    const entities = this.mapObjectsToStorable(requests);
    await this.databaseService.execute('DELETE FROM data_cache;');
    const { statement, values } = this.databaseService.generateInsertStatement<DataCacheEntity>(TABLE_NAME, entities);
    return this.databaseService.query(statement, values);
  }

  public async truncate(): Promise<capSQLiteChanges> {
    return await this.databaseService.execute('DELETE FROM data_cache;');
  }

  public async retrieve(cacheName: string): Promise<{ values: IDataCache[] }> {
    const results = await this.databaseService.select(TABLE_NAME, { cache_name: cacheName });
    return {
      values: this.mapResultsToDataCache(results),
    };
  }

  public async retrieveAll(): Promise<IDataCache[]> {
    const results = await this.databaseService.selectAll(TABLE_NAME);
    return this.mapResultsToDataCache(results);
  }

  public async remove(cacheName: string): Promise<capSQLiteValues> {
    return await this.databaseService.delete(TABLE_NAME, { cache_name: cacheName });
  }

  private mapResultsToDataCache(results: capSQLiteValues): IDataCache[] {
    if (results.values) {
      return results.values?.map((request) => {
        return {
          cacheName: request.cache_name,
          lastModified: request.last_modified,
        };
      });
    } else {
      return [];
    }
  }

  private mapObjectsToStorable(items: IDataCache[]): DataCacheEntity[] {
    if (items) {
      return items?.map((request) => {
        return {
          cache_name: request.cacheName ? request.cacheName : '',
          last_modified: request.lastModified ? request.lastModified : 0,
        };
      });
    } else {
      return [];
    }
  }
}
