import { IActivityLocation, IActivityLocationSearchRequest } from '@curbnturf/entities';
import { stringFromError } from '@curbnturf/form-helpers';
import { createReducer, on } from "@ngrx/store";
import * as ActivityActions from './activity.actions';

/**
 * Interface for the 'Activity' data used in
 *  - ActivityState, and
 *  - activityReducer
 *
 *  Note: replace if already defined in another module
 */

export interface ActivityState {
  activities: { [id: number]: IActivityLocation }; // list of Activity;
  selected?: number; // which Activity record has been selected
  loaded: boolean; // has the Activity list been loaded
  error?: string; // last error (if any)
  searchActivities: { [id: number]: IActivityLocation };
  lastSearchParams?: IActivityLocationSearchRequest;
  pendingImage?: FormData;
  awaitActivity?: IActivityLocation;
}

export const initialState: ActivityState = {
  activities: {},
  searchActivities: {},
  loaded: false,
};

export const activityReducer = createReducer(
  initialState,
  on(ActivityActions.awaitActivity, (state, action) => {
    if (!action.activity) {
      return state;
    }

    const awaitActivity = action.activity;

    return {
      ...state,
      awaitActivity,
      selected: undefined,
      error: undefined,
    };
  }),
  on(ActivityActions.cancelAwait, (state) => {
    return {
      ...state,
      awaitActivity: undefined,
      error: undefined,
    };
  }),
  on(ActivityActions.activityUpdated, (state, action) => {
    if (!action.activity || !action.activity.id) {
      return state;
    }

    const activity = action.activity;
    if (!activity.id) {
      return state;
    }

    let searchActivities = state.searchActivities;

    // Add the activity to the search list when created
    if (searchActivities[activity.id] || action.type === ActivityActions.activityUpdated.type) {
      searchActivities = { ...searchActivities, [activity.id]: activity };
    }

    return {
      ...state,
      searchActivities,
      activities: { ...state.activities, [activity.id]: activity },
      error: undefined,
    };
  }),
  on(ActivityActions.activityLoaded, (state, action) => {
    if (!action.activity || !action.activity.id) {
      return state;
    }

    const activity = action.activity;
    if (!activity.id) {
      return state;
    }

    let searchActivities = state.searchActivities;

    // Add the activity to the search list when created
    if (searchActivities[activity.id] || action.type === ActivityActions.activityLoaded.type) {
      searchActivities = { ...searchActivities, [activity.id]: activity };
    }

    return {
      ...state,
      searchActivities,
      activities: { ...state.activities, [activity.id]: activity },
      error: undefined,
    };
  }),
  on(ActivityActions.activityCreated, (state, action) => {
    if (!action.activity || !action.activity.id) {
      return state;
    }

    const activity = action.activity;
    if (!activity.id) {
      return state;
    }

    let searchActivities = state.searchActivities;

    // Add the activity to the search list when created
    if (searchActivities[activity.id] || action.type === ActivityActions.activityCreated.type) {
      searchActivities = { ...searchActivities, [activity.id]: activity };
    }

    return {
      ...state,
      searchActivities,
      activities: { ...state.activities, [activity.id]: activity },
      error: undefined,
    };
  }),
  on(ActivityActions.activityImagesUpdated, (state, action) => {
    if (!action.photos || !action.activityId) {
      return state;
    }

    const id = action.activityId;
    const photos = action.photos;
    const existingActivity = state.activities[id];

    const activity = { ...existingActivity, photos };
    if (!activity.id) {
      return state;
    }

    let searchActivities = state.searchActivities;

    if (searchActivities[activity.id]) {
      searchActivities = { ...searchActivities, [activity.id]: activity };
    }

    return {
      ...state,
      searchActivities,
      activities: { ...state.activities, [activity.id]: activity },
      pendingImage: undefined,
      error: undefined,
    };
  }),
  on(ActivityActions.activitiesLoaded, (state, action) => {
    const activities: { [id: number]: IActivityLocation } = {};
    action.activities.forEach((activity: IActivityLocation) => {
      if (activity && activity.id) {
        activities[activity.id] = activity;
      }
    });

    return {
      ...state,
      activities: { ...activities },
      error: undefined,
    };
  }),
  on(ActivityActions.loadForSearch, (state, action) => {
    return {
      ...state,
      lastSearchParams: action.request,
    };
  }),
  on(ActivityActions.loadedForSearch, (state, action) => {
    const activities: { [id: number]: IActivityLocation } = {};
    action.activities.forEach((activity: IActivityLocation) => {
      if (activity && activity.id) {
        activities[activity.id] = activity;
      }
    });

    return {
      ...state,
      searchActivities: { ...activities },
      error: undefined,
    };
  }),
  on(ActivityActions.activityRemoved, (state, action) => {
    const activityId = action.activityId;

    const activityState = { ...state, activities: { ...state.activities } };

    delete activityState.activities[activityId];
    delete activityState.error;

    return activityState;
  }),
  on(ActivityActions.selectActivity, (state, action) => {
    if (!action.activityId) {
      return {
        ...state,
        awaitActivity: undefined,
        selected: undefined,
      };
    }

    return {
      ...state,
      selected: action.activityId,
      error: undefined,
    };
  }),
  on(ActivityActions.activitySelected, (state, action) => {
    if (!action.activity || !action.activity.id) {
      return {
        ...state,
        awaitActivity: undefined,
        selected: undefined,
      };
    }

    const id = action.activity.id;
    const activity = action.activity;
    return {
      ...state,
      activities: { ...state.activities, [id]: activity },
      selected: id,
      error: undefined,
    };
  }),
  on(ActivityActions.createActivityFailed, (state, action) => {
    if (!action.error) {
      return state;
    }

    const error = stringFromError(action.error);

    return {
      ...state,
      error,
    };
  }),
  on(ActivityActions.loadActivityFailed, (state, action) => {
    if (!action.error) {
      return state;
    }

    const error = stringFromError(action.error);

    return {
      ...state,
      error,
    };
  }),
  on(ActivityActions.updateActivityFailed, (state, action) => {
    if (!action.error) {
      return state;
    }

    const error = stringFromError(action.error);

    return {
      ...state,
      error,
    };
  }),
  on(ActivityActions.activityFavorFailed, (state, action) => {
    if (!action.error) {
      return state;
    }

    const error = stringFromError(action.error);

    return {
      ...state,
      error,
    };
  }),
  on(ActivityActions.activityFavorCompleted, (state) => {
    return {
      ...state,
      error: undefined,
    };
  }),
  on(ActivityActions.clearActivities, (state) => {
    return {
      ...state,
      activities: {},
    };
  }),
  on(ActivityActions.clearSearchActivities, (state) => {
    return {
      ...state,
      searchActivities: {},
    };
  }),
  on(ActivityActions.pendingUploadActivityImage, (state, action) => {
    if (!action) {
      return state;
    }

    return {
      ...state,
      pendingImage: action,
    };
  }),
  on(ActivityActions.cancelUploadActivityImage, (state) => {
    return {
      ...state,
      pendingImage: undefined,
    };
  }),
);
