import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as logEffects from './log/+state/log.effects';
import * as fromLog from './log/+state/log.reducer';
import * as networkEffects from './network/+state/network.effects';
import * as fromNetwork from './network/+state/network.reducer';


@NgModule({
  imports: [HttpClientModule],
  providers: [
    provideState(NGRX_BUCKETS.network, fromNetwork.reducer),
    provideState(NGRX_BUCKETS.log, fromLog.reducer),
    provideEffects([networkEffects, logEffects]),
  ],
})
export class NetworkModule {}
