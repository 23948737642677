import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DateTime } from 'luxon';
import { DatepickerComponent } from './datepicker';
import { DatepickerService } from './datepicker-service';
import { getWeekdayShortName } from './datepicker-tools';
import { DayViewModel, MonthViewModel } from './datepicker-view-model';

/**
 * A component that renders one month including all the days, weekdays and week numbers. Can be used inside
 * the `<ng-template ngbDatepickerMonths></ng-template>` when you want to customize months layout.
 *
 * For a usage example, see [custom month layout demo](#/here-map/datepicker/examples#custommonth)
 *
 * @since 5.3.0
 */
@Component({
  selector: 'curbnturf-datepicker-month',
  host: { role: 'grid' },
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./datepicker-month.scss'],
  template: `
    <div *ngIf="datepicker.showWeekdays" class="ngb-dp-week ngb-dp-weekdays" role="row">
      <div *ngFor="let w of viewModel.weekdays" class="ngb-dp-weekday small" role="columnheader">
        {{ getWeekdayShortName(w) }}
      </div>
    </div>
    <ng-template ngFor let-week [ngForOf]="viewModel.weeks">
      <div *ngIf="!week.collapsed" class="ngb-dp-week" role="row">
        <div
          *ngFor="let day of week.days"
          (click)="doSelect(day)"
          class="ngb-dp-day"
          role="gridcell"
          [class.disabled]="day.context.disabled"
          [tabindex]="day.tabindex"
          [class.hidden-day]="day.hidden"
          [class.ngb-dp-today]="day.context.today"
          [attr.aria-label]="day.ariaLabel"
        >
          <ng-template [ngIf]="!day.hidden">
            <ng-template
              [ngTemplateOutlet]="datepicker.dayTemplate"
              [ngTemplateOutletContext]="day.context"
            ></ng-template>
          </ng-template>
        </div>
      </div>
    </ng-template>
  `,
  standalone: true,
  imports: [NgIf, NgFor, NgTemplateOutlet],
})
export class DatepickerMonth {
  /**
   * The first date of month to be rendered.
   *
   * This month must one of the months present in the
   * [datepicker state](#/here-map/datepicker/api#NgbDatepickerState).
   */
  @Input() set month(month: DateTime) {
    this.viewModel = this._service.getMonth(month);
  }

  getWeekdayShortName = getWeekdayShortName;

  viewModel: MonthViewModel;

  constructor(
    public datepicker: DatepickerComponent,
    private _service: DatepickerService,
  ) {}

  doSelect(day: DayViewModel) {
    if (!day.context.disabled && !day.hidden) {
      this.datepicker.onDateSelect(day.date);
    }
  }
}
