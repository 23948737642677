import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MiscSvgComponent } from '@curbnturf/custom-svgs/src/lib/misc-svg/misc-svg.component';
import { datepickerRangeToDateArray } from '@curbnturf/helpers';
import { DatepickerComponent } from '@curbnturf/ng-bootstrap/src/lib/datepicker/datepicker';
import { DateTime } from 'luxon';

@Component({
  selector: 'curbnturf-blackout-selector',
  templateUrl: './blackout-selector.component.html',
  styleUrls: ['./blackout-selector.component.scss'],
  standalone: true,
  imports: [MiscSvgComponent, DatepickerComponent],
})
export class BlackoutSelectorComponent implements OnChanges {
  @Input() dateRange: string;

  @Input() disabledDays: string[] = [];

  @Output() update = new EventEmitter<string>();

  @Output() remove = new EventEmitter<void>();

  hoveredDate: DateTime;
  fromDate?: DateTime;
  toDate?: DateTime;
  today: DateTime;

  loaded = false;

  constructor() {
    this.today = DateTime.now();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dateRange && changes.dateRange.currentValue && !this.loaded) {
      this.loaded = true;
      const range = datepickerRangeToDateArray(this.dateRange);
      if (range.length === 0) {
        return;
      }

      const firstDate = range[0];
      const secondDate = range[1];
      if (firstDate) {
        this.fromDate = firstDate;

        if (secondDate) {
          this.toDate = secondDate;
        } else {
          this.toDate = firstDate;
        }
      }
    }
  }

  onDateSelection(date: DateTime) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date > this.fromDate || date.equals(this.fromDate))) {
      this.toDate = date;
    } else {
      delete this.toDate;
      this.fromDate = date;
    }

    this.loaded = true;

    const fromDate = this.fromDate?.toISODate();
    const toDate = this.toDate?.toISODate();

    if (fromDate && toDate) {
      this.update.emit(`${fromDate}:${toDate}`);
    }
  }

  isHovered(date: DateTime): boolean {
    return Boolean(
      this.fromDate && !this.toDate && this.hoveredDate && date > this.fromDate && date < this.hoveredDate,
    );
  }

  isInside(date: DateTime): boolean {
    return Boolean(this.fromDate && this.toDate && date > this.fromDate && date < this.toDate);
  }

  isRange(date: DateTime): boolean {
    return Boolean(
      (this.fromDate && date.equals(this.fromDate)) ||
        (this.toDate && (date.equals(this.toDate) || this.isInside(date) || this.isHovered(date))),
    );
  }

  isDisabled() {
    if (!this.disabledDays) {
      return () => false;
    }
    return (date: DateTime): boolean => this.disabledDays.includes(date.toISODate() || '');
  }

  clearDates() {
    delete this.fromDate;
    delete this.toDate;
  }

  fireRemove() {
    this.remove.emit();
  }
}
