import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ConnectionStatus } from '@capacitor/network';
import { ILog, Log, LogLevel } from '@curbnturf/entities';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { NetworkFacade } from '../network/+state/network.facade';
import { LogFacade } from './+state/log.facade';
import { LogHttpService } from './log-http.service';

@Injectable({
  providedIn: 'root',
})
export class Logger {
  logs: Subject<ILog | Log> = new Subject<ILog | Log>();
  logToConsole = false;
  connectionStatus: ConnectionStatus = {
    connected: false,
    connectionType: 'none',
  };

  constructor(
    private logHttpService: LogHttpService,
    private networkFacade: NetworkFacade,
    private logFacade: LogFacade,
  ) {
    this.networkFacade.status$.subscribe((status) => {
      if (status) {
        this.connectionStatus = status;
      }
    });
  }

  log(log: ILog | Log) {
    if (!log.created) {
      log.created = DateTime.now().toMillis();
    }

    if (!log.trace) {
      log.trace = new Error().stack;
    }

    if (this.logToConsole) {
      const logDetails = Capacitor.isNativePlatform() ? JSON.stringify(log) : log;
      switch (log.level) {
        case LogLevel.CRITICAL:
        case LogLevel.ERROR:
          console.error(`${log.level}: ${log.message}`, logDetails);
          break;
        case LogLevel.WARNING:
          console.warn(`${log.level}: ${log.message}`, logDetails);
          break;
        default:
          console.log(`${log.level}: ${log.message}`, logDetails);
      }
    }

    // Report the log to the state and the subject
    this.logFacade.log(log);
    this.logs.next(log);

    // Don't report DEBUG logs to the server or any when offline.
    if (log.level !== LogLevel.DEBUG || this.connectionStatus.connected) {
      this.logHttpService.log(log);
    }
  }

  critical(
    message: string,
    details?: string | Record<string, unknown>,
    trace?: string,
    subjectType?: string,
    subjectId?: string,
  ) {
    this.log(
      new Log({
        level: LogLevel.CRITICAL,
        message,
        details,
        trace,
        subjectType,
        subjectId,
      }),
    );
  }

  error(
    message: string,
    details?: string | Record<string, unknown>,
    trace?: string,
    subjectType?: string,
    subjectId?: string,
  ) {
    this.log(
      new Log({
        level: LogLevel.ERROR,
        message,
        details,
        subjectType,
        subjectId,
      }),
    );
  }

  warning(
    message: string,
    details?: string | Record<string, unknown>,
    trace?: string,
    subjectType?: string,
    subjectId?: string,
  ) {
    this.log(
      new Log({
        level: LogLevel.WARNING,
        message,
        details,
        subjectType,
        subjectId,
      }),
    );
  }

  info(
    message: string,
    details?: string | Record<string, unknown>,
    trace?: string,
    subjectType?: string,
    subjectId?: string,
  ) {
    this.log(
      new Log({
        level: LogLevel.INFO,
        message,
        details,
        subjectType,
        subjectId,
      }),
    );
  }

  debug(
    message: string,
    details?: string | Record<string, unknown>,
    trace?: string,
    subjectType?: string,
    subjectId?: string,
  ) {
    this.log({
      level: LogLevel.DEBUG,
      message,
      details,
      subjectType,
      subjectId,
    });
  }
}
