import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as AlertActions from './alert.actions';
import { AlertState } from './alert.reducer';

@Injectable({ providedIn: 'root' })
export class AlertFacade {
  constructor(private store: Store<AlertState>) {}

  display(message: { level?: string; body: string; title: string; html?: boolean; noTimeout?: boolean }) {
    this.store.dispatch(AlertActions.displayAlert(message));
  }

  closeAll() {
    this.store.dispatch(AlertActions.closeAllAlerts());
  }
}
