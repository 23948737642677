import { IActivity, IPhoto, ISite } from '../interfaces';

export class Activity implements IActivity {
  id?: number;
  type?: IActivity['type'];
  typeCustom?: string;
  name?: string;
  description?: string;
  photos?: IPhoto[]; // array of URL strings
  site?: ISite;
  siteId?: number;
  url?: string;

  constructor(data?: IActivity) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IActivity): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (!this.photos) {
      this.photos = [];
    }
  }
}
