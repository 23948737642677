import { DEFAULT_MAP_LATITUDE, DEFAULT_MAP_LONGITUDE, DEFAULT_MAP_VIEWPORT_DISTANCE } from '@curbnturf/entities';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocationState } from './location.reducer';

// Lookup the 'Location' feature state managed by NgRx
const getLocationState = createFeatureSelector<LocationState>('location');

const getLoaded = createSelector(getLocationState, (state: LocationState) => state.loaded);

// If no location has been selected return null
const getSelectedLocation = createSelector(getLocationState, (state: LocationState) => {
  return state.loaded
    ? {
        center: state.center || { lat: DEFAULT_MAP_LATITUDE, lon: DEFAULT_MAP_LONGITUDE },
        distance: state.distance || DEFAULT_MAP_VIEWPORT_DISTANCE,
      }
    : null;
});

const getCurrentLocation = createSelector(getLocationState, (state: LocationState) => state.currentLocation);

export const locationQuery = {
  getLoaded,
  getSelectedLocation,
  getCurrentLocation,
};
