export enum MapLayerTypes {
  normal = 'normal',
  satellite = 'satellite',
  terrain = 'terrain',
}

export enum CurbnturfMapLayerTypes {
  host = 'host',
  hostInaccuracy = 'hostInaccuracy',
  boondock = 'boondock',
  poi = 'poi',
  currentLocation = 'currentLocation',
  route = 'route',
  trip = 'trip',
  user = 'user',
}
