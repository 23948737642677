import { AbstractControlOptions, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  IAddress,
  IAmenity,
  IAmenityType,
  IArticleImage,
  ILatLon,
  IPhoto,
  IPolicy,
  IPrivacy,
  IReservationExtra,
  IRoadCondition,
  IRoadConditionDescribed,
  ISignalReception,
  ISiteExtra,
  ISubPhoto,
  IVehicle,
  IWaypoint,
} from '@curbnturf/entities';
import {
  IAddressForm,
  IAmenityForm,
  IAmenityNullable,
  IArticleImageForm,
  ICoordinatesForm,
  IPhotoForm,
  IPolicyForm,
  IPrivacyForm,
  IReservationExtraForm,
  IRoadConditionsForm,
  IRvRoadConditionsForm,
  ISignalReceptionForm,
  ISiteExtraForm,
  ISiteExtraNullable,
  IVehiclesForm,
  IWaypointForm,
} from './interfaces';

export function addressFormBuilder(
  formBuilder: FormBuilder,
  address?: IAddress,
  options: AbstractControlOptions = { updateOn: 'blur' },
): FormGroup<IAddressForm> {
  return formBuilder.group(
    {
      address: formBuilder.control<string | null>(address?.address ? address.address : '', [Validators.maxLength(120)]),
      city: formBuilder.control<string | null>(address?.city ? address.city : '', [Validators.maxLength(120)]),
      state: formBuilder.control<string | null>(address?.state ? address.state : '', [Validators.maxLength(120)]),
      zipcode: formBuilder.control<string | null>(address?.zipcode ? address.zipcode : '', [Validators.maxLength(12)]),
    },
    options,
  );
}

export function formatAddressFormValue(form?: FormGroup<IAddressForm>): IAddress {
  if (!form) {
    return {
      address: '',
      city: '',
      state: '',
      zipcode: '',
    };
  }

  const value = form.value;

  return {
    address: value.address || '',
    city: value.city || '',
    state: value.state || '',
    zipcode: value.zipcode || '',
  };
}

export function addressRequiredFormBuilder(formBuilder: FormBuilder, address?: IAddress): FormGroup<IAddressForm> {
  return formBuilder.group(
    {
      address: formBuilder.control<string | null>(address?.address ? address.address : '', [
        Validators.required,
        Validators.maxLength(120),
      ]),
      city: formBuilder.control<string | null>(address?.city ? address.city : '', [
        Validators.required,
        Validators.maxLength(120),
      ]),
      state: formBuilder.control<string | null>(address?.state ? address.state : '', [
        Validators.required,
        Validators.maxLength(120),
      ]),
      zipcode: formBuilder.control<string | null>(address?.zipcode ? address.zipcode : '', [
        Validators.required,
        Validators.maxLength(12),
      ]),
    },
    { updateOn: 'blur' },
  );
}

export function amenityFormBuilder(formBuilder: FormBuilder, amenity?: IAmenity): FormGroup<IAmenityForm> {
  return formBuilder.group({
    type: formBuilder.control<IAmenityType['type'] | null>(amenity?.type || null),
    custom: formBuilder.control<string | null>(amenity?.custom || ''),
    description: formBuilder.control<string | null>(amenity?.description || ''),
    options: formBuilder.control<string | null>(amenity?.options || ''),
    id: formBuilder.control<number | null>(amenity?.id || null),
  });
}

export function formatAmenityFormValue(form?: FormGroup<IAmenityForm>): IAmenity {
  if (!form) {
    return {};
  }

  const value = form.value;

  return {
    type: value.type || undefined,
    custom: value.custom || '',
    description: value.description || '',
    options: value.options || '',
    id: value.id || undefined,
  };
}

export function amenityValueForForm(amenity: IAmenity): IAmenityNullable {
  return {
    id: amenity.id || null,
    custom: amenity.custom || null,
    description: amenity.description || null,
    options: amenity.options || null,
    type: amenity.type || null,
  };
}

export function coordinatesFormBuilder(formBuilder: FormBuilder, coordinates?: ILatLon): FormGroup<ICoordinatesForm> {
  return formBuilder.group(
    {
      lat: formBuilder.control(coordinates?.lat ? coordinates.lat : null, [Validators.min(-90), Validators.max(90)]),
      lon: formBuilder.control(coordinates?.lon ? coordinates.lon : null, [Validators.min(-180), Validators.max(180)]),
    },
    { updateOn: 'blur' },
  );
}

export function formatCoordinatesFormValue(form?: FormGroup<ICoordinatesForm>): ILatLon | undefined {
  if (!form) {
    return;
  }

  const value = form.value;

  if (!value.lat || !value.lon) {
    return;
  }

  return {
    lat: value.lat,
    lon: value.lon,
  };
}

export function extraFormBuilder(formBuilder: FormBuilder, extra?: ISiteExtra): FormGroup<ISiteExtraForm> {
  return formBuilder.group({
    id: [extra?.id ? extra.id : null],
    label: [extra?.label ? extra.label : ''],
    name: [extra?.name ? extra.name : ''],
    description: [extra?.description ? extra.description : ''],
    price: [extra?.price ? extra.price : 0, [Validators.min(0)]],
    count: [extra?.count ? extra.count : 0, [Validators.min(0)]],
  });
}

export function setSiteExtraForm(form: FormGroup<ISiteExtraForm>, extra?: ISiteExtra): FormGroup<ISiteExtraForm> {
  form.setValue({
    id: extra?.id ? extra.id : null,
    label: extra?.label ? extra.label : null,
    name: extra?.name ? extra.name : null,
    description: extra?.description ? extra.description : null,
    price: extra?.price === undefined ? null : extra.price,
    count: extra?.count === undefined ? null : extra.count,
  });

  return form;
}

export function formatSiteExtraFormValue(form?: FormGroup<ISiteExtraForm>): ISiteExtra {
  if (!form) {
    return {};
  }

  const value = form.value;

  return {
    id: value.id || undefined,
    label: value.label || undefined,
    name: value.name || undefined,
    description: value.description || undefined,
    price: value.price === null ? undefined : value.price,
    count: value.count === null ? undefined : value.count,
  };
}

export function siteExtraValueForForm(extra: ISiteExtra): ISiteExtraNullable {
  return {
    id: extra.id || null,
    label: extra.label || '',
    name: extra.name || '',
    description: extra.description || '',
    price: extra.price || 0,
    count: extra.count || 0,
  };
}

export function reservationExtraFormBuilder(
  formBuilder: FormBuilder,
  extra?: IReservationExtra,
): FormGroup<IReservationExtraForm> {
  return formBuilder.group({
    id: [extra?.id ? extra.id : null],
    count: [extra?.count ? extra.count : 0, [Validators.min(0)]],
  });
}

export function setReservationExtraForm(
  form: FormGroup<IReservationExtraForm>,
  extra?: IReservationExtra,
): FormGroup<IReservationExtraForm> {
  form.setValue({
    id: extra?.id ? extra.id : null,
    count: extra?.count === undefined ? null : extra.count,
  });

  return form;
}

export function formatReservationExtraFormValue(form?: FormGroup<IReservationExtraForm>): IReservationExtra {
  if (!form) {
    return { count: 0 };
  }

  const value = form.value;

  return {
    id: value.id || undefined,
    count: value.count || 0,
  };
}

// photos
function formatPhotoValue(value: any): IPhoto {
  return {
    id: value.id || undefined,
    key: value.key || undefined,
    caption: value.caption || undefined,
    cropLeft: value.cropLeft || undefined,
    cropTop: value.cropTop || undefined,
    cropRight: value.cropRight || undefined,
    cropBottom: value.cropBottom || undefined,
    zoomRatio: value.zoomRatio || undefined,
    activity: value.activity || undefined,
    activityId: value.activityId || undefined,
    location: value.location || undefined,
    locationId: value.locationId || undefined,
    user: value.user || undefined,
    userId: value.userId || undefined,
  };
}

export function photoFormBuilder(formBuilder: FormBuilder, photo?: ISubPhoto): FormGroup<IPhotoForm> {
  return formBuilder.group({
    key: [photo?.key ? photo.key : ''],
    caption: [photo?.caption ? photo.caption : '', [Validators.maxLength(120)]],
    cropLeft: [photo?.cropLeft ? photo.cropLeft : 0, [Validators.min(0)]],
    cropTop: [photo?.cropTop ? photo.cropTop : 0, [Validators.min(0)]],
    cropBottom: [photo?.cropBottom ? photo.cropBottom : 0, [Validators.min(0)]],
    cropRight: [photo?.cropRight ? photo.cropRight : 0, [Validators.min(0)]],
    zoomRatio: [photo?.zoomRatio ? photo.zoomRatio : 1, [Validators.min(0)]],
  });
}

export function articleImageFormBuilder(formBuilder: FormBuilder, image?: IArticleImage): FormGroup<IArticleImageForm> {
  return formBuilder.group({
    id: [image?.id ? image.id : 0, [Validators.min(0)]],
    key: [image?.key ? image.key : ''],
    caption: [image?.caption ? image.caption : '', [Validators.maxLength(120)]],
    cropLeft: [image?.cropLeft ? image.cropLeft : 0, [Validators.min(0)]],
    cropTop: [image?.cropTop ? image.cropTop : 0, [Validators.min(0)]],
    cropBottom: [image?.cropBottom ? image.cropBottom : 0, [Validators.min(0)]],
    cropRight: [image?.cropRight ? image.cropRight : 0, [Validators.min(0)]],
    zoomRatio: [image?.zoomRatio ? image.zoomRatio : 1, [Validators.min(0)]],
  });
}

export function formatPhotoFormValue(form?: FormGroup<IPhotoForm>): ISubPhoto {
  if (!form) {
    return {
      key: '',
    };
  }

  const value = form.value;

  return {
    key: value.key || '',
    caption: value.caption || undefined,
  };
}

export function formatPhotoValueForForm(value?: ISubPhoto): ISubPhoto {
  if (!value) {
    return {
      key: '',
      caption: '',
    };
  }

  return {
    key: value.key || '',
    caption: value.caption || '',
    cropLeft: value.cropLeft || 0,
    cropTop: value.cropTop || 0,
    cropRight: value.cropRight || 0,
    cropBottom: value.cropBottom || 0,
    zoomRatio: value.zoomRatio || 1,
  };
}

export function photosFormBuilder(formBuilder: FormBuilder, photos?: IPhoto[]): FormArray<FormGroup<IPhotoForm>> {
  const photoGroupArray: FormGroup[] = [];

  if (photos) {
    photos.forEach((photo) => {
      photoGroupArray.push(photoFormBuilder(formBuilder, photo));
    });
  }

  return formBuilder.array(photoGroupArray, { updateOn: 'blur' });
}

export function formatPhotosFormValue(form?: FormArray<FormGroup<IPhotoForm>>): IPhoto[] {
  if (!form) {
    return [];
  }

  const value = form.value;

  if (!value || !value.length) {
    return [];
  }

  return value.filter((row) => row).map((row) => formatPhotoValue(row));
}

export function policyFormBuilder(formBuilder: FormBuilder, policy?: IPolicy): FormGroup<IPolicyForm> {
  return formBuilder.group(
    {
      id: formBuilder.control(policy?.id ? policy.id : null),
      description: formBuilder.control(policy?.description ? policy.description : ''),
      custom: formBuilder.control<boolean>(policy?.custom ? policy.custom : true),
    },
    { updateOn: 'change' },
  );
}

export function formatPolicyFormValue(form?: FormGroup<IPolicyForm>): IPolicy {
  if (!form) {
    return {};
  }

  const value = form.value;

  return {
    id: value.id || undefined,
    description: value.description || undefined,
    custom: value.custom || undefined,
  };
}

export function privacyFormBuilder(formBuilder: FormBuilder, privacy?: IPrivacy): FormGroup<IPrivacyForm> {
  return formBuilder.group(
    {
      secluded: [privacy?.secluded ? privacy.secluded : false],
      home: [privacy?.home ? privacy.home : false],
      neighbor: [privacy?.neighbor ? privacy.neighbor : false],
      others: [privacy?.others ? privacy.others : false],
    },
    { updateOn: 'change' },
  );
}

export function formatPrivacyFormValue(form?: FormGroup<IPrivacyForm>): IPrivacy | undefined {
  if (!form) {
    return;
  }

  const value = form.value;

  return {
    secluded: value.secluded || false,
    home: value.home || false,
    neighbor: value.neighbor || false,
    others: value.others || false,
  };
}

export function roadConditionsFormBuilder(
  formBuilder: FormBuilder,
  roadCondition?: IRoadConditionDescribed,
): FormGroup<IRoadConditionsForm> {
  return formBuilder.group(
    {
      paved: formBuilder.control<boolean>(roadCondition?.paved ? roadCondition.paved : false),
      pavedDescription: formBuilder.control<string>(
        roadCondition?.pavedDescription ? `${roadCondition.pavedDescription}` : '',
      ),
      smoothGravel: formBuilder.control<boolean>(roadCondition?.smoothGravel ? roadCondition.smoothGravel : false),
      smoothGravelDescription: formBuilder.control<string>(
        roadCondition?.smoothGravelDescription ? `${roadCondition.smoothGravelDescription}` : '',
      ),
      poorGravel: formBuilder.control<boolean>(roadCondition?.poorGravel ? roadCondition.poorGravel : false),
      poorGravelDescription: formBuilder.control<string>(
        roadCondition?.poorGravelDescription ? `${roadCondition.poorGravelDescription}` : '',
      ),
      highClearance: formBuilder.control<boolean>(roadCondition?.highClearance ? roadCondition.highClearance : false),
      highClearanceDescription: formBuilder.control(
        roadCondition?.highClearanceDescription ? `${roadCondition.highClearanceDescription}` : '',
      ),
      offRoad: formBuilder.control<boolean>(roadCondition?.offRoad ? roadCondition.offRoad : false),
      offRoadDescription: formBuilder.control<string>(
        roadCondition?.offRoadDescription ? `${roadCondition.offRoadDescription}` : '',
      ),
    },
    {
      updateOn: 'change',
    },
  );
}

export function formatRoadConditionsFormValue(form?: FormGroup<IRoadConditionsForm>): IRoadConditionDescribed {
  if (!form) {
    return {};
  }

  const value = form.value;

  return {
    paved: value.paved === null ? undefined : value.paved,
    pavedDescription: value.pavedDescription || undefined,
    smoothGravel: value.smoothGravel === null ? undefined : value.smoothGravel,
    smoothGravelDescription: value.smoothGravelDescription || undefined,
    poorGravel: value.poorGravel === null ? undefined : value.poorGravel,
    poorGravelDescription: value.poorGravelDescription || undefined,
    highClearance: value.highClearance === null ? undefined : value.highClearance,
    highClearanceDescription: value.highClearanceDescription || undefined,
    offRoad: value.offRoad === null ? undefined : value.offRoad,
    offRoadDescription: value.offRoadDescription || undefined,
  };
}

export function rvRoadConditionsFormBuilder(
  formBuilder: FormBuilder,
  roadCondition?: IRoadCondition,
): FormGroup<IRvRoadConditionsForm> {
  return formBuilder.group(
    {
      paved: formBuilder.control<boolean>(roadCondition?.paved ? roadCondition.paved : false),
      smoothGravel: formBuilder.control<boolean>(roadCondition?.smoothGravel ? roadCondition.smoothGravel : false),
      poorGravel: formBuilder.control<boolean>(roadCondition?.poorGravel ? roadCondition.poorGravel : false),
      highClearance: formBuilder.control<boolean>(roadCondition?.highClearance ? roadCondition.highClearance : false),
      offRoad: formBuilder.control<boolean>(roadCondition?.offRoad ? roadCondition.offRoad : false),
    },
    {
      updateOn: 'change',
    },
  );
}

export function formatRvRoadConditionsFormValue(form?: FormGroup<IRvRoadConditionsForm>): IRoadCondition {
  if (!form) {
    return {};
  }

  const value = form.value;

  return {
    paved: value.paved === null ? undefined : value.paved,
    smoothGravel: value.smoothGravel === null ? undefined : value.smoothGravel,
    poorGravel: value.poorGravel === null ? undefined : value.poorGravel,
    highClearance: value.highClearance === null ? undefined : value.highClearance,
    offRoad: value.offRoad === null ? undefined : value.offRoad,
  };
}

export function signalReceptionFormBuilder(
  formBuilder: FormBuilder,
  signalReception?: ISignalReception,
): FormGroup<ISignalReceptionForm> {
  return formBuilder.group({
    strength: [signalReception?.strength ? signalReception.strength : null, [Validators.min(0), Validators.max(4)]],
    source: [signalReception?.source ? signalReception.source : ''],
    id: [signalReception?.id ? signalReception.id : null],
  });
}

export function formatSignalReceptionFormValue(form?: FormGroup<ISignalReceptionForm>): ISignalReception {
  if (!form) {
    return {};
  }

  const value = form.value;

  return {
    id: value.id || undefined,
    strength: value.strength === null ? undefined : value.strength,
    source: value.source || undefined,
  };
}

export function vehiclesFormBuilder(formBuilder: FormBuilder, vehicle?: IVehicle): FormGroup<IVehiclesForm> {
  return formBuilder.group(
    {
      classA: [vehicle?.classA ? vehicle.classA : false],
      classB: [vehicle?.classB ? vehicle.classB : false],
      classC: [vehicle?.classC ? vehicle.classC : false],
      fifthWheel: [vehicle?.fifthWheel ? vehicle.fifthWheel : false],
      travelTrailer: [vehicle?.travelTrailer ? vehicle.travelTrailer : false],
      truckCamper: [vehicle?.truckCamper ? vehicle.truckCamper : false],
      teardrop: [vehicle?.teardrop ? vehicle.teardrop : false],
      popup: [vehicle?.popup ? vehicle.popup : false],
    },
    {
      updateOn: 'change',
    },
  );
}

export function formatVehiclesFormValue(form?: FormGroup<IVehiclesForm>): IVehicle {
  if (!form) {
    return {
      classA: false,
      classB: false,
      classC: false,
      fifthWheel: false,
      travelTrailer: false,
      truckCamper: false,
      teardrop: false,
      popup: false,
    };
  }

  const value = form.value;

  return {
    classA: value.classA || false,
    classB: value.classB || false,
    classC: value.classC || false,
    fifthWheel: value.fifthWheel || false,
    travelTrailer: value.travelTrailer || false,
    truckCamper: value.truckCamper || false,
    teardrop: value.teardrop || false,
    popup: value.popup || false,
  };
}

export function waypointFormBuilder(formBuilder: FormBuilder, waypoint?: IWaypoint): FormGroup<IWaypointForm> {
  return formBuilder.group({
    name: formBuilder.control(waypoint?.name ? waypoint.name : ''),
    lat: formBuilder.control(waypoint?.lat ? waypoint.lat : null, [Validators.min(-90), Validators.max(90)]),
    lon: formBuilder.control(waypoint?.lon ? waypoint.lon : null, [Validators.min(-180), Validators.max(180)]),
  });
}

export function formatWaypointFormValue(form?: FormGroup<IWaypointForm>): IWaypoint | undefined {
  if (!form) {
    return;
  }

  const value = form.value;

  return {
    name: value.name || '',
    lat: value.lat || value.lat === 0 ? value.lat : NaN,
    lon: value.lon || value.lon === 0 ? value.lon : NaN,
  };
}
