import { IUser, Role } from '@curbnturf/entities';

export namespace AuthHelpers {
  export const isUserRole = (user: IUser): boolean => {
    return user.role === Role.guest || user.role === Role.boondocker || user.role === Role.host;
  };

  export const isSalesRole = (user: IUser): boolean => {
    return user.role === Role.sales || (user.role?.toLowerCase() as Role) === Role.salesManager;
  };

  export const isAdminRole = (user: IUser): boolean => {
    return user.role === Role.admin;
  };

  export const hasSalesOwnership = (authedUser: IUser, targetUser: IUser): boolean => {
    if (authedUser.role === Role.sales || (authedUser.role?.toLowerCase() as Role) === Role.salesManager) {
      if (authedUser.affiliateRef === targetUser.source) {
        return true;
      }
    }
    return false;
  };
}
