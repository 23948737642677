export const migration = '20230201154800';

export const statements = `
DROP TABLE IF EXISTS cached_http_requests;

CREATE TABLE IF NOT EXISTS cached_http_requests (
  id INTEGER PRIMARY KEY NOT NULL,
  method TEXT NOT NULL,
  url TEXT NOT NULL,
  body TEXT NULL,
  options TEXT NULL,
  retries INTEGER DEFAULT 0,
  sync_id TEXT NULL,
  sync_action TEXT NULL,
  last_modified INTEGER DEFAULT (strftime('%s', 'now'))
);

DROP TABLE IF EXISTS cached_http_responses;

CREATE TABLE IF NOT EXISTS cached_http_responses (
  id INTEGER PRIMARY KEY NOT NULL,
  method TEXT NOT NULL,
  url TEXT NOT NULL,
  body TEXT NULL,
  response TEXT NULL,
  last_modified INTEGER DEFAULT (strftime('%s', 'now'))
);

DROP TABLE IF EXISTS data_cache;

CREATE TABLE IF NOT EXISTS data_cache (
  cache_name TEXT PRIMARY KEY NOT NULL,
  last_modified INTEGER DEFAULT (strftime('%s', 'now'))
);

DROP TABLE IF EXISTS listings;

CREATE TABLE IF NOT EXISTS listings (
    id INTEGER PRIMARY KEY NOT NULL,
    name TEXT NULL,
    type TEXT NULL,
    lat REAL NULL,
    lng REAL NULL,
    price REAL NULL,
    location TEXT NULL,
    rating REAL,
    ratingCount INTEGER
);

DROP TABLE IF EXISTS listing_amenities;

CREATE TABLE IF NOT EXISTS listing_amenities (
    id INTEGER PRIMARY KEY NOT NULL,
    listing_id INTEGER,
    name TEXT NOT NULL
);

DROP TABLE IF EXISTS listing_photos;

CREATE TABLE IF NOT EXISTS listing_photos (
    id INTEGER PRIMARY KEY NOT NULL,
    listing_id INTEGER,
    caption TEXT NULL,
    key TEXT NOT NULL
);

DROP TABLE IF EXISTS points_of_interest;

CREATE TABLE IF NOT EXISTS points_of_interest (
    id INTEGER PRIMARY KEY NOT NULL,
    category_dump INTEGER NOT NULL DEFAULT 0,
    category_rest_area INTEGER NOT NULL DEFAULT 0,
    category_wifi INTEGER NOT NULL DEFAULT 0,
    category_potable_water INTEGER NOT NULL DEFAULT 0,
    category_propane INTEGER NOT NULL DEFAULT 0,
    activity_biking INTEGER NOT NULL DEFAULT 0,
    activity_boating INTEGER NOT NULL DEFAULT 0,
    activity_fishing INTEGER NOT NULL DEFAULT 0,
    activity_golf INTEGER NOT NULL DEFAULT 0,
    activity_hiking INTEGER NOT NULL DEFAULT 0,
    activity_horseback INTEGER NOT NULL DEFAULT 0,
    activity_hunting INTEGER NOT NULL DEFAULT 0,
    activity_kayaking INTEGER NOT NULL DEFAULT 0,
    activity_motor_sports INTEGER NOT NULL DEFAULT 0,
    activity_ohv INTEGER NOT NULL DEFAULT 0,
    activity_rafting INTEGER NOT NULL DEFAULT 0,
    activity_rock_climbing INTEGER NOT NULL DEFAULT 0,
    activity_snow_sports INTEGER NOT NULL DEFAULT 0,
    activity_surfing INTEGER NOT NULL DEFAULT 0,
    activity_swimming INTEGER NOT NULL DEFAULT 0,
    activity_water_sports INTEGER NOT NULL DEFAULT 0,
    activity_wildlife_watching INTEGER NOT NULL DEFAULT 0,
    activity_wind_sports INTEGER NOT NULL DEFAULT 0,
    custom_activity TEXT NULL,
    poi_name TEXT NULL,
    description TEXT NULL,
    address TEXT NULL,
    city TEXT NULL,
    state TEXT NULL,
    zipcode TEXT NULL,
    lat REAL NULL,
    lng REAL NULL,
    user_id INTEGER NULL,
    user_first_name TEXT NULL,
    user_last_name TEXT NULL,
    url TEXT NULL,
    phone TEXT NULL,
    favorite INTEGER NOT NULL DEFAULT 0,
    ratings REAL NULL
);

DROP TABLE IF EXISTS point_of_interest_photos;

CREATE TABLE IF NOT EXISTS point_of_interest_photos (
    id INTEGER PRIMARY KEY NOT NULL,
    poi_id INTEGER NOT NULL,
    caption TEXT NULL,
    key TEXT NOT NULL
);

DROP TABLE IF EXISTS sites;

CREATE TABLE IF NOT EXISTS sites (
    id INTEGER PRIMARY KEY NOT NULL,
    user_id INTEGER NULL,
    type TEXT NULL,
    name TEXT NULL,
    lat REAL NULL,
    lng REAL NULL,
    address TEXT NULL,
    city TEXT NULL,
    state TEXT NULL,
    zipcode TEXT NULL
);

DROP TABLE IF EXISTS site_photos;

CREATE TABLE IF NOT EXISTS site_photos (
    id INTEGER PRIMARY KEY NOT NULL,
    site_id INTEGER NOT NULL,
    caption TEXT NULL,
    key TEXT NULL
);

DROP TABLE IF EXISTS reservations;

CREATE TABLE IF NOT EXISTS reservations (
    id INTEGER PRIMARY KEY NOT NULL,
    date_type TEXT NULL,
    date_from TEXT NULL,
    date_to TEXT NULL,
    user_first_name TEXT NULL,
    user_last_name TEXT NULL,
    user_id INTEGER NOT NULL DEFAULT 0,
    site_id INTEGER NOT NULL DEFAULT 0,
    site_name TEXT NULL,
    guest_rated INTEGER NOT NULL DEFAULT 0,
    site_rated INTEGER NOT NULL DEFAULT 0,
    status TEXT NULL,
    rv_id INTEGER NULL
);

DROP TABLE IF EXISTS reservation_extras;

CREATE TABLE IF NOT EXISTS reservation_extras (
    id INTEGER PRIMARY KEY NOT NULL,
    reservation_id INTEGER NOT NULL,
    count INTEGER NULL,
    label TEXT NULL,
    name TEXT NULL,
    description TEXT NULL,
    price REAL NULL
);

DROP TABLE IF EXISTS reservation_price_components;

CREATE TABLE IF NOT EXISTS reservation_price_components (
    id INTEGER PRIMARY KEY NOT NULL,
    reservation_id INTEGER NOT NULL,
    date TEXT NULL,
    type TEXT NOT NULL,
    value REAL NOT NULL,
    fee REAL NULL,
    taxes STRING NULL,
    discounts STRING NULL
);

DROP TABLE IF EXISTS schedules;

CREATE TABLE IF NOT EXISTS schedules (
    id INTEGER PRIMARY KEY NOT NULL,
    site_id INTEGER NOT NULL,
    site_name TEXT NULL,
    confirmation_code TEXT NOT NULL,
    date_type TEXT NULL,
    date_from TEXT NULL,
    date_to TEXT NULL,
    email TEXT NULL,
    buses INTEGER NOT NULL DEFAULT 0,
    cars INTEGER NOT NULL DEFAULT 0,
    deleted INTEGER NOT NULL DEFAULT 0,
    completed INTEGER NOT NULL DEFAULT 0,
    rated INTEGER NOT NULL DEFAULT 0,
    donated INTEGER NOT NULL DEFAULT 0
);

DROP TABLE IF EXISTS offline_sites;

CREATE TABLE IF NOT EXISTS offline_sites (
    sync_id TEXT PRIMARY KEY NOT NULL,
    id INTEGER NULL,
    user_id INTEGER NULL,
    type TEXT NULL,
    body_content TEXT NULL,
    last_modified INTEGER DEFAULT (strftime('%s', 'now'))
);

DROP TABLE IF EXISTS offline_site_photos;

CREATE TABLE IF NOT EXISTS offline_site_photos (
    id INTEGER PRIMARY KEY NOT NULL,
    sync_id TEXT NULL,
    site_id TEXT NULL,
    caption TEXT NULL,
    key TEXT NULL
);
`;
