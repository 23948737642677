import { HttpErrorResponse } from '@angular/common/http';
import { IMessage, IMessageChannel, IMessageQuery } from '@curbnturf/entities';
import { createAction, props } from '@ngrx/store';

export enum MessageActionTypes {
  LoadMessageChannel = '[MessageChannel] Load MessageChannel',
  LoadReservationMessageChannel = '[MessageChannel] Load Reservation MessageChannel',
  LoadMessageChannels = '[MessageChannel] Load MessageChannels',
  MessageChannelLoaded = '[MessageChannel] MessageChannel Loaded',
  MessageChannelsLoaded = '[MessageChannel] MessageChannels Loaded',
  MessageChannelLoadError = '[MessageChannel] MessageChannel Load Error',
  CreateMessage = '[Message] Create Message',
  MessageChannelCreated = '[MessageChannel] MessageChannel Created',
  SelectMessageChannel = '[MessageChannel] Select MessageChannel',
  ReadMessages = '[MessageChannel] Read Messages',
  RegisterPushNotifications = '[MessageChannel] Register Push Notifications',
  RegisterPushNotificationsSuccess = '[MessageChannel] Register Push Notifications Success',
  RegisterPushNotificationsFailed = '[MessageChannel] Register Push Notifications Failed',
  ShowChatModal = '[MessageChannel] Show/Hide Chat Modal',
}

export const loadMessageChannel = createAction(MessageActionTypes.LoadMessageChannel, props<{ channel: number }>());

export const loadReservationMessageChannel = createAction(
  MessageActionTypes.LoadReservationMessageChannel,
  props<{ channel: number }>(),
);

export const loadMessageChannels = createAction(
  MessageActionTypes.LoadMessageChannels,
  props<{ query: IMessageQuery }>(),
);

export const messageChannelLoadError = createAction(
  MessageActionTypes.MessageChannelLoadError,
  props<{ error: HttpErrorResponse }>(),
);

export const messageChannelLoaded = createAction(
  MessageActionTypes.MessageChannelLoaded,
  props<{ channel: IMessageChannel }>(),
);

export const messageChannelsLoaded = createAction(
  MessageActionTypes.MessageChannelsLoaded,
  props<{ channels: IMessageChannel[] }>(),
);

export const createMessage = createAction(MessageActionTypes.CreateMessage, props<{ message: IMessage }>());

export const messageChannelCreated = createAction(
  MessageActionTypes.MessageChannelCreated,
  props<{ channels: IMessageChannel[] }>(),
);

export const selectMessageChannel = createAction(
  MessageActionTypes.SelectMessageChannel,
  props<{ channel?: IMessageChannel }>(),
);

export const readMessages = createAction(MessageActionTypes.ReadMessages, props<{ channel: IMessageChannel }>());

export const registerPushNotifications = createAction(MessageActionTypes.RegisterPushNotifications);

export const registerPushNotificationsSuccess = createAction(
  MessageActionTypes.RegisterPushNotificationsSuccess,
  props<{ token: string }>(),
);

export const registerPushNotificationsFailed = createAction(
  MessageActionTypes.RegisterPushNotificationsFailed,
  props<{ error?: any }>(),
);

export const showChatModal = createAction(
  MessageActionTypes.ShowChatModal,
  props<{ showModal: boolean; channelId?: number }>(),
);
