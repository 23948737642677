import { IReservation, IReservationExtra, ISiteExtra } from '../interfaces';

export class ReservationExtra implements IReservationExtra {
  id?: number;
  count: number;
  extra: ISiteExtra;
  extraId?: number;
  reservation?: IReservation;
  reservationId?: number;
  pendingChange?: boolean;

  constructor(data?: IReservationExtra) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IReservationExtra): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
