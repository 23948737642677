import { Injectable } from '@angular/core';
import { FlowStepType, IListingType, IPhoto, IRV, ISignUpFlowStep, ISite, IUser } from '@curbnturf/entities';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as SignUpActions from './sign-up-state.actions';
import { SignUpStateState } from './sign-up-state.reducer';
import * as SignUpStateSelectors from './sign-up-state.selectors';

@Injectable({
  providedIn: 'root',
})
export class SignUpStateFacade {
  signUpState$: Observable<SignUpStateState>;
  spinner$: Observable<boolean>;
  steps$: Observable<{
    steps: ISignUpFlowStep[];
    currentStep: ISignUpFlowStep | undefined;
    previousStep: ISignUpFlowStep | undefined;
    nextStep: ISignUpFlowStep | undefined;
    isLastStep: boolean;
    isFirstStep: boolean;
  }>;
  user$: Observable<IUser | undefined>;
  site$: Observable<ISite | undefined>;
  rv$: Observable<IRV | undefined>;
  percentComplete$: Observable<number>;
  boondocking$: Observable<boolean>;
  isLastStep$: Observable<boolean>;
  isFirstStep$: Observable<boolean>;
  guest$: Observable<boolean>;

  constructor(private store: Store<SignUpStateState>) {
    this.signUpState$ = this.store.pipe(select(SignUpStateSelectors.getSignUpStateState));
    this.spinner$ = this.store.pipe(select(SignUpStateSelectors.getSpinner));
    this.steps$ = this.store.pipe(select(SignUpStateSelectors.getSteps));
    this.user$ = this.store.pipe(select(SignUpStateSelectors.getUser));
    this.site$ = this.store.pipe(select(SignUpStateSelectors.getSite));
    this.rv$ = this.store.pipe(select(SignUpStateSelectors.getRv));
    this.percentComplete$ = this.store.pipe(select(SignUpStateSelectors.getPercentComplete));
    this.boondocking$ = this.store.pipe(select(SignUpStateSelectors.getBoondocking));
    this.isLastStep$ = this.store.pipe(select(SignUpStateSelectors.isLastStep));
    this.isFirstStep$ = this.store.pipe(select(SignUpStateSelectors.isFirstStep));
    this.guest$ = this.store.pipe(select(SignUpStateSelectors.getGuest));
  }

  init() {
    this.store.dispatch(SignUpActions.init());
  }

  setState(signUp: SignUpStateState) {
    this.store.dispatch(SignUpActions.setState(signUp));
  }

  redirect(url: string) {
    this.store.dispatch(SignUpActions.redirect({ routeTo: url }));
  }

  next(override?: string) {
    this.store.dispatch(SignUpActions.nextStep({ override }));
  }

  previous(override?: string) {
    this.store.dispatch(SignUpActions.previousStep({ override }));
  }

  setCurrentStep(step: FlowStepType) {
    this.store.dispatch(SignUpActions.setCurrentStepType({ step }));
  }

  startGuestFlow(user: IUser) {
    this.store.dispatch(SignUpActions.startGuestFlow({ user }));
  }

  startHostFlow(user: IUser) {
    this.store.dispatch(SignUpActions.startHostFlow({ user }));
  }

  setSiteTypeHost(site: ISite) {
    this.store.dispatch(SignUpActions.setSiteTypeHost({ site }));
  }

  setSiteTypeBoondock(site: ISite) {
    this.store.dispatch(SignUpActions.setSiteTypeBoondock({ site }));
  }

  saveSourceStep(user: IUser) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveSourceStep({ user }));
  }

  savePhoneStep(user: IUser, assistance: boolean | null | undefined) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.savePhoneStep({ user, assistance: assistance ?? true }));
  }

  savePropertyTypeStep(site: ISite) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.savePropertyTypeStep({ site }));
  }

  saveAmenitiesStep(site: ISite) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveAmenitiesStep({ site }));
  }

  saveActivitiesStep(site: ISite) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveActivitiesStep({ site }));
  }

  saveIsolationStep(site: ISite) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveIsolationStep({ site }));
  }

  saveBoondockLandStep(site: ISite) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveBoondockLandStep({ site }));
  }

  saveVehiclesStep(site: ISite) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveVehiclesStep({ site }));
  }

  saveDetailsStep(site: ISite) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveDetailsStep({ site }));
  }

  savePriceStep(site: ISite) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.savePriceStep({ site }));
  }

  saveBookingStep(site: ISite) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveBookingStep({ site }));
  }

  savePoliciesStep(site: ISite) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.savePoliciesStep({ site }));
  }

  saveLocationStep(site: ISite) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveLocationStep({ site }));
  }

  startUserTentFlow() {
    this.store.dispatch(SignUpActions.startUserTentFlow());
  }

  startUserRvFlow() {
    this.store.dispatch(SignUpActions.startUserRvFlow());
  }

  saveUserRvStep(rv: IRV) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveUserRvStep({ rv }));
  }

  saveUserRvSizeStep(rv: IRV) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveUserRvSizeStep({ rv }));
  }

  saveUserTentStep(rv: IRV) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveUserTentStep({ rv }));
  }

  saveUserRoadConditionStep(rv: IRV) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveUserRoadConditionStep({ rv }));
  }

  saveDescriptionStep(site: ISite) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.saveDescriptionStep({ site }));
  }

  updateProfilePhoto(photo: IPhoto) {
    // Since all the processing here happens on Photo upload there is
    // nothing to save and thus no need to show a spinner here.
    this.store.dispatch(SignUpActions.updateProfilePhoto({ photo }));
  }

  updateSitePhotos(photos: IPhoto[], siteId?: number) {
    this.store.dispatch(SignUpActions.updateSitePhotos({ photos, siteId }));
  }

  savePhotosStep(site: ISite) {
    this.store.dispatch(SignUpActions.displaySpinner());
    this.store.dispatch(SignUpActions.savePhotosStep({ site }));
  }

  publish() {
    this.store.dispatch(SignUpActions.publishSite());
  }

  publishLater() {
    this.store.dispatch(SignUpActions.publishLater());
  }

  salesComplete(siteId: number) {
    this.store.dispatch(SignUpActions.siteSalesComplete({ siteId }));
  }

  displaySpinner() {
    this.store.dispatch(SignUpActions.displaySpinner());
  }

  hideSpinner() {
    this.store.dispatch(SignUpActions.hideSpinner());
  }

  resetProcess() {
    this.store.dispatch(SignUpActions.resetProcess());
    this.store.dispatch(SignUpActions.clearInProgressSite());
  }

  setInProgressSite(site: ISite) {
    this.store.dispatch(SignUpActions.setInProgressSite({ site }));
  }

  startNewSite(siteType: IListingType['type'], user?: IUser) {
    this.store.dispatch(SignUpActions.startNewSite({ siteType, user }));
  }

  saveClientProfileStep(user: IUser, newsletter?: boolean | null) {
    this.store.dispatch(SignUpActions.saveClientProfileStep({ user, newsletter }));
  }
}
