import { FlowStepType, IListingType, IPhoto, IRV, ISignUpFlowStep, ISite, IUser } from '@curbnturf/entities';
import { createAction, props } from '@ngrx/store';
import { SignUpStateState } from './sign-up-state.reducer';

export enum SignUpStateActionTypes {
  SignUpInitialize = '[SignUpState] Initialize',
  SignUpSetState = '[SignUpState] Set SignUpState',
  SignUpRedirect = '[SignUpState] Redirect',
  SignUpNextStep = '[SignUpState] Next Step',
  SignUpPreviousStep = '[SignUpState] Previous Step',
  SignUpSaveState = '[SignUpState] Save State',
  SignUpUpdateInProgressSiteId = '[SignUpState] Update In Progress Site ID',
  SignUpSaveStateSuccess = '[SignUpState] Save State Success',
  SignUpSaveStateFailed = '[SignUpState] Save State Failure',
  SignUpStartGuestFlow = '[SignUpState] Begin Guest Flow',
  SignUpStartHostFlow = '[SignUpState] Begin Host Flow',
  SignUpSetCurrentStep = '[SignUpState] Set Current Step',
  SignUpSetCurrentStepType = '[SignUpState] Set Current Step Type',
  SignUpSetSiteTypeHost = '[SignUp] Set Site Type Host',
  SignUpSetSiteTypeHostSuccess = '[SignUp] Set Site Type Host Success',
  SignUpSetSiteTypeBoondock = '[SignUp] Set Site Type Boondock',
  SignUpSetSiteTypeBoondockSuccess = '[SignUp] Set Site Type Boondock Success',
  SignUpSaveSourceStep = '[SignUp] Save Source Step',
  SignUpSavePhoneStep = '[SignUp] Save Phone Step',
  SignUpSavePropertyTypeStep = '[SignUp] Save Property Type Step',
  SignUpSaveAmenitiesStep = '[SignUp] Save Amenities Step',
  SignUpSaveActivitiesStep = '[SignUp] Save Activities Step',
  SignUpSaveIsolationStep = '[SignUp] Save Isolation Step',
  SignUpSaveBoondockLandStep = '[SignUp] Save Boondock Land Step',
  SignUpSaveVehiclesStep = '[SignUp] Save Vehicles Step',
  SignUpSaveDetailsStep = '[SignUp] Save Details Step',
  SignUpSavePriceStep = '[SignUp] Save Price Step',
  SignUpSaveBookingStep = '[SignUp] Save Booking Step',
  SignUpSavePoliciesStep = '[SignUp] Save Policies Step',
  SignUpSaveLocationStep = '[SignUp] Save Location Step',
  SignUpStartUserTentFlow = '[SignUp] Start User Tent Flow',
  SignUpStartUserRvFlow = '[SignUp] Start User RV Flow',
  SignUpSaveUserRvStep = '[SignUp] Save User RV Step',
  SignUpSaveUserRvSizeStep = '[SignUp] Save User RV Size Step',
  SignUpSaveUserTentStep = '[SignUp] Save User Tent Step',
  SignUpSaveUserRoadConditionsStep = '[SignUp] Save User Road Conditions Step',
  SignUpSaveDescriptionStep = '[SignUp] Save Description Step',
  SignUpSaveClientProfileStep = '[SignUp] GROW - Save Client Profile Step',
  SignUpSaveClientProfileStepSuccess = '[SignUp] GROW - Save Client Profile Step Success',
  SignUpSaveClientProfileStepFailed = '[SignUp] GROW - Save Client Profile Step Failed',
  SignUpSendSalesClientEmail = '[SignUp] GROW - Send Sales Client Email',
  SignUpUpdateProfilePhoto = '[SignUp] Update Profile Photo',
  SignUpUploadProfileImage = '[SignUp] Upload Profile Photo',
  SignUpSitePhotos = '[SignUp] Update Site Photos',
  SignUpSavePhotosStep = '[SignUp] Save Photos Step',
  SiteSalesComplete = '[SignUp] Sales Complete Listing',
  SignUpPublishSite = '[SignUp] Publish Site',
  SignUpPublishSiteFailed = '[SignUp] Publish Site Failed',
  SignUpPublishLater = '[SignUp] Publish Later',
  SignUpPublishLaterFailed = '[SignUp] Publish Later Failed',
  SignUpRestoreSite = '[SignUp] Restore Site From Server',
  SignUpRestoreSiteSuccess = '[SignUp] Restore Site From Server Success',
  SignUpRestoreSiteFailed = '[SignUp] Restore Site From Server Failed',
  SignUpRestoreUser = '[SignUp] Restore User From Server',
  SignUpRestoreUserSuccess = '[SignUp] Restore User From Server Success',
  SignUpRestoreUserFailed = '[SignUp] Restore User From Server Failed',
  SignUpResetProcess = '[SignUp] Reset Sign Up Process',
  SignUpSetInProgressSite = '[SignUp] Set In Progress Site',
  SignUpClearInProgressSite = '[SignUp] Clear In Progress Site',
  SignUpClearInProgressSiteSuccess = '[SignUp] Clear In Progress Site Success',
  SignUpDisplaySpinner = '[SignUp] Display Spinner',
  SignUpHideSpinner = '[SignUp] Hide Spinner',
  SignUpStartNewSite = '[SignUp] Start New Site',
  SignUpStartNewSiteSuccess = '[SignUp] Start New Site Success',
  SignUpStartNewSiteFailed = '[SignUp] Start New Site Failed',
  SignUpRegisterForNewsletter = '[SignUp] Register For Newsletter',
}

export const init = createAction(SignUpStateActionTypes.SignUpInitialize);

export const setState = createAction(SignUpStateActionTypes.SignUpSetState, props<SignUpStateState>());

// @todo Do we really need this action?
export const redirect = createAction(SignUpStateActionTypes.SignUpRedirect, props<{ routeTo: string }>());

export const nextStep = createAction(SignUpStateActionTypes.SignUpNextStep, props<{ override?: string }>());

export const previousStep = createAction(SignUpStateActionTypes.SignUpPreviousStep, props<{ override?: string }>());

export const saveState = createAction(SignUpStateActionTypes.SignUpSaveState, props<{ redirect?: ISignUpFlowStep }>());

export const saveStateSuccess = createAction(
  SignUpStateActionTypes.SignUpSaveStateSuccess,
  props<{ user?: IUser; site?: ISite }>(),
);

export const saveStateFailed = createAction(SignUpStateActionTypes.SignUpSaveStateFailed, props<{ error: any }>());

export const updateInProgressSiteId = createAction(SignUpStateActionTypes.SignUpUpdateInProgressSiteId);

export const startGuestFlow = createAction(SignUpStateActionTypes.SignUpStartGuestFlow, props<{ user: IUser }>());

export const startHostFlow = createAction(SignUpStateActionTypes.SignUpStartHostFlow, props<{ user?: IUser }>());

export const setCurrentStep = createAction(
  SignUpStateActionTypes.SignUpSetCurrentStep,
  props<{ step: ISignUpFlowStep }>(),
);

export const setCurrentStepType = createAction(
  SignUpStateActionTypes.SignUpSetCurrentStepType,
  props<{ step: FlowStepType }>(),
);

export const setSiteTypeHost = createAction(SignUpStateActionTypes.SignUpSetSiteTypeHost, props<{ site?: ISite }>());

export const setSiteTypeHostSuccess = createAction(
  SignUpStateActionTypes.SignUpSetSiteTypeHostSuccess,
  props<{ site?: ISite; user?: IUser }>(),
);

export const setSiteTypeBoondock = createAction(
  SignUpStateActionTypes.SignUpSetSiteTypeBoondock,
  props<{ site?: ISite }>(),
);

export const setSiteTypeBoondockSuccess = createAction(
  SignUpStateActionTypes.SignUpSetSiteTypeBoondockSuccess,
  props<{ site?: ISite; user?: IUser }>(),
);

export const saveSourceStep = createAction(SignUpStateActionTypes.SignUpSaveSourceStep, props<{ user: IUser }>());

export const savePhoneStep = createAction(
  SignUpStateActionTypes.SignUpSavePhoneStep,
  props<{ user: IUser; assistance: boolean }>(),
);

export const savePropertyTypeStep = createAction(
  SignUpStateActionTypes.SignUpSavePropertyTypeStep,
  props<{ site: ISite }>(),
);

export const saveAmenitiesStep = createAction(SignUpStateActionTypes.SignUpSaveAmenitiesStep, props<{ site: ISite }>());

export const saveActivitiesStep = createAction(
  SignUpStateActionTypes.SignUpSaveActivitiesStep,
  props<{ site: ISite }>(),
);

export const saveIsolationStep = createAction(SignUpStateActionTypes.SignUpSaveIsolationStep, props<{ site: ISite }>());

export const saveBoondockLandStep = createAction(
  SignUpStateActionTypes.SignUpSaveBoondockLandStep,
  props<{ site: ISite }>(),
);

export const saveVehiclesStep = createAction(SignUpStateActionTypes.SignUpSaveVehiclesStep, props<{ site: ISite }>());

export const saveDetailsStep = createAction(SignUpStateActionTypes.SignUpSaveDetailsStep, props<{ site: ISite }>());

export const savePriceStep = createAction(SignUpStateActionTypes.SignUpSavePriceStep, props<{ site: ISite }>());

export const saveBookingStep = createAction(SignUpStateActionTypes.SignUpSaveBookingStep, props<{ site: ISite }>());

export const savePoliciesStep = createAction(SignUpStateActionTypes.SignUpSavePoliciesStep, props<{ site: ISite }>());

export const saveLocationStep = createAction(SignUpStateActionTypes.SignUpSaveLocationStep, props<{ site: ISite }>());

export const startUserTentFlow = createAction(SignUpStateActionTypes.SignUpStartUserTentFlow);

export const startUserRvFlow = createAction(SignUpStateActionTypes.SignUpStartUserRvFlow);

export const saveUserRvStep = createAction(SignUpStateActionTypes.SignUpSaveUserRvStep, props<{ rv: IRV }>());

export const saveUserRvSizeStep = createAction(SignUpStateActionTypes.SignUpSaveUserRvSizeStep, props<{ rv: IRV }>());

export const saveUserTentStep = createAction(SignUpStateActionTypes.SignUpSaveUserTentStep, props<{ rv: IRV }>());

export const saveUserRoadConditionStep = createAction(
  SignUpStateActionTypes.SignUpSaveUserRoadConditionsStep,
  props<{ rv: IRV }>(),
);

export const saveDescriptionStep = createAction(
  SignUpStateActionTypes.SignUpSaveDescriptionStep,
  props<{ site: ISite }>(),
);

export const saveClientProfileStep = createAction(
  SignUpStateActionTypes.SignUpSaveClientProfileStep,
  props<{ user: IUser, newsletter: boolean | null | undefined }>(),
);

export const saveClientProfileStepSuccess = createAction(
  SignUpStateActionTypes.SignUpSaveClientProfileStepSuccess,
  props<{ user: IUser }>(),
);

export const saveClientProfileStepFailed = createAction(
  SignUpStateActionTypes.SignUpSaveClientProfileStepFailed,
  props<{ error: any }>(),
);

export const sendSalesClientEmail = createAction(
  SignUpStateActionTypes.SignUpSendSalesClientEmail,
  props<{ user: IUser }>(),
);

export const updateProfilePhoto = createAction(
  SignUpStateActionTypes.SignUpUpdateProfilePhoto,
  props<{ photo: IPhoto }>(),
);

export const updateSitePhotos = createAction(
  SignUpStateActionTypes.SignUpSitePhotos,
  props<{ photos?: IPhoto[]; siteId?: number }>(),
);

export const savePhotosStep = createAction(SignUpStateActionTypes.SignUpSavePhotosStep, props<{ site: ISite }>());

export const publishSite = createAction(SignUpStateActionTypes.SignUpPublishSite);

export const publishSiteFailed = createAction(SignUpStateActionTypes.SignUpPublishSiteFailed, props<{ error: any }>());

export const publishLater = createAction(SignUpStateActionTypes.SignUpPublishLater);

export const publishLaterFailed = createAction(
  SignUpStateActionTypes.SignUpPublishLaterFailed,
  props<{ error: any }>(),
);

export const restoreSite = createAction(SignUpStateActionTypes.SignUpRestoreSite, props<{ siteId?: number }>());

export const restoreSiteSuccess = createAction(
  SignUpStateActionTypes.SignUpRestoreSiteSuccess,
  props<{ site: ISite; currentUser?: IUser }>(),
);

export const restoreSiteFailed = createAction(SignUpStateActionTypes.SignUpRestoreSiteFailed, props<{ error: any }>());

export const restoreUser = createAction(SignUpStateActionTypes.SignUpRestoreUser);

export const restoreUserSuccess = createAction(
  SignUpStateActionTypes.SignUpRestoreUserSuccess,
  props<{ user: IUser; currentUser?: IUser }>(),
);

export const restoreUserFailed = createAction(SignUpStateActionTypes.SignUpRestoreUserFailed, props<{ error: any }>());

export const resetProcess = createAction(SignUpStateActionTypes.SignUpResetProcess);

export const setInProgressSite = createAction(SignUpStateActionTypes.SignUpSetInProgressSite, props<{ site: ISite }>());

export const clearInProgressSite = createAction(SignUpStateActionTypes.SignUpClearInProgressSite);

export const clearInProgressSiteSuccess = createAction(SignUpStateActionTypes.SignUpClearInProgressSiteSuccess);

export const displaySpinner = createAction(SignUpStateActionTypes.SignUpDisplaySpinner);

export const hideSpinner = createAction(SignUpStateActionTypes.SignUpHideSpinner);

export const startNewSite = createAction(
  SignUpStateActionTypes.SignUpStartNewSite,
  props<{ siteType: IListingType['type']; user?: IUser; currentUser?: IUser }>(),
);

export const startNewSiteSuccess = createAction(
  SignUpStateActionTypes.SignUpStartNewSiteSuccess,
  props<{ site: ISite; user: IUser }>(),
);

export const startNewSiteFailed = createAction(
  SignUpStateActionTypes.SignUpStartNewSiteFailed,
  props<{ error: any }>(),
);

export const siteSalesComplete = createAction(
  SignUpStateActionTypes.SiteSalesComplete,
  props<{ siteId: number }>(),
);

export const uploadUserProfileImage = createAction(
  SignUpStateActionTypes.SignUpUploadProfileImage,
  props<{ formData: FormData; data?: { id?: number } }>(),
);

export const registerForNewsletter = createAction(
  SignUpStateActionTypes.SignUpRegisterForNewsletter,
  props<{ user: IUser }>(),
);
