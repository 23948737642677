import { createReducer, on } from '@ngrx/store';
import * as AlertActions from './alert.actions';

/**
 * Interface for the 'Alert' data used in
 *  - AlertState, and
 *  - alertReducer
 */
export interface AlertData {
  level?: string;
  title: string;
  body: string;
}

/**
 * Interface to the part of the Store containing AlertState
 * and other information related to AlertData.
 */
export interface AlertState {
  readonly alert: AlertData;
}

export const initialState: AlertState = {
  alert: {
    title: '',
    body: '',
  },
};

export const alertReducer = createReducer(
  initialState,
  on(AlertActions.displayAlert, (state, action) => {
    return {
      ...state,
      alert: { ...action },
    };
  }),
);
