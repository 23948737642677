import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumSelector',
    standalone: true,
})
export class EnumSelectorPipe implements PipeTransform {
  transform(value: Record<string, unknown>): { key: string; value: string }[] {
    const keys = [];
    for (const enumMember in value) {
      if (value.hasOwnProperty(enumMember)) {
        keys.push({ key: enumMember, value: value[enumMember] as string });
      }
    }
    return keys;
  }
}
