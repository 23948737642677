import { inject } from '@angular/core';
import * as AlertActions from '@curbnturf/alert/src/lib/+state/alert.actions';
import {
  ActivityLocation,
  IActivityLocation,
  IActivityLocationSearchRequest,
  IPhoto,
  NGRX_NO_ACTION,
} from '@curbnturf/entities';
import { stringFromError } from '@curbnturf/form-helpers';
import { locationQuery } from '@curbnturf/location';
import { Logger } from '@curbnturf/network/src/lib/log/logger';
import { GlobalSpinnerService } from '@curbnturf/ui/src/lib/spinner/global-spinner.service';
import * as UserSelectors from '@curbnturf/user/src/lib/+state/user.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of, withLatestFrom } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, throttleTime } from 'rxjs/operators';
import { ActivityService } from '../activity.service';
import * as ActivityActions from './activity.actions';
import * as ActivitySelectors from './activity.selectors';

export const createActivity$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), activityService = inject(ActivityService)) =>
    actions$.pipe(
      ofType(ActivityActions.createActivity),
      withLatestFrom(store.select(ActivitySelectors.getPendingImage)),
      switchMap(([action, pendingImage]) => {
        if (!action.activity) {
          return [
            ActivityActions.createActivityFailed({
              error: 'No activity to create',
            }),
          ];
        }

        return activityService.create(action.activity).pipe(
          switchMap((createdActivity: IActivityLocation) => {
            const createActions: Action[] = [
              ActivityActions.activityCreated({ activity: createdActivity }),
              ActivityActions.activityLoaded({ activity: createdActivity }),
              ActivityActions.activitySelected({ activity: createdActivity }),
            ];

            if (pendingImage && createdActivity.id) {
              createActions.push(
                ActivityActions.uploadActivityImage({
                  formData: pendingImage,
                  data: { activityId: createdActivity.id },
                }),
              );
            }

            return createActions;
          }),
          catchError((error) => [ActivityActions.createActivityFailed({ error })]),
        );
      }),
    ),
  { functional: true },
);

export const removeImageFromActivity$ = createEffect(
  (actions$ = inject(Actions), activityService = inject(ActivityService)) =>
    actions$.pipe(
      ofType(ActivityActions.removeImageFromActivity),
      switchMap((action) =>
        activityService.removeImage(action.activityId, action.index).pipe(
          mergeMap((photos: IPhoto[]) => [
            ActivityActions.activityImagesUpdated({
              activityId: action.activityId,
              photos,
            }),
            AlertActions.displayAlert({
              title: 'Image Removed from Activity',
              body: 'Removed Image',
              level: 'error',
            }),
          ]),
          catchError((error) => [
            ActivityActions.createActivityFailed({
              error,
            }),
          ]),
        ),
      ),
    ),
  { functional: true },
);

export const removeActivity$ = createEffect(
  (actions$ = inject(Actions), activityService = inject(ActivityService)) =>
    actions$.pipe(
      ofType(ActivityActions.removeActivity),
      switchMap((action) =>
        activityService.remove(action.activityId).pipe(
          mergeMap(() => {
            const actionArray: ({ type: string; payload: any } | Action)[] = [
              ActivityActions.activityRemoved({
                activityId: action.activityId,
              }),
            ];

            if (action.activityId) {
              actionArray.push(
                AlertActions.displayAlert({
                  title: 'Activity removed',
                  body: 'Removed Activity',
                  level: 'error',
                }),
              );
            }

            return actionArray;
          }),
          catchError((error) => [ActivityActions.createActivityFailed({ error })]),
        ),
      ),
    ),
  { functional: true },
);

export const loadActivity$ = createEffect(
  (actions$ = inject(Actions), activityService = inject(ActivityService)) =>
    actions$.pipe(
      ofType(ActivityActions.loadActivity),
      switchMap((action) =>
        activityService.fetch(action.activityId).pipe(
          switchMap((activity: IActivityLocation) => [
            ActivityActions.activityLoaded({
              activity,
            }),
            ActivityActions.selectActivity({
              activityId: activity.id,
            }),
          ]),
          catchError((error) => [ActivityActions.loadActivityFailed({ error })]),
        ),
      ),
    ),
  { functional: true },
);

export const loadActivities$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), activityService = inject(ActivityService)) =>
    actions$.pipe(
      ofType(ActivityActions.loadActivities),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      switchMap(([action, user]) => {
        if (action.siteId) {
          return activityService.fetchForSite(action.siteId).pipe(
            switchMap((activities: IActivityLocation[]) => [ActivityActions.activitiesLoaded({ activities })]),
            catchError((error) => [
              ActivityActions.loadActivityFailed({
                error,
              }),
            ]),
          );
        } else if (action.lat && action.lon) {
          return activityService.fetchByCoordinates(action.lat, action.lon).pipe(
            switchMap((activities: IActivityLocation[]) => [
              ActivityActions.activitiesLoaded({
                activities,
              }),
            ]),
            catchError((error) => [
              ActivityActions.loadActivityFailed({
                error,
              }),
            ]),
          );
        } else {
          if (!user.id) {
            return [
              ActivityActions.loadActivityFailed({
                error: 'Missing User ID',
              }),
            ];
          }

          return activityService.fetchForUser(user.id).pipe(
            switchMap((activities: IActivityLocation[]) => [ActivityActions.activitiesLoaded({ activities })]),
            catchError((error) => [
              ActivityActions.loadActivityFailed({
                error,
              }),
            ]),
          );
        }
      }),
    ),
  { functional: true },
);

export const loadForSearch$ = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    activityService = inject(ActivityService),
    logger = inject(Logger),
  ) =>
    actions$.pipe(
      ofType(ActivityActions.loadForSearch),
      throttleTime(1000),
      tap((payload) => logger.debug('Loading POIs for Search', JSON.stringify(payload))),
      switchMap((action) => {
        return store.pipe(select(locationQuery.getSelectedLocation)).pipe(
          take(1),
          switchMap((location) => {
            const query: IActivityLocationSearchRequest = { ...action.request };
            if (location?.center && location.distance) {
              query.lat = location.center.lat;
              query.lon = location.center.lon;
              query.distance = location.distance;
            }
            return activityService.fetchForSearch(query).pipe(
              map((activities: ActivityLocation[]) =>
                ActivityActions.loadedForSearch({
                  activities,
                }),
              ),
              catchError(() => [
                ActivityActions.loadedForSearch({
                  activities: [],
                }),
              ]),
            );
          }),
        );
      }),
    ),
  { functional: true },
);

// using mergeMap to allow multiple updates at a time

export const updateActivity$ = createEffect(
  (actions$ = inject(Actions), activityService = inject(ActivityService)) =>
    actions$.pipe(
      ofType(ActivityActions.updateActivity),
      mergeMap((action) =>
        activityService.update(action.activity).pipe(
          mergeMap((updatedActivity: IActivityLocation) => [
            AlertActions.displayAlert({
              title: 'Activity Updated',
              body: `Updated ${action.activity.name}`,
            }),
            ActivityActions.activityLoaded({
              activity: updatedActivity,
            }),
          ]),
          catchError((error) => [ActivityActions.updateActivityFailed({ error })]),
        ),
      ),
    ),
  { functional: true },
);

export const uploadActivityImage$ = createEffect(
  (
    actions$ = inject(Actions),
    activityService = inject(ActivityService),
    spinnerService = inject(GlobalSpinnerService),
  ) =>
    actions$.pipe(
      ofType(ActivityActions.uploadActivityImage),
      switchMap((payload) => {
        if (!payload || !payload.formData) {
          return of(NGRX_NO_ACTION);
        }

        const files = payload.formData;
        if (!payload.data || !payload.data.activityId) {
          return [
            ActivityActions.pendingUploadActivityImage({
              ...files,
            }),
          ];
        }

        const activityId = payload.data.activityId;

        spinnerService.spinnerOn();

        return activityService.uploadImage(activityId, files).pipe(
          switchMap((results: { activityId: number; photos: IPhoto[] }) => {
            spinnerService.spinnerOff();

            return [ActivityActions.activityImagesUpdated(results)];
          }),
          catchError((error) => {
            spinnerService.spinnerOff();
            return [
              ActivityActions.createActivityFailed({
                error,
              }),
            ];
          }),
        );
      }),
      catchError((error) => {
        spinnerService.spinnerOff();
        return [
          ActivityActions.createActivityFailed({
            error,
          }),
        ];
      }),
    ),
  { functional: true },
);

export const createActivityFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ActivityActions.createActivityFailed),
      map((action) =>
        AlertActions.displayAlert({
          title: 'Error Creating Activity',
          body: stringFromError(action.error),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const loadActivityFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ActivityActions.loadActivityFailed),
      map((action) =>
        AlertActions.displayAlert({
          title: 'Error Loading Activities',
          body: stringFromError(action.error),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const updateActivityFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ActivityActions.updateActivityFailed),
      map((action) =>
        AlertActions.displayAlert({
          title: 'Error Updating Activity',
          body: stringFromError(action.error),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const hideSite$ = createEffect(
  (actions$ = inject(Actions), activityService = inject(ActivityService)) =>
    actions$.pipe(
      ofType(ActivityActions.hideActivity),
      switchMap((action) => {
        if (!action.activity) {
          return [
            ActivityActions.updateActivityFailed({
              error: 'Unable to hide site',
            }),
          ];
        }

        return activityService.hide(action.activity).pipe(
          mergeMap((successResult: { success: boolean; message: string }) => {
            if (!successResult.success) {
              return [
                ActivityActions.updateActivityFailed({
                  error: successResult.message,
                }),
              ];
            }

            return [
              ActivityActions.activityUpdated({
                activity: { ...action.activity, hidden: true },
              }),
            ];
          }),
          catchError((error) => [ActivityActions.updateActivityFailed({ error })]),
        );
      }),
    ),
  { functional: true },
);

export const showSite$ = createEffect(
  (actions$ = inject(Actions), activityService = inject(ActivityService)) =>
    actions$.pipe(
      ofType(ActivityActions.showActivity),
      switchMap((action) => {
        if (!action.activity) {
          return [
            ActivityActions.updateActivityFailed({
              error: 'Unable to show site',
            }),
          ];
        }

        return activityService.show(action.activity).pipe(
          mergeMap((successResult: { success: boolean; message: string }) => {
            if (!successResult.success) {
              return [
                ActivityActions.updateActivityFailed({
                  error: successResult.message,
                }),
              ];
            }

            return [
              ActivityActions.activityUpdated({
                activity: { ...action.activity, hidden: false },
              }),
            ];
          }),
          catchError((error) => [ActivityActions.updateActivityFailed({ error })]),
        );
      }),
    ),
  { functional: true },
);

export const favoriteActivity$ = createEffect(
  (actions$ = inject(Actions), activityService = inject(ActivityService)) =>
    actions$.pipe(
      ofType(ActivityActions.favoriteActivity),
      switchMap((action) => {
        if (!action.activity) {
          return [
            ActivityActions.activityFavorFailed({
              error: 'No activity to favorite',
            }),
          ];
        }

        return activityService.favorite(action.activity).pipe(
          mergeMap((successResult: { success: boolean; message: string }) => {
            if (!successResult.success) {
              return [
                ActivityActions.activityFavorFailed({
                  error: successResult.message,
                }),
              ];
            }

            if (action.activity.id) {
              return [
                ActivityActions.activityFavorCompleted({
                  id: action.activity.id,
                  favored: true,
                }),
              ];
            } else {
              return [
                ActivityActions.activityFavorFailed({
                  error: 'Activity ID not found.',
                }),
              ];
            }
          }),
          catchError((error) => [ActivityActions.activityFavorFailed({ error })]),
        );
      }),
    ),
  { functional: true },
);

export const unfavoriteActivity$ = createEffect(
  (actions$ = inject(Actions), activityService = inject(ActivityService)) =>
    actions$.pipe(
      ofType(ActivityActions.unfavoriteActivity),
      switchMap((action) => {
        if (!action.activity) {
          return [
            ActivityActions.activityFavorFailed({
              error: 'No activity to unfavorite',
            }),
          ];
        }

        return activityService.unfavorite(action.activity).pipe(
          mergeMap((successResult: { success: boolean; message: string }) => {
            if (!successResult.success) {
              return [
                ActivityActions.activityFavorFailed({
                  error: successResult.message,
                }),
              ];
            }

            if (action.activity.id) {
              return [
                ActivityActions.activityFavorCompleted({
                  id: action.activity.id,
                  favored: false,
                }),
              ];
            } else {
              return [
                ActivityActions.activityFavorFailed({
                  error: 'Activity ID not found.',
                }),
              ];
            }
          }),
          catchError((error) => [ActivityActions.activityFavorFailed({ error })]),
        );
      }),
    ),
  { functional: true },
);
