import { Injectable } from '@angular/core';
import { ListingSearchQuery, MapPoint, MapPointTypes, SEARCH_API_URL } from '@curbnturf/entities';
import { CachedHttpClient, ListingDbSet } from '@curbnturf/network';
import { PlatformService } from '@curbnturf/network/src/lib/platform/platform.service';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ListingSearchService {
  constructor(
    private http: CachedHttpClient,
    private listingDbSet: ListingDbSet,
    private platformService: PlatformService,
  ) {}

  find(query: ListingSearchQuery): Observable<MapPoint[]> {
    const queryObject = new ListingSearchQuery(query);

    return this.http.get<MapPoint[]>(`${SEARCH_API_URL}?${queryObject.toString()}`, undefined, {
      offlineCallback: () => this.retrieveCached(query),
      syncAction: { type: '' }, // @todo create sync action
    });
  }

  private retrieveCached(query: ListingSearchQuery): Observable<MapPoint[]> {
    if (this.platformService.isPhysicalDevice()) {
      return from(this.listingDbSet.retrieveFiltered(query)).pipe(
        map((sites) => {
          return sites.map(
            (site) =>
              new MapPoint({
                ...site,
                type: site.type === MapPointTypes.standard ? MapPointTypes.standard : MapPointTypes.boondock,
              }),
          );
        }),
      );
    }
    return of([]);
  }
}
