export enum RatingType {
  overall = 'overall',
  quality = 'quality',
  accuracy = 'accuracy',
  checkin = 'checkin',
  communication = 'communication',
  location = 'location',
  value = 'value',
  cleanliness = 'cleanliness',
  rules = 'rules',
}

export const RatingTypeArray = [
  'overall',
  'quality',
  'accuracy',
  'checkin',
  'communication',
  'location',
  'value',
  'cleanliness',
  'rules',
];
