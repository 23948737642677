import { DateTime } from 'luxon';
import { IActivityLocation, ILatLon, IMapPoint, IRoutePoint, ISite } from '../interfaces';
import { ActivityLocation } from './activity-location';
import { LatLon } from './coordinates';
import { MapPoint } from './map-point';
import { Site } from './site';

export class RoutePoint implements IRoutePoint {
  listing?: ISite;
  poi?: IActivityLocation;
  isActive?: boolean;
  isPoi?: boolean;
  isListing?: boolean;
  point?: IMapPoint;
  name?: string;
  coordinates: ILatLon;
  origin?: boolean;
  destination?: boolean;
  arrival?: DateTime;
  departure?: DateTime;

  constructor(data?: IRoutePoint) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IRoutePoint): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (data.listing) {
      this.listing = new Site(data.listing);
    }

    if (data.poi) {
      this.poi = new ActivityLocation(data.poi);
    }

    if (data.point) {
      this.point = new MapPoint(data.point);
    }

    if (!data.origin) {
      this.origin = false;
    }

    if (!data.destination) {
      this.destination = false;
    }

    if (data.coordinates) {
      this.coordinates = new LatLon({ ...this.coordinates });
    }
  }
}
