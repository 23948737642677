import { ErrorHandler, NgModule } from '@angular/core';
import { NGRX_BUCKETS, PRODUCTION } from '@curbnturf/entities';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as alertEffects from './+state/alert.effects';
import { alertReducer, initialState as alertInitialState } from './+state/alert.reducer';
import { GlobalErrorHandler } from './global-errors.service';

@NgModule({
  providers: [
    // AlertFacade,
    PRODUCTION ? { provide: ErrorHandler, useClass: GlobalErrorHandler } : [],
    provideState(NGRX_BUCKETS.alert, alertReducer, {
      initialState: alertInitialState,
    }),
    provideEffects(alertEffects),
  ],
})
export class AlertModule {}
