import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_API_URL, IUser } from '@curbnturf/entities';
import { Observable } from 'rxjs';

const API_URL = BASE_API_URL + 'contact';

@Injectable({
  providedIn: 'root',
})
export class AddressBookService {
  constructor(private http: HttpClient) {}

  fetch(): Observable<IUser[]> {
    return this.http.get<IUser[]>(`${API_URL}`);
  }
}
