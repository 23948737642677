import { Injectable } from '@angular/core';
import { BASE_API_URL, IMessage, IMessageChannel, IMessageQuery } from '@curbnturf/entities';
import { CachedHttpClient } from '@curbnturf/network';
import { Observable } from 'rxjs';

const API_URL = BASE_API_URL + 'message';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private http: CachedHttpClient) {}

  create(message: IMessage): Observable<IMessageChannel> {
    return this.http.post<IMessageChannel>(API_URL, this.formatForServer(message));
  }

  fetch(propertyId: number): Observable<IMessageChannel> {
    if (!propertyId) {
      throw new Error('Unable to fetch property messages. No propertyId provided');
    }

    return this.http.get<IMessageChannel>(`${API_URL}/${propertyId}`);
  }

  query(query: IMessageQuery): Observable<IMessageChannel[]> {
    const queryString = this.queryToString(query);
    if (!queryString) {
      throw new Error('Unable to query for messages. No query parameters provided');
    }

    return this.http.get<IMessageChannel[]>(`${API_URL}?${queryString}`);
  }

  read(channel: IMessageChannel): Observable<IMessageChannel> {
    if (!channel || !channel.id) {
      throw new Error('Unable to mark message as read. Invalid message channel provided');
    }

    return this.http.post<IMessageChannel>(`${API_URL}/${channel.id}/read`, null);
  }

  private formatForServer(message: IMessage): IMessage {
    const newMessage = { ...message };

    return newMessage;
  }

  private queryToString(query: IMessageQuery): string {
    const queryArr = [];

    for (const prop in query) {
      if (query.hasOwnProperty(prop)) {
        queryArr.push(`${prop}=${query[prop]}`);
      }
    }

    return queryArr.join('&');
  }
}
