import { inject } from '@angular/core';
import * as AlertActions from './alert.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertService } from '../alert.service';

export const displayAlert$ = createEffect(
  (actions$ = inject(Actions), alertService = inject(AlertService)) =>
    actions$.pipe(
      ofType(AlertActions.displayAlert),
      tap((action: AlertActions.IAlertActionDisplayAlertProperties) => {
        return from(
          alertService.alert(action.level ?? 'success', action.body, action.title, action.html, action.noTimeout),
        );
      }),
    ),
  { dispatch: false, functional: true },
);

export const closeAllAlerts$ = createEffect(
  (actions$ = inject(Actions), alertService = inject(AlertService)) =>
    actions$.pipe(
      ofType(AlertActions.closeAllAlerts),
      tap(() => {
        alertService.closeAll();
      }),
    ),
  { dispatch: false, functional: true },
);
