import { Injectable } from '@angular/core';
import { BASE_API_URL, ILog, ILogSession, LogSession } from '@curbnturf/entities';
import { StatusFacade } from '@curbnturf/status';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { CachedHttpClient } from '../network/cached-http-client.service';
import * as LogActions from './+state/log.actions';
import { LogFacade } from './+state/log.facade';

const API_URL = BASE_API_URL + 'log';

@Injectable({
  providedIn: 'root',
})
export class LogHttpService {
  buffer: ILog[] = [];
  isReady: boolean = false;
  logSession: ILogSession;
  loggerDebug: boolean = false;

  static generateSessionGuid() {
    return uuidv4();
  }

  constructor(private http: CachedHttpClient, private statusFacade: StatusFacade, private logFacade: LogFacade) {
    // We can start logging immediately if we are logged in.
    this.statusFacade.loggedIn$.subscribe((loggedIn) => {
      if (loggedIn) {
        if (this.loggerDebug) {
          console.log('Logger Ready -- User Authenticated', loggedIn);
        }
        this.isReady = true;
      } else {
        this.isReady = false;
      }
    });

    this.logFacade.start();
    this.logFacade.session$.subscribe(
      (response: ILogSession) => {
        if (response?.sessionId) {
          if (this.loggerDebug) {
            console.log('Logger Ready - Log Session Started', response.sessionId);
          }
          this.logSession = new LogSession({ sessionId: response.sessionId });
          this.isReady = true;
          this.processBuffer();
        }
      },
      (e) => {
        if (this.loggerDebug) {
          console.error('Error Saving Log Information', e);
        }
      },
    );

    this.startSession();
  }

  startSession(): Observable<ILogSession> {
    if (this.loggerDebug) {
      console.log('Log Session Start');
    }

    // Get a log session to attach logs to.
    return this.http
      .post<ILogSession>(
        `${API_URL}/session/start`,
        {
          logSession: LogHttpService.generateSessionGuid(),
        },
        {},
        { syncAction: { type: LogActions.logSessionStarted.type } },
      )
      .pipe(
        map((result) => {
          this.logSession = result;
          return result;
        }),
        catchError((err, caught) => {
          console.error('startSession', JSON.stringify(err), JSON.stringify(caught));
          throw err;
        }),
      );
  }

  log(log: ILog) {
    if (this.isReady) {
      const storableLog = {
        ...log,
        created: log.created ? DateTime.fromMillis(log.created).toISO() : undefined,
      };
      if (this.logSession) {
        storableLog.logSession = this.logSession.sessionId;
      }

      this.http.post<ILog>(`${API_URL}`, storableLog).subscribe(
        () => {
          if (this.loggerDebug) {
            console.log('Log Saved');
          }
        },
        (e) => {
          console.error('Logger Error', JSON.stringify(e));
          if (e.status === 403) {
            console.error('Starting Log Session', e);
            this.startSession().subscribe();
          }

          if (this.loggerDebug) {
            console.log('Error Saving Log', e);
          }
        },
      );
    }
    this.buffer.push(log);
  }

  private processBuffer() {
    this.buffer.forEach((log) => {
      const newLog = { ...log };
      if (this.logSession) {
        newLog.logSession = this.logSession.sessionId;
      }
      this.log(newLog);
    });

    this.buffer = [];
  }
}
