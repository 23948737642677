import { Injectable } from '@angular/core';
import { capSQLiteChanges, capSQLiteValues } from '@capacitor-community/sqlite';
import { CDate, ISchedule, Schedule, Site } from '@curbnturf/entities';
import { DatabaseService } from '../database.service';
import { IDbSet } from '../dbset.interface';
import { ScheduleEntity } from '../entities/schedule';

const TABLE_NAME = 'schedules';

@Injectable({
  providedIn: 'root',
})
export class ScheduleDbSet implements IDbSet {
  public static readonly scheduleFields = [
    'id',
    'site_id',
    'site_name',
    'confirmation_code',
    'date_type',
    'date_from',
    'date_to',
    'email',
    'deleted',
    'completed',
    'rated',
    'donated',
  ];

  constructor(private databaseService: DatabaseService) {}

  public store(request: ISchedule): Promise<capSQLiteValues> {
    const entity = this.mapObjectsToStorable([request]).pop();
    if (entity) {
      return this.databaseService.insert<ScheduleEntity>(TABLE_NAME, entity);
    } else {
      throw new Error('Unable to convert to storable object.');
    }
  }

  public storeArray(requests: ISchedule[]): Promise<capSQLiteValues> {
    return this.databaseService.insertMulti<ScheduleEntity>(
      TABLE_NAME,
      this.mapObjectsToStorable(requests),
      ScheduleDbSet.scheduleFields.slice(1),
      true,
    );
  }

  public async truncateAndStoreArray(records: ISchedule[]): Promise<capSQLiteValues> {
    const entities = this.mapObjectsToStorable(records);
    await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
    const { statement, values } = this.databaseService.generateInsertStatement(
      TABLE_NAME,
      entities,
      ScheduleDbSet.scheduleFields.slice(1),
      true,
    );
    return this.databaseService.query(statement, values);
  }

  public async truncate(): Promise<capSQLiteChanges> {
    return await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
  }

  public async retrieve(id: number): Promise<ISchedule> {
    const results = await this.databaseService.select(TABLE_NAME, { id });
    const objResults = this.mapResultsObject(results);
    if (objResults && objResults?.length > 0) {
      return objResults[0];
    } else {
      return new Schedule({});
    }
  }

  public async retrieveByConfirmationCode(confirmationCode: string): Promise<ISchedule> {
    const results = await this.databaseService.select(TABLE_NAME, { confirmationCode });
    const objResults = this.mapResultsObject(results);
    if (objResults && objResults?.length > 0) {
      return objResults[0];
    } else {
      return new Schedule({});
    }
  }

  public async retrieveAll(limit?: number): Promise<ISchedule[]> {
    const results = await this.databaseService.selectAll(TABLE_NAME, undefined, limit);
    return this.mapResultsObject(results);
  }

  public async remove(id: string): Promise<capSQLiteValues> {
    return await this.databaseService.delete(TABLE_NAME, { id });
  }

  private mapResultsObject(results: capSQLiteValues): ISchedule[] {
    if (results.values) {
      return results.values?.map((record) => {
        return new Schedule({
          id: record.id,
          confirmationCode: record.confirmation_code,
          date: new CDate({
            type: record.date_type as 'range' | 'single' | undefined,
            from: record.date_from || '',
            to: record.date_to,
          }),
          email: record.email,
          siteId: record.site_id,
          deleted: !!record.deleted,
          completed: !!record.completed,
          rated: !!record.rated,
          donated: !!record.donated,
          site: new Site({ id: record.site_id, name: record.site_name }),
        });
      });
    } else {
      return [];
    }
  }

  private mapObjectsToStorable(items: ISchedule[]): ScheduleEntity[] {
    if (items) {
      return items?.map((record: ISchedule) => {
        return {
          // Can't use spread operator here as it tries to enter null in undefined properties as columns
          id: record.id ?? null,
          site_id: record.siteId || 0,
          site_name: record.site?.name,
          confirmation_code: record.confirmationCode,
          date_type: record.date?.type,
          date_from: record.date?.from,
          date_to: record.date?.to,
          email: record.email,
          deleted: record.deleted ? 1 : 0,
          completed: record.completed ? 1 : 0,
          rated: record.rated ? 1 : 0,
          donated: record.donated ? 1 : 0,
        };
      });
    } else {
      return [];
    }
  }
}
