import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { AuthFacade } from '@curbnturf/auth';
import { AuthModalFacade } from '@curbnturf/auth-modal/src/lib/+state/auth-modal.facade';
import { PATHS } from '@curbnturf/entities';
import {
  DisplayValidationErrorsDirective,
  DisplayValidationErrorsSubmitButtonDirective,
  DisplayValidationErrorsSubmitDirective,
} from '@curbnturf/form-helpers';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'curbnturf-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss'],
  standalone: true,
  imports: [
    DisplayValidationErrorsSubmitDirective,
    DisplayValidationErrorsDirective,
    DisplayValidationErrorsSubmitButtonDirective,
    FormsModule,
    IonicModule,
    NgIf,
    ReactiveFormsModule,
    RouterLink,
  ],
})
export class RecoverComponent {
  @Input() inModal: boolean;

  loginPath = PATHS.login;

  emailForm: FormGroup<{ email: FormControl<string | null> }>;

  constructor(
    private fb: FormBuilder,
    private authFacade: AuthFacade,
    private authModalFacade: AuthModalFacade,
  ) {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  public recover() {
    if (this.emailForm.valid) {
      const email = this.emailForm.get('email');
      if (email) {
        this.authFacade.recover(email.value || '');
      }
    }
  }

  openLoginModal() {
    this.authModalFacade.openAuthModal('login');
  }
}
