import { NgModule } from '@angular/core';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { NetworkModule } from '@curbnturf/network';
import { IonicStorageModule } from '@ionic/storage-angular';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as storageEffects from './+state/storage.effects';
import { initialState as storageInitialState, storageReducer } from './+state/storage.reducer';
import { StorageService } from './storage.service';

@NgModule({
  imports: [IonicStorageModule.forRoot(), NetworkModule],
  providers: [
    StorageService,
    provideState(NGRX_BUCKETS.storage, storageReducer, {
      initialState: storageInitialState,
    }),
    provideEffects(storageEffects),
  ],
})
export class StorageModule {}
