import { Injectable } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { HelperFunctions, NETWORK_STATUS_DEBOUNCE } from '@curbnturf/entities';
import { NetworkFacade } from './+state/network.facade';
import debounce = HelperFunctions.debounce;

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private readonly networkListener;
  private observers: ((status: ConnectionStatus) => void)[] = [];
  private readonly updateNetworkStatus: (status: ConnectionStatus) => Promise<void>;
  private status?: ConnectionStatus;

  constructor(private networkFacade: NetworkFacade) {
    this.updateNetworkStatus = debounce((status: ConnectionStatus) => {
      this.status = status;
      this.networkFacade.setNetworkStatus(status);
      this.notifyObservers(status);
    }, NETWORK_STATUS_DEBOUNCE);

    if (!this.networkListener) {
      this.networkListener = Network.addListener('networkStatusChange', async (status) => {
        // If no status objects changed then we don't need to send the update down the pipe
        if (status.connected !== this.status?.connected || status.connectionType !== this.status?.connectionType) {
          await this.updateNetworkStatus(status);
        }
      });
    }

    this.getCurrentNetworkStatus().then();
  }

  public async getCurrentNetworkStatus(): Promise<ConnectionStatus> {
    const status = await Network.getStatus();
    // If no status objects changed then we don't need to send the update down the pipe
    if (status.connected !== this.status?.connected || status.connectionType !== this.status?.connectionType) {
      await this.updateNetworkStatus(status);
    }
    return status;
    return { connected: true, connectionType: 'none' };
  }

  public observeNetworkStatus(callback: (status: ConnectionStatus) => void) {
    this.observers.push(callback);
  }

  private notifyObservers(status: ConnectionStatus) {
    this.observers.forEach((func) => func(status));
  }
}
