import { Injectable } from '@angular/core';
import { IActivityType, IAmenityType, ILatLon, IMapPoint, IRV, ListingSearchQuery, MapLayerTypes, MapPoint, Site } from '@curbnturf/entities';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { IFilters } from '../filters.interface';
import * as ListingSearchActions from './listing-search.actions';
import * as ListingSearchSelectors from './listing-search.selectors';

@Injectable({
  providedIn: 'root',
})
export class ListingSearchFacade {
  allListingSearch$: Observable<MapPoint[]>;
  allListingTypeahead$: Observable<MapPoint[]>;
  allUserPoints$: Observable<MapPoint[]>;
  filters$: Observable<{
    myRv?: IRV;
    types?: ('standard' | 'boondock')[];
    minPrice?: number;
    maxPrice?: number;
    tents?: number;
    additionalVehicles?: number;
    amenities?: IAmenityType['type'][];
    activities?: IActivityType['type'][];
    activityDistance?: number;
    searchString?: string;
    lat?: number;
    lon?: number;
    distance?: number;
    startDate: DateTime | undefined;
    endDate: DateTime | undefined;
  }>;
  selectedSite$: Observable<Site | undefined>;
  selectedPoint$: Observable<MapPoint | undefined>;
  selectedSiteId$: Observable<number | undefined>;
  loaded$: Observable<boolean>;
  searchMapCenter$: Observable<ILatLon | undefined>;
  searchMapZoom$: Observable<number | undefined>;
  searchMapHeading$: Observable<number | undefined>;
  searchListOpen$: Observable<boolean | undefined>;
  mapType$: Observable<MapLayerTypes>;

  constructor(private store: Store) {
    this.allListingSearch$ = this.store.pipe(select(ListingSearchSelectors.getAllListingSearch));
    this.allListingTypeahead$ = this.store.pipe(select(ListingSearchSelectors.getAllListingTypeahead));
    this.allUserPoints$ = this.store.pipe(select(ListingSearchSelectors.getUserPoints));
    this.filters$ = this.store.pipe(select(ListingSearchSelectors.getFilters));
    this.selectedSite$ = this.store.pipe(select(ListingSearchSelectors.getSelectedSite));
    this.selectedPoint$ = this.store.pipe(select(ListingSearchSelectors.getSelectedPoint));
    this.selectedSiteId$ = this.store.pipe(select(ListingSearchSelectors.getSelectedResultId));
    this.loaded$ = this.store.pipe(select(ListingSearchSelectors.getLoaded));
    this.searchMapCenter$ = this.store.pipe(select(ListingSearchSelectors.getSearchMapCenter));
    this.searchMapZoom$ = this.store.pipe(select(ListingSearchSelectors.getSearchMapZoom));
    this.searchMapHeading$ = this.store.pipe(select(ListingSearchSelectors.getSearchMapHeading));
    this.searchListOpen$ = this.store.pipe(select(ListingSearchSelectors.getSearchListOpen));
    this.mapType$ = this.store.pipe(select(ListingSearchSelectors.getMapType));
  }

  run(query: ListingSearchQuery) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (window && (window as any).gtag) {
      const event = {
        event_category: 'search',
        event_label: ListingSearchQuery.toQueryString({
          ...query,
          types: undefined,
        }),
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).gtag('event', 'run', event);
    }

    this.store.dispatch(ListingSearchActions.runListingSearch({ query }));
  }

  runTypeahead(query: ListingSearchQuery) {
    this.store.dispatch(ListingSearchActions.runListingTypeahead({ query }));
  }

  selectPoint(point?: IMapPoint) {
    this.store.dispatch(ListingSearchActions.selectPoint({ point }));
  }

  selectPointById(pointId: number) {
    this.store.dispatch(ListingSearchActions.selectPointById({ pointId }));
  }

  selectSiteById(siteId: number) {
    this.store.dispatch(ListingSearchActions.selectSiteById({ siteId }));
  }

  loadPoint(point: IMapPoint) {
    this.store.dispatch(ListingSearchActions.pointDisplayLoaded({ point }));
  }

  retrieveUserPointList() {
    this.store.dispatch(ListingSearchActions.retrieveUserPointList());
  }

  addUserPoint(point: MapPoint) {
    this.store.dispatch(ListingSearchActions.addUserPoint({ point }));
  }

  updateUserPoint(point: MapPoint) {
    this.store.dispatch(ListingSearchActions.updateUserPoint({ point }));
  }

  removeUserPoint(point: IMapPoint) {
    this.store.dispatch(ListingSearchActions.removeUserPoint({ point }));
  }

  setFilters(filters: IFilters) {
    this.store.dispatch(ListingSearchActions.setFilters({ filters }));
  }

  setSearchMapCenter(coordinates: ILatLon) {
    this.store.dispatch(ListingSearchActions.setSearchMapCenter({ center: coordinates }));
  }

  setSearchMapZoom(zoom: number) {
    this.store.dispatch(ListingSearchActions.setSearchMapZoom({ zoom }));
  }

  setSearchMapHeading(heading: number) {
    this.store.dispatch(ListingSearchActions.setSearchMapHeading({ heading }));
  }

  setSearchListOpen(isOpen: boolean) {
    this.store.dispatch(ListingSearchActions.setSearchListOpen({ isOpen }));
  }
}
