import { Injectable } from '@angular/core';
import { capSQLiteChanges, capSQLiteValues } from '@capacitor-community/sqlite';
import { Logger } from '../../log/logger';
import { DatabaseService } from '../database.service';
import { IDbSet } from '../dbset.interface';
import { OfflineSitePhotoEntity } from '../entities/offline-site-photo';

const TABLE_NAME = 'offline_site_photos';

@Injectable({
  providedIn: 'root',
})
export class OfflineSitePhotosDbSet implements IDbSet {
  constructor(private databaseService: DatabaseService, private logger: Logger) {}

  public store(entity: OfflineSitePhotoEntity): Promise<capSQLiteValues> {
    return this.databaseService.insert(TABLE_NAME, this.mapObjectsToStorable([entity]).pop());
  }

  public storeArray(entity: OfflineSitePhotoEntity[]): Promise<capSQLiteValues> {
    return this.databaseService.insertMulti(TABLE_NAME, this.mapObjectsToStorable(entity));
  }

  public async truncateAndStoreArray(entities: OfflineSitePhotoEntity[]): Promise<capSQLiteValues> {
    const records = this.mapObjectsToStorable(entities);
    await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
    const { statement, values } = this.databaseService.generateInsertStatement(TABLE_NAME, records);
    this.logger.debug('Executing INSERT STATEMENT', statement);
    return this.databaseService.query(statement, values);
  }

  public async truncate(): Promise<capSQLiteChanges> {
    return await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
  }

  public async retrieve(id: number): Promise<{ values: OfflineSitePhotoEntity[] }> {
    const results = await this.databaseService.select(TABLE_NAME, { id });
    return {
      values: this.mapResultsObject(results),
    };
  }

  public async retrieveAll(limit?: number): Promise<OfflineSitePhotoEntity[]> {
    const results = await this.databaseService.selectAll(TABLE_NAME, undefined, limit);
    return this.mapResultsObject(results);
  }

  public async retrieveForListingId(listingId: number): Promise<OfflineSitePhotoEntity[]> {
    const results = await this.databaseService.select(TABLE_NAME, { listing_id: listingId });
    return this.mapResultsObject(results);
  }

  public async retrieveByKey(key: string): Promise<OfflineSitePhotoEntity[]> {
    const results = await this.databaseService.select(TABLE_NAME, { key });
    return this.mapResultsObject(results);
  }

  public async retrieveBatch(listingId: number[]): Promise<OfflineSitePhotoEntity[]> {
    if (listingId.length > 0) {
      let statement = `SELECT * FROM ${TABLE_NAME} WHERE listing_id IN(`;
      const values: string[] = [];
      listingId.forEach((el) => {
        statement += '?, ';
        values.push(el.toString());
      });
      statement = statement.substr(0, statement.length - 2) + `);`;
      const results = await this.databaseService.query(statement, values);
      return this.mapResultsObject(results);
    } else {
      return [];
    }
  }

  public async remove(id: number): Promise<capSQLiteValues> {
    return await this.databaseService.delete(TABLE_NAME, { id });
  }

  public async removeBySyncId(sync_id: string): Promise<capSQLiteValues> {
    return await this.databaseService.delete(TABLE_NAME, { sync_id });
  }

  public async removeByKey(key: string): Promise<capSQLiteValues> {
    return await this.databaseService.delete(TABLE_NAME, { key });
  }

  private mapResultsObject(results: capSQLiteValues): OfflineSitePhotoEntity[] {
    if (results.values) {
      return results.values?.map((record) => {
        return {
          id: record.id,
          siteId: record.site_id,
          caption: record.caption,
          key: record.key,
        };
      });
    } else {
      return [];
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private mapObjectsToStorable(items: OfflineSitePhotoEntity[]): any[] {
    if (items) {
      return items?.map((record: OfflineSitePhotoEntity) => {
        return {
          id: record.id,
          site_id: record.siteId,
          caption: record.caption,
          key: record.key,
        };
      });
    } else {
      return [];
    }
  }
}
