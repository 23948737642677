import { IMessageChannel } from '@curbnturf/entities';
import { Action, createReducer, on } from '@ngrx/store';
import * as MessageActions from './message.actions';

export interface MessageState {
  chatModalActive: boolean;
  pushToken?: string;
  list: { [id: number]: IMessageChannel }; // list of MessageChannel; analogous to a sql normalized table
  selectedId?: number; // which MessageChannel record has been selected
  loaded: boolean; // has the MessageChannel list been loaded
  error?: string; // last error (if any)
}

export const initialState: MessageState = {
  list: {},
  loaded: false,
  chatModalActive: false,
};

const messageReducer = createReducer(
  initialState,
  on(MessageActions.messageChannelLoaded, (state, action) => {
    if (!action.channel || !action.channel.id) {
      return state;
    }
    return {
      ...state,
      list: {
        ...state.list,
        [action.channel.id]: action.channel,
      },
      loaded: true,
    };
  }),
  on(MessageActions.selectMessageChannel, (state, action) => {
    if (!action.channel) {
      return {
        ...state,
        selectedId: undefined,
      };
    }
    return {
      ...state,
      selectedId: action.channel?.id,
    };
  }),
  on(MessageActions.selectMessageChannel, (state, action) => {
    if (!action.channel) {
      return {
        ...state,
        selectedId: undefined,
      };
    }
    return {
      ...state,
      selectedId: action.channel?.id,
    };
  }),
  on(MessageActions.messageChannelsLoaded, (state, action) => {
    if (!action.channels) {
      return state;
    }

    const messageChannels: { [id: number]: IMessageChannel } = {};
    action.channels.forEach((messageChannel) => {
      if (messageChannel.id) {
        messageChannels[messageChannel.id] = messageChannel;
      }
    });
    return {
      ...state,
      list: { ...state.list, ...messageChannels },
      loaded: true,
    };
  }),
  on(MessageActions.registerPushNotificationsSuccess, (state, action) => {
    return {
      ...state,
      pushToken: action.token,
    };
  }),
  on(MessageActions.showChatModal, (state, action) => {
    return {
      ...state,
      chatModalActive: action.showModal,
    };
  }),
);

export function reducer(state: MessageState | undefined, action: Action) {
  return messageReducer(state, action);
}
