import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { camelToTitle } from '@curbnturf/entities';
import { capitalize } from '@curbnturf/helpers';

function amenityErrors(key: string, keyError: string, name: string, formName: string): string {
  let errorMessage = '';
  switch (key) {
    case 'description': {
      errorMessage = `${name ? capitalize(name) : 'Wheelchair'} description is required`;
      break;
    }

    default: {
      console.log(`Error in unknown input: ${formName}`);
    }
  }

  return errorMessage;
}

// eslint-disable-next-line complexity
function siteErrors(key: string, keyError: string, name: string, formName: string): string {
  let errorMessage = '';
  switch (key) {
    case 'amenities': {
      if (keyError === 'invalidParking') {
        errorMessage = 'Please select either "Pull Through" or "Back In".';
      } else if (keyError === 'electricityInvalid') {
        errorMessage = 'Please select what amperage you offer or add a description.';
      } else if (keyError === 'waterInvalid') {
        errorMessage = 'Please select whether or not you have a water hookup or add a description.';
      } else if (keyError === 'wifiInvalid') {
        errorMessage = `Please enter your internet upload and download or add a
                        description to wifi. You could use
                        <a href="https://speedsmart.net/" target="_blank">speedsmart</a>.`;
      } else {
        errorMessage = 'Invalid amenity selection.';
      }
      break;
    }
    case 'name': {
      if (keyError === 'minLength' || keyError === 'minlength') {
        errorMessage = 'Site name should be at least 8 characters long.';
      } else if (keyError === 'maxLength' || keyError === 'maxlength') {
        errorMessage = 'Site name should not be any longer then 32 characters.';
      } else {
        errorMessage = 'Site name is required.';
      }
      break;
    }
    case 'description': {
      if (keyError === 'minLength' || keyError === 'minlength') {
        errorMessage = 'Site description should be at least 150 characters long.';
      } else if (keyError === 'maxLength' || keyError === 'maxlength') {
        errorMessage = 'Site description should not be any longer then 3000 characters.';
      } else {
        errorMessage = 'Site description is required.';
      }
      break;
    }
    case 'activities': {
      if (keyError === 'required') {
        errorMessage = 'You must select at least one activity.';
      }
      break;
    }
    case 'landTypes': {
      if (keyError === 'required') {
        errorMessage = 'You must select at least one property type.';
      }
      break;
    }
    case 'lat': {
      if (keyError === 'required') {
        errorMessage = 'You must enter a valid latitude.';
      }
      break;
    }
    case 'lon': {
      if (keyError === 'required') {
        errorMessage = 'You must enter a valid longitude.';
      }
      break;
    }
    case 'level': {
      if (keyError === 'required') {
        errorMessage = 'Please select level of noise.';
      }
      break;
    }
    case 'vehicles': {
      if (keyError === 'invalidVehicles') {
        errorMessage = 'Please select at least one RV type to allow at the listing.';
      }
      break;
    }
    case 'privacy': {
      if (keyError === 'invalidPrivacy') {
        errorMessage = 'Please select the listings seclusion.';
      }
      break;
    }
    case 'nextReservationDiscount': {
      errorMessage = 'Choose to either not accept or accept the first CurbNTurfer discount.';
      break;
    }
    case 'bookingWindow': {
      errorMessage = 'Select an option for booking window.';
      break;
    }
    case 'reserveDaysInAdvance': {
      errorMessage = 'Select an option for advance notice.';
      break;
    }
    case 'minLengthOfStayInDays': {
      errorMessage = 'Select a minimum number of nights a guest can stay.';
      break;
    }
    case 'maxLengthOfStayInDays': {
      errorMessage = 'Select a maximum number of nights a guest can stay.';
      break;
    }

    default: {
      console.log(`Error in unknown input: ${formName}.`);
    }
  }

  return errorMessage;
}

function onTheGoErrors(key: string, keyError: string, name: string, formName: string): string {
  let errorMessage = '';
  switch (key) {
    case 'email': {
      if (keyError === 'required') {
        errorMessage = 'Email is required.';
      }
      break;
    }
    case 'description': {
      if (keyError === 'minLength' || keyError === 'minlength') {
        errorMessage = 'User description should be at least 150 characters long.';
      } else if (keyError === 'maxLength' || keyError === 'maxlength') {
        errorMessage = 'User description should not be any longer then 3000 characters.';
      } else {
        errorMessage = 'User description is required.';
      }
      break;
    }
    case 'key': {
      errorMessage = 'Please upload an image before submitting.';
      break;
    }
    case 'firstName': {
      if (keyError === 'required') {
        errorMessage = 'First name is required.';
      }
      break;
    }
    case 'lastName': {
      if (keyError === 'required') {
        errorMessage = 'Last name is required.';
      }
      break;
    }
    case 'phone': {
      if (keyError === 'required') {
        errorMessage = 'Phone is required.';
      }
      break;
    }
    case 'address': {
      if (keyError === 'required') {
        errorMessage = 'Address is required.';
      }
      break;
    }
    case 'city': {
      if (keyError === 'required') {
        errorMessage = 'City is required.';
      }
      break;
    }
    case 'state': {
      if (keyError === 'required') {
        errorMessage = 'State is required.';
      }
      break;
    }
    case 'zipcode': {
      if (keyError === 'required') {
        errorMessage = 'Zipcode is required.';
      }
      break;
    }

    default: {
      console.log(`Error in unknown input: ${formName}.`);
    }
  }

  return errorMessage;
}

function userErrors(key: string, keyError: string, name: string, formName: string): string {
  let errorMessage = '';
  switch (key) {
    case 'key': {
      errorMessage = 'Please upload an image before proceeding.';
      break;
    }
    default: {
      console.log(`Error in unknown input: ${formName}.`);
    }
  }

  return errorMessage;
}

function photoErrors(key: string, keyError: string, name: string, formName: string): string {
  let errorMessage = '';
  switch (key) {
    case 'email': {
      errorMessage = 'Please upload an image before proceeding.';
      break;
    }
    default: {
      console.log(`Error in unknown input: ${formName}.`);
    }
  }

  return errorMessage;
}

function promotionErrors(key: string, keyError: string, name: string, formName: string): string {
  let errorMessage = '';
  switch (key) {
    case 'email': {
      if (keyError === 'required') {
        errorMessage = 'Email is required.';
      }
      break;
    }
    case 'firstName': {
      if (keyError === 'required') {
        errorMessage = 'First name is required.';
      }
      break;
    }
    case 'lastName': {
      if (keyError === 'required') {
        errorMessage = 'Last name is required.';
      }
      break;
    }
    case 'phone': {
      if (keyError === 'required') {
        errorMessage = 'Phone is required.';
      }
      break;
    }
    case 'address': {
      if (keyError === 'required') {
        errorMessage = 'Address is required.';
      }
      break;
    }
    case 'city': {
      if (keyError === 'required') {
        errorMessage = 'City is required.';
      }
      break;
    }
    case 'state': {
      if (keyError === 'required') {
        errorMessage = 'State is required.';
      }
      break;
    }
    case 'zipcode': {
      if (keyError === 'required') {
        errorMessage = 'Zipcode is required.';
      }
      break;
    }
    default: {
      console.log(`Error in unknown input: ${formName}.`);
    }
  }

  return errorMessage;
}

function landOwnerErrors(key: string, keyError: string, name: string, formName: string): string {
  let errorMessage = '';
  switch (key) {
    case 'self': {
      if (keyError === 'required') {
        errorMessage = 'Please select if you are the owner of the site or if it is owned by another.';
      }
      break;
    }
    case 'firstName': {
      if (keyError === 'required') {
        errorMessage = 'For private landowners first name is required.';
      }
      break;
    }
    case 'lastName': {
      if (keyError === 'required') {
        errorMessage =
          'For private landowners last name is required and public landowners require you to include a name.';
      }
      break;
    }
    case 'phone': {
      if (keyError === 'required') {
        errorMessage = 'For private landowners phone is required.';
      }
      break;
    }
    case 'address': {
      if (keyError === 'required') {
        errorMessage = 'For private landowners address is required.';
      }
      break;
    }
    case 'city': {
      if (keyError === 'required') {
        errorMessage = 'For private landowners city is required.';
      }
      break;
    }
    case 'state': {
      if (keyError === 'required') {
        errorMessage = 'For private landowners state is required.';
      }
      break;
    }
    case 'zipcode': {
      if (keyError === 'required') {
        errorMessage = 'For private landowners zipcode is required.';
      }
      break;
    }
    case 'level': {
      if (keyError === 'required') {
        errorMessage = 'Please select a noise level.';
      }
      break;
    }
    case 'represent': {
      if (keyError === 'required') {
        errorMessage = 'You most either own the property or represent the owner.';
      }
      break;
    }

    default: {
      console.log(`Error in unknown input: ${formName}.`);
    }
  }

  return errorMessage;
}

const getRecursiveFormValidationErrors = (form: UntypedFormGroup): string[] => {
  const errors: string[] = [];
  let formName = 'Site';

  if (form.controls.startingPoint) {
    formName = 'Access Route';
  } else if (form.controls.photos && form.controls.typeCustom) {
    formName = 'Activity';
  } else if (form.controls.options && form.controls.type) {
    formName = 'Amenity';
  } else if (form.controls.source && !form.controls.inProgressId) {
    formName = 'OnTheGo';
  } else if (form.controls.price) {
    formName = 'Site';
  } else if (form.controls.inProgressId) {
    formName = 'User';
  } else if (form.controls.self) {
    formName = 'Landowner';
  } else if (form.controls.key && form.controls.caption) {
    formName = 'Photo';
  } else if (form.controls.service) {
    formName = 'Promotion';
  }

  // most forms have a name or a type
  let name = form.value.name;
  if (!name && name !== '' && form.value.type) {
    name = form.value.type;
  }

  Object.keys(form.controls).forEach((key) => {
    const formElement = form.get(key) as UntypedFormGroup;
    const controlErrors: ValidationErrors | null = formElement.errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach((keyError) => {
        let errorMessage = `Invalid ${camelToTitle(key)}`;
        switch (formName) {
          case 'Amenity': {
            errorMessage = amenityErrors(key, keyError, name, formName) || errorMessage;
            break;
          }
          case 'Site': {
            errorMessage = siteErrors(key, keyError, name, formName) || errorMessage;
            break;
          }
          case 'User': {
            errorMessage = userErrors(key, keyError, name, formName) || errorMessage;
            break;
          }
          case 'OnTheGo': {
            errorMessage = onTheGoErrors(key, keyError, name, formName) || errorMessage;
            break;
          }
          case 'Landowner': {
            errorMessage = landOwnerErrors(key, keyError, name, formName) || errorMessage;
            break;
          }
          case 'Photo': {
            errorMessage = photoErrors(key, keyError, name, formName) || errorMessage;
            break;
          }
          case 'Promotion': {
            errorMessage = promotionErrors(key, keyError, name, formName) || errorMessage;
            break;
          }
          default: {
            console.log(`Error in unknown form: ${formName}.`);
          }
        }
        errors.push(errorMessage);
      });
    } else if (formElement.controls) {
      errors.push(...getRecursiveFormValidationErrors(formElement));
    }
  });

  return errors;
};

export const getFormValidationErrors = (form: UntypedFormGroup) => {
  return getRecursiveFormValidationErrors(form);
};
