import { ICoordinateGis, ICoordinates, IGeoPoint, ILatLng, ILatLon, LatLon } from '@curbnturf/objects';
import { Point, Position } from 'geojson';

export const latLng2Coord = (latLng: ILatLng): ILatLon => {
  return { lat: latLng.lat, lon: latLng.lng };
};

export const getCenterCoordinate = (points: IGeoPoint[]): ILatLon => {
  const lat =
    points.reduce(
      (total: number, point) => total + ((point as ILatLon).lat || (point as ICoordinates).latitude || (0 as number)),
      0,
    ) / points.length;
  const lon =
    points.reduce(
      (total: number, point) =>
        total + ((point as ILatLon).lon || (point as ILatLng).lng || (point as ICoordinates).latitude || (0 as number)),
      0,
    ) / points.length;
  return { lat, lon };
};

export const coordinatesToGisCoordinates = (latLon: ILatLon): ICoordinateGis => {
  return {
    ...latLon,
    location: {
      type: 'Point',
      coordinates: [latLon.lon, latLon.lat],
    },
  };
};

export const coordinatesToPoint = (latLon: ILatLon): Point => {
  return {
    type: 'Point',
    coordinates: [latLon.lon, latLon.lat],
  };
};

export const milesToMeters = (miles: number) => {
  return miles * 1609.344;
};

export const metersToMiles = (miles: number) => {
  return miles / 1609.344;
};

export function toLatLng(geoPoint: IGeoPoint): ILatLng {
  if (geoPoint) {
    return {
      lat: 'lat' in geoPoint ? (geoPoint as ILatLng).lat : (geoPoint as ICoordinates).latitude,
      lng:
        'lng' in geoPoint
          ? (geoPoint as ILatLng).lng
          : 'lon' in geoPoint
          ? (geoPoint as ILatLon).lon
          : (geoPoint as ICoordinates).longitude,
    };
  }

  return { lat: 0, lng: 0 };
}

export function containsRect(
  rect1: { northwest: LatLon; southeast: LatLon },
  rect2: { northwest: LatLon; southeast: LatLon },
): boolean {
  // simple comparison
  if (rect1.northwest.lat >= rect2.northwest.lat && rect1.southeast.lat <= rect2.southeast.lat) {
    if (rect1.northwest.lon <= rect2.northwest.lon && rect1.southeast.lon >= rect2.southeast.lon) {
      return true;
    }

    // west is accross the antimeridian from east
    if (
      rect1.northwest.lon > rect1.southeast.lon &&
      (rect2.northwest.lon <= rect1.southeast.lon || rect2.northwest.lon >= rect1.northwest.lon) &&
      (rect2.southeast.lon <= rect1.southeast.lon || rect2.southeast.lon >= rect1.northwest.lon)
    ) {
      return true;
    }
  }

  return false;
}

export function toLatLon(geoPoint: IGeoPoint): LatLon {
  if (geoPoint) {
    return new LatLon({
      lat: 'lat' in geoPoint ? (geoPoint as ILatLng).lat : (geoPoint as ICoordinates).latitude,
      lon:
        'lng' in geoPoint
          ? (geoPoint as ILatLng).lng
          : 'lon' in geoPoint
          ? (geoPoint as ILatLon).lon
          : (geoPoint as ICoordinates).longitude,
    });
  }

  return new LatLon();
}

export function positionToLatLon(point: Position, precisionFunction?: (value: number) => number): LatLon {
  if (point && precisionFunction) {
    return new LatLon({
      lat: precisionFunction(point[1]),
      lon: precisionFunction(point[0]),
    });
  }

  if (point) {
    return new LatLon({
      lat: point[1],
      lon: point[0],
    });
  }

  return new LatLon();
}

export function latLonToPosition(point: ILatLon): Position {
  if (point) {
    return [point.lon, point.lat];
  }

  return [];
}

export function toRadians(val: number): number {
  // add 360 and mod 360
  // so we can simply add or remove 90 to get perpendicular bearing
  const normalizedVal = (val + 360) % 360;
  return (normalizedVal * Math.PI) / 180;
}

export function toDegrees(val: number): number {
  return (val * 180) / Math.PI;
}
