import { ICalendarDay, ISchedule } from '@curbnturf/entities';
import { stringFromError } from '@curbnturf/form-helpers';
import { ScheduleActions, ScheduleActionTypes } from './schedule.actions';

export interface ScheduleState {
  schedules: { [confirmationCode: string]: ISchedule }; // list of Schedule;
  storedSchedule?: ISchedule;
  days: ICalendarDay[];
  selected?: string; // which Schedule record has been selected
  loaded: boolean; // has the Schedule list been loaded
  error?: string; // last error (if any)
}

export const initialState: ScheduleState = {
  schedules: {},
  days: [],
  loaded: false,
};

export function scheduleReducer(state: ScheduleState = initialState, action: ScheduleActions): ScheduleState {
  switch (action.type) {
    case ScheduleActionTypes.ScheduleLoaded: {
      if (!action.payload || !action.payload.confirmationCode) {
        return state;
      }

      const schedule = action.payload;
      if (!schedule.confirmationCode) {
        return state;
      }

      return {
        ...state,
        schedules: {
          ...state.schedules,
          [schedule.confirmationCode]: schedule,
        },
        loaded: true,
        error: undefined,
      };
    }
    case ScheduleActionTypes.ScheduleCreated: {
      if (!action.payload || !action.payload.confirmationCode) {
        return state;
      }

      const schedule = action.payload;
      if (!schedule.confirmationCode) {
        return state;
      }

      return {
        ...state,
        schedules: {
          ...state.schedules,
          [schedule.confirmationCode]: schedule,
        },
        selected: schedule.confirmationCode,
        error: undefined,
      };
    }
    case ScheduleActionTypes.StoreSchedule: {
      return {
        ...state,
        storedSchedule: action.payload,
      };
    }
    case ScheduleActionTypes.SchedulesLoaded: {
      const schedules: { [confirmationCode: string]: ISchedule } = {};
      action.payload.forEach((schedule) => {
        if (schedule && schedule.confirmationCode) {
          schedules[schedule.confirmationCode] = schedule;
        }
      });

      return {
        ...state,
        schedules: {...schedules },
        loaded: true,
        error: undefined,
      };
    }

    case ScheduleActionTypes.ScheduleRemoved: {
      const schedule = action.payload;
      if (!schedule.confirmationCode) {
        return state;
      }

      const scheduleState = { ...state, schedules: { ...state.schedules } };

      delete scheduleState.schedules[schedule.confirmationCode];
      delete scheduleState.error;

      return scheduleState;
    }

    case ScheduleActionTypes.ScheduleSelected: {
      if (!action.payload) {
        return {
          ...state,
          selected: undefined,
        };
      }

      const confirmationCode = action.payload.confirmationCode;
      if (!confirmationCode) {
        return state;
      }

      const schedule = action.payload;

      return {
        ...state,
        schedules: { ...state.schedules, [confirmationCode]: schedule },
        selected: confirmationCode,
        error: undefined,
      };
    }

    case ScheduleActionTypes.ScheduleDonated: {
      const confirmationCode = action.payload.confirmationCode;
      if (!confirmationCode) {
        return state;
      }

      const schedule = action.payload;

      return {
        ...state,
        schedules: { ...state.schedules, [confirmationCode]: schedule },
        error: undefined,
      };
    }

    case ScheduleActionTypes.CreateScheduleFailed:
    case ScheduleActionTypes.LoadScheduleFailed:
    case ScheduleActionTypes.UpdateScheduleFailed:
    case ScheduleActionTypes.ScheduleDonateFailed: {
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        error: stringFromError(action.payload),
      };
    }

    case ScheduleActionTypes.ScheduleSet: {
      let schedules = {};
      action.payload.forEach((schedule: ISchedule) => {
        if (schedule.confirmationCode) {
          schedules = { ...schedules, [schedule.confirmationCode]: schedule };
        }
      });
      return {
        ...state,
        schedules,
      };
    }

    case ScheduleActionTypes.ClearSchedules: {
      return {
        ...state,
        schedules: {},
      };
    }

    case ScheduleActionTypes.DaysFilled: {
      if (action.payload) {
        state = {
          ...state,
          days: action.payload,
        };
      }
      break;
    }

    default:
  }
  return state;
}
