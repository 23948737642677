import { NgModule } from '@angular/core';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import * as orderEffects from './+state/order.effects';
import { initialState, orderReducer } from './+state/order.reducer';

export function getInitialState() {
  return { ...initialState };
}
@NgModule({
  providers: [
    provideState(NGRX_BUCKETS.order, orderReducer, {
      initialState: getInitialState,
    }),
    provideEffects(orderEffects),
  ],
})
export class OrderModule {}
