<div class="credit-card-wrapper">
  <div class="icon-visa">
    <div class="w-embed">
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 47.8 47.8"
        style="enable-background: new 0 0 47.8 47.8"
        xml:space="preserve"
      >
        <style type="text/css">
          .Visa {
            fill: #1a1f71;
          }
        </style>
        <g>
          <g>
            <polygon class="Visa" points="19.2,16.8 16.7,31.1 20.6,31.1 23,16.8"></polygon>
          </g>
        </g>
        <g>
          <g>
            <path
              class="Visa"
              d="M13.5,16.8l-3.8,9.7l-0.4-1.5c-0.8-1.8-2.9-4.3-5.4-5.9l3.5,11.9l4.1,0l6.1-14.2L13.5,16.8z"
            ></path>
          </g>
        </g>
        <g>
          <g>
            <path class="Visa" d="M7.8,17.8c-0.2-0.9-0.9-1.1-1.7-1.2h-6L0,17c4.7,1.1,7.8,3.9,9.1,7.2L7.8,17.8z"></path>
          </g>
        </g>
        <g>
          <g>
            <path
              class="Visa"
              d="M30.9,19.5c1.3,0,2.2,0.3,2.9,0.5l0.4,0.2l0.5-3.1c-0.8-0.3-2-0.6-3.5-0.6c-3.8,0-6.6,1.9-6.6,4.7
c0,2,1.9,3.2,3.4,3.9c1.5,0.7,2,1.1,2,1.8c0,1-1.2,1.4-2.3,1.4c-1.6,0-2.4-0.2-3.7-0.7l-0.5-0.2L23,30.5c0.9,0.4,2.6,0.7,4.3,0.8
c4.1,0,6.7-1.9,6.8-4.9c0-1.6-1-2.9-3.3-3.9c-1.4-0.7-2.2-1.1-2.2-1.8C28.7,20.2,29.4,19.5,30.9,19.5z"
            ></path>
          </g>
        </g>
        <g>
          <g>
            <path
              class="Visa"
              d="M44.7,16.8h-3c-0.9,0-1.6,0.3-2,1.2l-5.8,13.1H38c0,0,0.7-1.8,0.8-2.1c0.4,0,4.4,0,5,0
c0.1,0.5,0.5,2.1,0.5,2.1h3.6L44.7,16.8z M39.9,26c0.3-0.8,1.5-4,1.5-4c0,0,0.3-0.8,0.5-1.4l0.3,1.2c0,0,0.7,3.4,0.9,4.1H39.9z"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  </div>
  <div class="icon-mastercard">
    <div class="w-embed">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 131.4 86.9"
        style="enable-background: new 0 0 131.4 86.9"
        xml:space="preserve"
      >
        <style type="text/css">
          .MC-0 {
            opacity: 0;
          }
          .MC-1 {
            fill: #ffffff;
          }
          .MC-2 {
            fill: #ff5f00;
          }
          .MC-3 {
            fill: #eb001b;
          }
          .MC-4 {
            fill: #f79e1b;
          }
        </style>
        <g class="MC-0">
          <rect class="MC-1" width="131.4" height="86.9"></rect>
        </g>
        <rect x="48.4" y="15.1" class="MC-2" width="34.7" height="56.6"></rect>
        <path
          class="MC-3"
          d="M51.9,43.5c0-11,5.1-21.5,13.8-28.3C50.1,2.9,27.4,5.6,15.1,21.2s-9.6,38.3,6.1,50.5C34.3,82,52.6,82,65.7,71.8
C57,64.9,51.9,54.5,51.9,43.5z"
        ></path>
        <path
          class="MC-4"
          d="M120.5,65.8v-1.2h0.5v-0.2h-1.2v0.2h0.5v1.2H120.5z M122.8,65.8v-1.4h-0.4l-0.4,1l-0.4-1h-0.4v1.4h0.3v-1.1
l0.4,0.9h0.3l0.4-0.9v1.1L122.8,65.8z"
        ></path>
        <path
          class="MC-4"
          d="M123.9,43.5c0,19.9-16.1,36-36,36c-8.1,0-15.9-2.7-22.2-7.7C81.3,59.5,84,36.8,71.8,21.2
c-1.8-2.3-3.8-4.3-6.1-6.1c15.6-12.3,38.3-9.6,50.6,6.1C121.2,27.5,123.9,35.4,123.9,43.5L123.9,43.5z"
        ></path>
      </svg>
    </div>
  </div>
  <div class="icon-amex">
    <div class="w-embed">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 36 16"
        style="enable-background: new 0 0 36 16"
        xml:space="preserve"
      >
        <style type="text/css">
          .icon-amex {
            fill: #016fd0;
          }
        </style>
        <g>
          <path
            class="icon-amex"
            d="M9.8,7.4c0-1,0-1.9,0-2.9l0,0C9.6,5,9.4,5.5,9.3,6C9.1,6.4,9,6.8,8.8,7.2c0,0.2-0.1,0.2-0.3,0.2
c-0.3,0-0.6,0.1-0.8-0.1c-0.1,0-0.2-0.4-0.3-0.6C7.2,6,6.9,5.3,6.6,4.6c0,0.1,0,0.1,0,0.2c0,0.8,0,1.6,0,2.4c0,0.2-0.1,0.2-0.2,0.2
c-0.3,0-0.5,0-0.8,0c-0.1,0-0.2,0-0.2-0.2c0-1.3,0-2.6,0-3.9c0-0.2,0.1-0.2,0.2-0.2c0.5,0,0.9,0,1.4,0c0.2,0,0.2,0.1,0.3,0.2
c0.3,0.8,0.6,1.6,0.9,2.4c0.1-0.3,0.2-0.6,0.3-0.9c0.2-0.5,0.4-1.1,0.6-1.6C9.1,3.1,9.2,3,9.4,3c0.5,0,0.9,0,1.4,0
C11,3.1,11,3.1,11,3.3c0,1.3,0,2.6,0,3.9c0,0.2-0.1,0.2-0.2,0.2C10.5,7.4,10.2,7.4,9.8,7.4z"
          ></path>
          <path
            class="icon-amex"
            d="M34.1,5.5c0-0.7,0-1.4,0-2c0-0.4,0-0.4,0.4-0.4c0.2,0,0.4,0,0.5,0c0.2,0,0.3,0.1,0.3,0.3c0,0.8,0,1.5,0,2.3
c0,0.5,0,1,0,1.6c0,0.2-0.1,0.3-0.3,0.3c-0.3,0-0.6,0-1,0c-0.2,0-0.3-0.1-0.3-0.2c-0.5-0.8-1-1.5-1.5-2.3c0,0,0-0.1-0.1-0.2
c0,0.4,0,0.8,0,1.1c0,0.4,0,0.9,0,1.3c0,0.1-0.1,0.2-0.1,0.2c-0.3,0-0.7,0-1.1,0c0-0.1,0-0.2,0-0.2c0-1.3,0-2.6,0-3.8
c0-0.2,0.1-0.3,0.2-0.3c0.3,0,0.7,0,1,0c0.2,0,0.2,0.1,0.3,0.2c0.6,0.6,1.1,1.3,1.5,2C34,5.4,34,5.5,34.1,5.5L34.1,5.5z"
          ></path>
          <path
            class="icon-amex"
            d="M22.1,11.4c0.5,0.5,0.9,1,1.4,1.5c-0.1,0-0.1,0-0.2,0s-0.2,0-0.3,0c-0.3,0-0.7,0.1-1,0s-0.4-0.5-0.7-0.7
c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.1-0.2-0.1-0.2-0.1c-0.2,0-0.2,0-0.2,0.2c0,0.3,0,0.7,0,1c0,0.2,0,0.2-0.2,0.2c-0.3,0-0.5,0-0.8,0
c-0.2,0-0.2,0-0.2-0.2c0-1.3,0-2.6,0-3.9c0-0.2,0.1-0.2,0.2-0.2c0.8,0,1.6,0,2.3,0c0.9,0,1.5,0.6,1.5,1.4c0,0.6-0.3,1.1-0.9,1.3
C22.2,11.3,22.2,11.3,22.1,11.4z M21,10.4c0.1,0,0.3,0,0.4,0c0,0,0,0,0.1,0c0.3,0,0.4-0.1,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4
c-0.2,0-0.4,0-0.6,0c-0.6,0-0.6,0-0.6,0.6c0,0.1,0.1,0.2,0.2,0.2C20.7,10.4,20.9,10.4,21,10.4z"
          ></path>
          <path
            class="icon-amex"
            d="M19.1,5.9c0.5,0.5,0.9,1,1.4,1.5c-0.1,0-0.1,0.1-0.1,0.1c-0.4,0-0.8,0-1.2,0c-0.1,0-0.2-0.1-0.2-0.1
c-0.4-0.4-0.7-0.8-1.1-1.2c0-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.3,0-0.3,0.3s0,0.7,0,1c0,0.1,0,0.2-0.2,0.2c-0.3,0-0.6,0-0.8,0
c-0.1,0-0.2,0-0.2-0.2c0-1.3,0-2.7,0-4c0-0.1,0-0.2,0.2-0.2c0.9,0,1.8,0,2.7,0c0.6,0,1.1,0.5,1.2,1.1c0,0.7-0.3,1.2-0.9,1.5
C19.3,5.8,19.2,5.8,19.1,5.9z M18.1,4.1c-0.7,0-0.7,0-0.7,0.6l0,0l0.1,0.1c0.4,0,0.8,0,1.1,0c0.3,0,0.4-0.2,0.4-0.4
C19,4.2,18.8,4,18.5,4C18.4,4.1,18.2,4.1,18.1,4.1z"
          ></path>
          <path
            class="icon-amex"
            d="M11.8,5.2c0-0.6,0-1.3,0-1.9c0-0.2,0.1-0.3,0.3-0.3c1.1,0,2.1,0,3.2,0c0.2,0,0.3,0.1,0.3,0.3s0,0.4,0,0.6
s-0.1,0.2-0.2,0.2c-0.7,0-1.3,0-2,0c-0.2,0-0.3,0.1-0.2,0.3v0.1c0,0.2,0.1,0.2,0.2,0.2c0.6,0,1.2,0,1.8,0c0.3,0,0.3,0,0.3,0.3
c0,0.1,0,0.3,0,0.4c0,0.2,0,0.3-0.2,0.3c-0.6,0-1.3,0-1.9,0c-0.2,0-0.3,0-0.3,0.2c0,0.5,0,0.4,0.4,0.4c0.6,0,1.2,0,1.9,0
c0.2,0,0.2,0.1,0.2,0.2c0,0.2,0,0.4,0,0.6s-0.1,0.3-0.3,0.3c-0.9,0-1.8,0-2.6,0c-0.2,0-0.3,0-0.5,0s-0.3-0.1-0.3-0.3
C11.8,6.5,11.8,5.9,11.8,5.2z"
          ></path>
          <path
            class="icon-amex"
            d="M24.9,11.9c0.1,0,0.2,0,0.2,0c0.7,0,1.3,0,2,0c0.2,0,0.3,0.1,0.2,0.2c0,0.2,0,0.4,0,0.6c0,0.1-0.1,0.2-0.2,0.2
c-1.1,0-2.2,0-3.3,0c-0.2,0-0.2-0.1-0.2-0.2c0-0.7,0-1.3,0-2c0-0.6,0-1.2,0-1.8c0-0.2,0.1-0.3,0.3-0.3c1.1,0,2.1,0,3.2,0
c0.2,0,0.3,0.1,0.3,0.3s0,0.4,0,0.6c0,0.2-0.1,0.2-0.2,0.2c-0.7,0-1.3,0-2,0c-0.1,0-0.2,0-0.2,0.1c0,0.2,0,0.3,0,0.5
c0,0,0.1,0,0.2,0c0.6,0,1.3,0,1.9,0c0.2,0,0.3,0,0.3,0.3c0,0.2,0,0.4,0,0.5c0,0.2,0,0.3-0.2,0.3c-0.6,0-1.2,0-1.8,0
C25,11.4,25,11.4,24.9,11.9C24.9,11.7,24.9,11.8,24.9,11.9z"
          ></path>
          <path
            class="icon-amex"
            d="M5.5,10.7c0-0.6,0-1.3,0-1.9c0-0.2,0.1-0.3,0.3-0.3c1,0,2.1,0,3.1,0c0.2,0,0.3,0,0.3,0.2s0,0.3,0,0.5
S9.1,9.5,8.9,9.5c-0.7,0-1.3,0-2,0c-0.2,0-0.3,0-0.2,0.2v0.1c0,0.2,0,0.2,0.2,0.2c0.6,0,1.3,0,1.9,0c0.2,0,0.3,0.1,0.3,0.3
s0,0.4,0,0.6c0,0.2-0.1,0.2-0.2,0.2c-0.7,0-1.3,0-2,0c-0.2,0-0.3,0-0.3,0.3c0,0.4,0,0.4,0.4,0.4c0.6,0,1.2,0,1.9,0
c0.2,0,0.3,0,0.3,0.2s0,0.4,0,0.6c0,0.1,0,0.2-0.2,0.2c-1.1,0-2.2,0-3.3,0c-0.2,0-0.2-0.1-0.2-0.2C5.5,12,5.5,11.4,5.5,10.7z"
          ></path>
          <path
            class="icon-amex"
            d="M5.3,7.4c-0.4,0-0.8,0-1.2,0C4,7.4,3.9,7.3,3.9,7.3C3.8,7.1,3.7,6.9,3.6,6.7c0-0.1-0.1-0.1-0.2-0.1
c-0.5,0-1.1,0-1.6,0c-0.1,0-0.2,0.1-0.2,0.1C1.5,6.9,1.5,7.1,1.4,7.2c0,0.1-0.1,0.2-0.1,0.2c-0.4,0-0.8,0-1.2,0
c0-0.1,0.1-0.2,0.1-0.2c0.6-1.3,1.1-2.6,1.7-3.8c0-0.3,0.1-0.3,0.3-0.3c0.3,0,0.7,0,1,0c0.1,0,0.2,0.1,0.3,0.2
C4.1,4.6,4.7,6,5.3,7.4C5.3,7.3,5.3,7.3,5.3,7.4z M2.1,5.5c0.4,0,0.7,0,1.1,0C3,5.1,2.8,4.7,2.6,4.2C2.4,4.7,2.3,5.1,2.1,5.5z"
          ></path>
          <path
            class="icon-amex"
            d="M25.5,7.4c0.1-0.2,0.1-0.3,0.2-0.4c0.5-1.2,1.1-2.5,1.6-3.7c0.1-0.2,0.2-0.3,0.4-0.2c0.4,0,0.7,0,1.1,0
c0.1,0,0.2,0.1,0.3,0.2c0.6,1.3,1.2,2.7,1.8,4c0,0,0,0.1,0,0.2c-0.3,0-0.5,0-0.7,0c-0.7,0-0.5,0.1-0.8-0.5
c-0.2-0.4-0.1-0.3-0.5-0.3c-0.5,0-0.9,0-1.4,0c-0.2,0-0.2,0-0.3,0.2l0,0c-0.3,0.6-0.3,0.6-1,0.6C25.9,7.4,25.7,7.4,25.5,7.4z
M28.1,4.2c-0.2,0.5-0.4,0.9-0.5,1.3c0.4,0,0.7,0,1.1,0C28.5,5.1,28.3,4.7,28.1,4.2z"
          ></path>
          <path
            class="icon-amex"
            d="M35.9,8.6c-0.2,0.3-0.3,0.6-0.4,0.9c0,0.1-0.2,0.1-0.2,0.1c-0.6,0-1.1,0-1.7,0c-0.1,0-0.3,0.1-0.3,0.2
c-0.1,0.2,0,0.4,0.3,0.4s0.7,0,1,0c0.2,0,0.3,0,0.5,0.1c0.5,0.1,0.9,0.5,0.9,1c0.1,0.5-0.2,1.1-0.6,1.4c-0.2,0.1-0.5,0.2-0.8,0.2
c-0.8,0-1.5,0-2.3,0c-0.2,0-0.2-0.1-0.2-0.2c0-0.2,0-0.4,0-0.6c0-0.2,0.1-0.3,0.3-0.3c0.6,0,1.2,0,1.8,0c0.1,0,0.2,0,0.3,0
c0.2,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3c-0.3,0-0.5,0-0.8,0c-0.2,0-0.5,0-0.7-0.1c-0.5-0.1-0.9-0.5-1-1.1
c-0.1-0.6,0.2-1.1,0.8-1.4c0.2-0.1,0.5-0.2,0.7-0.2c0.8,0,1.5,0,2.3,0C35.8,8.6,35.8,8.6,35.9,8.6z"
          ></path>
          <path
            class="icon-amex"
            d="M31.7,8.6c-0.2,0.4-0.3,0.7-0.4,1c0,0.1-0.1,0.1-0.2,0.1c-0.5,0-1.1,0-1.6,0c-0.1,0-0.1,0-0.2,0
C29.1,9.7,29,9.8,29,10s0.1,0.3,0.3,0.3c0.3,0,0.7,0,1,0c0.2,0,0.4,0,0.5,0.1c0.5,0.1,0.8,0.4,0.9,0.9c0.1,0.6,0,1.1-0.5,1.4
c-0.3,0.2-0.6,0.3-0.9,0.3c-0.8,0-1.5,0-2.3,0c-0.2,0-0.2-0.1-0.2-0.2c0-0.2,0-0.4,0-0.6c0-0.2,0.1-0.2,0.2-0.2c0.6,0,1.2,0,1.8,0
c0.1,0,0.2,0,0.2,0c0.2,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.4-0.3c-0.2,0-0.5,0-0.7,0c-0.1,0-0.3,0-0.4,0c-0.7,0-1.3-0.5-1.3-1.1
c-0.1-0.8,0.4-1.4,1.2-1.5c0.5-0.1,1,0,1.6,0C30.9,8.6,31.3,8.6,31.7,8.6z"
          ></path>
          <path
            class="icon-amex"
            d="M11.9,9.9c0.3-0.4,0.6-0.8,1-1.1c0.1-0.1,0.2-0.1,0.3-0.1c0.3,0,0.7,0,1,0c0.1,0,0.1,0,0.3,0
c-0.6,0.7-1.2,1.4-1.9,2.1c0.6,0.7,1.3,1.4,1.9,2.2c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8,0-1.2,0c-0.1,0-0.2-0.1-0.3-0.2
c-0.3-0.4-0.7-0.8-1-1.2c-0.3,0.4-0.7,0.8-1,1.1c-0.1,0.1-0.2,0.2-0.4,0.2c-0.4,0-0.8,0-1.2,0c0,0,0,0,0-0.1
c0.6-0.7,1.2-1.4,1.9-2.1C10.5,10,9.9,9.3,9.2,8.6c0.1,0,0.2,0,0.2,0c0.4,0,0.7,0,1.1,0c0.1,0,0.2,0.1,0.3,0.1
C11.2,9.1,11.5,9.5,11.9,9.9z"
          ></path>
          <path
            class="icon-amex"
            d="M14.6,10.7c0-0.6,0-1.3,0-1.9c0-0.2,0.1-0.3,0.3-0.3c0.8,0,1.5,0,2.3,0c1.2,0,1.9,1.1,1.4,2.2
c-0.3,0.5-0.8,0.7-1.4,0.8c-0.4,0-0.8,0-1.2,0c-0.2,0-0.2,0.1-0.2,0.2c0,0.3,0,0.7,0,1c0,0.1,0,0.2-0.2,0.2s-0.4,0-0.6,0
c-0.4,0-0.4,0-0.4-0.4C14.6,11.9,14.6,11.3,14.6,10.7z M16.5,10.5L16.5,10.5c0.2,0,0.4,0,0.5,0c0.3,0,0.4-0.2,0.4-0.4
c0-0.3-0.1-0.4-0.4-0.4c-0.2,0-0.4,0-0.6,0c-0.7,0-0.7,0-0.7,0.7c0,0.1,0,0.2,0.2,0.2C16.2,10.5,16.4,10.5,16.5,10.5z"
          ></path>
          <path
            class="icon-amex"
            d="M25.5,6.4c-0.2,0.4-0.3,0.7-0.5,1c0,0-0.1,0.1-0.2,0.1c-0.5,0-0.9,0-1.3-0.2c-0.5-0.2-0.8-0.6-0.9-1.1
c-0.2-0.6-0.2-1.1,0-1.7c0.3-0.8,0.8-1.2,1.6-1.3c0.5-0.1,1,0,1.6,0C26,3.2,26,3.3,26,3.4c0,0.2,0,0.4,0,0.6s-0.1,0.2-0.2,0.2
c-0.4,0-0.7,0-1.1,0c-0.7,0-1,0.4-1,1.1c0,0.1,0,0.2,0,0.4c0.1,0.5,0.5,0.8,1,0.8C25,6.4,25.2,6.4,25.5,6.4z"
          ></path>
          <path
            class="icon-amex"
            d="M20.8,5.3c0-0.6,0-1.3,0-1.9c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.5,0,0.7,0S22,3.2,22,3.3c0,1.3,0,2.6,0,3.9
c0,0.1-0.1,0.2-0.2,0.2c-0.3,0-0.5,0-0.8,0c-0.2,0-0.2-0.1-0.2-0.2C20.8,6.6,20.8,5.9,20.8,5.3z"
          ></path>
        </g>
      </svg>
    </div>
  </div>
  <div class="icon-discover">
    <div class="w-embed">
      <svg
        version="1.1"
        inkscape:version="0.91 r13725"
        sodipodi:docname="Discover.svg"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 126.9 21.4"
        style="enable-background: new 0 0 126.9 21.4"
        xml:space="preserve"
      >
        <style type="text/css">
          .mastercard-st0 {
            fill: #201d1c;
          }
          .mastercard-st1 {
            fill: #e55c20;
          }
        </style>
        <g>
          <path
            class="mastercard-st0"
            d="M10.3,15.7c-1.2,1.1-2.8,1.6-5.4,1.6H3.9V4H5
C7.5,4,9,4.4,10.3,5.6c1.4,1.2,2.2,3.1,2.2,5C12.5,12.5,11.7,14.4,10.3,15.7z M5.7,0.6H0v20.1h5.7c3,0,5.2-0.7,7.2-2.3
c2.3-1.9,3.6-4.8,3.6-7.7C16.5,4.7,12.1,0.6,5.7,0.6"
          ></path>
          <path class="mastercard-st0" d="M18.3,20.6h3.9V0.6h-3.9V20.6z"></path>
          <path
            class="mastercard-st0"
            d="M31.8,8.3c-2.3-0.9-3-1.4-3-2.5c0-1.3,1.2-2.2,2.9-2.2
c1.2,0,2.1,0.5,3.2,1.6l2-2.7C35.2,1,33.2,0.2,31,0.2c-3.6,0-6.3,2.5-6.3,5.8c0,2.8,1.3,4.2,4.9,5.5c1.5,0.5,2.3,0.9,2.7,1.1
c0.8,0.5,1.2,1.2,1.2,2.1c0,1.6-1.3,2.8-3,2.8c-1.9,0-3.4-0.9-4.3-2.7l-2.5,2.4c1.8,2.6,4,3.8,6.9,3.8c4.1,0,6.9-2.7,6.9-6.6
C37.6,11.3,36.3,9.9,31.8,8.3"
          ></path>
          <path
            class="mastercard-st0"
            d="M38.8,10.6c0,5.9,4.6,10.5,10.6,10.5c1.7,0,3.1-0.3,4.9-1.2
v-4.6c-1.6,1.6-3,2.2-4.7,2.2c-3.9,0-6.7-2.9-6.7-6.9c0-3.9,2.9-6.9,6.6-6.9c1.9,0,3.3,0.7,4.9,2.3V1.4c-1.7-0.9-3.1-1.2-4.8-1.2
C43.6,0.1,38.8,4.8,38.8,10.6"
          ></path>
          <path class="mastercard-st0" d="M85.4,14.1L80,0.6h-4.3l8.5,20.6h2.1L95,0.6h-4.2L85.4,14.1"></path>
          <path
            class="mastercard-st0"
            d="M96.8,20.6h11.1v-3.4h-7.2v-5.4h6.9V8.4h-6.9V4h7.2V0.6H96.8
V20.6"
          ></path>
          <path
            class="mastercard-st0"
            d="M115.6,9.8h-1.1V3.7h1.2c2.4,0,3.8,1,3.8,3
C119.4,8.7,118.1,9.8,115.6,9.8z M123.4,6.5c0-3.8-2.6-5.9-7.1-5.9h-5.8v20.1h3.9v-8.1h0.5l5.4,8.1h4.8l-6.3-8.5
C121.8,11.6,123.4,9.6,123.4,6.5"
          ></path>
          <path
            class="mastercard-st0"
            d="M125.2,1.6L125.2,1.6l-0.1-0.5h0.1c0.2,0,0.3,0.1,0.3,0.2
C125.5,1.5,125.4,1.6,125.2,1.6z M125.9,1.3c0-0.4-0.2-0.5-0.7-0.5h-0.6v1.8h0.4V1.9l0.5,0.7h0.5l-0.6-0.7
C125.8,1.8,125.9,1.6,125.9,1.3"
          ></path>
          <path
            class="mastercard-st0"
            d="M125.3,2.9c-0.7,0-1.2-0.6-1.2-1.3c0-0.7,0.5-1.3,1.2-1.3
c0.7,0,1.2,0.6,1.2,1.3C126.5,2.4,126,2.9,125.3,2.9z M125.3,0.1c-0.9,0-1.5,0.7-1.5,1.5c0,0.9,0.7,1.5,1.5,1.5
c0.8,0,1.5-0.7,1.5-1.5C126.9,0.8,126.2,0.1,125.3,0.1"
          ></path>
          <path
            class="mastercard-st1"
            d="M55.6,10.7C55.6,10.7,55.6,10.7,55.6,10.7C55.6,4.8,60.4,0,66.3,0C72.2,0,77,4.8,77,10.7c0,0,0,0,0,0
c0,5.9-4.8,10.7-10.7,10.7C60.4,21.4,55.6,16.6,55.6,10.7"
          ></path>
        </g>
      </svg>
    </div>
  </div>
</div>
