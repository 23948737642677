<div class="info-inner listing">
  <div></div>
  <div class="delete-wrapper">
    <a (click)="fireRemove()" class="icon-trash-wrapper w-inline-block">
      <div class="html-embed w-embed">
        <curbnturf-misc-svg icon="trash"></curbnturf-misc-svg>
      </div>
    </a>
    <a (click)="clearDates()" class="cnt-button filter w-inline-block">
      <div class="cnt-button-copy">clear</div>
    </a>
  </div>
</div>
<curbnturf-datepicker
  #dp
  (select)="onDateSelection($event)"
  [displayMonths]="2"
  [dayTemplate]="t"
  [isDisabled]="isDisabled()"
  [minDate]="today"
>
</curbnturf-datepicker>

<ng-template #t let-date="date" let-focused="focused">
  <div
    class="custom-day"
    [class.focused]="focused"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </div>
</ng-template>
