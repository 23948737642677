import { IMessage, IMessageChannel, IUser } from '../interfaces';

export class Message implements IMessage {
  id?: number;
  channel: IMessageChannel;
  channelId?: number;
  from?: IUser;
  fromId?: number;
  to?: IUser;
  toId?: number;
  body: string;
  timestamp?: number;
  read?: boolean;

  constructor(data?: IMessage) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: IMessage): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
