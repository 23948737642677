import { HttpErrorResponse } from '@angular/common/http';
import { ICalendarDay, IOrder, IReservation } from '@curbnturf/entities';
import { Action } from '@ngrx/store';

export enum OrderActionTypes {
  OrderError = '[Order] Order Load Error',
  PriceError = '[Order] Price Load Error',
  RequestPrice = '[Order] Fill the priceComponents',
  PriceFilled = '[Order] priceComponents Filled',
  RequestDays = '[Order] Fill the calendar days',
  DaysFilled = '[Order] calendar days Filled',

  // new and used actions
  SetOrder = '[Order] Set Local Order Process',
}

export class OrderError implements Action {
  readonly type = OrderActionTypes.OrderError;
  constructor(public payload: HttpErrorResponse) {}
}

export class PriceError implements Action {
  readonly type = OrderActionTypes.PriceError;
  constructor(public payload: HttpErrorResponse) {}
}

export class RequestPrice implements Action {
  readonly type = OrderActionTypes.RequestPrice;
  constructor(public payload: IReservation) {}
}

export class PriceFilled implements Action {
  readonly type = OrderActionTypes.PriceFilled;
  constructor(public payload: IReservation) {}
}

export class RequestDays implements Action {
  readonly type = OrderActionTypes.RequestDays;
  constructor(public payload: number, public reservationId?: number) {}
}

export class DaysFilled implements Action {
  readonly type = OrderActionTypes.DaysFilled;
  constructor(public payload: ICalendarDay[]) {}
}

export class SetOrder implements Action {
  readonly type = OrderActionTypes.SetOrder;
  constructor(public payload?: IOrder) {}
}

export type OrderAction = OrderError | PriceError | RequestPrice | PriceFilled | RequestDays | DaysFilled | SetOrder;

export const fromOrderActions = {
  OrderError,
  PriceError,
  RequestPrice,
  PriceFilled,
  RequestDays,
  DaysFilled,
  SetOrder,
};
