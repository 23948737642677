import { HttpErrorResponse } from '@angular/common/http';
import { IActivityLocation, IActivityLocationSearchRequest, IPhoto } from '@curbnturf/entities';
import { createAction, props } from '@ngrx/store';

export enum ActivityActionTypes {
  AwaitActivity = '[Activity] Await Activity',
  CancelAwait = '[Activity] Complete Activity',
  CreateActivity = '[Activity] Create Activity',
  CreateActivityFailed = '[Activity] Create Activity Failed',
  LoadActivity = '[Activity] Load Data',
  LoadActivities = '[Activity] Load Activities',
  LoadActivityFailed = '[Activity] Load Data Failed',
  LoadForSearch = '[Activity] Load Search Data',
  LoadedForSearch = '[Activity] Loaded Search Data',
  ClearActivities = '[Activity] Clear Activities',
  ClearSearchActivities = '[Activity] Clear Search Activities',
  UpdateActivity = '[Activity] Update',
  UpdateActivityFailed = '[Activity] Update Failed',
  UploadActivityImage = '[Activity] Upload Activity Image',
  CancelUploadActivityImage = '[Activity] Cancel Upload Activity Image',
  PendingUploadActivityImage = '[Activity] Pending Upload Activity Image',
  ActivityImagesUpdated = '[Activity] Images Updated',
  RemoveImageFromActivity = '[Activity] Remove Image',
  RemoveActivity = '[Activity] Remove Activity',
  ActivityCreated = '[Activity] Created',
  ActivityImageRemoved = '[Activity] Image Removed',
  ActivityLoaded = '[Activity] Data Loaded',
  ActivitiesLoaded = '[Activity] Data For Activities Loaded',
  ActivityRemoved = '[Activity] Removed',
  ActivityUpdated = '[Activity] Updated',
  ShowActivity = '[Activity] Show',
  HideActivity = '[Activity] Hide',
  SelectActivity = '[Activity] Select Activity',
  ActivitySelected = '[Activity] Activity Selected',
  FavoriteActivity = '[Activity] Favorite Activity',
  UnfavoriteActivity = '[Activity] Unfavorite Activity',
  ActivityFavorCompleted = '[Activity] Activity Favorite/Unfavorite Completed',
  ActivityFavorFailed = '[Activity] Activity Favorite/Unfavorite Failed',
}

export const awaitActivity = createAction(
  ActivityActionTypes.AwaitActivity,
  props<{ activity?: IActivityLocation }>(),
);

export const cancelAwait = createAction(
  ActivityActionTypes.CancelAwait,
);

export const createActivity = createAction(
  ActivityActionTypes.CreateActivity,
  props<{ activity?: IActivityLocation }>(),
);

export const createActivityFailed = createAction(
  ActivityActionTypes.CreateActivityFailed,
  props<{ error: string | HttpErrorResponse }>(),
);

export const loadActivity = createAction(
  ActivityActionTypes.LoadActivity,
  // payload is the activity id
  props<{ activityId: number }>(),
);

export const loadActivities = createAction(
  ActivityActionTypes.LoadActivities,
  props<{ siteId?: number; lat?: number; lon?: number; hidden?: boolean; available?: boolean }>(),
);

export const loadActivityFailed = createAction(
  ActivityActionTypes.LoadActivityFailed,
  props<{ error: string | HttpErrorResponse }>(),
);

export const loadForSearch = createAction(
  ActivityActionTypes.LoadForSearch,
  props<{ request: IActivityLocationSearchRequest }>(),
);

export const loadedForSearch = createAction(
  ActivityActionTypes.LoadedForSearch,
  props<{ activities: IActivityLocation[] }>(),
);

export const clearActivities = createAction(
  ActivityActionTypes.ClearActivities,
);

export const clearSearchActivities = createAction(
  ActivityActionTypes.ClearSearchActivities,
);

export const uploadActivityImage = createAction(
  ActivityActionTypes.UploadActivityImage,
  props<{ formData: FormData; data: { activityId: number } }>(),
);

export const cancelUploadActivityImage = createAction(
  ActivityActionTypes.CancelUploadActivityImage,
);

export const pendingUploadActivityImage = createAction(
  ActivityActionTypes.PendingUploadActivityImage,
  props<FormData>(),
);

export const removeImageFromActivity = createAction(
  ActivityActionTypes.RemoveImageFromActivity,
  props<{ activityId: number; index: number }>(),
);

export const removeActivity = createAction(
  ActivityActionTypes.RemoveActivity,
  props<{ activityId: number }>(),
);

export const activityCreated = createAction(
  ActivityActionTypes.ActivityCreated,
  props<{ activity: IActivityLocation }>(),
);

export const activityImageRemoved = createAction(
  ActivityActionTypes.ActivityImageRemoved,
  props<{ activity: IActivityLocation }>(),
);

export const activityLoaded = createAction(
  ActivityActionTypes.ActivityLoaded,
  props<{ activity: IActivityLocation }>(),
);

export const activityRemoved = createAction(
  ActivityActionTypes.ActivityRemoved,
  props<{ activityId: number }>(),
);

export const activitiesLoaded = createAction(
  ActivityActionTypes.ActivitiesLoaded,
  props<{ activities: IActivityLocation[] }>(),
);

export const activityUpdated = createAction(
  ActivityActionTypes.ActivityUpdated,
  props<{ activity: IActivityLocation }>(),
);

export const showActivity = createAction(
  ActivityActionTypes.ShowActivity,
  props<{ activity: IActivityLocation }>(),
);

export const hideActivity = createAction(
  ActivityActionTypes.HideActivity,
  props<{ activity: IActivityLocation }>(),
);

export const updateActivity = createAction(
  ActivityActionTypes.UpdateActivity,
  props<{ activity: IActivityLocation }>(),
);

export const updateActivityFailed = createAction(
  ActivityActionTypes.UpdateActivityFailed,
  props<{ error: string | HttpErrorResponse }>(),
);

export const activityImagesUpdated = createAction(
  ActivityActionTypes.ActivityImagesUpdated,
  props<{ activityId: number; photos: IPhoto[] }>(),
);

export const selectActivity = createAction(
  ActivityActionTypes.SelectActivity,
  props<{ activityId?: number }>(),
);

export const activitySelected = createAction(
  ActivityActionTypes.ActivitySelected,
  props<{ activity: IActivityLocation }>(),
);

export const favoriteActivity = createAction(
  ActivityActionTypes.FavoriteActivity,
  props<{ activity: IActivityLocation }>(),
);

export const unfavoriteActivity = createAction(
  ActivityActionTypes.UnfavoriteActivity,
  props<{ activity: IActivityLocation }>(),
);

export const activityFavorCompleted = createAction(
  ActivityActionTypes.ActivityFavorCompleted,
  props<{ id: number; favored: boolean }>(),
);

export const activityFavorFailed = createAction(
  ActivityActionTypes.ActivityFavorFailed,
  props<{ error: string | HttpErrorResponse }>(),
);
