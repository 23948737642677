import { inject } from '@angular/core';
import { Router } from '@angular/router';
import * as AlertActions from '@curbnturf/alert/src/lib/+state/alert.actions';
import * as AuthModalActions from '@curbnturf/auth-modal/src/lib/+state/auth-modal.actions';
import * as AuthModalSelectors from '@curbnturf/auth-modal/src/lib/+state/auth-modal.selectors';
import { authModalQuery } from '@curbnturf/auth-modal/src/lib/+state/auth-modal.selectors';
import { ICreateUser, IUser, LogLevel, NGRX_NO_ACTION, PATHS, Role, User } from '@curbnturf/entities';
import { siteComplete } from '@curbnturf/helpers';
import * as MessageActions from '@curbnturf/message/src/lib/+state/message.actions';
import * as LogActions from '@curbnturf/network/src/lib/log/+state/log.actions';
import * as NetworkSelectors from '@curbnturf/network/src/lib/network/+state/network.selectors';
import { ReservationActionTypes } from '@curbnturf/reservation/src/lib/+state/reservation.actions';
import { StorageActionTypes } from '@curbnturf/storage/src/lib/+state/storage.actions';
import * as UserActions from '@curbnturf/user/src/lib/+state/user.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { exhaustMap, from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import * as AuthActions from './auth.actions';
import * as AuthSelectors from './auth.selectors';

export const userCreated$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router), store = inject(Store)) =>
    actions$.pipe(
      ofType(UserActions.userCreated),
      withLatestFrom(store.select(AuthModalSelectors.getModal)),
      mergeMap(([action, modal]) => {
        const alertAction = AlertActions.displayAlert({
          title: 'Email Sent',
          body: 'A verification email was sent to the email you provided. Please check your inbox and click the link provided to verify you email before logging in.',
        });

        if (!modal) {
          // reroute back to current location to see if they are still able to be here
          return from(router.navigate([PATHS.login])).pipe(map(() => alertAction));
        } else {
          return [AuthModalActions.openAuthModal({ modal: 'login' }), alertAction];
        }
      }),
      catchError((error) => [UserActions.userCreateError({ error })]),
    ),
  { functional: true },
);

export const loginUserFromSocialLogin$ = createEffect(
  (actions$ = inject(Actions), authService = inject(AuthService), router = inject(Router), store = inject(Store)) =>
    actions$.pipe(
      ofType(UserActions.loginUser),
      tap((action: { payload: ICreateUser }) => {
        authService.storeLogin(action.payload).subscribe((user) =>
          store
            .pipe(select(authModalQuery.getModal))
            .pipe(take(1))
            .subscribe((modal) => {
              if (modal) {
                store.dispatch(AuthModalActions.closeAuthModal());
              }
              if (!siteComplete.userProfileCompleted(user)) {
                if (user.role === Role.guest) {
                  router.navigateByUrl(PATHS.signupGuest).then();
                } else if (user.role === Role.host) {
                  router.navigateByUrl(PATHS.signupStandard).then();
                } else if (user.role === Role.boondocker) {
                  router.navigateByUrl(PATHS.signupBoondock).then();
                } else {
                  router.navigateByUrl(PATHS.signupStart).then();
                }
              } else {
                router.navigate(['', 'auth', 'redirect']).then();
              }
            }),
        );
      }),
    ),
  { dispatch: false, functional: true },
);

export const loginSuccess = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AuthActions.loginSuccess),
      map((action) => {
        const user: IUser = action.user;
        if (user.role) {
          // Call to re-register push notification token.
          return MessageActions.registerPushNotifications();
        } else {
          return NGRX_NO_ACTION;
        }
      }),
    ),
  { functional: true },
);

export const recoverPassword$ = createEffect(
  (actions$ = inject(Actions), authService = inject(AuthService)) =>
    actions$.pipe(
      ofType(AuthActions.recoverPassword),
      switchMap((action) =>
        authService.recover(action.email).pipe(
          switchMap(() => [AuthActions.recoverPasswordSuccess({ email: action.email })]),
          catchError(() => [AuthActions.recoverPasswordSuccess({ email: action.email })]),
        ),
      ),
    ),
  { functional: true },
);

export const resendVerification$ = createEffect(
  (actions$ = inject(Actions), authService = inject(AuthService)) =>
    actions$.pipe(
      ofType(AuthActions.resendVerification),
      switchMap((action) =>
        authService.resendVerification(action.email).pipe(
          switchMap(() => [AuthActions.resendSuccess({ email: action.email })]),
          catchError(() => [AuthActions.resendError({ error: action.email })]),
        ),
      ),
    ),
  { functional: true },
);

export const resendSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AuthActions.resendSuccess),
      switchMap((action) => {
        return [
          AlertActions.displayAlert({
            title: 'Verification Email Sent',
            body: `A new verification email has been sent to ${action.email}. You should receive it soon.`,
          }),
        ];
      }),
    ),
  { functional: true },
);

export const resendError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AuthActions.resendError),
      switchMap((action) => {
        return [
          AlertActions.displayAlert({
            title: 'Unable to Send Verification Email',
            body: `Unable to send verification email to ${action.error}. Please verify email and try to log in again.`,
            level: 'error',
          }),
        ];
      }),
    ),
  { functional: true },
);

export const recoverPasswordSuccess$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(AuthActions.recoverPasswordSuccess),
      switchMap((action) => {
        router.navigate(['', 'auth', 'reset-password']).then();
        return [
          AuthModalActions.closeAuthModal(),
          AlertActions.displayAlert({
            title: 'Password Recovery Request Sent',
            body: `
              If account exists for ${action.email} an email with a verification code to reset your password has been sent.
              If you do not receive an email you may have signed up with Google, Facebook, or Apple.
            `,
            level: 'warning',
            noTimeout: true,
          }),
        ];
      }),
    ),
  { functional: true },
);

export const resetPassword$ = createEffect(
  (actions$ = inject(Actions), authService = inject(AuthService)) =>
    actions$.pipe(
      ofType(AuthActions.resetPassword),
      switchMap((action) =>
        authService.resetPassword(action.email, action.verificationCode, action.password).pipe(
          mergeMap(() => [
            AuthActions.resetPasswordSuccess({
              payload: action.verificationCode,
            }),
          ]),
          catchError((err) => [AuthActions.resetPasswordError({ error: err })]),
        ),
      ),
    ),
  { functional: true },
);

export const resetPasswordSuccess$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(AuthActions.resetPasswordSuccess),
      switchMap(() => {
        router.navigate([PATHS.login]).then();
        return [
          AlertActions.displayAlert({
            title: 'Password has been updated',
            body: 'Your password has been updated. You are now ready to login.',
          }),
        ];
      }),
    ),
  { functional: true },
);

export const recoverPasswordError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AuthActions.resetPasswordError),
      switchMap(() => {
        return [
          AlertActions.displayAlert({
            title: 'Invalid verification code',
            body: `Invalid verification code try re-entering the verification code or request a new one`,
            level: 'error',
          }),
        ];
      }),
    ),
  { functional: true },
);

export const newPassword$ = createEffect(
  (actions$ = inject(Actions), authService = inject(AuthService), store = inject(Store)) =>
    actions$.pipe(
      ofType(AuthActions.newPassword),
      withLatestFrom(store.select(AuthSelectors.getUser)),
      switchMap(([action, user]) =>
        authService.newPassword(action.password).pipe(
          mergeMap(() => {
            const actions: Action[] = [];
            if (action.autoLogin) {
              actions.push(AuthActions.newPasswordSuccess({ autoLogin: true, newPassword: action.password }));
            } else {
              actions.push(AuthActions.newPasswordSuccess({}));
            }
            if (action.autoLogin === true && user && user.email) {
              return authService.login(user.email, action.password).pipe(
                mergeMap((loginUser) => {
                  if (loginUser) {
                    return [...actions, AuthActions.loginSuccess({ user: new User(loginUser) })];
                  } else {
                    return [...actions, AuthActions.loginError({ error: '' })];
                  }
                }),
              );
            }
            return [...actions];
          }),
          catchError((err) => [AuthActions.newPasswordError({ error: err })]),
        ),
      ),
    ),
  { functional: true },
);

export const newPasswordSuccess$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router), store = inject(Store)) =>
    actions$.pipe(
      ofType(AuthActions.newPasswordSuccess),
      withLatestFrom(store.select(AuthSelectors.getAttemptEmail)),
      switchMap(([action, email]) => {
        store.dispatch(AuthModalActions.closeAuthModal());
        if (action.autoLogin && email && action.newPassword) {
          return [
            AlertActions.displayAlert({
              title: 'Password Changed',
              body: 'Your password has been updated.',
            }),
            AuthActions.login({
              email,
              password: action.newPassword,
            }),
          ];
        } else {
          router.navigate([PATHS.login]);
          return [
            AlertActions.displayAlert({
              title: 'Password has been set',
              body: 'Your password has been set. You are now ready to login.',
            }),
          ];
        }
      }),
    ),
  { functional: true },
);

export const login$ = createEffect(
  (actions$ = inject(Actions), authService = inject(AuthService), router = inject(Router), store = inject(Store)) =>
    actions$.pipe(
      ofType(AuthActions.login),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      exhaustMap(([action, status]) => {
        if (status?.connected === true) {
          return authService.login(action.email, action.password).pipe(
            switchMap((user) => {
              if (!user) {
                return of(NGRX_NO_ACTION);
              }

              const actions: Action[] = [
                AlertActions.displayAlert({
                  title: 'Logged In',
                  body: 'You have successfully logged in.',
                }),
              ];

              return store.pipe(select(authModalQuery.getModal)).pipe(
                take(1),
                switchMap((modal) => {
                  store.dispatch(AuthModalActions.closeAuthModal());
                  if (!user || !siteComplete.userProfileCompleted(user)) {
                    if (user) {
                      if (user.role === Role.guest) {
                        router.navigateByUrl(PATHS.signupGuest).then();
                      } else if (user.role === Role.host) {
                        router.navigateByUrl(PATHS.signupStandard).then();
                      } else if (user.role === Role.boondocker) {
                        router.navigateByUrl(PATHS.signupBoondock).then();
                      } else {
                        router.navigateByUrl(PATHS.signupStart).then();
                      }
                    } else {
                      router.navigateByUrl(PATHS.signupStart).then();
                    }
                  } else {
                    if (!modal) {
                      router.navigate(['', 'auth', 'redirect']).then();
                    }
                  }
                  return actions;
                }),
              );
            }),
            catchError((error) => [AuthActions.loginError({ error })]),
          );
        } else {
          const actions = [
            AlertActions.displayAlert({
              level: 'warning',
              title: 'Unable to Login',
              body: 'You must be connected to the internet to login.',
            }),
          ];
          return store.pipe(select(authModalQuery.getModal)).pipe(
            take(1),
            switchMap((modal) => {
              store.dispatch(AuthModalActions.closeAuthModal());
              if (!modal) {
                router.navigate(['']).then();
              }
              return actions;
            }),
          );
        }
      }),
    ),
  { functional: true },
);

export const loginError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AuthActions.loginError),
      map((action) => {
        let body = action.error;
        if (!body) {
          body = 'Please re-enter your email and password and try again';
        }
        return AlertActions.displayAlert({
          title: 'Unable to Login',
          body,
          level: 'error',
        });
      }),
    ),
  { functional: true },
);

export const newPasswordError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AuthActions.newPasswordError),
      map((action) => {
        let body = action.error;
        if (!body) {
          body = 'Unable to create password';
        }
        return AlertActions.displayAlert({
          title: 'Unable to Set New Password',
          body,
          level: 'error',
        });
      }),
      catchError((error) => [AuthActions.loginError({ error })]),
    ),
  { functional: true },
);

export const logout$ = createEffect(
  (actions$ = inject(Actions), authService = inject(AuthService), store = inject(Store), router = inject(Router)) =>
    actions$.pipe(
      ofType(AuthActions.logout),
      withLatestFrom(store.select(AuthSelectors.getAuthSession)),
      exhaustMap(([action, user]) => {
        if (!user) {
          throw new Error("Can't log out when no logged in user supplied");
        }
        return from(authService.logout(user)).pipe(
          mergeMap(() =>
            from(router.navigateByUrl('')).pipe(
              mergeMap(() => [AuthActions.logoutSuccess({ message: action.message })]),
            ),
          ),
        );
      }),
      catchError((error) => [AuthActions.logoutError({ error })]),
    ),
  { functional: true },
);

export const logoutSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AuthActions.logoutSuccess),
      exhaustMap((action) => {
        const actions: {
          type: string;
          payload?: {
            title: string;
            body: string;
            type?: string;
            disableTimeOut?: boolean;
          };
        }[] = [
          UserActions.currentUserUnloaded(),
          {
            type: ReservationActionTypes.EmptyReservations,
          },
          { type: StorageActionTypes.Clear },
        ];

        if (action.message) {
          actions.push(
            AlertActions.displayAlert({
              title: 'Logged Out',
              body: action.message,
            }),
          );
        }

        return actions;
      }),
      catchError((error) => [AuthActions.logoutError({ error })]),
    ),
  { functional: true },
);

export const logoutError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AuthActions.logoutError),
      mergeMap((action) => {
        return [
          LogActions.logMessage({
            log: {
              message: action.error,
              level: LogLevel.ERROR,
            },
          }),
        ];
      }),
    ),
  { functional: true },
);

export const updatePassword$ = createEffect(
  (actions$ = inject(Actions), authService = inject(AuthService)) =>
    actions$.pipe(
      ofType(AuthActions.updatePassword),
      switchMap((action) =>
        authService.updatePassword(action.oldPassword, action.newPassword).pipe(
          mergeMap(() => [
            AlertActions.displayAlert({
              title: 'Password has been updated',
              body: 'Your password has been updated.',
            }),
          ]),
          catchError((error) => [UserActions.userUpdateError({ error })]),
        ),
      ),
    ),
  { functional: true },
);
