import { LogLevel } from '../enums';
import { ILog, IUser } from '../interfaces';

export class Log implements ILog {
  id?: number;
  logSession?: string;
  userId?: number;
  user?: IUser;
  level: LogLevel;
  message: string;
  details?: string | Record<string, unknown>;
  trace?: string;
  subjectId?: string;
  subjectType?: string;
  created?: number;

  constructor(data?: ILog) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: ILog): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
