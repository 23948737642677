import { HttpErrorResponse } from '@angular/common/http';
import { ILatLon, IRoutePoint, ITrip, RoutePoint } from '@curbnturf/entities';
import { createAction, props } from '@ngrx/store';
import { DateTime } from 'luxon';

export const init = createAction('[TripPlanner Page] Init');

export const newTrip = createAction('[TripPlanner] New Trip', props<{ trip?: RoutePoint[] }>());

export const addRoutePoint = createAction(
  '[TripPlanner] Add Route Point',
  props<{ point: IRoutePoint; updateRoute?: boolean }>(),
);

export const reorderPoint = createAction(
  '[TripPlanner] Reorder Point',
  props<{ source: number; destination: number }>(),
);

export const removeRoutePoint = createAction(
  '[TripPlanner] Remove Route Point',
  props<{ point: IRoutePoint; updateRoute?: boolean }>(),
);

export const setPointDate = createAction('[TripPlanner] Set Point Date', props<{ point: IRoutePoint }>());

export const setPointActivity = createAction(
  '[TripPlanner] Set Point Activity',
  props<{ point: IRoutePoint; active: boolean }>(),
);

export const updateRoutePoint = createAction('[TripPlanner] Update Route Point', props<{ point: IRoutePoint }>());

export const setCorridor = createAction('[TripPlanner] Set Corridor', props<{ range: number }>());

export const setRoute = createAction('[TripPlanner] Set Route Path', props<{ route: ILatLon[] | undefined }>());

export const plotRoute = createAction('[TripPlanner] Plot Route Path');

export const setTripPlannerPanelState = createAction(
  '[TripPlanner] Set Trip Planner Panel State',
  props<{ open: boolean }>(),
);

export const setBuffer = createAction(
  '[TripPlanner] Set Buffer Polygon Path',
  props<{ buffer: ILatLon[] | undefined }>(),
);

export const setDistance = createAction(
  '[TripPlanner] Set Total Distance of Path',
  props<{ distance: number | undefined }>(),
);

export const setDates = createAction(
  '[TripPlanner] Set Dates',
  props<{ point: IRoutePoint; arrival: DateTime; departure: DateTime }>(),
);

export const updateRoute = createAction('[TripPlanner] Update Route Plot', props<{ showLoader?: boolean }>());

export const saveTrip = createAction('[TripPlanner] Save Trip', props<{ name: string; rvId?: number }>());

export const saveTripSuccess = createAction('[TripPlanner] Save Trip Success', props<{ trip: ITrip }>());

export const saveTripFailed = createAction('[TripPlanner] Save Trip Failed', props<{ error: HttpErrorResponse }>());

export const loadTrips = createAction('[TripPlanner] Load Trips', props<{ userId?: number }>());

export const loadTripsSuccess = createAction('[TripPlanner] Load Trips Success', props<{ trips: ITrip[] }>());

export const loadTripsFailed = createAction('[TripPlanner] Load Trips Failed', props<{ error: HttpErrorResponse }>());

export const clearPointDates = createAction('[TripPlanner] Clear Route Point Dates', props<{ point: IRoutePoint }>());
