import { IWaypoint } from '../interfaces';
import { LatLon } from './coordinates';

export class Waypoint extends LatLon implements IWaypoint {
  name?: string;

  constructor(data?: IWaypoint) {
    super(data);
  }

  setData(data: IWaypoint): void {
    super.setData(data);
    this.name = data.name;
  }
}
