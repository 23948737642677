import { inject } from '@angular/core';
import { ICalendarDay, IReservation } from '@curbnturf/entities';
import * as UserActions from '@curbnturf/user/src/lib/+state/user.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OrderService } from '../order.service';
import { OrderActionTypes, RequestDays, RequestPrice } from './order.actions';

export const requestPrice$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), orderService = inject(OrderService)) =>
    actions$.pipe(
      ofType(OrderActionTypes.RequestPrice),
      switchMap((action: RequestPrice) => {
        // reload the current user to allow updating the CNT Cash available to the user so they aren't surprised by the actual costs
        store.dispatch(UserActions.reloadCurrentUser());

        return orderService.requestPrice(action.payload).pipe(
          map((reservation: IReservation) => ({
            type: OrderActionTypes.PriceFilled,
            payload: reservation,
          })),
          catchError((err) =>
            of({
              type: OrderActionTypes.PriceError,
              payload: err,
            }),
          ),
        );
      }),
    ),
  { functional: true },
);

export const requestDays$ = createEffect(
  (actions$ = inject(Actions), orderService = inject(OrderService)) =>
    actions$.pipe(
      ofType(OrderActionTypes.RequestDays),
      switchMap((action: RequestDays) =>
        orderService.requestDays(action.payload, action.reservationId).pipe(
          map((days: ICalendarDay[]) => ({
            type: OrderActionTypes.DaysFilled,
            payload: days,
          })),
          catchError((err) =>
            of({
              type: OrderActionTypes.OrderError,
              payload: err,
            }),
          ),
        ),
      ),
    ),
  { functional: true },
);