<ion-menu type="reveal" contentId="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-title>Admin Sections</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <ion-item lines="full" detail="true" [routerLink]="['', 'users']">
        <ion-icon slot="start" name="person" color="orange"></ion-icon>
        <ion-label>Users</ion-label>
      </ion-item>
      <ion-item lines="full" detail="true" [routerLink]="['', 'reservations']">
        <ion-icon slot="start" name="ticket" color="orange"></ion-icon>
        <ion-label>Reservations</ion-label>
      </ion-item>
      <ion-item lines="full" detail="true" [routerLink]="['', 'sites']">
        <ion-icon slot="start" name="location" color="orange"></ion-icon>
        <ion-label>Private</ion-label>
      </ion-item>
      <ion-item lines="full" detail="true" [routerLink]="['', 'affiliates']">
        <ion-icon slot="start" name="people" color="orange"></ion-icon>
        <ion-label>Affiliates</ion-label>
      </ion-item>
      <ion-item lines="full" detail="true" [routerLink]="['', 'sites', 'boondock']">
        <ion-icon slot="start" name="bonfire" color="orange"></ion-icon>
        <ion-label>Boondocking</ion-label>
      </ion-item>
      <ion-item lines="full" detail="true" [routerLink]="['', 'pois']">
        <ion-icon name="pin" slot="start" color="orange"></ion-icon>
        <ion-label>POIs</ion-label>
      </ion-item>
      <ion-item lines="full" detail="true" [routerLink]="['', 'log']">
        <ion-icon slot="start" name="server" color="orange"></ion-icon>
        <ion-label>Logs</ion-label>
      </ion-item>
      <ion-item lines="full" detail="true" [routerLink]="['', 'rating', 'caretaker']">
        <ion-icon slot="start" name="key" color="orange"></ion-icon>
        <ion-label>Caretakers</ion-label>
      </ion-item>
      <ion-item lines="full" detail="true" [routerLink]="['', 'search']">
        <ion-icon name="search" slot="start" color="orange"></ion-icon>
        <ion-label>Search</ion-label>
      </ion-item>
      <ion-item lines="full" detail="true" [routerLink]="['', 'reports']">
        <ion-icon slot="start" name="stats-chart" color="orange"></ion-icon>
        <ion-label>Reports</ion-label>
      </ion-item>
      <ion-item lines="full" detail="true" [routerLink]="['', 'payouts']">
        <ion-icon name="wallet" slot="start" color="orange"></ion-icon>
        <ion-label>Payouts</ion-label>
      </ion-item>
      <ion-item lines="full" detail="true" [routerLink]="['', 'articles']">
        <ion-icon slot="start" name="albums" color="orange"></ion-icon>
        <ion-label>Articles</ion-label>
      </ion-item>
      <ion-item lines="full" detail="true" [routerLink]="['', 'email-template']">
        <ion-icon slot="start" name="mail" color="orange"></ion-icon>
        <ion-label>Email Templates</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>

<curbnturf-auth-modals></curbnturf-auth-modals>
