<curbnturf-admin-header></curbnturf-admin-header>
<div class="page" id="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title>CurbNTurf Admin</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="logout()">
          <ion-label> Log Out </ion-label>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>

  </ion-header>
  <ion-content fullscreen class="ion-padding">
    <router-outlet></router-outlet>
  </ion-content>
</div>
