import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { defer, from, of } from 'rxjs';
import { catchError, debounceTime, filter, map, skipWhile, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { StorageService } from '../storage.service';
import { StorageActionTypes } from './storage.actions';
import { storageOptions } from '../storage.options';

export const storeState$ = createEffect(
  (
    actions$ = inject(Actions),
    storageService = inject(StorageService),
    store = inject(Store),
    platformId = inject(PLATFORM_ID),
  ) =>
    actions$.pipe(
      // don't store anything until after the storage has been hydrated then store all actions
      skipWhile((action) => action.type !== StorageActionTypes.Hydrated),
      filter((action) => storageOptions.ignoreActions.indexOf(action.type) === -1),
      debounceTime(isPlatformServer(platformId) ? 0 : 1000),
      withLatestFrom(store.pipe(select((state) => state))),
      tap(([action, state]) => {
        if (!isPlatformServer(platformId)) {
          storageService.saveState(state, action.type).catch((err) => console.log('Error storing state', err));
        }
      }),
    ),
  { dispatch: false, functional: true },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hydrate$ = createEffect(
  (storageService = inject(StorageService)) =>
    defer(() =>
      from(storageService.fetchState()).pipe(
        map((state) => {
          return {
            type: StorageActionTypes.Hydrated,
            payload: state,
          };
        }),
        catchError((e) => {
          console.warn(`error fetching data from store for hydration: ${e}`);

          return of({
            type: StorageActionTypes.Hydrated,
            payload: {},
          });
        }),
      ),
    ),
  { functional: true },
);

export const clear$ = createEffect(
  (actions$ = inject(Actions), storageService = inject(StorageService)) =>
    actions$.pipe(
      ofType(StorageActionTypes.Clear),
      switchMap(() =>
        from(storageService.clear()).pipe(
          map(() => {
            return { type: StorageActionTypes.Cleared };
          }),
        ),
      ),
    ),
  { functional: true },
);
