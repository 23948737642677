import { Injectable } from '@angular/core';
import {
  BASE_API_URL,
  DEFAULT_MAP_LATITUDE,
  DEFAULT_MAP_LONGITUDE,
  ITrip,
  LatLon,
  Trip,
  TripRoutePoint,
} from '@curbnturf/entities';
import { CachedHttpClient } from '@curbnturf/network';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const API_URL = BASE_API_URL + 'route';

@Injectable({
  providedIn: 'root',
})
export class TripPlannerService {
  constructor(private http: CachedHttpClient) {}

  saveTrip(trip: ITrip): Observable<ITrip> {
    return this.http.post<ITrip>(`${API_URL}/trip`, trip);
  }

  getTrips(userId?: number): Observable<Trip[]> {
    let userString: string = '';
    if (userId) {
      userString = `?userId=${userId}`;
    }
    return this.http.get<ITrip[]>(`${API_URL}/trip${userString}`).pipe(
      map((results: ITrip[]) =>
        results.map(
          (trip) =>
            new Trip({
              ...trip,
              route: trip.route.map(
                (point) =>
                  new TripRoutePoint({
                    ...point,
                    coordinates: new LatLon({
                      lat:
                        typeof point.coordinates?.lat === 'string'
                          ? parseFloat(point.coordinates?.lat || '0')
                          : point.coordinates?.lat || DEFAULT_MAP_LATITUDE,
                      lon:
                        typeof point.coordinates?.lon === 'string'
                          ? parseFloat(point.coordinates?.lon || '0')
                          : point.coordinates?.lon || DEFAULT_MAP_LONGITUDE,
                    }),
                    arrival: typeof point.arrival === 'string' ? DateTime.fromISO(point.arrival) : undefined,
                    departure: typeof point.departure === 'string' ? DateTime.fromISO(point.departure) : undefined,
                  }),
              ),
            }),
        ),
      ),
    );
  }
}
