export enum PhotoSizes {
  ThreeXFourLarge = '3x4Large',
  ThreeXFourMedium = '3x4Medium',
  ThreeXFourSmall = '3x4Small',
  ThreeXFourThumbnail = '3x4Thumbnail',
  Medium = 'Medium',
  FlatMedium = 'FlatMedium',
  FlatSmall = 'FlatSmall',
  FlatThumbnail = 'FlatThumbnail',
  SquareMedium = 'SquareMedium',
  SquareSmall = 'SquareSmall',
  SquareThumbnail = 'SquareThumbnail',
  SquareTiny = 'SquareTiny',
  Original = 'Original',
  FullSize = 'FullSize',
}

export const PhotoSizeArray = [
  '3x4Large',
  '3x4Medium',
  '3x4Small',
  '3x4Thumbnail',
  'Medium',
  'FlatMedium',
  'FlatSmall',
  'FlatThumbnail',
  'SquareMedium',
  'SquareSmall',
  'SquareThumbnail',
  'SquareTiny',
  'Original',
];
