import { inject } from '@angular/core';
import { Router } from '@angular/router';
import * as AlertActions from '@curbnturf/alert/src/lib/+state/alert.actions';
import { CDate, IReservation, NGRX_NO_ACTION, PATHS } from '@curbnturf/entities';
import { stringFromError } from '@curbnturf/form-helpers';
import { Logger } from '@curbnturf/network';
import * as UserSelectors from '@curbnturf/user/src/lib/+state/user.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { ReservationService } from '../reservation.service';
import * as ReservationActions from './reservation.actions';
import { ReservationActionTypes } from './reservation.actions';

export const loadReservation$ = createEffect(
  (actions$ = inject(Actions), reservationService = inject(ReservationService)) =>
    actions$.pipe(
      ofType(ReservationActionTypes.LoadReservation),
      switchMap((action: ReservationActions.LoadReservation) => {
        // TODO: check if it is already loaded before loading it again
        // if a reservationId is provided load that
        if (!action.payload) {
          return of({
            type: ReservationActionTypes.ReservationLoadError,
            payload: 'Missing Reservation ID',
          });
        }

        return reservationService.fetch(action.payload).pipe(
          mergeMap((reservation: IReservation) => [
            {
              type: ReservationActionTypes.ReservationLoaded,
              payload: reservation,
            },
            // if we are only loading a single reservation we will also select it
            {
              type: ReservationActionTypes.SelectReservation,
              payload: reservation,
            },
          ]),
          catchError((err) =>
            of({
              type: ReservationActionTypes.ReservationLoadError,
              payload: err,
            }),
          ),
        );
      }),
    ),
  { functional: true },
);

export const loadGuestReservations$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), reservationService = inject(ReservationService)) =>
    actions$.pipe(
      ofType(ReservationActionTypes.LoadGuestReservations),
      switchMap(() =>
        store.pipe(select(UserSelectors.getCurrentId)).pipe(
          filter((userId) => !!userId),
          take(1),
          mergeMap((userId) => {
            if (!userId) {
              return of({
                type: ReservationActionTypes.ReservationLoadError,
                payload: 'Missing User ID',
              });
            }

            return reservationService.fetchForGuest(userId).pipe(
              map((reservations: IReservation[]) => ({
                type: ReservationActionTypes.ReservationsLoaded,
                payload: reservations,
              })),
              catchError((err) =>
                of({
                  type: ReservationActionTypes.ReservationLoadError,
                  payload: err,
                }),
              ),
            );
          }),
        ),
      ),
    ),
  { functional: true },
);

export const loadHostReservations$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), reservationService = inject(ReservationService)) =>
    actions$.pipe(
      ofType(ReservationActionTypes.LoadHostReservations),
      switchMap(() =>
        store.pipe(select(UserSelectors.getCurrentId)).pipe(
          filter((userId) => !!userId),
          take(1),
          mergeMap((userId) => {
            if (!userId) {
              return of({
                type: ReservationActionTypes.ReservationLoadError,
                payload: 'Missing User ID',
              });
            }

            return reservationService.fetchForHost(userId).pipe(
              map((reservations: IReservation[]) => ({
                type: ReservationActionTypes.ReservationsLoaded,
                payload: reservations,
              })),
              catchError((err) =>
                of({
                  type: ReservationActionTypes.ReservationLoadError,
                  payload: err,
                }),
              ),
            );
          }),
        ),
      ),
    ),
  { functional: true },
);

export const loadAllReservations$ = createEffect(
  (actions$ = inject(Actions), reservationService = inject(ReservationService)) =>
    actions$.pipe(
      ofType(ReservationActionTypes.LoadAllReservations),
      switchMap((action: ReservationActions.LoadAllReservations) =>
        reservationService.fetchAll(action.payload).pipe(
          map((reservations: IReservation[]) => ({
            type: ReservationActionTypes.ReservationsLoaded,
            payload: reservations,
          })),
          catchError((err) =>
            of({
              type: ReservationActionTypes.ReservationLoadError,
              payload: err,
            }),
          ),
        ),
      ),
    ),
  { functional: true },
);

export const createReservation$ = createEffect(
  (actions$ = inject(Actions), reservationService = inject(ReservationService)) =>
    actions$.pipe(
      ofType(ReservationActionTypes.CreateReservation),
      switchMap((action: ReservationActions.CreateReservation) =>
        reservationService.create(action.payload).pipe(
          mergeMap((reservation: IReservation) => {
            const dates = new CDate();
            if (action.payload.date) {
              if (action.payload.date.from) {
                dates.from = action.payload.date.from;
              }
              if (action.payload.date.to) {
                dates.to = action.payload.date.to;
              }
            }

            return [
              {
                type: ReservationActionTypes.ReservationLoaded,
                payload: reservation,
              },
              {
                type: ReservationActionTypes.SelectReservation,
                payload: reservation,
              },
              AlertActions.displayAlert({
                title: 'Reservation Made',
                body: `Reservation made for ${dates}.`,
              }),
            ];
          }),
          catchError((err) =>
            of({
              type: ReservationActionTypes.ReservationLoadError,
              payload: err,
            }),
          ),
        ),
      ),
    ),
  { functional: true },
);

export const acceptReservation$ = createEffect(
  (actions$ = inject(Actions), reservationService = inject(ReservationService)) =>
    actions$.pipe(
      ofType(ReservationActionTypes.AcceptReservation),
      switchMap((action: ReservationActions.AcceptReservation) => {
        if (!action.payload || !action.payload.reservation || !action.payload.reservation.id) {
          return of({
            type: ReservationActionTypes.ReservationLoadError,
            payload: 'Missing Reservation ID',
          });
        }

        return reservationService.accept(action.payload.reservation.id, action.payload.reason).pipe(
          map((reservation: IReservation) => ({
            type: ReservationActionTypes.ReservationLoaded,
            payload: reservation,
          })),
          catchError((err) =>
            from([
              {
                type: ReservationActionTypes.ReservationLoadError,
                payload: err,
              },
              // Attempt to reload the reservation to get an update on the status
              {
                type: ReservationActionTypes.LoadReservation,
                payload: action.payload.reservation.id,
              },
            ]),
          ),
        );
      }),
    ),
  { functional: true },
);

export const rejectReservation$ = createEffect(
  (actions$ = inject(Actions), reservationService = inject(ReservationService)) =>
    actions$.pipe(
      ofType(ReservationActionTypes.RejectReservation),
      switchMap((action: ReservationActions.RejectReservation) => {
        if (!action.payload || !action.payload.reservation || !action.payload.reservation.id) {
          return of({
            type: ReservationActionTypes.ReservationLoadError,
            payload: 'Missing Reservation ID',
          });
        }

        return reservationService.reject(action.payload.reservation.id, action.payload.reason).pipe(
          map((reservation: IReservation) => ({
            type: ReservationActionTypes.ReservationLoaded,
            payload: reservation,
          })),
          catchError((err) =>
            of({
              type: ReservationActionTypes.ReservationLoadError,
              payload: err,
            }),
          ),
        );
      }),
    ),
  { functional: true },
);

export const cancelReservation$ = createEffect(
  (actions$ = inject(Actions), reservationService = inject(ReservationService)) =>
    actions$.pipe(
      ofType(ReservationActionTypes.CancelReservation),
      switchMap((action: ReservationActions.CancelReservation) => {
        if (!action.payload || !action.payload.reservation || !action.payload.reservation.id) {
          return of({
            type: ReservationActionTypes.ReservationLoadError,
            payload: 'Missing Reservation ID',
          });
        }

        return reservationService.cancel(action.payload.reservation.id, action.payload.reason).pipe(
          map((reservation: IReservation) => ({
            type: ReservationActionTypes.ReservationLoaded,
            payload: reservation,
          })),
          catchError((err) =>
            of({
              type: ReservationActionTypes.ReservationLoadError,
              payload: err,
            }),
          ),
        );
      }),
    ),
  { functional: true },
);

export const loadReservationFailed$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(ReservationActionTypes.ReservationLoadError),
      map((action: ReservationActions.ReservationLoadError) => {
        const err = action.payload;

        if (err?.error?.statusCode === 403) {
          router.navigateByUrl(PATHS.trips);
        }

        return AlertActions.displayAlert({
          title: 'Reservation Error',
          body: stringFromError(action.payload),
          level: 'error',
        });
      }),
    ),
  { functional: true },
);

export const changeReservation$ = createEffect(
  (actions$ = inject(Actions), reservationService = inject(ReservationService)) =>
    actions$.pipe(
      ofType(ReservationActions.changeReservation),
      mergeMap((action) => {
        if (action.reservation.id) {
          return reservationService.change(action.reservation.id, action.order).pipe(
            map((reservation) => {
              if (reservation.id) {
                return ReservationActions.changeReservationSuccess({ reservation });
              } else {
                return ReservationActions.changeReservationFailure({
                  error: 'Successful return, but not ID value returned.',
                });
              }
            }),
            catchError((error) => {
              return [ReservationActions.changeReservationFailure({ error })];
            }),
          );
        } else {
          return of(NGRX_NO_ACTION);
        }
      }),
    ),
  { functional: true },
);

export const changeReservationFailure$ = createEffect(
  (actions$ = inject(Actions), logger = inject(Logger)) =>
    actions$.pipe(
      ofType(ReservationActions.changeReservationFailure),
      map((error) => {
        logger.error('Failed to change reservation', { error });
        return AlertActions.displayAlert({
          level: 'error',
          title: '',
          body: '',
        });
      }),
    ),
  { functional: true },
);

export const changeReservationSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(ReservationActions.changeReservationSuccess),
      map((action) => {
        return ReservationActions.reservationLoaded({ payload: action.reservation });
      }),
    ),
  { functional: true },
);
