import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import * as AlertActions from '@curbnturf/alert/src/lib/+state/alert.actions';
import {
  DEFAULT_MAP_LATITUDE,
  DEFAULT_MAP_LONGITUDE,
  HERE_API_KEY,
  LatLon,
  LogLevel,
  NGRX_NO_ACTION,
} from '@curbnturf/entities';
import { Logger } from '@curbnturf/network';
import * as LogActions from '@curbnturf/network/src/lib/log/+state/log.actions';
import * as UserSelectors from '@curbnturf/user/src/lib/+state/user.selectors';
import H from '@here/maps-api-for-javascript';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, debounceTime, mergeMap, withLatestFrom } from 'rxjs/operators';
import { GeolocationCapacitorService } from '../geolocation-capacitor.service';
import * as LocationActions from './location.actions';

const DEFAULT_GEOLOCATION_TIMEOUT = 10000;

export const findCurrentLocation$ = createEffect(
  (actions$ = inject(Actions), geolocation = inject(GeolocationCapacitorService), platformId = inject(PLATFORM_ID)) =>
    actions$.pipe(
      ofType(LocationActions.findCurrentLocation),
      debounceTime(isPlatformServer(platformId) ? 0 : 1000),
      mergeMap((action) =>
        from(
          geolocation.getCurrentPosition({
            timeout: action.timeout || DEFAULT_GEOLOCATION_TIMEOUT,
            enableHighAccuracy: action.highAccuracy || false,
          }),
        ).pipe(
          mergeMap((location) => {
            if (location.coords) {
              const lat = location.coords.latitude || DEFAULT_MAP_LATITUDE;
              const lon = location.coords.longitude || DEFAULT_MAP_LONGITUDE;
              return [LocationActions.currentLocationSuccess({ location: new LatLon({ lat, lon }) })];
            } else {
              return [
                AlertActions.displayAlert({
                  body: '',
                  title: '',
                }),
                LocationActions.currentLocationFailed({ error: 'Failed to return a location.' }),
              ];
            }
          }),
        ),
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catchError((err: any) => {
        const error: { code?: number } = err;

        // GeolocationPositionError.TIMEOUT
        if (error?.code === 1) {
          return [
            AlertActions.displayAlert({
              title: 'Unable to Find Your Location',
              body: 'Request for location timed out.',
              level: 'warning',
            }),
            LogActions.logMessage({
              log: {
                level: LogLevel.WARNING,
                message: 'Location Retrieval Error',
                details: { message: 'Geolocation Position Error. The request timed out.', error },
              },
            }),
            LocationActions.currentLocationFailed({ error }),
          ];

          //GeolocationPositionError.POSITION_UNAVAILABLE
        } else if (error?.code === 2) {
          return [
            AlertActions.displayAlert({
              title: 'Unable to Find Your Location',
              body: 'Location currently unavailable, trying again.',
              level: 'warning',
            }),
            LogActions.logMessage({
              log: {
                level: LogLevel.WARNING,
                message: 'Location Retrieval Error',
                details: {
                  message: 'Geolocation Position Error. Location currently unavailable, trying again.',
                  error,
                },
              },
            }),
            LocationActions.currentLocationFailed({ error }),
          ];
          // GeolocationPositionError.PERMISSION_DENIED
        } else if (error?.code === 3) {
          return [
            AlertActions.displayAlert({
              body: "You have not granted location permission. We won't be able to find sites close to you.",
              title: 'Location Permission Denied',
              level: 'warning',
            }),
            LogActions.logMessage({
              log: {
                level: LogLevel.INFO,
                message: 'Location Retrieval Error',
                details: {
                  message: 'Position Permission Denied, Attempting Reverse Address Geocode.',
                  error,
                },
              },
            }),
            LocationActions.reverseGeocodeUserAddress(),
          ];
        } else {
          return [
            LogActions.logMessage({
              log: {
                level: LogLevel.ERROR,
                message: 'Location Retrieval Error',
                details: {
                  message: 'Unknown Error, using reverse geocoding.',
                  error,
                },
              },
            }),
            LocationActions.reverseGeocodeUserAddress(),
          ];
        }
      }),
    ),
  { functional: true },
);

export const reverseGeocode$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), logger = inject(Logger)) =>
    actions$.pipe(
      ofType(LocationActions.reverseGeocodeUserAddress),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([action, user]) => {
        if (user) {
          if (user.address) {
            const address = user.address;
            const searchText = address.address + ', ' + address.city + ', ' + address.state + ' ' + address.zipcode;

            const platform: H.service.Platform = new H.service.Platform({
              apikey: HERE_API_KEY,
            });

            const searchService = platform.getSearchService();

            // Create the parameters for the geocoding request:
            const geocodingParams = {
              searchText,
            };

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const resultCallback = (result: any) => {
              if (
                result?.Response &&
                result?.Response.View[0].Result[0] &&
                result?.Response.View[0].Result[0].Location.DisplayPosition.Latitude &&
                result?.Response.View[0].Result[0].Location.DisplayPosition.Longitude
              ) {
                const lat = result?.Response.View[0].Result[0].Location.DisplayPosition.Latitude;
                const lon = result?.Response.View[0].Result[0].Location.DisplayPosition.Longitude;

                logger.debug('Setting Location from reverse geocode.', { lat, lon });

                store.dispatch(LocationActions.reverseGeocodeSuccess({ location: new LatLon({ lat, lon }) }));
              }
            };

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const errorCallback = (error: any) => {
              store.dispatch(LocationActions.reverseGeocodeFailed({ error }));
            };

            // Geocode the search params.
            searchService.geocode(geocodingParams, resultCallback, errorCallback);

            // Since the Here Maps API uses callbacks, we return no action here and then dispatch new actions from the callbacks.
            return [NGRX_NO_ACTION];
          } else {
            logger.warning('User has not provided an address to reverse Geocode.');

            return [
              LocationActions.reverseGeocodeFailed({ error: 'User has not provided an address to reverse Geocode.' }),
            ];
          }
        } else {
          return [
            LogActions.logMessage({
              log: {
                level: LogLevel.INFO,
                message: 'User is not logged in, no address to reverse Geocode.',
              },
            }),
          ];
        }
      }),
    ),
  { functional: true },
);
