import { ISubPhoto } from '@curbnturf/entities';
import { DbEntity } from './db-entity';

export interface ISitePhotoEntity extends ISubPhoto {
  id?: number | null;
  site_id: number;
  key: string;
  caption?: string;
}

export class SitePhotoEntity extends DbEntity implements ISitePhotoEntity {
  // id?: number;
  site_id: number;
  key: string;
  caption?: string;

  constructor(data?: ISitePhotoEntity) {
    super();
    if (data) {
      this.setData(data);
    }
  }

  setData(data: ISitePhotoEntity): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
