import { DateTime } from 'luxon';
import { DayTemplateContext } from './datepicker-day-template-context';

export type IsDisabled = (date: DateTime) => boolean;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DatePickerDayTemplateData = (date: DateTime, current: { year?: number; month?: number }) => any;

export interface DayViewModel {
  date: DateTime;
  context: DayTemplateContext;
  tabindex: number;
  ariaLabel: string;
  hidden: boolean;
}

export interface WeekViewModel {
  number: number;
  days: DayViewModel[];
  collapsed: boolean;
}

export interface MonthViewModel {
  firstDate?: DateTime;
  lastDate?: DateTime;
  number?: number;
  year?: number;
  weeks: WeekViewModel[];
  weekdays: number[];
}

// clang-format off
export interface DatepickerViewModel {
  dayTemplateData?: DatePickerDayTemplateData;
  disabled?: boolean;
  displayMonths?: number;
  firstDate?: DateTime;
  focusDate?: DateTime;
  focusVisible?: boolean;
  lastDate?: DateTime;
  isDisabled?: IsDisabled;
  maxDate?: DateTime;
  minDate?: DateTime;
  months: (MonthViewModel | undefined)[];
  navigation?: 'select' | 'arrows' | 'none';
  outsideDays?: 'visible' | 'collapsed' | 'hidden';
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  selectBoxes?: {
    years: number[];
    months: number[];
  };
  selectedDate?: DateTime;
}
// clang-format on

export enum NavigationEvent {
  PREV,
  NEXT,
}
