import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RvTypes } from '@curbnturf/entities';
import { IRvForm } from '@curbnturf/form-helpers';
import { DisplayValidationErrorsSubmitDirective } from '../../validation-errors/display-validation-errors-submit.directive';
import { DisplayValidationErrorsDirective } from '../../validation-errors/display-validation-errors/display-validation-errors.directive';

@Component({
  selector: 'curbnturf-guest-rv-selector',
  templateUrl: './guest-rv-selector.component.html',
  styleUrls: ['./guest-rv-selector.component.scss'],
  standalone: true,
  imports: [
    DisplayValidationErrorsDirective,
    DisplayValidationErrorsSubmitDirective,
    FormsModule,
    NgIf,
    ReactiveFormsModule,
  ],
})
export class GuestRvSelectorComponent {
  @Input() rvForm: FormGroup<IRvForm>;

  RvTypes = RvTypes;

  get type() {
    if (this.rvForm) {
      return this.rvForm.get('type');
    }

    return;
  }

  selectedType(type: string): boolean {
    if (!this.type) {
      return false;
    }

    return this.type.value === type;
  }

  onSelect($event: MouseEvent) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (this.type?.value === ($event?.target as any)?.value) {
      this.type?.setValue(null);
      this.type?.markAsPristine();
      this.type?.markAsUntouched();
    }
  }
}
