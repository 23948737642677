import { Injectable } from '@angular/core';
import { BASE_API_URL, ILatLon, IUser, IUserCustomLocation } from '@curbnturf/entities';
import { CachedHttpClient } from '@curbnturf/network';
import { Observable } from 'rxjs';
import { UserFacade } from './+state/user.facade';
const API_URL = BASE_API_URL + 'user/locations';

@Injectable({
  providedIn: 'root',
})
export class UserCustomLocationService {
  private user: IUser;
  constructor(
    private http: CachedHttpClient,
    private userFacade: UserFacade,
  ) {
    this.userFacade.currentUser$.subscribe((user) => {
      this.user = user;
    });
  }

  create(name: string, coordinates: ILatLon): Observable<IUserCustomLocation> {
    if (this.user) {
      return this.http.post<IUserCustomLocation>(`${API_URL}`, { name, coordinates, user: this.user });
    } else {
      throw new Error('Cannot create a new location without a current user.');
    }
  }

  fetchAll(): Observable<IUserCustomLocation[]> {
    return this.http.get<IUserCustomLocation[]>(`${API_URL}`);
  }

  remove(point: IUserCustomLocation): Observable<IUserCustomLocation> {
    return this.http.delete<IUserCustomLocation>(`${API_URL}/${point.id}`);
  }

  update(point: IUserCustomLocation): Observable<IUserCustomLocation> {
    return this.http.put<IUserCustomLocation>(`${API_URL}/${point.id}`, point);
  }
}
