import { HttpErrorResponse } from '@angular/common/http';
import { ILatLon, IMapPoint, ListingSearchQuery, MapPoint, Site } from '@curbnturf/entities';
import { createAction, props } from '@ngrx/store';
import { IFilters } from '../filters.interface';

export enum ListingSearchActionTypes {
  RunListingSearch = '[ListingSearch] Run ListingSearch',
  ListingSearchCompleted = '[ListingSearch] ListingSearch Completed',
  ListingSearchError = '[ListingSearch] ListingSearch Error',
  RunListingTypeahead = '[ListingSearch] Run Listing Typeahead',
  ListingTypeaheadCompleted = '[ListingSearch] Listing Typeahead Completed',
  ListingTypeaheadCleared = '[ListingSearch] Listing Typeahead Cleared',
  PointDisplayLoaded = '[ListingSearch] Loaded Point for Display',
  RetrieveUserPointList = '[ListingSearch] Retrieve User Point List',
  RetrieveUserPointListSuccess = '[ListingSearch] Retrieve User Point List Success',
  RetrieveUserPointListFailed = '[ListingSearch] Retrieve User Point List Failed',
  AddUserPoint = '[ListingSearch] Add User Point',
  UpdateUserPoint = '[ListingSearch] Update User Point',
  UpdateUserPointSuccess = '[ListingSearch] Update User Point Success',
  UpdateUserPointFailed = '[ListingSearch] Update User Point Failed',
  RemoveUserPoint = '[ListingSearch] Remove User Point',
  RemoveUserPointSuccess = '[ListingSearch] Remove User Point Success',
  RemoveUserPointFailed = '[ListingSearch] Remove User Point Failed',
  SelectPoint = '[ListingSearch] Select Map Point',
  SelectPointById = '[ListingSearch] Select Map Point By ID',
  SelectSiteById = '[ListingSearch] Select Site By ID',
  PointDisplaySelected = '[ListingSearch] Map Point Selected',
  SiteDisplaySelected = '[ListingSearch] Site Display Selected',
  SetFilters = '[ListingSearch] Set Filters',
  SetSearchMapCenter = '[ListingSearch] Set Search Map Center',
  SetSearchMapZoom = '[ListingSearch] Set Search Map Zoom',
  SetSearchMapHeading = '[ListingSearch] Set Search Map Heading',
  SetSearchListOpen = '[ListingSearch] Set Search List Open',
}

export const runListingSearch = createAction(
  ListingSearchActionTypes.RunListingSearch,
  props<{ query: ListingSearchQuery }>(),
);

export const listingSearchError = createAction(
  ListingSearchActionTypes.ListingSearchError,
  props<{ error: HttpErrorResponse }>(),
);

export const listingSearchCompleted = createAction(
  ListingSearchActionTypes.ListingSearchCompleted,
  props<{ results: MapPoint[] }>(),
);

export const runListingTypeahead = createAction(
  ListingSearchActionTypes.RunListingTypeahead,
  props<{ query: ListingSearchQuery }>(),
);

export const listingTypeaheadCompleted = createAction(
  ListingSearchActionTypes.ListingTypeaheadCompleted,
  props<{ results: MapPoint[] }>(),
);

export const listingTypeaheadCleared = createAction(ListingSearchActionTypes.ListingTypeaheadCleared);

export const pointDisplayLoaded = createAction(
  ListingSearchActionTypes.PointDisplayLoaded,
  props<{ point: IMapPoint }>(),
);

export const retrieveUserPointList = createAction(ListingSearchActionTypes.RetrieveUserPointList);

export const retrieveUserPointListSuccess = createAction(
  ListingSearchActionTypes.RetrieveUserPointListSuccess,
  props<{ points: MapPoint[] }>(),
);

export const retrieveUserPointListFailed = createAction(
  ListingSearchActionTypes.RetrieveUserPointListFailed,
  props<{ error: any }>(),
);

export const addUserPoint = createAction(ListingSearchActionTypes.AddUserPoint, props<{ point: MapPoint }>());

export const updateUserPoint = createAction(ListingSearchActionTypes.UpdateUserPoint, props<{ point: MapPoint }>());

export const updateUserPointSuccess = createAction(
  ListingSearchActionTypes.UpdateUserPointSuccess,
  props<{ point: MapPoint }>(),
);

export const updateUserPointFailed = createAction(
  ListingSearchActionTypes.UpdateUserPointFailed,
  props<{ error: any }>(),
);

export const removeUserPoint = createAction(ListingSearchActionTypes.RemoveUserPoint, props<{ point: IMapPoint }>());

export const removeUserPointSuccess = createAction(
  ListingSearchActionTypes.RemoveUserPointSuccess,
  props<{ point: IMapPoint }>(),
);

export const removeUserPointFailed = createAction(
  ListingSearchActionTypes.RemoveUserPointFailed,
  props<{ error: any }>(),
);

export const selectPoint = createAction(ListingSearchActionTypes.SelectPoint, props<{ point?: IMapPoint }>());

export const selectPointById = createAction(ListingSearchActionTypes.SelectPointById, props<{ pointId?: number }>());

export const selectSiteById = createAction(ListingSearchActionTypes.SelectSiteById, props<{ siteId?: number }>());

export const pointDisplaySelected = createAction(
  ListingSearchActionTypes.PointDisplaySelected,
  props<{ point?: IMapPoint }>(),
);

export const siteDisplaySelected = createAction(ListingSearchActionTypes.SiteDisplaySelected, props<{ site?: Site }>());

export const setFilters = createAction(ListingSearchActionTypes.SetFilters, props<{ filters: IFilters }>());

export const setSearchMapCenter = createAction(
  ListingSearchActionTypes.SetSearchMapCenter,
  props<{ center: ILatLon }>(),
);

export const setSearchMapZoom = createAction(ListingSearchActionTypes.SetSearchMapZoom, props<{ zoom: number }>());

export const setSearchMapHeading = createAction(
  ListingSearchActionTypes.SetSearchMapHeading,
  props<{ heading: number }>(),
);

export const setSearchListOpen = createAction(ListingSearchActionTypes.SetSearchListOpen, props<{ isOpen: boolean }>());
