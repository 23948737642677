import { Injectable } from '@angular/core';
import { capSQLiteChanges, capSQLiteValues } from '@capacitor-community/sqlite';
import { Address, IListingType, ISite, LatLon, Site, User } from '@curbnturf/entities';
import { firstValueFrom, of } from 'rxjs';
import { DatabaseService } from '../database.service';
import { IDbSet } from '../dbset.interface';
import { SiteEntity } from '../entities/site';

const TABLE_NAME = 'sites';

@Injectable({
  providedIn: 'root',
})
export class SiteDbSet implements IDbSet {
  public static readonly siteFields = [
    'id',
    'type',
    'name',
    'lat',
    'lng',
    'address',
    'city',
    'state',
    'zipcode',
    'user_id',
  ];

  constructor(private databaseService: DatabaseService) {}

  public store(request: ISite): Promise<capSQLiteValues> {
    const entity = this.mapObjectsToStorable([request]).pop();
    if (entity) {
      return this.databaseService.insert<SiteEntity>(TABLE_NAME, entity);
    } else {
      throw new Error('Unable to create storable object.');
    }
  }

  public storeArray(requests: ISite[], ignoreDuplicates: boolean = false): Promise<capSQLiteValues> {
    const mappedObjects = this.mapObjectsToStorable(requests);
    return this.databaseService.insertMulti<SiteEntity>(
      TABLE_NAME,
      mappedObjects,
      SiteDbSet.siteFields,
      ignoreDuplicates,
    );
  }

  public async truncateAndStoreArray(records: ISite[], ignoreDuplicates: boolean = false): Promise<capSQLiteValues> {
    await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
    return this.storeArray(records, ignoreDuplicates);
  }

  public async truncate(): Promise<capSQLiteChanges> {
    return await this.databaseService.execute(`DELETE FROM ${TABLE_NAME};`);
  }

  public async retrieve(id: number): Promise<ISite> {
    const results = await this.databaseService.select(TABLE_NAME, { id });
    const objResults = this.mapResultsObject(results);
    if (objResults && objResults?.length > 0) {
      return objResults[0];
    } else {
      return new Site();
    }
  }

  public async retrieveOwnSites(userId?: number): Promise<ISite[]> {
    if (userId) {
      const results = await this.databaseService.select(TABLE_NAME, { user_id: userId });
      return this.mapResultsObject(results);
    } else {
      return firstValueFrom(of([]));
    }
  }

  public async retrieveAll(limit?: number): Promise<ISite[]> {
    const results = await this.databaseService.selectAll(TABLE_NAME, undefined, limit);
    return this.mapResultsObject(results);
  }

  public async remove(id: string): Promise<capSQLiteValues> {
    return await this.databaseService.delete(TABLE_NAME, { id });
  }

  private mapResultsObject(results: capSQLiteValues): ISite[] {
    if (results.values) {
      return results.values?.map(
        (record) =>
          new Site({
            id: record.id || undefined,
            user: record.user_id ? new User({ id: record.user_id }) : undefined,
            type: record.type as IListingType['type'],
            name: record.name,
            coordinates: new LatLon({
              lat: record.lat || 0,
              lon: record.lng || 0,
            }),
            address: new Address({
              address: record.address || '',
              city: record.city || '',
              state: record.state || '',
              zipcode: record.zipcode || '',
            }),
            photos: [],
          }),
      );
    } else {
      return [];
    }
  }

  private mapObjectsToStorable(items: ISite[]): SiteEntity[] {
    if (items) {
      return items?.map((record: ISite) => ({
        // Can't use spread operator here as it tries to enter null in undefined properties as columns
        id: record.id,
        user_id: record.user?.id,
        type: record.type as IListingType['type'],
        name: record.name,
        lat: record.coordinates?.lat,
        lng: record.coordinates?.lon,
        address: record.address?.address,
        city: record.address?.city,
        state: record.address?.state,
        zipcode: record.address?.zipcode,
      }));
    } else {
      return [];
    }
  }
}
