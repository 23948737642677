import { ILog, Log } from '@curbnturf/entities';
import { Action, createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';
import * as LogActions from './log.actions';

const MAX_LOCAL_LOGS = 200;

export interface LoggerState {
  sessionId: string;
  logs: ILog[];
  created?: number;
}

export const initialState: LoggerState = {
  sessionId: '',
  logs: [],
  created: undefined,
};

const logReducer = createReducer(
  initialState,
  on(LogActions.logSessionStarted, (state, action) => {
    return {
      ...state,
      sessionId: action?.payload?.response.sessionId,
    };
  }),
  on(LogActions.logMessage, (state, action) => {
    let logs = [...state.logs];
    if (logs.length > MAX_LOCAL_LOGS) {
      logs = logs.slice(logs.length - MAX_LOCAL_LOGS, logs.length);
    }
    const log = new Log({ ...action.log });
    if (!log.created) {
      log.created = DateTime.now().toMillis();
    }

    logs.push(log);

    return {
      ...state,
      logs,
    };
  }),
);

export function reducer(state: LoggerState | undefined, action: Action) {
  return logReducer(state, action);
}
