import { TransactionEvent, TransactionStatus } from '../enums';
import { ICurbNTurfCash } from '../interfaces/cash';
import { IUser } from '../interfaces/user';

export class CurbNTurfCash implements ICurbNTurfCash {
  id: number;
  user: IUser;
  userId: number;
  timestamp: number;
  event: TransactionEvent;
  value: number;
  note: string;
  privateNote: string;
  status: TransactionStatus;

  constructor(data?: ICurbNTurfCash) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: ICurbNTurfCash): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }
  }
}
