import { IRoadConditionDescribed } from '../interfaces/road-condition-described';

export class RoadConditionDescribed implements IRoadConditionDescribed {
  paved: boolean = false;
  pavedDescription: string;
  smoothGravel: boolean = false;
  smoothGravelDescription: string;
  poorGravel: boolean = false;
  poorGravelDescription: string;
  highClearance: boolean = false;
  highClearanceDescription: string;
  offRoad: boolean = false;
  offRoadDescription: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [id: string]: any;
}
