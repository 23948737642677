import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  AWS_REGION,
  COGNITO_CALLBACK,
  COGNITO_CALLBACK_APP,
  COGNITO_CALLBACK_IOS,
  COGNITO_CLIENT_ID,
  COGNITO_DOMAIN,
  COGNITO_LOGOUT_CALLBACK,
  COGNITO_LOGOUT_CALLBACK_IOS,
  COGNITO_USER_POOL_ID,
  IUser,
} from '@curbnturf/entities';
import { SafariViewWrapperService } from '@curbnturf/network';
import { PlatformService } from '@curbnturf/network/src/lib/platform/platform.service';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserSessionData,
  ISignUpResult,
} from 'amazon-cognito-identity-js';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const requestUrl = `https://cognito-idp.${AWS_REGION}.amazonaws.com`;

@Injectable()
export class CognitoService {
  userPool: CognitoUserPool = new CognitoUserPool({
    UserPoolId: COGNITO_USER_POOL_ID,
    ClientId: COGNITO_CLIENT_ID,
  });

  authUser: CognitoUser;

  version: number;

  constructor(
    private httpClient: HttpClient,
    private platformService: PlatformService,
    private safariViewController: SafariViewWrapperService,
  ) {}

  public signUp(email: string, password: string): Promise<ISignUpResult | undefined> {
    return new Promise((resolve, reject) => {
      this.userPool.signUp(email.toLocaleLowerCase(), password, [], [], (err, result) => {
        if (err) {
          return reject(err);
        }

        return resolve(result);
      });
    });
  }

  public authenticateUser(email: string, password: string): Promise<IUser> {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: email.toLocaleLowerCase(),
        Password: password,
      });

      const userData = {
        Username: email.toLocaleLowerCase(),
        Pool: this.userPool,
      };

      this.authUser = new CognitoUser(userData);
      this.authUser.authenticateUser(authenticationDetails, {
        onSuccess: (result: CognitoUserSession): void => {
          resolve(this.sessionToUser(result));
        },
        onFailure: (err: Error): void => {
          reject(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes): void => {
          resolve({ ...userAttributes, temporaryPassword: true });
        },
      });
    });
  }

  public newPassword(password: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.authUser) {
        reject('Must be authenticated to update password');
      }

      this.authUser.completeNewPasswordChallenge(
        password,
        {},
        {
          onSuccess: (result: CognitoUserSession): void => {
            resolve('Done');
          },
          onFailure: (err: Error): void => {
            reject(err);
          },
        },
      );
    });
  }

  public renew(renewToken: string, idToken: string): Observable<any> {
    const requestPayload = {
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: renewToken,
      },
      ClientId: COGNITO_CLIENT_ID,
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
    });

    return this.httpClient
      .post(requestUrl, requestPayload, {
        headers,
      })
      .pipe(
        map((result: any) => {
          const session = this.sessionFromData({
            ...result.AuthenticationResult,
            RefreshToken: renewToken,
          });

          if (!session || !session.getIdToken() || !session.getIdToken().getJwtToken()) {
            throw new Error('Unable to get session data from authentication service.');
          }

          const idPayload = new CognitoIdToken({
            IdToken: session.getIdToken().getJwtToken(),
          }).decodePayload();

          if (!idPayload || !idPayload.email) {
            throw new Error('Received invalid session data from authentication service.');
          }

          const userData = {
            Username: idPayload.email.toLocaleLowerCase(),
            Pool: this.userPool,
          };

          this.authUser = new CognitoUser(userData);

          this.authUser.setSignInUserSession(session);
          return this.sessionToUser(session);
        }),
      );
  }

  public resendVerification(email: string): Observable<any> {
    const requestPayload = {
      Username: email.toLocaleLowerCase(),
      ClientId: COGNITO_CLIENT_ID,
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.ResendConfirmationCode',
    });

    return this.httpClient.post(requestUrl, requestPayload, {
      headers,
    });
  }

  public resetPassword(email: string): Observable<IUser> {
    const requestPayload = {
      Username: email.toLocaleLowerCase(),
      ClientId: COGNITO_CLIENT_ID,
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.ForgotPassword',
    });

    return this.httpClient.post(requestUrl, requestPayload, {
      headers,
    });
  }

  public confirmResetPassword(email: string, verificationCode: string, password: string): Observable<IUser> {
    const requestPayload = {
      Username: email.toLocaleLowerCase(),
      ConfirmationCode: verificationCode,
      Password: password,
      ClientId: COGNITO_CLIENT_ID,
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.ConfirmForgotPassword',
    });

    return this.httpClient.post(requestUrl, requestPayload, {
      headers,
    });
  }

  public changePassword(password: string, newPassword: string, accessToken: string): Observable<boolean> {
    const requestPayload = {
      AccessToken: accessToken,
      PreviousPassword: password,
      ProposedPassword: newPassword,
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.ChangePassword',
    });

    return this.httpClient
      .post(requestUrl, requestPayload, {
        headers,
      })
      .pipe(map(() => true)); // Assuming true unless a 400 is returned
  }

  public codeToUser(code: string): Observable<IUser> {
    const redirectURI = this.platformService.isPhysicalDevice()
      ? this.platformService.isIOS()
        ? COGNITO_CALLBACK_IOS
        : COGNITO_CALLBACK_APP
      : COGNITO_CALLBACK;

    const requestPayload = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', code)
      .set('client_id', COGNITO_CLIENT_ID)
      .set('redirect_uri', redirectURI);

    return this.httpClient
      .post(`https://${COGNITO_DOMAIN}/oauth2/token`, requestPayload, {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      })
      .pipe(
        map((result: any) => {
          const token = {
            IdToken: result.id_token,
            AccessToken: result.access_token,
            RefreshToken: result.refresh_token,
          };

          const session = this.sessionFromData(token);

          const payload = session.getIdToken().decodePayload();

          const userData = {
            Username: payload.email?.toLocaleLowerCase(),
            Pool: this.userPool,
          };

          this.authUser = new CognitoUser(userData);

          this.authUser.setSignInUserSession(session);

          return this.sessionToUser(session);
        }),
      );
  }

  public async signOut(token: { IdToken: string; AccessToken: string; RefreshToken: string }) {
    const session = this.sessionFromData(token);
    const payload = session.getIdToken().decodePayload();

    if (payload.identities && payload.identities.length > 0) {
      const logoutRedirect = this.buildSocialLogoutLink();
      if (Capacitor.isNativePlatform()) {
        // Check for the availability of the Safari View Controller
        this.safariViewController.isAvailable().then(
          (available: boolean) => {
            if (available) {
              this.safariViewController.launchBrowserUI(logoutRedirect, async (result) => {
                if (result.event === 'loaded') {
                  await this.safariViewController.closeAll();
                }
              });
            } else {
              console.error('Attempted Safari View Controller, but was not available.');
              // Fall back to browser link
              window.location.href = logoutRedirect;
            }
          },
          (e) => {
            console.error('Attempted Safari View Controller, but an error occurred.', e);
          },
        );
      } else {
        window.location.href = logoutRedirect;
      }
    } else {
      const userData = {
        Username: payload.email?.toLocaleLowerCase(),
        Pool: this.userPool,
      };

      this.authUser = new CognitoUser(userData);
      this.authUser.signOut();
    }
  }

  private sessionFromData(result: { IdToken: string; AccessToken: string; RefreshToken: string }): CognitoUserSession {
    const token: ICognitoUserSessionData = {
      IdToken: new CognitoIdToken({ IdToken: result.IdToken }),
      AccessToken: new CognitoAccessToken({ AccessToken: result.AccessToken }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: result.RefreshToken,
      }),
    };

    return new CognitoUserSession(token);
  }

  private sessionToUser(result: CognitoUserSession): IUser {
    const payload = result.getIdToken().decodePayload();

    return {
      authAccessToken: result.getAccessToken().getJwtToken(),
      authIdToken: result.getIdToken().getJwtToken(),
      authRenewToken: result.getRefreshToken().getToken(),
      authExpires: result.getIdToken().getExpiration() * 1000,
      email: payload.email.toLocaleLowerCase(),
      firstName: payload.given_name,
      lastName: payload.family_name,
    };
  }

  private buildSocialLogoutLink() {
    let callback = COGNITO_LOGOUT_CALLBACK;
    if (this.platformService.isPhysicalDevice()) {
      if (this.platformService.isIOS()) {
        callback = COGNITO_LOGOUT_CALLBACK_IOS;
      } else {
        callback = COGNITO_LOGOUT_CALLBACK;
      }
    }

    return (
      `https://${COGNITO_DOMAIN}/logout?logout_uri=${callback}` +
      `&client_id=${COGNITO_CLIENT_ID}&redirect_uri=${callback}&response_type=code`
    );
  }
}
