import { Injectable } from '@angular/core';
import { ConnectionStatus } from '@capacitor/network';
import * as AuthSelectors from '@curbnturf/auth/src/lib/+state/auth.selectors';
import * as NetworkSelectors from '@curbnturf/network/src/lib/network/+state/network.selectors';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatusFacade {
  networkStatus$: Observable<ConnectionStatus>;
  loggedIn$: Observable<boolean>;
  userId$: Observable<number | undefined>;

  constructor(private store: Store) {
    this.networkStatus$ = this.store.pipe(select(NetworkSelectors.getStatus));
    this.loggedIn$ = this.store.pipe(select(AuthSelectors.getLoggedIn));
    this.userId$ = this.store.pipe(
      select(AuthSelectors.getUser),
      map((user) => user?.id),
    );
  }
}
