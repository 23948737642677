import { Injectable } from '@angular/core';
import { ActivityLocation, IActivityLocation, IActivityLocationSearchRequest, ILatLon } from '@curbnturf/entities';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as ActivityActions from './activity.actions';
import { ActivityState } from './activity.reducer';
import { activityQuery } from './activity.selectors';

@Injectable({
  providedIn: 'root',
})
export class ActivityLocationFacade {
  activityEntities$: Observable<{[id: number]: IActivityLocation}>;
  activities$: Observable<IActivityLocation[]>;
  searchActivities$: Observable<IActivityLocation[]>;
  lastSearchParams$: Observable<IActivityLocationSearchRequest | undefined>;
  currentUserActivities$: Observable<IActivityLocation[]>;
  awaitedActivity$: Observable<IActivityLocation | undefined>;
  pendingImage$: Observable<FormData | undefined>;
  selected$: Observable<ActivityLocation | undefined>;
  error$: Observable<string | undefined>;

  constructor(private store: Store<ActivityState>) {
    this.activityEntities$ = this.store.pipe(select(activityQuery.getActivityEntities));
    this.activities$ = this.store.pipe(select(activityQuery.getActivities));
    this.searchActivities$ = this.store.pipe(select(activityQuery.getSearchActivities));
    this.lastSearchParams$ = this.store.pipe(select(activityQuery.getLastSearchParams));
    this.currentUserActivities$ = this.store.pipe(select(activityQuery.getCurrentUserActivities));
    this.awaitedActivity$ = this.store.pipe(select(activityQuery.getAwaitedActivity));
    this.pendingImage$ = this.store.pipe(select(activityQuery.getPendingImage));
    this.selected$ = this.store.pipe(select(activityQuery.getSelectedActivity));
    this.error$ = this.store.pipe(select(activityQuery.getError));
  }

  awaitActivity(activity?: IActivityLocation) {
    this.store.dispatch(ActivityActions.awaitActivity({ activity }));
  }

  cancelAwait() {
    this.store.dispatch(ActivityActions.cancelAwait());
  }

  create(activity?: IActivityLocation) {
    this.store.dispatch(ActivityActions.createActivity({ activity }));
  }

  remove(id: number) {
    this.store.dispatch(ActivityActions.removeActivity({ activityId: id }));
  }

  update(activity: IActivityLocation) {
    this.store.dispatch(ActivityActions.updateActivity({ activity }));
  }

  removePhoto(activityId: number, index: number): void {
    this.store.dispatch(ActivityActions.removeImageFromActivity({ activityId, index }));
  }

  cancelPoiImageUpload(): void {
    this.store.dispatch(ActivityActions.cancelUploadActivityImage());
  }

  hide(activity: IActivityLocation) {
    this.store.dispatch(ActivityActions.hideActivity({ activity }));
  }

  show(activity: IActivityLocation) {
    this.store.dispatch(ActivityActions.showActivity({ activity }));
  }

  load(id: number) {
    this.store.dispatch(ActivityActions.loadActivity({ activityId: id }));
  }

  loaded(activity: IActivityLocation) {
    this.store.dispatch(ActivityActions.activityLoaded({ activity }));
  }

  loadForUser() {
    this.store.dispatch(ActivityActions.loadActivities({}));
  }

  loadByCoordinates(coordinates: ILatLon) {
    this.store.dispatch(ActivityActions.loadActivities({ ...coordinates, hidden: false, available: true }));
  }

  loadForSearch(activityLocationSearchRequest: IActivityLocationSearchRequest) {
    if (
      activityLocationSearchRequest?.activities?.length ||
      activityLocationSearchRequest?.selected?.dump ||
      activityLocationSearchRequest?.selected?.wifi ||
      activityLocationSearchRequest?.selected?.restArea ||
      activityLocationSearchRequest?.selected?.potableWater ||
      activityLocationSearchRequest?.selected?.propane
    ) {
      this.store.dispatch(ActivityActions.loadForSearch({ request: activityLocationSearchRequest }));
    } else {
      this.store.dispatch(ActivityActions.loadedForSearch({ activities: [] }));
    }
  }

  loadAllSearch(activityLocationSearchRequest: IActivityLocationSearchRequest) {
    this.store.dispatch(ActivityActions.loadForSearch({ request: activityLocationSearchRequest }));
  }

  clear() {
    this.store.dispatch(ActivityActions.clearActivities());
  }

  clearSearch() {
    this.store.dispatch(ActivityActions.clearSearchActivities());
  }

  favorite(activity: IActivityLocation) {
    this.store.dispatch(ActivityActions.favoriteActivity({ activity }));
  }

  unfavorite(activity: IActivityLocation) {
    this.store.dispatch(ActivityActions.unfavoriteActivity({ activity }));
  }
}
