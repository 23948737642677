import { NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import { MessageChannel, Reservation, Site, User } from '@curbnturf/entities';
import * as MessageActions from '@curbnturf/message/src/lib/+state/message.actions';
import { MessageFacade } from '@curbnturf/message/src/lib/+state/message.facade';
import { ReservationFacade } from '@curbnturf/reservation/src/lib/+state/reservation.facade';
import { UserFacade } from '@curbnturf/user/src/lib/+state/user.facade';
import { IonContent, IonicModule, ModalController } from '@ionic/angular';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { MessageChatBoxComponent } from '../message-chat-box/message-chat-box.component';

@Component({
  selector: 'curbnturf-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
  standalone: true,
  imports: [IonicModule, NgIf, MessageChatBoxComponent, FormsModule, ReactiveFormsModule],
})
export class MessageModalComponent implements OnInit, OnDestroy {
  @ViewChild(IonContent)
  content: IonContent;

  @Input() channelId: number;

  @Input() channel?: MessageChannel;
  reservation?: Reservation;

  currentUser: User;

  subscriptions: Subscription[] = [];

  messageForm: FormGroup<{ messageBody: FormControl<string | null> }>;

  refreshInterval: any;

  get messageBody() {
    return this.messageForm.get('messageBody') as FormControl<string | null>;
  }

  constructor(
    private fb: FormBuilder,
    private messageFacade: MessageFacade,
    private reservationFacade: ReservationFacade,
    private modalController: ModalController,
    private userFacade: UserFacade,
    private actions$: Actions,
    private cd: ChangeDetectorRef,
  ) {
    this.messageForm = this.fb.group({
      messageBody: ['', [Validators.required, Validators.minLength(2)]],
    });
  }

  ngOnInit() {
    if (this.channelId) {
      this.messageFacade.load(this.channelId);
      this.messageFacade.selectById(this.channelId);
    } else {
      console.warn('Channel ID Not provided.');
    }

    this.subscriptions.push(
      this.userFacade.currentUserOnce$.subscribe((user) => {
        this.currentUser = user;
      }),
      this.messageFacade.selectedMessageChannel$.subscribe((channel) => {
        if (channel) {
          this.channel = channel;
          if (channel.reservation) {
            this.reservationFacade.select(channel.reservation);
          }
          this.cd.detectChanges();
        }
      }),
      this.reservationFacade.selected$.subscribe((reservation) => {
        if (reservation) {
          this.reservation = reservation;
        }
      }),
      // Scroll to the bottom once the channel reloads
      this.actions$.pipe(ofType(MessageActions.messageChannelLoaded)).subscribe(() => {
        this.scrollToBottom(0);
      }),
    );
    this.scrollToBottom();

    // Setup refresh for web.
    if (!Capacitor.isNativePlatform()) {
      this.refreshChannel();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      if (!sub.closed) {
        sub.unsubscribe();
      }
    });

    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  send(bodyInput: FormControl<string | null>) {
    const body = bodyInput.value;
    if (body && body.length >= 2) {
      const channel = this.getChannel();
      if (channel) {
        this.messageFacade.create({ body, channel });
        bodyInput.setValue('');
        this.messageForm.markAsPristine();
      }
    }
  }

  scrollToBottom(delay: number = 400) {
    setTimeout(() => {
      if (this.content.scrollToBottom) {
        this.content.scrollToBottom();
      }
    }, delay);
  }

  private getChannel(): MessageChannel | undefined {
    // return the message channel id if it already exists
    if (this.channel) {
      return new MessageChannel({ id: this.channel.id });
    }

    // provide the minimum info needed for a message channel if this is new.
    if (this.currentUser && this.reservation && this.reservation.user && this.reservation.site) {
      if (this.currentUser.id === this.reservation.user.id) {
        return new MessageChannel({
          user: { id: this.currentUser.id },
          reservation: { id: this.reservation.id } as Reservation,
          site: { id: this.reservation.site.id } as Site,
        });
      }

      return new MessageChannel({
        user: { id: this.reservation.user.id },
        reservation: { id: this.reservation.id } as Reservation,
        site: { id: this.reservation.site.id } as Site,
      });
    }

    // we can't create a message channel
    return;
  }

  private refreshChannel(delay: number = 30000) {
    this.refreshInterval = setInterval(() => {
      if (this.channelId) {
        this.messageFacade.load(this.channelId);
      }
    }, delay);
  }

  async closeModal() {
    this.messageFacade.showChatModal(false);
    await this.modalController.dismiss();
  }
}
