export enum Status {
  requested = 'requested',
  changeRequested = 'change',
  accepted = 'accepted',
  rejected = 'rejected',
  processing = 'processing',
  paid = 'paid',
  rated = 'rated',
  completed = 'completed',
  cancelled = 'cancelled',
}

export const StatusArray = [
  'requested',
  'accepted',
  'rejected',
  'processing',
  'paid',
  'rated',
  'completed',
  'cancelled',
];
