export enum CancellationPolicyOption {
  easy = "Easy Goin'",
  moderate = 'Moderate',
  strict = 'Strict',
  exclusive = 'Exclusive',
}

export interface ICancellationPolicyOption {
  option: 'easy' | 'moderate' | 'strict' | 'exclusive';
}
