import {
  dateCompare,
  dateStringRangeToDateStringArray,
  getMonthDaysForNext3Years,
  toPrecision,
} from '@curbnturf/helpers';
import {
  ICancellationPolicyOption,
  ICheckInProcessType,
  IReservationProcessType,
  ListingType,
  RatingType,
  Status,
} from '../enums';
import {
  IAccessRoute,
  IActivity,
  IAddress,
  IAmenity,
  ICoordinateGis,
  ILandOwner,
  IMessage,
  IMessageChannel,
  IPolicy,
  IPrivacy,
  IReservation,
  ISignalReception,
  ISite,
  ISiteExtra,
  ISiteRating,
  ISiteTax,
  ISubPhoto,
  ITax,
  IUser,
  IVehicle,
} from '../interfaces';
import { AccessRoute } from './access-route';
import { Activity } from './activity';
import { LatLon } from './coordinates';
import { LandOwner } from './land-owner';
import { Noise } from './noise';
import { Privacy } from './privacy';
import { RoadConditionDescribed } from './road-condition-described';
import { SiteFavorite } from './site-favorite';
import { SiteRating } from './site-rating';
import { Tax } from './tax';
import { User } from './user';

const FEEDBACK_INCENTIVE = 5;
const SERVICE_FEE_MINIMUM = 5;
const SERVICE_FEE_RATE = 0.15;

export class Site implements ISite {
  id?: number;
  syncId?: string;
  user?: IUser;
  available?: boolean;
  hidden?: boolean;
  name?: string;
  type?: ISite['type'];
  landTypes?: ISite['landTypes'];
  price?: number;
  weekdayDiscount?: number;
  nextReservationDiscount?: number;
  reservationProcess?: IReservationProcessType['type'];
  checkInProcess?: ICheckInProcessType['type'];
  checkIn?: string; // time string '04:00PM'
  checkOut?: string; // time string '11:00AM'
  privacy?: IPrivacy;
  policies?: IPolicy[];
  maxRVAge?: number;
  minLengthOfStayInDays?: number;
  maxLengthOfStayInDays?: number;
  reserveDaysInAdvance?: number;
  bookingWindow?: number;
  cancellationPolicy?: ICancellationPolicyOption['option'];
  noise?: Noise;
  blackoutDays?: string[]; // array of date strings '2018-12-25'
  blackoutMonths?: number[]; // array of months for which the property is unavailable (1 = January, 12 = December)
  photos?: ISubPhoto[]; // array URL strings
  surface?: string;
  maxHeight?: number; // ft
  maxWidth?: number; // ft
  maxLength?: number; // ft
  description?: string;
  maxGuests?: number;
  size?: number; // square feet
  sizeBuses?: number; // size in number of buses
  sizeCars?: number; // size in number of cars
  tentOnly?: boolean;
  extras?: ISiteExtra[];
  taxes?: ITax[];
  siteTaxes?: ISiteTax[]; // used since TypeORM Many-to-Many in Postgres seems to be broken
  ratings?: ISiteRating[];
  rating?: number;
  ratingCount?: number;
  messageChannels?: IMessageChannel[];
  amenities?: IAmenity[];
  signalReception?: ISignalReception[];
  reservations?: IReservation[];
  messages?: IMessage[];
  deleted?: boolean;
  deletedDate?: number;
  vehicles?: IVehicle;
  coordinates?: ICoordinateGis;
  accessRoutes?: IAccessRoute[];
  activities?: IActivity[];
  landOwner?: ILandOwner;
  address?: IAddress;
  phone?: string;
  url?: string;
  email?: string;
  source?: string;
  youtubeCode?: string;
  created?: number;
  publishDate?: number;
  potentialHazards?: boolean;
  potentialHazardDescription?: string;

  timeZone?: string;

  favoritedUsers?: SiteFavorite[];

  modified?: number;

  get roadCondition(): RoadConditionDescribed {
    if (this.accessRoutes) {
      return Site.mergeRoadCondition(this.accessRoutes);
    }

    return new RoadConditionDescribed();
  }

  get nameId(): string {
    return `${this.name} (${this.id})`;
  }
  static mergeRoadCondition(accessRoutes: IAccessRoute[]): RoadConditionDescribed {
    return accessRoutes.reduce(
      (conditionArray: RoadConditionDescribed, route: IAccessRoute) => {
        if (route && route.roadCondition) {
          const roadCondition = route.roadCondition;
          for (const key in conditionArray) {
            if (Object.prototype.hasOwnProperty.call(conditionArray, key)) {
              if (roadCondition[key]) {
                conditionArray[key] = roadCondition[key];
              }
            }
          }
        }
        return conditionArray;
      },
      {
        paved: false,
        pavedDescription: '',
        smoothGravel: false,
        smoothGravelDescription: '',
        poorGravel: false,
        poorGravelDescription: '',
        highClearance: false,
        highClearanceDescription: '',
        offRoad: false,
        offRoadDescription: '',
      } as RoadConditionDescribed,
    );
  }

  static getName(site: ISite): string {
    if (site.id === 1) {
      return 'Curb<span class="trinidad">N</span>Turf HQ';
    }

    return site.name || 'Your New Site';
  }

  static getNameNoHTML(site: ISite): string {
    if (site.id === 1) {
      return 'CurbNTurf HQ';
    }

    return site.name || 'Your New Site';
  }

  constructor(data?: ISite) {
    if (data) {
      this.setData(data);
    }
  }

  setData(data: ISite): void {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key];
      }
    }

    if (!this.blackoutDays) {
      this.blackoutDays = [];
    }

    if (!this.blackoutMonths) {
      this.blackoutMonths = [];
    } else {
      this.blackoutMonths = this.blackoutMonths.map((blackoutMonth) => {
        if (typeof blackoutMonth === 'string') {
          return parseInt(blackoutMonth, 10);
        }

        return blackoutMonth;
      });
    }

    if (!this.photos) {
      this.photos = [];
    }

    if (!this.amenities) {
      this.amenities = [];
    }

    if (!this.extras) {
      this.extras = [];
    }

    if (!this.signalReception) {
      this.signalReception = [];
    }

    if (!this.reservations) {
      this.reservations = [];
    }

    // todo: save these numbers so they only have to be calculated when a rating is created
    if (!this.ratings) {
      this.ratings = [];
    } else if (!this.ratingCount && !this.rating) {
      const overallRating = this.ratings.filter((rating) => rating.topic === RatingType.overall);

      this.ratingCount = overallRating.length;
      if (!this.ratingCount) {
        this.rating = 0;
      } else {
        this.rating = toPrecision(
          overallRating.reduce(
            (total, rating: ISiteRating) =>
              (total = total + (rating && rating.score && rating.score.score ? rating.score.score : 0)),
            0,
          ) / this.ratingCount,
          1,
        );
      }
    }

    if (!this.policies) {
      this.policies = [];
    }

    if (this.privacy) {
      this.privacy = new Privacy(this.privacy);
    }

    if (typeof this.size === 'string') {
      this.size = parseFloat(this.size);
    }

    this.setTaxData();

    // if (typeof this.checkIn === 'string') {
    //   this.checkIn = timeStringToDate(this.checkIn);
    // }

    // if (typeof this.checkOut === 'string') {
    //   this.checkOut = timeStringToDate(this.checkOut);
    // }

    this.coordinates = new LatLon(this.coordinates);

    if (!this.activities) {
      this.activities = [];
    } else {
      this.activities = this.activities.map((activity) => new Activity(activity));
    }

    if (!this.landTypes) {
      this.landTypes = [];
    }

    this.landOwner = new LandOwner(this.landOwner);

    this.user = new User(this.user);

    if (this.noise) {
      this.noise = new Noise(this.noise);
    }

    if (this.accessRoutes) {
      this.accessRoutes = this.accessRoutes.map((accessRoute) => new AccessRoute(accessRoute));
    } else {
      this.accessRoutes = [];
    }
  }

  getBusyDays(): string[] {
    if (!this.available || this.hidden) {
      return [];
    }

    return [...this.getBlackoutDays(), ...this.getReservationDays()].filter((days) => !!days).sort(dateCompare);
  }

  getBlackoutDays(): string[] {
    const blackoutDays: string[] = [];

    if (this.blackoutDays) {
      blackoutDays.push(
        ...this.blackoutDays.reduce((days: string[], blackout) => {
          days.push(...dateStringRangeToDateStringArray(blackout));

          return days;
        }, []),
      );
    }

    if (this.blackoutMonths) {
      this.blackoutMonths.forEach((month) => blackoutDays.push(...getMonthDaysForNext3Years(month)));
    }

    return blackoutDays;
  }

  getReservationDays(): string[] {
    const reservationDays: string[] = [];

    if (this.reservations) {
      this.reservations.forEach((reservation) => {
        if (
          reservation.status &&
          reservation.status !== Status.rejected &&
          reservation.status !== Status.cancelled &&
          reservation.date
        ) {
          reservationDays.push(...reservation.date.toDateStringArray());
        }
      });
    }

    return reservationDays;
  }

  getPrice(): number {
    if (this.type === ListingType.boondock) {
      return 0;
    }

    // Add CurbNTurf rate.
    const fee = Math.max(((this.price || 0) / (1 - SERVICE_FEE_RATE)) * SERVICE_FEE_RATE, SERVICE_FEE_MINIMUM);
    return toPrecision((this.price || 0) + fee + FEEDBACK_INCENTIVE, 2);
  }

  getPriceWithTax(): number {
    let totalPrice = this.getPrice();

    // get taxes
    let totalTaxRate = 0;
    if (this.siteTaxes) {
      totalTaxRate = this.siteTaxes.reduce(
        (total: number, siteTax: ISiteTax) =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          total + parseFloat(siteTax.tax.rate as any) / 100,
        totalTaxRate,
      );
    }

    if (this.taxes) {
      totalTaxRate = this.taxes.reduce((total: number, tax: Tax) => total + tax.rate / 100, totalTaxRate);
    }

    if (totalTaxRate) {
      totalPrice = totalPrice + totalTaxRate * totalPrice;
    }

    return totalPrice;
  }

  getName(): string {
    return Site.getName(this);
  }

  getNameNoHTML(): string {
    return Site.getNameNoHTML(this);
  }

  getRating(): number {
    if (this.rating || this.rating === 0) {
      return this.rating;
    }

    return SiteRating.calculateRating(this.ratings);
  }

  getRatingCount(): number {
    if (this.ratingCount || this.ratingCount === 0) {
      return this.ratingCount;
    }

    return SiteRating.calculateRatingCount(this.ratings) || 0;
  }

  getFinishedPercent(): number {
    return 1;
  }

  displayLocation(): string {
    if (!this.address || (!this.address.city && !this.address.state)) {
      return '';
    }

    if (!this.address.city) {
      return `${this.address.state}`;
    }

    return `${this.capitalize(this.address.city.toLocaleLowerCase())}, ${this.address.state}`;
  }

  private setTaxData(): void {
    if (this.taxes && this.taxes.length > 0) {
      this.taxes = this.taxes.map((tax) => new Tax(tax));
      this.siteTaxes = [];
    } else if (this.siteTaxes && this.siteTaxes.length > 0) {
      this.taxes = this.siteTaxes.map((propertyTax) => new Tax(propertyTax.tax));
    } else {
      this.taxes = [];
      this.siteTaxes = [];
    }
  }

  private capitalize(str: string): string {
    return str.replace(/\w\S*/g, (word) => word.replace(/^\w/, (character) => character.toUpperCase()));
  }
}
