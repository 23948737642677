import { inject } from '@angular/core';
import * as AlertActions from '@curbnturf/alert/src/lib/+state/alert.actions';
import { LogLevel, NGRX_NO_ACTION } from '@curbnturf/entities';
import { MessageModalComponent } from '@curbnturf/message-display/src/lib/message-modal/message-modal.component';
import * as LogActions from '@curbnturf/network/src/lib/log/+state/log.actions';
import * as UserActions from '@curbnturf/user/src/lib/+state/user.actions';
import * as UserSelectors from '@curbnturf/user/src/lib/+state/user.selectors';
import { ModalController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { MessageService } from '../message.service';
import { PushNotificationService } from '../push-notification.service';
import * as MessageActions from './message.actions';

export const loadMessage$ = createEffect(
  (actions$ = inject(Actions), messageService = inject(MessageService)) =>
    actions$.pipe(
      ofType(MessageActions.loadMessageChannel),
      switchMap((action) => {
        return messageService.fetch(action.channel).pipe(
          map((channel) => MessageActions.messageChannelLoaded({ channel })),
          catchError((error) => of(MessageActions.messageChannelLoadError({ error }))),
        );
      }),
    ),
  { functional: true },
);

export const loadMessageChannels$ = createEffect(
  (actions$ = inject(Actions), messageService = inject(MessageService)) =>
    actions$.pipe(
      ofType(MessageActions.loadMessageChannels),
      switchMap((action) => {
        return messageService.query(action.query).pipe(
          mergeMap((channels) => {
            return [MessageActions.messageChannelsLoaded({ channels })];
          }),
          catchError((error) => of(MessageActions.messageChannelLoadError({ error }))),
        );
      }),
    ),
  { functional: true },
);

export const loadReservationMessage$ = createEffect(
  (actions$ = inject(Actions), messageService = inject(MessageService)) =>
    actions$.pipe(
      ofType(MessageActions.loadReservationMessageChannel),
      switchMap((action) => {
        return messageService.query({ reservationId: action.channel }).pipe(
          mergeMap((messageChannels) => {
            if (messageChannels.length === 0) {
              return [MessageActions.selectMessageChannel({})];
            }

            return [
              MessageActions.messageChannelLoaded({ channel: messageChannels[0] }),
              MessageActions.selectMessageChannel({ channel: messageChannels[0] }),
            ];
          }),
          catchError((error) => of(MessageActions.messageChannelLoadError({ error }))),
        );
      }),
    ),
  { functional: true },
);

export const createMessage = createEffect(
  (actions$ = inject(Actions), messageService = inject(MessageService)) =>
    actions$.pipe(
      ofType(MessageActions.createMessage),
      switchMap((action) => {
        return messageService.create(action.message).pipe(
          switchMap((channel) => {
            if (!channel) {
              throw new Error(`Error creating message.`);
            }

            return [MessageActions.messageChannelLoaded({ channel }), MessageActions.selectMessageChannel({ channel })];
          }),
          catchError((error) => of(MessageActions.messageChannelLoadError({ error }))),
        );
      }),
    ),
  { functional: true },
);

export const readMessage = createEffect(
  (actions$ = inject(Actions), messageService = inject(MessageService)) =>
    actions$.pipe(
      ofType(MessageActions.readMessages),
      switchMap((action) => {
        return messageService.read(action.channel).pipe(
          switchMap((channel) => {
            if (!channel) {
              throw new Error(`Error updating message.`);
            }

            return [MessageActions.messageChannelLoaded({ channel }), MessageActions.selectMessageChannel({ channel })];
          }),
          catchError((error) => of(MessageActions.messageChannelLoadError({ error }))),
        );
      }),
    ),
  { functional: true },
);

export const messageChannelLoadError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(MessageActions.messageChannelLoadError),
      map((action) =>
        AlertActions.displayAlert({
          title: 'Error Loading Message(s)',
          body: action.error.toString(),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const registerPushNotifications = createEffect(
  (actions$ = inject(Actions), pushNotificationService = inject(PushNotificationService)) =>
    actions$.pipe(
      ofType(MessageActions.registerPushNotifications),
      mergeMap(() => {
        return of(pushNotificationService.register()).pipe(map(() => NGRX_NO_ACTION));
      }),
      catchError((error) => {
        return [MessageActions.registerPushNotificationsFailed({ error })];
      }),
    ),
  { functional: true },
);

export const registerPushNotificationsSuccess = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(MessageActions.registerPushNotificationsSuccess),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([action, user]) => {
        if (user) {
          return [
            UserActions.registerPushToken({ token: action.token }),
            // @todo probably remove the token logging, as it could be a security issue in the future.
            LogActions.logMessage({
              log: {
                level: LogLevel.DEBUG,
                message: 'Push Registration Completed',
                details: { token: action.token },
              },
            }),
          ];
        } else {
          return [NGRX_NO_ACTION];
        }
      }),
    ),
  { functional: true },
);

export const registerPushNotificationsFailed = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(MessageActions.registerPushNotificationsFailed),
      mergeMap((action) => {
        return [
          LogActions.logMessage({
            log: {
              level: LogLevel.ERROR,
              message: 'Failed to register for push notifications',
              details: { error: action },
            },
          }),
        ];
      }),
    ),
  { functional: true },
);

export const showChatModal$ = createEffect(
  (actions$ = inject(Actions), modalController = inject(ModalController)) =>
    actions$.pipe(
      ofType(MessageActions.showChatModal),
      mergeMap((action) => {
        if (action.showModal) {
          modalController
            .create({
              id: 'chatModal',
              component: MessageModalComponent,
              componentProps: {
                channelId: action.channelId,
              },
            })
            .then((modal) => {
              modal.present().then();
            });
        } else {
          try {
            modalController.dismiss(undefined, undefined, 'chatModal').then();
          } catch (e) {}
        }
        return [NGRX_NO_ACTION];
      }),
    ),
  { functional: true },
);
