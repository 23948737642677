import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConnectionStatus } from '@capacitor/network';
import { BASE_API_URL, IOrder, IReservation, IReservationWhere, ISite } from '@curbnturf/entities';
import { objectToUrlParams } from '@curbnturf/helpers';
import { CachedHttpClient } from '@curbnturf/network';
import { ReservationDbSet } from '@curbnturf/network/src/lib/native/dbset/reservation.dbset';
import { SiteDbSet } from '@curbnturf/network/src/lib/native/dbset/site.dbset';
import { StatusFacade } from '@curbnturf/status';
import { from, Observable } from 'rxjs';

const API_URL = BASE_API_URL + 'reservation';

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  connectionStatus: ConnectionStatus = { connected: false, connectionType: 'none' };

  constructor(
    private http: HttpClient,
    private cachedHttp: CachedHttpClient,
    private statusFacade: StatusFacade,
    private reservationDbSet: ReservationDbSet,
    private siteDbSet: SiteDbSet,
  ) {
    this.statusFacade.networkStatus$.subscribe((status) => {
      if (status) {
        this.connectionStatus = status;
      }
    });
  }

  fetch(reservationId: number): Observable<IReservation> {
    return this.cachedHttp.get<IReservation>(`${API_URL}/${reservationId}`, {
      cachableResponse: true,
    });
  }

  fetchForGuest(userId: number): Observable<IReservation[]> {
    if (this.connectionStatus.connected) {
      return this.cachedHttp.get<IReservation[]>(`${API_URL}?userId=${userId}`, {
        cachableResponse: true,
      });
    } else {
      return from(this.reservationDbSet.retrieveAll());
    }
  }

  fetchForHost(userId: number): Observable<IReservation[]> {
    return this.http.get<IReservation[]>(`${API_URL}?ownerId=${userId}`);
  }

  fetchAll(query?: IReservationWhere): Observable<IReservation[]> {
    if (query) {
      const queryString = objectToUrlParams(query, true);
      return this.http.get<IReservation[]>(`${API_URL}?${queryString}`);
    } else {
      return this.http.get<IReservation[]>(`${API_URL}`);
    }
  }

  create(reservation: IReservation): Observable<IReservation> {
    return this.http.post<IReservation>(API_URL, reservation);
  }

  accept(reservationId: number, reason: string): Observable<IReservation> {
    return this.http.post<IReservation>(`${API_URL}/${reservationId}/accept`, {
      reason,
    });
  }

  reject(reservationId: number, reason: string): Observable<IReservation> {
    return this.http.post<IReservation>(`${API_URL}/${reservationId}/reject`, {
      reason,
    });
  }

  cancel(reservationId: number, reason: string): Observable<IReservation> {
    return this.http.post<IReservation>(`${API_URL}/${reservationId}/cancel`, {
      reason,
    });
  }

  change(reservationId: number, order: IOrder): Observable<IReservation> {
    return this.http.put<IReservation>(`${API_URL}/${reservationId}/change`, {
      reservationId,
      order,
    });
  }

  preload(userId: number) {
    this.fetchForGuest(userId).subscribe(async (reservations) => {
      const sites: ISite[] = [];
      reservations.forEach((reservation) => {
        if (reservation.site) {
          const siteFound = sites.find((el) => el.id === reservation.site?.id);
          if (!siteFound) {
            sites.push(reservation.site);
          }
        }
      });

      await this.reservationDbSet.truncateAndStoreArray(reservations, true);
      await this.siteDbSet.storeArray(sites, true);
    });
  }
}
