import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'curbnturf-display-validation-errors',
  templateUrl: './display-validation-errors.component.html',
  styleUrls: ['./display-validation-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf],
})
export class DisplayValidationErrorsComponent {
  @Input() field: string;
  _text: string;
  show = true;
  hide = true;

  @Input() set text(value: string) {
    if (value !== this._text) {
      this._text = value;
      this.hide = !value;
      this.cdr.detectChanges();
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
