/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * We arbitrarily select a number smaller then the rounding error and set it to a string using toFixed then change it back to a number
 *
 * @param float
 */
import { IRating } from '@curbnturf/objects';

export const fixFloat = (float: number): number => {
  return parseFloat(parseFloat(float as any).toFixed(10));
};

export const forceInt = (value: string | number): number => {
  if (typeof value === 'string') {
    return parseInt(value, 10);
  }

  return Math.floor(value);
};

export const forceNumber = (value: string | number): number => {
  if (typeof value === 'string') {
    return parseFloat(value);
  }

  return value;
};

export function toPrecision(value: number, precision: number): number {
  const precisionMultiplier = Math.pow(10, precision);
  return Math.round(value * precisionMultiplier) / precisionMultiplier;
}

export const moneyDecimal = (value: number): string => {
  value = toPrecision(value, 2);

  if (value > Math.floor(value)) {
    return value.toFixed(2);
  }

  return value.toFixed(0);
};

export const ratingAvg = (ratings: IRating[], topic: string): number => {
  let length = 0;

  return (
    Math.round(
      (ratings.reduce((total, rating) => {
        if (rating.topic !== topic) {
          return total;
        }
        length = length + 1;
        return total + rating.score.score;
      }, 0) /
        length) *
        10,
    ) / 10
  );
};

export function random0to9(): number {
  return Math.round(Math.random() * 10);
}

export function isNumber(value: any): value is number {
  return !isNaN(forceInt(value));
}

export function isInteger(value: any): value is number {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}
