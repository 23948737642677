import { NgClass, NgStyle, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'curbnturf-site-svgs',
  templateUrl: './site-svgs.component.html',
  styleUrls: ['./site-svgs.component.scss'],
  standalone: true,
  imports: [NgClass, NgSwitch, NgSwitchCase, NgStyle],
})
export class SiteSvgsComponent implements OnChanges {
  @Input() type: string;
  @Input() opacity = 1;
  @Input() black = false;
  @Input() white = false;
  @Input() pin = false;
  @Input() filledPin = false;
  @Input() solidPin = false;
  @Input() string = '';
  @Input() color: string | undefined;

  get fillColor() {
    return this.white ? '#fff' : this.black ? '#000' : this.color ? this.color : '#454545';
  }

  trimmedType: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.type) {
      this.trimmedType = this.type ? this.type.trim() : '';
    }
  }
  getClass() {
    return this.white ? 'white' : this.black ? 'black' : 'secondary';
  }
}
